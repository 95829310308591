import {
  DeleteMasterInstrumentApi,
  UpdateMasterInstrumentApi,
} from "helpers/app-backend/masterinstrumentbackend_helpers";

import HandleReponse from "utils/handleReponse";

const UpdateMasterInstrumentService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await UpdateMasterInstrumentApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const DeleteMasterInstrumentService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await DeleteMasterInstrumentApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

export default {
  UpdateMasterInstrumentService,
  DeleteMasterInstrumentService,
};
