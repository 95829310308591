import React, { useState } from "react"
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"
import { Check, CustomButton } from "."
import { permissionType } from "constant"

const ButtonDropdownGroup = ({
  onEditClick,
  onCloneClick,
  onDeleteClick,
  isDisableClone,
  t,
  resource,
  children,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <ButtonDropdown
      style={{ marginLeft: 0 }}
      isOpen={isMenuOpen}
      toggle={() => setIsMenuOpen(prev => !prev)}
      direction="left"
    >
      {children ? (
        children
      ) : (
        <>
          {/* <Check permission={permissionType.U} resource={resource}>
            <CustomButton
              color="primary"
              onClick={onEditClick}
              outline
              text={t("Edit")}
            />
          </Check> */}
          {/* {!isDisableClone &&
            <Check permission={permissionType.C} resource={resource}>
              <CustomButton color="primary" onClick={onCloneClick} outline>
                {t("Clone")}
              </CustomButton>
            </Check>
          } */}

          <Check
            permission={permissionType.D}
            // exclusivePermissions={[permissionType.U, permissionType.C]}
            resource={resource}
          >
            <CustomButton color="primary" onClick={onDeleteClick} outline>
              {t("Delete")}
            </CustomButton>
          </Check>

          {/* <Check
            permissions={[permissionType.U, permissionType.C]}
            resource={resource}
          >
            <Check permission={permissionType.D} resource={resource}>
              <DropdownToggle caret color="primary" outline>
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled>{t("Action")}</DropdownItem>
                <DropdownItem onClick={onDeleteClick}>
                  {t("Delete")}
                </DropdownItem>
              </DropdownMenu>
            </Check>
          </Check> */}
        </>
      )}
    </ButtonDropdown>
  )
}

ButtonDropdownGroup.propTypes = {
  onEditClick: PropTypes.func,
  onCloneClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isDisableClone: PropTypes.bool,
  t: PropTypes.any,
  resource: PropTypes.string,
}

ButtonDropdownGroup.defaultProps = {
  onEditClick: () => { },
  onCloneClick: () => { },
  onDeleteClick: () => { },
}

export default withTranslation(["common"])(ButtonDropdownGroup)
