import Table, { getSTTCellData, formatNumber } from "components/Common/Table";
import { format } from 'date-fns';
const Index = ({paging, setPaging, data, pageSize}) => {
  return (
    <Table
    paging={paging}
    setPaging={setPaging}
    data={data}  
    pageSize={pageSize}
    columns={[
        {
          Header: "STT",
          accessor: "",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSTTCellData(data, paging)}
            </div>
          ),
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          width: 150,
          Cell: ({ value }) => (
            <div>
              {format(new Date(value), 'yyyy-MM')}
            </div>
          ),
        },
        {
          Header: "MasterTestCode",
          accessor: "masterTestcode",
          width: 350,
        },
        {
          Header: "MasterCategoryCode",
          accessor: "masterCategoryCode",
          width: 250,
        },
        {
            Header: "MasterCategoryName",
            accessor: "masterCategoryName",
            width: 250,
        },
        {
            Header: "CountTest",
            accessor: "countTest",
            Cell: data => (
              <div className="d-flex w-100 justify-content-end">{formatNumber(data.value)}</div>
            ),
            width: 150,
        },
        {
            Header: "CommonName",
            accessor: "commonName",
            width: 250,
        },      
      ]}
      disablePagination = {false}
    />
  );
};

export default Index;
