import {
  DeleteMasterTestCodeApi,
  InsertMasterTestCodeApi,
  UpdateMasterTestCodeApi,
  GetLisLabelValueMasterTestCodeApi
} from "helpers/app-backend/mastertestcodebackend_helpers";

import HandleReponse from "utils/handleReponse";


const InsertMasterTestCodeService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await InsertMasterTestCodeApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse_v2(err.response);
  }
  return result;
};

const UpdateMasterTestCodeService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await UpdateMasterTestCodeApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const DeleteMasterTestCodeService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await DeleteMasterTestCodeApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const GetLisLabelValueMasterTestCodeService = async () => {
  try {
    const res = await GetLisLabelValueMasterTestCodeApi();
    return res;
  } catch (err) {
    return err;
  }
};

export default {
  InsertMasterTestCodeService,
  DeleteMasterTestCodeService,
  UpdateMasterTestCodeService,
  GetLisLabelValueMasterTestCodeService
};
