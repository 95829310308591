import React, { useEffect, useRef, useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import { Row, Col, Typography } from "antd";
import MapContainer from "../Map/Map.container";

import {
  fetchSampleInfo,
  fetchSampleInfoAll,
  fetchAllDetailDataSampleTypeRoute,
  fetchAllDataSampleTypeRouteApi,
  fetchTestCodeInfo,
  fetchMenuInfo,
} from "../../../../helpers/app-backend/samplepathbackend_helpers";
import {
  getDistancePixel,
  getSampleInfoByArea,
  mappingActionLocationToArea,
  getDistanceByPixels,
  isSpecialRoute,
  isRouteData,
} from "../utils";
import { CustomCard } from "./custom";
import { area } from "../constants";
import Dijkstra from "../utils/Dijkstra";
import { configKey } from "../constants";
import SampleTimeLine from "./Timeline";
import Filter from "./../FlowSample/filter";
import Setting from "./Setting";
import moment from "moment";
import { Tooltip } from "antd";
import ListSamplePath from "../FlowSample/ListSamplePath";
import ListDetailSamplePath from "../FlowSample/Details";
import ListMenuTest from "../FlowSample/MenuTest";
import WattageConfig from "./WattageConfig";
import { convertSecondToDisplayTime, createUUID } from "../config";
import { showToast } from "components/Common";
import ChartNumTest from "./ChartNumTest";
import Select from "react-select";

const Index = ({
  imageSize,
  config,
  setConfig,
  sampleNumberList,
  areaList,
  setMachineSelected,
  filter,
  setFilter,
  wattageConfig,
  setWattageConfig,
  showSampleNumber,
  showSampleFlow,
  sites,
  error,
  setError,
  paging,
  setPaging,
}) => {
  const delayRef = useRef(null);
  const timeLineIdxRef = useRef(-1);
  const [isLoading, setIsLoading] = useState(false);
  const iDijkstra = Dijkstra.getInstance();
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [initial, setInitial] = useState(null);
  const [target, setTarget] = useState(null);
  const [history, setHistory] = useState([]); // full history
  const [timeLineHistory, setTimelineHistory] = useState([]);
  const [totalPoint, setTotalPoint] = useState(0);
  const [distance, setDistance] = useState([]);
  const [dataFlow, setDataFlow] = useState([]);
  const [dataLsAll, setDataLsAll] = useState([]);
  const [currentRouteName, setCurrentRouteName] = useState("");
  const [totalDataFlow, setTotalDataFlow] = useState(0);
  const [totalPointReal, setTotalPointReal] = useState(0);
  const [lsTestCode, setLsTestCode] = useState([]);
  const [lsMenu, setLsMenu] = useState([]);
  const inputRef = useRef("");
  const [keyState, setKeyState] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [keyTopSelectData, setKeyTopSelectData] = useState("10");
  const [dataTop10Calculate, setDataTop10Calculate] = useState([]);
  const [dataTop10Percentage, setDataTop10Percentage] = useState([]);
  const [dataTop5Calculate, setDataTop5Calculate] = useState([]);
  const [dataTop5Percentage, setDataTop5Percentage] = useState([]);
  const [dataNameRoute, setDataNameRoute] = useState([]);
  const [dataTestCodeChart, setDataTestCodeChart] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const removeTarget = id => {
    if (!target) {
      return;
    }
    const idx = target.findIndex(el => el.id === id);
    if (idx > -1) {
      // Bắt đầu là 1 section => cập nhật vào danh sách timeLineHistory
      // Vì giữa 2 SECTION sẽ có 1 thống kê quãng đường & thời gian
      if (target[idx].data.area.type === area.TYPE.SECTION) {
        const hisLen = timeLineHistory.length;

        if (!hisLen) {
          setTimelineHistory([target[idx]]);
        } else {
          const lastTimelineHis = timeLineHistory.slice(-1)[0];
          const statistic = {
            sectionFrom: lastTimelineHis.data.section,
            sectionTo: target[idx].data.section,
            timeFrom: lastTimelineHis.data.time,
            timeTo: target[idx].data.time,
            isStatistic: true,
            time: 0,
            idx: timeLineIdxRef.current + 1,
          };

          timeLineIdxRef.current += 1;

          const previous = moment(lastTimelineHis.data.time);
          const current = moment(target[idx].data.time);
          var duration = moment.duration(current.diff(previous));
          let diff = duration.asSeconds();
          diff = convertSecondToDisplayTime(Math.round(diff));

          statistic.time = diff;

          setTimelineHistory(prev => [...prev, statistic, target[idx]]);
        }
      }

      const newHistory = [...history, target[idx]];
      setHistory(newHistory);

      // Tính khoảng cách giữa 2 điểm cuối trong danh sách History
      if (newHistory.length > 1) {
        const calcArr = newHistory.slice(-2);

        for (let i = 0; i < calcArr.length - 1; i++) {
          const distancePixels = getDistancePixel(
            calcArr[i].data.x, // x1
            calcArr[i].data.y, // y1
            calcArr[i + 1].data.x, // x2
            calcArr[i + 1].data.y // y2
          );
          const realDistance = getDistanceByPixels(distancePixels);
          const ds = {
            distancePixels,
            realDistance,
            from: calcArr[i].data.area.id,
            to: calcArr[i + 1].data.area.id,
            // **** Thông tin về 2 điểm from,to để kiểm tra
            fromData: calcArr[i].data,
            toData: calcArr[i + 1].data,
          };

          // Tạo 1 dòng khoảng cách mới khi:
          // - Điểm bắt đầu thuộc loại SECTION
          if (calcArr[i].data.area.type === area.TYPE.SECTION) {
            setDistance(prev => [...prev, { ...ds, child: [ds] }]);
          } else {
            if (distance.length) {
              // Lấy dữ liệu khoảng cách cuối cùng trong mảng
              const lastDis = { ...distance.slice(-1)[0] };

              // Cập nhật khoảng cách mới
              const newDistancePixels =
                lastDis.distancePixels + ds.distancePixels;
              lastDis.distancePixels = newDistancePixels;
              lastDis.realDistance = getDistanceByPixels(newDistancePixels);
              lastDis.to = calcArr[i + 1].data.area.id;

              // Nếu danh sách khoảng cách chi tiết chưa có => cập nhật vào phần tử đầu tiên vừa tính được
              if (!lastDis.child || !lastDis.child.length) {
                lastDis.child = [ds];
              } else {
                lastDis.child.push(ds);
              }
              const tempDS = JSON.parse(JSON.stringify(distance));
              // Xóa bỏ thông tin cuối cùng trong mảng vì ta sẽ thay đổi khoảng cách ở vị trí này
              tempDS.splice(-1, 1, lastDis);
              setDistance(tempDS);
            } else {
              setDistance(prev => [...prev, { ...ds, child: [ds] }]);
            }
          }
        }
      }
    }

    const newTarget = target.filter(el => el.id !== id);

    // Kết thúc tiến trình mẫu
    if (!newTarget.length) {
      setTarget(null);
      return;
    }

    setTarget(newTarget);
  };

  const getSIDInfoWithRouteName = async (_routeName, dataApiSID) => {
    let param = {
      fullSID: dataApiSID.fullSID,
      routeName: _routeName,
      sampleTypeId: dataApiSID.sampleTypeId,
      actionTime: dataApiSID.actionTime,
      siteCode: filter.site.value,
    };
    const res = await fetchSampleInfo(param);

    if (res) {
      return res;
    }

    // LỖI
    return null;
  };
  const getAllInfoWithRouteName = async (_routeName, value) => {
    let param = {
      dateFrom: filter.fromDate,
      dateTo: filter.toDate,
      routeNameSearch: _routeName,
      siteCode: filter.site?.value,
    };
    const res = await fetchSampleInfoAll(param);

    if (res) {
      return res;
    }

    // LỖI
    return null;
  };
  const getMenuTest = async (_routeName, value) => {
    let param = {
      fullSID: value.fullSID,
      routeName: _routeName,
      sampleTypeId: value.sampleTypeId,
      actionTime: value.actionTime,
      siteCode: localStorage.getItem("siteCode"),
    };
    const res = await fetchMenuInfo(param);
    if (res.data.isSuccess) {
      // setLsMenu(res.data.data);

      const transformedData = res.data.data.reduce((result, item) => {
        const existingItem = result.find(
          resultItem => resultItem.actionLocation === item.actionLocation
        );

        if (existingItem) {
          // Append testCode to existing item if actionLocation already exists
          existingItem.testCode += `, ${item.testCode.trim()}`;
        } else {
          // Create a new item if actionLocation does not exist
          result.push({
            actionLocation: item.actionLocation,
            testCode: item.testCode.trim(),
            categoryName: item.categoryName,
          });
        }

        return result;
      }, []);
      setLsMenu(transformedData);
      return;
    }

    // LỖI
    setLsMenu([]);
  };

  const getSampleRouteFlow = async () => {
    let err = ValidFilter();
    if (JSON.stringify(err) !== "{}") {
      setError(err);
    } else {
      let data = {
        dateFrom: filter.fromDate,
        dateTo: filter.toDate,
        typeFlow: filter.typeFlow?.value,
        routeNameSearch: filter.searchRoute ? filter.searchRoute : "",
        siteCode: filter.site?.value,
      };
      try {
        const res = await fetchAllDataSampleTypeRouteApi(data);
        if (res.data.length > 0) {
          let newLs = res.data.map(_item => {
            return { ..._item, path: _item.lsPoint };
          });

          let finalRes = newLs.map(_item => {
            const renderData = [];
            const notFoundData = [];
            const len = _item.path.length;
            for (let i = 0; i < len; i++) {
              const actionLocation = mappingActionLocationToArea(_item.path[i]);

              if (actionLocation) {
                renderData.push({
                  // ..._item.path[i],
                  rawActionLocation: _item.path[i],
                  actionLocation,
                });
              } else {
                notFoundData.push(_item.path[i]);
              }
            }

            let dataPathPoints = getDataPath(renderData);
            let distancePixel = calculateDistances(dataPathPoints);
            return { ..._item, distance: getDistanceByPixels(distancePixel) };
          });
          setDataFlow(finalRes);
          setTotalDataFlow(res.totalElements);
        } else {
          setDataFlow([]);
        }
        setDataLsAll([]);
      } catch (e) {
        setDataFlow([]);
        showToast("Lỗi lấy dữ liệu", 3);
      }
    }
    setIsLoading(false);
  };
  const calculateDistances = data => {
    let distances = 0;

    for (let i = 0; i < data.length - 1; i++) {
      const point1 = data[i].data;
      const point2 = data[i + 1].data;
      const distance = getDistancePixel(point1.x, point1.y, point2.x, point2.y);

      distances += distance;
    }

    return distances;
  };

  const ValidFilter = () => {
    let err = {};
    if (!filter.site && !filter.site?.value) {
      err.siteName = "Vui lòng chọn bệnh viện";
    }
    return err;
  };
  async function processAllData(currentRouteName, data) {
    reset();
    setLoading(true);
    const info = await getAllInfoWithRouteName(currentRouteName, data);
    const newData = [];
    const steps = currentRouteName.split(" => ");

    for (let i = 0; i < steps.length; i++) {
      const currentStep = steps[i];
      let foundSampleTypeId = 0;

      for (let j = 0; j < info.length; j++) {
        if (info[j].actionLocation === currentStep) {
          foundSampleTypeId = info[j].sampleTypeId;
          break;
        }
      }

      newData.push(foundSampleTypeId);
    }

    setDataLsAll(newData);
  }

  const onGetDetailSampleFlow = async (routeName, searchID = "") => {
    setLoadingDetail(true);
    setLsTestCode([]);
    let data = {
      dateFrom: filter.fromDate,
      dateTo: filter.toDate,
      typeFlow: filter.typeFlow?.value,
      routeNameSearch: routeName,
      siteCode: filter.site?.value,
    };
    const res = await fetchAllDetailDataSampleTypeRoute(data);
    if (res.data) {
      if (searchID) {
        const regex = new RegExp(searchID, "i");
        let data = res.data.filter(el => el.fullSID.match(regex));
        // setDataLsAll(data);

        await processAllData(data[0]?.routeName, data[0]);
        const newInfo = await getSIDInfoWithRouteName(routeName, data[0]);
        if (!newInfo) {
          setLoading(false);
          return;
        }
        const renderData = [];
        const notFoundData = [];
        const len = newInfo.length;
        for (let i = 0; i < len; i++) {
          const actionLocation = mappingActionLocationToArea(
            newInfo[i].actionLocation
          );
          if (actionLocation) {
            renderData.push({
              ...newInfo[i],
              rawActionLocation: newInfo[i].actionLocation,
              actionLocation,
            });
          } else {
            notFoundData.push(newInfo[i]);
          }
        }
        // Không có tiến trình để vẽ
        if (!renderData || !renderData.length) {
          setLoading(false);
        }
        createPaths(renderData, routeName);
      } else {
        // setDataLsAll(res.data);
        await processAllData(res.data[0]?.routeName, res.data[0]);
        const newInfo = await getSIDInfoWithRouteName(routeName, res.data[0]);
        if (!newInfo) {
          setLoading(false);
          return;
        }
        const renderData = [];
        const notFoundData = [];
        const len = newInfo.length;
        for (let i = 0; i < len; i++) {
          const actionLocation = mappingActionLocationToArea(
            newInfo[i].actionLocation
          );
          if (actionLocation) {
            renderData.push({
              ...newInfo[i],
              rawActionLocation: newInfo[i].actionLocation,
              actionLocation,
            });
          } else {
            notFoundData.push(newInfo[i]);
          }
        }
        // Không có tiến trình để vẽ
        if (!renderData || !renderData.length) {
          setLoading(false);
        }
        createPaths(renderData, routeName);
      }
    }
    // const lsTestCode = await fetchTestCodeInfo(param);
    // if (lsTestCode.data.isSuccess) {
    //   setLsTestCode(lsTestCode.data.data);
    // }
    setLoadingDetail(false);
  };

  const getDataPath = _data => {
    let sInitial = null;
    let sHistory = [];
    let sTarget = [];

    if (!_data || !_data.length) return;

    //Mẫu chỉ nằm trên 1 vị trí và không di chuyển
    if (_data.length === 1 && _data[0].actionLocation === area.RECEIVE_SAMPLE) {
      const initialTube = getSampleInfoByArea(
        _data[0],
        _data[0].actionLocation
      );
      const tubeData = {
        ...initialTube,
        time: initialTube.actionTime,
        type: initialTube.sampleTypeId,
        fullSID: initialTube.fullSID,
        section: initialTube.actionLocation,
      };
      sTarget = [];
      sHistory = [
        {
          id: createUUID(),
          data: tubeData,
        },
      ];
      sInitial = tubeData;
    } else {
      if (
        _data[0].actionLocation === area.RECEIVE_SAMPLE &&
        _data[0].actionLocation === _data[1].actionLocation
      ) {
      } else {
        //mặc định chạy qua khu vực nhận mẫu và ly tâm
        if (_data[0].actionLocation !== area.RECEIVE_SAMPLE) {
          _data.unshift({ actionLocation: area.RECEIVE_SAMPLE });
        }
        _data.splice(1, 0, { actionLocation: area.CENTRIFUGAL });
      }

      // set cứng, cần linh động hơn
      //Tìm điểm cuối cùng chạy trong hệ để auto ra oulet khi vào hệ
      let indexInlet = _data.findIndex(_item => {
        return _item.actionLocation === area.INLET;
      });
      let indexOutlet = _data.findIndex(_item => {
        return _item.actionLocation === area.OUTLET;
      });
      let indexStock = _data.findIndex(_item => {
        return _item.actionLocation === area.STOCKYAR;
      });
      let indexDxI1 = _data.findIndex(_item => {
        return _item.actionLocation === area.DXI_2;
      });
      let indexAu1 = _data.findIndex(_item => {
        return _item.actionLocation === area.AU680_1;
      });
      let indexAu2 = _data.findIndex(_item => {
        return _item.actionLocation === area.AU680_2;
      });

      if (indexInlet > 0 && indexOutlet < 0) {
        if (indexStock < 0) {
          let maxIndex = indexInlet || 0;
          if (
            maxIndex < (indexDxI1 || 0) &&
            indexDxI1 < (indexAu1 || 0) &&
            indexDxI1 < (indexAu2 || 0)
          ) {
            maxIndex = indexDxI1;
          }
          if (maxIndex < (indexAu1 || 0) && indexAu1 < (indexAu2 || 0)) {
            maxIndex = indexAu1;
          }
          if (maxIndex < (indexAu2 || 0)) {
            maxIndex = indexAu2;
          }

          // const maxIndex = Math.max(
          //   indexDxI1,
          //   indexAu1,
          //   indexAu2,
          //   indexStock,
          //   indexInlet
          // );
          _data.splice(maxIndex + 1, 0, { actionLocation: area.OUTLET });
        }
      }

      const len = _data.length;
      let realPath = [];
      let tempArr = [];
      let totalPoints = len;

      for (let i = 0; i < len - 1; i++) {
        let pth = [];
        tempArr.push(_data[i].actionLocation);
        //Đường đi đặt biệt
        let specialRoute = isSpecialRoute(currentRouteName);
        let id = `${_data[i].actionLocation}-${_data[i + 1].actionLocation}`;
        // if (specialRoute) {
        //   pth = [
        //     _data[i].actionLocation,
        //     ...specialRoute[id],
        //     _data[i + 1].actionLocation,
        //   ];
        // }
        const isInletBefore = tempArr.find(x => x === area.INLET);
        //đã kết thúc inlet
        const isEndInlet = tempArr.find(x => x === area.STOCKYAR);
        let routeData = isRouteData(id, isInletBefore, isEndInlet);
        if (!pth.length && routeData) {
          pth = [
            _data[i].actionLocation,
            ...routeData,
            _data[i + 1].actionLocation,
          ];
        }
        // 2 điểm bắt đầu & kết thúc trùng nhau
        if (_data[i].actionLocation === _data[i + 1].actionLocation) {
          pth = [_data[i].actionLocation, _data[i + 1].actionLocation];
        }

        // Tìm đường giữa 2 điểm cho trước
        if (!pth.length) {
          pth = iDijkstra.find(
            _data[i].actionLocation,
            _data[i + 1].actionLocation
          );
        }

        const dataPth = [];

        // Xóa điểm cuối cùng vì đó là điểm đầu của đường tiếp theo
        if (realPath.length) {
          realPath.splice(-1);
        }

        pth.forEach((el, idx) => {
          if (idx === pth.length - 1) {
            dataPth.push({
              time: _data[i + 1].actionTime,
              type: _data[i + 1].sampleTypeId,
              fullSID: _data[i + 1].fullSID,
              section: el,
            });
          } else {
            dataPth.push({
              time: _data[i].actionTime,
              type: _data[i].sampleTypeId,
              fullSID: _data[i].fullSID,
              section: el,
            });
          }
        });

        realPath = realPath.concat(dataPth);
      }

      realPath.forEach((relpath, idx) => {
        if (idx === 0) {
          const initialTube = getSampleInfoByArea(relpath, relpath.section);
          sInitial = initialTube;
          sHistory = [
            {
              id: createUUID(),
              data: initialTube,
            },
          ];
        } else {
          const initialTube2 = getSampleInfoByArea(relpath, relpath.section);
          sTarget.push({
            id: createUUID(),
            data: initialTube2,
          });
        }
      });
    }

    sTarget.unshift({
      id: createUUID(),
      data: sInitial,
    });

    return sTarget;
  };

  const createPaths = (_data, currentRouteName = "") => {
    if (!_data || !_data.length) return;
    setTotalPointReal(_data.length);
    //Mẫu chỉ nằm trên 1 vị trí và không di chuyển
    if (_data.length === 1 && _data[0].actionLocation === area.RECEIVE_SAMPLE) {
      const initialTube = getSampleInfoByArea(
        _data[0],
        _data[0].actionLocation
      );
      const tubeData = {
        ...initialTube,
        time: initialTube.actionTime,
        type: initialTube.sampleTypeId,
        fullSID: initialTube.fullSID,
        section: initialTube.actionLocation,
      };
      setTarget(null);
      setHistory([
        {
          id: createUUID(),
          data: tubeData,
        },
      ]);
      setTimelineHistory([
        {
          id: createUUID(),
          data: tubeData,
        },
      ]);
      setInitial(tubeData);
      setLoading(false);
      setTotalPoint(1);
      return;
    } else {
      if (
        _data[0].actionLocation === area.RECEIVE_SAMPLE &&
        _data[0].actionLocation === _data[1].actionLocation
      ) {
      } else {
        //mặc định chạy qua khu vực nhận mẫu và ly tâm
        if (_data[0].actionLocation !== area.RECEIVE_SAMPLE) {
          _data.unshift({ actionLocation: area.RECEIVE_SAMPLE });
        }
        _data.splice(1, 0, { actionLocation: area.CENTRIFUGAL });
      }

      // set cứng, cần linh động hơn
      //Tìm điểm cuối cùng chạy trong hệ để auto ra oulet khi vào hệ
      let indexInlet = _data.findIndex(_item => {
        return _item.actionLocation === area.INLET;
      });
      let indexOutlet = _data.findIndex(_item => {
        return _item.actionLocation === area.OUTLET;
      });
      let indexStock = _data.findIndex(_item => {
        return _item.actionLocation === area.STOCKYAR;
      });
      let indexDxI1 = _data.findIndex(_item => {
        return _item.actionLocation === area.DXI_2;
      });
      let indexAu1 = _data.findIndex(_item => {
        return _item.actionLocation === area.AU680_1;
      });
      let indexAu2 = _data.findIndex(_item => {
        return _item.actionLocation === area.AU680_2;
      });

      if (indexInlet > 0 && indexOutlet < 0) {
        if (indexStock < 0) {
          let maxIndex = indexInlet || 0;
          if (
            maxIndex < (indexDxI1 || 0) &&
            indexDxI1 < (indexAu1 || 0) &&
            indexDxI1 < (indexAu2 || 0)
          ) {
            maxIndex = indexDxI1;
          }
          if (maxIndex < (indexAu1 || 0) && indexAu1 < (indexAu2 || 0)) {
            maxIndex = indexAu1;
          }
          if (maxIndex < (indexAu2 || 0)) {
            maxIndex = indexAu2;
          }

          // const maxIndex = Math.max(
          //   indexDxI1,
          //   indexAu1,
          //   indexAu2,
          //   indexStock,
          //   indexInlet
          // );
          _data.splice(maxIndex + 1, 0, { actionLocation: area.OUTLET });
        }
      }

      const len = _data.length;
      let realPath = [];
      let tempArr = [];
      let totalPoints = len;

      for (let i = 0; i < len - 1; i++) {
        let pth = [];
        tempArr.push(_data[i].actionLocation);
        //Đường đi đặt biệt
        let specialRoute = isSpecialRoute(currentRouteName);
        let id = `${_data[i].actionLocation}-${_data[i + 1].actionLocation}`;
        if (specialRoute) {
          pth = [
            _data[i].actionLocation,
            ...specialRoute[id],
            _data[i + 1].actionLocation,
          ];
        }
        const isInletBefore = tempArr.find(x => x === area.INLET);
        //đã kết thúc inlet
        const isEndInlet = tempArr.find(x => x === area.STOCKYAR);
        let routeData = isRouteData(id, isInletBefore, isEndInlet);

        if (!pth.length && routeData) {
          pth = [
            _data[i].actionLocation,
            ...routeData,
            _data[i + 1].actionLocation,
          ];
        }
        // 2 điểm bắt đầu & kết thúc trùng nhau
        if (_data[i].actionLocation === _data[i + 1].actionLocation) {
          pth = [_data[i].actionLocation, _data[i + 1].actionLocation];
        }

        // Tìm đường giữa 2 điểm cho trước
        if (!pth.length) {
          pth = iDijkstra.find(
            _data[i].actionLocation,
            _data[i + 1].actionLocation
          );
        }

        const dataPth = [];

        // Xóa điểm cuối cùng vì đó là điểm đầu của đường tiếp theo
        if (realPath.length) {
          realPath.splice(-1);
        }

        pth.forEach((el, idx) => {
          if (idx === pth.length - 1) {
            dataPth.push({
              time: _data[i + 1].actionTime,
              type: _data[i + 1].sampleTypeId,
              fullSID: _data[i + 1].fullSID,
              section: el,
            });
          } else {
            dataPth.push({
              time: _data[i].actionTime,
              type: _data[i].sampleTypeId,
              fullSID: _data[i].fullSID,
              section: el,
            });
          }
        });

        realPath = realPath.concat(dataPth);
      }

      let sInitial = null;
      let sHistory = [];
      let sTarget = [];

      realPath.forEach((relpath, idx) => {
        if (idx === 0) {
          const initialTube = getSampleInfoByArea(relpath, relpath.section);
          sInitial = initialTube;
          sHistory = [
            {
              id: createUUID(),
              data: initialTube,
            },
          ];
        } else {
          const initialTube2 = getSampleInfoByArea(relpath, relpath.section);
          sTarget.push({
            id: createUUID(),
            data: initialTube2,
          });
        }
      });
      setLoading(false);
      setInitial(sInitial);
      setHistory(sHistory);
      setTimelineHistory(sHistory);
      setTarget(sTarget);
      setTotalPoint(totalPoints);
    }
  };

  const reset = () => {
    setTarget(null);
    setHistory([]);
    setTimelineHistory([]);
    setInitial(null);
    setDistance([]);
    timeLineIdxRef.current = -1;
  };

  useEffect(() => {
    if (delayRef.current) {
      clearTimeout(delayRef);
    }
  }, [target]);

  useEffect(() => {
    getSampleRouteFlow();
  }, [filter.searchRoute]);

  const runAgain = () => {
    const newTarget = [...JSON.parse(JSON.stringify(history.slice(1)))];
    const newInitial = JSON.parse(JSON.stringify(history[0].data));
    const newHistory = [JSON.parse(JSON.stringify(history[0]))];
    setInitial(newInitial);
    setHistory(newHistory);
    setTimelineHistory(newHistory);
    setTarget(newTarget);
    setDistance([]);
    timeLineIdxRef.current = -1;
  };

  const options = [
    {
      value: "10",
      label: "Top 10 dữ liệu",
    },
    {
      value: "5",
      label: "Top 5 dữ liệu",
    },
  ];
  const selectedOption = options.find(
    option => option.value === keyTopSelectData
  );

  return (
    <div>
      {/* Thêm filter màn hình */}
      <Row className="mb-2">
        <Filter
          setError={setError}
          setPaging={setPaging}
          error={error}
          sites={sites}
          keyState={keyState}
          setKeyState={setKeyState}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          xs={24}
          filter={filter}
          setFilter={setFilter}
          onSearch={getSampleRouteFlow}
        />
      </Row>
      <Row gutter={[8, 8]}>
        <Col md={24} lg={24} xl={24} xxl={24}>
          <CustomCard
            style={{
              margin: "8px 0",
              flex: 1,
            }}
          >
            <ListSamplePath
              paging={paging}
              setPaging={setPaging}
              keyState={keyState}
              setKeyState={setKeyState}
              totalDataFlow={totalDataFlow}
              filter={filter}
              setFilter={setFilter}
              loading={isLoading}
              setIsLoading={setIsLoading}
              data={dataFlow}
              lsTestCode={lsTestCode}
              onGetDetailSampleFlow={onGetDetailSampleFlow}
              setCurrentRouteName={setCurrentRouteName}
              onSearch={getSampleRouteFlow}
              inputRef={inputRef}
              keyTopSelectData={keyTopSelectData}
              setDataTop10Calculate={setDataTop10Calculate}
              setDataTop10Percentage={setDataTop10Percentage}
              setDataTop5Calculate={setDataTop5Calculate}
              setDataTop5Percentage={setDataTop5Percentage}
              setDataNameRoute={setDataNameRoute}
              setDataTestCodeChart={setDataTestCodeChart}
            />
          </CustomCard>
        </Col>

        {/* <Col md={24} lg={10} xl={8} xxl={7}>
          <CustomCard
            style={{
              // height: "calc(100vh - 280px)",
              margin: "8px 0",
              flex: 1,
            }}
          >
            <ListDetailSamplePath
              data={dataLsSID}
              currentRouteName={currentRouteName}
              loading={loadingDetail}
              onGetDetailSampleFlow={onGetDetailSampleFlow}
              // loading={isLoading}
              onClickValue={async value => {
                reset();
                setLoading(true);
                // await getMenuTest(currentRouteName, value);
                const info = await getSIDInfoWithRouteName(
                  currentRouteName,
                  value
                );

                if (!info) {
                  setLoading(false);
                  return;
                }

                const renderData = [];
                const notFoundData = [];

                const len = info.length;

                for (let i = 0; i < len; i++) {
                  const actionLocation = mappingActionLocationToArea(
                    info[i].actionLocation
                  );

                  if (actionLocation) {
                    renderData.push({
                      ...info[i],
                      rawActionLocation: info[i].actionLocation,
                      actionLocation,
                    });
                  } else {
                    notFoundData.push(info[i]);
                  }
                }

                // Không có tiến trình để vẽ
                if (!renderData || !renderData.length) {
                  setLoading(false);
                }

                createPaths(renderData, currentRouteName);
              }}
            />
          </CustomCard>
        </Col> */}
      </Row>
      <Row gutter={[8, 8]}>
        <Col md={24} lg={17} xl={18}>
          {/* {isLoading || loading ? <LoadingBackground /> : null}
          {isLoading || loading ? <LoadingText /> : null} */}
          <CustomCard
            style={{
              height: "calc(100vh - 150px)",
              margin: "8px 0 15px",
              flex: 1,
            }}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Typography.Title
                level={4}
                className="mb-0 pt-2 px-3"
                style={{ color: "#1677ff", fontWeight: "bold" }}
              >
                Bản đồ tiến trình mẫu
              </Typography.Title>

              <div className="d-flex align-items-center">
                {showSampleNumber && (
                  <WattageConfig
                    config={wattageConfig}
                    setConfig={setWattageConfig}
                  />
                )}
                <Setting
                  config={config}
                  setConfig={setConfig}
                  isDrawing={Boolean(target)}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                // maxWidth: "1150px",
                margin: "auto",
                height: "90%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                  <MapContainer
                    imageSize={imageSize}
                    history={history}
                    target={target}
                    initial={initial}
                    width={width}
                    height={height}
                    config={config}
                    sampleNumberList={sampleNumberList}
                    setMachineSelected={setMachineSelected}
                    areaList={areaList}
                    removeTarget={target => {
                      // Delay khi di chuyển giữa các hệ
                      if (target.data.area.type === area.TYPE.SECTION) {
                        delayRef.current = setTimeout(() => {
                          removeTarget(target.id);
                        }, config[configKey.DELAY_TIME]);
                        return;
                      }
                      removeTarget(target.id);
                    }}
                  />
                )}
              </ReactResizeDetector>
            </div>
          </CustomCard>
        </Col>

        <Col md={24} lg={7} xl={6}>
          <SampleTimeLine
            loading={loading}
            data={history}
            target={target}
            totalPoint={totalPoint}
            totalPointReal={totalPointReal}
            distance={distance}
            timeLineHistory={timeLineHistory}
            runAgain={runAgain}
            dataLsAll={dataLsAll}
          />
        </Col>
      </Row>
      <>
        {showSampleFlow && (
          <>
            {lsMenu && (
              <Row className="mb-2">
                <CustomCard
                  style={{
                    // height: "calc(100vh - 280px)",
                    margin: "8px 0 15px",
                    flex: 1,
                  }}
                >
                  <ListMenuTest
                    data={lsMenu}
                    currentRouteName={currentRouteName}
                    loading={loadingDetail}
                    onGetDetailSampleFlow={onGetDetailSampleFlow}
                  />
                </CustomCard>
              </Row>
            )}
          </>
        )}
      </>
      <Row gutter={[8, 8]}>
        <Col
          md={24}
          lg={24}
          xl={24}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ width: "10rem" }}>
            <Select
              className="custom-select"
              options={options}
              placeholder="Chọn loại thống kê hiển thị"
              value={selectedOption}
              onChange={selectedOption =>
                setKeyTopSelectData(
                  selectedOption ? selectedOption.value : null
                )
              }
            />
          </div>
        </Col>
        <Col md={24} lg={24} xl={24}>
          <ChartNumTest
            windowDimensions={windowDimensions}
            keyTopSelectData={keyTopSelectData}
            dataTop10Calculate={dataTop10Calculate}
            dataTop10Percentage={dataTop10Percentage}
            dataTop5Calculate={dataTop5Calculate}
            dataTop5Percentage={dataTop5Percentage}
            dataNameRoute={dataNameRoute}
            dataTestCodeChart={dataTestCodeChart}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
