import React, { useRef, useState, useEffect } from "react";
import StatisticService from "services/Statistic";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalDetail from "./Detail/ModalDetail";
import { startOfMonth, format } from "date-fns";
import BarChart from "components/Dashboards/BarChart";
import Chart from "./../CombineStatMonthlyResult/Chart/BarChartResult";
const Index = ({ sites, filterAll }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSelectSite, setDataSelectSite] = useState([]);
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);

  const [optionsChart, setOptionsChart] = useState(null);
  const [dataChart, setDataChart] = useState(null);

  const [seriesChart, setSeriesChart] = useState([]);
  const [filter, setFilter] = useState({
    siteCode: filterAll.siteCode,
    dateFrom: filterAll.dateFrom,
    dateTo: filterAll.dateTo,
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        siteCode: filterAll.siteCode || [],
        dateFrom: format(startOfMonth(filterAll.dateFrom), "yyyy-MM-dd"),
        dateTo: format(startOfMonth(filterAll.dateTo), "yyyy-MM-dd"),
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await StatisticService.GetListStatisticStatMonthPatientService(
          params,
          requestBody
        );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      // GetDataChart(res.data);
      let labels = res.data?.map(x => x.siteName);
      let dataSeries = res.data?.map(x => x.sumCount);
      const dataChart = {
        labels,
        datasets: [
          {
            axis: "y",
            label: "Số lượng bệnh nhân",
            data: dataSeries,
            borderColor: "rgb(1, 116, 190)",
            backgroundColor: "rgb(1, 116, 190, 0.5)",
            borderWidth: 1,
            barPercentage: 1,
            barThickness: 20,
          },
        ],
      };
      setDataChart(dataChart);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  const GetDataChart = data => {
    let categories = data?.map(x => x.siteName);
    let options = {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          horizontal: true,
          columnWidth: "25%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "Dashboard Số lượng bệnh nhân", // Default chart title
      },
      xaxis: {
        categories: categories || [],
      },
      zoom: {
        enabled: true,
        type: "x",
        resetIcon: {
          offsetX: -10,
          offsetY: 0,
          fillColor: "#fff",
          strokeColor: "#37474F",
        },
        selection: {
          background: "#90CAF9",
          border: "#0D47A1",
        },
      },
    };

    setOptionsChart(options);
    let dataSeries = data?.map(x => x.sumCount);
    let series = [
      {
        name: "Số lượng bệnh nhân",
        data: dataSeries,
      },
    ];
    setSeriesChart(series);
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging]);

  useEffect(() => {
    setPaging(prev => ({ ...prev, currentPage: 1 }));
    getTableData();
  }, [filterAll, filter]);

  return (
    <>
    {
      !loading && <Chart loading={loading} data={dataChart} options={optionsChart} />
    }
      

      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setSiteCodeDetail={setSiteCodeDetail}
        setIsOpenModalDetail={setIsOpenModalDetail}
        // onSearch={() => {
        //   setPaging(prev => ({ ...prev, currentPage: 1 }));
        //   getTableData({ page: 1 });
        // }}
      />
      {isOpenModalDetail && (
        <ModalDetail
          setSiteCodeDetail={setSiteCodeDetail}
          data={siteCodeDetail}
          isOpen={isOpenModalDetail}
          onClose={() => setIsOpenModalDetail(false)}
        />
      )}
    </>
  );
};

export default Index;
