import React, { useEffect, useState } from "react";
import { Col, Row, DatePicker, Button } from "antd";

import dayjs from "dayjs";
import { Card, Label } from "reactstrap";
import Select from "react-select";
const FlowOptions = [
  { value: "0", label: "Tất cả" },
  { value: "1", label: "Hệ" },
  { value: "2", label: "Máy lẻ" },
  { value: "3", label: "Hệ + Máy lẻ" },
  { value: "4", label: "Lỗi" },
];

const Filter = ({
  filter,
  setFilter,
  onSearch,
  isLoading,
  setIsLoading,  
  sites,
  error,
  setError,
  setPaging
}) => {
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
  const { RangePicker } = DatePicker;
  const [toDate, setToDate] = useState(dayjs(filter.toDate));
  const [fromDate, setFromDate] = useState(dayjs(filter.fromDate));

  const handleSelectChange = (value, label) => {
    setInsNameOptions(value);
  };

  const handleDateChange = dates => {   
    if (dates !== null) {
      setFromDate(dayjs(dates[0]).format("YYYY-MM-DD"));
      setToDate(dayjs(dates[1]).format("YYYY-MM-DD"));
      setFilter(prev => ({
        ...prev,              
        fromDate: dayjs(dates[0]).format("YYYY-MM-DD"),
        toDate: dayjs(dates[1]).format("YYYY-MM-DD"),
      }));
    }
  };

  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={24} md={8} >
          <div className="d-flex align-items-center">
            <span style={{fontWeight: "bold" }}>
              SiteName
            </span>
            <div
              style={{
                flex: 1,
                marginLeft: "10px",
                marginRight: "10px",
                zIndex: 5,
              }}
            >
              <Select
                options={sites}
                placeholder=""
                value={filter.site}
                // style={{ width: "15rem" }}
                onChange={val => {                 
                  setFilter(prev => ({ ...prev, site: val }));                 
                  if (error) {
                    setError(null);
                  }
                }}
                isDisabled={isLoading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>
        <Col md={24} xl={8}>
          <div className="d-flex align-items-center">
            <span style={{ fontWeight: "bold" }}>
              Thời gian
            </span>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 5 }}>
              <RangePicker
                disabled={isLoading}
                // className="ml-2 mr-4"
                style={{ width: "80%", padding: "7px" }}
                defaultValue={[
                  dayjs(fromDate, dateFormatList),
                  dayjs(toDate, dateFormatList),
                ]}
                format={dateFormatList}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </Col>        
        <Col md={24} xl={4}>
          <div>
            <Button
              disabled={isLoading}
              type="primary"
              style={{ width: "7rem"}}
              onClick={() => {
                setIsLoading(true)
                // setFilter(prev => ({
                //   ...prev,              
                //   fromDate: fromDate,
                //   toDate: toDate,
                // }));
                onSearch()
              }}
            >
              {!isLoading ? "Áp dụng" : "Đang tìm..."}
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Filter;
