import React, { useEffect, useState } from "react";
import { PageContent, CustomBreadcrumb } from "components/Common";
import CombineStatMonthlyResult from './CombineStatMonthlyResult'
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SiteService from "services/Site";

const RESOURCE = ModuleIds.CombineStatMonthlyResult;

const CombineStatMonthlyResultContainer = ({ t }) => {
  const [sites, setSites] = useState([]);
  const getData = async () => {
    const res = await SiteService.getListMasterSite({
      page: 1,
      size: 1000,
      search: "",
    });
    let result = res.map(
      item =>
        (item = {
          value: item.siteCode,
          label: item.siteName,
        })
    );
    setSites(result);
  };
  useEffect(() => {
    getData();
  }, []);

  const titleTag = `Danh sách xét nghiệm`;
  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb
        breadcrumbItem={titleTag}
        resource={RESOURCE}
        icon={<i className="mdi mdi-clipboard-list"></i>}
      />
      {/* Page Body */}
    <CombineStatMonthlyResult sites={sites}/>
    </PageContent>
  );
};

CombineStatMonthlyResultContainer.propTypes = {
  t: PropTypes.any,
};

export default withTranslation(["combineStatMonthlyResultPage"])(CombineStatMonthlyResultContainer);
