import CurrencyInput from "react-currency-input-field";

const InputAutoFormat = ({
  defaultValue = undefined,
  placeholder = "",
  decimalsLimit = 0,
  groupSeparator = ",",
  decimalSeparator = ".",
  style = {},
  ...other
}) => {
  return (
    <CurrencyInput
      className="current-input"
      placeholder={placeholder}
      defaultValue={defaultValue}
      decimalsLimit={decimalsLimit}
      decimalSeparator={decimalSeparator}
      groupSeparator={groupSeparator}
      style={{
        ...style,
      }}
      {...other}
    />
  );
};

export default InputAutoFormat;
