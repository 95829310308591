import { Card, Button, Row, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import { CustomButton } from "components/Common";

const Index = ({
  filter,
  setFilter,
  isLoading,
  onSearch,
  setIsOpenModalInsertUpdate,
  setIsEdit
}) => {
  return (
    <>
      <Card className="p-3 my-2">
        <div className="w-100 d-flex justify-content-end">
          <div
            style={{ maxWidth: "600px", minWidth: "300px", marginRight: "8px" }}
          >
            <Input
              value={filter.search}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  onSearch();
                }
              }}
              placeholder="Tìm kiếm theo instrument type..."
              onChange={e =>
                setFilter(prev => ({ ...prev, search: e.target.value }))
              }
            />
          </div>
          <CustomButton
            color="success"
            onClick={() => {
              setIsEdit(false)
              setIsOpenModalInsertUpdate(true);
            }}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle"></i>
              <p className="mb-0 mx-1">Thêm mới</p>
            </div>
          </CustomButton>
        </div>
      </Card>
    </>
  );
};

export default Index;
