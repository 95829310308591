import Select from "react-select";
import { Button, Card, Col, Input, Label, Row } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { DISPLAY_MONTH_YEAR_FORMAT } from "constant";
const Index = ({
  sites = [],
  exportFilter,
  setExportFilter,
  error,
  setError,
  isLoading,
  setPaging,
}) => {
  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={4} xl={3}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">SiteName</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 5 }}>
              <Select
                options={sites}
                placeholder=""
                value={exportFilter.site}
                onChange={val => {
                  setExportFilter(prev => ({ ...prev, site: val }));
                  setPaging(prev => ({ ...prev, currentPage: 1 }));
                  if (error) {
                    setError(null);
                  }
                }}
                isDisabled={isLoading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>      
      </Row>
    </Card>
  );
};

export default Index;
