import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Typography, Radio, Spin } from "antd";
import { Card } from "reactstrap";
import Chart from "react-apexcharts";
import CustomTooltip from "./CustomTooltip";
import { customColors } from "./helper";
import { BoxLoading } from "react-loadingg";

const Index = ({ data, mode, onChangeMode, isLoading }) => {
  const { categories, series } = data || {};

  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Phân bố test trên máy theo thời gian
            </Typography.Title>
          </div>

          <div>
            <Radio.Group
              onChange={e => {
                onChangeMode(e.target.value);
              }}
              buttonStyle="solid"
              value={mode}
              style={{ width: "200px" }}
            >
              <Radio.Button value="week">Tuần</Radio.Button>
              <Radio.Button value="month">Tháng</Radio.Button>
              <Radio.Button value="quarter">Quý</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {!isLoading ? (
          <div className="d-flex justify-content-center w-100">
            <div className="w-100" style={{ maxWidth: "894px" }}>
              <Chart
                options={{
                  colors: customColors,
                  chart: {
                    type: "bar",
                    height: 350,
                    stackType: "100%",
                    stacked: true,
                    toolbar: {
                      show: true,
                    },
                    animations: {
                      enabled: (categories || []).length < 20,
                    },
                    zoom: {
                      enabled: true,
                    },
                  },
                  noData: {
                    text: data ? "Không có dữ liệu" : "Đang tải...",
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        legend: {
                          position: "bottom",
                          offsetX: -10,
                          offsetY: 0,
                        },
                      },
                    },
                  ],
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth:
                        20 +
                        60 /
                          (1 + 30 * Math.exp(-(categories || []).length / 3)) +
                        "%",
                    },
                  },
                  xaxis: {
                    categories: categories || [],
                    labels: {
                      rotate: -20,
                    },
                  },
                  legend: {
                    position: "bottom",
                  },
                  fill: {
                    opacity: 1,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    custom: function ({ dataPointIndex, w }) {
                      const data = w.globals.initialSeries;
                      const tooltipItems = data.map((el, idx) => ({
                        id: idx,
                        name: el.name,
                        value: el.data[dataPointIndex],
                        color: w.globals.colors[idx],
                      }));
                      const total = tooltipItems.reduce(
                        (acc, el) => acc + el.value,
                        0
                      );
                      const result = tooltipItems.map(el => ({
                        ...el,
                        percent: ((el.value / total) * 100).toFixed(1),
                      }));
                      return renderToStaticMarkup(
                        <CustomTooltip
                          title={`Ngày ${categories[dataPointIndex]}`}
                          data={result}
                        />
                      );
                    },
                  },
                }}
                series={series || []}
                type="bar"
                width="100%"
              />
            </div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center mt-5 mb-5"
            style={{ height: "300px" }}
          >
            <BoxLoading size="large" />
          </div>
        )}
      </Card>
    </div>
  );
};

export default Index;
