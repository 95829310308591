import Table, { getSTTCellData, formatValueToMoneyVND } from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({paging, setPaging, data, pageSize, setIsOpenModalDetail, setSiteCodeDetail}) => {
  return (
    <Table
    paging={paging}
    setPaging={setPaging}
    data={data}  
    pageSize={pageSize}
    columns={[
        {
          Header: "STT",
          accessor: "siteCode",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSTTCellData(data, paging)}
            </div>
          ),
        },
        {
          Header: "Mã bệnh viện",
          accessor: "siteCode",
          width: 150,
        },
        {
          Header: "Tên bệnh viện",
          accessor: "siteName",
          width: 350,
        },
        {
          Header: "Tống doanh số",
          accessor: "doanhSo",
          width: 250,
          Cell: ({ value }) => (   
            <div className="d-flex w-100 justify-content-end">
              {formatValueToMoneyVND(value)}
            </div>
        ),
        },
        {
            Header: "Action",            
            width: 50,
            Cell: data => (
              <Button className="d-flex w-100 justify-content-center" color="link" onClick={(e)=>{
                setSiteCodeDetail(data.original)
                setIsOpenModalDetail(true);
              }}>
                Chi tiết
              </Button>
            ),
          },        
      ]}
      disablePagination = {false}
    />
  );
};

export default Index;
