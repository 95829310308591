import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  FormGroup,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import { setCheckBox } from "./../../../store/site/actions";
//i18n
import { withTranslation } from "react-i18next";

import { Check } from "../";
import { permissionType } from "constant";
import { connect } from "react-redux";
const dataStatMonth = {
  isStatMonthlyPatient: true,
  isStatMonthlyResult: true,
  isStatMonthlyResultInstrument: true,
  isStatMonthlyResultDepartment: true,
};

const ActionGroup = ({
  id,
  onSetCheckBox,
  onSyncDatabase,
  onExport,
  onSync,
  onRefresh,
  onEdit,
  onConfirm,
  onDelete,
  onCorrectConfirm,
  resource,
  t,
  lableConfirm,
  isPrint,
  onPrint,
  isPayment,
  onPayment,
  isSync,
  isEnableCombine,
  onCombineStatMonthPatient,
  onCombineStatMonthResult,
  onCombineStatMonthlyResultLocationType,
  onCombineStatMonthlyResultInstrument,
  onExportStatMonth,
}) => {
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isOpenSubExport, setIsOpenSubExport] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState(dataStatMonth);

  const onEditHandler = e => {
    e.preventDefault();
    onEdit(e);
  };

  const onConfirmHandler = e => {
    e.preventDefault();
    onConfirm(e);
  };

  const onDeleteHandler = e => {
    e.preventDefault();
    onDelete(e);
  };
  const onRefreshHandler = e => {
    e.preventDefault();
    onRefresh(e);
  };

  const onCorrectConfirmHandler = e => {
    e.preventDefault();
    onConfirm(e);
  };

  const onSyncHandler = e => {
    e.preventDefault();
    onSync(e);
  };
  const onExportHandler = e => {
    e.preventDefault();
    onExport(e);
  };
  const onInsertDataStatMonthToTestCode = e => {
    e.preventDefault();
    onSyncDatabase(e);
  };
  const onCombineStatMonthlyPatientHandler = e => {
    e.preventDefault();
    onCombineStatMonthPatient(e);
  };
  const onCombineStatMonthlyResultHandler = e => {
    e.preventDefault();
    onCombineStatMonthResult(e);
  };
  const onCombineStatMonthlyResultLocationTypeHandler = e => {
    e.preventDefault();
    onCombineStatMonthlyResultLocationType(e);
  };
  const onCombineStatMonthlyResultInstrumentHandler = e => {
    e.preventDefault();
    onCombineStatMonthlyResultInstrument(e);
  };

  const handleCheckboxChange = (optionName, e) => {
    e.stopPropagation();
    setCheckboxValues(prevState => ({
      ...prevState,
      [optionName]: !prevState[optionName],
    }));
    onSetCheckBox(checkboxValues);
  };

  const onSubmitReportStatMonthHandler = e => {
    onSetCheckBox(checkboxValues);
    // onSetCheckBox(checkboxValues);
    e.preventDefault();
    onExportStatMonth(e);
  };

  const closeCombinePopover = () => {
    // Auto Close Popover
    const el = document.getElementById(`${id}export2`);
    if (el) {
      el.click();
    }
  };

  const closeExportPopover = () => {
    // Auto Close Popover
    const el = document.getElementById(`${id}export`);
    if (el) {
      el.click();
    }
  };

  return (
    <>
      <div className="d-inline-flex">
        {isSync == true ? (
          <Button
            size="sm"
            color="link"
            className="me-3 my-0 p-0"
            id={`${id}syncSite-done`}
          >
            <i className="mdi mdi-check font-size-24 text-success" />
            <UncontrolledTooltip
              placement="right"
              target={`${id}syncSite-done`}
            >
              Site đã được sync
            </UncontrolledTooltip>
          </Button>
        ) : (
          <Button
            size="sm"
            color="link"
            onClick={onSyncHandler}
            className="me-3 my-0 p-0"
            id={`${id}syncSite-undone`}
          >
            <i className="mdi mdi-refresh font-size-24 text-success" />{" "}
            <UncontrolledTooltip
              placement="right"
              target={`${id}syncSite-undone`}
            >
              Sync site
            </UncontrolledTooltip>
          </Button>
        )}
        <Button
          size="sm"
          color="link"
          onClick={onEditHandler}
          className="me-3 my-0 p-0"
        >
          <i className="mdi mdi-pencil font-size-24 text-primary" />
          {/* {t("Chỉnh sửa")} */}
        </Button>
        {onDelete && onDelete != undefined && (
          <Button
            size="sm"
            color="link"
            onClick={onDeleteHandler}
            className="me-3 my-0 p-0"
          >
            <i className="mdi mdi-trash-can font-size-24 text-danger" />
          </Button>
        )}
        {onExport && onExport != undefined && (
          <div>
            <Button
              size="sm"
              color="link"
              id={`${id}export`}
              className="me-3 my-0 p-0"
            >
              <i className="mdi mdi-file-export-outline font-size-24 text-success" />
            </Button>
            <UncontrolledTooltip placement="right" target={`${id}export`}>
              Xuất file báo cáo StatMonth
            </UncontrolledTooltip>
            <UncontrolledPopover
              placement="bottom"
              trigger="legacy"
              target={`${id}export`}
            >
              <PopoverBody>
                <Button
                  color="primary"
                  className="btn-hover-shine w-100"
                  onClick={e => {
                    onExportHandler(e);
                    closeExportPopover();
                  }}
                >
                  Xuất file MapTestCode
                </Button>
                <Button
                  color="success"
                  className="btn-hover-shine w-100 my-1"
                  onClick={() => setIsOpenSubExport(!isOpenSubExport)}
                >
                  Xuất báo cáo bảng StatMonths
                </Button>
                {isOpenSubExport && (
                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <input
                        id="isStatMonthlyPatient"
                        type="checkbox"
                        checked={checkboxValues.isStatMonthlyPatient}
                        onChange={e => {
                          handleCheckboxChange("isStatMonthlyPatient", e);
                        }}
                      />

                      <Label className="mb-0 mx-2">StatMonthlyPatient</Label>
                    </div>

                    <div className="d-flex align-items-center my-1">
                      <input
                        id="isStatMonthlyResult"
                        type="checkbox"
                        checked={checkboxValues.isStatMonthlyResult}
                        onChange={e => {
                          handleCheckboxChange("isStatMonthlyResult", e);
                        }}
                      />
                      <Label className="mb-0 mx-2">StatMonthlyResult</Label>
                    </div>

                    <div className="d-flex align-items-center">
                      <input
                        id="isStatMonthlyResultInstrument"
                        type="checkbox"
                        checked={checkboxValues.isStatMonthlyResultInstrument}
                        onChange={e => {
                          handleCheckboxChange(
                            "isStatMonthlyResultInstrument",
                            e
                          );
                        }}
                      />

                      <Label className="mb-0 mx-2">
                        StatMonthlyResultInstrument
                      </Label>
                    </div>

                    <div className="d-flex align-items-center my-1">
                      <input
                        id="isStatMonthlyResultDepartment"
                        type="checkbox"
                        checked={checkboxValues.isStatMonthlyResultDepartment}
                        onChange={e => {
                          handleCheckboxChange(
                            "isStatMonthlyResultDepartment",
                            e
                          );
                        }}
                      />

                      <Label className="mb-0 mx-2">
                        StatMonthlyResultDepartment
                      </Label>
                    </div>

                    <div className="w-100 d-flex justify-content-center mt-3">
                      <Button
                        color="danger"
                        size="sm"
                        onClick={e => {
                          onSubmitReportStatMonthHandler(e);
                          closeExportPopover();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        )}
        {isSync == true && (
          <Button
            color="link"
            onClick={onInsertDataStatMonthToTestCode}
            className="me-3 my-0 p-0"
            size="sm"
            id={`${id}map-testcode`}
          >
            <UncontrolledTooltip placement="right" target={`${id}map-testcode`}>
              Thêm dữ liệu statmonth vào testcode
            </UncontrolledTooltip>
            <i className="mdi mdi-database-sync-outline font-size-24 text-primary" />
          </Button>
        )}
        {isPrint == true && (
          <Check resource={resource} permission={permissionType.Print}>
            <DropdownItem href="#" onClick={onPrint} className="p-0 me-3 my-0">
              <i className="mdi mdi-printer font-size-16 text-success" />
              {t("In")}
            </DropdownItem>
          </Check>
        )}
        {isPayment == true && (
          <DropdownItem href="#" onClick={onPayment} className="p-0 me-3 my-0">
            <i className="mdi font-size-16 text-success" />
            {t("Thanh Toán")}
          </DropdownItem>
        )}
        {isEnableCombine == true && (
          <>
            <Button
              id={`${id}export2`}
              size="sm"
              type="button"
              color="link"
              className="p-0 me-3 my-0"
            >
              <UncontrolledTooltip placement="right" target={`${id}export2`}>
                Thêm dữ liệu site vào bảng master combine
              </UncontrolledTooltip>
              <i className="mdi mdi-vector-combine font-size-24 text-success" />
            </Button>
            <UncontrolledPopover
              placement="bottom"
              target={`${id}export2`}
              trigger={"legacy"}
              close
            >
              <PopoverBody>
                <Button
                  className="w-100 btn-hover-shine"
                  onClick={e => {
                    onCombineStatMonthlyPatientHandler(e);
                    closeCombinePopover();
                  }}
                  color="danger"
                >
                  StatMonthPatient
                </Button>
                <Button
                  className="w-100 my-1 btn-hover-shine"
                  onClick={e => {
                    onCombineStatMonthlyResultHandler(e);
                    closeCombinePopover();
                  }}
                  color="warning"
                >
                  StatMonthResult
                </Button>
                <Button
                  className="w-100 btn-hover-shine"
                  onClick={e => {
                    onCombineStatMonthlyResultLocationTypeHandler(e);
                    closeCombinePopover();
                  }}
                  color="success"
                >
                  StatMonthlyResultLocationType
                </Button>
                <Button
                  className="w-100 my-1 btn-hover-shine"
                  onClick={e => {
                    onCombineStatMonthlyResultInstrumentHandler(e);
                    closeCombinePopover();
                  }}
                  color="primary"
                >
                  StatMonthlyResultInstrument
                </Button>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        )}
      </div>
    </>
  );
};

ActionGroup.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
  onSync: PropTypes.func,
  onExport: PropTypes.func,
  resource: PropTypes.string,
  t: PropTypes.func,
};

ActionGroup.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  onSync: () => {},
  onExport: () => {},
};
const mapDispatchToProps = dispatch => ({
  onSetCheckBox: payload => {
    dispatch(setCheckBox(payload));
  },
});
// export default withTranslation(["common"])(ActionGroup);
export default connect(
  null,
  mapDispatchToProps
)(withTranslation(["common"])(ActionGroup));
