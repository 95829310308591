import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import * as moment from "moment"
import { BASE_URL } from "helpers/url_helper"

const prefix = `${BASE_URL}/samplepath`

const fetchAllDataSampleTypeRouteApi = async (data) => {
  return await post(`${prefix}/get-list-route`, data);
};

const fetchAmountTestCodeInActionLocation = async (data) => {
  let q = spreadSearchQuery(data);
  return await get(`${prefix}/GetAmountTestCodeInActionLocation?${q}`);
}

const fetchDetailTestCodeInActionLocation = async (data, siteCode) => {
  return await post(`${prefix}/GetDetailTestCodeInActionLocation?siteCode=${siteCode}`, data);
}

const fetchAllDetailDataSampleTypeRoute = async (data) => {
  return await post(`${prefix}/get-detail-route`, data);
};
 
const fetchSampleInfo = async (data) => {
  return await post(`${prefix}/get-action-route`, data);
};
const fetchSampleInfoAll = async (data) => {
  return await post(`${prefix}/get-all-route`, data);
};
const fetchTestCodeSamplesAllRoute = async (data) => {
  return await post(`${prefix}/get-testcode-all-route`, data);
};

const fetchTestCodeInfo = async (data) => {
  let q = spreadSearchQuery(data);
  return await get(`${prefix}/GetDetailSamples_TestCode?${q}`);
};

const fetchMenuInfo = async (data) => {
  let q = spreadSearchQuery(data);
  return await get(`${prefix}/GetMenuTestSamples?${q}`);
};

export {
  fetchSampleInfo,
  fetchSampleInfoAll,
  fetchAllDetailDataSampleTypeRoute,
  fetchAllDataSampleTypeRouteApi,
  fetchTestCodeInfo,
  fetchMenuInfo,
  fetchAmountTestCodeInActionLocation,
  fetchDetailTestCodeInActionLocation,
  fetchTestCodeSamplesAllRoute
};
