import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

import { Check } from "../"
import { permissionType } from "constant"

const InlineAction = ({ onRefresh,onEdit, onConfirm, onDelete, onCorrectConfirm, resource, t, lableConfirm
  , isPrint,onPrint, isPayment,onPayment }) => {
  const onEditHandler = e => {
    e.preventDefault()
    onEdit(e)
  }

  const onConfirmHandler = e => {
    e.preventDefault()
    onConfirm(e)
  }

  const onDeleteHandler = e => {
    e.preventDefault()
    onDelete(e)
  }
  const onRefreshHandler = e => {
    e.preventDefault()
    onRefresh(e)
  }

  const onCorrectConfirmHandler = e => {
    e.preventDefault()
    onConfirm(e)
  }

  return (
    // <Check
    //   resource={resource}
    //   permissions={[permissionType.U, permissionType.D, permissionType.Confirm, permissionType.S, permissionType.Print]}
    // >
    <>
      
      <UncontrolledDropdown>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
          onClick={e => e.preventDefault()}
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {onConfirm && onConfirm != undefined && <Check resource={resource} permission={permissionType.Confirm}>
            <DropdownItem href="#" onClick={onConfirmHandler}>
              <i className="mdi mdi-check-underline font-size-16 text-success me-1" />{" "}
              {lableConfirm || t("Xác nhận khách đến")}
            </DropdownItem>
          </Check>
          }
          {onCorrectConfirm && onCorrectConfirm != undefined && <Check resource={resource} permission={permissionType.Confirm}>
            <DropdownItem href="#" onClick={onCorrectConfirm}>
              <i className="mdi mdi-check-underline font-size-16 text-success me-1" />{" "}
              {t("Xác nhận đúng khách")}
            </DropdownItem>
          </Check>
          }
          {/* <Check resource={resource} permission={permissionType.U}> */}
            <DropdownItem href="#" onClick={onRefreshHandler}>
              <i className="mdi mdi-refresh font-size-16 text-success me-1" />{" "}
              {t("Làm mới")}
            </DropdownItem>
            <DropdownItem href="#" onClick={onEditHandler}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
              {t("Chỉnh sửa")}
            </DropdownItem>
          {/* </Check> */}

          { onDelete && onDelete != undefined &&
            // <Check resource={resource} permission={permissionType.D}>
            <DropdownItem href="#" onClick={onDeleteHandler}>
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
              {t("Xóa")}
            </DropdownItem>
          // </Check>
        }
          {isPrint==true &&
            <Check resource={resource} permission={permissionType.Print}>
            <DropdownItem href="#" onClick={onPrint}>
              <i className="mdi mdi-printer font-size-16 text-success me-1" />{" "}
              {t("In")}
            </DropdownItem>
            </Check>

          
          }
          {isPayment==true &&
            <DropdownItem href="#" onClick={onPayment}>
              <i className="mdi font-size-16 text-success me-1" />{" "}
              {t("Thanh Toán")}
            </DropdownItem>
          
          }
        </DropdownMenu>
      </UncontrolledDropdown>
      
      </>
    // </Check>
  )
}

InlineAction.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
  resource: PropTypes.string,
  t: PropTypes.func,
}

InlineAction.defaultProps = {
  onEdit: () => { },
  onDelete: () => { },
}

export default withTranslation(["common"])(InlineAction)
