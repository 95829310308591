import React from "react";

const Index = () => {
  return (
    <>
      <div style={{maxHeight: "1000px", marginTop: 10}}>
        <iframe
          src="https://app.powerbi.com/view?r=eyJrIjoiOWMxMGRhZDMtYWQzOC00NmRhLTljNjUtMDAxNTA5OTM3ZGJlIiwidCI6ImRiMzVhYWFhLTA1MWYtNDgyMC05NGExLTE2NGRkZjAwZDBkMiIsImMiOjEwfQ%3D%3D"
          width="100%"
          height="840px" 
        ></iframe>
      </div>
    </>
  );
};              

export default Index;
