import axios from "axios";
import { connect } from "react-redux";
// import { toast } from "react-toastify";

export const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SITE_SERVER_RELATIVE_URL}`

const apiDefault = axios.create({
  //baseURL: 'http://192.168.10.53:1212/api',
  baseURL:BASE_URL,
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
});
const methodConst = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
  options: "options",
};
class ApiCaller {
  static state = ApiCaller.getDefaultState();
  static getDefaultState() {
    return {
 
      transition: "bounce",
      type: "error",
      disableAutoClose: false,
    };
  }
  static async get(url, params = null, customConfig = null, isUseToken = true) {
    return ApiCaller.callApi({
      url,
      method: methodConst.get,
      data: null,
      params,
      customConfig,
      isUseToken
    });
  }
  static async post(url, data, customConfig = null, isUseToken = true) {
    return await ApiCaller.callApi({
      url,
      method: methodConst.post,
      data,
      params: null,
      customConfig,
      isUseToken
    });
  }
  static async put(url, data, customConfig = null, isUseToken = true) {
    return await ApiCaller.callApi({
      url,
      method: methodConst.put,
      data,
      params: null,
      customConfig,
      isUseToken
    });
  }
  static async delete(url, data = null, customConfig = null, isUseToken = true) {
    return await ApiCaller.callApi({
      url,
      method: methodConst.delete,
      data,
      params: null,
      customConfig,
      isUseToken,
    });
  }
  static async options(url) {
    return await ApiCaller.callApi({url, method: methodConst.options});
  }

  static async callApi({
    url,
    method,
    data = null,
    params = null,
    customConfig = null,
    isUseToken,
  }) {
    //handle config common
    let config = {};
    if (customConfig != null && customConfig !== undefined) {
      config = customConfig;
    }
    config.url = url;
    config.method = method;
    if (params != null) {
      config.params = params;
    }
    if (
      data != null &&
      (method === methodConst.post ||
        method === methodConst.put ||
        method === methodConst.delete)
    ) {
      config.data = data;
    }
    //Gắn token khi gọi api
    if (isUseToken) {
      config.headers = { ...(config.headers || {}), Authorization: "Bearer " + global.localStorage.token };
    }

    //Call api
    return await apiDefault
      .request(config)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log(error)
        return Promise.reject(error);
      })
      .finally(() => {
        setTimeout(() => {
          // toast.dismiss();
        }, [3000]);
      });
  }
}

const mapStateToProps = state => ({
  global: state.global,
});
export default connect(mapStateToProps)(ApiCaller);
