export const DEFAULT_PAGE_SIZE = 20;
export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const TEXT_COLOR_FOCUSED = "#FFF";
export const BACKGROUND_COLOR_FOCUSED = "rgb(53, 175, 254)";

export const secondsToMinutes = (seconds) => {
  var minutes = seconds / 60; 
  return minutes.toFixed();
};
export const MIN_ROW = 10;

export const convertSecondToDisplayTime = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;
  
    // If you want strings with leading zeroes:
    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return hours + ":" + minutes + ":" + seconds;
  };

  export function createUUID() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

export function dataURLtoFile(dataurl, filename) {
    return new Promise((resolve, reject) => {
        fetch(dataurl)
            .then(res => res.blob())
            .then(blob => {

                return resolve(new File([blob], filename, { type: "image/png" }))

            })
    })
}