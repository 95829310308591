import React from "react"
import { PageContent, CustomBreadcrumb } from "components/Common";
import Sites from "./Sites";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"

const RESOURCE = ModuleIds.Site;

const SiteContainer = ({ t }) => {
    const titleTag = t("Danh sách Site")
    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={titleTag}
                resource={RESOURCE}
                icon={<i className="bx bx-file" />}
            />
            {/* Page Body */}
            <Sites />
        </PageContent >
    )
}

SiteContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["sitePage"])(SiteContainer)