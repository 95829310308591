import {
  GetListMasterInstrumentTypesApi,
  DeleteMasterInstrumentTypeApi,
  InsertMasterInstrumentTypeApi,
  UpdateMasterInstrumentTypeApi,
  GetLisLabelValueMasterInstrumentApi
} from "helpers/app-backend/masterinstrumenttypebackend_helpers";

import HandleReponse from "utils/handleReponse";

const GetListMasterInstrumentTypesService = async paramters => {
  try {
    const res = await GetListMasterInstrumentTypesApi(paramters);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const InsertMasterInstrumentTypeService =  async (paramters, reqBody) => {
  let result;
  try {
    const res = await InsertMasterInstrumentTypeApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse_v2(err.response);
  }
  return result;
};

const UpdateMasterInstrumentTypeService =  async (paramters, reqBody) => {
  let result;
  try {
    const res = await UpdateMasterInstrumentTypeApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const DeleteMasterInstrumentTypeService =  async (paramters, reqBody) => {
  let result;
  try {
    const res = await DeleteMasterInstrumentTypeApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const GetLisLabelValueMasterInstrumentService = async () => {
  try {
    const res = await GetLisLabelValueMasterInstrumentApi();
    return res;
  } catch (err) {
    return err;
  }
};

export default {
  GetListMasterInstrumentTypesService,
  InsertMasterInstrumentTypeService,
  DeleteMasterInstrumentTypeService,
  UpdateMasterInstrumentTypeService,
  GetLisLabelValueMasterInstrumentService
};
