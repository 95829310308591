import Select from "react-select";
import { Button, Card, Col, Input, Label, Row } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { DISPLAY_MONTH_YEAR_FORMAT } from "constant";
const Index = ({
  sites = [],
  exportFilter,
  setExportFilter,
  reportTypesList = [],
  error,
  setError,
  isLoading,
  setIsExportFile,
  setPaging,
  filter,
  setFilter,
  onAutoPullData,
  onUpdateFromGoogleSheetFile,
}) => {
  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={4} xl={3}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">SiteName</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 5 }}>
              <Select
                options={sites}
                placeholder=""
                value={exportFilter.site}
                onChange={val => {
                  setExportFilter(prev => ({ ...prev, site: val }));
                  setPaging(prev => ({ ...prev, currentPage: 1 }));
                  if (error) {
                    setError(null);
                  }
                }}
                isDisabled={isLoading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>
        <Col sm={12} md={2} xl={2}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">StatMonth</Label>

            <div style={{ width: "95px" }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={
                  filter.dateFrom.getFullYear() == 1 ? "" : filter.dateFrom
                }
                onChange={date => {
                  setFilter(prev => ({ ...prev, dateFrom: date }));
                  if (error) {
                    setError(null);
                  }
                }}
                showMonthYearPicker
                disabled={isLoading}
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    // onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>

        <Col sm={12} md={4} xl={4}>
          <div className="d-flex align-items-center">
            <Button
              color="success"
              disabled={error || isLoading}
              className="btn-hover-shine"
              style={{ height: "40px" }}
              onClick={() => {
                onAutoPullData();
              }}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-download"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mb-0">Đồng bộ data</p>
              </div>
            </Button>
            <Button
              color="warning"
              disabled={error || isLoading}
              className="btn-hover-shine mx-2"
              style={{ height: "40px" }}
              onClick={() => {
                onUpdateFromGoogleSheetFile();
              }}
            >
              <div className="d-flex align-items-center">
                <i className="mdi mdi-update" style={{ fontSize: "1rem" }}></i>
                <p className="mb-0">Update</p>
              </div>
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
