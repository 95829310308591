import DrawManager from "./DrawManager";
import AreaManager from "./AreaManager";
import ConfigManager from "./ConfigManager";
import {
  HIT_BOX_COLOR,
  IS_RENDER_HIT_BOX,
  area,
  configKey,
} from "../constants";

export const getRealPosition = (pos, ratio) => Math.round(ratio * pos);

const MapBuilder = (function () {
  let instance;

  // ******************* TỈ LỆ ẢNH THẬT SO VỚI VÙNG VIEWPORT  *******************
  function ratio() {
    var ratio = 0;
    return {
      setRatio: function (x) {
        ratio = x;
      },
      getRatio: function () {
        return ratio;
      },
    };
  }

  // ******************* KHỞI TẠO *******************
  function init() {
    return {
      // VẼ MAP VÀ HIGHT-LIGHT NHỮNG VÙNG QUAN TRỌNG
      initialMapAndBreakPoint: function (
        map,
        mWidth,
        mHeight,
        sectionIdTypes = [], // Vị trí mẫu đã đi qua (Không tính HIT_BOX)
        sampleNumberList, // Danh sách số lượng mẫu đang phân tích trên từng máy
        callback
      ) {
        const iAreaManager = AreaManager.getInstance();
        const iConfig = ConfigManager.getInstance();
        const config = iConfig.getConfig();

        instance.asyncDrawImage(
          map,
          0,
          0,
          mWidth,
          mHeight,
          () => {
            iAreaManager
              .getAreas()
              .forEach(({ x, y, w, h, type, id, name, sampleNumberX, sampleNumberY }) => {
                const isRenderHightLight =
                  sectionIdTypes.includes(id) &&
                  config[configKey.RENDER_SECTION_HIGHTLIGHT_BACKGROUND];

                if (type === area.TYPE.SECTION) {
                  if (
                    !isRenderHightLight &&
                    config[configKey.RENDER_SECTION_BACKGROUND]
                  ) {
                    instance.drawRectangle(
                      x,
                      y,
                      w,
                      h,
                      config[configKey.SECTION_BACKGROUND]
                    );
                  }

                  if (config[configKey.RENDER_SECTION_TEXT]) {
                    instance.drawText(
                      name,
                      x + w / 2,
                      y + h / 2,
                      config[configKey.SECTION_TEXT_COLOR],
                      config[configKey.FONT_SIZE]
                    );
                  }
                } else if (type === area.TYPE.HIT_BOX && IS_RENDER_HIT_BOX) {
                  instance.drawRectangle(x, y, w, h, HIT_BOX_COLOR);
                  instance.drawText(
                    id,
                    x + 10,
                    y + 35,
                    HIT_BOX_COLOR,
                    config[configKey.FONT_SIZE]
                  );
                }

                if (isRenderHightLight) {
                  instance.drawRectangle(
                    x,
                    y,
                    w,
                    h,
                    config[configKey.SECTION_HIGHTLIGHT_BACKGROUND]
                  );
                }

                if(config[configKey.RENDER_SAMPLE_NUMBER]) {
                  if (sampleNumberList[id] && sampleNumberX) {
                    instance.drawTextInsideCircle({
                      x: sampleNumberX,
                      y: sampleNumberY,
                      number: sampleNumberList[id].number.toString(),
                      bgColor: sampleNumberList[id].isWarning
                        ? config[configKey.SAMPLE_NUMBER_WARNING_BG]
                        : config[configKey.SAMPLE_NUMBER_NORMAL_BG],
                      radius: config[configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER] / 2,
                      fontSize: config[configKey.SAMPLE_NUMBER_FONT_SIZE],
                      color: config[configKey.SAMPLE_NUMBER_COLOR],
                      borderColor: config[configKey.SAMPLE_NUMBER_BORDER_COLOR],
                    });
                  }
                }
              });
          },
          () => {
            callback();
          }
        );
      },
    };
  }

  // ******************* VẼ *******************
  function draw() {
    return {
      // VẼ HÌNH ẢNH
      drawImage: function (src, x, y, w, h) {
        DrawManager.drawImage({
          src: src,
          x: getRealPosition(x, instance.getRatio()) - Math.round(w / 2), //vẽ từ tâm hình ảnh
          y: getRealPosition(y, instance.getRatio()) - Math.round(h / 2), //vẽ từ tâm hình ảnh
          width: w,
          height: h,
        });
      },

      // VẼ CHỮ
      drawText: function (text, x, y, color, fontSize, additional = {}) {
        const additionalPos = { x: 0, y: 0, ...additional };

        DrawManager.drawText({
          x: getRealPosition(x, instance.getRatio()) + additionalPos.x,
          y: getRealPosition(y, instance.getRatio()) + additionalPos.y,
          text,
          style: { color, fontSize },
        });
      },

      // VẼ CHỮ NẰM TRONG HÌNH TRÒN (BADGE)
      drawTextInsideCircle: function ({
        x,
        y,
        radius,
        number,
        bgColor,
        fontSize,
        color,
        borderColor,
      }) {
        DrawManager.drawTextInsideCircle({
          x: getRealPosition(x, instance.getRatio()),
          y: getRealPosition(y, instance.getRatio()),
          radius,
          number,
          bgColor,
          fontSize,
          color,
          borderColor,
        });
      },

      // VẼ HÌNH BẤT ĐỒNG BỘ (VD: DÙNG VẼ BACKGROUND TRƯỚC, SAU ĐÓ MỚI VẼ NỘI DUNG)
      asyncDrawImage: function (src, x, y, w, h, callback1, callback2) {
        DrawManager.asyncDraw(src, x, y, w, h)
          .then(() => {
            callback1();
          })
          .then(() => {
            callback2();
          })
          .catch((error) => {
            console.log(error.message);
          });
      },

      // VẼ HÌNH VUÔNG
      drawRectangle: function (x, y, w, h, color) {
        DrawManager.drawRect(
          getRealPosition(x, instance.getRatio()),
          getRealPosition(y, instance.getRatio()),
          getRealPosition(w, instance.getRatio()),
          getRealPosition(h, instance.getRatio()),
          color
        );
      },

      // VẼ ĐƯỜNG THẲNG
      drawLine: function (x1, y1, x2, y2, stroke, width) {
        DrawManager.drawLine(
          getRealPosition(x1, instance.getRatio()),
          getRealPosition(y1, instance.getRatio()),
          getRealPosition(x2, instance.getRatio()),
          getRealPosition(y2, instance.getRatio()),
          stroke,
          width
        );
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance)
        instance = {
          ...init(),
          ...ratio(),
          ...draw(),
        };
      return instance;
    },
  };
})();

export default MapBuilder;
