import {
  ExportMapInstrumentERPNull as ExportMapInstrumentERPNullApi,
  ExportMapInstrumentFull as ExportMapInstrumentFullApi,
  ExportSMRIMapInstrument as ExportSMRIMapInstrumentApi,
} from "helpers/app-backend/export_helpers";
import {
    ExportMapTestCodeData as ExportMapTestCodeDataApi
} from "helpers/app-backend/datacollection_helpers";
const ExportMapInstrumentERPNull = async ({siteCode}) => {
  try {
    const res = await ExportMapInstrumentERPNullApi(siteCode);
    return res;
  } catch (err) {
    return null;
  }
};

const ExportMapInstrumentFull = async ({siteCode}) => {
  try {
    const res = await ExportMapInstrumentFullApi(siteCode);
    return res;
  } catch (err) {
    return null;
  }
};

const ExportSMRIMapInstrument = async ({siteCode}) => {
  try {
    const res = await ExportSMRIMapInstrumentApi(siteCode);
    return res;
  } catch (err) {
    return null;
  }
};

const ExportMapTestCodeData = async ({siteCode}) => {
  try {
    const res = await ExportMapTestCodeDataApi(siteCode);
    return res;
  } catch (err) {
    return null;
  }
};
export default {
  ExportMapInstrumentERPNull,
  ExportMapInstrumentFull,
  ExportSMRIMapInstrument,
  ExportMapTestCodeData
};
