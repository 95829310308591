import Select from "react-select";
import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import { DISPLAY_MONTH_YEAR_FORMAT } from "constant";
import DatePicker, { registerLocale } from "react-datepicker";
import { InputAutoFormat, CustomSelect } from "components/Common";
import vi from "date-fns/locale/vi";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect, useState } from "react";

const Index = ({
  sites = [],
  filter,
  setFilter,
  error,
  onSearch,
  setError,
  isLoading,
  setPaging,
  selectValue,
  setSelectValue,
}) => {
  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col md={6} lg={6} xl={5}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              SiteCode
            </Label>
            <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
              <Input
                value={filter.siteCode}
                disabled={isLoading}
                onChange={e => {
                  setFilter(prev => ({
                    ...prev,
                    siteCode: e.target.value,
                  }));
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col md={6} lg={6} xl={5} className="mb-2">
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              Thời gian
            </Label>
            <div style={{ width: "125px" }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={
                  filter.statMonthFrom.getFullYear() == 1
                    ? ""
                    : filter.statMonthFrom
                }
                onChange={date => {
                  if (date) {
                    setFilter(prev => ({ ...prev, statMonthFrom: date }));
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      statMonthFrom: new Date("0001-01-01"),
                    }));
                  }
                }}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                disabled={isLoading}
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
            <i className="bx bxs-right-arrow"></i>
            <div style={{ width: "125px" }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={
                  filter.statMonthTo.getFullYear() == 1
                    ? ""
                    : filter.statMonthTo
                }
                onChange={date => {
                  if (date) {
                    setFilter(prev => ({ ...prev, statMonthTo: date }));
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      statMonthTo: new Date("0001-01-01"),
                    }));
                  }
                  if (error) {
                    setError(null);
                  }
                }}
                disabled={isLoading}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          {error && error.date ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.date}
            </p>
          ) : null}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} lg={6} xl={5}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              MasterTestcode
            </Label>
            <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
              <Input
                value={filter.masterTestcode}
                disabled={isLoading}
                onChange={e => {
                  setFilter(prev => ({
                    ...prev,
                    masterTestcode: e.target.value,
                  }));
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col md={6} lg={6} xl={5}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              CommonName
            </Label>
            <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
              <Input
                value={filter.commonName}
                disabled={isLoading}
                onChange={e => {
                  setFilter(prev => ({
                    ...prev,
                    commonName: e.target.value,
                  }));
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col md={12} lg={12} xl={2}>
          <Button
            color="primary"
            className="btn-hover-shine"
            onClick={onSearch}
            disabled={isLoading}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-search mr-1" style={{ fontSize: "1rem" }}></i>
              <p className="mx-1 mb-0">
                {isLoading ? "Đang tìm.." : "Tìm kiếm"}
              </p>
            </div>
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} lg={6} xl={5}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              MasterInstrumentID
            </Label>
            <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
              <Input
                value={filter.masterInstrumentID}
                disabled={isLoading}
                onChange={e => {
                  setFilter(prev => ({
                    ...prev,
                    masterInstrumentID: e.target.value,
                  }));
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col md={6} lg={6} xl={5}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              MasterCategoryCode
            </Label>
            <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
              <Input
                value={filter.masterCategoryCode}
                disabled={isLoading}
                onChange={e => {
                  setFilter(prev => ({
                    ...prev,
                    masterCategoryCode: e.target.value,
                  }));
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
