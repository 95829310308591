const textFieldTypes = {
  TEXT: "TEXT",
  TEXTAREA: "TEXTAREA",
  CHECKBOX: "CHECKBOX",
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
  DATE: "DATE",
  NUMBER: "NUMBER",
  USER: "USER",
  DEPARTMENT: "DEPARTMENT",
  COMPANY: "COMPANY",
  PROFILE: "PROFILE",
  GROUP: "GROUP",
  TAGS: "TAGS",
};

const statusTypes = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

const bookingStatus = {
  WAITING: "Waiting",
  ARRIVED: "Arrived",
  CANCELED: "Cancel",
};

const parameterCode = {
  ERP_CUSTOMERS: "ERP_CUSTOMERS",
  SITES: "SITES",
  PARAMETER_TYPE: "L001",
  LANGUAGE_CODE: "L002",
  COUNTRY_CODE: "L003",
  PROVINCE_CODE: "L004",
  DISTRICT_CODE: "L005",
  WARD_CODE: "L006",
  DEPARTMENT_TYPE_CODE: "L007",
  USER_STATUS_CODE: "L008",
  TITLE_CODE: "L009",
  PARTY_TYPE_CODE: "L013",
  FIELD_CODE: "L014",
  FIELD_TYPE: "L015",
  CONTACT_ROLE_CODE: "L016",
  ADDRESS_TYPE: "L017",
  COMPANIES: "COMPANIES",
  DEPARTMENTS: "DEPARTMENTS",
  ROLES: "ROLES",
  EMPLOYEES: "EMPLOYEES",
  CATEGORYS: "CATEGORYS",
  PRODUCTS: "PRODUCTS",
  COMBOS: "COMBOS",
  USERS: "USERS",
  PATIENTS: "PATIENTS",
  PROFILE: "PROFILE", // PARTIES
  PROFILES: "PROFILES", // PROFILE Management
  PARAMETER: "PARAMETER",
  ORGANIZATIONS: "ORGANIZATIONS",
  PHYSICIANS: "PHYSICIANS",
  INDIVIDUALS: "INDIVIDUALS",
  TEST_TYPES: "L101",
  TEST_CATEGORIES: "L103",
  SAMPLE_TYPES: "L102",
  TESTPROFILE_TYPE_CODE: "L105",
  TESTPROFILE_CATEGORY_CODE: "L103",
  TESTPROFILE_SAMPLE_TYPE_CODE: "L102",
  TESTPROFILE: "TESTPROFILE",
  TESTPROFILE_TEST: "TESTPROFILE_TEST",
  TESTPROFILE_SUB_CATEGORY_CODE: "L104",
  INSTRUMENT_TYPES: "INSTRUMENT_TYPES",
  HISCONNECTOR: "HISCONNECTOR",
  PROTOCOL: "L109",
  CONNECTION_TYPE: "L110",
  HIS_CODE: "H",
  MAPPING_CODE: "M",
  HIS_MAPPING_KEY: "HIS_MAPPING_KEY",
  LIS_MAPPING_KEY: "LIS_MAPPING_KEY",
  LIS_PROFILE_MAPPING_KEY: "LIS_PROFILE_MAPPING_KEY",
  INSTRUMENT: "INSTRUMENT",
  GENDER: "L018",
  AGE_TYPE: "L108",
  ADDITIONAL_CONFIG: "L106",
  RESULT_TIME_EXTRA_CODE: "L107",
  SEX: "SEX",
  CUSTOMERTYPE: "CUSTOMERTYPE",
  CUSTOMERLEVEL: "CUSTOMERLEVEL",
  POSITION: "POSITION",
  BookingStatisticS: "BookingStatisticS",
  BookingTable: "BookingTable",
  BookingUser: "BookingUser",
  LINKED: "LINKED",
  AREAS: "AREAS",
  INPUTTYPE: "INPUTTYPE",
  OUTPUTTYPE: "OUTPUTTYPE",
  LOCATION: "LOCATION",
  TYPE_SITE: "TYPE_SITE",
};

const resourceType = {
  m2: "m2",
  m3: "m3",
  main: "main",
  Page: "Page",
  Tab: "Tab",
  BasicSetup: "BasicSetup",
  Other: "Other",
};

const permissionType = {
  C: "Create",
  R: "Read",
  U: "Update",
  D: "Delete",
  E: "Export",
  S: "See",

  //Booking
  Confirm: "Confirm",
  UpdateTable: "UpdateTable",

  //DS Bàn
  ChangeTable: "ChangeTable",
  Order: "Order",
  Cancel: "Cancel",
  Payment: "Payment",
  PrintBill: "PrintBill",

  //DS Order
  Print: "Print",
};

const ModuleIds = {
  Company: "Company",
  Department: "Department",
  Customer: "Customer",
  Role: "Role",
  RoleResource: "RoleResource",
  RolePemisssion: "RolePemisssion",
  User: "User",
  Employee: "Employee",
  Parameter: "Parameter",
  ParameterDetail: "ParameterDetail",
  ParameterCode: "ParameterCode",
  Profile: "Profile",
  Individual: "Individual",
  Organization: "Organization",
  Physician: "Physician",
  FastReport: "FastReport",
  Test: "Test",
  TestProfile: "TestProfile",
  Interface: "Interface",
  Machine: "Machine",
  TestConfig: "TestConfig",
  Connector: "Connector",
  Booking: "Booking",
  ListBooking: "ListBooking",
  OrderDetail: "OrderDetail",
  Input: "Input",
  Debt: "Debt",
  Site: "Site",
  Instrument: "Instrument",
  MasterInstrument: "MasterInstrument",
  MasterTestCode: "MasterTestCode",
  CombineStatMonthlyResult: "CombineStatMonthlyResult",
  CombineStatMonthlyPatient: "CombineStatMonthlyPatient",
  CombineStatMonthlyResultByCate: "CombineStatMonthlyResultByCate",
  CombineStatMonthlyResultByGroupTest: "CombineStatMonthlyResultByGroupTest",
  CombineStatMonthlyResultLocationType: "CombineStatMonthlyResultLocationType",
  CombineStatMonthlyResultInstrument: "CombineStatMonthlyResultInstrument",
  CombineStatMonthlyPatientByYear: "CombineStatMonthlyPatientByYear",
  CombineStatMonthlyResultByYear: "CombineStatMonthlyResultByYear",
  CombineStatMonthlyResultInstrumentByGroup:
    "CombineStatMonthlyResultInstrumentByGroup",
  CombineStatMonthlyResultLocationTypeSaleByLocalLocation:
    "CombineStatMonthlyResultLocationTypeSaleByLocalLocation",
  CombineStatMonthlyResultInstrument: "CombineStatMonthlyResultInstrument",
  MasterInstrumentType: "MasterInstrumentType",
  MasterGroupTest: "MasterGroupTest",
  Statistic: "Statistic",
  Report: "Report",
  ERPProduct: "ERPProduct",
  MapTestCode: "MapTestCode",
  MasterParentTest:"MasterParentTest",
  DataHospitalFile:"DataHospitalFile",
  SamplePath:"SamplePath",
  AutoSystem:"AutoSystem",
  MachineSystem:"MachineSystem",
  SystemEvaluate:"SystemEvaluate"
};

const DefaultMenuDisplayInfo = {
  icon: "bx bx-border-inner",
  color: "#ffc107",
};

const PartyType = {
  1: "Individual",
  2: "Organization",
};

const PartyTypeText = {
  Individual: 1,
  Organization: 2,
};

const InternalList = {
  0: "External",
  1: "Internal",
};

const ProfileId = {
  Physician: 7,
};

const TestProfileType = {
  1: "PROFILE",
  2: "GROUP",
};

const TestProfileTypeText = {
  PROFILE: 1,
  GROUP: 2,
};

const TestProfileStatus = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
};

const DefaultValue = {
  COUNTRY: "VN",
};

const MappingStatus = {
  UNMAPED: "Unmaped",
  MAPPED: "Mapped",
};

export {
  textFieldTypes,
  statusTypes,
  parameterCode,
  resourceType,
  permissionType,
  ModuleIds,
  DefaultMenuDisplayInfo,
  PartyType,
  PartyTypeText,
  InternalList,
  ProfileId,
  TestProfileType,
  TestProfileStatus,
  TestProfileTypeText,
  DefaultValue,
  MappingStatus,
  bookingStatus,
};
