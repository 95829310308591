import React, {useEffect, useState} from "react"
import { PageContent, CustomBreadcrumb } from "components/Common";

import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import InstrumentSurveys from "./InstrumentSurvey";
import SiteService from 'services/Site';

const RESOURCE = ModuleIds.Instrument;

const InstrumentContainer = ({ t }) => {
    const titleTag = t("Khớp mã InstrumentSurvey");
    const [sites, setSites] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const res = await SiteService.getLabelAndValueSite({
                page: 1,
                size: 1000,
                search: "",
            });
            setSites(res.data)
        }
        getData();
    }, []);

    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={titleTag}
                resource={RESOURCE}               
                icon={<i className='bx bx-link'></i>}
            />
            {/* Page Body */}
            <InstrumentSurveys sites={sites} />          
        </PageContent >
    )
}

InstrumentContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["mapInstrumentPage"])(InstrumentContainer)