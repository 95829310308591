import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/combine-instrument`

const GetListCombineStatMonthlyResultInstrument = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/ls-intrument-price?${q}`,reqBody)
}

export {
    GetListCombineStatMonthlyResultInstrument
}