import { useEffect, useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import classnames from "classnames"

import { getI18nextLng, isEmptyArray } from "helpers/utilities"
import { useDetectedChanges } from "helpers/hooks"
import React from "react"
import { parameterCode } from "constant/utility"
import { Label } from "reactstrap"
import { RollbackButton } from "."
import { isEmpty } from "lodash"

const CustomSelect = ({
  value,
  name,
  required,
  errorMessage,
  code,
  group,
  onChange,
  isDependent,
  isMulti,
  portal,
  label,
  detected,
  options,
  readOnly,
  searchQuery,
}) => {
  const [items, setItems] = useState([])
  const [defaultValue, setDefaultValue] = useState()
  const inputRef = useRef()
  const [valueInput, isChanged] = useDetectedChanges(
    value,
    isMulti ? defaultValue : defaultValue?.[0]
  )

  const onUndoHandler = () => {
    onChangeHandler(
      isMulti ? (isEmpty(valueInput) ? [] : valueInput) : { value: valueInput }
    )
  }

  const isTouched = inputRef.current?.FormCtrl.isTouched()
  const isDirty = inputRef.current?.FormCtrl.isDirty()
  const isInvalid =
    !readOnly &&
    required &&
    isEmptyArray(defaultValue) &&
    (isTouched || isDirty)

  let lang = getI18nextLng()

  const MergeDataTestProfileSubCategory = (sub, parent) => {
    let result = [];
    //danh sách sub không có parent
    let lstSubNotParent = sub.filter(x => parent.findIndex(p => p.code === x.group) < 0)
    if (lstSubNotParent.length > 0)
      result.push(lstSubNotParent);
    //thêm group option vào data
    parent.forEach(element => {
      let pa = {
        label: element.label,
        options: sub.filter(x => x.group === element.code)
      }
      result.push(pa)
    });
    setItems(result)
  }

  const fetchOptions = useCallback(async (code, group, searchQuery) => {
    let res = []
    if (code === parameterCode.TESTPROFILE_SUB_CATEGORY_CODE) {
      let sub = await fetchCodes(code, group, searchQuery)
      let parent = await fetchCodes(parameterCode.TESTPROFILE_CATEGORY_CODE, group, searchQuery)
      MergeDataTestProfileSubCategory(sub, parent);
      return;
    }
    else {
      res = await fetchCodes(code, group, searchQuery)
    }
    setItems(res)
  }, [])

  const onChangeHandler = (e, b) => {
    const element = document.getElementsByName(name)[0]
    var event = new Event("change", { bubbles: true })
    const value =
      (isMulti
        ? e?.map(_value => {
          return _value.value
        })
        : [e?.value]) || []

    element.value = value || ""
    element.dispatchEvent(event)

    setDefaultValue(value || "")
    onChange(name, value)
  }

  const isValidStyle = isInvalid && {
    borderColor: "#f46a6a !important",
    backgroundImage: `url(
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/></svg>"
  )`,
    backgroundPosition: "right 2.75rem center, center right 0.5rem",
    backgroundSize: "17px 18px, calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
    backgroundRepeat: "no-repeat",
  }

  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
      fontSize: "13px",
      cursor: isDisabled ? "not-allowed" : "default",
      ...isValidStyle,
      // ...isVaild,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: "13px",
        // color: "black",
        // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },

    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }

  useEffect(() => {
    if (code && (!isDependent || (isDependent && group)))
      fetchOptions(code, group, searchQuery)
    else setItems([])
  }, [code, group, isDependent, searchQuery])

  useEffect(() => {
    setDefaultValue(isMulti ? value : [value])
  }, [value])

  useEffect(() => {
    if (!isEmpty(options)) setItems(options)
  }, [options])

  useEffect(() => {
    GetValueSelected();
  }, [items, defaultValue])

  const [valueSelectted, setValueSelectted] = useState({});

  //lấy giá trị select
  const GetValueSelected = () => {
    for (let index = 0; index < items.length; index++) {
      //element datasource
      const element = items[index];
      //element có group options
      if (element.options?.length > 0) {
        for (let indexSub = 0; indexSub < element.options.length; indexSub++) {
          const sub = element.options[indexSub];
          let ind = defaultValue.findIndex(x => JSON.stringify(x + "") === JSON.stringify(sub.value + ""))
          //gán giá trị
          if (ind >= 0) {
            setValueSelectted({ ...sub, label: `${element.label} > ${sub.label}` })
            return;
          }
          //kết thúc vòng lặp, gán giá trị
          if (indexSub + 1 === element.options.length && index + 1 === items.length) {
            setValueSelectted([])
            return;
          }
        }
      }
      //element không có group options
      else {
        let ind = defaultValue.findIndex(x => JSON.stringify(x + "") === JSON.stringify(element.value + ""))
        //gán giá trị
        if (ind >= 0) {
          setValueSelectted(element)
          return;
        }
        //kết thúc vòng lặp, gán giá trị
        if (index + 1 === items.length) {
          setValueSelectted([])
          return;
        }
      }
    }

  }

  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <Select
        isClearable
        menuPlacement="auto"
        maxMenuHeight={150}
        menuPortalTarget={!portal && document.body}
        styles={colourStyles}
        isDisabled={readOnly}
        isMulti={isMulti}
        value={valueSelectted}
        onChange={onChangeHandler}
        options={items}
        classNamePrefix="select2-selection"
        className={classnames(
          { "has-changed": isChanged && detected },
          "form-select2 is-touched is-dirty av-invalid is-invalid"
        )}
      />
      {isInvalid && (
        <div className="text-danger form-group">
          <div className="is-touched is-dirty av-invalid is-invalid"></div>
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      )}
      <div className="d-none">
        <AvField
          multiple={isMulti}
          name={name}
          type="select"
          className={`form-select`}
          value={isMulti ? defaultValue : defaultValue?.[0]}
          errorMessage={errorMessage}
          required={!readOnly && required}
          ref={inputRef}
          readOnly={readOnly}
        >
          <option value="">--Select--</option>
          {items.map((_item, idx) => (
            <option key={idx} value={_item.value}>
              {_item.label}
            </option>
          ))}
        </AvField>
      </div>
    </>
  )
}

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isDependent: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  group: PropTypes.string,
  code: PropTypes.string,
  isMulti: PropTypes.bool,
  portal: PropTypes.bool,
  label: PropTypes.any.isRequired,
  detected: PropTypes.bool,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  searchQuery: PropTypes.object,
}

CustomSelect.defaultProps = {
  name: "",
  onChange: () => { },
  isDependent: false,
  errorMessage: "This field is invalid",
  portal: false,
  searchQuery: {},
}

export default CustomSelect
