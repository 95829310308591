import React, { Fragment, useEffect, useState } from "react";
import ReactTable from "react-table";
import { withTranslation } from "react-i18next";

import dayjs from "dayjs";
import {
  BACKGROUND_COLOR_FOCUSED,
  TEXT_COLOR_FOCUSED,
} from "../../config";
import { Button } from "antd";
import { LoadingText } from "../../Main/custom";
const Index = ({ data = [], onClickValue, loading }) => {
  const [selectRow, setSelectRow] = useState(null);
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    if (data) {
      setFilterData(data);
    }
  }, [data]);

  const columns = [
    {
      Header: "Máy",
      accessor: "actionLocation",
      key: "actionLocation",
      minWidth: 130,
      Cell: (data) => {
        return (
          <div
            className="w-100 d-flex justify-content-center"
            style={{ fontWeight: "bold" }}
          >
            {data.value}
          </div>
        );
      },
    },
    {
      Header: "Category",
      accessor: "categoryName",
      key: "categoryName",
      Cell: (data) => {
        return (
          <div
            className="w-100 d-flex justify-content-center"
            style={{
              fontWeight: "bold",
              borderWidth: 0,
              backgroundColor: "transparent",
            }}
          >
            {data.value}
          </div>
        );
      },
    },
    {
      Header: "Testcode",
      accessor: "testCode",
      key: "testCode",
      Cell: (data) => {
        return (
          <div
            className="w-100 d-flex justify-content-center"
            style={{
              fontWeight: "bold",
              borderWidth: 0,
              backgroundColor: "transparent",
            }}
          >
            {data.value}
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <ReactTable
        // key={`${keyTable}`}
        loading={loading}
        loadingText={<LoadingText />}
        style={{ height: "100%" }}
        data={filterData}
        filterable={true}        
        columns={columns}
        showPagination={true}
        noDataText={"No data"}
        previousText={"<"}
        nextText={">"}
        pageSize={5}
        pageSizeOptions={[5]}
        defaultFilterMethod={(filter, row) => {
          const id = filter.id;
          if (typeof filter.value === "object") {
            return row[id] !== undefined
              ? filter.value.indexOf(row[id]) > -1
              : true;
          } else if (typeof filter.value === "string") {
            return row[id] !== undefined && row[id] !== null
              ? String(row[id].toString().toLowerCase()).indexOf(
                  filter.value.toString().toLowerCase()
                ) > -1
              : false; // Các cột có giá trị undefined hoặc null sẽ không hiển thị khi filter
          }
        }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
                // onClickValue(rowInfo.row.fullSID, rowInfo.row.sampleTypeId);
                // onClickValue(rowInfo.row);
                setSelectRow(rowInfo.row);
              },
              style: {
                background:
                  JSON.stringify(rowInfo.row) === JSON.stringify(selectRow)
                    ? BACKGROUND_COLOR_FOCUSED
                    : "",
                color:
                  JSON.stringify(rowInfo.row) === JSON.stringify(selectRow)
                    ? TEXT_COLOR_FOCUSED
                    : "black",
                cursor: "pointer",
              },
            };
          } else {
            return {};
          }
        }}
      />
    </Fragment>
  );
};

export default withTranslation(["configure"])(Index);
