import React from "react"
import PropTypes from "prop-types"
import CustomModal from "./CustomModal"
import { ModalBody, ModalFooter } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const WarningModal = ({ modal, onToggle, message, t }) => {
  message = message || t("SelectRowWarning")
  return (
    <CustomModal
      modal={modal}
      classBackground={"bg-warning"}
      onToggle={onToggle}
      size={"md"}
      title={t("Warning")}
    >
      <ModalBody>
        <p className="text-center">{message}</p>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <button type="button" onClick={onToggle} className="btn btn-secondary">
          OK
        </button>
      </ModalFooter>
    </CustomModal>
  )
}

WarningModal.propTypes = {
  message: PropTypes.string,
}
WarningModal.defaultProps = {
  // message: "Please select a row",
}

export default withTranslation(["message"])(WarningModal)
