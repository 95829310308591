import React, { useRef, useState, useEffect } from "react";
import ERPProductService from "services/ERPProduct";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";

const Index = () => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    maSPChung: "",
    maHang: "",
    maLinhVuc: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });

  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        maSPChung: filter.maSPChung,
        maHang: filter.maHang,
        maLinhVuc: filter.maLinhVuc,    
        ...requestBody
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await ERPProductService.getLisErpProductsService(
        params,
        requestBody
      );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging]);

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        isLoading={loading}
        onSearch={() => {
          setPaging(prev => ({...prev, currentPage: 1}))
          getTableData({page: 1})
        }}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
      />
    </>
  );
};

export default Index;
