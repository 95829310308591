import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  // Chart as ChartJS
} from "chart.js";
import Chart from "chart.js/auto";
import "./../../assets/scss/custom/components/_dashboard_barchart.scss";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const StackChart = ({ data, options, loading }) => {
  const [defaultOptions, setDefaultOptions] = useState({
    scales: {
      x: {
        stacked: true, // Stacked x-axis
      },
      y: {
        stacked: true, // Stacked y-axis
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  });

  let config = {
    type: "bar",
    data,
    options: defaultOptions,
  };

  useEffect(() => {
    let chartStatus = Chart.getChart("myChart4");
    if (chartStatus) {
      chartStatus.destroy();
    }
    if (options) {
      config = {
        type: "bar",
        data,
        options: options,
      };
    }

    let myChart = new Chart(document.getElementById("myChart4"), config);
  }, [data]);

  return (
    <>
      <div className="wrapper">
        <canvas id="myChart4"></canvas>
      </div>
    </>
  );
};

export default StackChart;
