import { useState, useEffect } from "react";
import { Input, Button, Pagination, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { formatNumber } from 'utils/formatNumber';
import { Card } from "reactstrap";

const { Option } = Select;

const CustomPaginationComponent = ({
  page,
  pages,
  pageSize,
  previousText,
  nextText,
  onPageChange,
  totalRecords,
  onPageSizeChange,
  loading,
  // Add any other props you need
}) => {
  const { t } = useTranslation();
  const [inputPage, setInputPage] = useState(page);

  useEffect(() => {
    setInputPage(page);
  }, [page]);

  const handleInputChange = e => {
    const val = e.target.value;
    if (val >= 1 && val <= pages) {
      setInputPage(parseInt(val, 10));
    }
  };

  const handleInputBlur = () => {
    onPageChange(inputPage);
  };

  return (
    <Card>
    <div className="w-100 p-1 position-relative">
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255,255,255,.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <Spin />
        </div>
      )}

      <div className="d-flex align-items-center">
        <Button
          type="link"
          onClick={() => onPageChange(page - 1)}
          disabled={page <= 1}
          className="w-100"
        >
          « {previousText}
        </Button>
        <div className="d-flex align-items-center mx-2">
          <Select
            value={pageSize}
            onChange={value => onPageSizeChange(value)}
            style={{ maxWidth: "130px" }}
          >
            <Option value={20}>20 rows/page</Option>
            <Option value={40}>40 rows/page</Option>
            <Option value={50}>50 rows/page</Option>
            <Option value={80}>80 rows/page</Option>
            <Option value={100}>100 rows/page</Option>
            <Option value={200}>200 rows/page</Option>
          </Select>
          <div className="d-flex align-items-center mx-1" style={{ width: "120px" }}>
            <Input
              type="number"
              min={1}
              max={pages}
              value={inputPage}
              onChange={handleInputChange}
              style={{ width: "70px" }}
              onBlur={handleInputBlur}
            />
            &nbsp;/ {formatNumber(pages)}
          </div>
          <span style={{ margin: "0 40px", width: "180px" }}>
            {t("Total Rows")}:{" "}
            <span style={{ fontWeight: "bold", color: "#556ee6" }}>
              {formatNumber(totalRecords)}
            </span>
          </span>
        </div>
        <Button
          type="link"
          onClick={() => onPageChange(page + 1)}
          disabled={page >= pages}
          className="w-100"
        >
          {nextText} »
        </Button>
      </div>
    </div>
    </Card>
  );
};

export default CustomPaginationComponent;