import { AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Label } from "reactstrap"
import RollbackButton from "./RollbackButton"

const CustomAvFieldFile = ({
  label,
  name,
  required,
  value,
  detected,
  id,
  className,
  onUndoClick,
  onRemove,
  ...rest
}) => {
  const [defaultValue, setDefaultValue] = useState()
  const onChangeHandler = (e, value) => {
    const inputValue = value
    setDefaultValue(inputValue)
  }

  const onUndoHandler = () => {
    if (onUndoClick) onUndoClick()
  }


  useEffect(() => {
    setDefaultValue(value)
  }, [value])
  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          onClick={onUndoHandler}
          display={detected}
        />
      </div>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}
          onClick={() => {
            onRemove && onRemove()
          }}
        >
          <i className="fa fa-times"></i>
        </div>
        <AvField
          name={name}
          id={id}
          value={defaultValue}
          onChange={onChangeHandler}
          className={classnames(
            className
          )}
          {...rest}
        />
      </div>
    </>
  )
}

CustomAvFieldFile.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  required: PropTypes.bool,
  detected: PropTypes.bool,
  className: PropTypes.string,
}

CustomAvFieldFile.defaultProps = {
  detected: false,
  className: "",
}

export default CustomAvFieldFile
