import React, { useEffect, useState } from "react";
import { Spin, Typography, Radio } from "antd";
import { Card } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { renderToStaticMarkup } from "react-dom/server";

const Index = ({
  windowDimensions,
  keyTopSelectData,
  dataTop10Calculate,
  dataTop10Percentage,
  dataTop5Calculate,
  dataTop5Percentage,
  dataNameRoute,
  dataTestCodeChart,
}) => {
  
  const [updatedDataNameRoute, setUpdatedDataNameRoute] = useState([]);
  useEffect(() => {
    const updatedRoutes = [...dataNameRoute];
    
    updatedRoutes.forEach((route, index) => {
      const foundItem = dataTestCodeChart.find(
        item => item.routeName === route
      );
      if (foundItem) {
        updatedRoutes[index] = `Route name: ${foundItem.routeName} ::: Menu test: ${foundItem.menuTest}`;
      } else {
        updatedRoutes[index] = dataNameRoute[index];
      }
    });
    
    setUpdatedDataNameRoute(updatedRoutes);
  }, [dataNameRoute, dataTestCodeChart]);

  const series = [
    {
      name: "Số lượng mẫu",
      type: "column",
      data: keyTopSelectData === "5" ? dataTop5Calculate : dataTop10Calculate,
    },
    {
      name: "% mẫu",
      type: "column",
      data: keyTopSelectData === "5" ? dataTop5Percentage : dataTop10Percentage,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#4472c4", "#ed7d31"],
    title: {
      text: "Số lượng mẫu và % mẫu trên luồng",
      align: "center",
      offsetX: 110,
      style: {
        fontSize: "16px",
        fontFamily: "Times New Roman, serif",
      },
    },
    xaxis: {
      categories: updatedDataNameRoute,
      labels: {
        show: false,
        formatter: function(value) {
          if (typeof value === 'string') {
            return value.split(':::').join('<br>');
          } else {
            return value; // Trả về giá trị gốc nếu không phải chuỗi
          }
        },
        
      },
    },
    yaxis: [
      {
        seriesName: "Income",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          style: {
            colors: "#74788d",
          },
          formatter: function (val) {
            return parseInt(val) + " mẫu";
          },
        },
        title: {
          text: "Mẫu",
          style: {
            color: "#74788d",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Revenue",
        opposite: true,
        labels: {
          style: {
            colors: "#74788d",
          },
          formatter: function (val) {
            return val + " %";
          },
        },
        title: {
          text: "%",
          style: {
            color: "#74788d",
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      fixed: {
        enabled: true,
        position: 'topLeft', // Vị trí top-left của tooltip
        offsetY: 30,
        offsetX: 60,
      },
      style: {
        width: '100px',
        whiteSpace: 'pre-wrap', 
        // fontSize: '150px',
      },
    },
    
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  };

  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Số lượng mẫu và % mẫu trên luồng
            </Typography.Title>
          </div>
        </div>
        <div className="d-flex justify-content-center w-100">
          <div
            className="w-100"
            style={{
              maxWidth: windowDimensions.height > 768 ? "74%" : "894px",
            }}
          >
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={windowDimensions.height > 768 ? 650 : 500}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Index;
