import React, { useState, useRef, useEffect } from "react";
import UploadFile from "components/Common/UploadFile";
import TestCodeService from "services/TestCode";
import { showToast } from "components/Common";
import DownloadFile from "components/Common/DownloadFile";
import ExportService from "services/ExportS";
import Filter from "./Filter";
import Table from "./Table";
import { DELAY_FETCH } from "constant";
import ImportService from "services/Import";
import ManualAddModal from "./ManualAddModal";
import { scrollToTopRow } from "components/Common/Table";

const Index = ({ sites }) => {
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [isShowManualAddModal, setIsShowManualAddModal] = useState(false);
  const [dataDetailTestCode, setDataDetailTestCode] = useState([]);
  const [responseErrorMsg, setResponseErrorMsg] = useState(null);
  const [testcodeData, setTestcodeData] = useState([]);
  const [error, setError] = useState(null);
  const [isMatching, setIsMatching] = useState(-1);
  const [filter, setFilter] = useState({
    site: null,
  });
  const [search, setSearch] = useState({
    testCodeLis: "",
    mapMasterTestcode: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });

  const getRequestParams = (_params = {}, _requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        siteCode: filter.site ? filter.site.value : null,
        ..._params,
      },
      requestBody: {
        testCodeLis: search.testCodeLis,
        mapMasterTestcode: search.mapMasterTestcode,
        isMatching: isMatching,
        ..._requestBody,
      },
    };
    return reqParams;
  };

  const getTestCodeData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    console.log(requestBody);
    setTestcodeData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await TestCodeService.getLsTestCode(params, requestBody);

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTestcodeData([]);
        setLoading(false);
        return;
      }
      setTestcodeData(res);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!testcodeData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [testcodeData]);

  // Mặc định lấy site đầu tiên trong danh sách sites
  useEffect(() => {
    if (!sites || !sites.length) return;
    setFilter(prev => ({ ...prev, site: sites[0] }));
  }, [sites]);

  useEffect(() => {
    if (!filter || sites.length < 1) return;
    getTestCodeData();
  }, [filter, paging]);

  useEffect(() => {
    if(isDone){
      getTestCodeData();
    }
    setIsDone(false)
  }, [isDone]);
  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        setSearch={setSearch}
        isMatching={isMatching}
        setIsMatching={setIsMatching}
        error={error}
        setError={setError}
        onSearch={() => {
          setPaging(prev => ({ ...prev, currentPage: 1 }));
          getTestCodeData({ page: 1 });
        }}
        setIsEdit={setIsEdit}
        setIsUpload={setIsUpload}
        setIsShowModal={setIsShowManualAddModal}
        sites={sites}
        isLoading={loading}
        onExportExcel={val => setExportData(val)}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={testcodeData}
        setIsEdit={setIsEdit}
        setIsShowModal={setIsShowManualAddModal}
        setDataDetailTestCode={setDataDetailTestCode}
      />
      {isUpload && (
        <UploadFile
          isOpen={isUpload}
          title={
            <span>
              Map TestCode cho
              <span style={{ color: "#556ee6" }}>
                {" "}
                {filter.site.label.toUpperCase()}
              </span>
            </span>
          }
          onClose={() => setIsUpload(false)}
          onStartUploadingFile={async formData => {
            const res = await ImportService.importMapTestCodeService(
              filter.site.value,
              formData
            );
            if (res.error) {
              showToast(`${res.error.title}: ${res.error.description}`, 3);
              setIsUpload(false);
              return;
            }

            showToast(`Map mã thành công: ${res}`, 1);
            setIsUpload(false);
          }}
        />
      )}

      {exportData && (
        <DownloadFile
          isOpen={exportData}
          isUseFilter
          request={{
            siteCode: filter.site.value,
          }}
          serviceApi={ExportService[exportData.api]}
          onClose={() => setExportData(null)}
          fileName={`${exportData.fileName} ${filter.site.label}`}
        />
      )}
      {isShowManualAddModal && (
        <ManualAddModal
          data={isEdit ? dataDetailTestCode : []}
          filter={filter}
          isEdit={isEdit}
          setIsDone={setIsDone}
          isOpen={isShowManualAddModal}
          onClose={() => setIsShowManualAddModal(false)}
        />
      )}
    </>
  );
};

export default Index;
