import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "components/Common/InputText";
import { showToast } from "components/Common";
import MapInstrumentTypeService from "services/MasterInstrumentType";
import Select from "react-select";

const Schema = Yup.object().shape({
  instrumentTypeCode: Yup.string().required("Không được bỏ trống"),
});

const Index = ({ isOpen, onClose, filter, data, isEdit, setIsDone }) => {
  const [loading, setIsLoading] = useState(false);
  const [selectMasterInstrument, setSelectMasterInstrument] = useState([]);
  const [dataMasterInstrument, setDataMasterInstrument] = useState([]);

  const formik = useFormik({
    initialValues: {
      instrumentTypeCode: data?.instrumentTypeCode || "",
      instrumentTypeName: data?.instrumentTypeName || "",
    },
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      setIsLoading(true);
      if (isEdit) {
        updateInstrument(values);
      } else {
        addNewInstrument(values);
      }
    },
  });

  const getRequestParams = (_params = {}, _requestBody = {}) => {
    const reqParams = {
      params: {
        siteCode: filter.site ? filter.site.value : null,
        ..._params,
      },
      requestBody: {
        ..._requestBody,
      },
    };
    return reqParams;
  };

  const addNewInstrument = async (_requestBody = {}, _params = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    const res =
      await MapInstrumentTypeService.InsertMasterInstrumentTypeService(
        requestBody
      );

    if (res.error) {
      showToast(`${res.error.title}`, 3);
      setIsLoading(false);
    } else {
      showToast("Thêm mới thành công", 1);
      setIsDone(true);
      onClose();
    }
  };
  const updateInstrument = async (_requestBody = {}, _params = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    const res =
      await MapInstrumentTypeService.UpdateMasterInstrumentTypeService(
        requestBody
      );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
      setIsDone(true);
    } else {
      showToast("Cập nhật thành công", 1);
      setIsDone(true);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "650px", width: "100%" }}>
      <ModalHeader style={{ backgroundColor: "#d5d8ef" }}>
        {isEdit ? "Cập nhật" : "Thêm mới"} instrument type
      </ModalHeader>
      <ModalBody>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <InputText
              label="InstrumentTypeCode"
              name="instrumentTypeCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentTypeCode}
              errors={formik.errors}
              disabled={loading || isEdit}
              isRequired
            />
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <InputText
              label="InstrumentTypeName"
              name="instrumentTypeName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentTypeName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
        {loading && (
          <Row
            style={{ width: "80%", justifyContent: "center", display: "flex" }}
          >
            <Label>Đang xử lý...</Label>
            <div className="mt-1">
              <Progress color="primary" animated value="100" />
            </div>
          </Row>
        )}
        <div>
          <Button
            disabled={loading}
            color="primary"
            onClick={formik.handleSubmit}
          >
            Đồng ý
          </Button>{" "}
          <Button color="danger" onClick={onClose}>
            Đóng
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
