import React, { useEffect, useState } from "react";
import map from "../../../../assets/images/samplepath/new-map-3.png";
import { MapBuilder, getNewPos, isStopRender } from "../utils";
import { useRef } from "react";
import { sampleType, configKey, area } from "../constants";

const Index = ({
  width,
  height,
  ratio,
  target: initialTarget,
  initial,
  removeTarget,
  history,
  config,
  sampleNumberList,
}) => {
  const [drawing, setDrawing] = useState(false);
  const [current, setCurrent] = useState(initial);
  const [target, setTarget] = useState(null);
  const iMapBuilder = MapBuilder.getInstance();
  const ref = useRef(null);

  const updateTargetState = (_initTargetList) => {
    if (_initTargetList && _initTargetList.length > 0) {
      setTarget(_initTargetList[0]);
      return;
    }
    setTarget(null);
  };

  useEffect(() => {
    // Đang vẽ nhưng người dùng nhấn stop
    if (ref.current && drawing) {
      cancelAnimationFrame(ref.current);
      setDrawing(false);
      updateTargetState(initialTarget); // IMPORTANT CONTROL ANIMATION
    }

    setCurrent(initial);

    const sectionIdTypes = (history || []).reduce((acc, val) => {
      if (!val || val.data.area.type !== area.TYPE.SECTION) {
        return acc;
      }

      return [...acc, val.data.area.id];
    }, []);

    // Frame đầu tiên luôn dựa vào data của initial vì lúc này current chưa có
    // Hàm setCurrent ở trên nên giá trị current sẽ có ở frame tiếp theo
    iMapBuilder.initialMapAndBreakPoint(
      map,
      width,
      height,
      sectionIdTypes,
      sampleNumberList,
      () => {
        if (initial) {
          if (config[configKey.RENDER_SAMPLE_TYPE]) {
            iMapBuilder.drawText(
              initial.type,
              initial.x,
              initial.y - config[configKey.ICON_SIZE] - 4,
              config[configKey.SAMPLE_TYPE_COLOR],
              config[configKey.FONT_SIZE]
            );
          }

          if (config[configKey.RENDER_ICON]) {
            const icon = sampleType.getIcon(initial.type);

            iMapBuilder.drawImage(
              icon,
              initial.x,
              initial.y,
              config[configKey.ICON_SIZE],
              config[configKey.ICON_SIZE]
            );
          }

          if (config[configKey.RENDER_SID]) {
            iMapBuilder.drawText(
              initial.fullSID,
              initial.x,
              initial.y,
              config[configKey.SID_COLOR],
              config[configKey.FONT_SIZE],
              {
                y: config[configKey.ICON_SIZE],
              }
            );
          }
        }
      }
    );
  }, [width, height, ratio, initial, config]);

  const draw = () => {
    if (!target || !current) {
      setDrawing(false);
      return;
    }
    if (!drawing) {
      setDrawing(true);
    }

    const latestHistory = (history || []).slice(-1);

    const sectionIdTypes = (history || []).reduce((acc, val) => {
      if (!val || val.data.area.type !== area.TYPE.SECTION) {
        return acc;
      }

      return [...acc, val.data.area.id];
    }, []);

    let typeId = current.type;
    // Lấy loại mẫu ở vị trí history gần nhất
    // Vì current chỉ thay đổi tọa độ, không thay đổi thông tin mẫu
    if (latestHistory && latestHistory[0]) {
      typeId = latestHistory[0].data.type;

      // Lấy loại mẫu của vị trí kế tiếp khi mẫu đến nơi (trừ HIT_BOX)
      if (
        initialTarget &&
        initialTarget[0] &&
        initialTarget[0].data.area.type === area.TYPE.SECTION &&
        current.x === initialTarget[0].data.x &&
        current.y === initialTarget[0].data.y
      ) {
        typeId = initialTarget[0].data.type;
        sectionIdTypes.push(initialTarget[0].data.area.id);
      }
    }

    // DRAW
    iMapBuilder.initialMapAndBreakPoint(
      map,
      width,
      height,
      sectionIdTypes,
      sampleNumberList,
      () => {
        if (config[configKey.RENDER_PATH]) {
          if (history.length > 1) {
            for (let i = 0; i < history.length - 1; i++) {
              iMapBuilder.drawLine(
                history[i].data.x,
                history[i].data.y,
                history[i + 1].data.x,
                history[i + 1].data.y,
                config[configKey.PATH_COLOR],
                config[configKey.PATH_WIDTH]
              );
            }
          }
        }

        // The new line
        if (config[configKey.RENDER_PATH]) {
          const lastHistory = history.slice(-1);

          iMapBuilder.drawLine(
            lastHistory[0].data.x,
            lastHistory[0].data.y,
            current.x,
            current.y,
            config[configKey.PATH_COLOR],
            config[configKey.PATH_WIDTH]
          );
        }

        const icon = sampleType.getIcon(typeId);

        if (config[configKey.RENDER_SAMPLE_TYPE]) {
          iMapBuilder.drawText(
            typeId,
            current.x,
            current.y - config[configKey.ICON_SIZE] - 4,
            config[configKey.SAMPLE_TYPE_COLOR],
            config[configKey.FONT_SIZE]
          );
        }

        if (config[configKey.RENDER_ICON]) {
          iMapBuilder.drawImage(
            icon,
            current.x,
            current.y,
            config[configKey.ICON_SIZE],
            config[configKey.ICON_SIZE]
          );
        }

        if (config[configKey.RENDER_SID]) {
          iMapBuilder.drawText(
            current.fullSID,
            current.x,
            current.y,
            config[configKey.SID_COLOR],
            config[configKey.FONT_SIZE],
            {
              y: config[configKey.ICON_SIZE],
            }
          );
        }
      }
    );

    // STOP ANIMATION
    if (isStopRender(current, target.data)) {
      setDrawing(false);
      removeTarget(target);
      return;
    }

    // UPDATE ANIMATION
    ref.current = requestAnimationFrame(() => {
      setCurrent({
        ...current,
        ...getNewPos(current, target.data, config[configKey.SPEED]),
      });
    });
  };

  useEffect(() => {
    draw();
  }, [target, current]);

  useEffect(() => {
    updateTargetState(initialTarget);
  }, [initialTarget]);

  return (
    <canvas
      onMouseDown={(e) => {}}
      id="map"
      width={width}
      height={height}
      style={{ display: "block", margin: "auto" }}
    ></canvas>
  );
};

export default Index;
