import React from "react";
import { Spin, Typography, Radio } from "antd";
import { Card } from "reactstrap";
import Chart from "react-apexcharts";
import { renderToStaticMarkup } from "react-dom/server";
import CustomTooltip from "./CustomTooltip";
import { color } from "./constants";
import { BoxLoading } from "react-loadingg";

const Index = ({ avgFlDateData, flDateMode, setFlDateMode, unit }) => {
  const { categories = [], series = [] } = avgFlDateData || {};

  const options = {
    colors: [color.BLUE, color.GREEN, color.YELLOW],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    noData: {
      text: avgFlDateData ? "Không có dữ liệu" : "Đang tải",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth:
          20 + 60 / (1 + 30 * Math.exp(-categories.length / 3)) + "%",
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories,
      title: {
        text: "Ngày",
        style: {
          fontFamily: "Roboto",
          fontSize: "13px",
        },
      },
    },
    // yaxis: {
    //   title: {
    //     text: "Phút",
    //     style: {
    //       fontFamily: "Roboto",
    //       fontSize: "13px",
    //     },
    //   },
    // },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        const chartData = w.globals.initialSeries;

        const data = [];
        chartData.forEach((item, index) => {
          item.data.forEach((el, idx) => {
            if (idx === dataPointIndex) {
              data.push({
                id: idx,
                name: item.name,
                value: el,
                color: w.globals.colors[index],
              });
            }
          });
        });
        const total = data.reduce((acc, el) => acc + el.value, 0);
        const result = data.map(el => ({
          ...el,
          percent: ((el.value / total) * 100).toFixed(1),
        }));
        return renderToStaticMarkup(
          <CustomTooltip
            title={categories[dataPointIndex]}
            data={result}
            unit={unit}
          />
        );
      },
    },
  };

  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Tỉ lệ TAT trung bình từng bước trong quy trình theo thời gian
            </Typography.Title>
          </div>

          <div>
            <Radio.Group
              onChange={e => {
                setFlDateMode(e.target.value);
              }}
              buttonStyle="solid"
              value={flDateMode}
              style={{ width: "133px" }}
            >
              <Radio.Button value="week">Tuần</Radio.Button>
              <Radio.Button value="month">Tháng</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {avgFlDateData ? (
          <div className="d-flex justify-content-center w-100">
            <div className="w-100" style={{ maxWidth: "894px" }}>
              <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
              />
            </div>
          </div>
        ) : (
          <BoxLoading size="large" />
        )}
      </Card>
    </div>
  );
};

export default Index;
