import {
    GetListMasterParentTestsApi,
    DeleteMasterParentTestApi,
    InsertMasterParentTestApi,
    UpdateMasterParentTestApi,
    GetLisLabelValueMasterParentTestApi
  } from "helpers/app-backend/masterparenttestbackend_helpers";
  
  import HandleReponse from "utils/handleReponse";
  
  const GetListMasterParentTestsService = async paramters => {
    try {
      const res = await GetListMasterParentTestsApi(paramters);
      return HandleReponse.getListSuccess(res);
    } catch (err) {
      return HandleReponse.getListFail();
    }
  };
  
  const InsertMasterParentTestService =  async (paramters, reqBody) => {
    let result;
    try {
      const res = await InsertMasterParentTestApi(paramters, reqBody);
      result = HandleReponse.insertDataSuccess(res);
    } catch (err) {
      result = HandleReponse.catchJSONErrorReponse_v2(err.response);
    }
    return result;
  };
  
  const UpdateMasterParentTestService =  async (paramters, reqBody) => {
    let result;
    try {
      const res = await UpdateMasterParentTestApi(paramters, reqBody);
      result = HandleReponse.insertDataSuccess(res);
    } catch (err) {
      result = HandleReponse.catchJSONErrorReponse(err.response);
    }
    return result;
  };
  
  const DeleteMasterParentTestService =  async (paramters, reqBody) => {
    let result;
    try {
      const res = await DeleteMasterParentTestApi(paramters, reqBody);
      result = HandleReponse.insertDataSuccess(res);
    } catch (err) {
      result = HandleReponse.catchJSONErrorReponse(err.response);
    }
    return result;
  };
  
  const GetLisLabelValueMasterParentTestService = async () => {
    try {
      const res = await GetLisLabelValueMasterParentTestApi();
      return res;
    } catch (err) {
      return err;
    }
  };
  
  export default {
    GetListMasterParentTestsService,
    InsertMasterParentTestService,
    DeleteMasterParentTestService,
    UpdateMasterParentTestService,
    GetLisLabelValueMasterParentTestService
  };
  