import React, { useEffect, useState } from "react";
import cx from "classnames";

import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import { withTranslation } from "react-i18next";

function RenderPopfirm(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const PrpopoverID = "PopoverCustom-" + props.id;

  const closePopover = () => {
    const element = document.getElementById(PrpopoverID);
    if (!element) return;
    element.click();
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          closePopover();
          props.onOK();
        }
      }}
      onMouseLeave={() => {
        if(props.autoClose) {
          const popover = document.getElementById("root");
          if (popover) {
            popover.click();
          }
        }
      }}
    >
      <div color={props.type} id={PrpopoverID}>
        {props.children}
      </div>
      <UncontrolledPopover
        className="popover-custom"
        placement={props.placement}
        trigger="legacy"
        target={PrpopoverID}
        // fade={true}
        popperClassName={props.popperClassName}
      >
        <PopoverBody>
          <div style={{ width: 250, height: props.height || 200 }}>
            <div>
              <div
                className={cx("dropdown-menu-header-inner bg-" + props.type)}
              >
                <div className="menu-header-content">
                  <h5
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: 5,
                    }}
                  >
                    {props.title}
                  </h5>
                </div>
              </div>
            </div>

            <div
              style={{
                height: props.height ? props.height - 90 : 110,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 10px",
              }}
            >
              {props.content}
            </div>

            <div style={{ borderTop: "1px solid rgb(190,190,190)" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Button
                size="sm"
                className="btn-wide btn-shadow"
                color="primary"
                onClick={() => {
                  closePopover();
                  props.onOK();
                }}
              >
                {props.t("Đồng ý")}
              </Button>
              <Button
                size="sm"
                className="btn-wide btn-shadow"
                color="danger"
                onClick={() => {
                  closePopover();
                }}
              >
                {props.t("Không")}
              </Button>
            </div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
}

export default withTranslation(["configure"])(RenderPopfirm);
