import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "components/Common/Table";
import { Card, Input, Button, Row, Col } from "reactstrap";
import dayjs from "dayjs";
const Index = ({ dataTable, paging, setPaging ,totalRows}) => {
  const { t } = useTranslation(["Statistics"]);
  const formatCurrency = value => {
    const formattedValue = new Intl.NumberFormat("vi-VN").format(value);
    return formattedValue.replace(/\./g, ",");
  }
  const columns = [
    {
      filterable: false,
      resiable: false,
      sortable: false,
      fixed: "left",
      width: 50,
      Header: t("STT"),
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      accessor: "siteCodeNew",
      Header: t("SiteCodeNew"),
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "siteName",
      Header: t("SiteName"),
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "masterCategoryName",
      Header: t("MasterCategoryName"),
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "masterProducerCode",
      Header: t("MasterProducerCode"),
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "companyName",
      Header: t("CompanyName"),
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "masterInstrumentName",
      Header: t("MasterInstrumentName"),
      minWidth: 300,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "countInstrument",
      Header: t("CountInstrument"),
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "countTest",
      Header: t("CountTest"),
      minWidth: 100,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "revenue",
      Header: t("DS (Tỷ)"),
      minWidth: 100,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrency(value)} Tỷ
        </div>
      ),
    },
    {
      accessor: "marketShare",
      Header: t("Thị phần"),
      minWidth: 100,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}%
        </div>
      ),
    },
    {
      accessor: "statYear",
      Header: t("StatYear"),
      minWidth: 100,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "userInput",
      Header: t("Người nhập"),
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "inputTime",
      Header: t("Thời gian nhập"),
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {dayjs(value).format("DD-MM-YYYY HH:mm:ss")}
        </div>
      ),
    },

    {
      accessor: "userUpdate",
      Header: t("Người cập nhật"),
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "updateTime",
      Header: t("Cập nhật gần nhất"),
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {dayjs(value).format("DD-MM-YYYY HH:mm:ss")}
        </div>
      ),
    },
  ];

  return (
    <Card style={{ paddingBottom: 0 }}>
      <Row>
        <Col md={12}>
          <Table
            paging={paging}
            setPaging={setPaging}
            data={{ data: dataTable }}
            pageSize={paging.pageSize}
            style={{ height: "calc(100vh - 415px)" }}
            columns={columns}
            totalRows={totalRows}
          />
        </Col>
      </Row>
    </Card>
  );
};
export default Index;
