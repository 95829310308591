import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Collapse } from "reactstrap"

export const AccordionBody = ({ children }) => {
  return (
    <div className="accordion-body px-0">
      <div className="text-muted">{children}</div>
    </div>
  )
}

export const Accordion = ({ open, onToggle, tabId, title, children }) => {
  const isOpen = open
  return (
    <div className="mb-3">
      <Link
        to="#"
        className="accordion-list py-2"
        onClick={() => onToggle(tabId, !isOpen)}
        style={{ cursor: "pointer" }}
      >
        <div className="accordion-title">
          <i
            className={
              isOpen ? "dripicons-chevron-down" : "dripicons-chevron-up"
            }
          />
          &nbsp;
          {title}
        </div>
      </Link>

      <Collapse isOpen={isOpen} className="accordion-collapse">
        {children}
      </Collapse>
    </div>
  )
}

Accordion.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onToggle: PropTypes.func,
  // tabId: PropTypes.string.isRequired,
}

Accordion.defaultProps = {
  onToggle: () => {},
  title: "title",
}

const AccordionWrapper = ({ children, defaultTab, className }) => {
  const [accordionState, setAccordionState] = useState({ [defaultTab]: true })
  const onToggle = (tabId, open) => {
    setAccordionState({ [tabId]: open })
  }

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        open: !!accordionState?.[child?.props?.tabId],
        onToggle,
      })
    }
    return child
  })

  return (
    <div className={`accordion custom-accordion ${className}`} id="accordion">
      {childrenWithProps}
    </div>
  )
}

AccordionWrapper.propTypes = {
  defaultTab: PropTypes.string,
}

export default AccordionWrapper
