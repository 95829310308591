import Select from "react-select";
import { Button, Card, Col, Label, Row } from "reactstrap";

const Index = ({
  sites = [],
  exportFilter,
  setExportFilter,
  reportTypesList = [],
  error,
  setError,
  isLoading,
  setIsExportFile,
  setPaging,
}) => {
  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={4} xl={3}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">SiteName</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 5}}>
              <Select
                options={sites}
                placeholder=""
                value={exportFilter.site}
                onChange={val => {
                  setExportFilter(prev => ({ ...prev, site: val }));
                  setPaging(prev => ({ ...prev, currentPage: 1 }));
                  if (error) {
                    setError(null);
                  }
                }}
                isDisabled={isLoading}
                styles={{
                  control:  base => ({
                    ...base,
                    border: `1px solid ${error && error.siteName ? 'red' : 'rgb(206, 212, 218)'}`
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>

        <Col sm={12} md={4} xl={3}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">Loại thống kê</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 5 }}>
              <Select
                options={reportTypesList}
                placeholder=""
                value={exportFilter.reportType}
                onChange={val => {
                  setPaging(prev => ({ ...prev, currentPage: 1 }));
                  setExportFilter(prev => ({ ...prev, reportType: val }));
                }}
                isDisabled={isLoading}
              />
            </div>
          </div>
        </Col>

        <Col sm={12} md={2} xl={2}>
          <Button
            color="success"
            disabled={error || isLoading}
            className="btn-hover-shine"
            onClick={() => setIsExportFile(true)}
          >
            <div className="d-flex align-items-center">
            <i className='bx bx-cloud-download' style={{fontSize: '1rem'}}></i>
              <p className="mb-0 mx-1">Xuất Excel</p>
            </div>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
