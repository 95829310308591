import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";

import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/statistics`;

const GetListStatisticStatMonthResult = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyResult = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult?${q}`, reqBody);
};

const GetListStatisticStatMonthPatient = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthpatient?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyPatient = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthpatient?${q}`, reqBody);
};

const GetListStatisticStatMonthPatientByYear = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthpatient-year?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyPatientByYear = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthpatient-year?${q}`, reqBody);
};

const GetListStatisticStatMonthResultByYear = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-year?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyResultByYear = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-year?${q}`, reqBody);
};

const GetListStatisticStatMonthResultByCate = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-cate?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyResultByCate = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-cate?${q}`, reqBody);
};
const GetListStatMonthlyResultLocationType = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-location?${q}`, reqBody);
};

const GetListDetailStatMonthlyResultLocationType = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-location?${q}`, reqBody);
};

const GetListMaHangInstrument = () => {
  let q = spreadSearchQuery();
  return get(`${BASE_API_URL}/ls-instrument-mahang`);
};

const GetLsLabelValueMatHang = parameters => {
  let q = spreadSearchQuery(parameters);
  return get(`${BASE_API_URL}/label-value-mahang?${q}`);
};

const GetLsCommonName = parameters => {
  let q = spreadSearchQuery(parameters);
  return get(`${BASE_API_URL}/label-value-commonname?${q}`);
};

const GetLsMasterCategoryCode = parameters => {
  let q = spreadSearchQuery(parameters);
  return get(`${BASE_API_URL}/label-value-mastercategory?${q}`);
};

const GetLsMasterCategoryCodeResult = parameters => {
  let q = spreadSearchQuery(parameters);
  return get(`${BASE_API_URL}/label-value-mastercategory-result?${q}`);
};

const GetListCombineStatMonthlyResultInstrument = (parameters, redBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-intrument?${q}`, redBody);
};

const GetListResultInstrumentByCate = (parameters, redBody) => {
  let q = spreadSearchQuery(parameters);
  return post(
    `${BASE_API_URL}/sum-statmonthresult-intrument-cate?${q}`,
    redBody
  );
};

const GetDetailCombineStatMonthlyResultInstrumentByMaHang = (
  parameters,
  redBody
) => {
  let q = spreadSearchQuery(parameters);
  return post(
    `${BASE_API_URL}/detail-statmonthresult-intrument-mahang?${q}`,
    redBody
  );
};

const GetDetailCombineStatMonthlyResultInstrumentByMaHangCommonName = (
  parameters,
  reqBody
) => {
  let q = spreadSearchQuery(parameters);
  return post(
    `${BASE_API_URL}/detail-statmonthresult-intrument-mahang-commonname?${q}`,
    reqBody
  );
};

const GetDetailCombineStatMonthlyResultInstrumentByMaHangCategoryCode = (
  parameters,
  reqBody
) => {
  let q = spreadSearchQuery(parameters);
  return post(
    `${BASE_API_URL}/detail-statmonthresult-intrument-mahang-category?${q}`,
    reqBody
  );
};

const GetListStatisticStatMonthResultBySale = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-sale?${q}`, reqBody);
};

const GetListDetailStatisticStatMonthResultByCateCommon = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale?${q}`, reqBody);
};

const GetListStatisticStatMonthResultSaleByCategory = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  
  return post(`${BASE_API_URL}/sum-statmonthresult-sale-cate?${q}`, reqBody);
};

const GetListDetailStatisticStatMonthResultSaleByCategory = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale-cate?${q}`, reqBody);
};

const GetListInstrumentSaleByMaHang = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthinstrument-sale-hang?${q}`, reqBody);
};


const GetListResultInstrumentSaleByCate = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  
  return post(`${BASE_API_URL}/sum-statmonthintrument-sale-cate?${q}`, reqBody);
};

const GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCategoryCode = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthintrument-sale-mahang-category?${q}`, reqBody);
};

const GetDetailCombineStatMonthlyResultInstrumentSaleByMaHang = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthintrument-sale-mahang?${q}`, reqBody);
};
const GetListDetailStatMonthlyResultLocationTypeSale = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthlocation-sale?${q}`, reqBody);
};

const GetListStatMonthlyResultLocationTypeSale = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthlocation-sale?${q}`, reqBody);
};

const GetListStatisticStatMonthResultSaleByYear = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-sale-year?${q}`, reqBody);
};
const GetListDetailCombineStatMonthlyResultSaleByYear = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale-site?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyResultSale = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale-year?${q}`, reqBody);
};

const GetListStatisticStatMonthResultSaleBySite = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-sale-site?${q}`, reqBody);
};

const GetListStatisticStatMonthResultByCateType = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-cate-type?${q}`, reqBody);
};

const GetListDetailStatisticStatMonthResultSaleByCateCommon = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale-cate-common?${q}`, reqBody);
};

const GetListDetailStatisticStatMonthResultSaleByCate = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale-category?${q}`, reqBody);
};

const GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCommonName = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-intrument-sale-mahang-commonname?${q}`, reqBody);
};

const GetListStatMonthlyResultLocationTypeSaleByLocalLocation = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-location-sale?${q}`, reqBody);
};

const GetListDetailStatMonthlyResultLocationTypeSaleByLocalLocation = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-location-sale?${q}`, reqBody);
};

const GetListStatisticStatMonthResultByGroupTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-group-test?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyResultByGroupTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-group-test?${q}`, reqBody);
};

const GetListStatisticStatMonthResultByParentTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-parent-test?${q}`, reqBody);
};

const GetListDetailCombineStatMonthlyResultByParentTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-parent-test?${q}`, reqBody);
};

const GetListStatisticStatMonthResultSaleByGroupTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-sale-group-test?${q}`, reqBody);
};

const GetListDetailStatisticStatMonthResultSaleByGroupTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale-group-test?${q}`, reqBody);
};

const GetListStatisticStatMonthResultSaleByParentTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/sum-statmonthresult-sale-parent-test?${q}`, reqBody);
};

const GetListDetailStatisticStatMonthResultSaleByParentTest = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/detail-statmonthresult-sale-parent-test?${q}`, reqBody);
};

const GetDataTotalStatistics = (data) => {
  return post(`${BASE_URL}/TotalStatistics`, data);
};
export {
  GetListStatisticStatMonthResult,
  GetListDetailCombineStatMonthlyResult,
  GetListStatisticStatMonthPatient,
  GetListDetailCombineStatMonthlyPatient,
  GetListStatisticStatMonthResultByCate,
  GetListDetailCombineStatMonthlyResultByCate,
  GetListStatMonthlyResultLocationType,
  GetListDetailStatMonthlyResultLocationType,
  GetListMaHangInstrument,
  GetLsLabelValueMatHang,
  GetLsCommonName,
  GetListCombineStatMonthlyResultInstrument,
  GetDetailCombineStatMonthlyResultInstrumentByMaHang,
  GetDetailCombineStatMonthlyResultInstrumentByMaHangCommonName,
  GetListResultInstrumentByCate,
  GetDetailCombineStatMonthlyResultInstrumentByMaHangCategoryCode,
  GetLsMasterCategoryCode,
  GetListStatisticStatMonthPatientByYear,
  GetListDetailCombineStatMonthlyPatientByYear,
  GetListStatisticStatMonthResultByYear,
  GetListDetailCombineStatMonthlyResultByYear,
  GetLsMasterCategoryCodeResult,
  GetListStatisticStatMonthResultBySale,
  GetListDetailStatisticStatMonthResultByCateCommon,
  GetListStatisticStatMonthResultSaleByCategory,
  GetListDetailStatisticStatMonthResultSaleByCategory,
  GetListInstrumentSaleByMaHang,
  GetListResultInstrumentSaleByCate,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCategoryCode,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHang,
  GetListDetailStatMonthlyResultLocationTypeSale,
  GetListStatMonthlyResultLocationTypeSale,
  GetListStatisticStatMonthResultSaleByYear,
  GetListDetailCombineStatMonthlyResultSaleByYear,
  GetListDetailCombineStatMonthlyResultSale,
  GetListStatisticStatMonthResultSaleBySite,
  GetListStatisticStatMonthResultByCateType,
  GetListDetailStatisticStatMonthResultSaleByCateCommon,
  GetListDetailStatisticStatMonthResultSaleByCate,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCommonName,
  GetListStatMonthlyResultLocationTypeSaleByLocalLocation,
  GetListDetailStatMonthlyResultLocationTypeSaleByLocalLocation,
  GetListStatisticStatMonthResultByGroupTest,
  GetListDetailCombineStatMonthlyResultByGroupTest,
  GetListStatisticStatMonthResultByParentTest,
  GetListDetailCombineStatMonthlyResultByParentTest,
  GetListStatisticStatMonthResultSaleByGroupTest,
  GetListDetailStatisticStatMonthResultSaleByParentTest,
  GetListStatisticStatMonthResultSaleByParentTest,
  GetListDetailStatisticStatMonthResultSaleByGroupTest,
  GetDataTotalStatistics
};
