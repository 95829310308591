import {
  ConfirmModal,
  CustomButton,
  WarningModal,
  showToast,
  ProcessingModal,
  CustomModal,
} from "components/Common";
import { DELAY_FETCH, ModuleIds } from "constant";
import { onDelete, onDeleteToggle } from "helpers/utilities";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
//i18n
import { ActionGroup } from "components/Common";
import Table, {
  getSeqByCellData,
  scrollToTopRow,
} from "components/Common/Table";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Input } from "reactstrap";
import SiteService from "services/Site";
import UploadFile from "components/Common/UploadFile";
import ImportService from "services/Import";
import {
  addNewSite,
  combineStatMonthPatient,
  combineStatMonthResult,
  combineStatMonthlyResultInstrument,
  combineStatMonthlyResultLocationType,
  deleteSites,
  exportDataDatabaseAction,
  exportDataInstrumentAction,
  exportDataStatMonth,
  exportMapTestCodeData,
  getSiteDetail,
  syncDatabaseTestCode,
  syncSites,
  updateSite,
} from "store/site/actions";
import ActionGroupReport from "./ActionGroup/ActionGroupReport";
import SiteModal from "./Modal/SiteModal";
import CombineService from "./../../services/Combine";
const RESOURCE = ModuleIds.Site;

const Sites = ({
  onCombineStatMonthlyResultLocationType,
  onCombineStatMonthPatient,
  onCombineStatMonthResult,
  onCombineStatMonthlyResultInstrument,
  onAddNewSite,
  onUpdateSite,
  onDeleteSite,
  onSyncSite,
  onExport,
  onExportStatMonth,
  onSyncDatabase,
  onGetSiteDetail,
  site,
  t,
  onExportDataDatabase,
  onExportDataInstrument,
}) => {
  const timerRef = useRef(null);
  const [isUpload, setIsUpload] = useState(false);
  const [listSite, setListSite] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isClone, setIsClone] = useState(false);
  const [row, setRow] = useState({});
  const [rowDelete, setRowDelete] = useState({});
  const [rows, setRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  const formEl = useRef(null);

  const toggle = () => {
    setModal(prev => !prev);
  };

  const handleValidSubmit = (e, values) => {
    if (isEdit) {
      onUpdateSite({ site: values, callback: afterCreate });
    } else {
      const newItem = {
        ...values,
      };
      onAddNewSite({
        site: newItem,
        callback: afterCreate,
      });
    }
  };
  const afterCreate = () => {
    toggle();
    onGetListSite();
  };

  const onEditHandler = (e, param) => {
    const id = param.fullNameSchema || row?.fullNameSchema;
    if (id) {
      onGetSiteDetail(id);
      setIsEdit(true);
      toggle();
    } else setWarningModal(true);
  };

  const resetState = () => {
    setRows([]);
    setRow({});
  };

  const onDeleteToggleHandler = (e, param) => {
    onDeleteToggle({
      rows,
      row: param || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    });
    if (confirmModal) setRowDelete({});
  };

  const onDeleteMultipleRows = rowsState => {
    onDeleteSite({ sites: rowsState, callback: resetState });
  };

  const onDeleteSingleRow = rowsState => {
    onDeleteSite({
      sites: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id));
      },
    });
    setRowDelete({});
    setRow({});
  };

  const onDeleteSiteHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    });
    setConfirmModal(false);
  };

  const onExportHandler = (e, paramId) => {
    const id = paramId || row?.id;
    if (id) {
      onExport(id);
      onGetListSite();
    } else setWarningModal(true);
  };
  const onSyncHandler = (e, param) => {
    const id = param || row;
    if (id) {
      onSyncSite(id);
      onGetListSite();
    } else setWarningModal(true);
  };
  const onSyncDatabaseHandler = (e, param) => {
    const site = param || row;
    if (site) {
      onSyncDatabase(site);
      onGetListSite();
    } else {
      setWarningModal(true);
    }
  };
  const onCombineStatMonthPatientHandler = (e, param) => {
    const site = param || row;
    if (site) {
      onCombineStatMonthPatient(site.siteCode);
    } else {
      setWarningModal(true);
    }
  };
  const onCombineStatMonthlyResultLocationTypeHandler = (e, param) => {
    const site = param || row;
    if (site) {
      onCombineStatMonthlyResultLocationType(site.siteCode);
    } else {
      setWarningModal(true);
    }
  };
  const onCombineStatMonthResultHandler = (e, param) => {
    const site = param || row;
    if (site) {
      onCombineStatMonthResult(site.siteCode);
    } else {
      setWarningModal(true);
    }
  };
  const onCombineStatMonthlyResultInstrumentHandler = (e, param) => {
    const site = param || row;
    if (site) {
      onCombineStatMonthlyResultInstrument(site.siteCode);
    } else {
      setWarningModal(true);
    }
  };
  const onExportStatMonthHandler = (e, paramId) => {
    const id = paramId || row?.id;
    if (id) {
      onExportStatMonth(id);
    } else setWarningModal(true);
  };

  /**-----CYCLE------ */
  const onClickExportDataDatabaseHandler = e => {
    onExportDataDatabase();
  };
  const onClickExportDataInstrumentHandler = e => {
    onExportDataInstrument();
  };

  const addSiteClicks = () => {
    setIsEdit(false);
    setIsClone(false);
    toggle();
  };

  const onGetListSite = (options = {}) => {
    setListSite(null);
    timerRef.current = setTimeout(async () => {
      const res = await SiteService.getListSite({
        page: paging.currentPage,
        size: paging.pageSize,
        search: filter.search,
        ...options,
      });

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setListSite([]);
        return;
      }
      setListSite(res);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  const onCombineAll = async () => {
    setIsProcessing(true);
    try {
      const res = await CombineService.combineAllService();
      showToast(`Combine dữ liệu thành công`)
    } catch (error) {
      
      showToast(`Lỗi ${error.response.data}`, 3)
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    onGetListSite();
  }, [paging]);

  return (
    <React.Fragment>
      <Card className="p-2 mb-2">
        <div className="w-100 d-flex justify-content-end">
          <div
            style={{ maxWidth: "400px", minWidth: "200px", marginRight: "8px" }}
          >
            <Input
              value={filter.search}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  setPaging(prev => ({ ...prev, currentPage: 1 }));
                  onGetListSite({ page: 1 });
                }
              }}
              placeholder="Tìm kiếm..."
              onChange={e =>
                setFilter(prev => ({ ...prev, search: e.target.value }))
              }
            />
          </div>
          <ActionGroupReport
            color="primary"
            onClickExportDataDatabase={onClickExportDataDatabaseHandler}
            onClickExportDataInstrument={onClickExportDataInstrumentHandler}
          >
            {t("Tạo báo cáo")}
          </ActionGroupReport>
          <CustomButton
            style={{ backgroundColor: "#8625BE", border: 0 }}
            onClick={onCombineAll}
          >
            <div className="d-flex align-items-center">
              <i className="mdi mdi-vector-combine"></i>
              <p className="mb-0 mx-1">{t("Combine All")}</p>
            </div>
          </CustomButton>
          <CustomButton
            color="danger"
            className="mx-2"
            onClick={e => {
              setIsUpload(true);
            }}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle"></i>
              <p className="mb-0 mx-1">{t("Upload file SQL")}</p>
            </div>
          </CustomButton>
          <CustomButton color="success" onClick={addSiteClicks}>
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle"></i>
              <p className="mb-0 mx-1">{t("Add Site")}</p>
            </div>
          </CustomButton>
        </div>
      </Card>
      <Table
        paging={paging}
        setPaging={setPaging}
        data={listSite}
        pageSize={paging.pageSize}
        style={{ height: "calc(100vh - 280px)" }}
        columns={[
          {
            Header: "",
            accessor: "instrumentIDLis",
            width: 50,
            Cell: data => (
              <div className="d-flex w-100 justify-content-center">
                {getSeqByCellData(data)}
              </div>
            ),
          },
          {
            accessor: "fullNameSchema",
            Header: t("Schema Name"),
            width: 190,
          },
          {
            accessor: "parentSiteCode",
            Header: t("Parent Code"),
            width: 150,
          },
          {
            accessor: "siteCode",
            Header: t("Site Code"),
            minWidth: 150,
          },
          {
            accessor: "fullNameSite",
            Header: t("Site Name"),
            minWidth: 200,
          },
          {
            Header: "Action",
            minWidth: 250,
            maxWidth: 340,
            accessor: "",
            Cell: row => {
              const param = row.original;
              return (
                <ActionGroup
                  id={`siteInfo${row.index}`}
                  isEnableCombine={param?.parentSiteCode == null}
                  isSync={param.isSync}
                  resource={RESOURCE}
                  onCombineStatMonthPatient={e => {
                    onCombineStatMonthPatientHandler(e, param);
                  }}
                  onCombineStatMonthResult={e => {
                    onCombineStatMonthResultHandler(e, param);
                  }}
                  onCombineStatMonthlyResultLocationType={e => {
                    onCombineStatMonthlyResultLocationTypeHandler(e, param);
                  }}
                  onCombineStatMonthlyResultInstrument={e => {
                    onCombineStatMonthlyResultInstrumentHandler(e, param);
                  }}
                  onEdit={e => {
                    onEditHandler(e, param);
                  }}
                  onDelete={e => {
                    onDeleteToggleHandler(e, param);
                  }}
                  onSync={e => {
                    onSyncHandler(e, param);
                  }}
                  onRefresh={e => {}}
                  onExport={e => {
                    onExportHandler(e, param);
                  }}
                  onSyncDatabase={e => {
                    onSyncDatabaseHandler(e, param);
                  }}
                  onExportStatMonth={e => {
                    onExportStatMonthHandler(e, param);
                  }}
                />
              );
            },
          },
        ]}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Site")}`}
        message={t("message:Bạn muốn xóa ngay cả khi site đã có dữ liệu?")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteSiteHandler}
      />
      <SiteModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : site}
      />
      {isUpload && (
        <UploadFile
          isOpen={isUpload}
          fileSupport={"*.sql"}
          title={<span>Vui lòng chọn file cần import</span>}
          onClose={() => setIsUpload(false)}
          onStartUploadingFile={async formData => {
            const res = await ImportService.importFileSQLService(formData);

            if (res.error) {
              showToast(`${res.error.title}: ${res.error.description}`, 3);
              setIsUpload(false);
              return;
            }

            showToast(`Upload file thành công`, 1);
            setIsUpload(false);
          }}
        />
      )}
      {isProcessing && (
        <ProcessingModal
          isOpen={isProcessing}
          title={
            "Quá trình xử lí có thể mất vài phút, vui lòng chờ trong giây lát..."
          }
          size="500px"
        />
      )}
    </React.Fragment>
  );
};

Sites.propTypes = {
  onGetSiteDetail: PropTypes.func,
  onAddNewSite: PropTypes.func,
  onUpdateSite: PropTypes.func,
  onDeleteSite: PropTypes.func,
  onSyncSite: PropTypes.func,
  onExport: PropTypes.func,
  onSyncDatabase: PropTypes.func,
  t: PropTypes.any,
};

const mapStateToProps = ({ sites }) => {
  return {
    site: sites?.site,
  };
};

const mapDispatchToProps = dispatch => ({
  onAddNewSite: (site, history) => {
    return dispatch(addNewSite(site, history));
  },
  onUpdateSite: site => {
    return dispatch(updateSite(site));
  },
  onDeleteSite: site => dispatch(deleteSites(site)),
  onGetSiteDetail: siteId => dispatch(getSiteDetail(siteId)),
  onSyncSite: site => {
    return dispatch(syncSites({ site }));
  },
  onExport: site => dispatch(exportMapTestCodeData({ site })),
  onExportStatMonth: site => {
    return dispatch(exportDataStatMonth({ site }));
  },
  onSyncDatabase: site => dispatch(syncDatabaseTestCode({ site })),
  onCombineStatMonthPatient: site => {
    dispatch(combineStatMonthPatient({ siteCode: site }));
  },
  onCombineStatMonthResult: site => {
    dispatch(combineStatMonthResult({ siteCode: site }));
  },
  onCombineStatMonthlyResultLocationType: site => {
    dispatch(combineStatMonthlyResultLocationType({ siteCode: site }));
  },
  onCombineStatMonthlyResultInstrument: site => {
    dispatch(combineStatMonthlyResultInstrument({ siteCode: site }));
  },
  onExportDataDatabase: () => {
    dispatch(exportDataDatabaseAction());
  },
  onExportDataInstrument: () => {
    dispatch(exportDataInstrumentAction());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["sitePage", "message"])(Sites)));
