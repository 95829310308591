import {
  GetListMasterGroupTestsApi,
  DeleteMasterGroupTestApi,
  InsertMasterGroupTestApi,
  UpdateMasterGroupTestApi,
  GetLisLabelValueMasterGroupTestApi,
  GetLisLabelValueTestCodeOfGroupTestApi,
  InsertTestCodeToMasterGroupTestApi
} from "helpers/app-backend/mastergrouptestbackend_helpers";

import HandleReponse from "utils/handleReponse";

const GetListMasterGroupTestsService = async paramters => {
  try {
    const res = await GetListMasterGroupTestsApi(paramters);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const InsertMasterGroupTestService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await InsertMasterGroupTestApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse_v2(err.response);
  }
  return result;
};

const UpdateMasterGroupTestService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await UpdateMasterGroupTestApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const DeleteMasterGroupTestService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await DeleteMasterGroupTestApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const GetLsMasterGroupTestService = async () => {
  try {
    const res = await GetLisLabelValueMasterGroupTestApi();
    return res;
  } catch (err) {
    return err;
  }
};

const InsertTestCodeToMasterGroupTestService = async (paramters, reqBody) => {
  let result;
  try {
    const res = await InsertTestCodeToMasterGroupTestApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const GetLisLabelValueTestCodeOfGroupTestService = async (paramters) => {
  try {
    const res = await GetLisLabelValueTestCodeOfGroupTestApi(paramters);
    return res;
  } catch (err) {
    return err;
  }
};

export default {
  GetListMasterGroupTestsService,
  InsertMasterGroupTestService,
  DeleteMasterGroupTestService,
  UpdateMasterGroupTestService,
  GetLsMasterGroupTestService,
  GetLisLabelValueTestCodeOfGroupTestService,
  InsertTestCodeToMasterGroupTestService
};
