import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = localStorage.getItem("token")

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
  // responseType: "blob"
})
axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept"
axiosApi.interceptors.response.use(
  response => response,
  error => {     
    return Promise.reject(error)
  }
)
const axiosApiBlob = axios.create({
  baseURL: API_URL,
  responseType:"blob"
})
axiosApiBlob.defaults.headers.common["Authorization"] = token
axiosApiBlob.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept"
axiosApiBlob.interceptors.response.use(
  response => response,
  error => {     
    return Promise.reject(error)
  }
)
export async function getBlobFile(url, config = {}) {
  return await axiosApiBlob.get(url, { ...config }).then(response => response.data)
}
export async function postBlobFile(url, data, config = {}) {
  return await axiosApiBlob.post(url, data, { ...config }).then(response => response.data)
}
export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data , { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

