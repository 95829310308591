import {
  ConfirmModal,
  CustomButton,
  WarningModal,
  showToast,
  ProcessingModal,
  CustomModal,
} from "components/Common";
import { DELAY_FETCH, ModuleIds } from "constant";
import { onDelete, onDeleteToggle } from "helpers/utilities";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useMemo } from "react";
//i18n
import Table, {
  getSeqByCellData,
  scrollToTopRow,
} from "components/Common/Table";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getAllSystemAccessHistory } from "helpers/app-backend/sahistorybackend_helpers";
import dayjs from "dayjs";

const SAHistorys = ({ t }) => {
  const timerRef = useRef(null);
  const [listSAHistory, setListSAHistory] = useState([]);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const columns = [
    {
      filterable: false,
      resiable: false,
      sortable: false,
      width: 50,
      Header: t("STT"),
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      accessor: "fullName",
      Header: t("Người thực hiện"),
      width: 200,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "urlApi",
      Header: t("Thao tác"),
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "method",
      Header: t("Phương thức"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "ipAddress",
      Header: t("Địa chỉ IP"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "statusCode",
      Header: t("Trạng thái"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "timeAccess",
      Header: t("Thời gian truy cập"),
      width: 200,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {dayjs(value).format("DD-MM-YYYY HH:mm:ss")}
          </div>
        );
      },
    },
  ];

  const onGetListSAHistory = (options = {}) => {
    setListSAHistory(null);
    timerRef.current = setTimeout(async () => {
      const res = await getAllSystemAccessHistory({
        page: paging.currentPage,
        size: paging.pageSize,
        ...options,
      });

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setListSAHistory([]);
        return;
      }
      setListSAHistory(res);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    onGetListSAHistory();
  }, [paging]);
console.log("listSAHistory",listSAHistory)
  return (
    <React.Fragment>
      <Table
        paging={paging}
        setPaging={setPaging}
        data={listSAHistory}
        pageSize={paging.pageSize}
        style={{ height: "calc(100vh - 250px)" }}
        columns={columns}
      />
    </React.Fragment>
  );
};

export default withRouter(withTranslation(["UserPage", "message"])(SAHistorys));
