import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
// import Dashboard from "../pages/Dashboard"

//Sites
import Sites from "../pages/Sites"
import Instruments from "../pages/Instruments"
import MasterInstrument from "../pages/MasterInstrument";
import Report from "../pages/Report"
import ERPProduct from "../pages/ERPProduct"
import MapTestCode from "../pages/MapTestCode"
import MasterTestCode from "pages/MasterTestCode"
import CombineStatMonthlyResultInstrument from "pages/Combines/CombineStatMonthlyResultInstrument"
import CombineStatMonthlyResult from "pages/Combines/CombineStatMonthlyResult"
import PowerBiERP from "pages/PowerBiERP"
import StatisticsBySale from "pages/Statistics/StatisticsBySale"
import StatisticByAmount from "pages/Statistics/StatisticByAmount"
import MasterInstrumentType from "pages/MasterInstrumentType"
import MasterGroupTest from "pages/MasterGroupTest"
import MasterParentTest from "pages/MasterParentTest"
import DataHospitalFile from "pages/DataHospitalFile"
import SamplePath from "pages/SamplePath"
import AutoSystem from "pages/Dashboards/AutoSystem"
import MachineSystem from "pages/Dashboards/MachineSystem"
import SystemEvaluate from "pages/Dashboards/SystemEvaluate"
import UserManagement from "pages/UserManagement"
import TargetPAKD from "pages/TargetPAKD"
import TotalStatistic from "pages/Statistics/TotalStatistic"
import StatisticsEffective from "pages/Statistics/StatisticsEffective"
import StatisticsRealSale from "pages/Statistics/StatisticsRealSale"
import StatisticsSurveyResult from "pages/Statistics/StatisticsSurveyResult"
import StatisticsAnnualData from "pages/Statistics/StatisticsAnnualData"
import AnalyzerPath from "pages/AnalyzerPath"
import SystemAccessHistory from "pages/SystemAccessHistory"
import KHCLRevenueReport from "pages/KHCLRevenueReport" 
import InstrumentSurvey from "../pages/InstrumentSurvey"
import CategorySurvey from "../pages/CategorySurvey"



const authProtectedRoutes = [
  // { path: "/dashboard", component: Dashboard },
  //profile
  { path: "/", component: Login },
  {path: "/profile", component: UserProfile },
  {path: "/site", component: Sites},
  {path:"/instrument", component: Instruments},
  {path:"/master-instrument", component: MasterInstrument},
  {path:"/report", component: Report},
  {path:"/erp-product", component: ERPProduct},
  {path:"/map-testcode", component: MapTestCode},
  {path:"/master-testcode", component: MasterTestCode},
  {path:"/ls-statistics-number", component: StatisticByAmount},
  {path:"/ls-statistics-sale", component: StatisticsBySale},
  {path:"/combine-instrument", component: CombineStatMonthlyResultInstrument},
  {path:"/combine-result", component: CombineStatMonthlyResult},
  {path:"/powerbi-erp", component: PowerBiERP},
  {path:"/master-instrument-type", component: MasterInstrumentType},
  {path:"/master-grouptest", component: MasterGroupTest},
  {path:"/master-parent-test", component: MasterParentTest},
  {path:"/data-hospital", component: DataHospitalFile},
  {path:"/sample-path-hospital", component: SamplePath},
  {path:"/auto-system-evaluate", component: AutoSystem},
  {path:"/tat-machine-system", component: MachineSystem},
  {path:"/tat-system", component: SystemEvaluate},
  {path:"/user-management", component: UserManagement},
  {path:"/total-statistics", component: TotalStatistic},
  {path:"/StatisticsEffective", component: StatisticsEffective},
  {path:"/StatisticsRealSale", component: StatisticsRealSale},
  {path:"/StatisticsSurveyResult", component: StatisticsSurveyResult},
  {path:"/StatisticsAnnualData", component: StatisticsAnnualData},
  {path:"/TargetPAKD", component: TargetPAKD},
  {path:"/AnalyzerPath", component: AnalyzerPath},
  {path:"/SystemAccessHistory", component: SystemAccessHistory},
  {path:"/KHCLRevenueReport", component: KHCLRevenueReport},
  {path:"/InstrumentSurvey", component: InstrumentSurvey},
  {path:"/CategorySurvey", component: CategorySurvey},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register }
]

export { authProtectedRoutes, publicRoutes }
