export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"
export const CHANGEPASS_PROFILE = "EDIT_PROFILE"
//AUTHORIZE
export const GET_AUTHORIZE_MENUS = "GET_AUTHORIZE_MENUS"
export const GET_AUTHORIZE_MENUS_SUCCESS = "GET_AUTHORIZE_MENUS_SUCCESS"
export const GET_AUTHORIZE_MENUS_FAIL = "GET_AUTHORIZE_MENUS_FAIL"

//PERMISSIONS
export const GET_AUTHORIZE_PERMISSIONS = "GET_AUTHORIZE_PERMISSIONS"
export const GET_AUTHORIZE_PERMISSIONS_SUCCESS =
  "GET_AUTHORIZE_PERMISSIONS_SUCCESS"
export const GET_AUTHORIZE_PERMISSIONS_FAIL = "GET_AUTHORIZE_PERMISSIONS_FAIL"
