import React, { useState, useRef, useEffect } from "react";
import UploadFile from "components/Common/UploadFile";
import InstrumentService from "services/Instrument";
import { showToast } from "components/Common";
import DownloadFile from "components/Common/DownloadFile";
import ExportService from "services/ExportS";
import Filter from "./Filter";
import Table from "./Table";
import ManualAddModal from "./ManualAddModal";
import AutoAddModal from "./AutoAddModal";
import { DELAY_FETCH } from "constant";
import ImportService from "services/Import";
import { scrollToTopRow } from "components/Common/Table";
import ModalInsertUpdateMapInstrument from "./ModalInsertUpdateMapInstrument"
const Index = ({ sites }) => {
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [dataDetailInstrument, setDataDetailInstrument] = useState([]);
  const [isShowManualAddModal, setIsShowManualAddModal] = useState(false);
  const [isShowManualInsertUpdate, setIsShowManualInsertUpdate] = useState(false);
  const [instrumentCodeNeedAdd, setInstrumentCodeNeedAdd] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [responseErrorMsg, setResponseErrorMsg] = useState(null);
  const [instrumentData, setInstrumentData] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    site: null,
  });
  const [search, setSearch] = useState({
    instrumentNameLis: "",
    mapMasterInstrumentID: "",
  })
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });

  const getRequestParams = (_params = {}, _requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        siteCode: filter.site ? filter.site.value : null,
        ..._params
      },
      requestBody: {
        instrumentNameLis: search.instrumentNameLis,
        mapMasterInstrumentID: search.mapMasterInstrumentID,
        ..._requestBody,
      },
    };
    return reqParams;
  };

  const getInstrumentData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setInstrumentData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await InstrumentService.getListMapInstrument(
        params,
        requestBody
      );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setInstrumentData([]);
        setLoading(false);
        return;
      }
      setInstrumentData(res);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!instrumentData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [instrumentData]);

  // Mặc định lấy site đầu tiên trong danh sách sites
  useEffect(() => {
    if (!sites || !sites.length) return;
    setFilter(prev => ({ ...prev, site: sites[0] }));
  }, [sites]);
  useEffect(() => {
    if (!filter || sites.length < 1) return;
    getInstrumentData();
  }, [filter, paging]);

  useEffect(() => {
    if(isDone){
      getInstrumentData();
    }
    setIsDone(false)
  }, [isDone]);

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        setSearch={setSearch}
        error={error}
        setError={setError}
        onSearch={() => {
          setPaging(prev => ({...prev, currentPage: 1}))
          getInstrumentData({page: 1});
        }}
        setIsUpload={setIsUpload}
        setIsShowModal={setIsShowManualAddModal}
        setIsShowModalInsert = {setIsShowManualInsertUpdate}
        sites={sites}        
        isLoading={loading}
        onExportExcel={val => setExportData(val)}
        setIsEdit={setIsEdit}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={instrumentData}
        setIsEdit={setIsEdit}
        setDataDetailInstrument={setDataDetailInstrument}
        setIsShowModalUpdate={setIsShowManualInsertUpdate}
      />
      {isShowManualAddModal && (
        <ManualAddModal
          isOpen={isShowManualAddModal}
          onClose={() => setIsShowManualAddModal(false)}
        />
      )}

      {responseErrorMsg && instrumentCodeNeedAdd ? (
        <AutoAddModal
          isOpen={Boolean(responseErrorMsg)}
          onClose={() => {
            setResponseErrorMsg(null);
            setInstrumentCodeNeedAdd(null);
          }}
          instrumentCodes={instrumentCodeNeedAdd}
          responseErrorMsg={responseErrorMsg}
          onOk={params => {
            addNewInstrument(params);
          }}
        />
      ) : null}

      {isUpload && (
        <UploadFile
          isOpen={isUpload}
          title={<span>
            Thêm dữ liệu Master Instrument cho
            <span style={{color: '#556ee6'}}> {filter.site.label.toUpperCase()}</span>
          </span>}
          onClose={() => setIsUpload(false)}
          onStartUploadingFile={async formData => {
            const { error, errorData } = await ImportService.importInstrument(
              filter.site.value,
              formData
            );
            if (error && !errorData) {
              showToast("Đã có lỗi xảy ra: " + error, 3);
              setIsUpload(false);
              return;
            }
            if (errorData) {
              setResponseErrorMsg(error);
              setInstrumentCodeNeedAdd(errorData);
              setIsUpload(false);
              return;
            }

            showToast("Thêm dữ liệu thành công", 1);
            setIsUpload(false);
          }}
        />
      )}

      {exportData && (
        <DownloadFile
          isOpen={exportData}
          isUseFilter
          request={{
            siteCode: filter.site.value,
          }}
          serviceApi={ExportService[exportData.api]}
          onClose={() => setExportData(null)}
          fileName={`${exportData.fileName} ${filter.site.label}`}
        />
      )}
      {isShowManualInsertUpdate && (
        <ModalInsertUpdateMapInstrument
          data={isEdit ? dataDetailInstrument : []}          
          filter={filter}
          isEdit={isEdit}
          setIsDone={setIsDone}
          isOpen={isShowManualInsertUpdate}
          onClose={() => setIsShowManualInsertUpdate(false)}
        />
      )}
    </>
  );
};

export default Index;
