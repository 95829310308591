import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  EDIT_PROFILE,
  CHANGEPASS_PROFILE,
  GET_AUTHORIZE_MENUS,
  GET_AUTHORIZE_PERMISSIONS,
} from "./actionTypes"
import {
  profileSuccess,
  profileError,
  getAuthorizeMenusFail,
  getAuthorizeMenusSuccess,
  getAuthorizePermissionsSuccess,
  getAuthorizePermissionsFail,
} from "./actions"

//Include Both Helper File with needed methods
// import { updatedByUserById , changepass} from "helpers/app-backend"


// import { getAuthorizeMenu, getAuthorizePage } from "helpers/app-backend"
// import { isEmptyValues } from "helpers/utilities"
import { DefaultMenuDisplayInfo } from "constant"

// function* editProfile({ payload: { user } }) {
//   try {
    
//       const response = yield call(updatedByUserById, user)
//       localStorage.setItem("authUser", JSON.stringify(user))
//       yield put(profileSuccess(response))
//   } catch (error) {
//     yield put(profileError(error))
//   }
// }

// function* changePass({ payload: { pass } }) {
//   try {
    
//       const response = yield call(changepass, pass)
//       yield put(profileSuccess(response))
//   } catch (error) {
//     yield put(profileError(error))
//   }
// }

// AUTHORIZE
function* fetchAuthorizeMenus({ payload: { role, callback } }) {
  try {
     const response = yield call(getAuthorizeMenu, role)
    const _DisplayInfo = response._DisplayInfo
    // delete response._DisplayInfo

    // convert response object to array
    const result = menusHandler(response.data) // {pageCode: "", parent: [pageCode]}
    
    // const menus = menuIconHandler(result, _DisplayInfo)
    yield put(getAuthorizeMenusSuccess(response.data))
    if (callback) callback(result?.find(_menu => _menu.level === 4))
    
  } catch (error) {
    console.log(error)
    yield put(getAuthorizeMenusFail(error))
  }
}

const menuIconHandler = (menus, displayInfo) => {
  return menus.map(_menu => {
    const idx = displayInfo.findIndex(_info => _info.item === _menu.pageCode)
    let color = DefaultMenuDisplayInfo.color,
      icon = DefaultMenuDisplayInfo.icon
    if (idx >= 0) {
      icon = displayInfo[idx].icon || icon
      color = displayInfo[idx].color || color
    }

    _menu.icon = icon
    _menu.color = color

    return _menu
  })
}

const menusHandler = (object, parent) => {
  const result = []

  const isArray = Array.isArray(object)
  const arrayObject = isArray ? object : Object.keys(object)

  let level = 0
  if (!parent) level = 1
  else if (parent === "_OneLIS") level = 2
  else if (isArray) level = 4
  else if (parent !== "_OneLIS") level = 3

  arrayObject.forEach(_key => {
    result.push({
      pageCode: _key,
      parent,
      level,
      hasChildren: !isEmptyValues(object[_key]),
    })

    if (!isArray && !isEmptyValues(object[_key]))
      result.push.apply(result, menusHandler(object[_key], _key))
    else return
  })

  return result
}

// AUTHORIZE
function* fetchAuthorizePermissions({ payload: { role, module } }) {
  try {
    //ko call api thật
    const response = yield call(getAuthorizePage, role, module)
    const result = {
      "_Permissions": {
      },

      "_DisplayInfo": [
        {
          "item": "_default",
          "icon": "bx bx-border-inner",
          "color": "#50a5f1"
        }
      ]
    }
    result._Permissions[module.replace('_','')] =  {
          "permissions": response.data,
      "icon": "bx bx-border-inner",
      "color": "#50a5f1"
    }
    // const _Permissions = { ...response._Permissions }
    // const _DisplayInfo = [...(response._DisplayInfo || [])]

    // response._Permissions = permissionIconHandler(_Permissions, _DisplayInfo)
    
    yield put(getAuthorizePermissionsSuccess(result))
  } catch (error) {
    console.log(error)
    yield put(getAuthorizePermissionsFail(error))
  }
}

const permissionIconHandler = (permissions, displayInfo) => {
  const arrayPermission = Object.keys(permissions)
  arrayPermission?.forEach(_key => {
    let idx = displayInfo.findIndex(_info => _info.item === _key)
    idx =
      idx < 0 ? displayInfo.findIndex(_info => _info.item === "_default") : idx

    let color = DefaultMenuDisplayInfo.color,
      icon = DefaultMenuDisplayInfo.icon

    if (idx >= 0) {
      icon = displayInfo[idx].icon || icon
      color = displayInfo[idx].color || color
    }
    permissions[_key] = {
      permissions: permissions[_key],
      icon: icon,
      color: color,
    }
  })

  return permissions
}

export function* watchProfile() {
  //yield takeEvery(EDIT_PROFILE, editProfile)
  //yield takeEvery(CHANGEPASS_PROFILE, changePass)
  //yield takeLatest(GET_AUTHORIZE_MENUS, fetchAuthorizeMenus)
  //yield takeLatest(GET_AUTHORIZE_PERMISSIONS, fetchAuthorizePermissions)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
