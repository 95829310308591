import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import * as moment from "moment"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/reports`

const getPatients = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/patient?${q}`,reqBody)
}

const getResults = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/result?${q}`,reqBody)
}

const getResultInstruments = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/result-instrument?${q}`,reqBody)
}

const getResultDepartments = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/result-department?${q}`,reqBody)
}

export {
    getPatients,
    getResults,
    getResultInstruments,
    getResultDepartments,
}