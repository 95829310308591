import React, { useEffect, useState } from "react";
import { PageContent, CustomBreadcrumb } from "components/Common";
import Statistic from "./StatisticByAmount";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SiteService from "services/Site";

const RESOURCE = ModuleIds.Statistic;

const StatisticContainer = ({ t }) => {
  const [sites, setSites] = useState([]);
  const getData = async () => {
    const res = await SiteService.getListMasterSite({
      page: 1,
      size: 1000,
      search: "",
    });
    let result = res.map(
      item =>
        (item = {
          value: item.siteCode,
          label: item.siteName,
        })
    );
    setSites(result);
  };
  useEffect(() => {
    getData();
  }, []);

  const titleTag = `Thống kê số lượng`;
  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb
        breadcrumbItem={titleTag}
        resource={RESOURCE}
        icon={<i className="bx bx-bar-chart-alt"></i>}
      />
      {/* Page Body */}
      <Statistic sites={sites} />
    </PageContent>
  );
};

StatisticContainer.propTypes = {
  t: PropTypes.any,
};

export default withTranslation(["statisticPage"])(StatisticContainer);
