import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/testcodes`


const getLsTestCodeApi = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/map-testcode?${q}`,reqBody)
}

const getLsMasterTestCodeApi = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/ls-master-testcode?${q}`,reqBody)
}

const InsertTestCodeManualApi = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/map-testcode-manual?${q}`,reqBody)
}

const UpdateTestCodeManualApi = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return put(`${BASE_API_URL}/map-testcode-manual?${q}`,reqBody)
}

const getLsLabelValueMasterTestCodeApi = (parameters) => {
    let q = spreadSearchQuery(parameters)
    return get(`${BASE_API_URL}/label-value-master-testcode?${q}`)
}

export {
    getLsTestCodeApi,
    getLsMasterTestCodeApi,
    InsertTestCodeManualApi,
    UpdateTestCodeManualApi,
    getLsLabelValueMasterTestCodeApi
}