import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import * as moment from "moment"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/combines`

const insertCombineStatMonthlyPatient = payload => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/combine-statmonthlypatient?${q}`)
}      

const insertCombineStatMonthlyResult = payload => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/combine-statmonthlyresult?${q}`)
}

const insertCombineStatMonthlyResultLocationType = payload => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/combine-statmonthlyresusltlocationtype?${q}`)
}

const insertCombineStatMonthlyResultInstrument = payload => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/combine-statmonthlyresultinstrument?${q}`)
}

const insertCombineStatMonthlyResultInstrumentFullData = payload => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/combine-statmonthlyresultinstrumentfulldata?${q}`)
}

const combineAll = () => {    
    return post(`${BASE_API_URL}/combine-all`)
} 

export {
    insertCombineStatMonthlyPatient,
    insertCombineStatMonthlyResult,
    insertCombineStatMonthlyResultLocationType,
    insertCombineStatMonthlyResultInstrument,
    insertCombineStatMonthlyResultInstrumentFullData,
    combineAll
}