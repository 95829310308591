import {
  Card,
  Button,
  Label,
  Input,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import Select from "react-select";
import { useState } from "react";

const Index = ({
  setIsUpload,
  setIsEdit,
  filter,
  setFilter,
  isMatching,
  setIsMatching,
  setSearch,
  isLoading,
  onSearch,
  error,
  setError,
  setIsShowModal,
  sites,
  onExportExcel,
}) => {
  const onClosePopover = () => {
    const el = document.getElementById("PopoverDownload");
    if (el) {
      el.click();
    }
  };

  const selectedIsMatching = [
    {
      label: "Tất cả",
      value: -1
    },
    {
      label: "Đã khớp",
      value: 1
    },
    {
      label: "Chưa khớp",
      value: 0
    },
  ];

  return (
    <>
      <Card className="p-3 my-2">
        <div className="d-flex align-item-center">
          <div style={{ flex: 1, maxWidth: "405px" }}>
            <div className="d-flex align-items-center">
              <Label
                className="mb-0 text-truncate fw-bold"
                style={{ minWidth: "98px" }}
              >
                SiteName
              </Label>
              <div style={{ flex: 1, marginLeft: "6px", zIndex: 10}}>
                <Select
                  options={sites || []}
                  placeholder=""
                  value={filter.site}
                  onChange={val => {
                    setIsMatching(-1);
                    setFilter(prev => ({ ...prev, site: val }));
                  }}
                  isDisabled={isLoading}
                  styles={{
                    control: base => ({
                      ...base,
                      border: `1px solid ${
                        filter && !filter.site ? "red" : "rgb(206, 212, 218)"
                      }`,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="position-relative">
              {filter && !filter.site ? (
                <p
                  className="mb-0 position-absolute fixed-top"
                  style={{ color: "red" }}
                >
                  Hãy chọn một SiteName
                </p>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              color="warning"
              className="btn-hover-shine mx-2"
              id="PopoverDownload"
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-download"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Download</p>
              </div>
            </Button>

            <UncontrolledPopover
              placement="bottom"
              target="PopoverDownload"
              trigger="legacy"
            >
              <PopoverBody>
                <Button
                  color="primary"
                  disabled={!filter.site}
                  onClick={() => {
                    onClosePopover();
                    onExportExcel({
                      fileName: `File Map TestCode`,
                      api: "ExportMapTestCodeData",
                    });
                  }}
                  className="w-100 btn-hover-shine"
                >
                  <i className="bx bx-link-alt mx-1"></i>
                  File Map TestCode
                </Button>
              </PopoverBody>
            </UncontrolledPopover>

            <Button
              color="success"
              className="btn-hover-shine mx-2"
              disabled={!filter.site}
              onClick={() => setIsUpload(true)}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-upload"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Upload file map mã</p>
              </div>
            </Button>
            <Button
              style={{ backgroundColor: "#76229A" }}
              className="btn-hover-shine mx-2"
              disabled={!filter.site}
              onClick={() => {
                setIsShowModal(true);
                setIsEdit(false)}
              }
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-upload"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Thêm mới</p>
              </div>
            </Button>
          </div>
        </div>
      </Card>
      <Card className="p-3 mb-2">
        <div className="d-flex align-items-end">
          <div className="d-flex flex-column align-items-start">
            <Label className="mb-0 text-truncate fw-bold">Mã/Tên Testcode</Label>
            <div style={{ flex: 1, maxWidth: "300px" }}>
              <Input
                value={filter.testCodeLis}
                disabled={isLoading}
                style={{
                  borderColor: error && error.testCodeLis ? "red" : "#ced4da",
                }}
                onChange={e => {
                  setSearch(prev => ({
                    ...prev,
                    testCodeLis: e.target.value,
                  }));
                  if (error) {
                    setError(null);
                  }
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column align-items-start" style={{marginLeft: "16px", marginRight: "16px"}}>
            <Label className="mb-0 text-truncate fw-bold">MapMasterTestcode</Label>
            <div style={{ flex: 1, maxWidth: "300px" }}>
              <Input
                value={filter.mapMasterTestcode}
                disabled={isLoading}
                style={{
                  borderColor:
                    error && error.mapMasterTestcode ? "red" : "#ced4da",
                }}
                onChange={e => {
                  setSearch(prev => ({
                    ...prev,
                    mapMasterTestcode: e.target.value,
                  }));
                  if (error) {
                    setError(null);
                  }
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column align-items-start" style={{marginRight: "16px", zIndex: 10, flex: 1}}>
            <Label className="mb-0 text-truncate fw-bold">Khớp mã</Label>
            <Select
              options={selectedIsMatching || []}
              placeholder=""
              value={{label: selectedIsMatching.find(item => item.value === isMatching)?.label, value: isMatching}}
              onChange={val => {
                setIsMatching(val.value);
              }}
              isDisabled={isLoading}
              styles={{
                control: base => ({
                  ...base,
                  width: "160px",
                }),
              }}
            />
          </div>
          <Button
            color="primary"
            className="btn-hover-shine"
            onClick={onSearch}
            disabled={error || isLoading}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-search mr-1" style={{ fontSize: "1rem" }}></i>
              <p className="mx-1 mb-0">
                {isLoading ? "Đang tìm..." : "Tìm kiếm"}
              </p>
            </div>
          </Button>
        </div>
      </Card>
    </>
  );
};

export default Index;
