import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import * as moment from "moment"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/sites`

// PHYSICIANS
// Get All Physicians Method
const getAllSites = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}

const getLabelValueSite = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/label-value-site?${q}`)
}

const getAllParentSites = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/parent?${q}`)
}
const getSiteById = siteCode => {
  return get(`${BASE_API_URL}/GetSiteById?siteCode=${siteCode}`)
}      
                                             
const createSite = data => {
  return post(`${BASE_API_URL}/CreateSite`,data)
}   

const updateSiteById = data => { 
  return put(`${BASE_API_URL}/UpdateSite`, data)
}

const deleteSiteById = data => {
  return post(`${BASE_API_URL}/DeleteSite`, data)
}

const syncSite = data => {
  return post(`${BASE_API_URL}/SyncSite`, data);
}

export {
    getAllSites,
    getAllParentSites,
    getSiteById,
    createSite,
    updateSiteById,
    deleteSiteById,
    syncSite,
    getLabelValueSite,
}