import Table, {
  getSeqByCellData,
  getSTTCellData,
  formatNumber,
  formatValueToMonthYear,
} from "components/Common/Table";
import { format } from "date-fns";
const Index = ({ paging, setPaging, data, pageSize }) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSTTCellData(data, paging)}
            </div>
          ),
        },
        {
          Header: "SiteCode",
          accessor: "siteCode",
          width: 150,
        },
        {
          Header: "SiteName",
          accessor: "siteName",
          width: 350,
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          width: 250,
          Cell: ({ value }) => <div>{formatValueToMonthYear(value)}</div>,
        },
        {
          Header: "CountPatient",
          accessor: "countPatient",
          Cell: data => <div className="d-flex w-100 justify-content-end">{formatNumber(data.value)}</div>,
          width: 250,
        },
      ]}
      disablePagination={false}
    />
  );
};

export default Index;
