import {
  ADD_SITE,
  ADD_SITE_FAIL,
  ADD_SITE_SUCCESS,
  DELETE_SITE,
  DELETE_SITE_FAIL,
  DELETE_SITE_SUCCESS,
  GET_SITES,
  GET_SITES_FAIL,
  GET_SITES_SUCCESS,
  GET_SITE_DETAIL,
  GET_SITE_DETAIL_FAIL,
  GET_SITE_DETAIL_SUCCESS,
  RESET_SITE_SEARCH_QUERY,
  SET_SITE_SEARCH_QUERY,
  UPDATE_SITE,
  UPDATE_SITE_FAIL,
  UPDATE_SITE_SUCCESS,
  SYNC_SITE,
  SYNC_SITE_SUCCESS,
  SYNC_SITE_FAIL,
  EXPORT_MAP_TEST_CODE,
  EXPORT_MAP_TEST_CODE_SUCCESS,
  EXPORT_MAP_TEST_CODE_FAIL,
  GET_DUPLICATE_STATMONTHRESULT,
  GET_DUPLICATE_STATMONTHRESULT_SUCCESS,
  GET_DUPLICATE_STATMONTHRESULT_FAIL,
  SYNC_DATABASE_SUCCESS,
  SYNC_DATABASE_FAIL,
  SYNC_DATABASE,
  COMBINE_STATMONTHPATIENT,
  COMBINE_STATMONTHPATIENT_FAIL,
  COMBINE_STATMONTHPATIENT_SUCCESS,
  COMBINE_STATMONTHRESULT,
  COMBINE_STATMONTHRESULT_FAIL,
  COMBINE_STATMONTHRESULT_SUCCESS,
  COMBINE_STATMONTHLOCATIONTYPE,
  COMBINE_STATMONTHLOCATIONTYPE_FAIL,
  COMBINE_STATMONTHLOCATIONTYPE_SUCCESS,
  COMBINE_STATMONTHRESULTINSTRUMENT,
  COMBINE_STATMONTHRESULTINSTRUMENT_FAIL,
  COMBINE_STATMONTHRESULTINSTRUMENT_SUCCESS,
  REPORT_DATA_INSTRUMENT,
  REPORT_DATA_INSTRUMENT_FAIL,
  REPORT_DATA_INSTRUMENT_SUCCESS,
  REPORT_DATA_DATABASE,
  REPORT_DATA_DATABASE_FAIL,
  REPORT_DATA_DATABASE_SUCCESS,
  REPORT_DATA_STATMONTH,
  REPORT_DATA_STATMONTH_FAIL,
  REPORT_DATA_STATMONTH_SUCCESS,
  SET_CHECKBOX,
} from "./actionTypes";

//reset search query
export const resetSiteSearchQuery = () => ({
  type: RESET_SITE_SEARCH_QUERY,
});

//set search query
export const setSiteSearchQuery = searchQuery => ({
  type: SET_SITE_SEARCH_QUERY,
  payload: searchQuery,
});

//get Site
export const getSites = payload => {
  return {
    type: GET_SITES,
    payload: payload || {},
  };
};

export const getSitesSuccess = sites => {
  return {
    type: GET_SITES_SUCCESS,
    payload: sites,
  };
};

export const getSitesFail = error => ({
  type: GET_SITES_FAIL,
  payload: error,
});

// get Site detail
export const getSiteDetail = id => ({
  type: GET_SITE_DETAIL,
  id,
});

export const getSiteDetailSuccess = sites => ({
  type: GET_SITE_DETAIL_SUCCESS,
  payload: sites,
});

export const getSiteDetailFail = error => ({
  type: GET_SITE_DETAIL_FAIL,
  payload: error,
});

// Add Site
export const addNewSite = site => ({
  type: ADD_SITE,
  payload: site,
});

export const addNewSiteSuccess = site => ({
  type: ADD_SITE_SUCCESS,
  payload: site,
});

export const addNewSiteFail = error => ({
  type: ADD_SITE_FAIL,
  payload: error,
});

// update Site
export const updateSite = site => ({
  type: UPDATE_SITE,
  payload: site,
});

export const updateSiteSuccess = site => ({
  type: UPDATE_SITE_SUCCESS,
  payload: site,
});

export const updateSiteFail = error => ({
  type: UPDATE_SITE_FAIL,
  payload: error,
});

// Delete Site
export const deleteSites = sites => ({
  type: DELETE_SITE,
  payload: sites,
});

export const deleteSitesSuccess = sites => ({
  type: DELETE_SITE_SUCCESS,
  payload: sites,
});

export const deleteSitesFail = error => ({
  type: DELETE_SITE_FAIL,
  payload: error,
});

// Sync Site
export const syncSites = site => {
  return {
    type: SYNC_SITE,
    payload: site,
  };
};

export const syncSitesSuccess = sites => ({
  type: SYNC_SITE_SUCCESS,
  payload: sites,
});

export const syncSiteFail = error => ({
  type: SYNC_SITE_FAIL,
  payload: error,
});

//Export Data Map Test Code
export const exportMapTestCodeData = site => {
  return {
    type: EXPORT_MAP_TEST_CODE,
    payload: site,
  };
};
export const exportMapTestCodeDataSucess = site => {
  return {
    type: EXPORT_MAP_TEST_CODE_SUCCESS,
    payload: site,
  };
};
export const exportMapTestCodeDataFail = error => {
  return {
    type: EXPORT_MAP_TEST_CODE_FAIL,
    payload: error,
  };
};
//Export Data Database
export const exportDataDatabaseAction = () => {
  return {
    type: REPORT_DATA_DATABASE,
  };
};
export const exportDataDatabaseActionSucess = () => {
  return {
    type: REPORT_DATA_DATABASE_SUCCESS,
  };
};
export const exportDataDatabaseActionFail = error => {
  return {
    type: REPORT_DATA_DATABASE_FAIL,
    payload: error,
  };
};

//Export Data Instrument
export const exportDataInstrumentAction = () => {
  return {
    type: REPORT_DATA_INSTRUMENT,
  };
};
export const exportDataInstrumentActionSucess = () => {
  return {
    type: REPORT_DATA_INSTRUMENT_SUCCESS,
  };
};
export const exportDataInstrumentActionFail = error => {
  return {
    type: REPORT_DATA_INSTRUMENT_FAIL,
    payload: error,
  };
};
//GET_DUPLICATE_STATMONTHRESULT
export const getDuplicateStatMonthResult = site => {
  return {
    type: GET_DUPLICATE_STATMONTHRESULT,
    payload: site,
  };
};
export const getDuplicateStatMonthResultSucess = sites => {
  return {
    type: GET_DUPLICATE_STATMONTHRESULT_SUCCESS,
    payload: sites,
  };
};
export const getDuplicateStatMonthResultFail = error => {
  return {
    type: GET_DUPLICATE_STATMONTHRESULT_FAIL,
    payload: error,
  };
};

//SYNC-DATABASE-MAPTESTCODE-STATMONTH-RESULT
export const syncDatabaseTestCode = site => {
  return {
    type: SYNC_DATABASE,
    payload: site,
  };
};
export const syncDatabaseTestCodeSucess = sites => {
  return {
    type: SYNC_DATABASE_SUCCESS,
    payload: sites,
  };
};
export const syncDatabaseTestCodeFail = error => {
  return {
    type: SYNC_DATABASE_FAIL,
    payload: error,
  };
};

//COMBINE_STATMONTHPATIENT
export const combineStatMonthPatient = site => {
  return {
    type: COMBINE_STATMONTHPATIENT,
    payload: site,
  };
};

export const combineStatMonthPatientFail = error => {
  return {
    type: COMBINE_STATMONTHPATIENT_FAIL,
    payload: error,
  };
};

export const combineStatMonthPatientSuccess = sites => {
  return {
    type: COMBINE_STATMONTHPATIENT_SUCCESS,
    payload: sites,
  };
};

//COMBINE_STATMONTHRESULT
export const combineStatMonthResult = site => {
  return {
    type: COMBINE_STATMONTHRESULT,
    payload: site,
  };
};

export const combineStatMonthResultFail = error => {
  return {
    type: COMBINE_STATMONTHRESULT_FAIL,
    payload: error,
  };
};

export const combineStatMonthResultSuccess = sites => {
  return {
    type: COMBINE_STATMONTHRESULT_SUCCESS,
    payload: sites,
  };
};

//COMBINE_STATMONTHLOCATIONTYPE
export const combineStatMonthlyResultLocationType = site => {
  return {
    type: COMBINE_STATMONTHLOCATIONTYPE,
    payload: site,
  };
};

export const combineStatMonthlyResultLocationTypeFail = error => {
  return {
    type: COMBINE_STATMONTHLOCATIONTYPE_FAIL,
    payload: error,
  };
};

export const combineStatMonthlyResultLocationTypeSuccess = sites => {
  return {
    type: COMBINE_STATMONTHLOCATIONTYPE_SUCCESS,
    payload: sites,
  };
};

//COMBINE_STATMONTHLOCATIONTYPE
export const combineStatMonthlyResultInstrument = site => {
  return {
    type: COMBINE_STATMONTHRESULTINSTRUMENT,
    payload: site,
  };
};

export const combineStatMonthlyResultInstrumentFail = error => {
  return {
    type: COMBINE_STATMONTHRESULTINSTRUMENT_FAIL,
    payload: error,
  };
};

export const combineStatMonthlyResultInstrumentSuccess = sites => {
  return {
    type: COMBINE_STATMONTHRESULTINSTRUMENT_SUCCESS,
    payload: sites,
  };
};

//REPORT_DATA_STATMONTH
export const exportDataStatMonth = payload => {
  return {
    type: REPORT_DATA_STATMONTH,
    payload: payload || {},
  };
};

export const exportDataStatMonthFail = error => {
  return {
    type: REPORT_DATA_STATMONTH_FAIL,
    payload: error,
  };
};

export const exportDataStatMonthSuccess = () => {
  return {
    type: REPORT_DATA_STATMONTH_SUCCESS,
  };
};

export const setCheckBox = payload => {
  return {
    type: SET_CHECKBOX,
    payload: payload || {},
  };
};
