import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import ReactTable from "react-table";
import InstrumentService from "services/Instrument";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { getSeqByCellData } from "components/Common/Table";
import { showToast } from "components/Common";
import { BoxLoading } from "react-loadingg";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Index = ({ isOpen, onClose, instrumentCodes, responseErrorMsg }) => {
  const [tableData, setTableData] = useState(
    (instrumentCodes || []).reduce((a, v) => ({ ...a, [v]: {
      instrumentCode: v,
      instrumentName: "",
      otherName: "",
      producerCode: "",
      producerName: "",
      fieldCode: "",
      fieldName: "",
      vT3Code: "",
      decription: "",
    }}), {}) 
  );
  const [inserting, setInserting] = useState(false);
  const [selected, setSelected] = useState(instrumentCodes || []);

  const onUpdateSelected = (value, isChecked) => {
    if (!isChecked) {
      const newList = selected.filter(el => el !== value);
      setSelected(newList);
      return;
    }

    if (!selected.includes(value)) {
      setSelected(prev => [...prev, value]);
    }
  };

  const AddInstrumentData = async () => {
    const params = selected.reduce((acc, val) => {
      if(tableData[val]) {
        return [...acc, tableData[val]]
      }

      return acc;
    }, []);

    const res = await InstrumentService.addNewInstrument(params);
    if (res.error) {
      showToast(res.error.title, 3);
    } else {
      showToast("Thêm thành công", 1);
      onClose();
    }
  };

  const onUpdateTableData = (value, instrumentCode, attribute) => {
    const prevTableData = tableData;
    
    if(prevTableData[instrumentCode] && prevTableData[instrumentCode].hasOwnProperty(attribute)) {
      prevTableData[instrumentCode][attribute] = value;
      setTableData(prevTableData)
    }
  };

  const renderInput = (_data, key) => {
    return (
      <Input
        bsSize="sm"
        defaultValue={_data.original[key]}
        onChange={e =>
          {
            onUpdateTableData(
              e.target.value,
              _data.original.instrumentCode,
              key,
            )
          }
        }
      />
    );
  }

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "1450px", width: "100%" }}>
      <ModalHeader>Thêm mới Master Instrument</ModalHeader>
      <ModalBody>
        <p style={{ color: "red" }}>{responseErrorMsg}</p>
        <ReactTableFixedColumns
          data={Object.values(tableData) || []}
          columns={[
            {
              Header: () => (
                <Input
                  type="checkbox"
                  defaultChecked={
                    (selected || []).length === (instrumentCodes || []).length
                  }
                  onChange={e => {
                    if (e.target.checked) setSelected(instrumentCodes || []);
                    else setSelected([]);
                  }}
                />
              ),
              accessor: "instrumentCode",
              width: 40,
              Cell: ({ value }) => (
                <div className="w-100 d-flex justify-content-center">
                  <Input
                    type="checkbox"
                    checked={selected.includes(value)}
                    onChange={e => onUpdateSelected(value, e.target.checked)}
                  />
                </div>
              ),
            },
            {
              Header: "STT",
              accessor: "instrumentCode",
              width: 50,
              Cell: data => (
                <div className="d-flex w-100 justify-content-center">
                  {getSeqByCellData(data)}
                </div>
              ),
            },
            {
              Header: "InstrumentCode",
              accessor: "instrumentCode",
              width: 120,
            },
            {
              Header: "InstrumentName",
              accessor: "instrumentName",
              width: 140,
              Cell: data => renderInput(data, 'instrumentName'),
            },
            {
              Header: "OtherName",
              accessor: "otherName",
              width: 130,
              Cell: data => renderInput(data, 'otherName'),
            },
            {
              Header: "ProducerCode",
              accessor: "producerCode",
              width: 140,
              Cell: data => renderInput(data, 'producerCode'),
            },
            {
              Header: "ProducerName",
              accessor: "producerName",
              width: 150,
              Cell: data => renderInput(data, 'producerName'),
            },
            {
              Header: "FieldCode",
              accessor: "fieldCode",
              width: 140,
              Cell: data => renderInput(data, 'fieldCode'),
            },
            {
              Header: "FieldName",
              accessor: "fieldName",
              width: 180,
              Cell: data => renderInput(data, 'fieldName'),
            },
            {
              Header: "VT3Code",
              accessor: "vT3Code",
              width: 120,
              Cell: data => renderInput(data, 'vT3Code'),
            },
            {
              Header: "Decription",
              accessor: "decription",
              minWidth: 180,
              Cell: data => renderInput(data, 'decription'),
            },
          ]}
          style={{ height: "300px" }}
          sortable={false}
          filterable={false}
          defaultPageSize={100}
          loading={inserting}
          loadingText={<BoxLoading />}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={!selected.length || inserting}
          onClick={() => {
            setInserting(true);
            AddInstrumentData();
          }}
        >
          {inserting ? "Đang xử lý..." : "Tiến hành thêm tự động"}
        </Button>{" "}
        <Button color="danger" onClick={onClose}>
          Hủy
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
