import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/TargetPAKD`


const getAllTargetPAKDs = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}      
                                             
const createTargetPAKD = data => {
  return post(`${BASE_API_URL}/CreateTargetPAKD`,data)
}   

const updateTargetPAKDById = data => { 
  return put(`${BASE_API_URL}/UpdateTargetPAKD`, data)
}

const deleteTargetPAKDById = id => {
  return del(`${BASE_API_URL}/DeleteTargetPAKD?id=${id}`)
}


export {
    getAllTargetPAKDs,
    createTargetPAKD,
    updateTargetPAKDById,
    deleteTargetPAKDById,
}