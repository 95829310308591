import { Card, Button, Row, Col, Label, Input } from "reactstrap";
import Select from "react-select";
import { CustomButton } from "components/Common";
import { useEffect, useState } from "react";

const Index = ({
  filter,
  setFilter,
  isLoading,
  onSearch,
  setIsOpenModalInsertUpdate,
  setIsEdit,
  masterCategory,
}) => {
  const [selectCategory, setSelectCategory] = useState({});
  useEffect(() => {
    let sel = masterCategory.filter(x => x.value == filter.category);
    setSelectCategory(sel);
  }, [filter]);
  return (
    <>
      <Card className="p-3 my-2">
        <div className="w-100 d-flex">
          <div
            style={{ maxWidth: "600px", minWidth: "300px", marginRight: "8px" }}
          >
            <Input
              value={filter.search}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  onSearch();
                }
              }}
              placeholder="Tìm kiếm theo grouptest..."
              onChange={e =>
                setFilter(prev => ({ ...prev, groupTestCode: e.target.value }))
              }
            />
          </div>
          <div style={{ display: "flex", zIndex: 6 }}>
            <Select
              options={masterCategory || []}
              placeholder=""
              value={selectCategory}
              onChange={val => {
                 setSelectCategory(val);
                setFilter(prev => ({ ...prev, category: val.value }));
                // onSearch();                
              }}
              isDisabled={isLoading}
              styles={{
                control: base => ({
                  ...base,
                  border: `1px solid  "rgb(206, 212, 218)"`,
                  maxWidth: "300px",
                  width: "400px",
                }),
              }}
            />
            {selectCategory && (
              <Button
                onClick={() => {
                  setSelectCategory({});
                  setFilter(prev => ({ ...prev, category: "" }));
                  onSearch();
                }}
                className="clear-button"
                color="link"
              >
                <i className="mdi mdi-close"></i>
              </Button>
            )}
          </div>
          {/* <CustomButton
            color="success"
            onClick={() => {
              setIsEdit(false);
              setIsOpenModalInsertUpdate(true);
            }}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle"></i>
              <p className="mb-0 mx-1">Thêm mới</p>
            </div>
          </CustomButton> */}
        </div>
      </Card>
    </>
  );
};

export default Index;
