import React, { useEffect, useRef, useState } from "react";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  // Chart as ChartJS
} from "chart.js";
import Chart from "chart.js/auto";
import "./../../assets/scss/custom/components/_dashboard_barchart.scss"

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarCharResult = ({ data, loading }) => {
  let config = {
    type: "bar",
    data,
    options: {
      responsive: true,
      indexAxis: "y",
      layout: {
        padding: {
          bottom: -100,
         
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          afterFit: context => {
            context.height += 20;
          },
          grid: {
            drawTicks: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
            color: "transparent",
          },
        },
        y: {
          beginAtZero: true,
          afterFit: context => {},
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          // text: "Dashboard số lượng xét nghiệm",
        },
      },
    },
  };
  const chartRef = useRef(null);

  useEffect(() => {   
    let data2 = {
      labels: [],
      datasets :data?.datasets,
    };
    let chartStatus = Chart.getChart("myChart");
    let chartStatus2 = Chart.getChart("myChart2");
    if (chartStatus) {
      chartStatus.destroy();
    }
    if (chartStatus2) {
      chartStatus2.destroy();
    }
    let config2 = {
      type: "bar",
      data: data2,
      options: {
        maintainAspectRatio: false,
        indexAxis: "y",
        layout: {
          padding: {
            top: -100,
          },
        },
        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
            afterFit: context => {
              context.height += 10;
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            afterFit: context => {
              context.width += myChart.chartArea.left;
            },
            grid: {
              drawTicks: false,
              drawBorder: false,
            },
            // min: 0,
            // max: 10,
          },
        },
        //  responsive: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
    const scrollBoxBody = document.querySelector(".scrollBoxBody");
    const newHeight = ((data?.labels.length - 5) * 20);
    // scrollBoxBody.style.height = data?.labels.length < 4 ? `${newHeight}px` : scrollBoxBody.style.height;
    if (data?.labels.length < 10) {      
      config = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          indexAxis: "y",
          layout: {
            padding: {
              bottom: -100,
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              afterFit: context => {
                // context.height += 20;
              },
              grid: {
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                display: true,
                color: "transparent",
              },
            },
            y: {
              beginAtZero: true,         
              afterFit: context => {},
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              // text: "Dashboard số lượng xét nghiệm",
            },
          },
          maintainAspectRatio: false,
          // height: data.labels.length - 70,
        },
      };
    } else {     
      config = {
        type: "bar",
        data,
        options: {       
          responsive: true,
          maintainAspectRatio: true,         
          indexAxis: "y",
          layout: {
            padding: {
              bottom: 0,
            },
          },
          scales: {
            x: {
              stacked: true,
              beginAtZero: true,
              afterFit: context => {
                // context.height += 20;
              },
              grid: {
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                display: true,
                color: "transparent",
              },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              afterFit: context => {},
              // min: 0,
              // max: 10,
            },
          },
          // plugins: {
          //   legend: {
          //     display: false,
          //   },
          //   title: {
          //     display: true,
          //     // text: "Dashboard số lượng xét nghiệm",
          //   },
          // },
        },
      };
    }

    let myChart = new Chart(document.getElementById("myChart"), config);
    let myChart2  = new Chart(document.getElementById("myChart2"), config2);
  }, [data]);

  return (
    <>
      <div className="chartBox">
        <div className="scrollBox">
          <div className="scrollBoxBody">
            <canvas id="myChart" height="1000px" ref={chartRef}></canvas>
          </div>
        </div>
        <div className="box">
          <canvas id="myChart2" ref={chartRef}></canvas>
        </div>
      </div>
    </>
  );
};

export default BarCharResult;
