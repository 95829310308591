import { useEffect, useRef, useState } from "react";
import Filter from "./Filter";
import { statisticType } from "./constants";

import CombineStatMonthlyResultBySale from "./CombineStatMonthlyResultBySale/CombineStatMonthlyResultBySale";
import CombineStatMonthlyResultSaleByCategory from "./CombineStatMonthlyResultSaleByCategory/CombineStatMonthlyResultSaleByCategory";
import CombineStatMonthlyResultInstrumentSaleByHang from "./CombineStatMonthlyResultInstrumentSaleByHang/CombineStatMonthlyResultInstrumentSaleByHang";
import CombineStatMonthlyResultInstrumentSaleByGroup from "./CombineStatMonthlyResultInstrumentSaleByGroup/CombineStatMonthlyResultInstrumentSaleByGroup";
import CombineStatMonthlyResultLocationTypeSale from "./CombineStatMonthlyResultLocationTypeSale/CombineStatMonthlyResultLocationTypeSale";
import CombineStatMonthlyResultSaleByYear from "./CombineStatMonthlyResultSaleByYear/CombineStatMonthlyResultSaleByYear";
import CombineStatMonthlyResultSaleBySite from "./CombineStatMonthlyResultSaleBySite/CombineStatMonthlyResultSaleBySite";
import CombineStatMonthlyResultLocationTypeSaleByLocalLocation from "./CombineStatMonthlyResultLocationTypeSaleByLocalLocation/CombineStatMonthlyResultLocationTypeSaleByLocalLocation";
import CombineStatMonthlyResultSaleByGroupTest from "./CombineStatMonthlyResultSaleByGroupTest/CombineStatMonthlyResultSaleByGroupTest";
import CombineStatMonthlyResultByParentTest from "./CombineStatMonthlyResultSaleByParentTest/CombineStatMonthlyResultByParentTest";


const Index = ({ sites }) => {
  const [error, setError] = useState(null);
  const [isExportFile, setIsExportFile] = useState(false);
  const [selectValue, setSelectValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const reportTypesList = statisticType.getListSaleStatistic();

  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [filter, setFilter] = useState({
    siteCode: [],
    dateFrom: new Date("0001-01-01"),
    dateTo: new Date("0001-01-01"),
  });

  const [exportFilter, setExportFilter] = useState({
    site: null,
    reportType: reportTypesList[0],
  });

  return (
    <div>
      <Filter
        filter={filter}
        setFilter={setFilter}
        sites={sites}
        error={error}
        setError={setError}
        exportFilter={exportFilter}
        setExportFilter={setExportFilter}
        reportTypesList={reportTypesList}
        isLoading={false}
        setIsExportFile={setIsExportFile}
        setPaging={setPaging}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
      />     
      {exportFilter.reportType.value == "DOANHSO_LOAIXN" && (
        <CombineStatMonthlyResultBySale
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_NHOMXN" && (
        <CombineStatMonthlyResultSaleByCategory
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_HANG" && (
        <CombineStatMonthlyResultInstrumentSaleByHang
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_HANG_NHOMXN" && (
        <CombineStatMonthlyResultInstrumentSaleByGroup
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_KHOAPHONG" && (
        <CombineStatMonthlyResultLocationTypeSaleByLocalLocation
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_NHOMKHOAPHONG" && (
        <CombineStatMonthlyResultLocationTypeSale
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_NAM" && (
        <CombineStatMonthlyResultSaleByYear
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_BENHVIEN" && (
        <CombineStatMonthlyResultSaleBySite
          sites={sites}
          filterAll={filter}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setFilterAll={setFilter}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_BOXN" && (
        <CombineStatMonthlyResultSaleByGroupTest
          sites={sites}
          filterAll={filter}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setFilterAll={setFilter}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "DOANHSO_PARENT_TEST" && (
        <CombineStatMonthlyResultByParentTest
          sites={sites}
          filterAll={filter}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setFilterAll={setFilter}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default Index;
