
const convertMinutesToHHMM = minutes => {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;

  // Add leading zero if necessary
  var hoursString = hours < 10 ? "0" + hours : hours.toString();
  var minutesString =
    remainingMinutes < 10
      ? "0" + remainingMinutes
      : remainingMinutes.toString();

  return hoursString + ":" + minutesString;
};

const customColors = [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#3F51B5",
  "#03A9F4",
  "#4CAF50",
  "#F9CE1D",
  "#FF9800",
  "#A300D6",
  "#5653FE",
  "#2983FF",
];
export {
  convertMinutesToHHMM,
  customColors,
};
