import toastr from "toastr"
import "toastr/build/toastr.min.css"

//1: success, 2: warning, 3: error
const showToast = (message = "message", type = 1, time) => {
  // console.log(message, type, 'showToast');
  toastr.options = {
    positionClass: "toast-top-right",
    closeButton: true,
    showMethod: "slideDown",
    hideMethod: "slideUp",
    // preventDuplicates: true,
    timeOut: time|| 2000, 
  }
  if (type == 2)
    toastr.warning(message)
  else if (type == 3)
    toastr.error(message)
  else
    toastr.success(message)
}

export { showToast }
