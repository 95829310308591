import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import * as moment from "moment"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/instruments`

const addInstrument = (reqBody) => {
    return post(`${BASE_API_URL}/master-instrument`,reqBody)
}

const getInstrument = (instrumentCode) => {
    return get(`${BASE_API_URL}/master-instrument-${instrumentCode}`)
}

const getInstrumentBySiteCode = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/map-instrument?${q}`,reqBody)
}

const getMasterInstrument = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/get-master-instrument?${q}`,reqBody)
}

const InsertInstrumentManualApi = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/map-instrument-manual?${q}`,reqBody)
}

const UpdateInstrumentManualApi = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return put(`${BASE_API_URL}/map-instrument-manual?${q}`,reqBody)
}

const GetListLabelValueMasterInstrumentApi = (parameters) => {
    let q = spreadSearchQuery(parameters)
    return get(`${BASE_API_URL}/label-value-master-instrument?${q}`)
}

export {
    addInstrument,
    getInstrument,
    getInstrumentBySiteCode,
    getMasterInstrument,
    InsertInstrumentManualApi,
    UpdateInstrumentManualApi,
    GetListLabelValueMasterInstrumentApi
}