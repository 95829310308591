import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/master-testcode`;


const InsertMasterTestCodeApi = reqBody => {
  return post(`${BASE_API_URL}/`, reqBody);
};

const UpdateMasterTestCodeApi = reqBody => {
  return put(`${BASE_API_URL}/`, reqBody);
};

const DeleteMasterTestCodeApi = reqBody => {
  return post(`${BASE_API_URL}/delete`, reqBody);
};

const GetLisLabelValueMasterTestCodeApi = () => {
  return get(`${BASE_API_URL}/ls-label-value`);
};

export { 
  InsertMasterTestCodeApi,
  UpdateMasterTestCodeApi,
  DeleteMasterTestCodeApi,  
  GetLisLabelValueMasterTestCodeApi
};
