import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/master-instrument-type`;

const GetListMasterInstrumentTypesApi = payload => {
  let q = spreadSearchQuery(payload);
  return get(`${BASE_API_URL}/ls-master-instrument-type?${q}`);
};

const InsertMasterInstrumentTypeApi = reqBody => {
  return post(`${BASE_API_URL}/`, reqBody);
};

const UpdateMasterInstrumentTypeApi = reqBody => {
  return put(`${BASE_API_URL}/`, reqBody);
};

const DeleteMasterInstrumentTypeApi = reqBody => {
  return post(`${BASE_API_URL}/delete`, reqBody);
};

const GetLisLabelValueMasterInstrumentApi = () => {
  return get(`${BASE_API_URL}/ls-label-value`);
};

export {
  GetListMasterInstrumentTypesApi,
  InsertMasterInstrumentTypeApi,
  UpdateMasterInstrumentTypeApi,
  DeleteMasterInstrumentTypeApi,
  GetLisLabelValueMasterInstrumentApi
};
