import React from "react";
import { Spin, Typography } from "antd";

const CustomCard = ({ style, children }) => (
  <div
    style={{
      width: "100%",
      backgroundColor: "#FFF",
      minHeight: "105px",
      borderRadius: "8px",
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      ...style,
    }}
  >
    {children}
  </div>
);

const CustomBox = ({ title, value, isActive, onClick }) => (
  <div
    style={{
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
      padding: "10px 15px",
      border: `1px solid ${isActive ? "#007bff" : 'transparent'}`,
      borderRadius: "10px",
      fontSize: "1.2rem",
      fontWeight: "bold",
      cursor: "pointer",
      background: "#FFF",
      color: isActive ? "#007bff" : "#2e2d2e",
      whiteSpace: 'nowrap',
    }}
    onClick={() => onClick(title, value)}
  >
    {title}
  </div>
);

const LoadingBackground = () => (
  <div
    style={{
      position: "absolute",
      top: 8,
      borderRadius: "8px",
      width: "99%",
      height: "97%",
      backgroundColor: "rgba(255,255,255,.85)",
    }}
  />
);

const LoadingText = () => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Spin size="large" className="ml-3 mb-2" />
    <Typography.Title level={5} style={{ color: "#0080ff" }}>
      Đang tải...
    </Typography.Title>
  </div>
);

export { CustomCard, CustomBox, LoadingBackground, LoadingText };
