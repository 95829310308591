import Select from "react-select";
import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import { DISPLAY_MONTH_YEAR_FORMAT } from "constant";
import DatePicker, { registerLocale } from "react-datepicker";
import { InputAutoFormat, CustomSelect } from "components/Common";
import vi from "date-fns/locale/vi";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect, useState } from "react";

const Index = ({
  sites = [],
  filter,
  setFilter,
  error,
  onSearch,
  setError,
  isLoading,
  setPaging,
  selectValue,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!selectValue || selectValue.length < 0) return;
    setData(selectValue.filter(x => x.value == "all"));
  }, [selectValue || sites]);

  const HandleSubmit = val => {
    let filterAll = val.filter(x => x.value == "all");
    if (filterAll.length > 0) {
      setData(filterAll);
      setFilter(prev => ({
        ...prev,
        siteCode: sites.map(item => item.value),
      }));
    } else {
      setData(val);
      setFilter(prev => ({
        ...prev,
        siteCode: val.map(item => item.value),
      }));
    }
    setPaging(prev => ({ ...prev, currentPage: 1 }));
  };

  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">SiteName</Label>
            <div style={{ flex: 1, marginLeft: "6px" }}>
              <Select
                className="custom-select"
                isMulti
                options={selectValue}
                placeholder="Chọn bệnh viện"
                value={data}
                onChange={val => {
                  HandleSubmit(val);
                }}
                isDisabled={isLoading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>
        <Col md={6} lg={6} xl={5} className="mb-2">
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              Thời gian
            </Label>
            <div style={{ width: "125px" }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={
                  filter.statMonthFrom.getFullYear() == 1
                    ? ""
                    : filter.statMonthFrom
                }
                onChange={date => {
                  setFilter(prev => ({ ...prev, statMonthFrom: date }));
                  if (error) {
                    setError(null);
                  }
                }}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                disabled={isLoading}
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>

            <i className="bx bxs-right-arrow"></i>
            <div style={{ width: "125px" }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={
                  filter.statMonthTo.getFullYear() == 1
                    ? ""
                    : filter.statMonthTo
                }
                onChange={date => {
                  if(date){
                    setFilter(prev => ({ ...prev, statMonthTo: date }));
                  }else{
                    setFilter(prev => ({ ...prev, dateTo: new Date("0001-01-01") }));
                  }
                  if (error) {
                    setError(null);
                  }
                }}
                disabled={isLoading}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          {error && error.date ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.date}
            </p>
          ) : null}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} lg={6} xl={5}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              MasterTestcode
            </Label>
            <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
              <Input
                value={filter.masterTestcode}
                disabled={isLoading}
                onChange={e => {
                  setFilter(prev => ({
                    ...prev,
                    masterTestcode: e.target.value,
                  }));
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col md={6} lg={6} xl={5}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate" style={{ minWidth: "100px" }}>
              CommonName
            </Label>
            <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
              <Input
                value={filter.commonName}
                disabled={isLoading}
                onChange={e => {
                  setFilter(prev => ({
                    ...prev,
                    commonName: e.target.value,
                  }));
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col md={12} lg={12} xl={2}>
          <Button
            color="primary"
            className="btn-hover-shine"
            onClick={onSearch}
            disabled={isLoading}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-search mr-1" style={{ fontSize: "1rem" }}></i>
              <p className="mx-1 mb-0">
                {isLoading ? "Đang tìm.." : "Tìm kiếm"}
              </p>
            </div>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
