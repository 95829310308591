import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "components/Common/InputText";
import { showToast } from "components/Common";
import MapTestCodeService from "services/TestCode";
import Select from "react-select";

const Schema = Yup.object().shape({
  testCodeLis: Yup.string().required("Không được bỏ trống"),
});

const Index = ({ isOpen, onClose, filter, data, isEdit, setIsDone }) => {
  const [loading, setIsLoading] = useState(false);
  const [selectMasterTestcode, setSelectMasterTestcode] = useState([]);
  const [dataMasterTestcode, setDataMasterTestcode] = useState([]);
  const getDataMasterTestcode = async () => {
    const res = await MapTestCodeService.getLsLabelValueMasterTestCodeService({
      search: "",
    });
    res.unshift({ value: null, label: "Giá trị rỗng(NULL)" });
    setDataMasterTestcode(res);
  };

  const formik = useFormik({
    initialValues: {
      testCodeLis: data?.testCodeLis || "",
      testNameLis: data?.testNameLis || "",
      mapMasterTestcode: data?.mapMasterTestcode || "",
    },
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      setIsLoading(true);
      if (isEdit) {
        updateInstrument(values);
      } else {
        addNewInstrument(values);
      }
    },
  });

  const getRequestParams = (_params = {}, _requestBody = {}) => {
    const reqParams = {
      params: {
        siteCode: filter.site ? filter.site.value : null,
        ..._params,
      },
      requestBody: {
        ..._requestBody,
      },
    };
    return reqParams;
  };
  const addNewInstrument = async (_requestBody = {}, _params = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    const res = await MapTestCodeService.InsertTestCodeManualService(
      params,
      requestBody
    );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
    } else {
      showToast("Thêm thành công", 1);
      setIsDone(true);
      onClose();
    }
  };
  const updateInstrument = async (_requestBody = {}, _params = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    const res = await MapTestCodeService.UpdateTestCodeManualService(
      params,
      requestBody
    );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
      setIsDone(true);
    } else {
      showToast("Cập nhật thành công", 1);
      setIsDone(true);
      onClose();
    }
  };
  useEffect(() => {
    if (dataMasterTestcode.length > 0) return;
    getDataMasterTestcode();
  }, []);

  useEffect(() => {
    if (!dataMasterTestcode || !dataMasterTestcode.length) return;
    let selectMasterTestcode = dataMasterTestcode.find(
      x => x.value == data?.mapMasterTestcode
    );
    if (selectMasterTestcode) {
      setSelectMasterTestcode(selectMasterTestcode);
    } else {
      setSelectMasterTestcode(dataMasterTestcode[0]);
    }
  }, [dataMasterTestcode]);

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "650px", width: "100%" }}>
      <ModalHeader>
      {isEdit ? "Cập nhật" : "Thêm mới" } testcode bệnh viện {filter.site?.label}
      </ModalHeader>
      <ModalBody>
        <div>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={6} lg={8}>
              <InputText
                label="TestCodeLis"
                name="testCodeLis"
                onChange={e => {
                  formik.handleChange(e);
                  formik.setErrors(null);
                }}
                value={formik.values.testCodeLis}
                errors={formik.errors}
                disabled={loading || isEdit}
                isRequired
              />
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={6} lg={8}>
              <InputText
                label="TestNameLis"
                name="testNameLis"
                onChange={e => {
                  formik.handleChange(e);
                  formik.setErrors(null);
                }}
                value={formik.values.testNameLis}
                errors={formik.errors}
                disabled={loading}
              />
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={6} lg={8}>
              <Label
                className="mb-0 text-truncate"
                style={{ minWidth: "98px" }}
              >
                MapMasterTestcode
              </Label>
              <div style={{ display: "flex" }}>
                <Select
                  options={dataMasterTestcode || []}
                  placeholder=""
                  value={selectMasterTestcode}
                  onChange={val => {
                    formik.setFieldValue("mapMasterTestcode", val.value);
                    setSelectMasterTestcode(val);
                  }}
                  isDisabled={loading}
                  styles={{
                    control: base => ({
                      ...base,
                      border: `1px solid  "rgb(206, 212, 218)"`,
                      maxWidth: "400px",
                      width: "400px",
                    }),
                  }}
                />
                {selectMasterTestcode && (
                  <Button
                    onClick={() => {
                      formik.setFieldValue("mapMasterTestcode", null);
                      setSelectMasterTestcode({
                        value: null,
                        label: "Giá trị rỗng(NULL)",
                      });
                    }}
                    className="clear-button"
                    color="link"
                  >
                    <i className="mdi mdi-close"></i>
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
        {loading && (
          <Row
            style={{ width: "80%", justifyContent: "center", display: "flex" }}
          >
            <Label>
              Quá trình xử lí này có thể mất vài phút, vui lòng chờ đến khi kết
              thúc...
            </Label>
            <div className="mt-1">
              <Progress color="primary" animated value="100" />
            </div>
          </Row>
        )}
        <div>
          <Button
            disabled={loading}
            color="primary"
            onClick={formik.handleSubmit}
          >
            Đồng ý
          </Button>{" "}
          <Button color="danger" onClick={onClose}>
            Đóng
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
