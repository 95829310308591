import React from "react"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

const SweetAlertCustom = ({ title, show, message, onConfirm }) => {
  if (!show) return null
  return (
    <SweetAlert warning title={title} onConfirm={onConfirm}>
      {message}
    </SweetAlert>
  )
}

SweetAlertCustom.defaultProps = {
  title: "title",
  message: "message",
  onConfirm: () => {},
}

export default SweetAlertCustom
