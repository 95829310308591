import React from "react"
import CustomBootstrapTableAsync from "./CustomBootstrapTableAsync"
import CustomBootstrapTable from "./CustomBootstrapTable"

const CustomBootstrapTableWrapper = ({ isScrollable, ...rest }) => {
  if (!isScrollable) return <CustomBootstrapTableAsync {...rest} />
  else return <CustomBootstrapTable isScrollable {...rest} />
}

export default CustomBootstrapTableWrapper
