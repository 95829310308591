import ContextManager from "./ContextManager";

class DrawManager {
    static drawLine(x1, y1, x2,y2, stroke = 'black', width = 3) {
        const ctx = ContextManager.getContext();
        if(!ctx) return;

        ctx.beginPath();
        // Từ điểm A
        ctx.moveTo(x1, y1);

        // Đến điểm B
        ctx.lineTo(x2, y2);
    
        // Màu sắc
        ctx.strokeStyle = stroke;
    
        // Độ rộng
        ctx.lineWidth = width;
    
        ctx.stroke();
    }

    static drawImage({src, x = 0, y = 0, width: _width, height: _height}) {
        const ctx = ContextManager.getContext();
        if(!ctx) return;
    
    
        let width = _width , height = _height;
        if(!_width || !_height) {
            const {width: canvasWidth, height: canvasHeight} = ContextManager.getCanvasSize();
            width = canvasWidth;
            height = canvasHeight;
        }
    
        const image = new Image();
        image.src = src;
        image.onload = () => {
            ctx.drawImage(image, x, y, width, height);
        }
    }

    static drawTextInsideCircle({
        x,
        y,
        radius = 15,
        number = "100",
        bgColor = "#04bd35",
        fontSize = 10,
        color = '#FFF',
        borderColor= '#FFF',
      }) {
        const ctx = ContextManager.getContext();
        if (!ctx) return;
        var centerX = x;
        var centerY = y;
    
        ctx.beginPath();
        ctx.fillStyle = bgColor;
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.strokeStyle = borderColor;
        ctx.lineWidth = 2;
        ctx.font = `bold ${fontSize}pt Calibri`;
        ctx.textAlign = "center";
        ctx.fillStyle = color;
    
        // Defining the `textBaseline`…
        ctx.textBaseline = "middle";
    
        ctx.fillText(number, centerX, centerY);
        ctx.stroke();
      }

    static async asyncDraw(src, x, y, w, h) {
        return new Promise((resolve, reject) => {
            const ctx = ContextManager.getContext();
            const imgToDraw = new Image();
            imgToDraw.src = src;
            imgToDraw.onload = () => {
                ctx.drawImage(imgToDraw, x, y, w, h);
                resolve();
            };
            imgToDraw.onerror = reject;
        });
    }

    static drawRect(x, y, width, height, color = 'black') {
        const ctx = ContextManager.getContext();
        if(!ctx) return;
    
        ctx.fillStyle = color;
        ctx.fillRect(x, y, width, height);
    }

    static drawText({x = 0, y = 0, text = '', style = {}}) {
        const ctx = ContextManager.getContext();
        if(!ctx) return;
    
        const fontStyle = {
            fontSize: '12px', color: 'black',
            ...style
        };
    
        ctx.fillStyle = fontStyle.color;
        ctx.font = `bold ${fontStyle.fontSize} Roboto`;
        ctx.textAlign = "center";
        ctx.textBaseline = 'middle';
    
        ctx.fillText(text, x , y);
    }

    // static clearCanvas(ctx, width, height) {
    //     ctx.clearRect(0, 0, width, height);
    // }
}

export default DrawManager;