import Select from "react-select";
import { Button, Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import "./../../../assets/scss/custom/components/_switchbutton.scss";
import { useState } from "react";
import { InputAutoFormat } from "components/Common";
const Index = ({
  filter,
  setFilter,
  error,
  setError,
  setPaging,
  isLoading,
  onSearch,
}) => {
  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={6} lg={5} xl={2} className="my-1">
          <FormGroup switch className="d-flex align-items-center">
            <Label check style={{ width: "100px" }} className="fw-bold">
              Đã có giá?
            </Label>
            <Input
              checked={filter.isNullPrice}
              type="switch"
              role="switch"
              style={{ height: "30px", width: "50px" }}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  isNullPrice: !filter.isNullPrice,
                }));
                onSearch();
              }}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={6} lg={5} xl={4} className="my-1">
          <div className="d-flex align-items-center" style={{width: "350px"}}>
          <div style={{width: "80px", display: "flex", alignItems: "center", justifyContent: "center"}}><Label className="mb-0 text-truncate fw-bold">CountTest</Label></div>
            <div className="mx-1">
              <InputAutoFormat
                defaultValue={filter.countTestFrom ? filter.countTestFrom : ""}
                style={{
                  width: "150px",
                  borderColor: error && error.count ? "red" : "#ced4da",
                }}
                disabled={isLoading}
                maxLength={10}
                // onChange={e => {
                //   let val = e.target.value
                //   console.log(val,  val || val === "0" ? parseInt(val) : undefined)
                //   setFilter(prev => ({
                //     ...prev,
                //     countTestFrom:
                //       val || val === "0" ? parseInt(val) : undefined,
                //   }));
                //   if (error) {
                //     setError(null);
                //   }
                // }}
                onValueChange={val => {
                  setFilter(prev => ({
                    ...prev,
                    countTestFrom:
                      val || val === "0" ? parseInt(val) : undefined,
                  }));
                  if (error) {
                    setError(null);
                  }
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
            <i className="bx bxs-right-arrow"></i>
            <div className="mx-1">
              <InputAutoFormat
                defaultValue={
                  filter.countTestTo
                    ? filter.countTestTo
                    : ""
                }
                style={{
                  width: "150px",
                  borderColor: error && error.count ? "red" : "#ced4da",
                }}
                disabled={isLoading}
                maxLength={10}
                onValueChange={val => {
                  setFilter(prev => ({
                    ...prev,
                    countTestTo: val || val === "0" ? parseInt(val) : undefined,
                  }));
                  if (error) {
                    setError(null);
                  }
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          {error && error.count ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.count}
            </p>
          ) : null}
        </Col>     
        <Col sm={12} md={6} lg={5} xl={4} className="my-1">
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate fw-bold">MasterTestcode</Label>
            <div style={{ flex: 1, marginLeft: "6px" }}>
              <Input
                value={filter.masterTestcode}
                disabled={isLoading}
                onChange={e =>
                  setFilter(prev => ({
                    ...prev,
                    masterTestcode: e.target.value,
                  }))
                }
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    onSearch()
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={5} xl={2}>
          <Button
            color="primary"
            className="btn-hover-shine"
            onClick={onSearch}
            disabled={error || isLoading}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-search mr-1" style={{ fontSize: "1rem" }}></i>
              <p className="mx-1 mb-0">
                {isLoading ? "Đang tìm..." : "Tìm kiếm"}
              </p>
            </div>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
