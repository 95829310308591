import React, { useRef, useState, useEffect } from "react";
import InstrumentService from "services/Instrument";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ManualAddModal from "./ManualAddModal";
import { ConfirmModal, showToast } from "components/Common";
import MasterInstrumentService from "services/MasterInstrument";
const Index = ({ masterInstrumentType }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [detailData, setDetailData] = useState(false);
  const [filter, setFilter] = useState({
    instrumentCode: "",
    instrumentName: "",
    producerCode: "",
    producerName: "",
    instrumentType: ""
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [isModalInsertUpdate, setIsModalInsertUpdate] = useState(false);
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        instrumentCode: filter.instrumentCode,       
        producerCode: filter.producerCode,      
        instrumentType: filter.instrumentType,
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await InstrumentService.getMasterInstrument(
        params,
        requestBody
      );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setLoading(false);
      setIsDone(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  const getRequestParamsSearch = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: 1,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        instrumentCode: filter.instrumentCode,       
        producerCode: filter.producerCode,      
        instrumentType: filter.instrumentType,
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableDataSearch = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParamsSearch(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await InstrumentService.getMasterInstrument(
        params,
        requestBody
      );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setLoading(false);
      setIsDone(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };
  const onDeleteDetail = async () => {
    const res =
      await MasterInstrumentService.DeleteMasterInstrumentService(
        detailData
      );
    if (res.error) {
      showToast(`${res.error.title}`, 3);
      setLoading(false);
      setIsDone(true);
    } else {
      showToast(`Xóa thành công ${detailData?.instrumentCode}`, 1);
      setIsDone(true);    
      setIsModalConfirmDelete(false); 
    }
  };
  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging, isDone]);
  
  useEffect(() => {
    getTableData({ page: 1 });
  }, [filter.instrumentType]);
  return (
    <>
      <Filter
        setIsEdit={setIsEdit}
        filter={filter}
        setFilter={setFilter}
        isLoading={loading}
        onSearch={() => {
          setPaging(prev => ({ ...prev, currentPage: 1 }));
          getTableData({ page: 1 });
        }}
        setIsModalInsertUpdate={setIsModalInsertUpdate}
        masterInstrumentType={masterInstrumentType}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setIsEdit={setIsEdit}
        setIsModalInsertUpdate={setIsModalInsertUpdate}
        setIsModalConfirmDelete={setIsModalConfirmDelete}
        setDetailData={setDetailData}
      />
      {isModalInsertUpdate && (
        <ManualAddModal
          setDetailData={setDetailData}
          isEdit={isEdit}
          data={isEdit ? detailData : {}}
          setIsDone={setIsDone}
          masterInstrumentType={masterInstrumentType}
          isOpen={isModalInsertUpdate}
          onClose={() => {
            setIsModalInsertUpdate(false);
            setDetailData({});
          }}
        />
      )}
      {isModalConfirmDelete && (
        <ConfirmModal
          modal={isModalConfirmDelete}
          title="Xác nhận xóa?"
          message={`Bạn có chắc chắn muốn xóa instrument ${detailData?.instrumentCode}?`}
          onToggle={() => {
            setIsModalConfirmDelete(false);
          }}
          onDelete={onDeleteDetail}
        />
      )}
    </>
  );
};

export default Index;
