import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "components/Common/InputText";
import { showToast } from "components/Common";
import MasterGroupTestService from "services/MasterGroupTest";
import Select from "react-select";

const Schema = Yup.object().shape({
  groupTestCode: Yup.string().required("Không được bỏ trống"),
});

const Index = ({
  isOpen,
  onClose,
  filter,
  data,
  isEdit,
  setIsDone,
  masterCategory,
  masterTestCode,
}) => {
  const [loading, setIsLoading] = useState(false);
  const [selectCategory, setSelectCategory] = useState({});
  const [selectTestCode, setSelectTestCode] = useState([]);
  const formik = useFormik({
    initialValues: {
      groupTestCode: data?.groupTestCode || "",
      groupTestName: data?.groupTestName || "",
      category: data?.category || null,
    },
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      setIsLoading(true);
      if (isEdit) {
        updateMasterGroupTest(values);
      } else {
        addMasterGroupTest(values);
      }
    },
  });

  const getRequestParams = (_params = {}, _requestBody = {}) => {
    const reqParams = {
      params: {
        siteCode: filter.site ? filter.site.value : null,
        ..._params,
      },
      requestBody: {
        ..._requestBody,
      },
    };
    return reqParams;
  };

  const addMasterGroupTest = async (_requestBody = {}, _params = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    const res = await MasterGroupTestService.InsertMasterGroupTestService(
      requestBody
    );

    if (res.error) {
      showToast(`${res.error.title}`, 3);
      setIsLoading(false);
    } else {
      showToast("Thêm mới bộ xét nghiệm thành công", 1);
      // setIsDone(true);
      // onClose();
    }
    addTestCodeToGroupTest();
  };

  const updateMasterGroupTest = async (_requestBody = {}, _params = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    const res = await MasterGroupTestService.UpdateMasterGroupTestService(
      requestBody
    );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
      setIsDone(true);
    } else {
      showToast("Cập nhật bộ xét nghiệm thành công", 1);
      // setIsDone(true);
      // onClose();
    }
    addTestCodeToGroupTest();
  };

  const addTestCodeToGroupTest = async () => {
    const params = { groupTestCode: formik.values.groupTestCode };
    const requestBody = selectTestCode.map(x => x.value);
    const res =
      await MasterGroupTestService.InsertTestCodeToMasterGroupTestService(
        params,
        requestBody
      );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
      setIsDone(true);
    } else {
      showToast(`${isEdit ? "Cập nhật" : "Thêm mới"} xét nghiệm thương mại thành công`, 1);
      setIsDone(true);
      onClose();
    }
  };

  useEffect(() => {
    if (isEdit) {
      let sel = masterCategory.filter(x => x.value == data?.category);
      setSelectCategory(sel);
      let selectTestCode = data.lsMasterTestCode;
      setSelectTestCode(selectTestCode);
    }
  }, []);
  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "650px", width: "100%" }}>
      <ModalHeader style={{ backgroundColor: "#d5d8ef" }}>
        {isEdit ? "Cập nhật" : "Thêm mới"} bộ xét nghiệm
      </ModalHeader>
      <ModalBody>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <InputText
              label="Mã bộ xét nghiệm"
              name="groupTestCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.groupTestCode}
              errors={formik.errors}
              disabled={loading || isEdit}
              isRequired
            />
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <InputText
              label="Tên bộ xét nghiệm"
              name="groupTestName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.groupTestName}
              errors={formik.errors}
              disabled={loading || isEdit}
            />
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <Label className="mb-0 text-truncate" style={{ minWidth: "98px" }}>
              Category
            </Label>
            <div style={{ display: "flex" }}>
              <Select
                options={masterCategory || []}
                placeholder=""
                value={selectCategory}
                onChange={val => {
                  formik.setFieldValue("category", val.value);
                  setSelectCategory(val);
                }}
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid  "rgb(206, 212, 218)"`,
                    maxWidth: "400px",
                    width: "400px",
                  }),
                }}
              />
              {selectCategory && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("category", null);
                    setSelectCategory({});
                  }}
                  className="clear-button"
                  color="link"
                >
                  <i className="mdi mdi-close"></i>
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <Label
              className="mb-0 text-truncate"
              style={{ minWidth: "98px", marginTop: 7 }}
            >
              Xét nghiệm thương mại thuộc bộ xét nghiệm
            </Label>
            <div style={{ display: "flex" }}>
              <Select
                className="custom-select"
                isMulti
                options={masterTestCode || []}
                placeholder=""
                value={selectTestCode}
                onChange={val => {
                  setSelectTestCode(val);
                }}
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid  "rgb(206, 212, 218)"`,
                    maxWidth: "400px",
                    width: "400px",
                  }),
                }}
              />
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
        {loading && (
          <Row
            style={{ width: "80%", justifyContent: "center", display: "flex" }}
          >
            <Label>Đang xử lý...</Label>
            <div className="mt-1">
              <Progress color="primary" animated value="100" />
            </div>
          </Row>
        )}
        <div>
          <Button
            disabled={loading}
            color="primary"
            onClick={formik.handleSubmit}
          >
            Đồng ý
          </Button>{" "}
          <Button color="danger" onClick={onClose}>
            Đóng
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
