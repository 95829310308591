// const formatNumber2 = (number) => number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
const formatNumber = number => {
    const numStr = (number || 0).toString();
    const [_, decimal] = numStr.split(".");
  
  
    // Trả về chuỗi đã được format
    if (decimal) {
      return (number || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else {
      return numStr.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
};

export {formatNumber}