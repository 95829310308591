import {
  configKey,
  IS_RENDER_SECTION,
  IS_RENDER_SECTION_TEXT,
  SECTION_BACKGROUND,
  SECTION_TEXT_COLOR,
  IS_RENDER_PATH,
  PATH_COLOR,
  PATH_WIDTH,
  SPEED,
  DELAY_TIME,
  FONT_SIZE,
  IS_RENDER_ICON,
  ICON_SIZE,
  IS_RENDER_SID,
  SID_COLOR,
  IS_RENDER_SAMPLE_TYPE,
  SAMPLE_TYPE_COLOR,
  SECTION_HIGHTLIGHT_BACKGROUND,
  IS_RENDER_SECTION_HIGHTLIGHT,
  IS_CALC_DISTANCE_IN_PROCESS,
  RENDER_SAMPLE_NUMBER,
  SAMPLE_NUMBER_NORMAL_BG,
  SAMPLE_NUMBER_NORMAL_BG_HOVER,
  SAMPLE_NUMBER_WARNING_BG,
  SAMPLE_NUMBER_WARNING_BG_HOVER,
  SAMPLE_NUMBER_CIRCLE_DIAMETER,
  SAMPLE_NUMBER_FONT_SIZE,
  SAMPLE_NUMBER_COLOR,
  SAMPLE_NUMBER_BORDER_COLOR,
} from "../constants";

const ConfigManager = (function () {
  let instance;
  const defaultConfig = {
    [configKey.RENDER_SECTION_BACKGROUND]: IS_RENDER_SECTION,
    [configKey.RENDER_SECTION_TEXT]: IS_RENDER_SECTION_TEXT,
    [configKey.SECTION_BACKGROUND]: SECTION_BACKGROUND,
    [configKey.SECTION_TEXT_COLOR]: SECTION_TEXT_COLOR,
    [configKey.RENDER_PATH]: IS_RENDER_PATH,
    [configKey.PATH_COLOR]: PATH_COLOR,
    [configKey.PATH_WIDTH]: PATH_WIDTH,
    [configKey.SPEED]: SPEED,
    [configKey.DELAY_TIME]: DELAY_TIME,
    [configKey.FONT_SIZE]: FONT_SIZE,
    [configKey.RENDER_ICON]: IS_RENDER_ICON,
    [configKey.ICON_SIZE]: ICON_SIZE,
    [configKey.SID_COLOR]: SID_COLOR,
    [configKey.RENDER_SID]: IS_RENDER_SID,
    [configKey.RENDER_SAMPLE_TYPE]: IS_RENDER_SAMPLE_TYPE,
    [configKey.SAMPLE_TYPE_COLOR]: SAMPLE_TYPE_COLOR,
    [configKey.SECTION_HIGHTLIGHT_BACKGROUND]: SECTION_HIGHTLIGHT_BACKGROUND,
    [configKey.RENDER_SECTION_HIGHTLIGHT_BACKGROUND]: IS_RENDER_SECTION_HIGHTLIGHT,
    [configKey.IS_CALC_DISTANCE_IN_PROCESS]: IS_CALC_DISTANCE_IN_PROCESS,
    [configKey.RENDER_SAMPLE_NUMBER]: RENDER_SAMPLE_NUMBER,
    [configKey.SAMPLE_NUMBER_NORMAL_BG]: SAMPLE_NUMBER_NORMAL_BG,
    [configKey.SAMPLE_NUMBER_NORMAL_BG_HOVER]: SAMPLE_NUMBER_NORMAL_BG_HOVER,
    [configKey.SAMPLE_NUMBER_WARNING_BG]: SAMPLE_NUMBER_WARNING_BG,
    [configKey.SAMPLE_NUMBER_WARNING_BG_HOVER]: SAMPLE_NUMBER_WARNING_BG_HOVER,
    [configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER]: SAMPLE_NUMBER_CIRCLE_DIAMETER,
    [configKey.SAMPLE_NUMBER_FONT_SIZE]: SAMPLE_NUMBER_FONT_SIZE,
    [configKey.SAMPLE_NUMBER_COLOR]: SAMPLE_NUMBER_COLOR,
    [configKey.SAMPLE_NUMBER_BORDER_COLOR]: SAMPLE_NUMBER_BORDER_COLOR,
  };

  function init() {
    let config = { ...defaultConfig };

    return {
      init() {
        const ts = localStorage.getItem("mapConfig");
        if (ts) {
          const oldConfig = JSON.parse(ts);
          config = { ...config, ...oldConfig };
        }
      },

      getDefault() {
        return defaultConfig;
      },

      getConfig: function () {
        return config;
      },

      setConfig: function (newConfig) {
        config = { ...newConfig };
        localStorage.setItem("mapConfig", JSON.stringify(config));
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance)
        instance = {
          ...init(),
        };
      return instance;
    },
  };
})();

export default ConfigManager;
