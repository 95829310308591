import {
  getPatients,
  getResults,
  getResultInstruments,
  getResultDepartments,
} from "helpers/app-backend/report_helpers";
import handleReponse from 'utils/handleReponse'


const getListPatients = async (paramters, reqBody) => {
  try {
    const res = await getPatients(paramters, reqBody);
    return handleReponse.getListSuccess(res);
  } catch (err) {
    return handleReponse.getListFail();
  }
};

const getListResults = async (paramters, reqBody) => {
  try {
    const res = await getResults(paramters, reqBody);
    return handleReponse.getListSuccess(res);
  } catch (err) {
    return handleReponse.getListFail();
  }
};

const getListResultsInstrument = async (paramters, reqBody) => {
  try {
    const res = await getResultInstruments(paramters, reqBody);
    return handleReponse.getListSuccess(res);
  } catch (err) {
    return handleReponse.getListFail();
  }
};

const getListResultsDepartment = async (paramters, reqBody) => {
  try {
    const res = await getResultDepartments(paramters, reqBody);
    return handleReponse.getListSuccess(res);
  } catch (err) {
    return handleReponse.getListFail();
  }
};

export default {
  getListPatients,
  getListResults,
  getListResultsInstrument,
  getListResultsDepartment,
};
