import React from "react";
import { Spin, Typography, Radio } from "antd";
import { Card } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const Index = ({
  windowDimensions,
  dataChart,
  formatCurrencyVND,
  dataLastYear12,
}) => {
  const limitedDataChart = dataChart.slice(0, 12);

  const series = [
    {
      name: "Doanh số 2023",
      type: "column",
      data: dataLastYear12,
    },
    {
      name: "Doanh số actual 2024",
      type: "column",
      data: limitedDataChart.map(item => item.value),
    },
    {
      name: "Target 2024",
      type: "column",
      data: limitedDataChart.map(item => item.target),
    },
    {
      name: "Achivement (%)",
      type: "line",
      data: limitedDataChart.map(item => item.achievementPercentage),
    },
    {
      name: "Growth (%)",
      type: "line",
      data: limitedDataChart.map(item => item.investmentEfficiencyPercentage),
    },
  ];
  const findMaxValue = () => {
    const columnData = series
      .filter(item => item.type === "column")
      .map(item => item.data)
      .flat();
    const lineData = series
      .filter(item => item.type === "line")
      .map(item => item.data)
      .flat();

    const maxValueColumn = Math.max(...columnData);
    const maxValueLine = Math.max(...lineData);

    return { maxValueColumn, maxValueLine };
  };

  const { maxValueColumn, maxValueLine } = findMaxValue();

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [3,4],
      formatter: (value) => {
          return `${value.toFixed(2)}%`;
      }
  },
    colors: ["#4472c4", "#ed7d31", "#34c38f", "#a5a5a5", "#ffc000"],

    stroke: {
      width: [1, 1, 1, 4, 4],
    },
    title: {
      text: "Hiệu quả đầu tư",
      align: "center",
      offsetX: 110,
      style: {
        fontSize: "16px",
        fontFamily: "Times New Roman, serif",
      },
    },
    xaxis: {
      categories: [
        "Tháng 1",
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
        "Tháng 9",
        "Tháng 10",
        "Tháng 11",
        "Tháng 12",
      ],
    },
    yaxis: [
      {
        min: 0,
        max: maxValueColumn,
        seriesName: "Income",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return (value / 1e9).toFixed(0) + " tỷ";
          },
          style: {
            colors: "#74788d",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        min: 0,
        max: maxValueColumn,
        show: false,
        seriesName: "Cashflow",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return (value / 1e9).toFixed(0) + " tỷ";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
      {
        min: 0,
        max: maxValueColumn,
        show: false,
        seriesName: "Cashflow",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return (value / 1e9).toFixed(0) + " tỷ";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
      {
        min: 0,
        max: maxValueLine,
        show: false,
        seriesName: "Revenue",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
      {
        min: 0,
        max: maxValueLine,
        seriesName: "Revenue",
        opposite: true,

        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft",
        offsetY: 30,
        offsetX: 60,
      },
      enabled: true,
      y: {
        formatter: function (value, { seriesIndex }) {
          if (seriesIndex === 3) {
            return value.toFixed(3) + "%";
          } else if (seriesIndex == 4) {
            return value.toFixed(4) + "%";
          } else {
            return formatCurrencyVND(value);
          }
        },
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  };

  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Thống kê hiệu quả đầu tư
            </Typography.Title>
          </div>
        </div>
        <div className="d-flex justify-content-center w-100">
          <div
            className="w-100"
            style={{
              maxWidth: windowDimensions.height > 768 ? "74%" : "894px",
            }}
          >
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={windowDimensions.height > 768 ? 650 : 500}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Index;
