import { importMappingInstrument, importMappingTestCode, importFileSQL } from "helpers/app-backend/import_helpers";
import convertBlobToString from "utils/convertBlobToString";
import HandleReponse from "utils/handleReponse";

const importInstrument = async (siteCode, formData) => {
  const res = { error: null, errorData: null };

  try {
    await importMappingInstrument(siteCode, formData);
  } catch (error) {
    if (!error.response || !error.response.data) {
      return { error: "Đã có lỗi không xác định", errorData: null };
    }
    const errString = await convertBlobToString(error.response.data);

    try {
      const errJSON = JSON.parse(errString || "{}");
      res.error = errJSON.title || "Đã có lỗi xảy ra";
      res.errorData = errJSON.errorData;
    } catch {
      return { error: "Lỗi trả về không đúng định dạng JSON", errorData: null };
    }
  }
  return res;
};

const importMapTestCodeService = async (siteCode, formData) => {
  try {
    let res = await importMappingTestCode(siteCode, formData);
    return res;
  } catch (error) {
    let result = HandleReponse.catchJSONErrorReponse(error.response);
    return result;
  }
};

const importFileSQLService = async (formData) => {
  try {
    let res = await importFileSQL(formData);
    return HandleReponse.getListSuccess(res);
  } catch (error) {
    let result = HandleReponse.catchJSONErrorReponse(error.response);
    return result;
  }
};

export default {
  importInstrument, importMapTestCodeService, importFileSQLService
};
