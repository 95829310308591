import {area} from '../constants';
import Dijkstra from './Dijkstra';

const AreaManager = (function () {
    let instance;
    let iDijkstra = Dijkstra.getInstance();
  
    function init() {
      var areas = [];
      return {
        initAreas: function () {
          const temp = [];
          area.getList().forEach((el) => {
            temp.push({
              ...el,
              center: {
                x: Math.round((el.x + el.x + el.w)/ 2),
                y: Math.round((el.y + el.y + el.h)/ 2),
              }
            });
          });
          areas = [...temp];
          iDijkstra.init();
        },

        getAreas: function () {
          return areas;
        },

        getAreaById: function (id) {
          const idx = instance.getAreas().findIndex(area => area.id === id);
          if(idx > -1) {
            return instance.getAreas()[idx]
          }

          return null;
        }
      };
    }
  
    return {
      getInstance: function () {
        if (!instance)
          instance = {
            ...init(),
          };
        return instance;
      },
    };
  })();
  
  export default AreaManager;