import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "components/Common/InputText";
import { showToast } from "components/Common";
import InstrumentService from "services/Instrument";
import MasterInstrument from "services/MasterInstrument";
import Select from "react-select";
const Schema = Yup.object().shape({
  instrumentCode: Yup.string().required("Không được bỏ trống"),
});

const Index = ({
  isOpen,
  onClose,
  masterInstrumentType,
  setIsDone,
  data,
  isEdit,
}) => {
  const [loading, setIsLoading] = useState(false);
  const [selectInstrumentType, setSelectInstrumentType] = useState({});
  const formik = useFormik({
    initialValues: {
      instrumentCode: data?.instrumentCode || "",
      instrumentName: data?.instrumentName || "",
      instrumentType: data?.instrumentType || "",
      otherName: data?.otherName || "",
      producerCode: data?.producerCode || "",
      producerName: data?.producerName || "",
      fieldCode: data?.fieldCode || "",
      fieldName: data?.fieldName || "",
      vT3Code: data?.vT3Code || "",
      decription: data?.decription || "",
      erpCode: data?.erpCode || "",
    },
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      setIsLoading(true);
      if (isEdit) {
        updateMasterInstrument(values);
      } else {
        addNewInstrument(values);
      }
    },
  });

  const addNewInstrument = async params => {
    const res = await InstrumentService.addNewInstrument([params]);
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
    } else {
      showToast("Thêm thành công", 1);
      setIsDone(true);
      onClose();
    }
  };
  const updateMasterInstrument = async (_requestBody = {}) => {
    const res = await MasterInstrument.UpdateMasterInstrumentService(
      _requestBody
    );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
      setIsDone(true);
    } else {
      showToast("Cập nhật thành công", 1);
      setIsDone(true);
      onClose();
    }
  };
  useEffect(() => {
    if (isEdit) {
      let sel = masterInstrumentType.filter(
        x => x.value == data?.instrumentType
      );
      setSelectInstrumentType(sel);
    }
  }, []);
  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "950px", width: "100%" }}>
      <ModalHeader>Thêm mới Master Instrument</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6} lg={4}>
            <InputText
              label="Instrument Code"
              name="instrumentCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentCode}
              errors={formik.errors}
              disabled={loading || isEdit}
              isRequired
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Instrument Name"
              name="instrumentName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Other name"
              name="otherName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.otherName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Producer Code"
              name="producerCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.producerCode}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Producer Name"
              name="producerName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.producerName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Field Code"
              name="fieldCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.fieldCode}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Field Name"
              name="fieldName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.fieldName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="vT3Code"
              name="vT3Code"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.vT3Code}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Decription"
              name="decription"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.decription}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <Label className="mb-0 text-truncate" style={{ minWidth: "98px" }}>
              InstrumentType
            </Label>
            <div style={{ display: "flex" }}>
              <Select
                options={masterInstrumentType || []}
                placeholder=""
                value={selectInstrumentType}
                onChange={val => {
                  formik.setFieldValue("instrumentType", val.value);
                  setSelectInstrumentType(val);
                }}
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid  "rgb(206, 212, 218)"`,
                    maxWidth: "300px",
                    width: "300px",
                  }),
                }}
              />
              {selectInstrumentType && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("instrumentType", null);
                    setSelectInstrumentType({});
                  }}
                  className="clear-button"
                  color="link"
                >
                  <i className="mdi mdi-close"></i>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          color="primary"
          onClick={formik.handleSubmit}
        >
          Đồng ý
        </Button>{" "}
        <Button color="danger" onClick={onClose}>
          Đóng
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
