import Table , { getSeqByCellData }from "components/Common/Table";

const Index = ({ paging, setPaging, data, pageSize }) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "maSPChung",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        {
          Header: "Mã Sản Phẩm",
          accessor: "maSPChung",
          width: 150,
        },
        {
          Header: "Tên Sản Phẩm",
          accessor: "tenSPChung",
          width: 490,
        },
        {
          Header: "Mã Hãng",
          accessor: "maHang",
          width: 120,
        },
        {
          Header: "Tên Nhãn Hàng",
          accessor: "tenNH",
          width: 130,
        },
        {
          Header: "Mã Lĩnh Vực",
          accessor: "maLinhVuc",
          width: 100,
        },
        {
          Header: "Tên Lĩnh Vực",
          accessor: "tenLinhVuc",
          width: 200,
        },
        {
          Header: "NH_VT3",
          accessor: "nhvt3",
          width: 80,
        },
        {
          Header: "Ghi Chú",
          accessor: "ghiChu",
          width: 100,
        },
        {
          Header: "Trạng thái",
          accessor: "status",
          minWidth: 80,
        },
      ]}
      disablePagination={false}
      style={{height: "calc(100vh - 360px)"}}
    />
  );
};

export default Index;
