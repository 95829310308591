import { useEffect, useRef, useState } from "react";
import Filter from "./filter";
import { fetchTATInSystemApi } from "helpers/app-backend/autosystembackend_helpers";
import dayjs from "dayjs";
import { showToast } from "components/Common";
import AverageTime from "./AverageTime";
import FollowingDate from "./FollowingDate";
import FollowingTime from "./FollowingTime";
import {
  getAverageTimeData,
  getFollowingTimeData,
  getFollowingDateData,
} from "../AutoSystem/helper";
import { convertMinutesToHHMM, customColors } from "./helper";

const Index = ({ sites }) => {
  const [error, setError] = useState(null);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 5,
  });
  const [filter, setFilter] = useState({
    site: null,
    fromDate: dayjs(),
    toDate: dayjs(),
    type: "week",
  });
  const [avgTimeData, setAvgTimeData] = useState(null);
  const [avgFlTimeData, setAvgFlTimeData] = useState(null);
  const [avgFlDateData, setAvgFlDateData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [flDateMode, setFlDateMode] = useState("week");

  const getTAT = async params => {
    await GetLsStatisticSystemEvaluate(params);
  };

  const getTATByHour = async params => {
    await GetLsStatisticByHour(params);
  };

  const getTATByDate = async () => {
    await GetLsStatisticByDate();
  };

  const getTATByMonth = async params => {
    await GetLsStatisticByMonth(params);
  };

  const GetLsStatisticSystemEvaluate = async params => {
    let { chartData, cardData } = getAverageTimeData();
    try {
      const data = await fetchTATInSystemApi(params);
      setAvgTimeData(null);
      if (data) {
        //total
        let preAnalysisTotal = data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.preAnalysis,
          0
        );

        let preInAnalysisTotal = data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.inAnalysis,
          0
        );
        let afterPreAnalysisTotal = data.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.afterAnalysis,
          0
        );
        let total =
          preAnalysisTotal + preInAnalysisTotal + afterPreAnalysisTotal;
        //%
        let perPreAnalysis = Math.floor((preAnalysisTotal * 100) / total);
        let perInAnalysis = Math.floor((preInAnalysisTotal * 100) / total);
        let perAfterPreAnalysis = Math.floor(
          (afterPreAnalysisTotal * 100) / total
        );

        chartData.labels = [
          `Thời gian tiền phân tích (${perPreAnalysis}%)`,
          `Thời gian phân tích (${perInAnalysis}%)`,
          `Thời gian hậu phân tích (${perAfterPreAnalysis}%)`,
        ];
        chartData.datasets[0].data = [
          preAnalysisTotal,
          preInAnalysisTotal,
          afterPreAnalysisTotal,
        ];

        setAvgTimeData(chartData);
        let dataCard = {
          preAnalysis: preAnalysisTotal,
          inAnalysis: preInAnalysisTotal,
          afterAnalysis: afterPreAnalysisTotal,
          totalAnalysis: total,
        };
        cardData.forEach(item => {
          var key = item.id;
          var minutes = dataCard[key];
          item.value = convertMinutesToHHMM(minutes);
        });
        setCardData(cardData);
      } else {
        setAvgTimeData({
          labels: [],
          datasets: [],
        });
        setCardData(cardData);
      }
    } catch (err) {
      setAvgTimeData({
        labels: [],
        datasets: [],
      });
      setCardData(cardData);
    }
  };

  const GetLsStatisticByHour = async params => {
    const flTimeData = getFollowingTimeData();
    setAvgFlTimeData(null);
    try {
      const responseTotal = await fetchTATInSystemApi({
        ...params,
        type: "hour",
      });

      if (responseTotal) {
        let data = responseTotal
          .map(x => {
            return {
              ...x,
              label: Number(x.label),
            };
          })
          .sort((a, b) => a.label - b.label);

        flTimeData.categories = data.map(x => x.label);
        flTimeData.series[0].data = data.map(x => x.preAnalysis);
        flTimeData.series[1].data = data.map(x => x.inAnalysis);
        flTimeData.series[2].data = data.map(x => x.afterAnalysis);
        setAvgFlTimeData(flTimeData);
      } else {
        setAvgFlTimeData({
          categories: [],
          series: [],
        });
      }
    } catch (err) {
      setAvgFlTimeData({
        categories: [],
        series: [],
      });
    }
  };

  const GetLsStatisticByDate = async () => {
    let params = {
      fromDate: dayjs(filter.toDate).subtract(7, "day"),
      toDate: filter.toDate,
      siteCode: filter.site?.value,
      type: "week",
    };
    const flDateData = getFollowingDateData();
    setAvgFlDateData(null);

    try {
      const data = await fetchTATInSystemApi(params);

      if (data) {
        flDateData.categories = data.map(x =>
          dayjs(x.label).format("YYYY-MM-DD")
        );
        flDateData.series[0].data = data.map(x => x.preAnalysis);
        flDateData.series[1].data = data.map(x => x.inAnalysis);
        flDateData.series[2].data = data.map(x => x.afterAnalysis);
        setAvgFlDateData(flDateData);
      } else {
        setAvgFlDateData({
          categories: [],
          series: [],
        });
      }
    } catch (err) {
      setAvgFlDateData({
        categories: [],
        series: [],
      });
    }
  };

  const GetLsStatisticByMonth = async params => {
    const flDateData = getFollowingDateData();
    setAvgFlDateData(null);

    try {
      const responseTotal = await fetchTATInSystemApi(params);
      if (responseTotal) {
        let data = responseTotal;
        flDateData.categories = data.map(x => x.label);
        flDateData.series[0].data = data.map(x => x.preAnalysis);
        flDateData.series[1].data = data.map(x => x.inAnalysis);
        flDateData.series[2].data = data.map(x => x.afterAnalysis);
        setAvgFlDateData(flDateData);
      } else {
        setAvgFlDateData({
          categories: [],
          series: [],
        });
      }
    } catch (err) {
      setAvgFlDateData({
        categories: [],
        series: [],
      });
    }
  };

  const getData = async () => {
    let params = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      siteCode: filter.site?.value,
      type: "week",
    };
    setFlDateMode("week");
    setCardData([])
    setAvgTimeData(null);
    setAvgFlTimeData(null);
    setAvgFlDateData(null);
    setTimeout(() => {
      // Thời gian TAT trung bình trong lab
      getTAT(params);
      // Thời gian TAT trung bình trong lab
      getTATByDate();
      //  Thời gian TAT trung bình từng bước trong quy trình theo khung giờ
      getTATByHour(params);
      setIsLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (!sites || !sites.length) return;
    setFilter(prev => ({
      ...prev,
      site: sites[0],
    }));
    setError(null);
  }, [sites]);

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Filter
        setError={setError}
        setPaging={setPaging}
        error={error}
        sites={sites}
        xs={24}
        filter={filter}
        setFilter={setFilter}
        onSearch={getData}
        setIsLoading={setIsLoading}
        isLoading={!cardData && !avgTimeData && !avgFlDateData && !avgFlTimeData}
      />

      <AverageTime cardData={cardData} avgTimeData={avgTimeData} />

      <FollowingDate
        unit={"phút"}
        avgFlDateData={avgFlDateData}
        flDateMode={flDateMode}
        setFlDateMode={val => {
          setFlDateMode(val);
          setAvgFlDateData(null);
          if (val == "week") {
            setTimeout(async () => {
              await GetLsStatisticByDate();
            }, 1500);
          } else {
            let params = {
              fromDate: filter.fromDate,
              toDate: filter.toDate,
              siteCode: filter.site?.value,
              type: "month",
            };
            setTimeout(async () => {
              await GetLsStatisticByMonth(params);
            }, 1500);
          }
        }}
      />

      <FollowingTime avgFlTimeData={avgFlTimeData} unit={"phút"} />
    </div>
  );
};

export default Index;
