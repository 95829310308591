import React from "react";
import PropTypes from "prop-types";
import { Label, Modal, ModalBody, ModalHeader, Progress, Row } from "reactstrap";

const ProcessingModal = ({  
  size,
  isOpen,
  title,
  classBackground,
  onToggle,
  style,
}) => {
  return (
    <Modal scrollable={true} size={size} isOpen={isOpen} style={style}>
      <ModalHeader className={classBackground} toggle={onToggle} tag="h5">
        {title}
      </ModalHeader>
      <ModalBody>
          <div className="mt-1">
            <Progress color="primary" animated value="100" />
          </div>      
      </ModalBody>
    </Modal>
  );
};


export default ProcessingModal;
