import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import CustomTooltip from "./CustomTooltip";
import { renderToStaticMarkup } from "react-dom/server";
import { Spin, Typography } from "antd";

const ApexChart = ({ dataChart, formatCurrency, loading }) => {
  const thisYear = new Date().getFullYear();
  const previousYear = thisYear - 1;

  const categories = dataChart.map(item => item.name);

  const series = [
    {
      name: `Doanh số ${previousYear}`,
      type: "column",
      data: dataChart.map(item => item.prevYearValue),
    },
    {
      name: `Doanh số ${thisYear}`,
      type: "column",
      data: dataChart.map(item => item.value),
    },
    {
      name: "Forecast",
      type: "column",
      data: dataChart.map(item => item.forecast),
    },
    {
      name: "Target",
      type: "column",
      data: dataChart.map(item => item.target),
    },
    {
      name: "Growth",
      type: "line",
      data: dataChart.map(item =>
        item.value
          ? ((item.value / item.prevYearValue - 1) * 100).toFixed(2)
          : 0
      ),
    },
    {
      name: "%Sale/Target",
      type: "line",
      data: dataChart.map(item =>
        item.target ? ((item.value * 100) / item.target).toFixed(2) : 0
      ),
    },
  ];

  const findMinMaxValues = () => {
    // Extract and flatten the data for columns and lines
    const columnData = series
      .filter(item => item.type === "column")
      .map(item => item.data)
      .flat();
    const lineData = series
      .filter(item => item.type === "line")
      .map(item => item.data)
      .flat();

    // Find max and min values for column data
    const maxValueColumn = Math.max(...columnData);
    const minValueColumn = Math.min(...columnData);

    // Find max and min values for line data
    const maxValueLine = Math.max(...lineData);
    const minValueLine = Math.min(...lineData);

    return { maxValueColumn, minValueColumn, maxValueLine, minValueLine };
  };

  const { maxValueColumn, minValueColumn, maxValueLine, minValueLine } =
    findMinMaxValues();

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [4, 5],
      formatter: value => {
        return `${value}%`;
      },
    },
    stroke: {
      width: [1, 1, 4, 4, 4, 4],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: [
      {
        min: minValueColumn,
        max: maxValueColumn,
        seriesName: "previousYear",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return formatCurrency((value / 1e9).toFixed(0)) + " tỷ";
          },
          style: {
            colors: "#74788d",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        min: minValueColumn,
        max: maxValueColumn,
        show: false,
        seriesName: "thisYear",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return formatCurrency((value / 1e9).toFixed(0)) + " tỷ";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
      {
        min: minValueColumn,
        show: false,
        max: maxValueColumn,
        seriesName: "Target",
        opposite: true,

        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
      {
        show: false,
        min: minValueColumn,
        max: maxValueColumn,
        seriesName: "Forecast",
        opposite: true,

        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
      {
        min: minValueLine + -250,
        max: maxValueLine,
        show: false,
        seriesName: "Growth",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
      {
        min: minValueLine + -250,
        max: maxValueLine,
        seriesName: "%Sale/Target",
        opposite: true,

        labels: {
          show: false,
          formatter: function (value) {
            return value.toFixed(0) + "%";
          },
          style: {
            colors: "#74788d",
          },
        },
      },
    ],
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        const data = w.globals.initialSeries;
        const tooltipItems = data.map((el, idx) => ({
          id: idx,
          name: el.name,
          value: el.data[dataPointIndex],
          color: w.globals.colors[idx],
        }));
        const result = tooltipItems.map(el => {
          let formattedValue;

          // Định dạng giá trị dựa trên tên
          switch (el.name) {
            case `Doanh số ${previousYear}`:
              formattedValue = formatCurrency(el.value) + " VNĐ";
              break;
            case `Doanh số ${thisYear}`:
              formattedValue = formatCurrency(el.value) + " VNĐ";
              break;
            case "Forecast":
              formattedValue = el.value + " VNĐ";
              break;
            case "Target":
              formattedValue = formatCurrency(el.value) + " VNĐ";
              break;
            case "Growth":
              formattedValue = formatCurrency(el.value) + "%";
              break;
            case "%Sale/Target":
              formattedValue = el.value + "%";
              break;
            default:
              formattedValue = el.value; // Không thêm đơn vị cho các trường hợp khác
          }

          return {
            ...el,
            value: formattedValue,
          };
        });
        return renderToStaticMarkup(
          <CustomTooltip
            title={`${categories[dataPointIndex]}`}
            data={result}
          />
        );
      },
    },
    legend: {
      show: true,
    },
    colors: ["#008FFB", "#00E396", "#FF4560", "#FEB019", "#FF66C3", "#775DD0"],

    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: "#FF4560",
          borderWidth: 1,
          label: {
            borderColor: "#FF4560",
            style: {
              color: "#fff",
              background: "#FF4560",
            },
            text: "0%",
          },
          yAxisIndex: 4,
        },
        {
          y: 100,
          borderColor: "#FF4560",
          borderWidth: 1,
          label: {
            borderColor: "#FF4560",
            style: {
              color: "#fff",
              background: "#FF4560",
            },
            text: "100%",
          },
          yAxisIndex: 5,
        },
      ],
    },
  };

  return (
    <div>
      {loading ? (
        <Spin tip="Loading...">
          <div style={{ height: 739 }} />
        </Spin>
      ) : (
        <>
          <Typography.Title
            className="text-center"
            style={{ fontWeight: "bold", marginBottom: 0, fontSize: "20px" }}
          >
            {`DS ${previousYear}, DS ${thisYear} and Growth%`}
          </Typography.Title>
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={700}
            />
          </div>
        </>
      )}
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
