import Select from "react-select";
import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import { DISPLAY_MONTH_YEAR_FORMAT } from "constant";
import DatePicker, { registerLocale } from "react-datepicker";
import { InputAutoFormat, CustomSelect } from "components/Common";
import vi from "date-fns/locale/vi";
import "react-datepicker/dist/react-datepicker.css";
import "./../../../../assets/scss/custom/components/_select.scss";
import { useEffect, useState } from "react";
import StatisticService from "services/Statistic";
import { DELAY_FETCH } from "constant";

const Index = ({
  sites = [],
  filter,
  setFilter,
  error,
  onSearch,
  setError,
  isLoading,
  setPaging,
  selectValue,
  setSelectValue,
  ref,
}) => {
  const [data, setData] = useState([]);
  const [dataHang, setDataHang] = useState([]);
  const [dataMasterCategoryCode, setDataMasterCategoryCode] = useState([]);
  const [selectMaHang, setSelectMaHang] = useState([]);
  const [selectMasterCategoryCode, setSelectMasterCategoryCode] = useState([]);

  useEffect(() => {
    getDataHang();
    getDataMasterCategoryCode();
  }, []);

  useEffect(() => {
    if (!selectValue || selectValue.length < 0) return;
    setData(selectValue.filter(x => x.value == "all"));
  }, [selectValue || sites]);

  const getDataHang = async () => {
    const res = await StatisticService.GetLsLabelValueMatHangService({
      search: "",
    });
    res.unshift({ value: "all", label: "Chọn tất cả" });
    setDataHang(res);
  };

  const getDataMasterCategoryCode = async () => {
    const res = await StatisticService.GetLsMasterCategoryCodeService({
      search: "",
    });
    res.unshift({ value: "all", label: "Chọn tất cả" });
    setDataMasterCategoryCode(res);
  };

  const HandleSubmitSite = val => {
    //Chọn site
    let filterAll = val.filter(x => x.value == "all");
    if (filterAll.length > 0) {
      setData(filterAll);
      setFilter(prev => ({
        ...prev,
        siteCode: sites.map(item => item.value),
      }));
    } else {
      setData(val);
      setFilter(prev => ({
        ...prev,
        siteCode: val.map(item => item.value),
      }));
    }
    setPaging(prev => ({ ...prev, currentPage: 1 }));
  };

  const findDuplicate = arr => {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) !== index || self.lastIndexOf(value) !== index;
    });
  };
  
  const HandleSubmitMaHang = val => {
    let filterAll = val.filter(x => x.value == "all");
    if (filterAll.length > 0) {
      let resfilter = dataHang.filter(item => {
        if (item.value != "all") return item.value;
      });     
      setSelectMaHang(filterAll);
      setFilter(prev => ({
        ...prev,
        maHang: resfilter.map(x=> x.value),
      }));
    } else {
      setSelectMaHang(val);
      setFilter(prev => ({
        ...prev,
        maHang: val.map(item => item.value),
      }));
    }
    setPaging(prev => ({ ...prev, currentPage: 1 }));
  };

  const HandleSubmitMasterCategoryCode = val => {
    let filterAll = val.filter(x => x.value == "all");
    if (filterAll.length > 0) {
      setSelectMasterCategoryCode(filterAll);
      setFilter(prev => ({
        ...prev,
        masterCategoryCode: dataMasterCategoryCode.map(item => item.value),
      }));
    } else {
      setSelectMasterCategoryCode(val);
      setFilter(prev => ({
        ...prev,
        masterCategoryCode: val.map(item => item.value),
      }));
    }
    setPaging(prev => ({ ...prev, currentPage: 1 }));
  };
  return (
    <Card className="p-3 mb-2">    
      <Row>
        <Col>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">Mã hãng</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 2 }}>
              <Select
                className="custom-select"
                isMulti
                options={dataHang}
                placeholder="Chọn hãng"
                value={selectMaHang}
                onChange={val => {
                  HandleSubmitMaHang(val);
                }}
                isDisabled={isLoading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">MasterCategoryCode</Label>
            <div style={{ flex: 1, marginLeft: "6px" }}>
              <Select
                className="custom-select"
                isMulti
                options={dataMasterCategoryCode}
                placeholder="Chọn xét nghiệm"
                value={selectMasterCategoryCode}
                onChange={val => {
                  HandleSubmitMasterCategoryCode(val);
                }}
                isDisabled={isLoading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
