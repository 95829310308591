import React, { useRef, useState, useEffect } from "react";
import StatisticService from "services/Statistic";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalDetail from "./Detail/ModalDetail";
import { startOfMonth, format } from "date-fns";
import PieChart from "components/Dashboards/PieChart";
const Index = ({ sites, filterAll }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [optionChart, setOptionChart] = useState({});
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);
  const [filter, setFilter] = useState({
    siteCode: filterAll.siteCode || [],
    dateFrom: new Date("0001-01-01"),
    dateTo: new Date("0001-01-01"),
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        siteCode: filterAll.siteCode || [],
        dateFrom: format(startOfMonth(filterAll.dateFrom), "yyyy-MM-dd"),
        dateTo: format(startOfMonth(filterAll.dateTo), "yyyy-MM-dd"),
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await StatisticService.GetListStatisticStatMonthResultSaleByCategoryService(
          params,
          requestBody
        );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      let seriesChart = res.data?.map(x => x.doanhSo);
      let optionChart = {
        chart: {
          width: 500,
          type: "pie",
        },
        labels: res.data?.map(x => {
          if (x.masterCategoryName) {
            return x.masterCategoryName;
          }
          return "(Trống)";
        }),
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        fill: {
          type: "gradient",
        },
        title: {
          text: "Dashboard doanh số - nhóm XN",
          style: {
            fontFamily: "Roboto",
          },
        },
      };
      setDataChart(seriesChart);
      setOptionChart(optionChart);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  // useEffect(() => {
  //   getTableData();
  // }, [paging]);

  useEffect(() => {
    setPaging(prev => ({ ...prev, currentPage: 1 }));
    getTableData();
  }, [filterAll]);

  return (
    <>
      {loading == false && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PieChart options={optionChart} chartSeries={dataChart} />
        </div>
      )}

      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setSiteCodeDetail={setSiteCodeDetail}
        setIsOpenModalDetail={setIsOpenModalDetail}
      />
      {isOpenModalDetail && (
        <ModalDetail
          sites={sites}
          setSiteCodeDetail={setSiteCodeDetail}
          data={siteCodeDetail}
          isOpen={isOpenModalDetail}
          onClose={() => setIsOpenModalDetail(false)}
        />
      )}
    </>
  );
};

export default Index;
