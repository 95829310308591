import React from "react";
import Table, { getSeqByCellData } from "components/Common/Table";
import { reportType } from "./constants";
import moment from "moment";
import { formatNumber } from "utils/formatNumber";
import { DISPLAY_DATE_TIME_FORMAT, DISPLAY_YEAR_MONTH_FORMAT } from "constant";
import { Button } from "reactstrap";

const Index = ({ paging, setPaging, data, tableType, pageSize, setIsModalConfirmDownload, setDetailData, setIsExportFile  }) => {
  const getColumns = () => {
    return [
      {
        Header: "STT",
        accessor: "siteCode",
        width: 50,
        Cell: data => (
          <div className="d-flex w-100 justify-content-center">
            {getSeqByCellData(data)}
          </div>
        ),
      },
      {
        Header: "SiteCode",
        accessor: "siteCode",
        width: 150,
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        width: 200,
      },
      {
        Header: "Year",
        accessor: "year",
        width: 120,
      },
      {
        Header: "Month",
        accessor: "month",
        width: 100,
      },
      {
        Header: "FileName",
        accessor: "fileName",
        width: 400,
      },
      {
        Header: "FileId",
        accessor: "fileId",
        width: 400,
      },
      {
        Header: "CreateAt",
        accessor: "createAt",
        width: 120,
        Cell: ({ value }) => (
          <div className="d-flex w-100 justify-content-center">
            {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
          </div>
        ),
      },
      {
        Header: "",
        width: 40,
        fixed: "left",
        headerClassName: "sticky-header",
        Cell: data => (
          <div className="d-flex">
            <Button
              size="sm"
              className="d-flex w-100 justify-content-center me-1"
              style={{ backgroundColor: "#559d52", borderWidth: 0 }}
              onClick={e => {
                setIsExportFile(true);
                setDetailData(data.original);
              }}
            >
              <i className="mdi mdi-download-multiple"></i>
            </Button>            
          </div>
        ),
      },
    ];
  };

  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      columns={getColumns()}
      pageSize={pageSize}
    />
  );
};

export default Index;
