import Select from "react-select";
import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const Index = ({
  dataFilter,
  setDataFilter,
  customerOptions,
  employeesOptions,
  regionsOptions,
}) => {
  const [filteredCustomerOptions, setFilteredCustomerOptions] = useState([]);

  useEffect(() => {
    const filterCustomerOptionsByRegion = () => {
      if (dataFilter.regionSelect && dataFilter.regionSelect.value) {
        const filteredOptions = customerOptions.filter(customer => {
          return customer.key === dataFilter.regionSelect.value;
        });
        setFilteredCustomerOptions(filteredOptions);
      } else {
        setFilteredCustomerOptions(customerOptions);
      }
    };
    
    filterCustomerOptionsByRegion();
  }, [dataFilter.regionSelect, customerOptions]);

  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={4} xl={4}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">Miền</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 4 }}>
              <Select
                className="custom-select"
                options={regionsOptions}
                placeholder="Chọn miền"
                value={regionsOptions
                  .filter(option =>
                    dataFilter.regionSelect.includes(option.value)
                  )
                  .map(option => ({
                    value: option.value,
                    label: option.label,
                  }))}
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value)
                    : [];
                  setDataFilter(prev => ({
                    ...prev,
                    regionSelect: selectedValues,
                    maKHSelect: [],
                    tenKHSelect: [],
                    employeeSelect: [],
                  }));
                }}
                isDisabled={false}
                isClearable
                isMulti
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={4} xl={4}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">Khách hàng</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 4 }}>
              <Select
                className="custom-select"
                options={filteredCustomerOptions}
                placeholder="Chọn khách hàng"
                value={filteredCustomerOptions
                  .filter(option =>
                    dataFilter.maKHSelect.includes(option.value)
                  )
                  .map(option => ({
                    value: option.value,
                    label: option.label,
                  }))}
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value)
                    : [];
                  const selectedLabels = selectedOptions
                    ? selectedOptions.map(option => option.label)
                    : [];
                  setDataFilter(prev => ({
                    ...prev,
                    maKHSelect: selectedValues,
                    tenKHSelect: selectedLabels,
                  }));
                }}
                isDisabled={false}
                isClearable
                isMulti
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={4} xl={4}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">KAM</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 4 }}>
              <Select
                className="custom-select"
                options={employeesOptions}
                placeholder="Chọn KAM"
                value={employeesOptions
                  .filter(option =>
                    dataFilter.employeeSelect.includes(option.value)
                  )
                  .map(option => ({
                    value: option.value,
                    label: option.label,
                  }))}
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value)
                    : [];
                  setDataFilter(prev => ({
                    ...prev,
                    employeeSelect: selectedValues,
                  }));
                }}
                isDisabled={false}
                isClearable
                isMulti
              />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
