import React, { useEffect, useState } from "react";
import { PageContent, CustomBreadcrumb } from "components/Common";

import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MasterGroupTest from "./MasterGroupTest";
import MasterCategoryService from "services/MasterCategory";
import MasterTestCodeService from "services/MasterTestCode";
const RESOURCE = ModuleIds.MasterGroupTest;

const MasterGroupTestContainer = ({ t }) => {
  const titleTag = t("Danh sách Master GroupTest");
  const [masterCategory, setMasterCatgory] = useState([]);
  const [masterTestCode, setMasterTestCode] = useState([]);
  const getData = async () => {
    const res = await MasterCategoryService.GetLsMasterCategoryService();    
    setMasterCatgory(res);
  };
  const getDataTestCode = async () => {
    const res = await MasterTestCodeService.GetLisLabelValueMasterTestCodeService();    
    setMasterTestCode(res);
  };
  useEffect(() => {
    if (masterCategory.length > 0) return;
    getData();
    getDataTestCode();
  }, []);
  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb
        breadcrumbItem={titleTag}
        resource={RESOURCE}
        icon={<i className="bx bx-file" />}
      />
      {/* Page Body */}
      <MasterGroupTest masterCategory={masterCategory} masterTestCode={masterTestCode} />
    </PageContent>
  );
};

MasterGroupTestContainer.propTypes = {
  t: PropTypes.any,
};

export default withTranslation(["masterGroupTestPage"])(
  MasterGroupTestContainer
);
