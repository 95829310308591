import React from "react";
import { useTranslation } from "react-i18next";
import Table from "components/Common/Table";
import { Card, Row, Col } from "reactstrap";
const Index = ({ dataTable, paging, setPaging, totalRows ,totalAllPages}) => {
  const { t } = useTranslation(["Statistics"]);
  const formatCurrency = value => {
    const formattedValue = new Intl.NumberFormat("vi-VN").format(value);
    return formattedValue.replace(/\./g, ",");
  };
  const columns = [
    {
      Header: t("STT"),
      filterable: false,
      resizable: false,
      sortable: false,
      fixed: "left",
      width: 50,
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      Header: t("Mã khách hàng"),
      accessor: "siteCode",
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Tên Khách Hàng"),
      accessor: "siteName",
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Mã SP"),
      accessor: "masterCategoryCode",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Nhóm SP"),
      accessor: "masterCategoryName",
      minWidth: 250,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Hãng"),
      accessor: "masterProducerCode",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Mã Công Ty"),
      accessor: "companyCode",
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Tên Công Ty"),
      accessor: "companyName",
      minWidth: 250,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Mã máy"),
      accessor: "masterInstrumentCode",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Tên máy"),
      accessor: "masterInstrumentName",
      minWidth: 250,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Số lượng máy"),
      accessor: "countInstrument",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t(" SL test hoặc mẫu/ năm "),
      accessor: "countTest",
      minWidth: 200,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("DS/năm (tỷ)"),
      accessor: "revenue",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrency(value)} Tỷ
        </div>
      ),
    },
    {
      Header: t("Thị Phần"),
      accessor: "marketShare",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value} %
        </div>
      ),
    },
    {
      Header: t("Thời gian"),
      accessor: "statYear",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: t("Loại Dữ Liệu"),
      accessor: "dataType",
      minWidth: 150,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
  ];

  return (
    <Card style={{ paddingBottom: 0 }}>
      <Row>
        <Col md={12}>
          <Table
            paging={paging}
            setPaging={setPaging}
            data={{ data: dataTable }}
            pageSize={paging.pageSize}
            style={{ height: "calc(100vh - 415px)" }}
            columns={columns}
            totalAllPages={totalAllPages}
            totalRows={totalRows}
          />
        </Col>
      </Row>
    </Card>
  );
};
export default Index;
