import React, { useEffect, useRef, useState, useMemo } from "react";
//i18n

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Filter from "./Filter";
import StatisticsCustomer from "./Components/StatisticsCustomer";
import StatisticsDistribution from "./Components/StatisticsDistribution";
import {
  getEmployees,
  getDataRevenueByCustomer,
  getDataRevenueByGroupProduct,
} from "../../../helpers/app-backend/effectiverealsalebackend_helpers";

const StatisticsRealSale = ({ t }) => {
  const userName = localStorage.getItem("userName");
  const [dataFilter, setDataFilter] = useState({
    employeeSelect: [],
    maKHSelect: [],
    tenKHSelect: [],
    regionSelect: [],
    quaters: [],
  });
  const [showStatistics, setShowStatistics] = useState("StatisticsCustomer");
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onGetData = async () => {
    const listEmp = await getEmployees();
    setDataEmployees(listEmp.result.data);
    const optionsEmp = listEmp.result.data.map(item => ({
      value: item.maNV,
      label: item.tenNV,
    }));
    setEmployeesOptions(optionsEmp);
  };

  useEffect(() => {
    const getDataChart = async () => {
      const year = new Date().getFullYear();
      const commonParams = {
        year,
        maNVBH: dataFilter.employeeSelect,
        maMien: dataFilter.regionSelect,
        maKH: dataFilter.maKHSelect,
        quaters: dataFilter.quaters,
        username: localStorage ? localStorage.getItem("userName") : "",
      };
      if (showStatistics === "StatisticsCustomer") {
        try {
          const response = await getDataRevenueByCustomer(commonParams);
          if (response.result.isSuccess) {
            setDataChart(response.result.data);
            return;
          }
          toastr.error("Đã có lỗi xảy ra");
          setDataChart([]);
        } catch (err) {
          toastr.error("Đã có lỗi xảy ra");
          setDataChart([]);
        }
      } else {
        try {
          const response = await getDataRevenueByGroupProduct(commonParams);
          if (response.result.isSuccess) {
            setDataChart(response.result.data);
            return;
          }
          toastr.error("Đã có lỗi xảy ra");
          setDataChart([]);
        } catch (err) {
          toastr.error("Đã có lỗi xảy ra");
          setDataChart([]);
        }
      }
    };

    getDataChart();
  }, [dataFilter, showStatistics]);

  const formatCurrencyVND = value => {
    const formattedValue = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    }).format(value);
    return formattedValue.replace(/\./g, ",").replace("₫", "VND");
  };
  useMemo(() => {
    onGetData();
  }, []);
  return (
    <React.Fragment>
      <Filter
        employeesOptions={employeesOptions}
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        showStatistics={showStatistics}
        setShowStatistics={setShowStatistics}
      />
      {showStatistics === "StatisticsCustomer" ? (
        <StatisticsCustomer
          windowDimensions={windowDimensions}
          dataChart={dataChart}
          formatCurrencyVND={formatCurrencyVND}
        />
      ) : (
        <StatisticsDistribution
          windowDimensions={windowDimensions}
          dataChart={dataChart}
          formatCurrencyVND={formatCurrencyVND}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(
  withTranslation(["UserPage", "message"])(StatisticsRealSale)
);
