import Table from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setIsShowModal,
  setIsEdit,
  setDataDetailTestCode,
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "TestCodeLis",
          accessor: "testCodeLis",
          width: 170,
        },
        {
          Header: "TestNameLis",
          accessor: "testNameLis",
          width: 900,
        },
        {
          Header: " MapMasterTestcode",
          accessor: "mapMasterTestcode",
          width: 180,
        },
        {
          Header: " SampleType",
          accessor: "sampleType",
          width: 180,
        },
        {
          Header: " SampleTypeName",
          accessor: "sampleTypeName",
          width: 180,
        },
        {
          Header: " Category",
          accessor: "category",
          width: 180,
        },
        {
          Header: "CategoryName",
          accessor: "categoryName",
          width: 180,
        },
        {
          Header: "Action",
          width: 50,
          Cell: data => (
            <Button
              className="d-flex w-100 justify-content-center"
              color="link"
              onClick={e => {
                setIsEdit(true);
                setIsShowModal(true);
                setDataDetailTestCode(data.original);                
              }}
            >
              Edit
            </Button>
          ),
        },
      ]}
      disablePagination={false}
      style={{ height: "calc(100vh - 360px)" }}
    />
  );
};

export default Index;
