import {
  Card,
  Button,
  Row,
  Col,
  Label,
  Input,
  UncontrolledCollapse,
} from "reactstrap";
import Select from "react-select";
import { CustomButton, InputAutoFormat } from "components/Common";
import { useState } from "react";

const Index = ({
  filter,
  setFilter,
  isLoading,
  onSearch,
  setIsModalInsertUpdate,
  setIsEdit,
  masterGroupTest,
  masterCategory,
  masterParentTest,
}) => {
  const [selectGroupTest, setSelectGroupTest] = useState({});
  const [selectCategory, setSelectCategory] = useState({});
  const [selectParentTest, setSelectParentTest] = useState({});
  return (
    <>
      <Card className="p-3 my-2">
        <div className="w-100 d-flex">
          <div
            style={{ maxWidth: "600px", minWidth: "300px", marginRight: "8px" }}
          >
            <Input
              value={filter.instrumentCode}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  onSearch();
                }
              }}
              placeholder="Tìm kiếm theo testcode..."
              onChange={e =>
                setFilter(prev => ({ ...prev, testCodeName: e.target.value }))
              }
            />
          </div>
          <CustomButton
            id="filterPlus"
            color="warning"
            className="btn-hover-shine"
          >
            <div className="d-flex align-items-center ">
              <i className="mdi mdi-filter-plus"></i>
              <p className="mb-0 mx-1">Lọc +</p>
            </div>
          </CustomButton>
          <Button
            color="success"
            className="btn-hover-shine mx-2"
            onClick={() => {
              setIsEdit(false);
              setIsModalInsertUpdate(true);
            }}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle" style={{ fontSize: "1rem" }}></i>
              <p className="mx-1 mb-0">Thêm mới</p>
            </div>
          </Button>
        </div>
      </Card>
      <UncontrolledCollapse toggler="#filterPlus">
        <Card className="p-3 my-2">
          <Row>
            {/* <Col md={6} lg={4}>
              <div className="d-flex align-items-center">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "98px" }}
                >
                  GroupTest
                </Label>
                <div style={{ marginLeft: "6px", display: "flex", zIndex: 6 }}>
                  <Select
                    options={masterGroupTest || []}
                    placeholder=""
                    value={selectGroupTest}
                    onChange={val => {
                      setFilter(prev => ({
                        ...prev,
                        groupTest: val.value,
                      }));
                      // onSearch();
                      setSelectGroupTest(val);
                    }}
                    isDisabled={isLoading}
                    styles={{
                      control: base => ({
                        ...base,
                        border: `1px solid  "rgb(206, 212, 218)"`,
                        maxWidth: "300px",
                        width: "300px",
                      }),
                    }}
                  />
                  {selectGroupTest && (
                    <Button
                      onClick={() => {
                        setFilter(prev => ({
                          ...prev,
                          groupTest: "",
                        }));
                        // onSearch();
                        setSelectGroupTest({});
                      }}
                      className="clear-button"
                      color="link"
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  )}
                </div>
              </div>
            </Col> */}
            <Col md={6} lg={4}>
              <div className="d-flex flex-column align-items-start">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "98px" }}
                >
                  Category
                </Label>
                <div style={{ display: "flex", zIndex: 6 }}>
                  <Select
                    options={masterCategory || []}
                    placeholder="Tìm kiếm theo Category"
                    value={selectCategory}
                    onChange={(val) => {
                      setFilter((prev) => ({
                        ...prev,
                        category: val.value,
                      }));
                      // onSearch();
                      setSelectCategory(val);
                    }}
                    isDisabled={isLoading}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid  "rgb(206, 212, 218)"`,
                        maxWidth: "300px",
                        width: "300px",
                      }),
                    }}
                  />
                  {selectCategory && (
                    <Button
                      onClick={() => {
                        setFilter((prev) => ({
                          ...prev,
                          category: "",
                        }));
                        // onSearch();
                        setSelectCategory({});
                      }}
                      className="clear-button"
                      color="link"
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="d-flex flex-column align-items-start">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "98px" }}
                >
                  Xét nghiệm thương mại
                </Label>
                <div style={{ display: "flex", zIndex: 6 }}>
                  <Select
                    options={masterParentTest || []}
                    placeholder=""
                    value={selectParentTest}
                    onChange={val => {
                      setFilter(prev => ({
                        ...prev,
                        parentTest: val.value,
                      }));
                      // onSearch();
                      setSelectParentTest(val);
                    }}
                    isDisabled={isLoading}
                    styles={{
                      control: base => ({
                        ...base,
                        border: `1px solid  "rgb(206, 212, 218)"`,
                        maxWidth: "300px",
                        width: "300px",
                      }),
                    }}
                  />
                  {selectParentTest && (
                    <Button
                      onClick={() => {
                        setFilter(prev => ({
                          ...prev,
                          parentTest: "",
                        }));
                        // onSearch();
                        setSelectParentTest({});
                      }}
                      className="clear-button"
                      color="link"
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <Button
                color="primary"
                className="btn-hover-shine mt-3"
                onClick={onSearch}
                disabled={isLoading}
              >
                <div className="d-flex align-items-center ">
                  <i
                    className="bx bx-search mr-1"
                    style={{ fontSize: "1rem" }}
                  ></i>
                  <p className="mx-1 mb-0">
                    {isLoading ? "Đang tìm.." : "Tìm kiếm"}
                  </p>
                </div>
              </Button>
            </Col>
          </Row>
          <Row style={{marginTop: "10px"}}>
            <Col md={6} lg={4}>
              <div className="d-flex flex-column align-items-start">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "100px" }}
                >
                  CommonName
                </Label>
                <div style={{ width: "calc(100% - 145px)" }}>
                  <Input
                    value={filter.commonName}
                    disabled={isLoading}
                    onChange={e => {
                      setFilter(prev => ({
                        ...prev,
                        commonName: e.target.value,
                      }));
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        onSearch();
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="d-flex flex-column align-items-start">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "100px" }}
                >
                  PricePerTest
                </Label>
                <div className="d-flex flex-row align-items-center">
                  <div >
                    <InputAutoFormat
                      defaultValue={
                        filter.pricePerTestFrom ? filter.pricePerTestFrom : ""
                      }
                      style={{
                        width: "110px",
                        borderColor: "#ced4da",
                      }}
                      disabled={isLoading}
                      maxLength={10}
                      onValueChange={val => {
                        setFilter(prev => ({
                          ...prev,
                          pricePerTestFrom:
                            val || val === "0" ? parseInt(val) : undefined,
                        }));
                        if (error) {
                          setError(null);
                        }
                      }}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          onSearch();
                        }
                      }}
                    />
                  </div>
                  <i className="bx bxs-right-arrow" style={{margin: "0px 10px"}}></i>
                  <div>
                    <InputAutoFormat
                      defaultValue={
                        filter.pricePerTestTo ? filter.pricePerTestTo : ""
                      }
                      style={{
                        width: "110px",
                        borderColor: "#ced4da",
                      }}
                      disabled={isLoading}
                      maxLength={10}
                      onValueChange={val => {
                        setFilter(prev => ({
                          ...prev,
                          pricePerTestTo:
                            val || val === "0" ? parseInt(val) : undefined,
                        }));
                        if (error) {
                          setError(null);
                        }
                      }}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          onSearch();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
            
          </Row>
          {/* <Row className="mt-3"> */}
          {/* <Col md={6} lg={6} xl={5} className="mb-2">
              <div className="d-flex align-items-center">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "100px" }}
                >
                  Category
                </Label>
                <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
                  <Input
                    value={filter.category}
                    disabled={isLoading}
                    onChange={e => {
                      setFilter(prev => ({
                        ...prev,
                        category: e.target.value,
                      }));
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        onSearch();
                      }
                    }}
                  />
                </div>
              </div>
            </Col> */}

          {/* </Row> */}
        </Card>
      </UncontrolledCollapse>
    </>
  );
};

export default Index;
