import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/master-parent-test`;

const GetListMasterParentTestsApi = payload => {
  let q = spreadSearchQuery(payload);
  return get(`${BASE_API_URL}/ls-master-parent-test?${q}`);
};

const InsertMasterParentTestApi = reqBody => {
  return post(`${BASE_API_URL}/`, reqBody);
};

const UpdateMasterParentTestApi = reqBody => {
  return put(`${BASE_API_URL}/`, reqBody);
};

const DeleteMasterParentTestApi = reqBody => {
  return post(`${BASE_API_URL}/delete`, reqBody);
};

const GetLisLabelValueMasterParentTestApi = () => {
  return get(`${BASE_API_URL}/ls-label-value`);
};

export {
  GetListMasterParentTestsApi,
  InsertMasterParentTestApi,
  UpdateMasterParentTestApi,
  DeleteMasterParentTestApi,
  GetLisLabelValueMasterParentTestApi
};
