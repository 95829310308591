import { useEffect, useState } from "react"
import { Label } from "reactstrap"
import PropTypes from "prop-types"
import { textFieldTypes, permissionType } from "constant/utility"
import { withRouter } from "react-router-dom"
import { Check } from "."
import { isEmptyArray, isEmptyValues } from "helpers/utilities"
import React from "react"
const TextField = ({
  label,
  textField,
  value,
  type,
  checked,
  history,
  isEdit,
  children,
  resource,
}) => {

  const [tagsArray, setTagsArray] = useState(null)

  useEffect(() => {
    if (type == textFieldTypes.TAGS && value) {
      let arr = value?.split(",");
      setTagsArray(arr)
    }

  }, [value])

  return (
    <div className="floating-form-group">
      <Label className="floating-label">{label}</Label>
      {type === textFieldTypes.TEXT && (
        <>
          <div className="floating-text-field">
            {children}
            {textField}
            {((children?.length >= 0 && isEmptyArray(children)) ||
              isEmptyValues(children)) &&
              isEmptyValues(
                typeof textField === "string" ? textField?.trim() : textField
              ) && <>&nbsp;</>}
          </div>
        </>
      )}
      {type === textFieldTypes.TAGS && (
        <>
          <div className="floating-text-field">
            {
              tagsArray?.slice().map(item =>
                <label key={item}
                  style={{
                    color: 'hsl(0,0%,20%)',
                    backgroundColor: '#e6e6e6',
                    padding: '1px 5px',
                    marginRight: '5px',
                    borderRadius: '5px'
                  }}>
                  {item}
                </label>
              )
            }
            {((children?.length >= 0 && isEmptyArray(children)) ||
              isEmptyValues(children)) &&
              isEmptyValues(
                typeof textField === "string" ? textField?.trim() : textField
              ) && <>&nbsp;</>}
          </div>
        </>
      )
      }
      {
        type === textFieldTypes.CHECKBOX && (
          <div className="form-check mb-0">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={checked}
              disabled
            />
            <label className="form-check-label" htmlFor="defaultCheck1"></label>
          </div>
        )
      }
      <Check resource={resource} permission={permissionType.U}>
        {isEdit && (
          <i className="mdi mdi-pen" onClick={() => history.push("edit")}></i>
        )}
      </Check>
    </div >
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  textField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  type: PropTypes.string, // constants/utility.textFieldTypes
  checked: PropTypes.bool,
  isEdit: PropTypes.bool,
  resource: PropTypes.string,
}

TextField.defaultProps = {
  label: "label",
  type: textFieldTypes.TEXT,
  isEdit: true,
}

export default withRouter(TextField)
