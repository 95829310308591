import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import CustomTooltip from "./CustomTooltip";
import { renderToStaticMarkup } from "react-dom/server";
import { Spin, Typography } from "antd";
const PieLegendSharp = ({ color = "#FFF", label = "", style = {} }) => (
  <div className="d-flex align-items-center" style={style}>
    <div
      style={{
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: color,
        marginRight: "8px",
      }}
    />
    <Typography style={{ fontSize: ".9rem" }}>{label}</Typography>
  </div>
);
const ApexChart = ({ dataRevenueByCustomer, formatCurrency, loading }) => {
  const categories = dataRevenueByCustomer.map(item => item.tenKH);

  const series = [
    {
      name: "Doanh số",
      type: "column",
      data: dataRevenueByCustomer.map(item => item.doanhSo),
    },
    {
      name: "Target",
      type: "column",
      data: dataRevenueByCustomer.map(item => item.target),
    },
    {
      name: "%DS/TG",
      type: "line",
      data: dataRevenueByCustomer.map(item =>
        item.target ? ((item.doanhSo * 100) / item.target).toFixed(2) : 0
      ),
    },
  ];
  const findMaxValue = () => {
    const columnSeries = series.filter(item => item.type === "column");
    const tatDataArray = columnSeries.map(item => item.data).flat();
    const maxValue = Math.max(...tatDataArray);
    return maxValue;
  };

  const maxValue = findMaxValue();

  const getChartWidth = () => {
    const length = dataRevenueByCustomer.length;
    if (length < 20) {
      return "100%"; // Không truyền chiều rộng nếu dữ liệu ít
    } else if (length <= 40) {
      return 2500;
    } else if (length <= 60) {
      return 5000;
    } else {
      // Tăng dần chiều rộng nếu dữ liệu nhiều hơn 60
      return Math.min(5000 + (length - 60) * 50, 10000); // Ví dụ giới hạn chiều rộng tối đa là 10000
    }
  };

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2],
      formatter: value => {
        return `${value}%`;
      },
    },
    stroke: {
      width: [1, 1, 4],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: [
      {
        seriesName: "Doanh số",
        min: 0,
        max: maxValue,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
          show: true,
          formatter: function (value) {
            const valueInBillions = value / 1e9;
            return formatCurrency(valueInBillions) + " Tỷ";
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        min: 0,
        max: maxValue,
        show: false,
        seriesName: "Target",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
          show: true,
          formatter: function (value) {
            return formatCurrency(value);
          },
        },
      },
      {
        seriesName: "%DS/TG",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FEB019",
        },
        labels: {
          style: {
            colors: "#FEB019",
          },
          show: true,
          formatter: function (value) {
            return value + "%";
          },
        },
      },
    ],
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        const data = w.globals.initialSeries;
        const tooltipItems = data.map((el, idx) => ({
          id: idx,
          name: el.name,
          value: el.data[dataPointIndex],
          color: w.globals.colors[idx],
        }));
        const result = tooltipItems.map(el => {
          let formattedValue;

          // Định dạng giá trị dựa trên tên
          switch (el.name) {
            case "Doanh số":
              formattedValue = formatCurrency(el.value) + " VNĐ"; // Thêm đơn vị VND
              break;
            case "Target":
              formattedValue = formatCurrency(el.value) + " VNĐ"; // Thêm đơn vị Target
              break;
            case "%DS/TG":
              formattedValue = el.value + "%"; // Thêm ký hiệu phần trăm
              break;
            default:
              formattedValue = el.value; // Không thêm đơn vị cho các trường hợp khác
          }

          return {
            ...el,
            value: formattedValue,
          };
        });
        return renderToStaticMarkup(
          <CustomTooltip
            title={`${categories[dataPointIndex]}`}
            data={result}
          />
        );
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: "Không có dữ liệu",
    },
  };

  return (
    <div>
      {loading ? (
        <Spin tip="Loading...">
          <div style={{ height: 739 }} />
        </Spin>
      ) : (
        <>
          <Typography.Title
            className="text-center"
            style={{ fontWeight: "bold", marginBottom: 0, fontSize: "20px" }}
          >
            Doanh thu theo khách hàng
          </Typography.Title>
          {dataRevenueByCustomer.length > 0 ? (
            <div className="d-flex justify-content-center align-items-center">
              {[
                {
                  id: "DS",
                  color: "#008FFB",
                  averageTimeTitle: "Doanh số",
                },
                {
                  id: "TG",
                  color: "#00E396",
                  averageTimeTitle: "Target",
                },
                {
                  id: "DS_TG",
                  color: "#FEB019",
                  averageTimeTitle: "%DS/TG",
                },
              ].map((el, idx) => (
                <PieLegendSharp
                  key={el.id}
                  color={el.color}
                  label={el.averageTimeTitle}
                  style={{ margin: idx % 2 ? "0" : "0 20px" }}
                />
              ))}
            </div>
          ) : (
            <></>
          )}

          <div id="chart" style={{ overflowX: "scroll" }}>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={700}
              width={getChartWidth()}
            />
          </div>
        </>
      )}
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
