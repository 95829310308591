import React from "react";
import { Card } from "reactstrap";
import { Typography, Row, Col, Spin, Tooltip } from "antd";

const PieLegendSharp = ({ color = "#FFF", label = "", style = {} }) => (
  <div className="d-flex align-items-center" style={style}>
    <div className="pie-legend-sharp" style={{ borderRightColor: color }} />
    <Typography className="ml-1" style={{ fontSize: ".9rem" }}>
      {label}
    </Typography>
  </div>
);

const CustomCard = ({
  item: {
    title1,
    title2,
    value,
    background = "linear-gradient(180deg, #FEB019, #eb7f05)",
    percent
  },
  isLoading
}) => (
  <Tooltip title={title1}>
    <div
      className="w-100"
      style={{
        padding: "5px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "8px",
        background,
      }}
    >
      <Typography
        className="text-center text-truncate"
        style={{ color: "#FFF" }}
      >
        {title2}
      </Typography>

      {!isLoading ? (
        <Typography.Title
          className="mt-1 mb-1 text-center text-truncate"
          level={2}
          style={{ color: "#FFF" }}
        >
          {value} - {percent}%
        </Typography.Title>
       ) : (
        <div
          className="w-100 d-flex justify-content-center py-3"
          style={{ background: "rgba(255,255,255,.4)", borderRadius: "4px" }}
        >
          <Spin />
        </div>
      )} 
    </div>
  </Tooltip>
);

const Index = ({ data, cardData, isLoading }) => { 
  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Tỉ lệ TAT trên hệ
            </Typography.Title>
          </div>
        </div>

        <Row gutter={[16, 16]} className="mt-3">
          {cardData.map((item) => (
            <Col key={item.id} lg={6} md={12} sm={12} xs={24}>
              <CustomCard item={item} isLoading={isLoading}/>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default Index;
