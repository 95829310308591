import React, { useEffect, useState } from "react";
import { PageContent, CustomBreadcrumb } from "components/Common";

import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MasterTestCode from "./MasterTestCode";
import MasterGroupTestService from "services/MasterGroupTest";
import MasterCategoryService from "services/MasterCategory";
import MasterParentTestService from "services/MasterParentTest";
const RESOURCE = ModuleIds.MasterTestCode;

const MasterTestCodeContainer = ({ t }) => {
  const titleTag = t("Danh sách Master TestCode");
  const [masterGroupTest, setMasterGroupTest] = useState([]);
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterParentTest, setMasterParentTest] = useState([]);

  const getDataGroupTest = async () => {
    const res = await MasterGroupTestService.GetLsMasterGroupTestService();
    setMasterGroupTest(res);
  };

  const getDataCategory = async () => {
    const res = await MasterCategoryService.GetLsMasterCategoryService();
    setMasterCategory(res);
  };

  const getDataParentTest = async () => {
    const res = await MasterParentTestService.GetLisLabelValueMasterParentTestService();
    setMasterParentTest(res);
  };
  useEffect(() => {
    if (masterGroupTest.length > 0) return;
    getDataGroupTest();
    getDataCategory();
    getDataParentTest();
  }, []);
  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb
        breadcrumbItem={titleTag}
        resource={RESOURCE}
        icon={<i className="bx bx-file" />}
      />
      {/* Page Body */}
      <MasterTestCode
        masterGroupTest={masterGroupTest}
        masterCategory={masterCategory}
        masterParentTest={masterParentTest}
      />
    </PageContent>
  );
};

MasterTestCodeContainer.propTypes = {
  t: PropTypes.any,
};

export default withTranslation(["masterTestCodePage"])(MasterTestCodeContainer);
