import Table, { getSTTCellData } from "components/Common/Table";
import { Button } from "reactstrap";
import { format } from "date-fns";
const Index = ({ paging, setPaging, data, pageSize }) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "",       
          fixed:"left",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSTTCellData(data, paging)}
            </div>
          ),
        },
        {
          Header: "SiteCode",
          accessor: "siteCode",
          width: 100,
        },
        {
          Header: "SiteName",
          accessor: "siteName",
          width: 250,
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          width: 150,
          Cell: ({ value }) => {
            return <div>{format(new Date(value), "yyyy-MM")}</div>;
          },
        },
        {
          Header: "MasterInstrumentID",
          accessor: "masterInstrumentID",
          width: 150,
        },
        {
          Header: "MasterInstrumentName",
          accessor: "masterInstrumentName",
          width: 250,
        },
        {
          Header: "MasterTestcode",
          accessor: "masterTestcode",
          width: 150,
        },
        {
          Header: "MasterTestName",
          accessor: "masterTestName",
          width: 150,
        },
        {
          Header: "MasterCategoryCode",
          accessor: "masterCategoryCode",
          width: 150,
        },
        {
          Header: "MasterCategoryName",
          accessor: "masterCategoryName",
          width: 150,
        },
        {
          Header: "CountTest",
          accessor: "countTest",
          width: 150,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-end">{parseFloat(value).toLocaleString("vi-VN")}</div>
          ),
        },
        {
          Header: "Ma_hang",
          accessor: "ma_hang",
          width: 150,
        },
        {
          Header: "ten_nh",
          accessor: "ten_nh",
          width: 150,
        },
        {
          Header: "ma_linhvuc",
          accessor: "ma_linhvuc",
          width: 150,
        },
        {
          Header: "ten_linhvuc",
          accessor: "ten_linhvuc",
          width: 150,
        },
        {
          Header: "nh_vt3",
          accessor: "nh_vt3",
          width: 150,
        },
        {
          Header: "CommonName",
          accessor: "commonName",
          width: 150,
        },
      ]}
      disablePagination={false}
    />
  );
};

export default Index;
