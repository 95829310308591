import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalHeader } from "reactstrap"

const CustomModal = ({
  children,
  size,
  modal,
  title,
  classBackground,
  onToggle,
  style
}) => {
  return (
    <Modal scrollable={true} size={size} isOpen={modal} style={style} >
      <ModalHeader className={classBackground} toggle={onToggle} tag="h5">
        {title}
      </ModalHeader>
      {children}
    </Modal>
  )
}

CustomModal.propTypes = {
  size: PropTypes.string,
  modal: PropTypes.bool,
  title: PropTypes.string,
  classBackground: PropTypes.string,
  onToggle: PropTypes.func,
}

CustomModal.defaultProps = {
  size: "lg",
  classBackground: "",
  children: <h6>children</h6>,
  onToggle: () => {},
}

export default CustomModal
