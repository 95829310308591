import axios from "axios";
import { get, put, post, del, delData, postBlobFile } from "../api_helper";
import ApiCaller from "ApiCaller";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/InstrumentSurvey`;

const GetListMapInstrumentSurvey = data => {
  return post(`${BASE_API_URL}/GetListMapInstrumentSurvey`, data);
};
const UpdateMapInstrumentSurveyManual = data => {
  return post(`${BASE_API_URL}/UpdateMapInstrumentSurveyManual`, data);
};
const ExportMapInstrumentSurvey = data => {
  return post(`${BASE_API_URL}/ExportMapInstrumentSurvey`, data, {
    responseType: "blob",
  });
};

const ImportMapInstrumentSurvey = async (data) => {
  return await ApiCaller.post(
    `${BASE_API_URL}/ImportMapInstrumentSurvey`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    }
  );
};

export {
    GetListMapInstrumentSurvey,
    ExportMapInstrumentSurvey,
    ImportMapInstrumentSurvey,
    UpdateMapInstrumentSurveyManual
};
