import {
  getLsTestCodeApi,
  getLsMasterTestCodeApi,
  InsertTestCodeManualApi,
  UpdateTestCodeManualApi,
  getLsLabelValueMasterTestCodeApi
} from "helpers/app-backend/maptestcode_helpers";
import HandleReponse from "utils/handleReponse";

const getLsTestCode = async (paramters, reqBody) => {
  try {
    const res = await getLsTestCodeApi(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const getLsMasterTestCode = async (paramters, reqBody) => {
  try {
    const res = await getLsMasterTestCodeApi(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const InsertTestCodeManualService =  async (paramters, reqBody) => {
  let result;
  try {
    const res = await InsertTestCodeManualApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const UpdateTestCodeManualService =  async (paramters, reqBody) => {
  let result;
  try {
    const res = await UpdateTestCodeManualApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const getLsLabelValueMasterTestCodeService = async query => {
  try {
    const res = await getLsLabelValueMasterTestCodeApi(query);
    return res;
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

export default {
  getLsTestCode,
  getLsMasterTestCode,
  InsertTestCodeManualService,
  UpdateTestCodeManualService,
  getLsLabelValueMasterTestCodeService
};
