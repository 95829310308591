import {
  ExportPatientData,
  ExportResultData,
  ExportResultInstrumentData,
  ExportResultDepartmentData,
} from "helpers/app-backend/datacollection_helpers";

const exportPatientData = async ({ siteCode, reqBody }) => {
  try {
    const res = await ExportPatientData(siteCode, reqBody);
    return res;
  } catch (err) {
    return null;
  }
};

const exportResultData = async ({ siteCode, reqBody }) => {
  try {
    const res = await ExportResultData(siteCode, reqBody);
    return res;
  } catch (err) {
    return null;
  }
};

const exportResultInstrumentData = async ({ siteCode, reqBody }) => {
  try {
    const res = await ExportResultInstrumentData(siteCode, reqBody);
    return res;
  } catch (err) {
    return null;
  }
};

const exportResultDepartmentData = async ({ siteCode, reqBody }) => {
  try {
    const res = await ExportResultDepartmentData(siteCode, reqBody);
    return res;
  } catch (err) {
    return null;
  }
};

export default {
  exportPatientData,
  exportResultData,
  exportResultInstrumentData,
  exportResultDepartmentData,
};
