import React, { useRef, useState, useEffect } from "react";
import StatisticService from "services/Statistic";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalDetail from "./Detail/ModalDetail";
import ModalDetailCommon from "./DetailByYear/ModalDetail";
import { showToast } from "components/Common";
import { startOfMonth, format } from "date-fns";
import Chart from "../../../../components/Dashboards/StackChart";
import { GetDataSetChartJS } from "./../constants";
const Index = ({ sites, filterAll, selectValue, setSelectValue }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSelectSite, setDataSelectSite] = useState([]);
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);
  const [dataChart, setDataChart] = useState({});
  const [paraDetail, setParaDetail] = useState([]);
  const [filter, setFilter] = useState({
    siteCode: filterAll.siteCode || [],
    dateFrom: new Date("0001-01-01"),
    dateTo: new Date("0001-01-01"),
  });

  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });

  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [columnTable, setColumnTable] = useState([]);

  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        siteCode: filterAll.siteCode || [],
        dateFrom: format(startOfMonth(filterAll.dateFrom), "yyyy-MM-dd"),
        dateTo: format(startOfMonth(filterAll.dateTo), "yyyy-MM-dd"),
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await StatisticService.GetListStatisticStatMonthResultSaleByYearService(
          params,
          requestBody
        );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      let dataChart = GetDataSetChartJS(res.data, sites, "StatYear");
      setDataChart(dataChart);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  // useEffect(() => {
  //   getTableData();
  // }, [paging]);

  useEffect(() => {
    setPaging(prev => ({ ...prev, currentPage: 1 }));
    setFilter(prev => ({ ...prev, siteCode: filterAll.siteCode }));
    getTableData();
  }, [filterAll]);

  return (
    <>
      {!loading && <Chart loading={loading} data={dataChart} />}
      <Table
        colums={columnTable}
        paging={paging}
        pageSize={paging.pageSize}
        filter={filter}
        selectValue={selectValue}
        setPaging={setPaging}
        data={tableData}
        setSiteCodeDetail={setSiteCodeDetail}
        setIsOpenDetail={setIsOpenDetail}
        setIsOpenModalDetail={setIsOpenModalDetail}
        setParaDetail={setParaDetail}
      />
      {isOpenModalDetail && (
        <ModalDetail
          filterAll={filterAll}
          sites={sites}
          setParaDetail={setParaDetail}
          param={paraDetail}
          setSiteCodeDetail={setSiteCodeDetail}
          data={siteCodeDetail}
          isOpen={isOpenModalDetail}
          onClose={() => setIsOpenModalDetail(false)}
        />
      )}
      {isOpenDetail && (
        <ModalDetailCommon
          filterAll={filterAll}
          sites={sites}
          filter={filter}
          setParaDetail={setParaDetail}
          param={paraDetail}
          setSiteCodeDetail={setSiteCodeDetail}
          data={siteCodeDetail}
          isOpen={isOpenDetail}
          onClose={() => setIsOpenDetail(false)}
        />
      )}
    </>
  );
};

export default Index;
