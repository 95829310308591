import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import { Button } from "reactstrap"

const CustomButton = ({
  text,
  t,
  tReady,
  loading,
  disabled,
  isEdit,
  computedMatch,
  children,
  ...rest
}) => {
  // console.log(moduleId)
  const updating = useSelector(state => state.common?.updating)
  const isLoading = isEdit && (updating || loading)
  return (
    <Button disabled={isLoading || disabled} {...rest}>
      {isLoading && (
        <i className="bx bx-loader-alt bx-spin font-size-16 align-middle me-2"></i>
      )}{" "}
      {children ? children : t(text)}
    </Button>
  )
}

CustomButton.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
}
CustomButton.defaultProps = {
  type: "button",
  // className: "btn btn-secondary",
  onClick: () => {},
}

export default withTranslation(["button"])(CustomButton)
