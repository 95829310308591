import React from "react"
import PropTypes from "prop-types"
import { getFirstLetterOfEachWords } from "helpers/utilities"

const LetterAvatar = ({ name, className }) => {
  return (
    <span
      className={`avatar-xs avatar-title rounded-circle bg-success bg-soft text-success ${className}`}
    >
      {getFirstLetterOfEachWords(name)}
    </span>
  )
}

LetterAvatar.defaultProps = {
  className: "",
}

LetterAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default LetterAvatar
