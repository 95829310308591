import React, { useState, useMemo, useEffect } from "react";

import {
  Button,
  Card,
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Tooltip } from "antd";
import { CustomButton } from "components/Common";
import DownloadFile from "components/Common/DownloadFile";
import dayjs from "dayjs";
import {
  ExportCombineStatYearly,
  ExecuteStoredProcedures,
} from "../../../helpers/app-backend/statisticsannualbackend_helper";
import SiteService from "services/Site";
import CategoryService from "services/MasterCategory";
import ERPProductService from "services/ERPProduct";
import Select from "react-select";
import toastr from "toastr";
const { RangePicker } = DatePicker;

const Index = ({
  dataFilter,
  setDataFilter,
  onChangeSubmitData,
  paging,
  setPaging,
}) => {
  const [isCombineData, setIsCombineData] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [isExportFile, setIsExportFile] = useState(false);
  const handleInputChange = (field, value) => {
    setDataFilter(prev => ({ ...prev, [field]: value }));
  };
  const getExportApi = () => {
    return ExportCombineStatYearly;
  };
  const [dataSite, setDataSite] = useState([]);
  const [dataERPProduct, setDataERPProduct] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  // const [selectValue, setSelectValue] = useState([]);

  const getData = async () => {
    const res = await SiteService.getListMasterSite({
      search: "",
    });
    let result = res.map(
      item =>
        (item = {
          value: item.siteCode,
          label: item.siteName,
        })
    );
    // result.unshift({ value: "all", label: "Chọn tất cả" });
    setDataSite(result);
  };
  const getDataERPProduct = async () => {
    const res = await ERPProductService.getLisErpProductsService(
      {
        search: "",
      },
      {
        page: 1,
        pageSize: 1000,
      }
    );
    let result = res.data.map(
      item =>
        (item = {
          value: item.maHang,
          label: item.maHang,
        })
    );
    setDataERPProduct(result);
  };
  const getDataCategory = async () => {
    const res = await CategoryService.GetLsMasterCategoryService({
      search: "",
    });
    let result = res.map(
      item =>
        (item = {
          value: item.value,
          label: item.label,
        })
    );
    // result.unshift({ value: "all", label: "Chọn tất cả" });
    setDataCategory(result);
  };

  const HandleSubmitSite = (fieldName, val) => {
    let filterAll = val.filter(x => x.value === "all");
    if (filterAll.length > 0) {
      let resfilter = dataSite.filter(item => item.value !== "all");
      setDataFilter(prev => ({
        ...prev,
        [fieldName]: resfilter.map(x => x.value),
      }));
    } else {
      setDataFilter(prev => ({
        ...prev,
        [fieldName]: val.map(item => item.value),
      }));
    }
  };

  useMemo(() => {
    getData();
    getDataCategory();
    getDataERPProduct();
  }, []);
  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">SiteName</Label>
            <div style={{ zIndex: 100 }}>
              <Select
                className="custom-select"
                isMulti
                options={dataSite}
                placeholder="Chọn bệnh viện"
                defaultValue={dataFilter.siteCode}
                onChange={val => {
                  HandleSubmitSite("siteCode", val);
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">CategoryName</Label>
            <div style={{ zIndex: 100 }}>
              <Select
                className="custom-select"
                isMulti
                options={dataCategory}
                placeholder="Chọn MasterCategoryName"
                defaultValue={dataFilter.masterCategoryCode}
                onChange={val => {
                  HandleSubmitSite("masterCategoryCode", val);
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">Hãng</Label>
            <div style={{ zIndex: 100 }}>
              <Select
                className="custom-select"
                isMulti
                name="masterProducerCode"
                options={dataERPProduct}
                placeholder="Chọn hãng"
                defaultValue={dataFilter.masterProducerCode}
                onChange={val => {
                  HandleSubmitSite("masterProducerCode", val);
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">DataType</Label>
            <div style={{ zIndex: 100 }}>
              <Select
                className="custom-select"
                name="dataType"
                options={[
                  { value: "ALL", label: "ALL" },
                  { value: "KS", label: "KS" },
                  { value: "LIS", label: "LIS" },
                ]}
                placeholder="Chọn DataType"
                defaultValue={{ value: "ALL", label: "ALL" }}
                onChange={val => {
                  setDataFilter(prev => ({
                    ...prev,
                    dataType: val.value,
                  }));
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3} className="mt-2">
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">StatYear</Label>
            <div style={{ zIndex: 4 }}>
              <RangePicker
                picker="year"
                style={{ width: "100%", paddingBottom: 6, paddingTop: 6 }}
                placeholder={["From Year", "To Year"]}
                size="large"
                value={
                  dataFilter.fromYear && dataFilter.toYear
                    ? [
                        dayjs(dataFilter.fromYear, "YYYY"),
                        dayjs(dataFilter.toYear, "YYYY"),
                      ]
                    : null
                }
                onChange={(dates, dateStrings) => {
                  handleInputChange("fromYear", dateStrings[0]);
                  handleInputChange("toYear", dateStrings[1]);
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} xl={6} style={{ marginTop: 30 }}>
          <Tooltip title={"Tìm kiếm"}>
            <CustomButton
              color="primary"
              className="mx-2"
              onClick={() => onChangeSubmitData()}
            >
              <div className="d-flex align-items-center">
                <i className="bx bx-search" style={{ fontSize: "1rem" }}></i>
                <p className="mb-0 mx-1">{"Tìm kiếm"}</p>
              </div>
            </CustomButton>
          </Tooltip>
          <Tooltip title={"Combine data"}>
            <CustomButton
              color="success"
              className="mx-2"
              disabled={isDisable}
              onClick={async () => {
                try {
                  setIsCombineData(true);
                  setDisable(true);
                  const response = await ExecuteStoredProcedures();
                  if (response?.error) {
                    setIsCombineData(false);
                    setDisable(false);
                    toastr.error("Đã có lỗi xảy ra");
                  } else {
                    setIsCombineData(false);
                    setDisable(false);
                    onChangeSubmitData();
                    toastr.success("Tổng hợp dữ liệu thành công");
                  }
                } catch (error) {
                  // Handle errors
                  setIsCombineData(false);
                  setDisable(false);
                  toastr.error("Đã có lỗi xảy ra");
                }
              }}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-upload"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mb-0 mx-1">{"Combine data"}</p>
              </div>
            </CustomButton>
          </Tooltip>
          <Tooltip title={"Xuất Excel"}>
            <CustomButton
              color="success"
              className="mx-2"
              onClick={() => setIsExportFile(true)}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-download"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mb-0 mx-1">Xuất Excel</p>
              </div>
            </CustomButton>
          </Tooltip>
        </Col>
      </Row>
      {isExportFile && (
        <DownloadFile
          isOpen={isExportFile}
          isUseFilter
          request={{
            siteCode: dataFilter.siteCode,
            masterCategoryCode: dataFilter.masterCategoryCode,
            masterProducerCode: dataFilter.masterProducerCode,
            dataType: dataFilter.dataType,
            fromYear: dataFilter.fromYear,
            toYear: dataFilter.toYear,
            page: paging.currentPage,
            pageSize: paging.pageSize,
          }}
          serviceApi={getExportApi()}
          onClose={() => setIsExportFile(false)}
          fileName={`Danh sách thống kê dữ liệu tổng hợp năm`}
        />
      )}
      <Modal isOpen={isCombineData}>
        <ModalHeader>Tổng hợp dữ liệu</ModalHeader>
        <ModalBody className="p-3">
          <Row>
            <Label>Đang xử lý, xin vui lòng chờ trong giây lát...</Label>
            <div className="mt-1">
              <Progress color="primary" animated value="100" />
            </div>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-center mt-4">
                <Button color="danger" onClick={() => setIsCombineData(false)}>
                  Đóng
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default Index;
