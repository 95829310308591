import Table from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setIsEdit,
  setIsModalInsertUpdate,
  setDetailData,
  setIsModalConfirmDelete,
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "",
          width: 70,
          fixed: "left",
          headerClassName: "sticky-header",
          Cell: data => (
            <div className="d-flex">
              <Button
                size="sm"
                className="d-flex w-100 justify-content-center me-1"
                style={{ backgroundColor: "#c1c4e8", borderWidth: 0 }}
                onClick={e => {
                  setIsEdit(true);
                  setIsModalInsertUpdate(true);
                  setDetailData(data.original);
                }}
              >
                <i className="mdi mdi-pencil text-primary"></i>
              </Button>
              <Button
                size="sm"
                className="d-flex w-100 justify-content-center"
                style={{ backgroundColor: "#f4dcda", borderWidth: 0 }}
                onClick={e => {
                  setIsModalConfirmDelete(true);
                  setDetailData(data.original);
                }}
              >
                <i className="mdi mdi-delete text-danger"></i>
              </Button>
            </div>
          ),
        },
        {
          fixed: "left",
          headerClassName: "sticky-header",
          Header: "InstrumentCode",
          accessor: "instrumentCode",
          width: 170,
        },
        {
          fixed: "left",
          headerClassName: "sticky-header",
          Header: "InstrumentName",
          accessor: "instrumentName",
          width: 490,
        },
        {
          Header: "InstrumentType",
          accessor: "instrumentType",
          width: 150,
        },
        {
          Header: "OtherName",
          accessor: "otherName",
          width: 180,
        },
        {
          Header: "ProducerCode",
          accessor: "producerCode",
          width: 130,
        },
        {
          Header: "ProducerName",
          accessor: "producerName",
          width: 200,
        },
        {
          Header: "FieldCode",
          accessor: "fieldCode",
          width: 120,
        },
        {
          Header: "FieldName",
          accessor: "fieldName",
          width: 220,
        },
        {
          Header: "VT3Code",
          accessor: "vT3Code",
          width: 100,
        },
        {
          Header: "Description",
          accessor: "decription",
          minWidth: 200,
        },
        {
          Header: "ERPCode",
          accessor: "erpCode",
          minWidth: 200,
        },
      ]}
      disablePagination={false}
      style={{ height: "calc(100vh - 360px)" }}
    />
  );
};

export default Index;
