import React, { useRef, useState, useEffect } from "react";
import TestCodeService from "services/TestCode";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ManualAddModal from "./ManualAddModal";
import { ConfirmModal, showToast } from "components/Common";
import MasterTestCodeService from "services/MasterTestCode";
const Index = ({ masterGroupTest, masterCategory, masterParentTest }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    testCodeName: "",
    commonName: "",
    category: "",
    pricePerTestFrom: undefined,
    pricePerTestTo: undefined,
    groupTest: "",
    parentTest: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isDone, setIsDone] = useState(false);
  const [detailData, setDetailData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [isModalInsertUpdate, setIsModalInsertUpdate] = useState(false);
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        testCodeName: filter.testCodeName,
        commonName: filter.commonName,
        category: filter.category,
        pricePerTestFrom: filter.pricePerTestFrom,
        pricePerTestTo: filter.pricePerTestTo,
        groupTest: filter.groupTest,
        parentTest: filter.parentTest,
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await TestCodeService.getLsMasterTestCode(
        params,
        requestBody
      );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setLoading(false);
      setIsDone(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };
  const getRequestParamsSearch = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: 1,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        testCodeName: filter.testCodeName,
        commonName: filter.commonName,
        category: filter.category,
        pricePerTestFrom: filter.pricePerTestFrom,
        pricePerTestTo: filter.pricePerTestTo,
        groupTest: filter.groupTest,
        parentTest: filter.parentTest,
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableDataSearch = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParamsSearch(
      _params,
      _requestBody
    );
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await TestCodeService.getLsMasterTestCode(
        params,
        requestBody
      );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setLoading(false);
      setIsDone(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };
  const onDeleteDetail = async () => {
    const res = await MasterTestCodeService.DeleteMasterTestCodeService(
      detailData
    );
    if (res.error) {
      showToast(`${res.error.title}`, 3);
      setLoading(false);
      setIsDone(true);
    } else {
      showToast(`Xóa thành công ${detailData?.testCode}`, 1);
      setIsDone(true);
      setIsModalConfirmDelete(false);
    }
  };
  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging, isDone]);

  useEffect(() => {
    getTableDataSearch();
  }, [filter.category, filter.groupTest, filter.parentTest]);

  return (
    <>
      <Filter
        setIsEdit={setIsEdit}
        masterGroupTest={masterGroupTest}
        masterCategory={masterCategory}
        masterParentTest={masterParentTest}
        setIsModalInsertUpdate={setIsModalInsertUpdate}
        filter={filter}
        setFilter={setFilter}
        isLoading={loading}
        onSearch={() => {
          // setPaging(prev => ({ ...prev, currentPage: 1 }));
          // getTableData({ page: 1 });
          getTableDataSearch();
        }}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setIsEdit={setIsEdit}
        setIsModalInsertUpdate={setIsModalInsertUpdate}
        setIsModalConfirmDelete={setIsModalConfirmDelete}
        setDetailData={setDetailData}
      />
      {isModalInsertUpdate && (
        <ManualAddModal
          setDetailData={setDetailData}
          isEdit={isEdit}
          data={isEdit ? detailData : {}}
          setIsDone={setIsDone}
          masterGroupTest={masterGroupTest}
          masterCategory={masterCategory}
          masterParentTest={masterParentTest}
          isOpen={isModalInsertUpdate}
          onClose={() => {
            setIsModalInsertUpdate(false);
            setDetailData({});
          }}
        />
      )}
      {isModalConfirmDelete && (
        <ConfirmModal
          modal={isModalConfirmDelete}
          title="Xác nhận xóa?"
          message={`Bạn có chắc chắn muốn xóa testcode ${detailData?.testCode}?`}
          onToggle={() => {
            setIsModalConfirmDelete(false);
          }}
          onDelete={onDeleteDetail}
        />
      )}
    </>
  );
};

export default Index;
