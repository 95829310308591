import React, { useEffect, useState } from "react"
import { PageContent, CustomBreadcrumb } from "components/Common";
import AutoSystem from "./AutoSystem";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SiteService from 'services/Site';

const RESOURCE = ModuleIds.AutoSystem;

const AutoSystemContainer = ({ t }) => {
    const [sites, setSites] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const res = await SiteService.getLabelAndValueSite({
                page: 1,
                size: 1000,
                search: "",
            });
            setSites(res.data)
        }
        getData();
    }, []);


    const titleTag = `Đánh giá hệ tự động`
    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={titleTag}
                resource={RESOURCE}
                icon={<i className='bx bx-bar-chart-alt' ></i>}
            />
            {/* Page Body */}
            <AutoSystem sites={sites} />
        </PageContent >
    )
}

AutoSystemContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["sitePage"])(AutoSystemContainer)