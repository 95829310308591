import React from "react"
import { PageContent, CustomBreadcrumb } from "components/Common";
import StatisticsSurveyResult from "./StatisticsSurveyResult";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"

const RESOURCE = ModuleIds.StatisticsSurveyResult;

const StatisticsRealSaleContainer = ({ t }) => {
    const titleTag = t("Thống kê dữ liệu tổng hợp năm")
    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={titleTag}
                resource={RESOURCE}
                icon={<i className="bx bx-file" />}
            />
            {/* Page Body */}
            <StatisticsSurveyResult />
        </PageContent >
    )
}

StatisticsRealSaleContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["sitePage"])(StatisticsRealSaleContainer)