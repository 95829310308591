import { call, put, takeEvery, select, all } from "redux-saga/effects";
import i18n from "i18next";

//setting redux states
import {
  ADD_SITE,
  DELETE_SITE,
  GET_SITES,
  GET_SITE_DETAIL,
  UPDATE_SITE,
  RESET_SITE_SEARCH_QUERY,
  SET_SITE_SEARCH_QUERY,
  SYNC_SITE,
  SYNC_SITE_FAIL,
  SYNC_SITE_SUCCESS,
  EXPORT_MAP_TEST_CODE,
  EXPORT_MAP_TEST_CODE_FAIL,
  EXPORT_MAP_TEST_CODE_SUCCESS,
  SYNC_DATABASE,
  COMBINE_STATMONTHPATIENT,
  COMBINE_STATMONTHRESULT,
  COMBINE_STATMONTHLOCATIONTYPE,
  COMBINE_STATMONTHRESULTINSTRUMENT,
  REPORT_DATA_DATABASE,
  REPORT_DATA_INSTRUMENT,
  REPORT_DATA_STATMONTH,
} from "./actionTypes";

import {
  addNewSiteFail,
  addNewSiteSuccess,
  deleteSitesFail,
  deleteSitesSuccess,
  getSiteDetailFail,
  getSiteDetailSuccess,
  getSitesFail,
  getSitesSuccess,
  setSiteSearchQuery,
  updateSiteFail,
  updateSiteSuccess,
  syncSites,
  syncSitesSuccess,
  syncSiteFail,
  exportMapTestCodeDataFail,
  exportMapTestCodeDataSucess,
  syncDatabaseTestCode,
  syncDatabaseTestCodeSucess,
  syncDatabaseTestCodeFail,
  combineStatMonthPatient,
  combineStatMonthPatientSuccess,
  combineStatMonthPatientFail,
  combineStatMonthResult,
  combineStatMonthResultFail,
  combineStatMonthResultSuccess,
  combineStatMonthlyResultLocationType,
  combineStatMonthlyResultLocationTypeSuccess,
  combineStatMonthlyResultLocationTypeFail,
  combineStatMonthlyResultInstrument,
  combineStatMonthlyResultInstrumentFail,
  combineStatMonthlyResultInstrumentSuccess,
  exportDataDatabaseAction,
  exportDataDatabaseActionFail,
  exportDataDatabaseActionSucess,
  exportDataInstrumentAction,
  exportDataInstrumentActionFail,
  exportDataInstrumentActionSucess,
  exportDataStatMonth,
  exportDataStatMonthFail,
  exportDataStatMonthSuccess
} from "./actions";

//call api thật
import {
  getAllSites,
  getSiteById,
  updateSiteById,
  createSite,
  deleteSiteById,
  syncSite,
  ExportMapTestCodeData,
  SyncDatabaseTestCode,
  insertCombineStatMonthlyPatient,
  insertCombineStatMonthlyResult,
  insertCombineStatMonthlyResultLocationType,
  insertCombineStatMonthlyResultInstrument,
  ExportReportDataDatabase,
  ExportReportDataInstrument,
  ExportStatMonth,
} from "helpers/app-backend";

import { showToast } from "components/Common";
const t = (msg, param) => i18n.t(msg, param);

function* onCombineStatMonthlyResultInstrument({ payload }) {
  try {
    const response = yield call(
      insertCombineStatMonthlyResultInstrument,
      payload
    );
    yield put(combineStatMonthlyResultInstrumentSuccess(response));
    showToast(
      `${`Combine dữ liệu table CombineStatMonthlyResultInstrument bệnh viện ${payload.siteCode} thành công`}`
    );
  } catch (error) {
    showToast(
      `Combine dữ liệu table CombineStatMonthlyResultInstrument khôn thành công: ${error.response.data.title}`,
      3
    );
    yield put(combineStatMonthlyResultInstrumentFail(error));
  }
}

function* onCombineStatMonthlyResultLocationType({ payload }) {
  try {
    const response = yield call(
      insertCombineStatMonthlyResultLocationType,
      payload
    );
    yield put(combineStatMonthlyResultLocationTypeSuccess(response));
    showToast(
      `${`Combine dữ liệu table StatMonthlyResultLocationType bệnh viện ${payload.siteCode} thành công`}`
    );
  } catch (error) {
    showToast(
      `Combine dữ liệu table StatMonthlyResultLocationType khôn thành công: ${error.response.data.title}`,
      3
    );
    yield put(combineStatMonthlyResultLocationTypeFail(error));
  }
}

function* onCombineStatMonthlyPatient({ payload }) {
  try {
    const response = yield call(insertCombineStatMonthlyPatient, payload);
    yield put(combineStatMonthPatientSuccess(response));
    showToast(
      `${`Combine dữ liệu table StatMonthPatient bệnh viện ${payload.siteCode} thành công`}`
    );
  } catch (error) {
    showToast(
      `Combine dữ liệu table StatMonthPatient khôn thành công: ${error.response.data.title}`,
      3
    );
    yield put(combineStatMonthPatientFail(error));
  }
}

function* onCombineStatMonthlyResult({ payload }) {
  try {
    const response = yield call(insertCombineStatMonthlyResult, payload);
    yield put(combineStatMonthResultSuccess(response));
    showToast(
      `${`Combine dữ liệu table StatMonthResult bệnh viện ${payload.siteCode} thành công`}`
    );
  } catch (error) {
    showToast(
      `Combine dữ liệu table StatMonthResult không thành công: ${error.response.data.title}`,
      3
    );
    yield put(combineStatMonthResultFail(error));
  }
}

function* fetchSites({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.sites.searchQuery;
    });
    payload = { ...searchQuery, ...payload };
    const response = yield call(getAllSites, payload);
    response.data.map(item => (item.id = item.siteCode));
    yield put(getSitesSuccess(response));
    yield put(setSiteSearchQuery(payload));
  } catch (error) {
    showToast(`Load site fail: ${error.response.data.title}`, 3);
    yield put(getSitesFail(error));
  }
}
function* exportDataDatabase() {
  try {
    const response = yield call(ExportReportDataDatabase);
    var binaryData = [];
    binaryData.push(response);
    let url = window.URL.createObjectURL(
      new Blob(binaryData, { type: "data:application/vnd.ms-excel;base64" })
    );
    let a = document.createElement("a");
    a.href = url;
    //TODO đổi tên
    a.download = `Báo cáo tiến độ công việc-${Date.now()}.xlsx`;
    a.click();
    yield put(exportDataDatabaseActionSucess());
    showToast(`${t("Export báo cáo tiến độ thành công")}`);
  } catch (error) {
    showToast(`Export Báo cáo tiến độ lỗi: ${error.response.data.title}`, 3);
    yield put(ex(error));
  }
}
function* exportDataInstrument() {
  try {
    const response = yield call(ExportReportDataInstrument);
    var binaryData = [];
    binaryData.push(response);
    let url = window.URL.createObjectURL(
      new Blob(binaryData, { type: "data:application/vnd.ms-excel;base64" })
    );
    let a = document.createElement("a");
    a.href = url;
    //TODO đổi tên
    a.download = `Bao-cao-intrument-lis-erp-${Date.now()}.xlsx`;
    a.click();
    yield put(exportDataInstrumentActionSucess());
    showToast(`${t("Export báo cáo instrument thành công")}`);
  } catch (error) {
    showToast(
      `Export Báo cáo instrument lis erp lỗi: ${error.response.data.title}`,
      3
    );
    yield put(getSitesFail(error));
  }
}
function* fetchSiteDetail({ id }) {
  try {
    const response = yield call(getSiteById, id);
    if (response) {
      yield put(getSiteDetailSuccess(response));
    } else {
      yield put(getSiteDetailFail(response.err.msgString));
    }
  } catch (error) {
    yield put(getSiteDetailFail(error));
  }
}

function* onAddNewSite({ payload: { site, callback } }) {
  try {
    //call api thật
    const response = yield call(createSite, site);
    if (response) {
      yield put(getSiteDetailSuccess(response));
      showToast(`${"Tạo mới bệnh viện thành công"}`);
      if (callback) callback();
    } else {
      yield put(getSiteDetailFail("Lỗi tạo"));
    }
  } catch (error) {
    yield put(addNewSiteFail(error));
    showToast(`Tạo mới không thành công ${error.response.data.title}`, 3);
  }
}

function* onExportDataStatMonth({ payload: { site } }) {
  try{
    const data = yield select(state => {
      return state.sites.checkboxs;
    });
    const response = yield(call(ExportStatMonth, {siteCode: site.fullNameSchema, data}));

    var binaryData = [];
    binaryData.push(response);
    let url = window.URL.createObjectURL(
      new Blob(binaryData, { type: "data:application/vnd.ms-excel;base64" })
    );
    let a = document.createElement("a");
    a.href = url;
    //TODO đổi tên
    a.download = `Bao-cao-statmonh-${site.fullNameSchema}-${Date.now()}.xlsx`;
    a.click();
    yield put(exportDataStatMonthSuccess());
    showToast(`${t("Export báo cáo statmonth thành công")}`);

  }catch(error){
    showToast(`Export statmonth không thành công ${error.response.data.title}`, 3);
    yield put(exportDataStatMonthFail(error));
  }
}


function* onSyncSite({ payload: { site, callback } }) {
  try {
    const response = yield call(syncSite, site);
    yield put(syncSitesSuccess(response));
    showToast(`${`Sync site ${siteCode} thành công`}`);
    if (callback) callback();
  } catch (error) {
    yield put(syncSiteFail(error));
    showToast(`Sync site không thành công: ${error.response.data.title}`, 3);
  }
}

function* onUpdateSite({ payload: { site, callback } }) {
  try {
    const response = yield call(updateSiteById, site);
    site.id = site.siteCode;
    yield put(updateSiteSuccess(site));
    showToast(`Cập nhật thành công`);
    if (callback) callback();
  } catch (error) {
    yield put(updateSiteFail(error));
    showToast(`Cập nhật không thành công: ${error.response.data.title}`, 3);
  }
}

function* onDeleteSite({ payload: { sites, callback } }) {
  try {
    const response = yield call(deleteSiteById, sites[0]);
    yield put(deleteSitesSuccess(sites[0]));
    showToast(`${t("Xóa thành công")}`);
    if (callback) callback();
  } catch (error) {
    showToast(`Xóa không thành công: ${error.response.data.title}`, 3);
    yield put(deleteSitesFail(error));
  }
}

function* exportTestCodeExcel({ payload: { site, callback } }) {
  try {
    const response = yield call(ExportMapTestCodeData, site.fullNameSchema);
    //Download file
    var binaryData = [];
    binaryData.push(response);
    let url = window.URL.createObjectURL(
      new Blob(binaryData, { type: "data:application/vnd.ms-excel;base64" })
    );
    let a = document.createElement("a");
    a.href = url;
    //TODO đổi tên
    a.download = `${site.fullNameSchema}-${
      site.fullNameSite
    }-MapTestCode-${Date.now()}.xlsx`;
    a.click();
    yield put(exportMapTestCodeDataSucess(site.fullNameSchema));
    showToast(`${t("Export thành công")}`);
    if (callback) callback();
  } catch (error) {
    showToast(`Export không thành công: ${error?.response?.data?.title}`, 3);
    yield put(exportMapTestCodeDataFail(error));
  }
}

function* onSyncDatabaseTestcode({ payload: { site, callback } }) {
  try {
    const response = yield SyncDatabaseTestCode(site.fullNameSchema);
    yield put(exportMapTestCodeDataSucess(site.fullNameSchema));
    showToast(
      `Sync dữ liệu testcode ${site.fullNameSchema}-${site.fullNameSite} thành công `
    );
    if (callback) callback();
  } catch (error) {
    showToast(
      `Sync dữ liệu testcode ${site.fullNameSchema}-${site.fullNameSite} không thành công: ${error?.response?.data?.title}`,
      3
    );
    yield put(syncDatabaseTestCodeFail(error));
  }
}

function* siteSaga() {
  yield takeEvery(GET_SITES, fetchSites);
  yield takeEvery(GET_SITE_DETAIL, fetchSiteDetail);
  yield takeEvery(ADD_SITE, onAddNewSite);
  yield takeEvery(UPDATE_SITE, onUpdateSite);
  yield takeEvery(DELETE_SITE, onDeleteSite);
  yield takeEvery(SYNC_SITE, onSyncSite);
  yield takeEvery(EXPORT_MAP_TEST_CODE, exportTestCodeExcel);
  yield takeEvery(SYNC_DATABASE, onSyncDatabaseTestcode);
  yield takeEvery(COMBINE_STATMONTHPATIENT, onCombineStatMonthlyPatient);
  yield takeEvery(COMBINE_STATMONTHRESULT, onCombineStatMonthlyResult);
  yield takeEvery(
    COMBINE_STATMONTHLOCATIONTYPE,
    onCombineStatMonthlyResultLocationType
  );
  yield takeEvery(
    COMBINE_STATMONTHRESULTINSTRUMENT,
    onCombineStatMonthlyResultInstrument
  );
  yield takeEvery(REPORT_DATA_DATABASE, exportDataDatabase);
  yield takeEvery(REPORT_DATA_INSTRUMENT, exportDataInstrument);
  yield takeEvery(REPORT_DATA_STATMONTH, onExportDataStatMonth);
}
export default siteSaga;
