import React, { useEffect } from "react";
import { getMousePositionOnCanvas, isMouseEnterCircleSection } from "../utils";
import { configKey } from "../constants";

function SampleNumberEvt({
  areaList,
  setMachineSelected,
  sampleNumberList,
  config,
  instanceBuilder,
  ratio,
}) {
  useEffect(() => {
    const map = document.getElementById("map");
    if (!map || !config[configKey.RENDER_SAMPLE_NUMBER]) {
      return;
    }

    // Vẽ số lượng mẫu mỗi khi data thay đổi
    areaList.forEach(function (element) {
      if (
        element.sampleNumberX &&
        element.sampleNumberY &&
        sampleNumberList[element.id]
      ) {
        instanceBuilder.drawTextInsideCircle({
          x: element.sampleNumberX,
          y: element.sampleNumberY,
          number: sampleNumberList[element.id].number,
          bgColor: sampleNumberList[element.id].isWarning
            ? config[configKey.SAMPLE_NUMBER_WARNING_BG]
            : config[configKey.SAMPLE_NUMBER_NORMAL_BG],
          radius: config[configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER] / 2,
          fontSize: config[configKey.SAMPLE_NUMBER_FONT_SIZE],
          color: config[configKey.SAMPLE_NUMBER_COLOR],
          borderColor: config[configKey.SAMPLE_NUMBER_BORDER_COLOR],
        });
      }
    });

    // Click vào số lượng mẫu trên từng lab
    function clickHandler(evt) {
      const { x, y } = getMousePositionOnCanvas(map, evt);

      areaList.forEach(function (element) {
        if (
          element.sampleNumberX &&
          element.sampleNumberY &&
          sampleNumberList[element.id]
        ) {
          const isOnSection = isMouseEnterCircleSection({
            mouseX: x,
            mouseY: y,
            sectionX: element.sampleNumberX,
            sectionY: element.sampleNumberY,
            ratio,
            radius: config[configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER] / 2,
          });

          if (isOnSection) {
            setMachineSelected(element);
          }
        }
      });
    }

    // Hover vào số lượng mẫu trên máy và đổi màu
    function moveMoveHandler(evt) {
      const { x, y } = getMousePositionOnCanvas(map, evt);

      areaList.forEach(function (element) {
        if (
          element.sampleNumberX &&
          element.sampleNumberY &&
          sampleNumberList[element.id]
        ) {
          const isOnSection = isMouseEnterCircleSection({
            mouseX: x,
            mouseY: y,
            sectionX: element.sampleNumberX,
            sectionY: element.sampleNumberY,
            ratio,
            radius: config[configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER] / 2,
          });

          if (isOnSection) {
            instanceBuilder.drawTextInsideCircle({
              x: element.sampleNumberX,
              y: element.sampleNumberY,
              number: sampleNumberList[element.id].number,
              bgColor: sampleNumberList[element.id].isWarning
                ? config[configKey.SAMPLE_NUMBER_WARNING_BG_HOVER]
                : config[configKey.SAMPLE_NUMBER_NORMAL_BG_HOVER],
              radius: config[configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER] / 2,
              fontSize: config[configKey.SAMPLE_NUMBER_FONT_SIZE],
              color: config[configKey.SAMPLE_NUMBER_COLOR],
              borderColor: config[configKey.SAMPLE_NUMBER_BORDER_COLOR],
            });
          } else {
            instanceBuilder.drawTextInsideCircle({
              x: element.sampleNumberX,
              y: element.sampleNumberY,
              number: sampleNumberList[element.id].number,
              bgColor: sampleNumberList[element.id].isWarning
                ? config[configKey.SAMPLE_NUMBER_WARNING_BG]
                : config[configKey.SAMPLE_NUMBER_NORMAL_BG],
              radius: config[configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER] / 2,
              fontSize: config[configKey.SAMPLE_NUMBER_FONT_SIZE],
              color: config[configKey.SAMPLE_NUMBER_COLOR],
              borderColor: config[configKey.SAMPLE_NUMBER_BORDER_COLOR],
            });
          }
        }
      });
    }

    if (sampleNumberList && JSON.stringify(sampleNumberList) !== "{}") {
      map.addEventListener("click", clickHandler);
      map.addEventListener("mousemove", moveMoveHandler);
    }

    return () => {
      map.removeEventListener("click", clickHandler);
      map.removeEventListener("mousemove", moveMoveHandler);
    };
  }, [sampleNumberList, ratio, config]);

  return <></>;
}

export default SampleNumberEvt;
