import Table, { getSeqByCellData } from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setDetailCategory,
  setIsShowModalUpdate,
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "CategoryIDSurvey",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        {
          Header: "CategoryIDSurvey",
          accessor: "categoryIDSurvey",
          width: 150,
        },
        {
          Header: "SiteCodeNew",
          accessor: "siteCodeNew",
          width: 200,
        },
        {
          Header: "CategoryNameSurvey",
          accessor: "categoryNameSurvey",
          minWidth: 250,
        },
        {
          Header: "MapMasterCategoryID",
          accessor: "mapMasterCategoryID",
          minWidth: 250,
        },
        {
          Header: "Action",
          width: 80,
          Cell: data => (
            <Button
              className="d-flex w-100 justify-content-center"
              color="link"
              onClick={e => {
                setIsShowModalUpdate(true);
                setDetailCategory(data.original);
              }}
            >
              Edit
            </Button>
          ),
        },
      ]}
      disablePagination={false}
    />
  );
};

export default Index;
