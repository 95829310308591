import { Spin, Timeline, Tooltip, Typography, Button } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { AiOutlineClockCircle, AiOutlineSend } from "react-icons/ai";
import { BiCalendarAlt, BiTestTube, BiRefresh } from "react-icons/bi";
import { GiPathDistance } from "react-icons/gi";

import { area } from "../constants";
import { CustomCard } from "./custom";
import { timeLineStatistic, skipDistanceCalculate } from "../constants";
import { getDistanceByPixels } from "../utils";
import { convertSecondToDisplayTime } from "../config";

const getTargetName = target => {
  if (!target || !target.length) return null;

  for (let i = 0; i < target.length; i++) {
    if (target[i].data.area.type === area.TYPE.SECTION) {
      return (
        <p style={{ color: "#004cff", fontWeight: "bold" }}>
          {target[i].data.area.name}
        </p>
      );
    }
  }

  return null;
};
function formatTime(totalTime) {
  const hours = Math.floor(totalTime / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((totalTime % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(totalTime % 60)
    .toString()
    .padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}
const getStatistic = (data = [], distanceArr = [], totalTimeAll) => {
  // Tính tổng thời gian
  // let indexLast = data.findLastIndex(el => el.data.time);
  // const duration = moment.duration(
  //   moment(data[indexLast].data.time).diff(moment(data[0].data.time))
  // );

  let totalTime = totalTimeAll;

  // Tính tổng quãng đường
  let distancePixel = 0;
  distanceArr.forEach(el => {
    distancePixel += el.distancePixels;
  });

  let totalDistance = getDistanceByPixels(distancePixel);

  return {
    totalTime,
    totalDistance,
  };
};

const StatisticItem = ({ title, value, color, icon, bottom }) => (
  <div
    style={{
      width: "100%",
      padding: "10px",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      backgroundColor: "#FFF",
      borderRadius: "8px",
      marginTop: "10px",
      position: "sticky",
      bottom,
    }}
  >
    <div className="d-flex align-items-center">
      {icon}

      <div style={{ flex: 1 }}>
        <Typography.Title
          style={{ color }}
          level={2}
          className="mb-0 mt-0 ml-3 text-right"
        >
          {value}
        </Typography.Title>

        <Typography.Title
          level={5}
          style={{ fontSize: ".9rem" }}
          className="mb-0 mt-1 ml-3 text-right"
        >
          {title}
        </Typography.Title>
      </div>
    </div>
  </div>
);

const scrollToBottom = () => {
  const element = document.getElementById("sampleTimeline");

  if (!element) return;

  element.scrollTo(0, element.scrollHeight);
};

const isShowDistance = timelineInfo => {
  return (
    !skipDistanceCalculate.includes(
      `${timelineInfo.sectionFrom}-${timelineInfo.sectionTo}`
    ) &&
    !skipDistanceCalculate.includes(
      `${timelineInfo.sectionTo}-${timelineInfo.sectionFrom}`
    )
  );
};

const SampleTimeLine = ({
  data = [],
  target,
  loading,
  totalPoint,
  distance: distanceArr,
  timeLineHistory,
  runAgain,
  totalPointReal,
  dataLsAll,
}) => {
  let statistic = null;

  const rs = data.reduce((acc, val) => {
    if (val && val.data && val.data.area.type === area.TYPE.SECTION) {
      return [
        ...acc,
        {
          section: val.data.section,
          time: val.data.time,
          type: val.data.type,
          name: val.data.area.name,
        },
      ];
    }
    return acc;
  }, []);

  // Tính tổng
  if (rs.length && totalPoint && rs.length === totalPoint) {
    const validTimes = dataLsAll
      .filter(value => !isNaN(parseFloat(value)))
      .map(value => formatTime(parseFloat(value)));
    // Hàm chuyển đổi thời gian thành số giây
    function timeToSeconds(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }
    // Tính tổng các giá trị thời gian
    const totalSeconds = validTimes.reduce((accumulator, currentTime) => {
      return accumulator + timeToSeconds(currentTime);
    }, 0);
    const totalTimeAll = formatTime(totalSeconds);


    // const totalTimeAll = dataLsAll.reduce(
    //   (accumulator, currentValue) => accumulator + parseFloat(currentValue),
    //   0
    // );
    statistic = getStatistic(data, distanceArr, totalTimeAll);
  }

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  return (
    <CustomCard
      style={{
        height: "calc(100vh - 150px)",
        margin: "8px 0 15px",
        width: "100%",
        padding: "5px 10px",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <Typography.Title
          level={4}
          className="my-1 mx-1"
          style={{ color: "#1677ff", fontWeight: "bold" }}
        >
          Chi tiết tiến trình
        </Typography.Title>

        {distanceArr.length > 0 && (
          <Tooltip title="Chạy lại tiến trình">
            <Button
              type="primary"
              shape="circle"
              size="small"
              disabled={Boolean(target)}
              icon={<BiRefresh style={{ marginBottom: "3px" }} />}
              onClick={() => runAgain()}
            />
          </Tooltip>
        )}
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "calc(100vh - 340px)" }}
        >
          <Spin size="large" />
        </div>
      ) : timeLineHistory.length > 0 ? (
        <div
          id="sampleTimeline"
          style={{
            height: "calc(100vh - 196px)",
            padding: "10px",
            overflow: "auto",
            scrollBehavior: "smooth",
          }}
        >
          <Timeline
            mode="left"
            pending={getTargetName(target)}
            items={timeLineHistory.map(el => {
              // Thống kê quãng đường - thời gian qua từng vị trí
              if (el.isStatistic) {
                return {
                  color: "green",
                  children: (
                    <>
                      <div className="d-flex align-items-center mt-1">
                        <AiOutlineClockCircle />
                        <p
                          className="mb-0 ml-2"
                          style={{
                            fontSize: "0.9rem",
                            color: timeLineStatistic.totalTime.color,
                            fontWeight: "bold",
                          }}
                        >
                          {formatTime(dataLsAll[el.idx]) || "Chưa có giá trị"}
                        </p>
                      </div>
                      {distanceArr[el.idx] && isShowDistance(el) ? (
                        <div className="d-flex align-items-center mt-1">
                          <GiPathDistance />
                          <p
                            className="mb-0 ml-2"
                            style={{
                              fontSize: "0.9rem",
                              color: timeLineStatistic.totalDistance.color,
                              fontWeight: "bold",
                            }}
                          >
                            {distanceArr[el.idx].realDistance}
                          </p>
                        </div>
                      ) : null}
                    </>
                  ),
                };
              }

              // Hiễn thị thông tin của từng vị trí
              return {
                dot: <AiOutlineSend />,
                children: (
                  <div
                    style={{
                      boxShadow:
                        "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                      padding: "10px",
                    }}
                  >
                    <p
                      className="mb-2"
                      style={{ fontSize: ".9rem", fontWeight: "bold" }}
                    >
                      {el.data.area.name}
                    </p>
                  </div>
                ),
              };
            })}
          />

          {/* Thống kê sau khi hoàn thành tiến trình mẫu */}
          {statistic && (
            <>
              <StatisticItem
                title={timeLineStatistic.totalPosition.label}
                value={String(totalPointReal).padStart(2, "0")}
                color={timeLineStatistic.totalPosition.color}
                icon={timeLineStatistic.totalPosition.icon}
                bottom="170px"
              />
              <StatisticItem
                title={timeLineStatistic.totalTime.label}
                value={statistic.totalTime}
                color={timeLineStatistic.totalTime.color}
                icon={timeLineStatistic.totalTime.icon}
                bottom="85px"
              />
              <StatisticItem
                title={timeLineStatistic.totalDistance.label}
                value={statistic.totalDistance}
                color={timeLineStatistic.totalDistance.color}
                icon={timeLineStatistic.totalDistance.icon}
                bottom="0"
              />
            </>
          )}
        </div>
      ) : (
        <div
          className="mt-2 d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%" }}
        >
          <p className="mb-0 font-italic">
            Hãy chọn SID để theo dõi tiến trình mẫu
          </p>
        </div>
      )}
    </CustomCard>
  );
};

export default SampleTimeLine;
