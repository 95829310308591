import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData, getBlobFile, postBlobFile } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/imports`

const importMappingInstrument = (siteCode, formData) => { 
    var url = `${BASE_API_URL}/mapping-instrument?siteCode=${siteCode}`;   
    return postBlobFile(url, formData);
}
const importMappingTestCode = (siteCode, formData) => { 
    var url = `${BASE_API_URL}/mapping-testcode?siteCode=${siteCode}`;   
    return post(url, formData);
}

const importFileSQL = (formData) => { 
    var url = `${BASE_API_URL}/import-sql`;   
    return post(url, formData);
}
export {
    importMappingInstrument,
    importMappingTestCode,
    importFileSQL
}