import { useEffect, useRef, useState } from "react";
import ExportExcel from "./ExportExcel";
import SamplePathRoute from "./src/Main/";
import {
  AreaManager,
  ConfigManager,
  mappingActionLocationToArea,
} from "./src/utils";
import { area, configKey, wattageConfigDefault } from "./src/constants";
import { fetchAmountTestCodeInActionLocation } from "helpers/app-backend/samplepathbackend_helpers";

const Index = ({ sites }) => {
  // Chọn một máy khi click vào số lượng mẫu của máy đó
  const [machineSelected, setMachineSelected] = useState(null);
  const [wattageConfig, setWattageConfig] = useState(() => {
    const storeData = localStorage.getItem("wattageConfig");
    if (storeData) {
      return JSON.parse(storeData);
    }
    localStorage.setItem("wattageConfig", JSON.stringify(wattageConfigDefault));
    return wattageConfigDefault;
  });

  // Danh sách số lượng mẫu đang phân tích trên từng máy
  const [sampleNumberList, setSampleNumberList] = useState({});

  const imageSize = { width: 1150, height: 650 };
  AreaManager.getInstance().initAreas();
  const iConfig = ConfigManager.getInstance();
  iConfig.init();
  const areaList = area.getList();
  const intervalGetDataId = useRef(null);
  const [config, setConfig] = useState(iConfig.getConfig());

  const timeRef = useRef(null);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [filter, setFilter] = useState({
    site: null,
    fromDate: "2023-11-01",
    toDate: "2023-12-01",
  });

  const [exportFilter, setExportFilter] = useState({
    site: null,
  });
  // Mặc định lấy site đầu tiên trong danh sách sites
  useEffect(() => {
    if (!sites || !sites.length) return;
    setExportFilter(prev => ({ ...prev, site: sites[0] }));
    setError(null);
  }, [sites]);

  return (
    <div>
      <SamplePathRoute
        paging={paging}
        setPaging={setPaging}
        error={error}
        setError={setError}
        sites={sites}
        imageSize={imageSize}
        filter={filter}
        setFilter={setFilter}
        config={config}
        setConfig={setConfig}
        sampleNumberList={sampleNumberList}
        areaList={areaList}
        machineSelected={machineSelected}
        setMachineSelected={setMachineSelected}
        wattageConfig={wattageConfig}
        setWattageConfig={setWattageConfig}
      />
    </div>
  );
};

export default Index;
