import { useEffect, useRef, useState } from "react";
import Filter from "./filter";
import AverageTime from "./AverageTime";
import FollowingDate from "./FollowingDate";
import FollowingTime from "./FollowingTime";
import {
  fetchTATAutoSystemApi,
  fetchTATHourAutoSystemApi,
} from "helpers/app-backend/autosystembackend_helpers";
import { categoriesHour, initialCardData, nameListLabel } from "./constants";
import dayjs from "dayjs";
import { showToast } from "components/Common";
import { Label } from "reactstrap";
const Index = ({ sites }) => {
  const [error, setError] = useState(null);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 5,
  });
  const [filter, setFilter] = useState({
    site: null,
    fromDate: dayjs(),
    toDate: dayjs(),
  });
  const [cardData, setCardData] = useState(initialCardData);
  const [avgTimeData, setAvgTimeData] = useState(null);
  const [avgFlTimeData, setAvgFlTimeData] = useState(null);
  const [avgFlDateData, setAvgFlDateData] = useState(null);
  const [flDateMode, setFlDateMode] = useState("week");
  const [isLoading, setIsLoading] = useState(false);

  const getTATByHour = async params => {
    await GetLsStatisticByHour(params);
  };

  const getDataChartByTime = origindata => {
    let dataChart = {};
    const transformedDataMoment = nameListLabel.map(name => {
      const existingEntries = origindata.filter(entry => entry.label === name);

      const data = Array.from({ length: 24 }, (_, hour) => {
        const matchingEntry = existingEntries.find(
          entry => entry.hour === hour
        );
        return matchingEntry ? matchingEntry.value : 0;
      });
      return {
        name: name,
        data: data,
      };
    });

    dataChart.series = transformedDataMoment;
    dataChart.categories = categoriesHour;
    return dataChart;
  };

  const getDataChartByMoment = origindata => {
    let Difference_In_Time =
      new Date(filter.toDate).getTime() - new Date(filter.fromDate).getTime();

    // Calculating the no. of days between
    // two dates
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );

    let dataChart = {};
    const nameList = ["Ngoài giờ hành chính", "Buổi sáng", "Buổi chiều"];
    const transformedData = nameList.map(name => {
      const existingEntries = origindata.filter(
        entry => entry.label.includes(name) && entry.label.includes("Chạy hệ")
      );

      const data = Array.from({ length: 24 }, (_, hour) => {
        const sumForHour = existingEntries.reduce((sum, entry) => {
          return sum + (entry.hour === hour ? entry.value : 0);
        }, 0);

        return Math.round(sumForHour / (Difference_In_Days + 1));
      });

      return {
        name: name,
        data: data,
      };
    });
    dataChart.series = transformedData;
    dataChart.categories = categoriesHour;
    return dataChart;
  };

  const GetLsStatisticByHour = async params => {
    try {
      const responseTotal = await fetchTATHourAutoSystemApi(params);

      setAvgFlTimeData(null);
      setAvgFlDateData(null);
      if (responseTotal.data) {
        let dataChartByTime = getDataChartByTime(responseTotal.data);
        setAvgFlTimeData(dataChartByTime);
        let dataChartByMoment = getDataChartByMoment(responseTotal.data);
        setAvgFlDateData(dataChartByMoment);
      }
    } catch (e) {
      showToast(`Lỗi lấy dữ liệu ${e}`, 3);
    }
  };

  const GetDataCard = async params => {
    const responseTotal = await fetchTATAutoSystemApi(params);
    try {
      if (responseTotal.data) {
        let data = responseTotal.data;

        const transformedData = [
          "sampleLate",
          "sampleAlmostLate",
          "sampleOnTime",
        ]
          .map(id => ({
            id,
            title1: "Thời gian",
            title2:
              id === "sampleLate"
                ? "Trung bình - Không đạt TAT"
                : id === "sampleAlmostLate"
                ? "Cận TAT"
                : "Đạt TAT",
            value:
              id === "sampleLate"
                ? data.reduce((acc, entry) => acc + entry.sampleLate, 0)
                : id === "sampleAlmostLate"
                ? data.reduce((acc, entry) => acc + entry.sampleAlmostLate, 0)
                : id === "sampleOnTime"
                ? data.reduce((acc, entry) => acc + entry.sampleOnTime, 0)
                : null,
            background:
              id === "sampleLate"
                ? "linear-gradient(180deg, #00E396, #00bf7f)"
                : id === "sampleAlmostLate"
                ? "linear-gradient(180deg, #008FFB, #0279d4)"
                : id === "sampleOnTime"
                ? "linear-gradient(180deg, #FEB019, #eb7f05)"
                : "",
          }))
          .concat({
            id: "sampleTotal",
            title1: "Thời gian",
            title2: "Tổng số lượng mẫu trên hệ",
            value: data.reduce(
              (acc, entry) =>
                acc +
                entry.sampleLate +
                entry.sampleAlmostLate +
                entry.sampleOnTime,
              0
            ),
            background: "linear-gradient(180deg, #FF4560, #ff1c3d)",
          });

        // Merge the calculated values into initialCardData
        const mergedData = initialCardData.map(card => {
          const matchingTransformedData = transformedData.find(
            transformed => transformed.id === card.id
          );
          return { ...card, value: matchingTransformedData.value };
        });

        const sampleTotalValue = mergedData.find(
          entry => entry.id === "sampleTotal"
        ).value;
        const resultWithPercent = mergedData.map(entry => ({
          ...entry,
          percent:
            entry.value !== null
              ? Math.round((entry.value / sampleTotalValue) * 100)
              : null,
        }));
        setCardData(resultWithPercent);
      }
    } catch (e) {
      showToast(`Lỗi lấy dữ liệu ${e}`);
    }
  };

  const getData = () => {
    let params = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      siteCode: filter.site?.value,
    };

    setTimeout(() => {
      setCardData(initialCardData);
      setAvgFlDateData(null);
      setAvgFlTimeData(null);
      GetDataCard(params);
      //  Thời gian TAT trung bình từng bước trong quy trình theo khung giờ
      getTATByHour(params);
      setIsLoading(false)
    }, 1000);
    
  };

  useEffect(() => {
    if (!sites || !sites.length) return;
    setFilter(prev => ({
      ...prev,
      site: sites[0],
    }));
    setError(null);
  }, [sites]);

  return (
    <div>
      <Filter
        setError={setError}
        setPaging={setPaging}
        error={error}
        sites={sites}
        xs={24}
        filter={filter}
        setFilter={setFilter}
        onSearch={getData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      {cardData && <AverageTime cardData={cardData} isLoading={isLoading}/>}
      {avgFlDateData && <FollowingDate avgFlTimeData={avgFlDateData} unit={"test"} isLoading={isLoading}/>}
      {avgFlTimeData && <FollowingTime avgFlTimeData={avgFlTimeData} unit={"test"} isLoading={isLoading}/>}
     
    </div>
  );
};

export default Index;
