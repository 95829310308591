import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

import {
  insertSpaces,
  isRequiredError,
  convertDateFormat,
  getTypeByDelimiter,
  replaceId,
} from "helpers/utilities"
import { textFieldTypes, parameterCode } from "constant/utility"
import {
  CustomAvField,
  CustomSelect,
  CustomSelectAsync,
  CustomCheckbox,
  NumberInput,
  CustomDatePicker,
} from "."

//i18n
import { withTranslation } from "react-i18next"
import { Accordion, AccordionBody, AccordionWrapper, TextField } from ".."

const DynamicForm = ({ items, isEdit, t, resource, isView }) => {
  const [rawItems, setRawItems] = useState([])
  let parentId = 0,
    renderedIds = []

  const itemsHandler = items => {
    const newItems = JSON.parse(JSON.stringify(items))
    newItems.map(_item => {
      if (_item.type === textFieldTypes.GROUP) parentId = _item.id
      else {
        _item.parent = parentId
      }

      return _item
    })

    setRawItems(newItems)
  }

  useEffect(() => {
    itemsHandler(items)
  }, [items])

  const renderSelect = ({
    name,
    value,
    code,
    require,
    label,
    readonly,
    isMulti,
  }) => (
    <CustomSelect
      isMulti={isMulti}
      name={name}
      value={value}
      code={code}
      required={require}
      label={label}
      readOnly={readonly}
      detected={isEdit}
    />
  )

  const editItemHandler = ({ item, index, items }) => {
    const {
      id,
      fieldCode: name,
      fieldName,
      type,
      parameterKey,
      require,
      readonly,
      format,
      initialValue,
      tooltip,
    } = item
    let key = item.name + index || index

    let input = "",
      group = "",
      code = parameterKey,
      fieldType = getTypeByDelimiter(type)

    let value = item.value || initialValue || ""
    let valueName = item[replaceId(name)] || ""
    // const displayName = insertSpaces(name)
    const displayName = fieldName

    switch (fieldType) {
      case textFieldTypes.GROUP:
        group = (
          <AccordionWrapper defaultTab="1" className="px-2">
            <Accordion tabId={"1"} title={displayName}>
              <AccordionBody>
                <Row className="px-2">
                  {renderItem(
                    rawItems.filter(_subItem => _subItem.parent === id)
                  )}
                </Row>
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>
        )
        break
      case textFieldTypes.USER:
        code = parameterCode.USERS
        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            code={code}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.DEPARTMENT:
        code = parameterCode.DEPARTMENTS
        const company = items[index - 1]
        let isDependent = false,
          companyId

        if (company?.fieldCode === "ManagementCompanyId") {
          isDependent = true
          companyId = company.value
        }

        // console.log(company, company?.fieldCode)
        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            code={code}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
            isDependent={isDependent}
            group={companyId}
          />
        )

        break
      case textFieldTypes.COMPANY:
        code = parameterCode.COMPANIES
        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            code={code}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.PROFILE: // patient || organization || physician
        code = type

        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            code={code}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.MULTIPLE:
      case textFieldTypes.SINGLE:
        input = renderSelect({
          name,
          value,
          code,
          require,
          label: displayName,
          readonly,
          isMulti: fieldType === textFieldTypes.MULTIPLE,
        })
        break
      case textFieldTypes.TEXTAREA:
        input = (
          <>
            <CustomAvField
              name={name}
              type="textarea"
              rows="3"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={isEdit}
            />
          </>
        )
        break
      case textFieldTypes.CHECKBOX:
        input = (
          <CustomCheckbox
            type="checkbox"
            direction={"down"}
            name={name}
            value={!!value}
            label={displayName}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.NUMBER:
        input = (
          <NumberInput
            name={name}
            label={displayName}
            required={require}
            value={value}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.DATE:
        input = (
          <CustomDatePicker
            name={name}
            label={displayName}
            required={require}
            placeholder={format}
            format={format}
            value={value}
            readOnly={readonly}
            detected={isEdit}
          />
        )

        break
      case textFieldTypes.TEXT:
        input = (
          <>
            <CustomAvField
              name={name}
              type="text"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={isEdit}
            />
          </>
        )
        break
      default:
        break
    }

    return (
      <React.Fragment key={key}>
        {!input && group}
        {input && (
          <Col sm="6" className="px-3">
            <div className="mb-3">{input}</div>
          </Col>
        )}
      </React.Fragment>
    )
  }

  const viewItemHandler = ({ item, index }) => {
    const { id, fieldCode: name, fieldName, type, initialValue } = item
    let key = item.name + index || index

    let input = "",
      group = "",
      fieldType = getTypeByDelimiter(type)

    let value = item.value || initialValue || ""
    let valueName = value
    const displayName = fieldName

    switch (fieldType) {
      case textFieldTypes.GROUP:
        group = (
          <AccordionWrapper defaultTab="1" className="px-2">
            <Accordion tabId={"1"} title={displayName}>
              <AccordionBody>
                <Row className="px-2">
                  {renderItem(
                    rawItems.filter(_subItem => _subItem.parent === id)
                  )}
                </Row>
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>
        )
        break
      case textFieldTypes.USER:
      case textFieldTypes.DEPARTMENT:
      case textFieldTypes.COMPANY:
      case textFieldTypes.PROFILE: // patient || organization || physician
      case textFieldTypes.MULTIPLE:
      case textFieldTypes.SINGLE:
        valueName = item[replaceId(name, true)] || value
        // console.log(valueName, replaceId(name, true), item)
        input = (
          <TextField
            label={t(displayName)}
            textField={valueName}
            resource={resource}
          />
        )
        break
      case textFieldTypes.TEXTAREA:
      case textFieldTypes.NUMBER:
      case textFieldTypes.TEXT:
        input = (
          <TextField
            label={t(displayName)}
            textField={valueName}
            resource={resource}
          />
        )
        break
      case textFieldTypes.CHECKBOX:
        input = (
          <TextField
            label={t(displayName)}
            checked={!!value}
            type={type}
            resource={resource}
          />
        )
        break
      case textFieldTypes.DATE:
        input = (
          <TextField
            label={t(displayName)}
            textField={convertDateFormat(value, "YYYY-MM-DD")}
            resource={resource}
          />
        )
        break
      default:
        break
    }

    return (
      <React.Fragment key={key}>
        {!input && group}
        {input && (
          <Col sm="6" className="px-3">
            <div className="mb-3">{input}</div>
          </Col>
        )}
      </React.Fragment>
    )
  }
  const renderItem = items => {
    let formUI = items.map((item, index) => {
      const { id } = item

      const exists = renderedIds.indexOf(id) >= 0
      if (exists) return true

      renderedIds.push(id)
      if (isView) return viewItemHandler({ item, index })
      return editItemHandler({ item, index, items })
    })
    return formUI
  }

  return <Row>{renderItem(rawItems)}</Row>
}

DynamicForm.propTypes = {
  items: PropTypes.array.isRequired,
  t: PropTypes.func,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool.isRequired,
  resource: PropTypes.string,
}

DynamicForm.defaultProps = {
  items: [],
  isView: false,
}

export default withTranslation(["common"])(DynamicForm)
