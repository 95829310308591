import React, { useEffect, useState } from "react";

import { ModalBody, Row, Col, ModalFooter, Label } from "reactstrap";
import * as moment from "moment";
import {
  CustomSelect,
  CustomModal,
  CustomButton,
  CustomAvField,
} from "components/Common";
import { AvForm } from "availity-reactstrap-validation";
import Switch from "react-switch";
import { withTranslation } from "react-i18next";
import { getInvalidMessageI18n } from "helpers/utilities";
import toastr from "toastr";
import {
  createTargetPAKD,
  updateTargetPAKDById,
} from "helpers/app-backend/targetpakdbackend_helper";
import Select from "react-select";

export const convertDateFormat = (date, format) => {
  if (!date) return date;
  const date1 = moment(new Date(date)).format(format || "YYYY-MM-DD HH:mm");
  return moment(new Date(date1)).isValid() ? date1 : date;
};

const UsersModal = ({
  isAdmin,
  modal,
  toggle,
  setDataTarget,
  t,
  customerOptions,
  setModal,
  dataTarget,
  isAction,
  onGetListTargetPAKD,
}) => {
  const title =
    isAction === "add"
      ? `${t("Thêm mới")} ${t("target PAKD")}`
      : `Cập nhật ${t("target PAKD")}`;

  const [linhVucOptions, setLinhVucOptions] = useState([]);
  const [phanPhoiOptions, setPhanPhoiOptions] = useState([]);
  const [selectMaKH, setSelectMaKH] = useState([]);
  const [selectMaHPP, setSelectMaHPP] = useState([]);
  const [selectMaLV, setSelectMaLV] = useState([]);

  const onCustomerChange = selectedCustomer => {
    const { groupProducts } = selectedCustomer;
    const linhVucSet = new Set();
    const phanPhoiSet = new Set();
    groupProducts.forEach(item => {
      linhVucSet.add(item.maLinhVuc);
      phanPhoiSet.add(item.maNhomHangPhanPhoi);
    });
    const linhVucOptions = Array.from(linhVucSet).map(value => ({
      value,
      label: value,
    }));
    const phanPhoiOptions = Array.from(phanPhoiSet).map(value => ({
      value,
      label: value,
    }));
    setDataTarget(prev => ({
      ...prev,
      MaHPP: "",
      MaLV: "",
    }));
    setLinhVucOptions(linhVucOptions);
    setPhanPhoiOptions(phanPhoiOptions);
  };
  const onSubmitData = () => {
    if (isAction === "add") {
      // Kiểm tra các trường bắt buộc
      if (!dataTarget.MaKH) {
        toastr.error("Vui lòng chọn khách hàng");
        return;
      }
      if (!dataTarget.MaHPP) {
        toastr.error("Vui lòng chọn mã hàng phân phối");
        return;
      }
      if (!dataTarget.MaLV) {
        toastr.error("Vui lòng chọn mã lĩnh vực");
        return;
      }
      for (let i = 1; i <= 12; i++) {
        const monthKey = `thang${i}`;
        if (!dataTarget[monthKey]) {
          toastr.error(`Bạn chưa nhập tháng ${i}`);
          return;
        }
      }
      const currentYear = new Date().getFullYear();
      const dataCallApi = {
        MaKH: dataTarget.MaKH?.value,
        MaHPP: dataTarget.MaHPP?.value,
        MaLV: dataTarget.MaLV?.value,
        nam: currentYear.toString(),
        thang1: dataTarget.thang1,
        thang2: dataTarget.thang2,
        thang3: dataTarget.thang3,
        thang4: dataTarget.thang4,
        thang5: dataTarget.thang5,
        thang6: dataTarget.thang6,
        thang7: dataTarget.thang7,
        thang8: dataTarget.thang8,
        thang9: dataTarget.thang9,
        thang10: dataTarget.thang10,
        thang11: dataTarget.thang11,
        thang12: dataTarget.thang12,
      };

      createTargetPAKD(dataCallApi)
        .then(() => {
          toastr.success("Thêm mới thành công");
          onGetListTargetPAKD();
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.title
          ) {
            const errorMessage = error.response.data.title;
            toastr.error(errorMessage);
          } else {
            toastr.error("An error occurred.");
          }
        })
        .finally(() => {
          setModal(false);
          setDataTarget({
            MaKH: "",
            MaHPP: "",
            MaLV: "",
            thang1: "",
            thang2: "",
            thang3: "",
            thang4: "",
            thang5: "",
            thang6: "",
            thang7: "",
            thang8: "",
            thang9: "",
            thang10: "",
            thang11: "",
            thang12: "",
          });
        });
    } else {
      // Kiểm tra các trường bắt buộc
      if (!dataTarget.MaKH) {
        toastr.error("Vui lòng chọn khách hàng");
        return;
      }
      if (!dataTarget.MaHPP) {
        toastr.error("Vui lòng chọn mã hàng phân phối");
        return;
      }
      if (!dataTarget.MaLV) {
        toastr.error("Vui lòng chọn mã lĩnh vực");
        return;
      }
      for (let i = 1; i <= 12; i++) {
        const monthKey = `thang${i}`;
        if (!dataTarget[monthKey]) {
          toastr.error(`Bạn chưa nhập tháng ${i}`);
          return;
        }
      }

      const currentYear = new Date().getFullYear();
      const dataCallApi = {
        Id: dataTarget.id,
        MaKH: dataTarget.MaKH,
        MaHPP: dataTarget.MaHPP,
        MaLV: dataTarget.MaLV,
        nam: currentYear.toString(),
        thang1: dataTarget.thang1,
        thang2: dataTarget.thang2,
        thang3: dataTarget.thang3,
        thang4: dataTarget.thang4,
        thang5: dataTarget.thang5,
        thang6: dataTarget.thang6,
        thang7: dataTarget.thang7,
        thang8: dataTarget.thang8,
        thang9: dataTarget.thang9,
        thang10: dataTarget.thang10,
        thang11: dataTarget.thang11,
        thang12: dataTarget.thang12,
      };
      updateTargetPAKDById(dataCallApi)
        .then(() => {
          toastr.success("Cập nhật thành công");
          onGetListTargetPAKD();
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.title
          ) {
            const errorMessage = error.response.data.title;
            toastr.error(errorMessage);
          } else {
            toastr.error("An error occurred.");
          }
        })
        .finally(() => {
          setModal(false);
          setDataTarget({
            MaKH: "",
            MaHPP: "",
            MaLV: "",
            thang1: "",
            thang2: "",
            thang3: "",
            thang4: "",
            thang5: "",
            thang6: "",
            thang7: "",
            thang8: "",
            thang9: "",
            thang10: "",
            thang11: "",
            thang12: "",
          });
        });
    }
  };

  useEffect(() => {
    if (isAction === "edit") {
      const MaKH = dataTarget.MaKH;
      const MaHPP = dataTarget.MaHPP;
      const MaLV = dataTarget.MaLV;
      const selectMaKH1 = customerOptions.filter(item => item.value === MaKH);
      const { groupProducts } = selectMaKH1[0];
      const linhVucSet = new Set();
      const phanPhoiSet = new Set();
      groupProducts.forEach(item => {
        linhVucSet.add(item.maLinhVuc);
        phanPhoiSet.add(item.maNhomHangPhanPhoi);
      });
      const linhVucOptions = Array.from(linhVucSet).map(value => ({
        value,
        label: value,
      }));
      const phanPhoiOptions = Array.from(phanPhoiSet).map(value => ({
        value,
        label: value,
      }));

      const selectMaHPP1 = phanPhoiOptions.filter(item => item.value === MaHPP);
      const selectMaLV1 = linhVucOptions.filter(item => item.value === MaLV);
      setSelectMaKH(selectMaKH1);
      setSelectMaHPP(selectMaHPP1);
      setSelectMaLV(selectMaLV1);
    }
  }, [isAction, modal]);

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle} size="xl">
      <ModalBody>
        <AvForm id="userForm">
          <Row>
            <Col xs="4">
              <div className="mb-3">
                <div className="label-group-relative position-relative">
                  <Label>
                    {t("Khách hàng")}
                    {<span className="text-danger">*</span>}
                  </Label>
                </div>
                <Select
                  className="custom-select"
                  options={customerOptions}
                  placeholder="Chọn khách hàng"
                  value={isAction === "edit" ? selectMaKH : dataTarget.MaKH}
                  onChange={val => {
                    setDataTarget(prev => ({
                      ...prev,
                      MaKH: val,
                    }));
                    onCustomerChange(val);
                  }}
                  isDisabled={isAction === "edit" ? true : false}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <div className="label-group-relative position-relative">
                  <Label>
                    {t("Mã hàng phân phối")}
                    {<span className="text-danger">*</span>}
                  </Label>
                </div>
                <Select
                  className="custom-select"
                  options={phanPhoiOptions}
                  placeholder="Chọn mã phân phối"
                  value={isAction === "edit" ? selectMaHPP : dataTarget.MaHPP}
                  onChange={val => {
                    setDataTarget(prev => ({
                      ...prev,
                      MaHPP: val,
                    }));
                  }}
                  isDisabled={isAction === "edit" ? true : false}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <div className="label-group-relative position-relative">
                  <Label>
                    {t("Mã lĩnh vực")}
                    {<span className="text-danger">*</span>}
                  </Label>
                </div>
                <Select
                  className="custom-select"
                  options={linhVucOptions}
                  placeholder="Chọn mã lĩnh vực"
                  value={isAction === "edit" ? selectMaLV : dataTarget.MaLV}
                  onChange={val => {
                    setDataTarget(prev => ({
                      ...prev,
                      MaLV: val,
                    }));
                  }}
                  isDisabled={isAction === "edit" ? true : false}
                />
              </div>
            </Col>

            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang1"
                  type="number"
                  value={dataTarget.thang1 || dataTarget.thang1 || ""}
                  label={t("Tháng 1")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 1")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang1: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang1: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang2"
                  type="number"
                  value={dataTarget.thang2 || dataTarget.thang2 || ""}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 2")}
                  required={true}
                  label={t("Tháng 2")}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang2: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang2: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang3"
                  type="number"
                  value={dataTarget.thang3 || dataTarget.thang3 || ""}
                  label={t("Tháng 3")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 3")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang3: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang3: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang4"
                  type="number"
                  value={dataTarget.thang4 || dataTarget.thang4 || ""}
                  label={t("Tháng 4")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 4")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang4: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang4: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang5"
                  type="number"
                  value={dataTarget.thang5 || dataTarget.thang5 || ""}
                  label={t("Tháng 5")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 5")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang5: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang5: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang6"
                  type="number"
                  value={dataTarget.thang6 || dataTarget.thang6 || ""}
                  label={t("Tháng 6")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 6")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang6: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang6: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang7"
                  type="number"
                  value={dataTarget.thang7 || dataTarget.thang7 || ""}
                  label={t("Tháng 7")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 7")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang7: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang7: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang8"
                  type="number"
                  value={dataTarget.thang8 || dataTarget.thang8 || ""}
                  label={t("Tháng 8")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 8")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang8: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang8: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang9"
                  type="number"
                  value={dataTarget.thang9 || dataTarget.thang9 || ""}
                  label={t("Tháng 9")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 9")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang9: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang9: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang10"
                  type="number"
                  value={dataTarget.thang10 || dataTarget.thang10 || ""}
                  label={t("Tháng 10")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 10")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang10: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang10: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang11"
                  type="number"
                  value={dataTarget.thang11 || dataTarget.thang11 || ""}
                  label={t("Tháng 11")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 11")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang11: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang11: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="thang12"
                  type="number"
                  value={dataTarget.thang12 || dataTarget.thang12 || ""}
                  label={t("Tháng 12")}
                  errorMessage={getInvalidMessageI18n(t, "Tháng 12")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataTarget(prevDataTarget => ({
                        ...prevDataTarget,
                        thang12: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataTarget(prevDataTarget => ({
                      ...prevDataTarget,
                      thang12: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("Lưu")}
          type="submit"
          color="primary"
          onClick={() => {
            onSubmitData(dataTarget);
          }}
          className="save-user"
        />
        <CustomButton
          text={t("Hủy")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          color="danger"
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["userPage", "common"])(UsersModal);
