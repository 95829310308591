import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/auth`;

const loginAccount = reqBody => {
  return post(`${BASE_API_URL}/login`, reqBody);
};

export { loginAccount };
