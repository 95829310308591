import React, { useState, useMemo } from "react";

import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import { CustomButton, showToast } from "components/Common";
import UploadFile from "components/Common/UploadFile";
import DownloadFile from "components/Common/DownloadFile";
import dayjs from "dayjs";
import {
  ExportStatisticsSurveyResult,
  ImportStatisticsSurveyResult,
} from "../../../helpers/app-backend/surveyresultbackend_helper";

const { RangePicker } = DatePicker;

const Index = ({ dataFilter, setDataFilter, onChangeSubmitData, paging }) => {
  const [isUpload, setIsUpload] = useState(false);
  const [isExportFile, setIsExportFile] = useState(false);
  const handleInputChange = (field, value) => {
    setDataFilter(prev => ({ ...prev, [field]: value }));
  };
  const getExportApi = () => {
    return ExportStatisticsSurveyResult;
  };

  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">SiteName</Label>
            <div style={{ zIndex: 4 }}>
              <Input
                className="position-relative"
                placeholder="SideName"
                value={dataFilter.siteName}
                onChange={e => handleInputChange("siteName", e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">CategoryName</Label>
            <div style={{ zIndex: 4 }}>
              <Input
                className="position-relative"
                placeholder="CategoryName"
                value={dataFilter.masterCategoryName}
                onChange={e =>
                  handleInputChange("masterCategoryName", e.target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">ProducerCode</Label>
            <div style={{ zIndex: 4 }}>
              <Input
                className="position-relative"
                placeholder="ProducerCode"
                value={dataFilter.masterProducerCode}
                onChange={e =>
                  handleInputChange("masterProducerCode", e.target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3}>
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">CompanyName</Label>
            <div style={{ zIndex: 4 }}>
              <Input
                className="position-relative"
                placeholder="CompanyName"
                value={dataFilter.companyName}
                onChange={e => handleInputChange("companyName", e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3} className="mt-2">
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">InstrumentName</Label>
            <div style={{ zIndex: 4 }}>
              <Input
                className="position-relative"
                placeholder="InstrumentName"
                value={dataFilter.masterInstrumentName}
                onChange={e =>
                  handleInputChange("masterInstrumentName", e.target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xl={3} className="mt-2">
          <div className="d-flex flex-column">
            <Label className="mb-1 text-truncate">StatYear</Label>
            <div style={{ zIndex: 4 }}>
              <RangePicker
                picker="year"
                style={{ width: "100%", paddingBottom: 6, paddingTop: 6 }}
                placeholder={["From Year", "To Year"]}
                size="large"
                value={
                  dataFilter.fromYear && dataFilter.toYear
                    ? [
                        dayjs(dataFilter.fromYear, "YYYY"),
                        dayjs(dataFilter.toYear, "YYYY"),
                      ]
                    : null
                }
                onChange={(dates, dateStrings) => {
                  handleInputChange("fromYear", dateStrings[0]);
                  handleInputChange("toYear", dateStrings[1]);
                }}
              />
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} xl={6} style={{ marginTop: 30 }}>
          <CustomButton
            color="primary"
            className="mx-2"
            onClick={() => onChangeSubmitData()}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-search" style={{ fontSize: "1rem" }}></i>
              <p className="mb-0 mx-1">{"Tìm kiếm"}</p>
            </div>
          </CustomButton>
          <CustomButton
            color="success"
            className="mx-2"
            onClick={e => {
              setIsUpload(true);
            }}
          >
            <div className="d-flex align-items-center">
              <i
                className="bx bx-cloud-upload"
                style={{ fontSize: "1rem" }}
              ></i>
              <p className="mb-0 mx-1">{"Upload file"}</p>
            </div>
          </CustomButton>
          <CustomButton
            color="success"
            className="mx-2"
            onClick={() => setIsExportFile(true)}
          >
            <div className="d-flex align-items-center">
              <i
                className="bx bx-cloud-download"
                style={{ fontSize: "1rem" }}
              ></i>
              <p className="mb-0 mx-1">Xuất Excel</p>
            </div>
          </CustomButton>
        </Col>
      </Row>
      {isUpload && (
        <UploadFile
          isOpen={isUpload}
          fileSupport={"*.sql"}
          title={<span>Vui lòng chọn file cần import</span>}
          onClose={() => setIsUpload(false)}
          onStartUploadingFile={async formData => {
            try {
              const res = await ImportStatisticsSurveyResult(formData);
              if (res?.error) {
                showToast(`${res.error.title}: ${res.error.description}`, 3);
                setIsUpload(false);
                onChangeSubmitData();
                return;
              }
              onChangeSubmitData();
              showToast(`Upload file thành công`, 1);
              setIsUpload(false);
            } catch (error) {
              console.error(error);
              showToast(
                `Có lỗi xảy ra: ${error.message || "Unknown error"}`,
                3
              );
              setIsUpload(false);
              onChangeSubmitData();
            }
          }}
        />
      )}

      {isExportFile && (
        <DownloadFile
          isOpen={isExportFile}
          isUseFilter
          request={{
            siteName: dataFilter.siteName,
            masterCategoryName: dataFilter.masterCategoryName,
            masterProducerCode: dataFilter.masterProducerCode,
            companyName: dataFilter.companyName,
            masterInstrumentName: dataFilter.masterInstrumentName,
            fromYear: dataFilter.fromYear,
            toYear: dataFilter.toYear,
            page: paging.currentPage,
            pageSize: paging.pageSize,
          }}
          serviceApi={getExportApi()}
          onClose={() => setIsExportFile(false)}
          fileName={`Danh sách thống kê dữ liệu khảo sát`}
        />
      )}
    </Card>
  );
};

export default Index;
