import React, { useEffect, useState } from "react"
import { PageContent, CustomBreadcrumb } from "components/Common";

import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MapTestCode from "./MapTestCode";
import SiteService from 'services/Site';
const RESOURCE = ModuleIds.MasterInstrument;

const MapTestcodeContainer = ({ t }) => {
    const titleTag = t("Khớp mã TestCode");
    const [sites, setSites] = useState([]);
    const getData = async () => {
        const res = await SiteService.getLabelAndValueSite({
            page: 1,
            size: 1000,
            search: "",
        });
        setSites(res.data)
    }
    useEffect(() => {  
        if(sites.length > 0)  return;
        getData();
    }, [sites]);
    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={titleTag}
                resource={RESOURCE}               
                icon={<i className="bx bx-file" />}
            />
            {/* Page Body */}
            <MapTestCode sites={sites}/>          
        </PageContent >
    )
}

MapTestcodeContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["mapTestCodePage"])(MapTestcodeContainer)