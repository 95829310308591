import React, { useEffect, useState } from "react";
import { PageContent, CustomBreadcrumb } from "components/Common";

import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MasterInstrument from "./MasterInstrument";
import MasterInstrumentType from "services/MasterInstrumentType";
const RESOURCE = ModuleIds.MasterInstrument;

const MasterInstrumentContainer = ({ t }) => {
  const titleTag = t("Danh sách Master Instrument");
  const [masterInstrumentType, setMasterInstrumentType] = useState([]);
  const getData = async () => {
    const res = await MasterInstrumentType.GetLisLabelValueMasterInstrumentService();
    setMasterInstrumentType(res);
  };
  useEffect(() => {
    if (masterInstrumentType.length > 0) return;
    getData();
  }, []);
  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb
        breadcrumbItem={titleTag}
        resource={RESOURCE}
        icon={<i className="bx bx-file" />}
      />
      {/* Page Body */}
      <MasterInstrument masterInstrumentType={masterInstrumentType}/>
    </PageContent>
  );
};

MasterInstrumentContainer.propTypes = {
  t: PropTypes.any,
};

export default withTranslation(["mapInstrumentPage"])(
  MasterInstrumentContainer
);
