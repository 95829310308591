import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import HeaderButtons from "./HeaderButtons"

const Header = ({
  title,
  subtitle,
  iconClass,
  buttons,
  customButtons,
  onEdit,
  onDelete,
  onClone,
  resource,
  userPermissions,
  external,
}) => {
  const [displayInfo, setDisplayInfo] = useState({ icon: "", color: "" })

  useEffect(() => {
    if (userPermissions && resource && iconClass) {
      const _permission = userPermissions[resource] || {}
      const { icon, color } = _permission
      setDisplayInfo({ icon, color })
    }
  }, [userPermissions])

  return (
    <div className="d-flex flex-wrap">
      {/* {iconClass && (
        <div className="me-2 mini-stats-wid align-self-center">
          <div className="avatar-xs rounded bg-warning mini-stat-icon">
            <span
              className="avatar-title rounded"
              style={{ backgroundColor: displayInfo.color }}
            >
              <i
                className={`${displayInfo.icon} ${typeof iconClass === "string" ? iconClass : ""
                  } font-size-24`}
              ></i>
            </span>
          </div>
        </div>
      )}
      <div className="me-1">
        {title && <p className="mb-0 card-header-title">{title}</p>}
        {subtitle && <p className="mb-0 card-header-subtitle">{subtitle}</p>}
      </div> */}
      {/* Buttons Header */}
      {buttons && (
        <HeaderButtons
          onEdit={onEdit}
          onDelete={onDelete}
          resource={resource}
          external={external}
        >
          {typeof buttons === "function" && buttons()}
        </HeaderButtons>
      )}
      {customButtons && (

        <HeaderButtons
          onEdit={onEdit}
          onDelete={onDelete}
          resource={resource}
          external={external}
        >
          {typeof customButtons === "function" && customButtons()}
        </HeaderButtons>
      )}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconClass: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buttons: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  customButtons: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  resource: PropTypes.string,
  external: PropTypes.bool,
}

Header.defaultProps = {
  //title: "title",
  // subtitle: "subtitle",
  // iconClass: "mdi mdi-notebook-edit-outline",
  iconClass: true,
}
const mapStateToProps = ({ Authorization }) => ({
  userPermissions: Authorization.permissions,
})

export default connect(mapStateToProps)(Header)
