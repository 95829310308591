import React from "react"
import PropTypes from "prop-types"

const LabelAndValue = ({ label, value, className }) => {
  return (
    <div>
      <p className="text-muted text-truncate mb-2 font-size-12">{label}</p>
      <h6 className={`mb-0 font-size-14 ${className}`}>{value}</h6>
    </div>
  )
}

LabelAndValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
}

LabelAndValue.defaultProps = {
  label: "label",
  value: "",
  className: "",
}

export default LabelAndValue
