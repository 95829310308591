import Select from "react-select";
import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import { useEffect, useState } from "react";
import SiteService from "services/Site";
import { DISPLAY_MONTH_YEAR_FORMAT } from "constant";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomButton } from "components/Common";
import dayjs from "dayjs";
import { GetDataTotalStatistics } from "../../../helpers/app-backend/statisticbackend_helpers.js";
import toastr from "toastr";
import { startOfMonth, format } from "date-fns";

const Index = ({
  sites,
  error,
  setError,
  isLoading,
  setPaging,
  filter,
  setFilter,
  selectValue,
  setSelectValue,
  setMonthOfYear,
  setYears,
  setDataSoucre,
  setPage,
  exportExcelFunc,
}) => {
  const HandleSubmitSite = val => {
    let filterAll = val.filter(x => x.value == "all");
    if (filterAll.length > 0) {
      let resfilter = sites.filter(item => {
        if (item.value != "all") return item.value;
      });
      setSelectValue(filterAll);
      setFilter(prev => ({
        ...prev,
        siteCode: resfilter.map(x => x.value),
      }));
    } else {
      setSelectValue(val);
      setFilter(prev => ({
        ...prev,
        siteCode: val.map(item => item.value),
      }));
    }

    setPaging(prev => ({ ...prev, currentPage: 1 }));
  };

  const handleDateChange = (date, type) => {
    if (date) {
      const formattedDate = new Date(date.getFullYear(), date.getMonth());
      setFilter(prev => ({
        ...prev,
        [type]: formattedDate,
      }));
    } else {
      setFilter(prev => ({
        ...prev,
        [type]: new Date("0001-01-01"),
      }));
    }
    if (error) {
      setError(null);
    }
  };

  const handleGetData = async () => {
    //
    const res = await GetDataTotalStatistics({
      siteCode: filter.siteCode,
      fromDate: format(startOfMonth(filter.dateFrom), "yyyy-MM-dd"),
      toDate: format(startOfMonth(filter.dateTo), "yyyy-MM-dd"),
    })
      .then(res => {
        toastr.success("Lấy dữ liệu thành công");
        setDataSoucre(res);
        setPage(1);
      })
      .catch(error => {
        toastr.error(error);
      })
      .finally(() => {
        const startYear = new Date(filter.dateFrom).getFullYear();
        const endYear = new Date(filter.dateTo).getFullYear();
        const yearArray = [];
        for (let year = startYear; year <= endYear; year++) {
          yearArray.push(year);
        }
        setYears(yearArray);

        const monthYearArray = [];
        yearArray.forEach(year => {
          for (let month = 0; month < 12; month++) {
            const monthString = (month + 1).toString().padStart(2, "0");
            const date = new Date(year, month, 1);
            if (
              date.getTime() >= new Date(filter.dateFrom).getTime() &&
              date.getTime() <= new Date(filter.dateTo).getTime()
            ) {
              monthYearArray.push({ month: monthString, year });
            }
          }
        });
        setMonthOfYear(monthYearArray);
      });
  };

  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={4} xl={3}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">SiteName</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 4 }}>
              <Select
                className="custom-select"
                isMulti
                options={sites}
                placeholder="Chọn bệnh viện"
                value={selectValue}
                onChange={val => {
                  HandleSubmitSite(val);
                }}
                isDisabled={false}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>
        <Col sm={12} md={3}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">Thời gian</Label>
            <div style={{ width: "100px", zIndex: 5 }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={
                  filter.dateFrom.getFullYear() == 1 ? "" : filter.dateFrom
                }
                onChange={date => handleDateChange(date, "dateFrom")}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                disabled={isLoading}
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
            </div>

            <i className="bx bxs-right-arrow"></i>
            <div style={{ width: "100px", zIndex: 5 }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={filter.dateTo.getFullYear() == 1 ? "" : filter.dateTo}
                onChange={date => handleDateChange(date, "dateTo")}
                disabled={isLoading}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
            </div>
          </div>
          {error && error.date ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.date}
            </p>
          ) : null}
        </Col>
        <Col sm={12} md={3}>
          <CustomButton color="success" onClick={handleGetData}>
            <div className="d-flex align-items-center">
              <i className="bx bx-search"></i>{" "}
              <p className="mb-0 mx-1">{"Tìm kiếm"}</p>
            </div>
          </CustomButton>
          <CustomButton
            color="success"
            onClick={() => {
              exportExcelFunc();
              message.success("Xuất file thành công");
            }}
            style={{marginLeft:"10px"}}
          >
            <div className="d-flex align-items-center">
              <i
                className="bx bx-cloud-download"
                style={{ fontSize: "1rem" }}
              ></i>
              <p className="mb-0 mx-1">Xuất Excel</p>
            </div>
          </CustomButton>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
