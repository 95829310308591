
import { get, put, post, del, delData, getBlobFile } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/reports`

const ExportReportDataDatabase = () => {   
    var url = `${BASE_API_URL}/data-database`;   
    return getBlobFile(url);
}

const ExportReportDataInstrument = () => { 
    var url = `${BASE_API_URL}/data-instrument`;   
    return getBlobFile(url);
}

export {
    ExportReportDataDatabase,
    ExportReportDataInstrument,    
}