import {
  ADD_SITE,
  ADD_SITE_FAIL,
  ADD_SITE_SUCCESS,
  DELETE_SITE,
  DELETE_SITE_FAIL,
  DELETE_SITE_SUCCESS,
  GET_SITES,
  GET_SITES_FAIL,
  GET_SITES_SUCCESS,
  GET_SITE_DETAIL,
  GET_SITE_DETAIL_FAIL,
  GET_SITE_DETAIL_SUCCESS,
  RESET_SITE_SEARCH_QUERY,
  SET_SITE_SEARCH_QUERY,
  UPDATE_SITE,
  UPDATE_SITE_FAIL,
  UPDATE_SITE_SUCCESS,
  SYNC_SITE,
  SYNC_SITE_SUCCESS,
  SYNC_SITE_FAIL,
  EXPORT_MAP_TEST_CODE,
  EXPORT_MAP_TEST_CODE_FAIL,
  SYNC_DATABASE,
  SYNC_DATABASE_FAIL,
  COMBINE_STATMONTHPATIENT,
  COMBINE_STATMONTHPATIENT_FAIL,
  COMBINE_STATMONTHPATIENT_SUCCESS,
  COMBINE_STATMONTHRESULT,
  COMBINE_STATMONTHRESULT_FAIL,
  COMBINE_STATMONTHRESULT_SUCCESS,
  COMBINE_STATMONTHLOCATIONTYPE,
  COMBINE_STATMONTHLOCATIONTYPE_FAIL,
  COMBINE_STATMONTHLOCATIONTYPE_SUCCESS,
  COMBINE_STATMONTHRESULTFULLDATA,
  COMBINE_STATMONTHRESULTFULLDATA_FAIL,
  COMBINE_STATMONTHRESULTFULLDATA_SUCCESS,
  COMBINE_STATMONTHRESULTINSTRUMENT,
  COMBINE_STATMONTHRESULTINSTRUMENT_FAIL,
  COMBINE_STATMONTHRESULTINSTRUMENT_SUCCESS,
  REPORT_DATA_DATABASE,
  REPORT_DATA_DATABASE_FAIL,
  REPORT_DATA_INSTRUMENT,
  REPORT_DATA_INSTRUMENT_FAIL,
  REPORT_DATA_STATMONTH,
  REPORT_DATA_STATMONTH_FAIL,
  REPORT_DATA_STATMONTH_SUCCESS,
  SET_CHECKBOX,
} from "./actionTypes";

const INIT_STATE = {
  sites: [],
  paging: {},
  loadingSite: false,
  updatingSite: false,
  searchQuery: {},
  updateSiteTime: new Date(),
  error: {},
  site: {},
  checkboxs: {},
};

const Site = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SITES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSite: false,
      };
    case GET_SITES:
      return {
        ...state,
        sites: [],
      };
    case GET_SITES_SUCCESS:
      const { data, totalElements, ...rest } = action.payload;
      return {
        ...state,
        sites: data,
        paging: { ...rest, dataSize: totalElements },
        loadingSite: false,
        updateSiteTime: new Date(),
      };
    //set search query
    case SET_SITE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      };
    //reset search query
    case RESET_SITE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      };
    //get site detail
    case GET_SITE_DETAIL:
      return {
        ...state,
        site: {},
        error: {},
      };
    case GET_SITE_DETAIL_SUCCESS:
      return {
        ...state,
        site: action.payload,
      };

    case GET_SITE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    //add site
    case ADD_SITE:
      return {
        ...state,
      };

    case ADD_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    //update site
    case UPDATE_SITE:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };

    case UPDATE_SITE_SUCCESS:
      return {
        ...state,
        sites: state.sites.map(site =>
          site.id?.toString() === action.payload.id.toString()
            ? { ...site, ...action.payload }
            : site
        ),
        updatingSite: false,
      };

    case UPDATE_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };

    case DELETE_SITE:
      const payload = action.payload.sites;
      const itemUpdate = state.sites.filter(
        site => payload.findIndex(_payload => site.id === _payload.id) < 0
      );
      const statePaging = { ...state.paging };
      statePaging.dataSize = statePaging.dataSize - payload.length;
      if (statePaging.dataSize < 0) statePaging = 0;

      return {
        ...state,
        sites: itemUpdate,
        paging: statePaging,
      };

    case DELETE_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    //sync site
    case SYNC_SITE:
      // let sites = [];
      return {
        ...state,
        //sites: sites,
        sites: state.sites.map(site => {
          return site.id?.toString() == action.payload.siteCode
            ? { ...site, ...action.payload }
            : site;
        }),
        updatingSite: false,
      };
    case SYNC_SITE_SUCCESS:
      let site = action.payload;

      let sites = state.sites.filter(site => {
        if (site.id?.toString() === action.payload.toString())
          return (site.isSync = true);
      });

      return {
        ...state,
        sites: sites,
        updatingSite: false,
      };
    case SYNC_SITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    //Export data
    case EXPORT_MAP_TEST_CODE:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case EXPORT_MAP_TEST_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    //Export data
    case SYNC_DATABASE:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case SYNC_DATABASE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    case COMBINE_STATMONTHPATIENT:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case COMBINE_STATMONTHPATIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    case COMBINE_STATMONTHRESULT:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case COMBINE_STATMONTHRESULT_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    case COMBINE_STATMONTHPATIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    case COMBINE_STATMONTHLOCATIONTYPE:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case COMBINE_STATMONTHLOCATIONTYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    case COMBINE_STATMONTHRESULTINSTRUMENT:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case COMBINE_STATMONTHRESULTINSTRUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    //Report data database
    case REPORT_DATA_DATABASE:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case REPORT_DATA_DATABASE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    //Report data instrument
    case REPORT_DATA_INSTRUMENT:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case REPORT_DATA_INSTRUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    //Report data instrument
    case REPORT_DATA_STATMONTH:
      return {
        ...state,
        updatingSite: true,
        error: {},
      };
    case REPORT_DATA_STATMONTH_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingSite: false,
      };
    case SET_CHECKBOX:     
      return {
        ...state,
        checkboxs: action.payload
      };
    default:
      return state;
  }
};

export default Site;
