import React, { Fragment, useState } from "react";
import ReactTable from "react-table";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  BACKGROUND_COLOR_FOCUSED,
  TEXT_COLOR_FOCUSED,
} from "../../config";
import { Button } from "antd";
import { LoadingText } from "../../Main/custom";
const Index = ({ data = [], onClickValue, loading, keyTable }) => {
  const [selectRow, setSelectRow] = useState(null);
  const columns = [
    {
      Header: "Thời gian",
      accessor: "actionTime",
      key: "actionTime",
      minWidth: 130,
      Cell: (data) => {
        return (
          <div
            className="w-100 d-flex justify-content-center"
            style={{ fontWeight: "bold" }}
          >
            {dayjs(data.value).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      Header: "FullSID",
      accessor: "fullSID",
      key: "fullSID",

      Cell: (data) => {
        return (
          <div
            className="w-100 d-flex justify-content-center"
            style={{
              fontWeight: "bold",
              borderWidth: 0,
              backgroundColor: "transparent",
            }}
          >
            {data.value}
          </div>
        );
      },
    },
    {
      Header: "Sample",
      accessor: "sampleTypeId",
      key: "sampleTypeId",

      Cell: (data) => {
        return (
          <div
            className="w-100 d-flex justify-content-center"
            style={{
              fontWeight: "bold",
              borderWidth: 0,
              backgroundColor: "transparent",
            }}
          >
            {data.value}
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <ReactTable
        key={`${keyTable}`}
        loading={loading}
        loadingText={<LoadingText />}
        style={{ height: 450 }}
        data={data}
        columns={columns}
        showPagination={true}
        noDataText={"No data"}
        previousText={"<"}
        nextText={">"}
        pageSize={8}
        pageSizeOptions={[8]}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
               // onClickValue(rowInfo.row.fullSID, rowInfo.row.sampleTypeId);
               onClickValue(rowInfo.row);
                setSelectRow(rowInfo.row);
              },
              style: {
                background:
                  JSON.stringify(rowInfo.row) === JSON.stringify(selectRow)
                    ? BACKGROUND_COLOR_FOCUSED
                    : "",
                color:
                  JSON.stringify(rowInfo.row) === JSON.stringify(selectRow)
                    ? TEXT_COLOR_FOCUSED
                    : "black",
                cursor: "pointer",
              },
            };
          } else {
            return {};
          }
        }}
      />
    </Fragment>
  );
};

export default withTranslation(["configure"])(Index);
