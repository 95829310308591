import React, { useEffect, useState } from "react";
import { Typography, Space, Input, Button, Spin } from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CustomCard, CustomBox } from "./../../Main/custom";
import Table from "./Table";
const ListSamplePath = ({
  data = [],
  lsTestCode,
  onGetDetailSampleFlow,
  setCurrentRouteName,
  loading,
  setIsLoading,
  filter,
  setFilter,
  onSearch,
  inputRef,
  totalDataFlow,
  keyState,
  setKeyState,
  paging,
  setPaging,
  keyTopSelectData,
  setDataTop10Calculate,
  setDataTop10Percentage,
  setDataTop5Calculate,
  setDataTop5Percentage,
  setDataNameRoute,
  setDataTestCodeChart
}) => {
  return (
    <CustomCard style={{ padding: "10px 20px" }}>
      <div className="d-flex justify-content-between">
        <Typography.Title
          level={4}
          style={{ color: "#1677ff", fontWeight: "bold" }}
        >
          Danh sách luồng
        </Typography.Title>
      </div>
      <div>
        <Table
          paging={paging}
          setPaging={setPaging}
          setKeyState={setKeyState}
          keyState={keyState}
          totalDataFlow={totalDataFlow}
          inputRef={inputRef}
          filter={filter}
          setFilter={setFilter}
          data={data}
          lsTestCode={lsTestCode}
          onGetDetailSampleFlow={onGetDetailSampleFlow}
          setCurrentRouteName={setCurrentRouteName}
          loading={loading}
          setIsLoading={setIsLoading}
          onSearch={onSearch}
          keyTopSelectData={keyTopSelectData}
          setDataTop10Calculate={setDataTop10Calculate}
          setDataTop10Percentage={setDataTop10Percentage}
          setDataTop5Calculate={setDataTop5Calculate}
          setDataTop5Percentage={setDataTop5Percentage}
          setDataNameRoute={setDataNameRoute}
          setDataTestCodeChart={setDataTestCodeChart}
        />
      </div>
    </CustomCard>
  );
};

export default ListSamplePath;
