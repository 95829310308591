import { getLabelValueSite, getAllSites, getAllParentSites as getAllParentSitesApi} from "helpers/app-backend/sitebackend_helpers";
import HandleReponse from 'utils/handleReponse'

const getLabelAndValueSite = async query => {
  try {
    const res = await getLabelValueSite(query);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const getListSite = async (paramters) => {
  try {
    const res = await getAllSites(paramters);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const getListMasterSite = async (paramters) => {
  try {
    const res = await getAllParentSitesApi(paramters);
    return res
  } catch (err) {
    return [];
  }
};
export default {
  getLabelAndValueSite,
  getListSite,
  getListMasterSite
};
