import React from "react"
import { PageContent, CustomBreadcrumb } from "components/Common";
import StatisticsEffective from "./StatisticsEffective";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"

const RESOURCE = ModuleIds.StatisticsEffective;

const StatisticsEffectiveContainer = ({ t }) => {
    const titleTag = t("Thống kê hiệu quả đầu tư")
    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={titleTag}
                resource={RESOURCE}
                icon={<i className="bx bx-file" />}
            />
            {/* Page Body */}
            <StatisticsEffective />
        </PageContent >
    )
}

StatisticsEffectiveContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["sitePage"])(StatisticsEffectiveContainer)