export const statisticType = {
  XETNGHIEMBENHVIEN: "XETNGHIEMBENHVIEN",
  SOLUONGBENHNHAN: "SOLUONGBENHNHAN",
  SOLUONGBENHNHAN_NAM: "SOLUONGBENHNHAN_NAM",
  SOLUONGXETNGHIEM_NAM: "SOLUONGXETNGHIEM_NAM",
  SLXETNGHIEM_LOAIXN: "SLXETNGHIEM_LOAIXN",
  SLXETNGHIEM_NHOMXN: "SLXETNGHIEM_NHOMXN",
  SLXETNGHIEM_KHOAPHONG: "SLXETNGHIEM_KHOAPHONG",
  SLXETNGHIEM_HANG_LOAIXN: "SLXETNGHIEM_HANG_LOAIXN",
  SLXETNGHIEM_HANG_NHOMXN: "SLXETNGHIEM_HANG_NHOMXN",
  DOANHSO_LOAIXN: "DOANHSO_LOAIXN",
  DOANHSO_NHOMXN: "DOANHSO_NHOMXN",
  DOANHSO_HANG: "DOANHSO_HANG",
  DOANHSO_HANG_NHOMXN: "DOANHSO_HANG_NHOMXN",
  DOANHSO_NHOMKHOAPHONG: "DOANHSO_NHOMKHOAPHONG",
  DOANHSO_NAM: "DOANHSO_NAM",
  DOANHSO_BENHVIEN: "DOANHSO_BENHVIEN",
  DOANHSO_KHOAPHONG: "DOANHSO_KHOAPHONG",
  DOANHSO_BOXN: "DOANHSO_BOXN",
  DOANHSO_PARENT_TEST: "DOANHSO_PARENT_TEST",
  getListSaleStatistic() {
    return [
      {
        label: "Doanh số - Loại xét nghiệm",
        value: this.DOANHSO_LOAIXN,
      },
      {
        label: "Doanh số - Nhóm xét nghiệm",
        value: this.DOANHSO_NHOMXN,
      },
      {
        label: "Doanh số - Hãng",
        value: this.DOANHSO_HANG,
      },
      {
        label: "Doanh số - Hãng - Nhóm XN",
        value: this.DOANHSO_HANG_NHOMXN,
      },
      {
        label: "Doanh số - khoa phòng",
        value: this.DOANHSO_KHOAPHONG,
      },
      // {
      //   label: "Doanh số - Nhóm khoa phòng",
      //   value: this.DOANHSO_NHOMKHOAPHONG,
      // },
      {
        label: "Doanh số - Năm",
        value: this.DOANHSO_NAM,
      },
      {
        label: "Doanh số - Bệnh viện",
        value: this.DOANHSO_BENHVIEN,
      },
      {
        label: "Doanh số - Bộ xét nghiệm",
        value: this.DOANHSO_BOXN,
      },
      {
        label: "Doanh số - Xét nghiệm thương mại",
        value: this.DOANHSO_PARENT_TEST,
      },
    ];
  },
};

export const getLabelValueFrom = cellData =>
  (cellData.page ? cellData.page - 1 : cellData.page) * cellData.pageSize +
  cellData.viewIndex +
  1;

export const colorsSite = [
  "#FF5733",
  "#FF9A8B",
  "#FFC300",
  "#FFFC5E",
  "#CCFF00",
  "#81D8D0",
  "#00FFFF",
  "#008080",
  "#0040FF",
  "#D670DA",
  "#B57EDC",
  "#9370DB",
  "#7D26CD",
  "#0000FF",
  "#000080",
  "#964B00",
  "#8B4513",
  "#C76114",
  "#D2691E",
  "#FF7F50",
  "#FF6347",
  "#FF4500",
  "#E42217",
  "#FF0000",
  "#FF3030",
  "#A52A2A",
  "#800000",
  "#8B0000",
  "#CD5C5C",
  "#BC8F8F",
  "#F08080",
  "#E9967A",
  "#FA8072",
  "#B22222",
  "#FFA07A",
  "#A0522D",
  "#DAA520",
  "#FFD700",
  "#FFD700",
  "#FFD700",
  "#FFD700",
  "#B8860B",
  "#B8860B",
  "#D2691E",
  "#D2691E",
  "#8B4513",
  "#800000",
  "#8B0000",
  "#CD5C5C",
  "#BC8F8F",
  "#F08080",
  "#E9967A",
];

export const GetDataSetChartJS = (dataArr, lbArr, propertiesLabel) => {
  const distinctProperties = [
    ...new Set(dataArr.flatMap(obj => Object.keys(obj))),
  ];
  const dataByProperty = {};

  distinctProperties.forEach(property => {
    dataByProperty[property] = dataArr
      .filter(obj => obj.hasOwnProperty(property))
      .map(obj => {
        return obj[property];
      });
  });

  const keys = Object.keys(dataByProperty);
  var dataSetChart = keys.map((key, index) => {
    const value = dataByProperty[key];
    let dataSet = {
      label: lbArr?.find(x => x.value == key)?.label,
      backgroundColor: colorsSite[index],
      data: value,
    };
    return dataSet;
  });
  dataSetChart.shift();
  let dataChart = {
    labels: dataArr.map(x => x[propertiesLabel]),
    datasets: dataSetChart,
  };
  return dataChart;
};
