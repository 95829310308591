import React from "react";
import { GoLocation } from "react-icons/go";
import { AiOutlineClockCircle } from "react-icons/ai";
import { GiPathDistance } from "react-icons/gi";
import testTubeRed from "../../../assets/images/samplepath/tt-red.png";
import testTubeBlack from "../../../assets/images/samplepath/tt-black.png";
import testTubeGreen from "../../../assets/images/samplepath/tt-green.png";
import testTubeOrange from "../../../assets/images/samplepath/tt-orange.png";
import testTubePink from "../../../assets/images/samplepath/tt-pink.png";
import testTubePurple from "../../../assets/images/samplepath/tt-purple.png";
import testTubeYellow from "../../../assets/images/samplepath/tt-yellow.png";

export const area = {
  RECEIVE_SAMPLE: "RECEIVE_SAMPLE",
  CENTRIFUGAL: "CENTRIFUGAL",
  AU680_1: "AU680_1",
  AU680_2: "AU680_2",
  AU680_3: "AU680_3",
  AU680_4: "AU680_4",
  LIAISON: "LIAISON",
  COBAS: "COBAS",
  DXI_1: "DXI_1",
  DXI_2: "DXI_2",
  INLET: "INLET",
  STOCKYAR: "STOCKYARD",
  OUTLET: "OUTLET",

  // Name
  RECEIVE_SAMPLE_NAME: "Vị trí nhận mẫu",
  CENTRIFUGAL_NAME: "Máy ly tâm",
  AU680_1_NAME: "Máy AU680(1)",
  AU680_2_NAME: "Máy AU680(2)",
  AU680_3_NAME: "Máy AU680(3)",
  AU680_4_NAME: "Máy AU680(4)",
  LIAISON_NAME: "Máy Liaison",
  COBAS_NAME: "Máy Cobas",
  DXI_1_NAME: "Máy DXI(1)",
  DXI_2_NAME: "Máy DXI(2)",
  INLET_NAME: "Inlet",
  STOCKYAR_NAME: "Stockyard",
  OUTLET_NAME: "Outlet",

  P_0: "P_0",
  P_1: "P_1",
  P_2: "P_2",
  P_4: "P_4",
  P_7: "P_7",
  P_11: "P_11",
  P_12: "P_12",
  P_13: "P_13",
  P_14: "P_14",
  P_15: "P_15",
  P_16: "P_16",
  P_17: "P_17",
  P_18: "P_18",
  P_19: "P_19",
  TYPE: {
    SECTION: "SECTION",
    HIT_BOX: "HIT_BOX",
  },
  getList() {
    return [
      // MACHINE POSITION
      {
        id: this.RECEIVE_SAMPLE,
        name: this.RECEIVE_SAMPLE_NAME,
        x: 8,
        y: 138,
        w: 80,
        h: 68,
        type: this.TYPE.SECTION,
        sampleNumberX: 40,
        sampleNumberY: 130,
      },
      {
        id: this.CENTRIFUGAL,
        name: this.CENTRIFUGAL_NAME,
        x: 310,
        y: 2,
        w: 114,
        h: 38,
        type: this.TYPE.SECTION,
        sampleNumberX: 422,
        sampleNumberY: 36,
      },
      {
        id: this.AU680_1,
        name: this.AU680_1_NAME,
        x: 740,
        y: 494,
        w: 60,
        h: 164,
        type: this.TYPE.SECTION,
        sampleNumberX: 798,
        sampleNumberY: 484,
      },
      {
        id: this.AU680_2,
        name: this.AU680_2_NAME,
        x: 886,
        y: 494,
        w: 60,
        h: 164,
        type: this.TYPE.SECTION,
        sampleNumberX: 944,
        sampleNumberY: 484,
      },
      {
        id: this.AU680_3,
        name: this.AU680_3_NAME,
        x: 284,
        y: 162,
        w: 66,
        h: 122,
        type: this.TYPE.SECTION,
        sampleNumberX: 342,
        sampleNumberY: 162,
      },
      {
        id: this.AU680_4,
        name: this.AU680_4_NAME,
        x: 284,
        y: 324,
        w: 66,
        h: 122,
        type: this.TYPE.SECTION,
        sampleNumberX: 342,
        sampleNumberY: 324,
      },
      {
        id: this.LIAISON,
        name: this.LIAISON_NAME,
        x: 54,
        y: 528,
        w: 60,
        h: 94,
        type: this.TYPE.SECTION,
        sampleNumberX: 110,
        sampleNumberY: 528,
      },
      {
        id: this.DXI_1,
        name: this.DXI_1_NAME,
        x: 284,
        y: 486,
        w: 60,
        h: 106,
        type: this.TYPE.SECTION,
        sampleNumberX: 342,
        sampleNumberY: 496,
      },
      {
        id: this.COBAS,
        name: this.COBAS_NAME,
        x: 48,
        y: 342,
        w: 68,
        h: 134,
        type: this.TYPE.SECTION,
        sampleNumberX: 110,
        sampleNumberY: 342,
      },
      {
        id: this.DXI_2,
        name: this.DXI_2_NAME,
        x: 560,
        y: 510,
        w: 100,
        h: 132,
        type: this.TYPE.SECTION,
        sampleNumberX: 664,
        sampleNumberY: 498,
      },
      {
        id: this.INLET,
        name: this.INLET_NAME,
        x: 380,
        y: 230,
        w: 52,
        h: 50,
        type: this.TYPE.SECTION,
        sampleNumberX: 434,
        sampleNumberY: 230,
      },
      {
        id: this.STOCKYAR,
        name: this.STOCKYAR_NAME,
        x: 1004,
        y: 354,
        w: 138,
        h: 56,
        type: this.TYPE.SECTION,
        sampleNumberX: 1100,
        sampleNumberY: 382,
      },
      {
        id: this.OUTLET,
        name: this.OUTLET_NAME,
        x: 1040,
        y: 268,
        w: 72,
        h: 50,
        type: this.TYPE.SECTION,
        sampleNumberX: 1100,
        sampleNumberY: 290,
      },

      // HIT BOX POSITION
      {
        id: this.P_0,
        x: 85,
        y: 110,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_1,
        x: 318,
        y: 100,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_2,
        x: 184,
        y: 154,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_4,
        x: 184,
        y: 464,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_7,
        x: 460,
        y: 114,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_11,
        x: 760,
        y: 416,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_12,
        x: 932,
        y: 416,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_13,
        x: 1066,
        y: 566,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_14,
        x: 1002,
        y: 298,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_15,
        x: 990,
        y: 114,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_16,
        x: 460,
        y: 246,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_17,
        x: 392,
        y: 568,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_18,
        x: 1062,
        y: 226,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
      {
        id: this.P_19,
        x: 550,
        y: 416,
        w: 20,
        h: 20,
        type: this.TYPE.HIT_BOX,
      },
    ];
  },
  getListObjects() {
    const ls = this.getList();
    const objs = ls.reduce((a, v) => ({ ...a, [v.id]: { ...v } }), {});
    return objs;
  },
  getMapActionLocationObj() {
    return {
      "dxi (id=2)": this.DXI_2,
      "dxi 800-2 (id=35)": this.DXI_1,
      "mod:02-stockyard/outlet 2a, unit:1": this.STOCKYAR,
      "mod:01-outlet 1a, unit:2": this.OUTLET,
      delivery: this.RECEIVE_SAMPLE,
      "mod:00-inlet": this.INLET,
      "au680 (id=1)": this.AU680_1,
      "au680(2)  (id=4)": this.AU680_2,
      "au680_3 (id=33)": this.AU680_3,
      "au680_4 (id=34)": this.AU680_4,
      "liaison xl (id=29)": this.LIAISON,
      centrifugal: this.CENTRIFUGAL,
      "cobas 8000 (id=40)": this.COBAS,
    };
  },
};

export const wattageConfigDefault = [
  {
    name: "cobas 8000 (id=40)",
    title: area.COBAS_NAME,
    value: 10
  },
  {
    name: "liaison xl (id=29)",
    title: area.LIAISON_NAME,
    value: 10
  },
  {
    name: "au680_3 (id=33)",
    title: area.AU680_3_NAME,
    value: 10
  },
  {
    name: "au680_4 (id=34)",
    title: area.AU680_4_NAME,
    value: 10
  },
  {
    name: "dxi 800-2 (id=35)",
    title: area.DXI_1_NAME,
    value: 10
  },
  {
    name: "dxi (id=2)",
    title: area.DXI_2_NAME,
    value: 10
  },
  {
    name: "au680 (id=1)",
    title: area.AU680_1_NAME,
    value: 10
  },
  {
    name: "au680(2)  (id=4)",
    title: area.AU680_2_NAME,
    value: 10
  },
  {
    name: "mod:02-stockyard/outlet 2a, unit:1",
    title: area.STOCKYAR_NAME,
    value: 10
  },
  {
    name: "mod:01-outlet 1a, unit:2",
    title: area.OUTLET_NAME,
    value: 10
  },
]

// Kết nối các điểm và các HIT_BOX
export const matchHitBoxAndAreaList = [
  `${area.RECEIVE_SAMPLE}-${area.P_0}`,

  `${area.CENTRIFUGAL}-${area.P_1}`,
  `${area.CENTRIFUGAL}-${area.P_2}`,
  `${area.CENTRIFUGAL}-${area.P_7}`,
  `${area.CENTRIFUGAL}-${area.P_15}`,

  `${area.AU680_3}-${area.P_2}`,
  `${area.AU680_3}-${area.P_7}`,

  `${area.AU680_4}-${area.P_2}`,
  `${area.AU680_4}-${area.P_4}`,

  `${area.AU680_1}-${area.P_11}`,
  `${area.AU680_1}-${area.P_12}`,
  `${area.AU680_1}-${area.P_13}`,

  `${area.AU680_2}-${area.P_12}`,

  `${area.DXI_1}-${area.P_4}`,

  `${area.DXI_2}-${area.P_7}`,
  `${area.DXI_2}-${area.P_17}`,
  `${area.DXI_2}-${area.P_11}`,

  `${area.LIAISON}-${area.P_4}`,

  `${area.COBAS}-${area.P_2}`,

  `${area.STOCKYAR}-${area.P_12}`,
  `${area.STOCKYAR}-${area.P_14}`,
  `${area.STOCKYAR}-${area.P_13}`,
  // `${area.STOCKYAR}-${area.P_17}`,
  `${area.OUTLET}-${area.P_14}`,
  `${area.OUTLET}-${area.P_18}`,

  `${area.INLET}-${area.P_16}`,
  `${area.INLET}-${area.P_17}`,

  `${area.P_0}-${area.P_1}`,

  `${area.P_1}-${area.P_2}`,
  `${area.P_1}-${area.P_4}`,
  `${area.P_1}-${area.P_7}`,

  `${area.P_2}-${area.P_4}`,
  `${area.P_2}-${area.P_7}`,

  `${area.P_7}-${area.P_11}`,
  `${area.P_7}-${area.P_15}`,
  `${area.P_7}-${area.P_16}`,
  `${area.P_7}-${area.P_17}`,

  `${area.P_13}-${area.P_17}`,
  
  `${area.P_11}-${area.P_12}`,
  `${area.P_11}-${area.P_16}`,
  `${area.P_12}-${area.P_14}`,
  `${area.P_15}-${area.P_18}`,
  `${area.P_14}-${area.P_15}`,
  `${area.P_16}-${area.P_17}`,
];

// 2 điểm được kết nối trực tiếp mà không thông qua HIT_BOX
export const connectDirectlyPoints = [
  `${area.RECEIVE_SAMPLE}-${area.COBAS}`,
  `${area.RECEIVE_SAMPLE}-${area.LIAISON}`,
  `${area.COBAS}-${area.LIAISON}`,
  `${area.COBAS}-${area.AU680_4}`,
  `${area.COBAS}-${area.DXI_1}`,
  `${area.COBAS}-${area.AU680_3}`,
  // `${area.AU680_3}-${area.AU680_4}`,
  // `${area.AU680_4}-${area.DXI_1}`,
  `${area.AU680_4}-${area.COBAS}`,
  `${area.AU680_3}-${area.COBAS}`,
  `${area.DXI_1}-${area.INLET}`,
  `${area.DXI_1}-${area.COBAS}`,
  // `${area.AU680_4}-${area.INLET}`,
  // `${area.AU680_3}-${area.INLET}`,
  // `${area.AU680_1}-${area.INLET}`,

  `${area.DXI_2}-${area.AU680_1}`,
  `${area.DXI_2}-${area.AU680_2}`,

  // `${area.AU680_2}-${area.STOCKYAR}`,

  `${area.AU680_1}-${area.AU680_2}`,
  `${area.STOCKYAR}-${area.OUTLET}`,
  `${area.CENTRIFUGAL}-${area.AU680_3}`,
  `${area.LIAISON}-${area.DXI_1}`,
  `${area.LIAISON}-${area.AU680_4}`,
  `${area.LIAISON}-${area.AU680_3}`,

  `${area.DXI_1}-${area.LIAISON}`,
  `${area.AU680_2}-${area.DXI_1}`,
];

// 2 điểm được kết nối phải qua HIT_BOX cho trước
export const connectThroughExactHitBox = [
  `${area.INLET}-${area.DXI_2}`,
  // `${area.DXI_2}-${area.AU680_1}`,
  `${area.DXI_2}-${area.INLET}`,
  `${area.INLET}-${area.AU680_1}`,

  `${area.INLET}-${area.STOCKYAR}`, // mới thêm
  `${area.INLET}-${area.OUTLET}`, // mới thêm
  `${area.INLET}-${area.AU680_2}`,
  // `${area.AU680_1}-${area.AU680_2}`,
  `${area.AU680_1}-${area.STOCKYAR}`,
  `${area.AU680_1}-${area.DXI_2}`,
  `${area.AU680_2}-${area.STOCKYAR}`,
  `${area.AU680_2}-${area.OUTLET}`,
  `${area.AU680_2}-${area.DXI_2}`,
  `${area.AU680_2}-${area.INLET}`,

  `${area.AU680_1}-${area.OUTLET}`,
  `${area.COBAS}-${area.DXI_1}`,
  `${area.DXI_2}-${area.OUTLET}`,
  `${area.DXI_2}-${area.STOCKYAR}`,
  `${area.AU680_1}-${area.DXI_2}`,

  `${area.DXI_1}-${area.AU680_1}`,
  `${area.DXI_1}-${area.AU680_2}`,
  `${area.DXI_1}-${area.AU680_3}`,
  `${area.DXI_1}-${area.AU680_4}`,


  `${area.LIAISON}-${area.COBAS}`,

  `${area.AU680_3}-${area.DXI_1}`,
  `${area.AU680_3}-${area.LIAISON}`,
  `${area.AU680_3}-${area.AU680_4}`,
  `${area.AU680_4}-${area.DXI_1}`,

];

export const connectThroughExactHitBox_Data = {
  [`${area.INLET}-${area.DXI_2}`]: [area.P_17],
  [`${area.INLET}-${area.AU680_1}`]: [area.P_17],
  [`${area.INLET}-${area.AU680_2}`]: [area.P_17],
  [`${area.INLET}-${area.OUTLET}`]: [area.P_17, area.P_13], // mới thêm
  [`${area.INLET}-${area.STOCKYAR}`]: [area.P_17, area.P_13], // mới thêm


  [`${area.AU680_1}-${area.STOCKYAR}`]: [area.P_13],
  [`${area.AU680_1}-${area.OUTLET}`]: [area.P_13],
  [`${area.AU680_1}-${area.DXI_2}`]: [area.P_12, area.P_11],
  [`${area.AU680_2}-${area.DXI_2}`]: [area.P_12, area.P_11],
  [`${area.AU680_2}-${area.STOCKYAR}`]: [area.P_13],
  [`${area.AU680_2}-${area.OUTLET}`]: [area.P_13],
  [`${area.AU680_2}-${area.INLET}`]: [area.P_12, area.P_11], // mới thêm

  [`${area.AU680_3}-${area.DXI_1}`]: [area.P_4],
  
  [`${area.DXI_2}-${area.INLET}`]: [area.P_16],
  [`${area.DXI_2}-${area.STOCKYAR}`]: [area.P_13],
  [`${area.DXI_2}-${area.OUTLET}`]: [area.P_13],


  [`${area.DXI_1}-${area.AU680_3}`]: [area.P_4],
  [`${area.DXI_1}-${area.AU680_4}`]: [area.P_4],

  [`${area.LIAISON}-${area.COBAS}`]: [area.P_4],
  [`${area.AU680_4}-${area.DXI_1}`]: [area.P_4],
  [`${area.AU680_3}-${area.LIAISON}`]: [area.P_4],
  [`${area.AU680_3}-${area.AU680_4}`]: [area.P_4],

  [`${area.DXI_2}-${area.AU680_1}`]: [area.P_11],
  [`${area.DXI_2}-${area.AU680_2}`]: [area.P_11, area.P_12],
  [`${area.DXI_2}-${area.STOCKYAR}`]: [area.P_11, area.P_12],
};


export const connectThroughExactHitBox_Data_No_Inlet = {  
  [`${area.DXI_2}-${area.AU680_1}`]: [area.P_11],
  [`${area.DXI_2}-${area.AU680_2}`]: [area.P_11, area.P_12],
  [`${area.DXI_2}-${area.STOCKYAR}`]: [area.P_11, area.P_12],
  [`${area.INLET}-${area.STOCKYAR}`]: [area.P_17, area.P_13], // mới thêm
  [`${area.AU680_2}-${area.DXI_2}`]: [area.P_12, area.P_11],

  [`${area.AU680_2}-${area.DXI_2}`]: [area.P_12, area.P_11],
};


export const skipDistanceCalculate = [`${area.INLET}-${area.DXI_2}`];

export const sampleType = {
  HP: "HP",
  N: "N",
  EDTA: "EDTA",

  getList() {
    return [
      {
        id: this.HP,
        icon: testTubePurple,
      },
      {
        id: this.N,
        icon: testTubeRed,
      },
      {
        id: this.EDTA,
        icon: testTubeRed,
      },
    ];
  },

  getIcon(id) {
    if (!id) return testTubeRed;

    const ls = this.getList();
    const idx = ls.findIndex((type) => type.id === id);

    if (idx > -1) {
      return ls[idx].icon;
    }

    return testTubeRed;
  },
};

export const configKey = {
  RENDER_SECTION_BACKGROUND: "isRenderSectionBackground",
  RENDER_SECTION_TEXT: "isRenderSectionText",
  SECTION_BACKGROUND: "sectionBackground",
  SECTION_TEXT_COLOR: "sectionTextColor",
  RENDER_PATH: "isRenderSamplePath",
  PATH_WIDTH: "pathWidth",
  PATH_COLOR: "pathColor",
  SPEED: "speed",
  DELAY_TIME: "delayTime",
  FONT_SIZE: "fontSize",
  RENDER_ICON: "isRenderIcon",
  ICON_SIZE: "iconSize",
  SID_COLOR: "sidColor",
  RENDER_SID: "isRenderSID",
  RENDER_SAMPLE_TYPE: "isRenderSampleType",
  SAMPLE_TYPE_COLOR: "sampleTypeColor",
  RENDER_SECTION_HIGHTLIGHT_BACKGROUND: "isRenderSectionHightlightBackground",
  SECTION_HIGHTLIGHT_BACKGROUND: "sectionHightlightBackground",
  IS_CALC_DISTANCE_IN_PROCESS: "isCalcDistanceInProcess",

  // Số lượng mẫu đang phân tích trên máy
  RENDER_SAMPLE_NUMBER: "isRenderSampleNumber",
  SAMPLE_NUMBER_NORMAL_BG: "sampleNumberNormalBackground",
  SAMPLE_NUMBER_NORMAL_BG_HOVER: "sampleNumberNormalBackgroundHover",
  SAMPLE_NUMBER_WARNING_BG: "sampleNumberWarningBackground",
  SAMPLE_NUMBER_WARNING_BG_HOVER: "sampleNumberWarningBackgroundHover",
  SAMPLE_NUMBER_CIRCLE_DIAMETER: "sampleNumberCircleDiameter",
  SAMPLE_NUMBER_FONT_SIZE: "sampleNumberFontSize",
  SAMPLE_NUMBER_COLOR: "sampleNumberColor",
  SAMPLE_NUMBER_BORDER_COLOR: "sampleNumberBorderColor",
};

export const timeLineStatistic = {
  totalTime: {
    icon: (
      <AiOutlineClockCircle style={{ fontSize: "2.7rem", color: "#8c00ff" }} />
    ),
    label: "Tổng thời gian",
    color: "#8c00ff",
  },
  totalDistance: {
    icon: <GiPathDistance style={{ fontSize: "2.7rem", color: "#ff0077" }} />,
    label: "Tổng quãng đường",
    color: "#ff0077",
  },
  totalPosition: {
    icon: <GoLocation style={{ fontSize: "2.7rem", color: "#ff6600" }} />,
    label: "Tổng vị trí",
    color: "#ff6600",
  },
};

// 1:100 (1cm trên map = 100cm hoặc 1m ngoài thực tế)
export const MAP_RATIO = 100;

// PATH
export const IS_RENDER_PATH = true;
export const PATH_COLOR = "#ff7b00";
export const PATH_WIDTH = 3;
export const SPEED = 2;
export const DELAY_TIME = 500; // milisecond
export const FONT_SIZE = "12px";
export const IS_CALC_DISTANCE_IN_PROCESS = false;

// ICON
export const IS_RENDER_ICON = true;
export const ICON_SIZE = 24;

// SID
export const IS_RENDER_SID = false;
export const SID_COLOR = "#7700FF";

// SAMPLE TYPE
export const IS_RENDER_SAMPLE_TYPE = false;
export const SAMPLE_TYPE_COLOR = "#7700FF";

// SECTION
export const IS_RENDER_SECTION = false;
export const IS_RENDER_SECTION_TEXT = false;
export const SECTION_TEXT_COLOR = "#000000";
export const SECTION_BACKGROUND = "rgba(186, 186, 186, 0.6)";
export const SECTION_HIGHTLIGHT_BACKGROUND = "rgba(0, 255, 4, .6)";
export const IS_RENDER_SECTION_HIGHTLIGHT = true;

// HIT BOX
export const IS_RENDER_HIT_BOX = true;
export const HIT_BOX_COLOR = "#2e43ff";

// SAMPLE NUMBER
export const RENDER_SAMPLE_NUMBER = true;
export const SAMPLE_NUMBER_NORMAL_BG = '#05C020';
export const SAMPLE_NUMBER_NORMAL_BG_HOVER = '#08E95D';
export const SAMPLE_NUMBER_WARNING_BG = '#FF003A';
export const SAMPLE_NUMBER_WARNING_BG_HOVER = '#FF3B71';
export const SAMPLE_NUMBER_CIRCLE_DIAMETER = 40;
export const SAMPLE_NUMBER_FONT_SIZE = 12;
export const SAMPLE_NUMBER_COLOR = '#FFF';
export const SAMPLE_NUMBER_BORDER_COLOR = '#FFF';

export const distanceArea = {
  [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`]: 0,
  [`${area.RECEIVE_SAMPLE}-${area.INLET}`]: 1,
  [`${area.RECEIVE_SAMPLE}-${area.DXI_1}`]: 2,
  [`${area.RECEIVE_SAMPLE}-${area.AU680_1}`]: 3,
  [`${area.RECEIVE_SAMPLE}-${area.AU680_2}`]: 4,
  [`${area.RECEIVE_SAMPLE}-${area.STOCKYAR}`]: 5,
  [`${area.RECEIVE_SAMPLE}-${area.COBAS}`]: 6,
  [`${area.RECEIVE_SAMPLE}-${area.LIAISON}`]: 7,
  [`${area.RECEIVE_SAMPLE}-${area.AU680_3}`]: 8,
  [`${area.RECEIVE_SAMPLE}-${area.AU680_4}`]: 9,
  [`${area.RECEIVE_SAMPLE}-${area.DXI_2}`]: 10,

  [`${area.COBAS}-${area.LIAISON}`]: 1,
  [`${area.COBAS}-${area.AU680_3}`]: 2,
  [`${area.COBAS}-${area.AU680_4}`]: 3,
  [`${area.COBAS}-${area.DXI_2}`]: 4,
  [`${area.COBAS}-${area.INLET}`]: 5,
  [`${area.COBAS}-${area.DXI_1}`]: 6,
  [`${area.COBAS}-${area.AU680_1}`]: 7,
  [`${area.COBAS}-${area.AU680_2}`]: 8,
  [`${area.COBAS}-${area.STOCKYAR}`]: 9,

  [`${area.LIAISON}-${area.COBAS}`]: 1,
  [`${area.LIAISON}-${area.AU680_3}`]: 2,
  [`${area.LIAISON}-${area.AU680_4}`]: 3,
  [`${area.LIAISON}-${area.DXI_2}`]: 4,
  [`${area.LIAISON}-${area.INLET}`]: 5,
  [`${area.LIAISON}-${area.DXI_1}`]: 6,
  [`${area.LIAISON}-${area.AU680_1}`]: 7,
  [`${area.LIAISON}-${area.AU680_2}`]: 8,
  [`${area.LIAISON}-${area.STOCKYAR}`]: 9,

  [`${area.AU680_3}-${area.COBAS}`]: 1,
  [`${area.AU680_3}-${area.LIAISON}`]: 2,
  [`${area.AU680_3}-${area.AU680_4}`]: 3,
  [`${area.AU680_3}-${area.DXI_2}`]: 4,
  [`${area.AU680_3}-${area.INLET}`]: 5,
  [`${area.AU680_3}-${area.DXI_1}`]: 6,
  [`${area.AU680_3}-${area.AU680_1}`]: 7,
  [`${area.AU680_3}-${area.AU680_2}`]: 8,
  [`${area.AU680_3}-${area.STOCKYAR}`]: 9,

  [`${area.AU680_4}-${area.COBAS}`]: 1,
  [`${area.AU680_4}-${area.LIAISON}`]: 2,
  [`${area.AU680_4}-${area.AU680_3}`]: 3,
  [`${area.AU680_4}-${area.DXI_2}`]: 4,
  [`${area.AU680_4}-${area.INLET}`]: 5,
  [`${area.AU680_4}-${area.DXI_1}`]: 6,
  [`${area.AU680_4}-${area.AU680_1}`]: 7,
  [`${area.AU680_4}-${area.AU680_2}`]: 8,
  [`${area.AU680_4}-${area.STOCKYAR}`]: 9,

  [`${area.DXI_2}-${area.COBAS}`]: 1,
  [`${area.DXI_2}-${area.LIAISON}`]: 2,
  [`${area.DXI_2}-${area.AU680_3}`]: 3,
  [`${area.DXI_2}-${area.AU680_4}`]: 4,
  [`${area.DXI_2}-${area.INLET}`]: 5,
  [`${area.DXI_2}-${area.DXI_1}`]: 6,
  [`${area.DXI_2}-${area.AU680_1}`]: 7,
  [`${area.DXI_2}-${area.AU680_2}`]: 8,
  [`${area.DXI_2}-${area.STOCKYAR}`]: 9,

  [`${area.DXI_1}-${area.COBAS}`]: 1,
  [`${area.DXI_1}-${area.LIAISON}`]: 2,
  [`${area.DXI_1}-${area.AU680_3}`]: 3,
  [`${area.DXI_1}-${area.AU680_4}`]: 4,
  [`${area.DXI_1}-${area.INLET}`]: 5,
  [`${area.DXI_1}-${area.DXI_2}`]: 6,
  [`${area.DXI_1}-${area.AU680_1}`]: 7,
  [`${area.DXI_1}-${area.AU680_2}`]: 8,
  [`${area.DXI_1}-${area.STOCKYAR}`]: 9,

  [`${area.AU680_1}-${area.COBAS}`]: 1,
  [`${area.AU680_1}-${area.LIAISON}`]: 2,
  [`${area.AU680_1}-${area.AU680_3}`]: 3,
  [`${area.AU680_1}-${area.AU680_4}`]: 4,
  [`${area.AU680_1}-${area.INLET}`]: 5,
  [`${area.AU680_1}-${area.DXI_2}`]: 6,
  [`${area.AU680_1}-${area.DXI_1}`]: 7,
  [`${area.AU680_1}-${area.AU680_2}`]: 8,
  [`${area.AU680_1}-${area.STOCKYAR}`]: 9,

  [`${area.AU680_2}-${area.COBAS}`]: 1,
  [`${area.AU680_2}-${area.LIAISON}`]: 2,
  [`${area.AU680_2}-${area.AU680_3}`]: 3,
  [`${area.AU680_2}-${area.AU680_4}`]: 4,
  [`${area.AU680_2}-${area.INLET}`]: 5,
  [`${area.AU680_2}-${area.DXI_2}`]: 6,
  [`${area.AU680_2}-${area.DXI_1}`]: 7,
  [`${area.AU680_2}-${area.AU680_1}`]: 8,
  [`${area.AU680_2}-${area.STOCKYAR}`]: 9,

  [`${area.STOCKYAR}-${area.COBAS}`]: 1,
  [`${area.STOCKYAR}-${area.LIAISON}`]: 2,
  [`${area.STOCKYAR}-${area.AU680_3}`]: 3,
  [`${area.STOCKYAR}-${area.AU680_4}`]: 4,
  [`${area.STOCKYAR}-${area.INLET}`]: 5,
  [`${area.STOCKYAR}-${area.DXI_2}`]: 6,
  [`${area.STOCKYAR}-${area.DXI_1}`]: 7,
  [`${area.STOCKYAR}-${area.AU680_1}`]: 8,
  [`${area.STOCKYAR}-${area.AU680_2}`]: 9,

  [`${area.INLET}-${area.COBAS}`]: 1,
  [`${area.INLET}-${area.LIAISON}`]: 2,
  [`${area.INLET}-${area.AU680_3}`]: 3,
  [`${area.INLET}-${area.AU680_4}`]: 4,
  [`${area.INLET}-${area.STOCKYAR}`]: 5,
  [`${area.INLET}-${area.DXI_2}`]: 6,
  [`${area.INLET}-${area.DXI_1}`]: 7,
  [`${area.INLET}-${area.AU680_1}`]: 8,
  [`${area.INLET}-${area.AU680_2}`]: 9,
};

export const specialRoute = {
  ["Delivery => Mod:00-Inlet => Dxi (id=2) => Mod:02-Stockyard/Outlet 2A, Unit:1 => au680 (id=1) => Mod:02-Stockyard/Outlet 2A, Unit:1"]:{
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.DXI_2}`] : [area.P_17],
    [`${area.DXI_2}-${area.STOCKYAR}`] : [area.P_13],
    [`${area.STOCKYAR}-${area.AU680_1}`] : [area.P_13],
    [`${area.AU680_1}-${area.STOCKYAR}`] : [area.P_13],
  },
  "Delivery => Mod:00-Inlet => au680(2)  (id=4) => Mod:02-Stockyard/Outlet 2A, Unit:1 => Dxi (id=2) => Mod:02-Stockyard/Outlet 2A, Unit:1":{
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.AU680_2}`] : [area.P_17],
    [`${area.AU680_2}-${area.STOCKYAR}`] : [area.P_13],
    [`${area.STOCKYAR}-${area.DXI_2}`] : [area.P_13],
    [`${area.DXI_2}-${area.STOCKYAR}`] : [area.P_13],
  }, 
  "Delivery => Mod:00-Inlet => au680 (id=1) => Au680_3 (id=33) => Mod:02-Stockyard/Outlet 2A, Unit:1":{
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.AU680_1}`] : [area.P_17],
    [`${area.AU680_1}-${area.AU680_3}`] : [area.P_13, area.OUTLET, area.P_18, area.P_15, area.P_7],   
    [`${area.AU680_3}-${area.STOCKYAR}`] : [area.P_7, area.P_16, area.INLET, area.P_17, area.P_13],
  },  
  "Delivery => Mod:00-Inlet => au680 (id=1) => Mod:00-Inlet => Mod:02-Stockyard/Outlet 2A, Unit:1": {
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.AU680_1}`] : [area.P_16, area.P_11],
    [`${area.AU680_1}-${area.INLET}`] : [area.P_11, area.P_16],
    [`${area.INLET}-${area.STOCKYAR}`] : [area.P_17, area.P_13],
  },
  "Delivery => Mod:00-Inlet => au680 (id=1) => Mod:02-Stockyard/Outlet 2A, Unit:1 => Dxi (id=2) => Mod:02-Stockyard/Outlet 2A, Unit:1":{
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.AU680_1}`] : [area.P_17],
    [`${area.AU680_1}-${area.STOCKYAR}`] : [area.P_13],
    [`${area.STOCKYAR}-${area.DXI_2}`] : [area.P_13],
    [`${area.DXI_2}-${area.STOCKYAR}`] : [area.P_13],
  },
  "Delivery => Mod:00-Inlet => Dxi (id=2) => Mod:02-Stockyard/Outlet 2A, Unit:1 => au680(2)  (id=4) => Mod:02-Stockyard/Outlet 2A, Unit:1":{
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.DXI_2}`] : [area.P_17],
    [`${area.DXI_2}-${area.STOCKYAR}`] : [area.P_13],
    [`${area.STOCKYAR}-${area.AU680_2}`] : [area.P_13],
    [`${area.AU680_2}-${area.STOCKYAR}`] : [area.P_13],
  },
  "Delivery => Mod:00-Inlet => Mod:02-Stockyard/Outlet 2A, Unit:1 => au680 (id=1) => Mod:02-Stockyard/Outlet 2A, Unit:1":{
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.STOCKYAR}`] : [area.P_17, area.P_13],
    [`${area.STOCKYAR}-${area.AU680_1}`] : [area.P_13],    
    [`${area.AU680_1}-${area.STOCKYAR}`] : [area.P_13],
  }, 
  "Delivery => Mod:00-Inlet => Mod:02-Stockyard/Outlet 2A, Unit:1 => Dxi (id=2) => Mod:02-Stockyard/Outlet 2A, Unit:1":{
    [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`] : [area.P_2, area.P_1], 
    [`${area.CENTRIFUGAL}-${area.INLET}`] : [area.P_7, area.P_16],
    [`${area.INLET}-${area.STOCKYAR}`] : [area.P_17, area.P_13],
    [`${area.STOCKYAR}-${area.DXI_2}`] : [area.P_13],    
    [`${area.DXI_2}-${area.STOCKYAR}`] : [area.P_13],
  },
}


export const DataPath_Inlet = {
  [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`]: [area.P_2, area.P_1],
  
  [`${area.CENTRIFUGAL}-${area.INLET}`]: [area.P_7, area.P_16],
  [`${area.CENTRIFUGAL}-${area.COBAS}`]: [area.P_1, area.P_2],
  [`${area.CENTRIFUGAL}-${area.LIAISON}`]: [area.P_1, area.P_2, area.P_4],
  [`${area.CENTRIFUGAL}-${area.AU680_3}`]: [],
  [`${area.CENTRIFUGAL}-${area.AU680_4}`]: [area.P_1, area.P_2],
  [`${area.CENTRIFUGAL}-${area.DXI_1}`]: [area.P_1, area.P_2, area.P_4],
  [`${area.CENTRIFUGAL}-${area.DXI_2}`]: [area.P_7],
  [`${area.CENTRIFUGAL}-${area.AU680_1}`]: [area.P_7, area.P_19, area.P_11],
  [`${area.CENTRIFUGAL}-${area.AU680_2}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.CENTRIFUGAL}-${area.STOCKYAR}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.CENTRIFUGAL}-${area.OUTLET}`]: [area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],

  [`${area.COBAS}-${area.INLET}`]: [area.P_2, area.P_1, area.P_7, area.P_16],
  [`${area.COBAS}-${area.CENTRIFUGAL}`]: [area.P_2, area.P_1],
  [`${area.COBAS}-${area.LIAISON}`]: [area.P_4],
  [`${area.COBAS}-${area.AU680_3}`]: [],
  [`${area.COBAS}-${area.AU680_4}`]: [],
  [`${area.COBAS}-${area.DXI_1}`]: [],
  [`${area.COBAS}-${area.DXI_2}`]: [area.P_2, area.P_7],
  [`${area.COBAS}-${area.AU680_1}`]: [area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.COBAS}-${area.AU680_2}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.COBAS}-${area.STOCKYAR}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.COBAS}-${area.OUTLET}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],

  [`${area.LIAISON}-${area.INLET}`]: [area.P_4, area.P_2, area.P_7, area.P_16],
  [`${area.LIAISON}-${area.CENTRIFUGAL}`]: [area.P_4, area.P_2, area.P_1],
  [`${area.LIAISON}-${area.COBAS}`]: [area.P_4],
  [`${area.LIAISON}-${area.AU680_3}`]: [],
  [`${area.LIAISON}-${area.AU680_4}`]: [],
  [`${area.LIAISON}-${area.DXI_1}`]: [],
  [`${area.LIAISON}-${area.DXI_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19],
  [`${area.LIAISON}-${area.AU680_1}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.LIAISON}-${area.AU680_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.LIAISON}-${area.STOCKYAR}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.LIAISON}-${area.OUTLET}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],

  [`${area.AU680_3}-${area.INLET}`]: [area.P_7, area.P_16],
  [`${area.AU680_3}-${area.CENTRIFUGAL}`]: [],
  [`${area.AU680_3}-${area.COBAS}`]: [],
  [`${area.AU680_3}-${area.LIAISON}`]: [],
  [`${area.AU680_3}-${area.AU680_4}`]: [area.P_4],
  [`${area.AU680_3}-${area.DXI_1}`]: [area.P_4],
  [`${area.AU680_3}-${area.DXI_2}`]: [area.P_7],
  [`${area.AU680_3}-${area.AU680_1}`]: [area.P_7, area.P_19, area.P_11],
  [`${area.AU680_3}-${area.AU680_2}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_3}-${area.STOCKYAR}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_3}-${area.OUTLET}`]: [area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],  

  [`${area.AU680_4}-${area.INLET}`]: [area.P_2, area.P_7, area.P_16],
  [`${area.AU680_4}-${area.CENTRIFUGAL}`]: [area.P_2],
  [`${area.AU680_4}-${area.COBAS}`]: [],
  [`${area.AU680_4}-${area.LIAISON}`]: [],
  [`${area.AU680_4}-${area.AU680_3}`]: [area.P_2],
  [`${area.AU680_4}-${area.DXI_1}`]: [area.P_4],
  [`${area.AU680_4}-${area.DXI_2}`]: [area.P_2, area.P_7],
  [`${area.AU680_4}-${area.AU680_1}`]: [area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.AU680_4}-${area.AU680_2}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_4}-${area.STOCKYAR}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_4}-${area.OUTLET}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],  

  [`${area.DXI_1}-${area.INLET}`]: [area.P_4, area.P_2, area.P_7, area.P_16],
  [`${area.DXI_1}-${area.CENTRIFUGAL}`]: [area.P_4, area.P_2, area.P_1],
  [`${area.DXI_1}-${area.COBAS}`]: [],
  [`${area.DXI_1}-${area.LIAISON}`]: [],
  [`${area.DXI_1}-${area.AU680_3}`]: [area.P_4],
  [`${area.DXI_1}-${area.DXI_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.DXI_1}-${area.AU680_4}`]: [area.P_4],
  [`${area.DXI_1}-${area.AU680_1}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.DXI_1}-${area.AU680_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.DXI_1}-${area.STOCKYAR}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.DXI_1}-${area.OUTLET}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],  

  [`${area.DXI_2}-${area.INLET}`]: [area.P_16],
  [`${area.DXI_2}-${area.CENTRIFUGAL}`]: [area.P_7],
  [`${area.DXI_2}-${area.COBAS}`]: [area.P_7, area.P_2],
  [`${area.DXI_2}-${area.LIAISON}`]: [area.P_7, area.P_2],
  [`${area.DXI_2}-${area.AU680_3}`]: [area.P_7],
  [`${area.DXI_2}-${area.DXI_1}`]: [area.P_7, area.P_2, area.P_4],
  [`${area.DXI_2}-${area.AU680_4}`]: [area.P_7, area.P_2],
  [`${area.DXI_2}-${area.AU680_1}`]: [],
  [`${area.DXI_2}-${area.AU680_2}`]: [],
  [`${area.DXI_2}-${area.STOCKYAR}`]: [area.P_13],
  [`${area.DXI_2}-${area.OUTLET}`]: [area.P_13],  
  
  [`${area.AU680_1}-${area.INLET}`]: [area.P_11, area.P_19, area.P_16],
  [`${area.AU680_1}-${area.CENTRIFUGAL}`]: [area.P_11, area.P_19, area.P_7],
  [`${area.AU680_1}-${area.COBAS}`]: [area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_1}-${area.LIAISON}`]: [area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_1}-${area.AU680_3}`]: [area.P_11, area.P_19, area.P_7],
  [`${area.AU680_1}-${area.DXI_1}`]: [area.P_11, area.P_19, area.P_7, area.P_2, area.P_4],
  [`${area.AU680_1}-${area.AU680_4}`]: [area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_1}-${area.DXI_2}`]: [],
  [`${area.AU680_1}-${area.AU680_2}`]: [],
  [`${area.AU680_1}-${area.STOCKYAR}`]: [area.P_13],
  [`${area.AU680_1}-${area.OUTLET}`]: [area.P_13],  
 
  
  [`${area.AU680_2}-${area.INLET}`]: [area.P_12, area.P_11, area.P_19, area.P_16],
  [`${area.AU680_2}-${area.CENTRIFUGAL}`]: [area.P_12, area.P_11, area.P_19, area.P_7],
  [`${area.AU680_2}-${area.COBAS}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_2}-${area.LIAISON}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_2}-${area.AU680_3}`]: [area.P_12, area.P_11, area.P_19, area.P_7],
  [`${area.AU680_2}-${area.DXI_1}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2, area.P_4],
  [`${area.AU680_2}-${area.AU680_4}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_2}-${area.DXI_2}`]: [],  
  [`${area.AU680_2}-${area.AU680_1}`]: [], 
  [`${area.AU680_2}-${area.STOCKYAR}`]: [area.P_13], 
  [`${area.AU680_2}-${area.OUTLET}`]: [area.P_13],  
 
  [`${area.STOCKYAR}-${area.INLET}`]: [area.P_12, area.P_11, area.P_19, area.P_16],
  [`${area.STOCKYAR}-${area.CENTRIFUGAL}`]: [area.P_14, area.P_15],
  [`${area.STOCKYAR}-${area.COBAS}`]: [area.P_14, area.P_15, area.P_7, area.P_2],
  [`${area.STOCKYAR}-${area.LIAISON}`]: [area.P_14, area.P_15, area.P_7, area.P_2],
  [`${area.STOCKYAR}-${area.AU680_3}`]: [area.P_14, area.P_15, area.P_7],
  [`${area.STOCKYAR}-${area.DXI_1}`]: [area.P_14, area.P_15, area.P_7, area.P_2, area.P_4],
  [`${area.STOCKYAR}-${area.AU680_4}`]: [area.P_14, area.P_15, area.P_7, area.P_2],
  [`${area.STOCKYAR}-${area.DXI_2}`]: [area.P_13],  
  [`${area.STOCKYAR}-${area.AU680_1}`]: [area.P_13], 
  [`${area.STOCKYAR}-${area.AU680_2}`]: [area.P_13], 
  [`${area.STOCKYAR}-${area.OUTLET}`]: [],  
 

  [`${area.INLET}-${area.STOCKYAR}`]: [area.P_17, area.P_13], 
  [`${area.INLET}-${area.CENTRIFUGAL}`]: [area.P_14, area.P_15],
  [`${area.INLET}-${area.COBAS}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.LIAISON}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.AU680_3}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.DXI_1}`]: [area.P_16, area.P_7, area.P_2, area.P_4],
  [`${area.INLET}-${area.AU680_4}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.DXI_2}`]: [area.P_17],  
  [`${area.INLET}-${area.AU680_1}`]: [area.P_17], 
  [`${area.INLET}-${area.AU680_2}`]: [area.P_17], 
  [`${area.INLET}-${area.OUTLET}`]: [area.P_17, area.P_13], 
};


export const DataPath_No_Inlet = {
  [`${area.RECEIVE_SAMPLE}-${area.CENTRIFUGAL}`]: [area.P_2, area.P_1],
  
  [`${area.CENTRIFUGAL}-${area.INLET}`]: [area.P_7, area.P_16],
  [`${area.CENTRIFUGAL}-${area.COBAS}`]: [area.P_1, area.P_2],
  [`${area.CENTRIFUGAL}-${area.LIAISON}`]: [area.P_1, area.P_2, area.P_4],
  [`${area.CENTRIFUGAL}-${area.AU680_3}`]: [],
  [`${area.CENTRIFUGAL}-${area.AU680_4}`]: [area.P_1, area.P_2],
  [`${area.CENTRIFUGAL}-${area.DXI_1}`]: [area.P_1, area.P_2, area.P_4],
  [`${area.CENTRIFUGAL}-${area.DXI_2}`]: [area.P_7],
  [`${area.CENTRIFUGAL}-${area.AU680_1}`]: [area.P_7, area.P_19, area.P_11],
  [`${area.CENTRIFUGAL}-${area.AU680_2}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.CENTRIFUGAL}-${area.STOCKYAR}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.CENTRIFUGAL}-${area.OUTLET}`]: [area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],

  [`${area.COBAS}-${area.INLET}`]: [area.P_2, area.P_1, area.P_7, area.P_16],
  [`${area.COBAS}-${area.CENTRIFUGAL}`]: [area.P_2, area.P_1],
  [`${area.COBAS}-${area.LIAISON}`]: [area.P_4],
  [`${area.COBAS}-${area.AU680_3}`]: [],
  [`${area.COBAS}-${area.AU680_4}`]: [],
  [`${area.COBAS}-${area.DXI_1}`]: [],
  [`${area.COBAS}-${area.DXI_2}`]: [area.P_2, area.P_7],
  [`${area.COBAS}-${area.AU680_1}`]: [area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.COBAS}-${area.AU680_2}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.COBAS}-${area.STOCKYAR}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.COBAS}-${area.OUTLET}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],

  [`${area.LIAISON}-${area.INLET}`]: [area.P_4, area.P_2, area.P_7, area.P_16],
  [`${area.LIAISON}-${area.CENTRIFUGAL}`]: [area.P_4, area.P_2, area.P_1],
  [`${area.LIAISON}-${area.COBAS}`]: [area.P_4],
  [`${area.LIAISON}-${area.AU680_3}`]: [],
  [`${area.LIAISON}-${area.AU680_4}`]: [],
  [`${area.LIAISON}-${area.DXI_1}`]: [],
  [`${area.LIAISON}-${area.DXI_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19],
  [`${area.LIAISON}-${area.AU680_1}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.LIAISON}-${area.AU680_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.LIAISON}-${area.STOCKYAR}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.LIAISON}-${area.OUTLET}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],

  [`${area.AU680_3}-${area.INLET}`]: [area.P_7, area.P_16],
  [`${area.AU680_3}-${area.CENTRIFUGAL}`]: [],
  [`${area.AU680_3}-${area.COBAS}`]: [],
  [`${area.AU680_3}-${area.LIAISON}`]: [],
  [`${area.AU680_3}-${area.AU680_4}`]: [area.P_4],
  [`${area.AU680_3}-${area.DXI_1}`]: [area.P_4],
  [`${area.AU680_3}-${area.DXI_2}`]: [area.P_7],
  [`${area.AU680_3}-${area.AU680_1}`]: [area.P_7, area.P_19, area.P_11],
  [`${area.AU680_3}-${area.AU680_2}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_3}-${area.STOCKYAR}`]: [area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_3}-${area.OUTLET}`]: [area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],  

  [`${area.AU680_4}-${area.INLET}`]: [area.P_2, area.P_7, area.P_16],
  [`${area.AU680_4}-${area.CENTRIFUGAL}`]: [area.P_2],
  [`${area.AU680_4}-${area.COBAS}`]: [],
  [`${area.AU680_4}-${area.LIAISON}`]: [],
  [`${area.AU680_4}-${area.AU680_3}`]: [area.P_2],
  [`${area.AU680_4}-${area.DXI_1}`]: [area.P_4],
  [`${area.AU680_4}-${area.DXI_2}`]: [area.P_2, area.P_7],
  [`${area.AU680_4}-${area.AU680_1}`]: [area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.AU680_4}-${area.AU680_2}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_4}-${area.STOCKYAR}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.AU680_4}-${area.OUTLET}`]: [area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],  

  [`${area.DXI_1}-${area.INLET}`]: [area.P_4, area.P_2, area.P_7, area.P_16],
  [`${area.DXI_1}-${area.CENTRIFUGAL}`]: [area.P_4, area.P_2, area.P_1],
  [`${area.DXI_1}-${area.COBAS}`]: [],
  [`${area.DXI_1}-${area.LIAISON}`]: [],
  [`${area.DXI_1}-${area.AU680_3}`]: [area.P_4],
  [`${area.DXI_1}-${area.DXI_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.DXI_1}-${area.AU680_4}`]: [area.P_4],
  [`${area.DXI_1}-${area.AU680_1}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11],
  [`${area.DXI_1}-${area.AU680_2}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.DXI_1}-${area.STOCKYAR}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12],
  [`${area.DXI_1}-${area.OUTLET}`]: [area.P_4, area.P_2, area.P_7, area.P_19, area.P_11, area.P_12, area.P_14],  

  [`${area.DXI_2}-${area.INLET}`]: [area.P_16],
  [`${area.DXI_2}-${area.CENTRIFUGAL}`]: [area.P_7],
  [`${area.DXI_2}-${area.COBAS}`]: [area.P_7, area.P_2],
  [`${area.DXI_2}-${area.LIAISON}`]: [area.P_7, area.P_2],
  [`${area.DXI_2}-${area.AU680_3}`]: [area.P_7],
  [`${area.DXI_2}-${area.DXI_1}`]: [area.P_7, area.P_2, area.P_4],
  [`${area.DXI_2}-${area.AU680_4}`]: [area.P_7, area.P_2], 
  [`${area.DXI_2}-${area.AU680_1}`]: [area.P_11],   //no inlet 
  [`${area.DXI_2}-${area.AU680_2}`]: [area.P_11, area.P_12], //no inlet 
  [`${area.DXI_2}-${area.STOCKYAR}`]: [area.P_11, area.P_12], //no inlet   
  [`${area.DXI_2}-${area.OUTLET}`]: [area.P_11, area.P_12, area.P_14], //no inlet
  
  [`${area.AU680_1}-${area.INLET}`]: [area.P_11, area.P_19, area.P_16],
  [`${area.AU680_1}-${area.CENTRIFUGAL}`]: [area.P_11, area.P_19, area.P_7],
  [`${area.AU680_1}-${area.COBAS}`]: [area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_1}-${area.LIAISON}`]: [area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_1}-${area.AU680_3}`]: [area.P_11, area.P_19, area.P_7],
  [`${area.AU680_1}-${area.DXI_1}`]: [area.P_11, area.P_19, area.P_7, area.P_2, area.P_4],
  [`${area.AU680_1}-${area.AU680_4}`]: [area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_1}-${area.DXI_2}`]: [area.P_11],   //no inlet 
  [`${area.AU680_1}-${area.AU680_2}`]: [area.P_12], //no inlet  
  [`${area.AU680_1}-${area.STOCKYAR}`]: [area.P_12], //no inlet  
  [`${area.AU680_1}-${area.OUTLET}`]: [area.P_12, area.P_14], //no inlet
  
  [`${area.AU680_2}-${area.INLET}`]: [area.P_12, area.P_11, area.P_19, area.P_16],
  [`${area.AU680_2}-${area.CENTRIFUGAL}`]: [area.P_12, area.P_11, area.P_19, area.P_7],
  [`${area.AU680_2}-${area.COBAS}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_2}-${area.LIAISON}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_2}-${area.AU680_3}`]: [area.P_12, area.P_11, area.P_19, area.P_7],
  [`${area.AU680_2}-${area.DXI_1}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2, area.P_4],
  [`${area.AU680_2}-${area.AU680_4}`]: [area.P_12, area.P_11, area.P_19, area.P_7, area.P_2],
  [`${area.AU680_2}-${area.DXI_2}`]: [area.P_12, area.P_11],   //no inlet  
  [`${area.AU680_2}-${area.AU680_1}`]: [area.P_12], //no inlet 
  [`${area.AU680_2}-${area.STOCKYAR}`]: [], //no inlet  
  [`${area.AU680_2}-${area.OUTLET}`]: [ area.P_14], //no inlet

  [`${area.STOCKYAR}-${area.INLET}`]: [area.P_12, area.P_11, area.P_19, area.P_16],
  [`${area.STOCKYAR}-${area.CENTRIFUGAL}`]: [area.P_14, area.P_15],
  [`${area.STOCKYAR}-${area.COBAS}`]: [area.P_14, area.P_15, area.P_7, area.P_2],
  [`${area.STOCKYAR}-${area.LIAISON}`]: [area.P_14, area.P_15, area.P_7, area.P_2],
  [`${area.STOCKYAR}-${area.AU680_3}`]: [area.P_14, area.P_15, area.P_7],
  [`${area.STOCKYAR}-${area.DXI_1}`]: [area.P_14, area.P_15, area.P_7, area.P_2, area.P_4],
  [`${area.STOCKYAR}-${area.AU680_4}`]: [area.P_14, area.P_15, area.P_7, area.P_2], 
  [`${area.STOCKYAR}-${area.DXI_2}`]: [area.P_12, area.P_11],   //no inlet  
  [`${area.STOCKYAR}-${area.AU680_1}`]: [area.P_12], //no inlet 
  [`${area.STOCKYAR}-${area.AU680_2}`]: [], //no inlet   
  [`${area.STOCKYAR}-${area.OUTLET}`]: [ area.P_14], //no inlet


  [`${area.INLET}-${area.STOCKYAR}`]: [area.P_12, area.P_11, area.P_16], //no inlet   
  [`${area.INLET}-${area.CENTRIFUGAL}`]: [area.P_14, area.P_15],
  [`${area.INLET}-${area.COBAS}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.LIAISON}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.AU680_3}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.DXI_1}`]: [area.P_16, area.P_7, area.P_2, area.P_4],
  [`${area.INLET}-${area.AU680_4}`]: [area.P_16, area.P_7, area.P_2],
  [`${area.INLET}-${area.DXI_2}`]: [area.P_17],  
  [`${area.INLET}-${area.AU680_1}`]: [area.P_17], 
  [`${area.INLET}-${area.AU680_2}`]: [area.P_17], 
  [`${area.INLET}-${area.OUTLET}`]: [area.P_17, area.P_13], 
};