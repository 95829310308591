import Table, { formatValueToMoneyVND } from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setIsEdit,
  setIsModalInsertUpdate,
  setDetailData,
  setIsModalConfirmDelete,
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "",
          width: 70,
          fixed: "left",
          headerClassName: "sticky-header",
          Cell: data => (
            <div className="d-flex">
              <Button
                size="sm"
                className="d-flex w-100 justify-content-center me-1"
                style={{ backgroundColor: "#c1c4e8", borderWidth: 0 }}
                onClick={e => {
                  setIsEdit(true);
                  setIsModalInsertUpdate(true);
                  setDetailData(data.original);
                }}
              >
                <i className="mdi mdi-pencil text-primary"></i>
              </Button>
              <Button
                size="sm"
                className="d-flex w-100 justify-content-center"
                style={{ backgroundColor: "#f4dcda", borderWidth: 0 }}
                onClick={e => {
                  setIsModalConfirmDelete(true);
                  setDetailData(data.original);
                }}
              >
                <i className="mdi mdi-delete text-danger"></i>
              </Button>
            </div>
          ),
        },
        {
          fixed: "left",
          headerClassName: "sticky-header",
          Header: "TestCode",
          accessor: "testCode",
          width: 170,
        },
        {
          fixed: "left",
          headerClassName: "sticky-header",
          Header: "TestName",
          accessor: "testName",
          width: 490,
        },
        {
          Header: "CommonName",
          accessor: "commonName",
          width: 180,
        },
        {
          Header: "MethodName",
          accessor: "methodName",
          width: 130,
        },
        {
          Header: "Category",
          accessor: "category",
          width: 200,
        },
        {
          Header: "PricePerTest",
          accessor: "pricePerTest",
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-end">
              {formatValueToMoneyVND(value)}
            </div>
          ),
          width: 120,
        },
        // {
        //   Header: "GroupTest",
        //   accessor: "groupTest",
        //   width: 200,
        // },
        {
          Header: "Xét nghiệm thương mại",
          accessor: "parentTest",
          width: 200,
        },
        {
          Header: "Note",
          accessor: "note",
          width: 220,
        },
      ]}
      disablePagination={false}
      style={{ height: "calc(100vh - 360px)" }}
    />
  );
};

export default Index;
