import { isEmpty, transform } from "lodash"
import * as moment from "moment"

export const selectCheckboxHandler = (rows, row, isSelected) => {
  const rowsState = [...rows]
  const currentIdx = rowsState.findIndex(_r => _r.id === row.id)
  // check if row exists then add to rowsState array otherwise remove it
  if (currentIdx >= 0) rowsState.splice(currentIdx, 1)
  else rowsState.push(row)

  // check if row selected then set to rowState object otherwise set undefined {}
  let currentRow = { ...row } || {}
  if (!isSelected) {
    if (rowsState.length > 0) currentRow = rowsState[rowsState.length - 1]
    else currentRow = {}
  }

  return { rowsState, currentRow }
}

export const convertDateFormat = (date, format) => {
  if (!date) return date
  const date1 = moment(new Date(date)).format(format || "YYYY-MM-DD HH:mm")
  return moment(new Date(date1)).isValid() ? date1 : date
}

export const spreadSearchQuery = searchQuery => {
  let q = "q="
  if (searchQuery)
    Object.keys(searchQuery).forEach(_key => {
      if (!isEmptyValues(searchQuery[_key])) {
        if (Array.isArray(searchQuery[_key])) {
          // extract array as array object
          const arrayParams = searchQuery[_key]
          let types = getQueryArray(arrayParams, _key)

          q += `&${types}`
        } else q += `&${_key}=${searchQuery[_key]}`
      }

      return _key
    })

  return q
}

const getQueryArray = (list, key) => {
  let results = ""
  list?.forEach(_query => (results += `${key}=${_query}&`))
  return results
}

export const indexCalculator = (page, size, idx) => {
  return (page - 1) * size + idx
}

export const getInvalidMessageI18n = (t, field) => {
  return t("message:InvalidError", {
    field: `${t(field)}`,
  })
}

// DeleteToggle functions
export const onDeleteToggle = ({
  rows,
  row,
  setConfirmModal,
  setWarningModal,
  setRowDelete,
}) => {
  let rowState = []
  // check if delete inline or multiple
  if (!isEmpty(row)) {
    //delete inline (single)
    rowState = [row]
    setRowDelete(row)
  } else {
    // delete multiple
    rowState = [...rows]
  }

  onConfirmToggle(rowState, setConfirmModal, setWarningModal)
}

const onConfirmToggle = (rowState, setConfirmModal, setWarningModal) => {
  if (!isEmpty(rowState)) {
    setConfirmModal(prev => !prev)
  } else setWarningModal(true)
}

// Delete Handler functions
export const onDelete = ({
  rowDelete,
  rows,
  onDeleteSingleRow,
  onDeleteMultipleRows,
}) => {
 
  let rowState = []
  // check if delete inline or multiple
  if (!isEmpty(rowDelete)) {   
    //delete inline (single)
    rowState = [rowDelete]
    onDeleteSingleRow(rowState)
  } else {
    // delete multiple
    rowState = [...rows]
    onDeleteMultipleRows(rowState)
  }
}

export const getI18nextLng = () => {
  return localStorage.getItem("i18nextLng") || "vi"
}

export const getInputChangedValue = event => {
  const input = event.target
  const type = input.type
  const value = type === "checkbox" ? input.checked : input.value

  const name = input.name

  return {
    name,
    value,
    type,
  }
}

export const isEmptyValues = value => {
  /* eslint-disable */
  return (
    value === undefined ||
    value === null ||
    value === NaN ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value?.trim().length === 0)
  )
}

export const ifNull = (value, defaultValue) => {
  return isEmptyValues(value) ? defaultValue || "" : value
}

export const isEmptyArray = array => {
  return array?.filter(_item => !isEmptyValues(_item)).length === 0
}

export const insertSpaces = string => {
  string = string.replace(/([a-z])([A-Z])/g, "$1 $2")
  string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
  return string
}

export const getUserInfoStorage = prop => {
  let userInfo = localStorage.getItem("userInfo")
  if (userInfo) {
    userInfo = JSON.parse(userInfo)
    return userInfo[prop]
  }
  return prop
}

export const getFirstLetterOfEachWords = str => {
  let acronym = str && str.split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")

  acronym =
    acronym?.length > 2
      ? acronym.charAt(0) + "" + acronym.charAt(acronym.length - 1)
      : acronym
  return acronym || ""
}

export const isRequiredError = (field, require, translation) =>
  require
    ? {
      required: true,
      errorMessage: getInvalidMessageI18n(translation, field),
      validate: {
        required: { value: true },
      },
    }
    : {}

export const getNumber = value => {
  return !isNaN(value) ? +value : value
}

export const lowercaseObjectKeys = obj => {
  return transform(obj, (result, val, key) => {
    result[key.toLowerCase()] = val
  })
}

export const compareSortArray = (a, b, order) => {
  if (order === "asc") {
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }
  // desc
  if (a < b) {
    return 1
  }
  if (a > b) {
    return -1
  }
  return 0
}

export const getTypeByDelimiter = type => {
  const underlineIdx = type.indexOf("_")
  return underlineIdx < 0 ? type : type.substring(0, underlineIdx)
}

export const replaceId = (field, force) => {
  const regexId = /Id$/,
    regexName = /Name$/

  return regexId.test(field)
    ? field?.replace(regexId, "Name")
    : force && !regexName.test(field)
      ? field + "Name"
      : field
}

export const concatObjectValueToString = (object, delimiter) => {
  if (isEmptyValues(object)) return ""
  return Object.values(object)
    .filter(value => !isEmptyValues(value))
    .join(delimiter || ", ")
}

export const scrollToTopRow = () => {
  try{
      const table = document.getElementsByClassName("ReactTable")[0];
    table
      .getElementsByClassName("rt-table")[0]
      .scrollTo(0,0);
    }catch(err) {
      console.log('Can not scroll to top:', err);
    }
}
