import React, { useState, useMemo } from "react";
import { Card, Button, Label, Input, Row, Col } from "reactstrap";
import Select from "react-select";
import DownloadFile from "components/Common/DownloadFile";
import UploadFile from "components/Common/UploadFile";
import { showToast } from "components/Common";
import {
  ExportMapInstrumentSurvey,
  ImportMapInstrumentSurvey,
} from "../../helpers/app-backend/instrumentsurveybackend_helpers";

const Index = ({
  isLoading,
  error,
  setError,
  sites,
  dataFilter,
  setDataFilter,
  getDataTable,
  paging,
}) => {
  const [isUpload, setIsUpload] = useState(false);
  const [isExportFile, setIsExportFile] = useState(false);
  const getExportApi = () => {
    return ExportMapInstrumentSurvey;
  };
  return (
    <>
      <Card className="p-3 mb-2">
        <Row>
          <Col sm={12} md={3} xl={3} className="mt-2">
            <div className="d-flex flex-column">
              <Label className="mb-1 text-truncate">SiteName</Label>
              <div style={{ zIndex: 4 }}>
                <Select
                  options={sites || []}
                  placeholder=""
                  value={dataFilter.siteName}
                  onChange={val => {
                    setDataFilter(prev => ({ ...prev, siteName: val }));
                  }}
                  isDisabled={isLoading}
                  isClearable
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={2} xl={2} className="mt-2">
            <div className="d-flex flex-column">
              <Label className="mb-1 text-truncate">InstrumentNameSurvey</Label>
              <div style={{ zIndex: 4 }}>
                <Input
                  value={dataFilter.instrumentNameSurvey}
                  disabled={isLoading}
                  style={{
                    borderColor:
                      error && error.instrumentNameSurvey ? "red" : "#ced4da",
                  }}
                  onChange={e => {
                    setDataFilter(prev => ({
                      ...prev,
                      instrumentNameSurvey: e.target.value,
                    }));
                    if (error) {
                      setError(null);
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={2} xl={2} className="mt-2">
            <div className="d-flex flex-column">
              <Label className="mb-1 text-truncate">
                MapMasterInstrumentID
              </Label>
              <div style={{ zIndex: 4 }}>
                <Input
                  value={dataFilter.mapMasterInstrumentID}
                  disabled={isLoading}
                  style={{
                    borderColor:
                      error && error.mapMasterInstrumentID ? "red" : "#ced4da",
                  }}
                  onChange={e => {
                    setDataFilter(prev => ({
                      ...prev,
                      mapMasterInstrumentID: e.target.value,
                    }));
                    if (error) {
                      setError(null);
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={5} xl={5} style={{ marginTop: 30 }}>
            <Button
              color="primary"
              className="btn-hover-shine mx-2"
              onClick={() => {
                getDataTable(dataFilter, paging);
              }}
              disabled={error || isLoading}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-search mr-1"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">
                  {isLoading ? "Đang tìm..." : "Tìm kiếm"}
                </p>
              </div>
            </Button>
            <Button
              color="warning"
              className="btn-hover-shine mx-2"
              onClick={() => setIsExportFile(true)}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-download"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Download</p>
              </div>
            </Button>

            <Button
              color="success"
              className="btn-hover-shine mx-2"
              onClick={() => setIsUpload(true)}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-upload"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Upload file</p>
              </div>
            </Button>
          </Col>
        </Row>
      </Card>
      {isUpload && (
        <UploadFile
          isOpen={isUpload}
          fileSupport={"*.sql"}
          title={<span>Vui lòng chọn file cần import</span>}
          onClose={() => setIsUpload(false)}
          onStartUploadingFile={async formData => {
            try {
              const res = await ImportMapInstrumentSurvey(formData);

              if (res.error) {
                const errorTitle = res.error.title || "Lỗi không xác định";
                const errorDescription =
                  res.error.description ||
                  "Đã xảy ra lỗi trong quá trình upload file.";
                showToast(`${errorTitle}: ${errorDescription}`, 3);
                setIsUpload(false);
                return;
              }

              showToast(`Upload file thành công`, 1);
              setIsUpload(false);
              getDataTable(dataFilter, paging);
            } catch (error) {
              // Xử lý lỗi không mong đợi từ API
              const errorMessage =
                error?.response?.data?.message ||
                "Đã xảy ra lỗi không mong muốn. Vui lòng thử lại.";
              showToast(`Lỗi hệ thống: ${errorMessage}`, 3);
              setIsUpload(false);
            }
          }}
        />
      )}

      {isExportFile && (
        <DownloadFile
          isOpen={isExportFile}
          isUseFilter
          request={{
            siteName: dataFilter.siteName?.label,
            instrumentNameSurvey: dataFilter.instrumentNameSurvey,
            mapMasterInstrumentID: dataFilter.mapMasterInstrumentID,
            page: paging.currentPage,
            pageSize: paging.pageSize,
          }}
          serviceApi={getExportApi()}
          onClose={() => setIsExportFile(false)}
          fileName={`Danh sách khớp mã InstrumentSurvey`}
        />
      )}
    </>
  );
};

export default Index;
