export const reportType = {
  PATIENT: "PATIENT",
  RESULT: "RESULT",
  RESULT_INSTRUMENT: "RESULT_INSTRUMENT",
  RESULT_DEPARTMENT: "RESULT_DEPARTMENT",
  getList() {
    return [
      {
        label: "Theo bệnh nhân",
        value: this.PATIENT,
      },
      {
        label: "Theo xét nghiệm",
        value: this.RESULT,
      },
      {
        label: "Theo xét nghiệm chạy máy",
        value: this.RESULT_INSTRUMENT,
      },
      {
        label: "Theo xét nghiệm khoa phòng",
        value: this.RESULT_DEPARTMENT,
      },
    ];
  },
};
