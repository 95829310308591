import Select from "react-select";
import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import { useEffect, useState } from "react";
import SiteService from "services/Site";
import { DISPLAY_MONTH_YEAR_FORMAT } from "constant";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Index = ({
  sites = [],
  exportFilter,
  setExportFilter,
  reportTypesList = [],
  error,
  setError,
  isLoading,
  setIsLoading,
  setIsExportFile,
  setPaging,
  filter,
  setFilter,
  selectValue,
  setSelectValue
}) => {
  const [data, setData] = useState([]);
  // const [selectValue, setSelectValue] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await SiteService.getListMasterSite({
      search: "",
    });
    let result = res.map(
      item =>
        (item = {
          value: item.siteCode,
          label: item.siteName,
        })
    );
    // result.unshift({ value: "all", label: "Chọn tất cả" });
    setData(result);
  };

  const HandleSubmitSite = val => {
    let filterAll = val.filter(x => x.value == "all");
    if (filterAll.length > 0) {
      let resfilter = data.filter(item => {
        if (item.value != "all") return item.value;
      });
      setSelectValue(filterAll);
      setFilter(prev => ({
        ...prev,
        siteCode: resfilter.map(x => x.value),
      }));
    } else {
      setSelectValue(val);
      setFilter(prev => ({
        ...prev,
        siteCode: val.map(item => item.value),
      }));
    }
    
    setPaging(prev => ({ ...prev, currentPage: 1 }));
  };
  
  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={4} xl={3}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate fw-bold">SiteName</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 4 }}>
              <Select
                className="custom-select"
                isMulti
                options={data}
                placeholder="Chọn bệnh viện"
                value={selectValue}
                onChange={val => {
                  HandleSubmitSite(val);
                }}
                isDisabled={false}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>
        <Col sm={12} md={3} xl={6}>
          <div className="d-flex align-items-center">
            <div style={{width: "80px", display: "flex", alignItems: "center", justifyContent: "center"}}><Label className="mb-0 text-truncate fw-bold">Thời gian</Label></div>
            <div style={{ width: "100px", zIndex: 5}} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={
                  filter.dateFrom.getFullYear() == 1 ? "" : filter.dateFrom
                }
                onChange={date => {
                  if (date) {
                    setFilter(prev => ({ ...prev, dateFrom: date }));
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      dateFrom: new Date("0001-01-01"),
                    }));
                  }
                  if (error) {
                    setError(null);
                  }
                }}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                disabled={isLoading}
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                // onKeyDown={e => {
                //   if (e.keyCode === 13) {
                //     onSearch();
                //   }
                // }} 
              />
            </div>

            <i className="bx bxs-right-arrow"></i>
            <div style={{ width: "100px", zIndex: 5 }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={filter.dateTo.getFullYear() == 1 ? "" : filter.dateTo}
                onChange={date => {
                  if (date) {
                    setFilter(prev => ({ ...prev, dateTo: date }));
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      dateTo: new Date("0001-01-01"),
                    }));
                  }
                  if (error) {
                    setError(null);
                  }
                }}
                disabled={isLoading}
                showMonthYearPicker
                dateFormat={DISPLAY_MONTH_YEAR_FORMAT}
                popperPlacement="bottom"
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                // onKeyDown={e => {
                //   if (e.keyCode === 13) {
                //     onSearch();
                //   }
                // }}
              />
            </div>
          </div>
          {error && error.date ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.date}
            </p>
          ) : null}
        </Col>        
      </Row>
    </Card>
  );
};

export default Index;
