import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData, getBlobFile, postBlobFile } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/data-bv-google-drive`

const DownLoadFileFromDrive = fileId => { 
    var url = `${BASE_API_URL}/download-file?fileId=${fileId}`;   
    return getBlobFile(url);
}

const GetListMasterFileDataHospitalInGooleDrive = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/ls-master-file-google?${q}`,reqBody)
}

const PullAllData = (reqBody) => {    
    return post(`${BASE_API_URL}/pull-file`,reqBody)
}

const PullDataFileGoogleFrom = (reqBody) => {    
    return post(`${BASE_API_URL}/data-id-file`,reqBody)
}

export {
    DownLoadFileFromDrive,
    GetListMasterFileDataHospitalInGooleDrive,
    PullAllData,
    PullDataFileGoogleFrom
}