import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"

import AvField from "availity-reactstrap-validation/lib/AvField"

import RollbackButton from "./RollbackButton"
import { useDetectedChanges } from "helpers/hooks"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import classnames from "classnames"

const CustomDatePicker = props => {
  const {
    required,
    name,
    value,
    onChangeHandler: onChange,
    onChange2,
    label,
    readOnly,
    detected,
    format,
    maxDate,
    dateFormat = 'd-m-Y',
    enableTime = false,
    onlyTime = false,
    ...rest
  } = props
  const [defaultValue, setDefaultValue] = useState()
  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue)

  const onChangeHandler = (e, value) => {
    setDefaultValue(e[0])
    if (onChange)
      onChange({
        value: e[0],
        name,
      })
    if (onChange2)
      onChange2({
        value: e[0],
        name,
      })
  }

  const onUndoHandler = () => {
    setDefaultValue(valueInput)
  }

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  const option = {
    allowInput: !readOnly,
    altFormat: dateFormat,
    dateFormat: dateFormat,
    maxDate: maxDate || null,
    time_24hr: true,
    enableTime: enableTime,
    closeOnSelect: true,
  }
  if (onlyTime == true) {
    option.mode = 'time'
  }
  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <Flatpickr

        className={classnames(
          { "has-changed": isChanged && detected },
          "form-control d-block"
        )}
        placeholder={format}
        value={defaultValue}
        onChange={onChangeHandler}
        options={option}
      />
      <AvField type="hidden" name={name} value={defaultValue} {...rest} />
    </>
  )
}

Number.propTypes = {
  onChangeHandler: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.any,
  detected: PropTypes.bool,
  readOnly: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
}

export default CustomDatePicker
