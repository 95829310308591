import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/KamRevenue`;

const getCustomers = () => {
  return get(`${BASE_API_URL}/customers`);
};

const getEmployees = () => {
  return get(`${BASE_API_URL}/employees`);
};

const getRegions = () => {
  return get(`${BASE_API_URL}/regions`);
};

const getDataEfficiency = data => {
  return post(`${BASE_API_URL}/investmentEfficiency`, data);
};

const getDataRevenueByCustomer = data => {
  return post(`${BASE_API_URL}/revenueByCustomer`, data);
};
const getDataRevenueByGroupProduct = data => {
  return post(`${BASE_API_URL}/revenueByGroupProduct`, data);
};

const getDashboardRevenueBySector = data => {
  return post(`${BASE_API_URL}/getDashboardRevenueBySector`, data);
};

const getDashboardRevenueByKAM = data => {
  return post(`${BASE_API_URL}/getDashboardRevenueByKAM`, data);
};


export {
  getCustomers,
  getEmployees,
  getRegions,
  getDataEfficiency,
  getDataRevenueByCustomer,
  getDataRevenueByGroupProduct,
  getDashboardRevenueBySector,
  getDashboardRevenueByKAM,
};
