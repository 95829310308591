import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import { showToast } from "components/Common";
import StatisticService from "services/Statistic";
import TableDetail from "./TableDetail";
import FilterDetail from "./FilterDetail";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import { startOfMonth, format  } from 'date-fns';
const Index = ({ isOpen, onClose, data, sites, selectedSites, parentFilter }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSelectSite, setDataSelectSite] = useState([]);
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);
  const [filter, setFilter] = useState({
    siteCode: selectedSites.map(item => item.value),
    commonName: "",
    masterTestcode: "",
    statMonthFrom: parentFilter.dateFrom,
    statMonthTo: parentFilter.dateTo,
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        groupTestCode: data.groupTestCode,
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        siteCode: filter.siteCode || [],
        commonName: filter.commonName,
        statMonthFrom: format(startOfMonth(filter.statMonthFrom), "yyyy-MM-dd"),
        statMonthTo: format(startOfMonth(filter.statMonthTo), "yyyy-MM-dd"),
        masterTestcode: filter.masterTestcode,
        ...requestBody,
      },
    };
    return reqParams;
  };
  
  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await StatisticService.GetListDetailCombineStatMonthlyResultSaleByGroupTestService(
          params,
          requestBody
        );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };
  useEffect(() => {
    if (!tableData || !data) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData || data]);

  useEffect(() => {
    getTableData();
  }, [paging]);

  // Mặc định lấy all sites
  useEffect(() => {
    if (!sites || !sites.length) return;
    let valueArr = selectedSites.map(site => site.value);
    setFilter(prev => ({ ...prev, siteCode: valueArr }));
    // sites.unshift({ value: "all", label: "Chọn tất cả" });
    setDataSelectSite(sites);
  }, [sites]);
  
  return (
    <Modal
      isOpen={isOpen}
      style={{
        width: "80%",
        maxWidth: "1600px",
        height: "100%",
        // maxHeight: "1000px",
      }}
      toggle={onClose}
    >
      <ModalHeader
      toggle={onClose}
      >
        Chi tiết thống kê doanh số xét nghiệm bộ XN {data.groupTestCode} -{" "}
        {data.groupTestName}
      </ModalHeader>
      <ModalBody>
        <FilterDetail
          sites={sites}
          filter={filter}
          setFilter={setFilter}
          selectValue={selectedSites}
          setSelectValue={setDataSelectSite}
          isLoading={loading}
          setPaging={setPaging}
          onSearch={() => {
            setPaging(prev => ({ ...prev, currentPage: 1 }));
            getTableData({ page: 1 });
          }}
          parentFilter={parentFilter}
        />
        <TableDetail
          paging={paging}
          pageSize={paging.pageSize}
          setPaging={setPaging}
          data={tableData}
        />
        <div className="d-flex w-80 justify-content-end">
          <Button color="danger" onClick={onClose}>
            Đóng
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Index;
