import axios from "axios";
import { get, put, post, del, delData, postBlobFile } from "../api_helper";
import ApiCaller from "ApiCaller";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/StatisticsSurveyResult`;

const getStatisticsSurveyResult = data => {
  return post(`${BASE_API_URL}/GetListStatisticsSurveyResult`, data);
};

const ExportStatisticsSurveyResult = data => {
  return post(`${BASE_API_URL}/ExportStatisticsSurveyResult`, data, {
    responseType: "blob",
  });
};

const ImportStatisticsSurveyResult = async (data) => {
  return await ApiCaller.post(
    `${BASE_API_URL}/ImportStatisticsSurveyResult`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    }
  );
};
// const ImportStatisticsSurveyResult = data => {
//   console.log('ImportStatisticsSurveyResult=', data)
//   return axios.post(`${BASE_API_URL}/ExportStatisticsSurveyResult`, data,{
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//     responseType: "blob",
//   });
// };

export {
  getStatisticsSurveyResult,
  ExportStatisticsSurveyResult,
  ImportStatisticsSurveyResult,
};
