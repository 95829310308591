import React, { useEffect, useState } from "react";

import { ModalBody, Row, Col, ModalFooter } from "reactstrap";
import * as moment from "moment";
import {
  CustomSelect,
  CustomModal,
  CustomButton,
  CustomAvField,
  CustomCheckbox,
  CustomSelectGroup,
  CustomDatePicker,
  NumberInput,
  CustomBootstrapTable,
} from "components/Common";
//import CustomBootstrapTable from "components/Common/CustomTable/CustomBootstrapTable"
import { AvInput, AvField, AvForm } from "availity-reactstrap-validation";
import { parameterCode } from "constant/utility";
// import { uploadFile,getAllTestCodes, updateMapMasterTestcode,getListMapTestCode  } from "helpers/app-backend"

//i18n
import { withTranslation } from "react-i18next";
import { getInvalidMessageI18n } from "helpers/utilities";
import { isEmpty } from "lodash";
import { showToast } from "components/Common";
import toastr from "toastr";

export const convertDateFormat = (date, format) => {
  if (!date) return date;
  const date1 = moment(new Date(date)).format(format || "YYYY-MM-DD HH:mm");
  return moment(new Date(date1)).isValid() ? date1 : date;
};

const SiteModal = ({
  isAdmin,
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const [siteCodeId, setSiteCodeId] = useState();
  const [siteCodeName, setSiteCodeName] = useState();
  const [type, setType] = useState();
  const [testCodeMasterOptions, setTestCodeMasterOptions] = useState([]);
  const [listMapTestCode, setListMapTestCode] = useState([]);
  const [paging, setPaging] = useState({ page: 1, size: 10, totalPages: 0 });

  const isPasswordRequired = field =>
    !isEdit
      ? {
          required: true,
          errorMessage: getInvalidMessageI18n(t, field),
          validate: {
            required: { value: true },
          },
        }
      : {};

  const columns = [
    {
      dataField: "testCodeLis",
      text: t("Mã Xét Nghiệm"),
    },
    {
      dataField: "testNameLis",
      text: t("Tên Xét Nghiệm"),
    },
    {
      dataField: "mapMasterTestcode",
      text: t("Master Test"),

      formatter: (cellContent, param, index) => (
        <>
          <CustomSelect
            style={{ width: "200px" }}
            name="testCode"
            value={param?.mapMasterTestcode || ""}
            // options={testCodeMasterOptions}
            required
            label={t("")}
            valueName={""}
            onChange={(e, values) => {
              updateMapMaster(param.testCodeLis, values[0]);
            }}
          />
          {/* <AvForm>
                        <AvField
                            name="search"
                            type="text"
                            className="form-site"
                            value={param.mapMasterTestcode || ""}
                           
                        />
                    </AvForm> */}
        </>
      ),
    },
  ];

  // useEffect(() => {

  // }, [data]);

  const GetListMapTestCodeInfo = async payload => {
    const res = await getListMapTestCode({ ...payload, search: data.siteCode });
    if (res.isSuccess) {
      setPaging({ ...res, dataSize: res.totalElements });
      setListMapTestCode(res.data);
    } else {
      setListMapTestCode([]);
    }
  };

  const GetTestCodeMasters = async () => {
    const res = await getAllTestCodes({ page: 1, size: 1000 });
    if (res.isSuccess) {
      setTestCodeMasterOptions(
        res.data.map(x => ({
          value: x.testCode,
          label: x.testName,
        }))
      );
    } else {
      setTestCodeMasterOptions([]);
    }
  };

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Site");
  if (isEdit && isEmpty(data)) return null;

  const fileChange = async event => {
    if (data == undefined) return;
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    uploadFile(data.siteCode, formData)
      .then(objRespone => {
        if (objRespone.data.isSuccess) {
          showToast(`Upload Thành công`, 1);
        } else {
          showToast(objRespone.data.err.msgString, 3);
        }
      })
      .catch(ex => {});
  };

  const updateMapMaster = async (testCodeLis, mapMasterTestcode) => {
    if (data == undefined) return;
    const res = await updateMapMasterTestcode(data.siteCode, {
      testCodeLis: testCodeLis,
      mapMasterTestcode: mapMasterTestcode,
    });
    if (res.isSuccess) {
      showToast(`Upload Thành công`, 1);
    } else {
      showToast(res.err.msgString, 3);
    }
  };

  const onSizePerPageChange = size => {
    GetListMapTestCodeInfo({ page: 1, size });
  };

  const onPageChange = page => {
    GetListMapTestCodeInfo({ size: paging.size, page });
  };

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle} size="xl">
      <ModalBody>
        <AvForm
          ref={formEl}
          id="siteForm"
          onValidSubmit={(e, values) => {
            // console.log(e, values);
            onValidSubmit(e, values)
          }}
          model={data}
        >
          {!isEdit && (
            <CustomSelect
              code={parameterCode.ERP_CUSTOMERS}
              style={{ width: "200px" }}
              name="siteCode"
              value={""}
              // options={testCodeMasterOptions}
              label={t("Gợi ý tên Site")}
              valueName={"siteName"}
              readOnly={isEdit}
              onChange={(e, values, labels) => {
                data.siteCode = values[0]?.trim();
                data.siteName = labels[0]?.trim();
                setSiteCodeId(values[0]?.trim());
                setSiteCodeName(labels[0]?.trim());
              }}
            />
          )}

          <CustomAvField name="uuid" type="hidden" value={data.uuid || ""} />
          <Row>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="siteCode"
                  type="text"
                  value={siteCodeId || data.siteCode || ""}
                  // value={data.siteCode || ""}
                  label={t("Site Code")}
                  errorMessage={getInvalidMessageI18n(t, "Site Code")}
                  // validate={{
                  //   required: { value: true },
                  // }}
                  readOnly={isEdit}
                  detected={isEdit}
                  maxLength={100}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="siteName"
                  type="text"
                  value={siteCodeName || data.siteName || ""}
                  errorMessage={getInvalidMessageI18n(t, "Site Name")}
                  validate={{
                    required: { value: true },
                  }}
                  label={t("Site Name")}
                  detected={isEdit}
                  maxLength={100}
                />
              </div>
            </Col>
            <CustomSelect
              code={parameterCode.SITES}
              style={{ width: "200px" }}
              name="parentSiteCode"
              value={data?.parentSiteCode || ""}
              options={testCodeMasterOptions}
              label={t("Parent Site Code")}
              valueName={""}
              readOnly={isEdit}
              onChange={(e, values) => {
              }}
            />
            <CustomSelect
              code={parameterCode.TYPE_SITE}
              style={{ width: "200px", marginTop: '15px' }}
              name="type"
              value={type || data?.type || ""}
              options={[]}
              label={t("Collection Type")}
              valueName={""}
              // readOnly={isEdit}
              onChange={(e, values) => {
                setType(values[0]);               
              }}
            />
            {/* <Col xs="12">
                            <div className="mb-3" style={!isEdit ? { display: 'none' } : {}}>
                            <CustomBootstrapTable
                                        hideSelectColumn={true}
                                        columns={columns}
                                        keyField={"testCodeLis"}
                                        selected={false}
                                        data={listMapTestCode || []}
                                        disableUpdateTime={true}
                                        onSort={() => { }}
                                        onPageChange={onPageChange}
                                        onSizePerPageChange={ onSizePerPageChange}
                                        resource={""}
                                        isScrollable={false}
                                        paging={paging}
                                    />
                            </div>

                        </Col> */}
            {/* <Col xs="6">
                            <div className="mb-3">
                                <div >
                                    <input style={!isEdit ? { display: 'none' } : {}}
                                        type="file" name="file" accept=".*" className="upload-file" onChange={(file) => { fileChange(file) }} />

                                </div>
                            </div>
                        </Col> */}
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => {
            formEl?.current?.submit();
          }}
          className="save-user"
          isEdit
        />
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          color="danger"
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["sitePage", "common"])(SiteModal);
