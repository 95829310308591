import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/Users`

// PHYSICIANS
const getAllUsers = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}

const getUserById = userID => {
  return get(`${BASE_API_URL}/GetUserById?userID=${userID}`)
}      
                                             
const createUser = data => {
  return post(`${BASE_API_URL}/CreateUser`,data)
}   

const updateUserById = data => { 
  return put(`${BASE_API_URL}/UpdateUser`, data)
}

const deleteUserById = userID => {
  return del(`${BASE_API_URL}/DeleteUser?userID=${userID}`)
}


export {
    getAllUsers,
    getUserById,
    createUser,
    updateUserById,
    deleteUserById,
}