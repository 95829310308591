import { combineAll } from "helpers/app-backend/combinebackend_helpers";
import HandleReponse from "utils/handleReponse";

const combineAllService = async () => {
    const res = await combineAll();
    return res;  
};

export default {
  combineAllService,
};
