import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/master-instrument`;

const UpdateMasterInstrumentApi = reqBody => {
  return put(`${BASE_API_URL}/`, reqBody);
};

const DeleteMasterInstrumentApi = reqBody => {
  return post(`${BASE_API_URL}/delete`, reqBody);
};

export { UpdateMasterInstrumentApi, DeleteMasterInstrumentApi };
