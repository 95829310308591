import {
  Card,
  Button,
  Row,
  Col,
  Label,
  Input,
  UncontrolledPopover,
  PopoverBody,
  Collapse,
  UncontrolledCollapse,
} from "reactstrap";
import Select from "react-select";
import { CustomButton } from "components/Common";
import { useState } from "react";

const Index = ({
  filter,
  setFilter,
  isLoading,
  onSearch,
  setIsModalInsertUpdate,
  setIsEdit,
  masterInstrumentType,
}) => {
  const [selectInstrumentType, setSelectInstrumentType] = useState({});
  return (
    <>
      <Card className="p-3 my-2">
        <div className="w-100 d-flex">
          <div
            style={{ maxWidth: "600px", minWidth: "300px", marginRight: "8px" }}
          >
            <Input
              value={filter.instrumentCode}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  onSearch();
                }
              }}
              placeholder="Tìm kiếm theo instrument..."
              onChange={e =>
                setFilter(prev => ({ ...prev, instrumentCode: e.target.value }))
              }
            />
          </div>
          <CustomButton
            id="filterPlus"
            color="warning"
            className="btn-hover-shine"
          >
            <div className="d-flex align-items-center ">
              <i className="mdi mdi-filter-plus"></i>
              <p className="mb-0 mx-1">Lọc +</p>
            </div>
          </CustomButton>
          <Button
            color="success"
            className="btn-hover-shine mx-2"
            onClick={() => {
              setIsEdit(false);
              setIsModalInsertUpdate(true);
            }}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle" style={{ fontSize: "1rem" }}></i>
              <p className="mx-1 mb-0">Thêm mới</p>
            </div>
          </Button>
        </div>
      </Card>

      <UncontrolledCollapse toggler="#filterPlus">
        <Card className="p-3 my-2">
          <Row className="mt-3">
            <Col md={6} lg={6} xl={5} className="mb-2">
              <div className="d-flex align-items-center">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "100px" }}
                >
                  ProducerCode
                </Label>
                <div style={{ marginLeft: "6px", width: "calc(100% - 200px)" }}>
                  <Input
                    value={filter.producerCode}
                    disabled={isLoading}
                    onChange={e => {
                      setFilter(prev => ({
                        ...prev,
                        producerCode: e.target.value,
                      }));
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        onSearch();
                      }
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col md={6} lg={6} xl={5}>
              <div className="d-flex align-items-center">
                <Label
                  className="mb-0 text-truncate fw-bold"
                  style={{ minWidth: "98px" }}
                >
                  InstrumentType
                </Label>
                <div style={{ display: "flex", zIndex: 6}}>
                  <Select
                    options={masterInstrumentType || []}
                    placeholder=""
                    value={selectInstrumentType}
                    onChange={val => {
                      setFilter(prev => ({
                        ...prev,
                        instrumentType: val.value,
                      }));
                      // onSearch();
                      setSelectInstrumentType(val);
                    }}
                    isDisabled={isLoading}
                    styles={{
                      control: base => ({
                        ...base,
                        border: `1px solid  "rgb(206, 212, 218)"`,
                        maxWidth: "300px",
                        width: "300px",
                      }),
                    }}
                  />
                  {selectInstrumentType && (
                    <Button
                      onClick={() => {
                        setFilter(prev => ({
                          ...prev,
                          instrumentType: "",
                        }));
                        // onSearch();
                        setSelectInstrumentType({});
                      }}
                      className="clear-button"
                      color="link"
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
            <Col md={12} lg={12} xl={2}>
              <Button
                color="primary"
                className="btn-hover-shine"
                onClick={onSearch}
                disabled={isLoading}
              >
                <div className="d-flex align-items-center">
                  <i
                    className="bx bx-search mr-1"
                    style={{ fontSize: "1rem" }}
                  ></i>
                  <p className="mx-1 mb-0">
                    {isLoading ? "Đang tìm.." : "Tìm kiếm"}
                  </p>
                </div>
              </Button>
            </Col>
          </Row>
        </Card>
      </UncontrolledCollapse>
    </>
  );
};

export default Index;
