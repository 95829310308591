import { AvInput } from "availity-reactstrap-validation"
import classnames from "classnames"
import { useDetectedChanges } from "helpers/hooks"
import { getI18nextLng } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, Label } from "reactstrap"
import RollbackButton from "./RollbackButton"

const CustomAvField = ({
  code,
  label,
  name,
  required,
  value,
  detected,
  id,
  className,
  onChangeDropdown,
  dropdownType,
  isClone,
  ...rest
}) => {
  let lang = getI18nextLng()
  const [defaultValue, setDefaultValue] = useState()
  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue)

  const [defaultValueDropdown, setDefaultValueDropdown] = useState()

  const [valueInputDropdown, isChangedDropdown] = useDetectedChanges(dropdownType, defaultValueDropdown)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('');
  const [dropdownLabel, setDropdownLabel] = useState('');
  const [items, setItems] = useState([]);

  const onChangeHandler = (e, value) => {
    const inputValue = value
    setDefaultValue(inputValue)
  }

  const onUndoHandler = () => {
    setDefaultValue(valueInput)
    onChangeDropdown(valueInputDropdown);
  }

  const onFocusHandler = event => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "new-password"
    }
  }

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  useEffect(() => {
    if (dropdownType) {
      ChangeDropdownHandler(dropdownType);
      setDefaultValueDropdown(dropdownType);
    }
  }, [dropdownType])

  const fetchOptions = useCallback(async (code) => {
    let res = []
    res = await fetchCodes(code)
    if (res.length > 0) {
      if (!isClone) {
        if (dropdownType) {
          onChangeDropdown(dropdownType);
        }
        else {
          onChangeDropdown(res[0].value)
          setDropdownValue(res[0].value);
          setDropdownLabel(res[0].label);
        }
      }
    }
    setItems(res)
  }, [])

  useEffect(() => {
    fetchOptions(code)
  }, [])

  const ChangeDropdownHandler = (value) => {
    let item = items.find(x => `${x.value}` === `${value}`)
    if (item) {
      setDropdownValue(item.value);
      setDropdownLabel(item.label);
      setDefaultValueDropdown(item.value)
    }
  }

  return (
    <>

      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          onClick={onUndoHandler}
          display={(isChanged || isChangedDropdown) && detected}
        />
      </div>
      <InputGroup>
        <AvInput
          name={name}
          id={id}
          value={defaultValue}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          className={classnames(
            { "has-changed": isChanged && detected },
            className
          )}
          {...rest}

        />
        <ButtonDropdown
          isOpen={isMenuOpen}
          toggle={() => setIsMenuOpen(prev => !prev)}
        >
          <Button outline type="button"
            onClick={() => setIsMenuOpen(prev => !prev)}
          >
            {items.find(x => `${x.value}` === `${dropdownType}`)?.label}
          </Button>
          <DropdownToggle
            outline
            split
          >
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
          </DropdownToggle>
          <DropdownMenu>
            {items.map((row, index) => <DropdownItem
              onClick={() => {
                ChangeDropdownHandler(row.value);
                onChangeDropdown(row.value);
              }}
              key={index}
            >
              {row.label}
            </DropdownItem>)}
          </DropdownMenu>
        </ButtonDropdown>
      </InputGroup>

    </>
  )
}

CustomAvField.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  required: PropTypes.bool,
  detected: PropTypes.bool,
  className: PropTypes.string,
  code: PropTypes.string.isRequired,
  onChangeDropdown: PropTypes.func,
  dropdownType: PropTypes.string,
  isClone: PropTypes.bool,
}

CustomAvField.defaultProps = {
  detected: false,
  className: "",
}

export default CustomAvField
