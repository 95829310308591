import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import Breadcrumb from "./Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { insertSpaces, isEmptyValues } from "helpers/utilities"

const CustomBreadcrumb = ({ t, resource, breadcrumbItem, isDetail, icon }) => {
  const { menus } = useSelector(state => ({
    menus: state.Authorization.menus,
  }))

  const [itemsState, setItemsState] = useState([])
  const [item, setItem] = useState(breadcrumbItem)

  const getBreadCrumb = menus => {
    const result = []
    // console.log(resource, menus)
    const level4 = menus.filter(_menu => _menu.pageCode === "_" + resource)?.[0]
    const level3 = menus.filter(_menu => _menu.pageCode === level4?.parent)?.[0]
    const level2 = menus.filter(_menu => _menu.pageCode === level3?.parent)?.[0]

    if (!isEmptyValues(level2))
      result.push({
        title: t(insertSpaces(level2.pageCode.substring(1))),
        link: "",
      })

    if (!isEmptyValues(level3))
      result.push({
        title: t(insertSpaces(level3.pageCode.substring(1))),
        link: "",
      })

    if (isDetail)
      if (!isEmptyValues(level4)) {
        result.push({
          title: breadcrumbItem,
          link: "/" + level4.pageCode.substring(1),
        })
        setItem(t("common:Detail"))
      }

    setItemsState(result)
  }

  useEffect(() => {
    if (menus?.length > 0) getBreadCrumb(menus)
  }, [menus])

  return <Breadcrumb items={itemsState} breadcrumbItem={item} icon={icon} />
}

CustomBreadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string.isRequired,
  t: PropTypes.func,
  resource: PropTypes.string,
  isDetail: PropTypes.bool,
}

CustomBreadcrumb.defaultProps = {
  breadcrumbItem: "",
}

export default withTranslation(["sidebar"])(CustomBreadcrumb)
