import axios from "axios";
import { get, put, post, del, delData, postBlobFile } from "../api_helper";
import ApiCaller from "ApiCaller";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/CategorySurvey`;

const GetListMapCategorySurvey = data => {
  return post(`${BASE_API_URL}/GetListMapCategorySurvey`, data);
};
const UpdateMapCategorySurveyManual = data => {
  return post(`${BASE_API_URL}/UpdateMapCategorySurveyManual`, data);
};
const ExportMapCategorySurvey = data => {
  return post(`${BASE_API_URL}/ExportMapCategorySurvey`, data, {
    responseType: "blob",
  });
};

const ImportMapCategorySurvey = async (data) => {
  return await ApiCaller.post(
    `${BASE_API_URL}/ImportMapCategorySurvey`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    }
  );
};

export {
    GetListMapCategorySurvey,
    ExportMapCategorySurvey,
    ImportMapCategorySurvey,
    UpdateMapCategorySurveyManual
};
