import React, { useEffect, useRef, useState, useMemo } from "react";
//i18n

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Filter from "./Filter";
import TableStatistics from "./TableStatistics";
import { getStatisticsSurveyResult } from "../../../helpers/app-backend/surveyresultbackend_helper";

const StatisticsSurveyResult = ({ t }) => {
  const userName = localStorage.getItem("userName");
  const [dataFilter, setDataFilter] = useState({
    siteName: "",
    masterCategoryName: "",
    masterProducerCode: "",
    companyName: "",
    masterInstrumentName: "",
    fromYear: "",
    toYear: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [dataTable, setDataTable] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const getDataChart = async (dataFilter, paging) => {
    const commonParams = {
      siteName: dataFilter.siteName,
      masterCategoryName: dataFilter.masterCategoryName,
      masterProducerCode: dataFilter.masterProducerCode,
      companyName: dataFilter.companyName,
      masterInstrumentName: dataFilter.masterInstrumentName,
      fromYear: dataFilter.fromYear,
      toYear: dataFilter.toYear,
      page: paging.currentPage,
      pageSize: paging.pageSize,
    };
    try {
      const response = await getStatisticsSurveyResult(commonParams);
      if (response.last) {
        setDataTable(response.data);
        setTotalRows(response.totalElements)
        return;
      }
      toastr.error("Đã có lỗi xảy ra");
      setDataTable([]);
    } catch (err) {
      toastr.error("Đã có lỗi xảy ra");
      setDataTable([]);
    }
  };

  const onChangeSubmitData = async () => {
    getDataChart(dataFilter, paging);
  };

  useEffect(() => {
    getDataChart(dataFilter, paging);
  }, [paging]);
  // useMemo(() => {
  //   getDataChart(dataFilter, paging);
  // }, []);

  return (
    <React.Fragment>
      <Filter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        onChangeSubmitData={onChangeSubmitData}
        paging={paging}
      />
      <TableStatistics
        dataTable={dataTable}
        setPaging={setPaging}
        paging={paging}
        totalRows={totalRows}
      />
    </React.Fragment>
  );
};

export default withRouter(
  withTranslation(["UserPage", "message"])(StatisticsSurveyResult)
);
