import React, { useEffect, useState } from "react";
import { Popover, Button, Space, Typography, Tooltip } from "antd";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from "@fortawesome/free-solid-svg-icons";

const CustomInput = ({ name, title, defaultValue, onChange }) => (
  <div className="d-flex align-items-center justify-content-between mb-1">
    <Typography>{title}</Typography>
    <Input
      size="sm"
      defaultValue={defaultValue}
      type="number"
      style={{ width: "60px" }}
      onChange={(e) => onChange(e, name)}
    />
  </div>
);

function WattageConfig({ config, setConfig }) {
  const [innerConfig, setInnerConfig] = useState(config);

  useEffect(() => {
    setInnerConfig(config);
  }, [config]);

  const onChange = (e, name) => {
    const newConfig = [...innerConfig];
    const idx = newConfig.findIndex((el) => el.name === name);
    if (idx > -1) {
      newConfig[idx].value = parseInt(e.target.value);
    }
    setInnerConfig(newConfig);
  };

  const content = (
    <Space direction="vertical" style={{ width: "200px" }}>
      {innerConfig.map((el) => (
        <CustomInput
          key={el.name} // Add a unique key prop here
          title={el.title}
          name={el.name}
          defaultValue={el.value}
          onChange={onChange}
        />
      ))}
      <div className="d-flex justify-content-center">
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setConfig(innerConfig);
            localStorage.setItem("wattageConfig", JSON.stringify(innerConfig));
          }}
        >
          Lưu
        </Button>
      </div>
    </Space>
  );

  return (
    <Popover content={content} title="Cấu hình công suất máy" trigger="click">
      <div className="mr-2 mt-1">
        <Tooltip title="Cấu hình công suất máy">
          <Button
            type="primary"
            shape="circle"
            icon={<FontAwesomeIcon icon={faTools} />}
          />
        </Tooltip>
      </div>
    </Popover>
  );
}

export default WattageConfig;
