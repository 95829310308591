import {
  Button,
  Popover,
  Switch,
  ColorPicker,
  Select,
  Collapse,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
// import { toast } from "react-toastify";
import { configKey } from "../constants";
import { ConfigManager } from "../utils";
import testTube from "../../../../assets/images/samplepath/test-tube.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";

const Content = ({ onChange, onSave, onCancel, config, onResetDefault }) => {
  const items = [
    {
      key: "1",
      label: "Cài đặt chung",
      children: (
        <>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Đánh dấu vị trí mẫu có thể đi</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                checked={config[configKey.RENDER_SECTION_BACKGROUND]}
                size="small"
                onChange={val => {
                  onChange(configKey.RENDER_SECTION_BACKGROUND, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Màu sắc vị trí</div>
            <div>
              <ColorPicker
                size="small"
                showText
                format="rgb"
                disabled={!config[configKey.RENDER_SECTION_BACKGROUND]}
                value={config[configKey.SECTION_BACKGROUND]}
                onChangeComplete={color => {
                  onChange(configKey.SECTION_BACKGROUND, color.toRgbString());
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Đánh dấu vị trí mẫu đã đi qua</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                checked={config[configKey.RENDER_SECTION_HIGHTLIGHT_BACKGROUND]}
                size="small"
                onChange={val => {
                  onChange(configKey.RENDER_SECTION_HIGHTLIGHT_BACKGROUND, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Màu sắc vị trí</div>
            <div>
              <ColorPicker
                size="small"
                showText
                format="rgb"
                disabled={
                  !config[configKey.RENDER_SECTION_HIGHTLIGHT_BACKGROUND]
                }
                value={config[configKey.SECTION_HIGHTLIGHT_BACKGROUND]}
                onChangeComplete={color => {
                  onChange(
                    configKey.SECTION_HIGHTLIGHT_BACKGROUND,
                    color.toRgbString()
                  );
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Hiển thị tên của vị trí</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                size="small"
                checked={config[configKey.RENDER_SECTION_TEXT]}
                onChange={val => {
                  onChange(configKey.RENDER_SECTION_TEXT, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Cỡ chữ</div>
            <div>
              <Select
                value={config[configKey.FONT_SIZE]}
                onChange={val => onChange(configKey.FONT_SIZE, val)}
                dropdownStyle={{
                  zIndex: 9999,
                }}
                size="small"
                disabled={!config[configKey.RENDER_SECTION_TEXT]}
                style={{ width: "100%" }}
                options={[
                  { value: "6px", label: "6pt" },
                  { value: "9px", label: "9pt" },
                  { value: "12px", label: "12pt" },
                  { value: "14px", label: "14pt" },
                  { value: "16px", label: "16pt" },
                  { value: "18px", label: "18pt" },
                  { value: "20px", label: "20pt" },
                  { value: "22px", label: "22pt" },
                  { value: "24px", label: "24pt" },
                  { value: "26px", label: "26pt" },
                  { value: "28px", label: "28pt" },
                  { value: "30px", label: "30pt" },
                  { value: "32px", label: "32pt" },
                ]}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Màu chữ tên vị trí</div>
            <div>
              <ColorPicker
                size="small"
                showText
                format="hex"
                disabled={!config[configKey.RENDER_SECTION_TEXT]}
                value={config[configKey.SECTION_TEXT_COLOR]}
                onChangeComplete={color => {
                  onChange(configKey.SECTION_TEXT_COLOR, `#${color.toHex()}`);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Thời gian chờ ở mỗi vị trí</div>
            <div>
              <Select
                value={config[configKey.DELAY_TIME]}
                onChange={val => onChange(configKey.DELAY_TIME, val)}
                dropdownStyle={{
                  zIndex: 9999,
                }}
                size="small"
                style={{ width: "70px" }}
                options={[
                  { value: 500, label: "0.5s" },
                  { value: 1000, label: "1s" },
                  { value: 2000, label: "2s" },
                  { value: 4000, label: "4s" },
                  { value: 6000, label: "6s" },
                  { value: 8000, label: "8s" },
                  { value: 10000, label: "10s" },
                ]}
              />
            </div>
          </div>
        </>
      ),
    },

    // ...(showSampleNumber
    //   ? [
    //       {
    //         key: "4",
    //         label: "Số lượng mẫu",
    //         children: (
    //           <>
    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Hiển thị số lượng mẫu trên máy</div>
    //               <div>
    //                 <Switch
    //                   className="mb-1"
    //                   checkedChildren="Bật"
    //                   unCheckedChildren="Tắt"
    //                   checked={config[configKey.RENDER_SAMPLE_NUMBER]}
    //                   size="small"
    //                   onChange={(val) => {
    //                     onChange(configKey.RENDER_SAMPLE_NUMBER, val);
    //                   }}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Kích thước hiển thị</div>
    //               <div>
    //                 <Select
    //                   value={config[configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER]}
    //                   onChange={(val) =>
    //                     onChange(configKey.SAMPLE_NUMBER_CIRCLE_DIAMETER, val)
    //                   }
    //                   dropdownStyle={{
    //                     zIndex: 9999,
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                   size="small"
    //                   style={{ width: "100%" }}
    //                   options={[
    //                     { value: 20, label: "20px" },
    //                     { value: 30, label: "30px" },
    //                     { value: 40, label: "40px" },
    //                     { value: 50, label: "50px" },
    //                     { value: 60, label: "60px" },
    //                   ]}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Màu sắc bình thường</div>
    //               <div>
    //                 <ColorPicker
    //                   size="small"
    //                   showText
    //                   format="hex"
    //                   value={config[configKey.SAMPLE_NUMBER_NORMAL_BG]}
    //                   onChangeComplete={(color) => {
    //                     onChange(
    //                       configKey.SAMPLE_NUMBER_NORMAL_BG,
    //                       `#${color.toHex()}`
    //                     );
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Màu sắc bình thường (hover)</div>
    //               <div>
    //                 <ColorPicker
    //                   size="small"
    //                   showText
    //                   format="hex"
    //                   value={config[configKey.SAMPLE_NUMBER_NORMAL_BG_HOVER]}
    //                   onChangeComplete={(color) => {
    //                     onChange(
    //                       configKey.SAMPLE_NUMBER_NORMAL_BG_HOVER,
    //                       `#${color.toHex()}`
    //                     );
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Màu sắc cảnh báo</div>
    //               <div>
    //                 <ColorPicker
    //                   size="small"
    //                   showText
    //                   format="hex"
    //                   value={config[configKey.SAMPLE_NUMBER_WARNING_BG]}
    //                   onChangeComplete={(color) => {
    //                     onChange(
    //                       configKey.SAMPLE_NUMBER_WARNING_BG,
    //                       `#${color.toHex()}`
    //                     );
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Màu sắc cảnh báo (hover)</div>
    //               <div>
    //                 <ColorPicker
    //                   size="small"
    //                   showText
    //                   format="hex"
    //                   value={config[configKey.SAMPLE_NUMBER_WARNING_BG_HOVER]}
    //                   onChangeComplete={(color) => {
    //                     onChange(
    //                       configKey.SAMPLE_NUMBER_WARNING_BG_HOVER,
    //                       `#${color.toHex()}`
    //                     );
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Cỡ chữ</div>
    //               <div>
    //                 <Select
    //                   value={config[configKey.SAMPLE_NUMBER_FONT_SIZE]}
    //                   onChange={(val) =>
    //                     onChange(configKey.SAMPLE_NUMBER_FONT_SIZE, val)
    //                   }
    //                   dropdownStyle={{
    //                     zIndex: 9999,
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                   size="small"
    //                   style={{ width: "90px" }}
    //                   options={[
    //                     { value: 8, label: "8pt" },
    //                     { value: 10, label: "10pt" },
    //                     { value: 12, label: "12pt" },
    //                     { value: 16, label: "16pt" },
    //                     { value: 20, label: "20pt" },
    //                     { value: 24, label: "24pt" },
    //                     { value: 28, label: "28pt" },
    //                   ]}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Màu chữ</div>
    //               <div>
    //                 <ColorPicker
    //                   size="small"
    //                   showText
    //                   format="hex"
    //                   value={config[configKey.SAMPLE_NUMBER_COLOR]}
    //                   onChangeComplete={(color) => {
    //                     onChange(
    //                       configKey.SAMPLE_NUMBER_COLOR,
    //                       `#${color.toHex()}`
    //                     );
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                 />
    //               </div>
    //             </div>

    //             <div className="d-flex justify-content-between align-items-center mb-2">
    //               <div>Màu viền</div>
    //               <div>
    //                 <ColorPicker
    //                   size="small"
    //                   showText
    //                   format="hex"
    //                   value={config[configKey.SAMPLE_NUMBER_BORDER_COLOR]}
    //                   onChangeComplete={(color) => {
    //                     onChange(
    //                       configKey.SAMPLE_NUMBER_BORDER_COLOR,
    //                       `#${color.toHex()}`
    //                     );
    //                   }}
    //                   disabled={!config[configKey.RENDER_SAMPLE_NUMBER]}
    //                 />
    //               </div>
    //             </div>
    //           </>
    //         ),
    //       },
    //     ]
    //   : []),
    {
      key: "2",
      label: "Đường đi của mẫu",
      children: (
        <>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Tính khoảng cách giữa các vị trí trên hệ</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                size="small"
                checked={config[configKey.IS_CALC_DISTANCE_IN_PROCESS]}
                onChange={val => {
                  onChange(configKey.IS_CALC_DISTANCE_IN_PROCESS, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Hiển thị đường đi của mẫu</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                size="small"
                checked={config[configKey.RENDER_PATH]}
                onChange={val => {
                  onChange(configKey.RENDER_PATH, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Màu đường đi</div>
            <div>
              <ColorPicker
                size="small"
                showText
                format="hex"
                value={config[configKey.PATH_COLOR]}
                disabled={!config[configKey.RENDER_PATH]}
                onChangeComplete={color => {
                  onChange(configKey.PATH_COLOR, `#${color.toHex()}`);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Độ rộng đường đi</div>
            <div>
              <Select
                value={config[configKey.PATH_WIDTH]}
                onChange={val => onChange(configKey.PATH_WIDTH, val)}
                dropdownStyle={{
                  zIndex: 9999,
                }}
                disabled={!config[configKey.RENDER_PATH]}
                size="small"
                style={{ width: "100%" }}
                options={[
                  { value: 1, label: "1px" },
                  { value: 2, label: "2px" },
                  { value: 3, label: "3px" },
                  { value: 4, label: "4px" },
                  { value: 5, label: "5px" },
                ]}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Tốc độ di chuyển của mẫu</div>
            <div>
              <Select
                value={config[configKey.SPEED]}
                onChange={val => onChange(configKey.SPEED, val)}
                dropdownStyle={{
                  zIndex: 9999,
                }}
                size="small"
                style={{ width: "70px" }}
                options={[
                  { value: 1, label: "x0.5" },
                  { value: 2, label: "x1" },
                  { value: 4, label: "x2" },
                  { value: 6, label: "x3" },
                  { value: 8, label: "x4" },
                ]}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div
              style={{
                padding: "20px 10px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#FFF",
                width: "90px",
                marginTop: "12px",
              }}
            >
              {config[configKey.RENDER_PATH] && (
                <div
                  style={{
                    width: "50px",
                    height: `${config[configKey.PATH_WIDTH]}px`,
                    backgroundColor: config[configKey.PATH_COLOR],
                  }}
                />
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Biểu tượng mẫu",
      children: (
        <>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Hiển thị biểu tượng mẫu</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                checked={config[configKey.RENDER_ICON]}
                size="small"
                onChange={val => {
                  onChange(configKey.RENDER_ICON, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Kích thước biểu tượng</div>
            <div>
              <Select
                value={config[configKey.ICON_SIZE]}
                onChange={val => onChange(configKey.ICON_SIZE, val)}
                dropdownStyle={{
                  zIndex: 9999,
                }}
                disabled={!config[configKey.RENDER_ICON]}
                size="small"
                style={{ width: "90px" }}
                options={[
                  { value: 8, label: "8x8" },
                  { value: 12, label: "12x12" },
                  { value: 16, label: "16x16" },
                  { value: 20, label: "20x20" },
                  { value: 24, label: "24x24" },
                  { value: 28, label: "28x28" },
                  { value: 32, label: "32x32" },
                  { value: 36, label: "36x36" },
                  { value: 40, label: "40x40" },
                  { value: 44, label: "44x44" },
                  { value: 48, label: "48x48" },
                  { value: 52, label: "52x52" },
                ]}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Hiển thị SID</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                checked={config[configKey.RENDER_SID]}
                size="small"
                onChange={val => {
                  onChange(configKey.RENDER_SID, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Màu chữ SID</div>
            <div>
              <ColorPicker
                size="small"
                showText
                format="hex"
                value={config[configKey.SID_COLOR]}
                onChangeComplete={color => {
                  onChange(configKey.SID_COLOR, `#${color.toHex()}`);
                }}
                disabled={!config[configKey.RENDER_SID]}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Hiển thị tên loại mẫu</div>
            <div>
              <Switch
                className="mb-1"
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                checked={config[configKey.RENDER_SAMPLE_TYPE]}
                size="small"
                onChange={val => {
                  onChange(configKey.RENDER_SAMPLE_TYPE, val);
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>Màu chữ loại mẫu</div>
            <div>
              <ColorPicker
                size="small"
                showText
                format="hex"
                value={config[configKey.SAMPLE_TYPE_COLOR]}
                onChangeComplete={color => {
                  onChange(configKey.SAMPLE_TYPE_COLOR, `#${color.toHex()}`);
                }}
                disabled={!config[configKey.RENDER_SAMPLE_TYPE]}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div
              style={{
                padding: "10px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                background: "#FFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              {config[configKey.RENDER_SAMPLE_TYPE] && (
                <p
                  style={{
                    color: config[configKey.SAMPLE_TYPE_COLOR],
                    fontSize: config[configKey.FONT_SIZE],
                    fontWeight: "bold",
                  }}
                  className="mb-1"
                >
                  HP
                </p>
              )}

              {config[configKey.RENDER_ICON] && (
                <img
                  src={testTube}
                  alt=""
                  style={{
                    width: config[configKey.ICON_SIZE] + "px",
                    height: config[configKey.ICON_SIZE] + "px",
                    marginBottom: "5px",
                  }}
                />
              )}

              {config[configKey.RENDER_SID] && (
                <p
                  style={{
                    color: config[configKey.SID_COLOR],
                    fontSize: config[configKey.FONT_SIZE],
                    fontWeight: "bold",
                  }}
                  className="mb-0"
                >
                  2023-1234
                </p>
              )}
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div style={{ width: "350px" }}>
      <div style={{ width: "100%", maxHeight: "300px", overflow: "auto" }}>
        <Collapse
          bordered={false}
          size="small"
          defaultActiveKey={["1"]}
          style={{ background: "#FAFAFA" }}
          items={items}
        />
      </div>

      <div className="d-flex justify-content-center mt-1">
        <Button type="text" onClick={onResetDefault}>
          Đặt về mặc định
        </Button>
      </div>

      <div className="d-flex align-items-center justify-content-center mt-2">
        <Button type="primary" size="small" className="mr-1" onClick={onSave}>
          Lưu lại
        </Button>
        <Button type="primary" size="small" danger onClick={onCancel}>
          Hủy
        </Button>
      </div>
    </div>
  );
};

const Index = ({
  config: initialConfig,
  setConfig: setNewConfig,
  isDrawing,
}) => {
  const iConfig = ConfigManager.getInstance();
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState(initialConfig);

  const onChange = (name, value) => {
    const cfg = { ...config };

    cfg[name] = value;

    setConfig(cfg);
  };

  const onCancel = () => {
    setConfig(initialConfig);
    setOpen(false);
  };

  const onSave = () => {
    iConfig.setConfig(config);
    setNewConfig(config);
    // toast.success("Lưu cài đặt thành công", { autoClose: 1000 });
  };

  const handleOpenChange = newOpen => {
    setOpen(newOpen);

    // Đặt lại setting về trước đó
    if (!newOpen) {
      setConfig(initialConfig);
    }
  };

  const onResetDefault = () => {
    setConfig(iConfig.getDefault());
    // toast.success("Đã chọn cài đặt mặc định", { autoClose: 1000 });
  };

  if (isDrawing)
    return (
      <div className="mr-2 mt-1">
        <Button
          shape="circle"
          disabled
          icon={<AiOutlineSetting style={{ marginBottom: "3px" }} />}
        />
      </div>
    );

  return (
    <Popover
      content={
        <Content
          config={config}
          onChange={onChange}
          onCancel={onCancel}
          onSave={onSave}
          onResetDefault={onResetDefault}
        />
      }
      title="Cài đặt"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <div className="mr-2 mt-1">
        <Tooltip title="Cài đặt">
          <Button
            type="primary"
            shape="circle"
            icon={<FontAwesomeIcon icon={faGlobeAmericas} />}
          />
        </Tooltip>
      </div>
    </Popover>
  );
};

export default Index;
