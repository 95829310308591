import { GetLsMasterCategory } from "helpers/app-backend/mastercategorybackend_helpers";
import HandleReponse from "utils/handleReponse";

const GetLsMasterCategoryService = async (paramters, reqBody) => {
  try {
    const res = await GetLsMasterCategory(paramters, reqBody);
    return res;
  } catch (err) {
    return err;
  }
};

export default {
  GetLsMasterCategoryService,
};
