class HandleReponse {
  static getListSuccess(res) {
    if (!res) {
      this.getListFail();
    }
    const { data = [], last = true, page = 0, size = 100, totalElements = 0, totalPages = 0 } = res;
    return {
      data,
      last,
      page,
      size,
      totalElements,
      totalPages,
    };
  }

  static getListFail() {
    return {
      data: [],
      last: true,
      page: 1,
      size: 100,
      totalElements: 0,
      totalPages: 0,
    };
  }

  static getByIDSuccess(res) {
    const result = { data: {}, error: null };
    result.data = res;
    return result;
  }

  static insertDataSuccess() {
    const result = { error: null };
    return result;
  }

  static catchJSONErrorReponse(response, defaultData = {}) {
    const result = { data: defaultData, error: null };      
    result.error = {
      title: response && response.data ? response.data.title : "Lỗi",
      description:
        response && response.data
          ? response.data.detail
          : "Đã có lỗi không xác định",
    };
    return result;
  }

  static catchJSONErrorReponse_v2(response, defaultData = {}) {
    const result = { data: defaultData, error: null };      
    result.error = {
      title: response && response.data.title ? response.data.title : "Lỗi",
      description:
        response && response.data
          ? response.data
          : "Đã có lỗi không xác định",
    };
    return result;
  }
}

export default HandleReponse;
