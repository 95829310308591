import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData, getBlobFile, postBlobFile } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/data-collection`

const ExportMapTestCodeData = siteCode => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/export-data-test-code?siteCode=${stecodeurl}`;   
    return getBlobFile(url);
}
const ExportStatMonth = ({siteCode, data}) => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-statmonth?siteCode=${stecodeurl}`;   
    return postBlobFile(url, data);
} 
const GetListDuplicateStatMonthlyResult = siteCode =>{
    return get(`${BASE_API_URL}/data-duplicate-result?siteCode=${siteCode}`)
}

const SyncDatabaseTestCode = (siteCode) => {
    return post(`${BASE_API_URL}/sync-data-testcode?siteCode=${siteCode}`)
}

const ExportPatientData = (siteCode, reqBody) => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-statmonth-patient?siteCode=${stecodeurl}`;   
    return postBlobFile(url, reqBody);
}

const ExportResultData = (siteCode, reqBody) => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-statmonth-result?siteCode=${stecodeurl}`;   
    return postBlobFile(url, reqBody);
}

const ExportResultInstrumentData = (siteCode, reqBody) => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-statmonth-resultinstrument?siteCode=${stecodeurl}`;   
    return postBlobFile(url, reqBody);
}

const ExportResultDepartmentData = (siteCode, reqBody) => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-statmonth-resultdepartment?siteCode=${stecodeurl}`;   
    return postBlobFile(url, reqBody);
}

export {
    ExportMapTestCodeData,
    GetListDuplicateStatMonthlyResult,
    SyncDatabaseTestCode,
    ExportStatMonth,
    ExportPatientData,
    ExportResultData,
    ExportResultInstrumentData,
    ExportResultDepartmentData,
}