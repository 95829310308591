import React, { useRef, useState, useEffect } from "react";
import StatisticService from "services/Statistic";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalDetail from "./Detail/ModalDetail";
import ModalDetailCommon from "./DetailByNameMaHang/ModalDetail";
import { showToast } from "components/Common";
import { startOfMonth, format } from "date-fns";
import { colorsSite, GetDataSetChartJS } from "./../constants";
// import Chart from "./Chart";
import Chart from "./../../../../components/Dashboards/HorizontalStackChart";
const Index = ({ sites, filterAll }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSelectSite, setDataSelectSite] = useState([]);
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);
  const [dataChart, setDataChart] = useState(null);
  const [paraDetail, setParaDetail] = useState([]);
  const [selectMasterCategoryCode, setSelectMasterCategoryCode] = useState([]);
  const [filter, setFilter] = useState({
    siteCode: filterAll.siteCode || [],
    masterCategoryCode: [],
    statMonthFrom: new Date("0001-01-01"),
    statMonthTo: new Date("0001-01-01"),
  });

  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });

  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [columnTable, setColumnTable] = useState([]);
  const [optionChart, setOptionChart] = useState({
    indexAxis: "y",
    layout: {
      padding: {
        // bottom: -100,
       
      },
    },
    scales: {      
      x: {
        beginAtZero: true,
        stacked: true,
        grid: {          
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
          color: "transparent",
        },
        afterFit: context => {
          context.height -= 10;
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        afterFit: context => {
          // context.width += 20;
        },
      },
      
    },
    maintainAspectRatio: false, 
    height: 700 
  });
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        siteCode: filterAll.siteCode || [],
        masterCategoryCode: filter.masterCategoryCode || [],
        statMonthFrom: format(startOfMonth(filterAll.dateFrom), "yyyy-MM-dd"),
        statMonthTo: format(startOfMonth(filterAll.dateTo), "yyyy-MM-dd"),
        ...requestBody,
      },
    };
    return reqParams;
  };
  const GetDataSetChartJS = (dataArr, propertiesLabel) => {
    const distinctProperties = [
      ...new Set(dataArr.flatMap(obj => Object.keys(obj))),
    ];
    const dataByProperty = {};

    distinctProperties.forEach(property => {
      dataByProperty[property] = dataArr
        .filter(obj => obj.hasOwnProperty(property))
        .map(obj => {
          return obj[property];
        });
    });

    const keys = Object.keys(dataByProperty);

    var dataSetChart = keys.map((key, index) => {
      const value = dataByProperty[key];
      let dataSet = {
        label: key == "1NULL" ? "(Trống)" : key,
        backgroundColor: colorsSite[index],
        data: value,
      };
      return dataSet;
    });

    let newdataSetChart = dataSetChart.slice(1);

    let dataChart = {
      labels: dataArr.map(x =>
        x[propertiesLabel] ? x[propertiesLabel] : "(Trống)"
      ),
      datasets: newdataSetChart,
    };
    return dataChart;
  };
  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await StatisticService.GetListStatisticStatMonthResultBySaleService(
          params,
          requestBody
        );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      // var filteredData = res.data.filter((obj) => {
      //   // Kiểm tra nếu có bất kỳ thuộc tính nào khác "TestName" không phải null hoặc 0
      //   return Object.keys(obj).some((key) => key !== "TestName" && key != "CommonName" && (obj[key] !== null && obj[key] !== 0));
      // });
      // res.data = filteredData;
      setTableData(res);
      const arrayLabel = res.data.map(obj => obj.CommonName ? obj.CommonName : "(Trống)");
      const dataByProperty = {};
      const distinctProperties = [
        ...new Set(res.data?.flatMap(obj => Object.keys(obj))),
      ];
      distinctProperties.forEach(property => {
        dataByProperty[property] = res.data
          .filter(obj => obj.hasOwnProperty(property))
          .map(obj => {
            return obj[property];
          });
      });

      const keys = Object.keys(dataByProperty);

      var dataSetChart = keys.map((key, index) => {       
        if(key != "all" && key != "CommonName" && key !== "TestName"){
          const value = dataByProperty[key];
          let dataSet = {
            label: key == "1NULL" ? "(Trống)" : key,
            backgroundColor: colorsSite[index],
            data: value,
            barPercentage: 0.5,
            // barThickness: 20,      
          };
          return dataSet;
        }       
      });     

      dataSetChart = dataSetChart.filter(item => item !== undefined);

      let dataChart = {
        labels: arrayLabel,
        datasets: dataSetChart,
      };
      setDataChart(dataChart);  
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    // getColumnTable();
    getTableData();
  }, [paging]);

  useEffect(() => {
    setPaging(prev => ({ ...prev, currentPage: 1 }));
    // setFilter(prev => ({ ...prev, siteCode: filterAll.siteCode }));
    getTableData();
  }, [filterAll, filter]);

  return (
    <>
      <Filter
        ref={timerRef}
        sites={sites}
        filter={filter}
        filterAll={filterAll}
        selectValue={dataSelectSite}
        setSelectValue={setDataSelectSite}
        setFilter={setFilter}
        isLoading={loading}
        setPaging={setPaging}
        onSearch={() => {
          setPaging(prev => ({ ...prev, currentPage: 1 }));
          // getColumnTable();
          getTableData({ page: 1 });
        }}
      />
      {!loading && dataChart && (
        <Chart loading={loading} data={dataChart} options={optionChart} />
      )}
      <Table
        colums={columnTable}
        paging={paging}
        pageSize={paging.pageSize}
        filter={filter}
        setPaging={setPaging}
        data={tableData}
        setSiteCodeDetail={setSiteCodeDetail}
        setIsOpenDetail={setIsOpenDetail}
        setIsOpenModalDetail={setIsOpenModalDetail}
        setParaDetail={setParaDetail}
      />
      {isOpenModalDetail && (
        <ModalDetail
          sites={sites}
          setParaDetail={setParaDetail}
          param={paraDetail}
          setSiteCodeDetail={setSiteCodeDetail}
          data={siteCodeDetail}
          isOpen={isOpenModalDetail}
          onClose={() => setIsOpenModalDetail(false)}
        />
      )}
      {isOpenDetail && (
        <ModalDetailCommon
          filter={filter}
          filterAll={filterAll}
          sites={sites}
          setParaDetail={setParaDetail}
          param={paraDetail}
          setSiteCodeDetail={setSiteCodeDetail}
          data={siteCodeDetail}
          isOpen={isOpenDetail}
          onClose={() => setIsOpenDetail(false)}
        />
      )}
    </>
  );
};

export default Index;
