import { useEffect, useRef, useState } from "react";
import Filter from "./filter";
import { fetchTATMachineInSystemApi } from "helpers/app-backend/autosystembackend_helpers";
import dayjs from "dayjs";
import { showToast } from "components/Common";
import WattageMachine from "./WattageMachine";
import MachineEvaluate24h from "./MachineEvaluate24h";
import SampleDistributedMachine from "./SampleDistributedMachine";
import { getAverageTimeData } from "../AutoSystem/helper";
import { customColors } from "./helper";

const Index = ({ sites }) => {
  const [error, setError] = useState(null);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 5,
  });
  const [filter, setFilter] = useState({
    site: null,
    fromDate: dayjs(),
    toDate: dayjs(),
  });
  const [wattageMachinesData, setWattageMachineData] = useState(null);
  const [wattageMachinesLegends, setWattageMachinesLegends] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [distributedChartData, setDistributedChartData] = useState(null);
  const [distributedChartMode, setDistributedChartMode] = useState("week");
 
  const getTAT = async params => {
    try {
      setCardData([]);
      setWattageMachineData(null);
      setWattageMachinesLegends([]);
      setDistributedChartData(null);
      setDistributedChartMode("week");
      await GetLsWattageMachinesLegends();
      await GetLsStatisticSystemEvaluate(params);
    } catch (e) {
      showToast(`Lỗi truy xuất dữ liệu ${e}`, 3);
      setWattageMachinesLegends([{ label: [], datasets: [] }]);
      setWattageMachineData({ label: [], datasets: [] });
    }
    setIsLoading(false);
  };

  const createDistributedChartData = async (rawData, mode) => {
    let params = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      siteCode: filter.site?.value,
      type: mode || distributedChartMode,
      // listMachine: filter.listMachine.map(x => x.value),
    };
    let apiData = rawData;
    if (!apiData) {
      const response = await fetchTATMachineInSystemApi(params);
      if (response.data) {
        apiData = response.data || [];
      } else {
        apiData = [];
      }
    }
    const categoriesSet = new Set();
    const seriesSet = new Set();

    apiData.forEach(el => {
      categoriesSet.add(el.label1);
      seriesSet.add(el.label2);
    });

    const categories = Array.from(categoriesSet);
    const seriesArr = Array.from(seriesSet);
    const series = seriesArr.map(el => ({
      name: el,
      data: Array(categories.length).fill(0),
    }));

    apiData.forEach(el => {
      const categoryIdx = categories.findIndex(cate => cate === el.label1);
      const seriesIdx = seriesArr.findIndex(seri => seri === el.label2);

      if (seriesIdx > -1 && categoryIdx > -1) {
        series[seriesIdx].data[categoryIdx] = el.value;
      }
    });

    setDistributedChartData({ categories, series });
  };

  const GetLsStatisticSystemEvaluate = async params => {
    let { chartData } = getAverageTimeData();
    const responseTotal = await fetchTATMachineInSystemApi(params);

    if (responseTotal.data) {
      let data = responseTotal.data;
      const totalSum = data.reduce((sum, item) => sum + item.value, 0);
      let index = 0;
      const transformedData = data.reduce((result, item) => {
        const label = item.label2;
        const existingIndex = result.findIndex(el => el.label === label);

        if (existingIndex !== -1) {
          // If label already exists, aggregate the value
          result[existingIndex].value += item.value;
        } else {
          // If label is new, add it to the result array
          result.push({
            label,
            value: item.value,
            color: customColors[index] || "#4d4d4d",
          });
          index += 1;
        }

        return result;
      }, []);

      transformedData.forEach(item => {
        item.percent = Math.round((item.value / totalSum) * 100);
      });
      chartData.labels = transformedData.map(
        x => `${x.value} test (${x.percent}%)`
      );
      chartData.datasets[0].data = transformedData.map(x => x.value);
      chartData.datasets[0].backgroundColor = transformedData.map(x => x.color);

      createDistributedChartData(data);
    } else {
      setDistributedChartData({ categories: [], series: [] });
    }
  };

  const GetLsWattageMachinesLegends = async () => {
    let params = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      siteCode: filter.site?.value,
      type: "hour",
    };
    let { chartData } = getAverageTimeData();
    const responseTotal = await fetchTATMachineInSystemApi(params);
    setWattageMachineData(null);
    if (responseTotal.data) {
      let data = responseTotal.data;
      const totalSum = data.reduce((sum, item) => sum + item.value, 0);
      let cardData = [
        {
          id: "totalTest",
          title1: `${totalSum} test`,
          title2: "Tổng số test xét nghiệm",
          value: `${Math.floor((totalSum / 1000) * 10) / 10}K`,
          background: "#FFF",
        },
      ];
      setCardData(cardData);
      let index = 0;
      const transformedData = data.reduce((result, item) => {
        const label = item.label2;
        const existingIndex = result.findIndex(el => el.label === label);

        if (existingIndex !== -1) {
          // If label already exists, aggregate the value
          result[existingIndex].value += item.value;
        } else {
          // If label is new, add it to the result array
          result.push({
            label,
            value: item.value,
            color: customColors[index] || "#4d4d4d",
          });
          index += 1;
        }

        return result;
      }, []);

      transformedData.forEach(item => {
        item.percent = Math.round((item.value / totalSum) * 100);
      });

      chartData.labels = transformedData.map(
        x => `${x.label}`
      );
      chartData.datasets[0].data = transformedData.map(x => x.value);
      chartData.datasets[0].backgroundColor = transformedData.map(x => x.color);
      let labelPie = transformedData.map(x => {
        return {
          id: x.label,
          color: x.color,
          averageTimeTitle: x.label,
        };
      });
      setWattageMachinesLegends(labelPie);
      setWattageMachineData(chartData);
    } else {
      setWattageMachinesLegends([{ label: [], datasets: [] }]);
      setWattageMachineData({ label: [], datasets: [] });
    }
  };

  const getData = async () => {
    let params = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      siteCode: filter.site?.value,
      type: "week",
    };
    setTimeout(() => {
      getTAT(params);
    }, 1500);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!sites || !sites.length) return;
    setFilter(prev => ({
      ...prev,
      site: sites[0],
    }));
    setError(null);
  }, [sites]);

  return (
    <div>
      <Filter
        setError={setError}
        setPaging={setPaging}
        error={error}
        sites={sites}
        xs={24}
        filter={filter}
        setFilter={setFilter}
        onSearch={getData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      {wattageMachinesLegends && wattageMachinesData && (
        <WattageMachine
          data={wattageMachinesData}
          legends={wattageMachinesLegends}
          cardData={cardData}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      )}
      {distributedChartData && (
        <SampleDistributedMachine
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          data={distributedChartData}
          mode={distributedChartMode}
          onChangeMode={value => {
            setDistributedChartData(null);
            createDistributedChartData(null, value);
            setDistributedChartMode(value);
          }}
        />
      )}

      <MachineEvaluate24h filter={filter} />
    </div>
  );
};

export default Index;
