import {
  Card,
  Button,
  Label,
  Input,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import Select from "react-select";
import { useState } from "react";

const Index = ({
  setIsEdit,
  setIsUpload,
  filter,
  setFilter,
  setSearch,
  isLoading,
  onSearch,
  error,
  setError,
  setIsShowModal,
  sites,
  onExportExcel,
  setIsShowModalInsert
}) => {

  const onClosePopover =() => {
    const el = document.getElementById('PopoverDownload');
    if(el) {
      el.click();
    }
  }

  return (
    <>
      <Card className="p-3 my-2">
        <div className="d-flex align-item-center">
          <div style={{ flex: 1, maxWidth: "405px" }}>
            <div className="d-flex align-items-center">
              <Label
                className="mb-0 text-truncate fw-bold"
                style={{ minWidth: "98px" }}
              >
                SiteName
              </Label>
              <div style={{ flex: 1, marginLeft: "6px", zIndex: 6 }}>
                <Select
                  options={sites || []}
                  placeholder=""
                  value={filter.site}
                  onChange={val => {
                    setFilter(prev => ({ ...prev, site: val }));
                  }}
                  isDisabled={isLoading}
                  styles={{
                    control:  base => ({
                      ...base,
                      border: `1px solid ${filter && !filter.site ? 'red' : 'rgb(206, 212, 218)'}`
                    }),
                  }}
                />
              </div>
            </div>
            <div className="position-relative">
              {filter && !filter.site ? (
                <p className="mb-0 position-absolute fixed-top" style={{ color: "red" }}>
                  Hãy chọn một SiteName
                </p>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              color="info"
              className="btn-hover-shine mx-2"
              onClick={() => setIsShowModal(true)}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-plus-circle"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Thêm mới Master Instrument</p>
              </div>
            </Button>

            <Button
              color="warning"
              className="btn-hover-shine"
              id="PopoverDownload"
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-download"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Download</p>
              </div>
            </Button>

            <UncontrolledPopover
              placement="bottom"
              target="PopoverDownload"
              trigger="legacy"
            >
              <PopoverBody>
                <Button
                  color="primary"
                  disabled={!filter.site}
                  onClick={() => {
                    onClosePopover();
                    onExportExcel({
                      fileName: 'File Map Instrument',
                      api: 'ExportSMRIMapInstrument'
                    });
                  }}
                  className="w-100 btn-hover-shine"
                >
                  <i className='bx bx-link-alt mx-1' ></i>
                  File Map Instrument
                </Button>
                <Button
                  color="success"
                  disabled={!filter.site}
                  onClick={() => {
                    onClosePopover();
                    onExportExcel({
                      fileName: 'File Map Instrument Full',
                      api: 'ExportMapInstrumentFull'
                    });
                  }}
                  className="w-100 btn-hover-shine my-1"
                >
                  <i className='bx bx-data mx-1' ></i>
                  File Map Instrument Full
                </Button>
                <Button
                  color="danger"
                  disabled={!filter.site}
                  onClick={() => {
                    onClosePopover();
                    onExportExcel({
                      fileName: 'File Map Instrument chưa khớp mã ERP',
                      api: 'ExportMapInstrumentERPNull'
                    })
                  }}
                  className="w-100 btn-hover-shine"
                >
                  <i className='bx bx-unlink mx-1'></i>
                  File Map Instrument chưa khớp mã ERP
                </Button>
              </PopoverBody>
            </UncontrolledPopover>

            <Button
              color="success"
              className="btn-hover-shine mx-2"
              disabled={!filter.site}
              onClick={() => setIsUpload(true)}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-upload"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Upload map mã ERP</p>
              </div>
            </Button>
            <Button
              style={{ backgroundColor: "#76229A" }}
              className="btn-hover-shine mx-2"
              disabled={!filter.site}
              onClick={() => {
                setIsShowModalInsert(true);
                setIsEdit(false)}
              }
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-cloud-upload"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">Thêm mới</p>
              </div>
            </Button>
          </div>
        </div>
      </Card>
      <Card className="p-3 mb-2">
        <div className="d-flex align-items-center">
          <Label className="mb-0 text-truncate fw-bold">InstrumentNameLis</Label>
          <div style={{ flex: 1, marginLeft: "6px", maxWidth: "300px" }}>
            <Input
              value={filter.instrumentNameLis}
              disabled={isLoading}
              style={{
                borderColor:
                  error && error.instrumentNameLis ? "red" : "#ced4da",
              }}
              onChange={e => {
                setSearch(prev => ({
                  ...prev,
                  instrumentNameLis: e.target.value,
                }));
                if (error) {
                  setError(null);
                }
              }}
              onKeyDown={(e) => {
                if(e.keyCode === 13){
                  onSearch();
                }
              }}
            />
          </div>
          <Label className="mb-0 text-truncate p-2 fw-bold">
            MapMasterInstrumentID
          </Label>
          <div style={{ flex: 1, marginLeft: "6px", maxWidth: "300px" }}>
            <Input
              value={filter.mapMasterInstrumentID}
              disabled={isLoading}
              style={{
                borderColor:
                  error && error.mapMasterInstrumentID ? "red" : "#ced4da",
              }}
              onChange={e => {
                setSearch(prev => ({
                  ...prev,
                  mapMasterInstrumentID: e.target.value,
                }));
                if (error) {
                  setError(null);
                }
              }}
              onKeyDown={(e) => {
                if(e.keyCode === 13){
                  onSearch();
                }
              }}
            />
          </div>
          <Button
            color="primary"
            className="btn-hover-shine mx-2"
            onClick={onSearch}
            disabled={error || isLoading}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-search mr-1" style={{ fontSize: "1rem" }}></i>
              <p className="mx-1 mb-0">
                {isLoading ? "Đang tìm..." : "Tìm kiếm"}
              </p>
            </div>
          </Button>
        </div>
      </Card>
    </>
  );
};

export default Index;
