import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "components/Common/InputText";
import { showToast } from "components/Common";
import InstrumentService from "services/Instrument";
import MasterTestCodeService from "services/MasterTestCode";
import Select from "react-select";
const Schema = Yup.object().shape({
  testCode: Yup.string().required("Không được bỏ trống"),
  pricePerTest: Yup.number(),
});

const Index = ({
  isOpen,
  onClose,
  masterGroupTest,
  setIsDone,
  data,
  isEdit,
  masterCategory,
  masterParentTest
}) => {
  const [loading, setIsLoading] = useState(false);
  const [selectGroupTest, setSelectGroupTest] = useState({});
  const [selectCategory, setSelectCategory] = useState({});
  const [selectParentTest, setSelectParentTest] = useState({});
  const formik = useFormik({
    initialValues: {
      testCode: data?.testCode || "",
      testName: data?.testName || "",
      commonName: data?.commonName || "",
      methodName: data?.methodName || "",
      category: data?.category || "",
      pricePerTest: data?.pricePerTest || undefined,
      note: data?.note || "",
      groupTest: data?.groupTest || "",
      parentTest: data?.parentTest || "",
    },
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {      
      setIsLoading(true);
      if (isEdit) {
        updateMasterTestCode(values);
      } else {
        addNewTestCode(values);
      }
    },
  });

  const addNewTestCode = async params => {
    const res = await MasterTestCodeService.InsertMasterTestCodeService(params);
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
    } else {
      showToast("Thêm thành công", 1);
      setIsDone(true);
      onClose();
    }
  };
  const updateMasterTestCode = async (_requestBody = {}) => {
    const res = await MasterTestCodeService.UpdateMasterTestCodeService(
      _requestBody
    );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
      setIsDone(true);
    } else {
      showToast("Cập nhật thành công", 1);
      setIsDone(true);
      onClose();
    }
  };
  useEffect(() => {
    if (isEdit) {
      let sel = masterGroupTest.filter(x => x.value == data?.groupTest);
      setSelectGroupTest(sel);
      let selCate = masterCategory.filter(x=>x.value ==data?.category);
      setSelectCategory(selCate);
      let selParent = masterParentTest.filter(x=>x.value ==data?.parentTest);
      setSelectParentTest(selParent);
    }
  }, []);
  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "950px", width: "100%" }}>
      <ModalHeader>Thêm mới Master TestCode</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6} lg={4}>
            <InputText
              label="TestCode"
              name="testCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.testCode}
              errors={formik.errors}
              disabled={loading || isEdit}
              isRequired
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="TestName"
              name="testName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.testName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="CommonName"
              name="commonName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.commonName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="MethodName"
              name="methodName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.methodName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <Label className="mb-0 text-truncate" style={{ minWidth: "98px" }}>
              Category
            </Label>
            <div style={{ display: "flex" }}>
              <Select
                options={masterCategory || []}
                placeholder=""
                value={selectCategory}
                onChange={val => {
                  formik.setFieldValue("category", val.value);
                  setSelectCategory(val);
                }}
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid  "rgb(206, 212, 218)"`,
                    maxWidth: "300px",
                    width: "300px",
                  }),
                }}
              />
              {selectCategory && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("category", null);
                    setSelectCategory({});
                  }}
                  className="clear-button"
                  color="link"
                >
                  <i className="mdi mdi-close"></i>
                </Button>
              )}
            </div>
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="PricePerTest"
              name="pricePerTest"
              onChange={e => {
                formik.setFieldValue("pricePerTest", e.value ? e.value : undefined);
                // formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.pricePerTest}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Note"
              name="note"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.fieldName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          {/* <Col md={6} lg={4}>
            <Label className="mb-0 text-truncate" style={{ minWidth: "98px" }}>
              GroupTest
            </Label>
            <div style={{ display: "flex" }}>
              <Select
                options={masterGroupTest || []}
                placeholder=""
                value={selectGroupTest}
                onChange={val => {
                  formik.setFieldValue("groupTest", val.value);
                  setSelectGroupTest(val);
                }}
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid  "rgb(206, 212, 218)"`,
                    maxWidth: "300px",
                    width: "300px",
                  }),
                }}
              />
              {selectGroupTest && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("groupTest", null);
                    setSelectGroupTest({});
                  }}
                  className="clear-button"
                  color="link"
                >
                  <i className="mdi mdi-close"></i>
                </Button>
              )}
            </div>
          </Col> */}
          <Col md={6} lg={4}>
            <Label className="mb-0 text-truncate" style={{ minWidth: "98px" }}>
              Xét nghiệm thương mại
            </Label>
            <div style={{ display: "flex" }}>
              <Select
                options={masterParentTest || []}
                placeholder=""
                value={selectParentTest}
                onChange={val => {
                  formik.setFieldValue("parentTest", val.value);
                  setSelectParentTest(val);
                }}
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid  "rgb(206, 212, 218)"`,
                    maxWidth: "300px",
                    width: "300px",
                  }),
                }}
              />
              {selectParentTest && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("parentTest", null);
                    setSelectParentTest({});
                  }}
                  className="clear-button"
                  color="link"
                >
                  <i className="mdi mdi-close"></i>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          color="primary"
          onClick={formik.handleSubmit}
        >
          Đồng ý
        </Button>{" "}
        <Button color="danger" onClick={onClose}>
          Đóng
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
