import Table, { getSTTCellData, formatNumber } from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({paging, setPaging, data, pageSize, setIsOpenModalDetail, setSiteCodeDetail}) => {
  return (
    <Table
    paging={paging}
    setPaging={setPaging}
    data={data}  
    pageSize={pageSize}
    columns={[
        {
          Header: "STT",
          accessor: "localLocationId",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSTTCellData(data, paging)}
            </div>
          ),
        },
        {
          Header: "Mã vị trí",
          accessor: "localLocationId",
          width: 150,
        },
        {
          Header: "Tên vị trí",
          accessor: "localLocationName",
          width: 350,
        },
        {
          Header: "Tống số XN",
          accessor: "countSum",
          Cell: data => (
            <div className="d-flex w-100 justify-content-end">{formatNumber(data.value)}</div>
          ),
          width: 250,
        },
        {
            Header: "Action",            
            width: 50,
            Cell: data => (
              <Button className="d-flex w-100 justify-content-center" color="link" onClick={(e)=>{
                setSiteCodeDetail(data.original)
                setIsOpenModalDetail(true);
              }}>
                Chi tiết
              </Button>
            ),
          },        
      ]}
      disablePagination = {false}
    />
  );
};

export default Index;
