import React, { useRef, useState, useEffect } from "react";
import StatisticService from "services/Statistic";
import Table from "./Table";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalDetail from "./Detail/ModalDetail";
import { startOfMonth, format } from "date-fns";
import PieChart from "components/Dashboards/PieChart";

const Index = ({ sites, filterAll }) => {
    const timerRef = useRef(null);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataSelectSite, setDataSelectSite] = useState([]);
    const [siteCodeDetail, setSiteCodeDetail] = useState(null);
    const [dataChart, setDataChart] = useState([]);
    const [optionChart, setOptionChart] = useState({});
    const [filter, setFilter] = useState({
        siteCode: filterAll.siteCode || [],
        dateFrom: filterAll.dateFrom,
        dateTo: filterAll.dateTo,
    });
    const [paging, setPaging] = useState({
        currentPage: 1,
        pageSize: 100,
    });
    const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
    const getRequestParams = (params = {}, requestBody = {}) => {
        const reqParams = {
            params: {
                page: paging.currentPage,
                size: paging.pageSize,
                ...params,
            },
            requestBody: {
                siteCode: filterAll.siteCode || [],
                dateFrom: format(startOfMonth(filterAll.dateFrom), "yyyy-MM-dd"),
                dateTo: format(startOfMonth(filterAll.dateTo), "yyyy-MM-dd"),
                ...requestBody,
            },
        };
        return reqParams;
    };

    const getTableData = (_params = {}, _requestBody = {}) => {
        const { params, requestBody } = getRequestParams(_params, _requestBody);
        setTableData(null);
        setLoading(true);

        timerRef.current = setTimeout(async () => {
            const res =
                await StatisticService.GetListStatisticStatMonthResultByParentTestService(
                    params,
                    requestBody
                );

            if (res.error) {
                showToast(`${res.error.title}: ${res.error.description}`, 3);
                setTableData([]);
                setLoading(false);
                return;
            }
            setTableData(res);
            let seriesChart = res.data?.map(x => x.countSum);
            let optionChart = {
                chart: {
                    width: 250,
                    type: "pie",
                },
                labels: res.data?.map(x => {
                    if (x.parentTest) {
                        return x.parentTest
                    }
                    return "(Trống)"
                }),
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
                fill: {
                    type: "gradient",
                },
                title: {
                    text: "Dashboard SL xét nghiệm - xét nghiệm thương mại",
                    style: {
                        fontFamily: "Roboto",
                    },
                },
            };
            setDataChart(seriesChart);
            setOptionChart(optionChart);
            setLoading(false);
            scrollToTopRow();
        }, DELAY_FETCH);
    };

    useEffect(() => {
        if (!tableData) return;
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, [tableData]);

    useEffect(() => {
        setPaging(prev => ({ ...prev, currentPage: 1 }));
        getTableData();
    }, [filterAll, filter]);

    return (
        <>
          {loading == false && (
            <div style={{ display: "flex", justifyContent: "center"}}>
              <PieChart options={optionChart} chartSeries = {dataChart}/>
            </div>
          )}
    
          <Table
            paging={paging}
            pageSize={paging.pageSize}
            setPaging={setPaging}
            data={tableData}
            setSiteCodeDetail={setSiteCodeDetail}
            setIsOpenModalDetail={setIsOpenModalDetail}
          />
          {isOpenModalDetail && (
            <ModalDetail
              sites={sites}
              selectedSites={filterAll.siteCode.map(item => ({label: sites.find(i => i.value === item).label, value: item}))}
              setSiteCodeDetail={setSiteCodeDetail}
              data={siteCodeDetail}
              isOpen={isOpenModalDetail}
              onClose={() => setIsOpenModalDetail(false)}
              parentFilter={filterAll}
            />
          )}
        </>
      );
};

export default Index;
