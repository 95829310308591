import {
  ConfirmModal,
  CustomButton,
  WarningModal,
  showToast,
  ProcessingModal,
  CustomModal,
} from "components/Common";
import { DELAY_FETCH, ModuleIds } from "constant";
import { onDelete, onDeleteToggle } from "helpers/utilities";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
//i18n
import Table, {
  getSeqByCellData,
  scrollToTopRow,
} from "components/Common/Table";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Input, Button } from "reactstrap";
import UsersModalAdd from "./Modal/UsersModalAdd";
import UsersModalEdit from "./Modal/UserModalEdit";
import {
  getAllUsers,
  deleteUserById,
} from "helpers/app-backend/userbackend_helpers";
import Switch from "react-switch";
import toastr from "toastr";

import PopConfirm from "../../components/Common/PopConfirm";
const RESOURCE = ModuleIds.User;

const Users = ({ t }) => {
  const timerRef = useRef(null);
  const [listUser, setListUser] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const userName = localStorage.getItem("userName");
  const [modal, setModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState({
    userID:"",
    userName: "",
    fullName: "",
    email: "",
    userUpdate: userName,
    isActive: false,
    isAdmin: false,
  });
  const [isClone, setIsClone] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const formEl = useRef(null);

  const toggle = () => {
    setModal(prev => !prev);
  };
  const toggleEdit = () => {
    setOpenEdit(prev => !prev);
  };

  const onEditHandler = row => {
    setDataEdit({
      userID:row.userID,
      userName: row.userName,
      fullName: row.fullName,
      email: row.email,
      userUpdate: userName,
      isActive: row.isActive,
      isAdmin: row.isAdmin,
    });
    setOpenEdit(false);
    toggleEdit();
  };

  const addUserClicks = () => {
    setIsClone(false);
    toggle();
  };

  const onGetListUser = (options = {}) => {
    setListUser(null);
    timerRef.current = setTimeout(async () => {
      const res = await getAllUsers({
        page: paging.currentPage,
        size: paging.pageSize,
        search: filter.search,
        ...options,
      });

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setListUser([]);
        return;
      }
      setListUser(res);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  const onDeleteUserHandler = idSelect => {
    deleteUserById(idSelect)
      .then(() => {
        toastr.success("Xóa thành công");
        onGetListUser();
      })
      .catch(error => {
        console.error(error);
      });
  };
  const columns = [
    {
      filterable: false,
      resiable: false,
      sortable: false,
      fixed: "left",
      width: 50,
      Header: t("STT"),
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      accessor: "UserID",
      Header: t("Mã người dùng"),
      width: 100,
      Cell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {row._original.userID}
        </div>
      ),
    },
    {
      accessor: "UserName",
      Header: t("Tên đăng nhập"),
      width: 200,
      Cell: ({ row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {row._original.userName}
          </div>
        );
      },
    },
    {
      accessor: "FullName",
      Header: t("Họ và tên"),
      minWidth: 200,
      Cell: ({ row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {row._original.fullName}
          </div>
        );
      },
    },
    {
      accessor: "Email",
      Header: t("Email"),
      minWidth: 200,
      Cell: ({ row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {row._original.email}
          </div>
        );
      },
    },
    {
      Header: "Hoạt động",
      minWidth: 100,
      maxWidth: 100,
      accessor: "isActive",
      Cell: ({ value }) => (
        <div className="w-100 d-flex justify-content-center">
          <Switch disabled={true} checked={value} />
        </div>
      ),
    },
    {
      Header: "Is Admin",
      minWidth: 100,
      maxWidth: 100,
      accessor: "isAdmin",
      Cell: ({ value }) => (
        <div className="w-100 d-flex justify-content-center">
          <Switch disabled={true} checked={value} />
        </div>
      ),
    },
    {
      Header: "Thao tác",
      minWidth: 250,
      maxWidth: 340,
      accessor: "",
      Cell: row => {
        const uniqueId = `users-${row.original.userID}`;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="sm"
              color="link"
              onClick={() => onEditHandler(row.original)}
              className="me-3 my-0 p-0"
            >
              <i className="mdi mdi-pencil font-size-24 text-primary" />
            </Button>
            <PopConfirm
              id={uniqueId}
              onOK={() => {
                onDeleteUserHandler(row.original.userID);
              }}
              placement="bottom"
              type="danger"
              title={t("Cảnh báo")}
              content={t("Bạn có thực sự muốn xóa?")}
            >
              <Button size="sm" color="link" className="me-3 my-0 p-0">
                <i className="mdi mdi-trash-can font-size-24 text-danger" />
              </Button>
            </PopConfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    onGetListUser();
  }, [paging]);

  return (
    <React.Fragment>
      <Card className="p-2 mb-2">
        <div className="w-100 d-flex justify-content-end">
          <div
            style={{ maxWidth: "400px", minWidth: "200px", marginRight: "8px" }}
          >
            <Input
              value={filter.search}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  setPaging(prev => ({ ...prev, currentPage: 1 }));
                  onGetListUser({ page: 1 });
                }
              }}
              onBlur={e => {
                setPaging(prev => ({ ...prev, currentPage: 1 }));
                onGetListUser({ page: 1 });
              }}
              placeholder="Tìm kiếm..."
              onChange={e =>
                setFilter(prev => ({ ...prev, search: e.target.value }))
              }
            />
          </div>
          <CustomButton color="success" onClick={addUserClicks}>
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle"></i>
              <p className="mb-0 mx-1">{t("Thêm mới")}</p>
            </div>
          </CustomButton>
        </div>
      </Card>
      <Table
        paging={paging}
        setPaging={setPaging}
        data={listUser}
        pageSize={paging.pageSize}
        style={{ height: "calc(100vh - 280px)" }}
        columns={columns}
      />
      <UsersModalAdd
        formEl={formEl}
        modal={modal}
        toggle={toggle}
        onGetListUser={onGetListUser}
        setModal={setModal}
      />
      <UsersModalEdit
        setOpenEdit={setOpenEdit}
        openEdit={openEdit}
        toggleEdit={toggleEdit}
        data={[]}
        onGetListUser={onGetListUser}
        dataEdit={dataEdit}
        setDataEdit={setDataEdit}
      />
      {isProcessing && (
        <ProcessingModal
          isOpen={isProcessing}
          title={
            "Quá trình xử lí có thể mất vài phút, vui lòng chờ trong giây lát..."
          }
          size="500px"
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(withTranslation(["UserPage", "message"])(Users));
