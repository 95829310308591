import React, { useRef, useState, useEffect } from "react";
import StatisticService from "services/Statistic";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalDetail from "./Detail/ModalDetail";
import { startOfMonth, format } from "date-fns";
import Chart from "./Chart/BarChart";
const Index = ({ sites, filterAll }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSelectSite, setDataSelectSite] = useState([]);
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);
  const [filter, setFilter] = useState({
    siteCode: sites.map(site => site.value),
    dateFrom: new Date("0001-01-01"),
    dateTo: new Date("0001-01-01"),
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);

  const [optionsChart, setOptionsChart] = useState(null);
  const [dataChart, setDataChart] = useState(null);

  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        siteCode: filterAll.siteCode || [],
        dateFrom: format(startOfMonth(filterAll.dateFrom), "yyyy-MM-dd"),
        dateTo: format(startOfMonth(filterAll.dateTo), "yyyy-MM-dd"),
        ...requestBody,
      },
    };
    return reqParams;
  };
  const GetDataChart = data => {
    let labels = data?.map(x => x.localLocationName);
    let dataSeries = data?.map(x => x.countSum);
    const dataChart = {
      labels,
      datasets: [
        {
          axis: "y",
          label: "Số lượng xét nghiệm",
          data: dataSeries,
          borderColor: "rgb(1, 116, 190)",
          backgroundColor: "rgb(1, 116, 190, 0.5)",
          borderWidth: 1,
          // barPercentage: 0.5                           ,
          // barThickness: 20,
        },
      ],
    };
    setDataChart(dataChart);
    const options = {
      maintainAspectRatio: false,
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      scaleOverlay: {
        display: true,
        ticks: {
          min: 0,
          max: 100,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          maxBarThickness: 100,
        },
        y: {
          beginAtZero: true,
          maxItems: 5, // Set the maximum number of items to display
          maxTicksLimit: 5, // Limit the number of ticks
          scroll: {
            enabled: true, // Enable scrolling
          },
        },
      },
      scrollable: true,
      responsive: false,
      plugins: {
        legend: {
          position: "right",
        },
        title: {
          display: true,
          text: "Dashboard số lượng xét nghiệm",
        },
      },
    };

    setOptionsChart(options);
  };
  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await StatisticService.GetListStatMonthlyResultLocationTypeService(
          params,
          requestBody
        );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      GetDataChart(res.data);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging]);

  useEffect(() => {
    setPaging(prev => ({ ...prev, currentPage: 1 }));
    getTableData();
  }, [filterAll, filter]);

  return (
    <>
      {!loading && (
        <Chart loading={loading} data={dataChart} options={optionsChart} />
      )}

      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setSiteCodeDetail={setSiteCodeDetail}
        setIsOpenModalDetail={setIsOpenModalDetail}
      />
      {isOpenModalDetail && (
        <ModalDetail
          sites={sites}
          setSiteCodeDetail={setSiteCodeDetail}
          data={siteCodeDetail}
          isOpen={isOpenModalDetail}
          onClose={() => setIsOpenModalDetail(false)}
        />
      )}
    </>
  );
};

export default Index;
