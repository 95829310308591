import React, { useState, useRef, useEffect } from "react";
import Filter from "./Filter";
import Table from "./Table";
import ModalInsertUpdateMapCategorySurvey from "./ModalIUMapCategorySurvey";
import toastr from "toastr";

import { GetListMapCategorySurvey } from "../../helpers/app-backend/categorysurveybackend_helpers";

const Index = ({ sites }) => {
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [detailCategory, setDetailCategory] = useState([]);
  const [isShowManualInsertUpdate, setIsShowManualInsertUpdate] =
    useState(false);
  const [error, setError] = useState(null);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });

  const [dataFilter, setDataFilter] = useState({
    siteName: "",
    categoryNameSurvey: "",
    mapMasterCategoryID: "",
  });
  const [dataTable, setDataTable] = useState([]);

  const getDataTable = async (dataFilter, paging) => {
    const commonParams = {
      siteName: dataFilter.siteName?.label,
      categoryNameSurvey: dataFilter.categoryNameSurvey,
      mapMasterCategoryID: dataFilter.mapMasterCategoryID,
      page: paging.currentPage,
      pageSize: paging.pageSize,
    };
    try {
      const response = await GetListMapCategorySurvey(commonParams);
      console.log("response", response);
      if (response.last) {
        setDataTable(response);
        return;
      }
      toastr.error("Đã có lỗi xảy ra");
      setDataTable([]);
    } catch (err) {
      toastr.error("Đã có lỗi xảy ra");
      setDataTable([]);
    }
  };

  useEffect(() => {
    getDataTable(dataFilter, paging);
  }, [paging]);
  return (
    <>
      <Filter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        setIsUpload={setIsUpload}
        getDataTable={getDataTable}
        isLoading={loading}
        error={error}
        setError={setError}
        sites={sites}
        paging={paging}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={dataTable}
        setDetailCategory={setDetailCategory}
        setIsShowModalUpdate={setIsShowManualInsertUpdate}
      />
      {isShowManualInsertUpdate && (
        <ModalInsertUpdateMapCategorySurvey
          data={detailCategory}
          isOpen={isShowManualInsertUpdate}
          onClose={() => setIsShowManualInsertUpdate(false)}
          getDataTable={getDataTable}
          paging={paging}
          dataFilter={dataFilter}
        />
      )}
    </>
  );
};

export default Index;
