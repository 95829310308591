import { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import classnames from "classnames";
import React from "react";
// import {
//   getAllRoles,
//   getAllCategorys,
//   getAllUsers,
//   getAllProducts,
// } from "helpers/app-backend"
import {
  getAllSites,
  GetListCustomers,
  getAllParentSites,
} from "helpers/app-backend";
// import {
//   getAllRoles
// } from "helpers/fakebackend_helper"

import { getI18nextLng, isEmptyArray } from "helpers/utilities";
import { useDetectedChanges } from "helpers/hooks";

import { parameterCode } from "constant/utility";
import { Label } from "reactstrap";
import { RollbackButton } from ".";
import { isEmpty } from "lodash";

const CustomSelect = ({
  value,
  name,
  required,
  errorMessage,
  code,
  group,
  onChange,
  isDependent,
  isMulti,
  portal,
  label,
  detected,
  options,
  readOnly,
  searchQuery,
  showDefaultAll,
  style,
  valueName,
}) => {
  const [items, setItems] = useState([]);
  const [defaultValue, setDefaultValue] = useState();
  const inputRef = useRef();
  const [valueInput, isChanged] = useDetectedChanges(
    value,
    isMulti ? defaultValue : defaultValue?.[0]
  );

  const onUndoHandler = () => {
    onChangeHandler(
      isMulti ? (isEmpty(valueInput) ? [] : valueInput) : { value: valueInput }
    );
  };

  const isTouched = inputRef.current?.FormCtrl.isTouched();
  const isDirty = inputRef.current?.FormCtrl.isDirty();
  const isInvalid =
    !readOnly &&
    required &&
    isEmptyArray(defaultValue) &&
    (isTouched || isDirty);

  let lang = getI18nextLng();

  // COMMON CODES
  // const fetchCodes = async (code, group, searchQuery) => {
  //   const query = { lang, group, ...searchQuery }
  //   const res = await getCodesByParameterId(code, query);
  //   if (code === parameterCode.TEST_TYPES) {
  //     res?.map(_item => {
  //       _item.value = +_item.code
  //       _item.label = _item.message
  //       return _item
  //     })
  //   } else {
  //     res?.map(_item => {
  //       _item.value = _item.code
  //       _item.label = _item.message
  //       return _item
  //     })
  //   }
  //   if (code === parameterCode.SAMPLE_TYPES && showDefaultAll) {
  //     res.unshift({
  //       value: "--0--",
  //       label: 'All'
  //     })
  //   }
  //   return res
  // }

  // ROLES
  // const fetchRoles = async (isActive = false) => {
  //   const query = { size: 100 }
  //   const res = await getAllRoles(query)
  //   let data = []
  //   if (res.data) {
  //     if (isActive)
  //       data = res.data.filter(x => x.status == true).map(_item => {
  //         _item.value = _item.id
  //         _item.label = _item.rolename
  //         return _item
  //       })
  //     else
  //       data = res.data.map(_item => {
  //         _item.value = _item.id
  //         _item.label = _item.rolename
  //         return _item
  //       })
  //   }
  //   return data
  // }

  // CategoryS
  // const fetchCategorys = async () => {
  //   const query = { size: 100 }
  //   const res = await getAllCategorys(query)
  //   let data = []
  //   if (res.data)
  //     data = res.data.map(_item => {
  //       _item.value = _item.id
  //       _item.label = _item.name
  //       return _item
  //     })

  //   return data
  // }

  // ProductS
  // const fetchProducts = async searchQuery => {
  //   const query = { size: 10000, ...searchQuery }
  //   const res = await getAllProducts(query)
  //   let data = []
  //   if (res.data)
  //     data = res.data.map(_item => {
  //       _item.value = _item.id
  //       _item.label = _item.name  // + " ("  + _item.price + ") "
  //       return _item
  //     })

  //   return data
  // }

  // USERS
  // const fetchUsers = async () => {
  //   const query = { size: 100 }
  //   const res = await getAllUsers(query)
  //   let data = []
  //   if (res.data)
  //     data = res.data.map(_item => {
  //       _item.value = _item.id
  //       _item.label = _item.familyName + " " + _item.givenName
  //       return _item
  //     })

  //   return data
  // }

  // SEX
  const fetchSex = async () => {
    let data = [
      { value: 1, label: "Nam" },
      { value: 2, label: "Nữ" },
      { value: 3, label: "Khác" },
    ];
    return data;
  };

  //SITES
  const fetchSite = async search => {
    const query = { search };
    const res = await getAllParentSites(query);
    let data = [];
    if (res) {
      data = res.map(_item => {
        _item.value = _item.siteCode;
        _item.label = _item.siteName;
        return _item;
      });
    }
    return data;
  };

  //TYPE_SITE
  const fetchSiteType = async () => {
    const res = [{ value: "LIS", label: "LIS" }, { value: "Middleware", label: "Middleware" }];
    let data = [];
    if (res) {
      data = res.map(_item => {
        _item.value = _item.value;
        _item.label = _item.label;
        return _item;
      });
    }
    return data;
  };
  const fetchERPCustomers = async key => {
    const query = { key };
    const res = await GetListCustomers(query);
    let data = [];
    if (res.result.data) {
      data = res.result.data.map(_item => {
        _item.value = _item.value;
        _item.label = _item.label;
        return _item;
      });
    }
    return data;
  };
  const fetchOptions = useCallback(async (code, group, searchQuery) => {
    let res = [];
    if (code === parameterCode.ROLES) {
      res = await fetchRoles(searchQuery);
    } else if (code === parameterCode.CATEGORYS) {
      res = await fetchCategorys();
    } else if (code === parameterCode.PRODUCTS) {
      res = await fetchProducts(searchQuery);
    } else if (code === parameterCode.USERS) {
      res = await fetchUsers();
    } else if (code === "none") {
      // res = await fetchLinked()
    } else if (code === parameterCode.SITES) {
      res = await fetchSite();
    } else if (code === parameterCode.TYPE_SITE) {
      res = await fetchSiteType();
    } else if (code === parameterCode.ERP_CUSTOMERS) {
      res = await fetchERPCustomers();
    } else if (code.indexOf("_") >= 0 || code === parameterCode.PROFILE) {
      const profileId = code.substring(code.indexOf("_") + 1);
      if (profileId > 0) res = await fetchParties(profileId);
      else res = await fetchParties();
    } else {
      res = await fetchCodes(code, group, searchQuery);
    }

    setItems(res);
  }, []);

  const onChangeHandler = (e, b) => {
    var event = new Event("change", { bubbles: true });
    let element = document.getElementsByName(name)[0];
    let elementLabel;
    let label;
    if (code === parameterCode.ERP_CUSTOMERS) {
      element = document.getElementsByName(name)[1];
      elementLabel = document.getElementsByName(valueName)[0];
      label =
        (isMulti
          ? e?.map(_value => {
              return _value.label;
            })
          : [e?.label]) || [];
      elementLabel.value = label || "";
    }

    const value =
      (isMulti
        ? e?.map(_value => {
            return _value.value;
          })
        : [e?.value]) || [];

    element.value = value || "";

    element.dispatchEvent(event);

    setDefaultValue(value || "");
    onChange(name, value, label);
   
  };

  const isValidStyle = isInvalid && {
    borderColor: "#f46a6a !important",
    backgroundImage: `url(
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/></svg>"
  )`,
    backgroundPosition: "right 2.75rem center, center right 0.5rem",
    backgroundSize: "17px 18px, calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
    backgroundRepeat: "no-repeat",
  };

  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
      fontSize: "13px",
      cursor: isDisabled ? "not-allowed" : "default",
      ...isValidStyle,
      // ...isVaild,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: "13px",
        // color: "black",
        // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },

    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  };

  useEffect(() => {
    if (code && (!isDependent || (isDependent && group)))
      fetchOptions(code, group, searchQuery);
    else setItems([]);
  }, [code, group, isDependent, searchQuery]);

  useEffect(() => {
    setDefaultValue(isMulti ? value : [value]);
  }, [value]);

  useEffect(() => {
    if (!isEmpty(options)) setItems(options);
  }, [options]);

  return (
    <>
      <div style={style} className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <Select
        isClearable
        menuPlacement="auto"
        maxMenuHeight={150}
        menuPortalTarget={!portal && document.body}
        styles={colourStyles}
        isDisabled={readOnly}
        isMulti={isMulti}
        value={items.filter(
          _item =>
            defaultValue?.findIndex(
              _defaultValue =>
                JSON.stringify(_defaultValue + "") ===
                JSON.stringify(_item.value + "")
            ) >= 0
        )}
        onChange={onChangeHandler}
        options={items}
        classNamePrefix="select2-selection"
        className={classnames(
          { "has-changed": isChanged && detected },
          "form-select2 is-touched is-dirty av-invalid is-invalid"
        )}
      />
      {isInvalid && (
        <div className="text-danger form-group">
          <div className="is-touched is-dirty av-invalid is-invalid"></div>
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      )}
      <div className="d-none">
        <AvField
          multiple={isMulti}
          name={name}
          type="select"
          className={`form-select`}
          value={isMulti ? defaultValue : defaultValue?.[0]}
          errorMessage={errorMessage}
          required={!readOnly && required}
          ref={inputRef}
          readOnly={readOnly}
        >
          <option value="">--Select--</option>
          {items.map((_item, idx) => (
            <option key={idx} value={_item.value}>
              {_item.value}-{_item.label}
            </option>
          ))}
        </AvField>
      </div>
    </>
  );
};

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isDependent: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  group: PropTypes.string,
  code: PropTypes.string,
  isMulti: PropTypes.bool,
  portal: PropTypes.bool,
  label: PropTypes.any.isRequired,
  detected: PropTypes.bool,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  searchQuery: PropTypes.object,
  showDefaultAll: PropTypes.bool,
};

CustomSelect.defaultProps = {
  name: "",
  onChange: () => {},
  isDependent: false,
  errorMessage: "This field is invalid",
  portal: false,
  searchQuery: {},
};

export default CustomSelect;
