const chartList = [
  {
    value: 1,
    label: "Thời gian TAT trung bình trong lab",
  },
  {
    value: 2,
    label: "Tỉ lệ TAT trung bình từng bước trong quy trình theo thời gian",
  },
  {
    value: 3,
    label: "Thời gian TAT trung bình từng bước trong quy trình theo khung giờ",
  },
];

const color = {
  BLUE: '#008FFB',
  GREEN: '#00E396',
  YELLOW: '#feb019'
}

const initialCardData = [
  {
    id: "sampleLate",
    title1: "Số mẫu không đạt TAT",
    title2: "Không đạt TAT",
    value: null,
    background: "linear-gradient(180deg, #00E396, #00bf7f)",
  },
  {
    id: "sampleAlmostLate",
    title1: "Số mẫu cận TAT",
    title2: "Cận TAT",
    value: null,
    background: "linear-gradient(180deg, #008FFB, #0279d4)",
  },
  {
    id: "sampleOnTime",
    title1: "Số mẫu đạt TAT",
    title2: "Đạt TAT",
    value: null,
    background: "linear-gradient(180deg, #FEB019, #eb7f05)",
  },
  {
    id: "sampleTotal",
    title1: "",
    title2: "Tổng số lượng mẫu trên hệ",
    value: null,
    background: "linear-gradient(180deg, #FF4560, #ff1c3d)",
  },
];

const categoriesHour = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const nameListLabel = [
  "Chạy hệ - Ngoài giờ hành chính",
  "Chạy hệ - Buổi sáng",
  "Chạy hệ - Buổi chiều",
  "Chạy máy lẻ - Ngoài giờ hành chính",
  "Chạy máy lẻ - Buổi sáng",
  "Chạy máy lẻ - Buổi chiều",
];

export { chartList, color, initialCardData, categoriesHour, nameListLabel };
