import Table, { getSeqByCellData } from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setDataDetailInstrument,
  setIsEdit,
  setIsShowModalUpdate
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "instrumentIDLis",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        {
          Header: "InstrumentIDLis",
          accessor: "instrumentIDLis",
          width: 150,
        },
        {
          Header: "InstrumentNameLis",
          accessor: "instrumentNameLis",
          width: 250,
        },
        {
          Header: "MapMasterInstrumentID",
          accessor: "mapMasterInstrumentID",
          width: 250,
        },
        {
          Header: "ERPCode",
          accessor: "erpCode",
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                background: !rowInfo
                  ? null
                  : rowInfo.row.erpCode
                  ? null
                  : "#F2DEDE",
              },
            };
          },
          width: 140,
        },
        {
          Header: "Category",
          accessor: "category",
          width: 180,
        },
        {
          Header: "Action",
          width: 50,
          Cell: data => (
            <Button
              className="d-flex w-100 justify-content-center"
              color="link"
              onClick={e => {
                setIsEdit(true);
                setIsShowModalUpdate(true);
                setDataDetailInstrument(data.original);                
              }}
            >
              Edit
            </Button>
          ),
        },
      ]}
      disablePagination={false}
    />
  );
};

export default Index;
