import { useEffect, useRef, useState } from "react";
import ReportService from "services/Report";
import DataCollectionService from "services/DataCollection";
import ExportExcel from "./ExportExcel";
import Filter from "./Filter";
import Table from "./Table";
import { scrollToTopRow } from "components/Common/Table";
import DownloadFile from "components/Common/DownloadFile";
import { reportType } from "./constants";
import { DELAY_FETCH } from "constant";

const Index = ({ sites }) => {
  const timeRef = useRef(null);
  const [error, setError] = useState(null);
  const [requestParam, setRequestParam] = useState(null);
  const [isExportFile, setIsExportFile] = useState(false);
  const reportTypesList = reportType.getList();
  const [tableData, setTableData] = useState(null);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [filter, setFilter] = useState({
    dateFrom: new Date("2009-01-01"),
    dateTo: new Date("2010-12-31"),
    countFrom: 10000,
    countTo: 20000,
    testName: "",
    instrumentName: "",
    locationName: "",
  });

  const [exportFilter, setExportFilter] = useState({
    site: null,
    reportType: reportTypesList[0],
  });

  const getRequestParams = (additionalReqBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        siteCode: exportFilter.site ? exportFilter.site.value : null,
      },
      requestBody: {
        statMonthFrom: filter.dateFrom,
        statMonthTo: filter.dateTo,
        countFrom: filter.countFrom || 0,
        countTo: filter.countTo || 0,
        ...additionalReqBody,
      },
    };
    setRequestParam(reqParams);
    return reqParams;
  };

  const getPatientData = async () => {
    const { params, requestBody } = getRequestParams();
    const data = await ReportService.getListPatients(params, requestBody);
    setTableData(data);
    scrollToTopRow();
  };

  const getResultData = async () => {
    const { params, requestBody } = getRequestParams({
      testName: filter.testName,
    });
    const data = await ReportService.getListResults(params, requestBody);
    setTableData(data);
    scrollToTopRow();
  };

  const getResultInstrumentData = async () => {
    const { params, requestBody } = getRequestParams({
      testName: filter.testName,
      instrumentName: filter.instrumentName,
    });
    const data = await ReportService.getListResultsInstrument(
      params,
      requestBody
    );
  
    setTableData(data);
    scrollToTopRow();
  };

  const getResultDepartmentData = async () => {
    const { params, requestBody } = getRequestParams({
      testName: filter.testName,
      locationName: filter.locationName,
    });
    const data = await ReportService.getListResultsDepartment(
      params,
      requestBody
    );
    setTableData(data);
    scrollToTopRow();
  };

  const onValidation = () => {
    const err = {};
    if (!exportFilter.site) {
      err.siteName = "Hãy chọn một SiteName";
    }

    if (!filter.dateFrom || !filter.dateTo) {
      err.date = "Không được bỏ trống ngày";
    } else if (filter.dateFrom > filter.dateTo) {
      err.date = "Ngày bắt đầu <= ngày kết thúc";
    }

    if (filter.countFrom === undefined && filter.countTo === undefined) {
      return err;
    }
    if (
      (!filter.countFrom && filter.countFrom !== 0) ||
      (!filter.countTo && filter.countTo !== 0)
    ) {
      err.count = "Không được bỏ trống số lượng";
    } else if (filter.countFrom > filter.countTo) {
      err.count = "Count From <= To";
    }

    return err;
  };

  const getData = () => {
    if (error) return;
    const err = onValidation();
    if (JSON.stringify(err) !== "{}") {
      setError(err);
      setTableData({});
      return;
    }

    setTableData(null);
    timeRef.current = setTimeout(() => {
      const type = exportFilter.reportType.value;
      if (type === reportType.PATIENT) {
        getPatientData();
      } else if (type === reportType.RESULT) {
        getResultData();
      } else if (type === reportType.RESULT_INSTRUMENT) {
        getResultInstrumentData();
      } else if (type === reportType.RESULT_DEPARTMENT) {
        getResultDepartmentData();
      }
    }, DELAY_FETCH);
  };

  const getExportApi = () => {
    const type = exportFilter.reportType.value;
    if (type === reportType.PATIENT) {
      return DataCollectionService.exportPatientData;
    } else if (type === reportType.RESULT) {
      return DataCollectionService.exportResultData;
    } else if (type === reportType.RESULT_INSTRUMENT) {
      return DataCollectionService.exportResultInstrumentData;
    }
    return DataCollectionService.exportResultDepartmentData;
  };

  useEffect(() => {
    getData();
  }, [exportFilter, paging]);

  // Mặc định lấy site đầu tiên trong danh sách sites
  useEffect(() => {
    if (!sites || !sites.length) return;
    setExportFilter(prev => ({ ...prev, site: sites[0] }));
    setError(null);
  }, [sites]);

  useEffect(() => {
    if (!tableData) return;

    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
  }, [tableData]);

  return (
    <div>
      <ExportExcel
        sites={sites}
        error={error}
        setError={setError}
        exportFilter={exportFilter}
        setExportFilter={setExportFilter}
        reportTypesList={reportTypesList}
        isLoading={!tableData}
        setIsExportFile={setIsExportFile}
        setPaging={setPaging}
      />
      <Filter
        error={error}
        setError={setError}
        filter={filter}
        setFilter={setFilter}
        onSearch={() => {
          setPaging(prev => ({ ...prev, currentPage: 1 }));
          getData();
        }}
        filterType={exportFilter.reportType.value}
        isLoading={!tableData}
        exportFilter={exportFilter}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        tableType={exportFilter.reportType.value}
      />
      {isExportFile && (
        <DownloadFile
          isOpen={isExportFile}
          isUseFilter
          request={{
            siteCode: exportFilter.site.value,
            ...(requestParam ? {reqBody: requestParam.requestBody} : {}),
          }}
          serviceApi={getExportApi()}
          onClose={() => setIsExportFile(false)}
          fileName={`Danh sách thống kê dữ liệu ${exportFilter.reportType.label}`}
        />
      )}
    </div>
  );
};

export default Index;
