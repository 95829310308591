import Table, { formatValueToMoneyVND } from "components/Common/Table";
import { Button } from "reactstrap";
import StatisticService from "services/Statistic";
import { DELAY_FETCH } from "constant";
import { showToast } from "components/Common";
import { useEffect, useRef, useState } from "react";
// import "./../../../assets/scss/custom/components/_fixcolumn.scss";
const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  filter,
  setParaDetail,
  setIsOpenModalDetail,
  setIsOpenDetail,
  setSiteCodeDetail,
  colums,
}) => {
  const [columnTable, setColumnTable] = useState([{}]);
  const timerRef = useRef(null);
  const getColumnTable = () => {
    let columHeaders;
    if (filter.maHang && filter.maHang.length < 1) {
      timerRef.current = setTimeout(async () => {
        const res = await StatisticService.GetListMaHangInstrumentService();
        if (res.error) {
          showToast(
            `Lỗi lấy thông tin column ${res.error.title}: ${res.error.description}`,
            3
          );
          return [];
        }
        if (res) {
          columHeaders = res?.map(item => {
            let column;
            if (item == "1NULL") {
              column = {
                Header: () => (
                  <Button
                    color="transparent"
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      setParaDetail(item?.trim());
                      setIsOpenModalDetail(true);
                    }}
                  >
                    NULL
                  </Button>
                ),
                accessor: item.trim(),
                width: 150,
                Cell: data => (
                  <Button color="link">
                    <div
                      style={{ textAlign: "center" }}
                      onClick={() => {
                        let param = {
                          maHang: item.trim(),
                          masterCategoryCode: data.original.MasterCategoryCode,
                        };
                        setParaDetail(param);
                        setIsOpenDetail(true);
                      }}
                    >
                      <div>{formatValueToMoneyVND(data.value)}</div>
                    </div>
                  </Button>
                ),
              };
              return column;
            }
            if (item == "2EMPTY") {
              column = {
                Header: () => (
                  <Button
                    color="transparent"
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      setParaDetail(item?.trim());
                      setIsOpenModalDetail(true);
                    }}
                  >
                    {" "}
                  </Button>
                ),
                accessor: item.trim(),
                width: 150,
                Cell: data => (
                  <Button
                    color="link"
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      let param = {
                        maHang: item.trim(),
                        masterCategoryCode: data.original.MasterCategoryCode,
                      };
                      setParaDetail(param);
                      setIsOpenDetail(true);
                    }}
                  >
                    <div>{formatValueToMoneyVND(data.value)}</div>
                  </Button>
                ),
              };
              return column;
            }
            column = {
              Header: () => (
                <Button
                  color="transparent"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setParaDetail(item?.trim());
                    setIsOpenModalDetail(true);
                  }}
                >
                  {item.trim()}
                </Button>
              ),
              accessor: item.trim(),
              width: 150,
              Cell: data => (
                <Button
                  color="link"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    let param = {
                      maHang: item.trim(),
                      masterCategoryCode: data.original.MasterCategoryCode,
                    };
                    setParaDetail(param);
                    setIsOpenDetail(true);
                  }}
                >
                  <div>{formatValueToMoneyVND(data.value)}</div>
                </Button>
              ),
            };
            return column;
          });
          columHeaders.unshift({
            Header: () => (
              <div className="d-flex w-100 justify-content-center">
                MasterCategoryName
              </div>
            ),
            accessor: "MasterCategoryName",
            fixed: "left",
            // className: "sticky",
            headerClassName: "sticky-header",
            width: 250,
          });
          setColumnTable(columHeaders);
        } else {
          setColumnTable([]);
        }
      }, DELAY_FETCH);
    } else {
      columHeaders = filter.maHang?.map(item => {
        let column;
        if (item == "1NULL") {
          column = {
            Header: () => (
              <Button
                color="transparent"
                style={{ textAlign: "center" }}
                onClick={() => {
                  setParaDetail(item?.trim());
                  setIsOpenModalDetail(true);
                }}
              >
                NULL
              </Button>
            ),
            accessor: item?.trim(),
            width: 150,
            Cell: data => (
              <Button color="link">
                <div
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    let param = {
                      maHang: item.trim(),
                      masterCategoryCode: data.original.MasterCategoryCode,
                    };
                    setParaDetail(param);
                    setIsOpenDetail(true);
                  }}
                >
                  <div>{formatValueToMoneyVND(data.value)}</div>
                </div>
              </Button>
            ),
          };
          return column;
        }
        if (item == "2EMPTY") {
          column = {
            Header: () => (
              <div
                color="transparent"
                style={{
                  textAlign: "center",
                  maxWidth: "1000px",
                  height: "100%",
                }}
                onClick={() => {
                  setParaDetail(item?.trim());
                  setIsOpenModalDetail(true);
                }}
              >
                {" "}
              </div>
            ),
            accessor: item.trim(),
            width: 150,
            Cell: data => (
              <Button
                color="link"
                style={{ textAlign: "center" }}
                onClick={() => {
                  let param = {
                    maHang: item.trim(),
                    masterCategoryCode: data.original.MasterCategoryCode,
                  };

                  setParaDetail(param);
                  setIsOpenDetail(true);
                }}
              >
                <div>{formatValueToMoneyVND(data.value)}</div>
              </Button>
            ),
          };
          return column;
        }
        column = {
          Header: () => (
            <Button
              color="transparent"
              style={{ textAlign: "center" }}
              onClick={() => {
                setParaDetail(item?.trim());
                setIsOpenModalDetail(true);
              }}
            >
              {item.trim()}
            </Button>
          ),
          accessor: item.trim(),
          width: 150,
          Cell: data => (
            <Button
              color="link"
              style={{ textAlign: "center" }}
              onClick={() => {
                let param = {
                  maHang: item.trim(),
                  masterCategoryCode: data.original.MasterCategoryCode,
                };
                setParaDetail(param);
                setIsOpenDetail(true);
              }}
            >
              <div>{formatValueToMoneyVND(data.value)}</div>
            </Button>
          ),
        };
        return column;
      });

      columHeaders.unshift({
        fixed: "left",
        Header: () => (
          <div className="d-flex w-100 justify-content-center">
            MasterCategoryName
          </div>
        ),
        accessor: "MasterCategoryName",
        width: 250,
        fixed: "left",
        // className: "sticky",
        headerClassName: "sticky-header",
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              //background: "#fff",
              // position: "sticky",
            },
          };
        },
      });
    }
    setColumnTable(columHeaders);
  };

  useEffect(() => {
    getColumnTable();
  }, [filter]);

  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={columnTable}
      disablePagination={false}
    />
  );
};

export default Index;
