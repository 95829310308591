export const statisticType = {
  XETNGHIEMBENHVIEN: "XETNGHIEMBENHVIEN",
  SOLUONGBENHNHAN: "SOLUONGBENHNHAN",
  SOLUONGBENHNHAN_NAM: "SOLUONGBENHNHAN_NAM",
  SOLUONGXETNGHIEM_NAM: "SOLUONGXETNGHIEM_NAM",
  SLXETNGHIEM_LOAIXN:"SLXETNGHIEM_LOAIXN",
  SLXETNGHIEM_NHOMXN: "SLXETNGHIEM_NHOMXN",
  SLXETNGHIEM_KHOAPHONG: "SLXETNGHIEM_KHOAPHONG",
  SLXETNGHIEM_HANG_LOAIXN: "SLXETNGHIEM_HANG_LOAIXN",
  SLXETNGHIEM_HANG_NHOMXN: "SLXETNGHIEM_HANG_NHOMXN",
  DOANHSO_LOAIXN: "DOANHSO_LOAIXN",
  DOANHSO_NHOMXN: "DOANHSO_NHOMXN",
  DOANHSO_HANG: "DOANHSO_HANG",
  DOANHSO_HANG_NHOMXN: "DOANHSO_HANG_NHOMXN",
  DOANHSO_NHOMKHOAPHONG: "DOANHSO_NHOMKHOAPHONG",
  DOANHSO_NAM: "DOANHSO_NAM",
  DOANHSO_BENHVIEN: "DOANHSO_BENHVIEN",
  SLXETNGHIEM_BOXN: "SLXETNGHIEM_BOXN",
  SLXETNGHIEM_PARENTTEST: "SLXETNGHIEM_PARENTTEST",
  getListAmountStatistic() {
    return [
      {
        label: "Xét nghiệm bệnh viện",
        value: this.XETNGHIEMBENHVIEN,
      },
      {
        label: "Số lượng bệnh nhân",
        value: this.SOLUONGBENHNHAN,
      },
      {
        label: "SL bệnh nhân - Năm",
        value: this.SOLUONGBENHNHAN_NAM,
      },
      {
        label: "SL xét nghiệm - Năm",
        value: this.SOLUONGXETNGHIEM_NAM,
      },
      {
        label: "SL xét nghiệm - loại XN",
        value: this.SLXETNGHIEM_LOAIXN,
      },
      {
        label: "SL xét nghiệm - nhóm XN",
        value: this.SLXETNGHIEM_NHOMXN,
      },
      {
        label: "SL xét nghiệm - Khoa phòng",
        value: this.SLXETNGHIEM_KHOAPHONG,
      },
      {
        label: "SL xét nghiệm - Hãng - Loại XN",
        value: this.SLXETNGHIEM_HANG_LOAIXN,
      },
      {
        label: "SL xét nghiệm - Hãng - nhóm XN",
        value: this.SLXETNGHIEM_HANG_NHOMXN,
      },
      {
        label: "SL xét nghiệm - Bộ XN",
        value: this.SLXETNGHIEM_BOXN,
      },{
        label: "SL xét nghiệm - xét nghiệm thương mại",
        value: this.SLXETNGHIEM_PARENTTEST,
      },
    ];
  },
 
};

export const getLabelValueFrom = cellData =>
  (cellData.page ? cellData.page - 1 : cellData.page) * cellData.pageSize +
  cellData.viewIndex +
  1;

  export const colorsSite = [
    "#FF5733", "#FF9A8B", "#FFC300", "#FFFC5E", "#CCFF00",
    "#81D8D0", "#00FFFF", "#008080", "#0040FF", "#D670DA",
    "#B57EDC", "#9370DB", "#7D26CD", "#0000FF", "#000080",
    "#964B00", "#8B4513", "#C76114", "#D2691E", "#FF7F50",
    "#FF6347", "#FF4500", "#E42217", "#FF0000", "#FF3030",
    "#A52A2A", "#800000", "#8B0000", "#CD5C5C", "#BC8F8F",
    "#F08080", "#E9967A", "#FA8072", "#B22222", "#FFA07A",
    "#A0522D", "#DAA520", "#FFD700", "#FFD700", "#FFD700",
    "#FFD700", "#B8860B", "#B8860B", "#D2691E", "#D2691E",
    "#8B4513", "#800000", "#8B0000", "#CD5C5C", "#BC8F8F",
    "#F08080", "#E9967A"
  ];

  export const GetDataSetChartJS = (dataArr, lbArr, propertiesLabel) => {
    const distinctProperties = [
      ...new Set(dataArr.flatMap(obj => Object.keys(obj))),
    ];
    const dataByProperty = {};

    distinctProperties.forEach(property => {
      dataByProperty[property] = dataArr
        .filter(obj => obj.hasOwnProperty(property))
        .map(obj => {
          return obj[property];
        });
    });

    const keys = Object.keys(dataByProperty);
    var dataSetChart = keys.map((key, index) => {
      const value = dataByProperty[key];
      let dataSet = {
        label: lbArr?.find(x => x.value == key)?.label,
        backgroundColor: colorsSite[index],
        data: value,
      };
      return dataSet;
    });
    dataSetChart.shift();
    let dataChart = {
      labels: dataArr.map(x => x[propertiesLabel]),
      datasets: dataSetChart,
    };
    return dataChart;
  }