import React from "react";
import { PageContent, CustomBreadcrumb } from "components/Common";

import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MasterParentTest from "./MasterParentTest";

const RESOURCE = ModuleIds.MasterParentTest;

const MasterParentTestContainer = ({ t }) => {
  const titleTag = t("Danh sách Master Parent Test");

  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb
        breadcrumbItem={titleTag}
        resource={RESOURCE}
        icon={<i className="bx bx-book-bookmark" />}
      />
      {/* Page Body */}
      <MasterParentTest />
    </PageContent>
  );
};

MasterParentTestContainer.propTypes = {
  t: PropTypes.any,
};

export default withTranslation(["masterParentTestPage"])(
  MasterParentTestContainer
);
