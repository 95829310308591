import React, { useEffect, useRef } from "react";
import { Progress } from "reactstrap";
import { showToast } from "components/Common";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Label,
} from "reactstrap";

const DownloadFile = ({
  isOpen,
  onClose,
  isUseFilter,
  request,
  serviceApi,
  fileName = 'export-file'
}) => {
  const ref = useRef();
  useEffect(() => {
    async function exportData() {
      const res = await serviceApi(isUseFilter ? request : {});
// console.log("------", res)
      try {
        if (res) {
          var binaryData = [];
          binaryData.push(res);
          var url = window.URL.createObjectURL(
            new Blob(binaryData, { type: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64" })
          );
          
          ref.current.href = url;
          ref.current.download = `${fileName}.xlsx`;
          ref.current.click();
          showToast("Xuất file thành công", 1);
          onClose();
          return;
        }
        showToast("Xuất file không thành công", 3);
        onClose();
      } catch(e) {
        console.log(e);
        
        showToast("Xuất file không thành công", 3);
        onClose();
      }
    }

    exportData();
  }, []);

  return (
    <React.Fragment>
      <a href="#download" style={{ display: "none" }} ref={ref}>
        download
      </a>
      <Modal isOpen={isOpen}>
        <ModalHeader>Xuất file excel</ModalHeader>
        <ModalBody className="p-3">
          <Row>
            <Label>Đang xử lý, xin vui lòng chờ trong giây lát...</Label>
            <div className="mt-1">
              <Progress color="primary" animated value="100" />
            </div>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-center mt-4">
                <Button color="danger" onClick={onClose}>
                  Hủy xuất file
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DownloadFile;
