import { useEffect, useRef, useState } from "react";
import Filter from "./Filter";
import CombineStatMonthlyPatient from "./CombineStatMonthlyPatient/CombineStatMonthlyPatient";
import CombineStatMonthlyResult from "./CombineStatMonthlyResult/CombineStatMonthlyResult";
import { statisticType } from "./constants";

import CombineStatMonthlyPatientByYear from "./CombineStatMonthlyPatientByYear/CombineStatMonthlyPatientByYear";
import CombineStatMonthlyResultByYear from "./CombineStatMonthlyResultByYear/CombineStatMonthlyResultByYear";
import CombineStatMonthlyResultByCategory from "./CombineStatMonthlyResultByCategory/CombineStatMonthlyResultByCategory";
import CombineStatMonthlyResultLocationType from "./CombineStatMonthlyResultLocationType/CombineStatMonthlyResultLocationType";
import CombineStatMonthlyResultInstrument from "./CombineStatMonthlyResultInstrument/CombineStatMonthlyResultInstrument";
import CombineStatMonthlyResultInstrumentByGroup from "./CombineStatMonthlyResultInstrumentByGroup/CombineStatMonthlyResultInstrumentByGroup";
import CombineStatMonthlyResultByTypeCate from "./CombineStatMonthlyResultByTypeCate/CombineStatMonthlyResultByTypeCate";
import CombineStatMonthlyResultByGroupTest from "./CombineStatMonthlyResultByGroupTest/CombineStatMonthlyResultByGroupTest";
import CombineStatMonthlyResultByParentTest from "./CombineStatMonthlyResultByParentTest/CombineStatMonthlyResultByParentTest";

const Index = ({ sites }) => {
  const timeRef = useRef(null);
  const [error, setError] = useState(null);
  const [isExportFile, setIsExportFile] = useState(false);
  const [selectValue, setSelectValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const reportTypesList = statisticType.getListAmountStatistic();

  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [filter, setFilter] = useState({
    siteCode: [],
    dateFrom: new Date("0001-01-01"),
    dateTo: new Date("0001-01-01"),
  });

  const [exportFilter, setExportFilter] = useState({
    site: null,
    reportType: reportTypesList[0],
  });

  return (
    <div>
      <Filter
        filter={filter}
        setFilter={setFilter}
        sites={sites}
        error={error}
        setError={setError}
        exportFilter={exportFilter}
        setExportFilter={setExportFilter}
        reportTypesList={reportTypesList}
        isLoading={false}
        setIsExportFile={setIsExportFile}
        setPaging={setPaging}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
      />
      {exportFilter.reportType.value == "SOLUONGBENHNHAN" && (
        <CombineStatMonthlyPatient
          sites={sites}
          filterAll={filter}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setFilterAll={setFilter}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "XETNGHIEMBENHVIEN" && (
        <CombineStatMonthlyResult
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SOLUONGBENHNHAN_NAM" && (
        <CombineStatMonthlyPatientByYear
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SOLUONGXETNGHIEM_NAM" && (
        <CombineStatMonthlyResultByYear
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SLXETNGHIEM_NHOMXN" && (
        <CombineStatMonthlyResultByCategory
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SLXETNGHIEM_KHOAPHONG" && (
        <CombineStatMonthlyResultLocationType
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SLXETNGHIEM_HANG_LOAIXN" && (
        <CombineStatMonthlyResultInstrument
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SLXETNGHIEM_HANG_NHOMXN" && (
        <CombineStatMonthlyResultInstrumentByGroup
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SLXETNGHIEM_LOAIXN" && (
        <CombineStatMonthlyResultByTypeCate
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SLXETNGHIEM_BOXN" && (
        <CombineStatMonthlyResultByGroupTest
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
      {exportFilter.reportType.value == "SLXETNGHIEM_PARENTTEST" && (
        <CombineStatMonthlyResultByParentTest
          sites={sites}
          filterAll={filter}
          setFilterAll={setFilter}
          isLoading={isLoading}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default Index;
