import React, { useState, useEffect } from "react";
import { Button, Modal, Label, ModalHeader } from "reactstrap";
import { Progress } from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DropZoneCustom from "./DropZoneCustom";

const UploadMode = {
  FORM: "FORM",
  LOADING: "LOADING",
  UPLOADED: "UPLOADED",
};

const UploadExcel = ({
  title,
  isOpen,
  onClose,
  onStartUploadingFile,
  fileSupport,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  console.log()
  const [errorMessage, setErrorMessage] = useState(null);
  const [mode, setMode] = useState(UploadMode.FORM);

  const onFileChange = file => {
    // Add file to State
    setFile(file);
  };

  const onFileUpload = async () => {
    // Create an object of formData
    const formData = new FormData();
    
    // Update the formData object
    formData.append("fileData", file);

    // await onStartUploadingFile(formData);
    const response = await onStartUploadingFile(formData);

    setMode(UploadMode.UPLOADED);
  };

  useEffect(() => {
    if (mode === UploadMode.LOADING) {
      onFileUpload();
    }
  }, [mode]);

  const renderContent = () => {
    if (mode === UploadMode.LOADING)
      return (
        <>
          <Label>Đang xử lý, xin vui lòng chờ trong giây lát...</Label>
          <div className="mt-1">
            <Progress color="primary" animated value="100" />
          </div>
        </>
      );

    if (mode === UploadMode.UPLOADED)
      return (
        <>
          <>
            {errorMessage ? (
              <Label type="danger">{errorMessage}</Label>
            ) : (
              <Label type="success">{t("Uploading Success Text")}</Label>
            )}
          </>
          <div className="d-flex justify-content-center mt-2"></div>
          <div className="d-flex justify-content-end mt-2">
            <Button
              color="primary"
              onClick={() => {
                setFile(null);
                setMode(UploadMode.FORM);
                setErrorMessage(null);
              }}
            >
              {t("Tiếp tục upload file")}
            </Button>
          </div>
        </>
      );

    return (
      <>
        <div>
          <DropZoneCustom onChangeFile={onFileChange} />
          <div className="w-100 d-flex justify-content-end mt-1">
            <Label>
              <em>
                {t("Định dạng hỗ trợ")}:{" "}
                <b>{fileSupport ? fileSupport : "*.xlsx"}</b>
              </em>
            </Label>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button
              color="primary"
              onClick={() => {
                setMode(UploadMode.LOADING);
                return;
              }}
              disabled={!file}
              className="mx-2"
            >
              {t("Đồng ý")}
            </Button>

            <Button
              color="danger"
              onClick={() => {
                onClose();
              }}
            >
              {t("Thoát")}
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClosed={() => onClose()}>
        <ModalHeader>
          {title && typeof title === "string"
            ? title || t("Import file")
            : title}
        </ModalHeader>
        <div className="mt-3 p-3">{renderContent()}</div>
      </Modal>
    </>
  );
};

UploadExcel.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isExportXLSX: PropTypes.bool,
  onStartUploadingFile: PropTypes.func,
  onFinishUploadingFile: PropTypes.func,
};

export default UploadExcel;
