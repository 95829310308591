import axios from "axios";
import { get, put, post, del, delData, postBlobFile } from "../api_helper";
import ApiCaller from "ApiCaller";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/CombineStatYearly`;

const getCombineStatYearly = data => {
  return post(`${BASE_API_URL}/GetListCombineStatYearly`, data);
};

const ExportCombineStatYearly = data => {
  return post(`${BASE_API_URL}/ExportCombineStatYearly`, data, {
    responseType: "blob",
  });
};
const ExecuteStoredProcedures = data => {
  return post(`${BASE_API_URL}/ExecuteStoredProcedures`, data, {
    responseType: "blob",
  });
};

export {
  getCombineStatYearly,
  ExportCombineStatYearly,
  ExecuteStoredProcedures
};
