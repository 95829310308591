import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { BoxLoading } from "react-loadingg";
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("token")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
  
        if(location.pathname === '/') {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
  
        return (
          <div>
            <Layout>
              {/* {true && <BoxLoading />} */}
              <Component {...props} />
            </Layout>
          </div>
        );
      }}
    />
  )
};

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AppRoute;
