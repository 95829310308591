import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardHeader } from "reactstrap"

import Header from "components/Common/Header"

const TitleAndInformation = ({
  onEdit,
  onClone,
  onDelete,
  children,
  title,
  subtitle,
  icon,
  resource,
}) => {
  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom">
        <Header
          onEdit={onEdit}
          onClone={onClone}
          onDelete={onDelete}
          buttons
          title={title}
          subtitle={subtitle}
          iconClass={icon}
          resource={resource}
        />
      </CardHeader>

      <CardBody>{children}</CardBody>
    </Card>
  )
}

TitleAndInformation.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  resource: PropTypes.string.isRequired,
}

TitleAndInformation.defaultProps = {
  onEdit: () => {},
  onClone: () => {},
  onDelete: () => {},
}

export default TitleAndInformation
