import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Nav, NavItem, NavLink } from "reactstrap"
import classNames from "classnames"
import { isEmptyValues } from "helpers/utilities"

export const CustomNavLink = ({ tabId, isOpen, onToggle, children }) => {
  return (
    <NavItem>
      <NavLink
        style={{ cursor: "pointer" }}
        className={classNames({
          active: isOpen,
        })}
        onClick={() => {
          onToggle(tabId)
        }}
      >
        {children}
      </NavLink>
    </NavItem>
  )
}

CustomNavLink.CustomNavLink = {
  tabId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
}

const CustomNav = ({
  children,
  tabContents,
  vertical,
  defaultTab,
  onToggle,
  ...rest
}) => {
  const [customActiveTab, setCustomActiveTab] = useState("1")

  const onToggleHandler = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
      onToggle(tab)
    }
  }

  useEffect(() => {
    if (!isEmptyValues(defaultTab)) onToggleHandler(defaultTab)
  }, [defaultTab])

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isOpen: customActiveTab === child?.props?.tabId,
        onToggle: onToggleHandler,
      })
    }
    return child
  })

  const renderNav = () => (
    <Nav vertical={vertical} {...rest}>
      {childrenWithProps}
    </Nav>
  )

  return (
    <>
      {vertical ? (
        <>
          <Col lg={2} className="border-end border-3 pe-0">
            {renderNav()}
          </Col>

          <Col lg={10}>{tabContents(customActiveTab)}</Col>
        </>
      ) : (
        <>
          {renderNav()}
          {tabContents(customActiveTab)}
        </>
      )}
    </>
  )
}

CustomNav.propTypes = {
  tabContents: PropTypes.func.isRequired,
  defaultTab: PropTypes.any,
  vertical: PropTypes.bool,
  onToggle: PropTypes.func,
}

CustomNav.defaultProps = {
  tabContents: () => {},
  defaultTab: "1",
  onToggle: () => {},
}

export default CustomNav
