import { Input, Label } from "reactstrap";

const InputText = ({ name, label, value, onChange, errors, isRequired, ...other }) => {
  return (
    <>
      <Label>
        {label}
        {isRequired && (
          <span className="mx-1" style={{ color: "red" }}>
            (*)
          </span>
        )}
      </Label>
      <Input
        id={name}
        name={name}
        type="text"
        onChange={onChange}
        value={value}
        style={{
          borderColor: errors && errors[name] ? "red" : "#ced4da",
        }}
        {...other}
      />
      {errors && errors[name] ? (
        <p className="mb-0" style={{ color: "red" }}>
          {errors[name]}
        </p>
      ) : (
        <p className="mb-0" style={{ color: "transparent" }}>
          sample
        </p>
      )}
    </>
  );
};

export default InputText;
