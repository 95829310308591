import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "components/Common/Table";
import { Card, Input, Button, Row, Col } from "reactstrap";
import toastr from "toastr";
import { Typography } from "antd";
import ReactApexChart from "react-apexcharts";

const Index = ({ windowDimensions, dataChart,formatCurrencyVND }) => {
  const { t } = useTranslation(["Statistics"]);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const columns = [
    {
      filterable: false,
      resiable: false,
      sortable: false,
      fixed: "left",
      width: 50,
      Header: t("STT"),
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      accessor: "maNhomKhachHang",
      Header: t("Mã hàng phân phối"),
      minWidth: 300,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      accessor: "doanhSo",
      Header: t("Tổng doanh số"),
      width: 300,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
  ];

  const series = dataChart.map(item => item.doanhSo);
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: dataChart.map(item => item.maNhomKhachHang),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft",
        offsetY: 30,
        offsetX: 60,
      },
      enabled: true,
      y: {
        formatter: function (value, { seriesIndex }) {
            return formatCurrencyVND(value);   
        },
      },
    },
  };

  return (
    <Card>
      <Row>
        <Col md={12} className="mb-2">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center mb-2">
              <i
                className="pe-7s-graph icon-gradient bg-mean-fruit"
                style={{
                  fontSize: "1.4rem",
                  padding: "3px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  borderRadius: "4px",
                }}
              />
              <Typography.Title level={4} className="mb-0 ml-2">
                Thống kê doanh số thực theo mã hàng phân phối
              </Typography.Title>
            </div>
          </div>
          <div className="d-flex justify-content-center w-100">
            <div
              className="w-100"
              style={{
                maxWidth: windowDimensions.height > 768 ? "74%" : "894px",
              }}
            >
              <ReactApexChart
                options={options}
                series={series}
                type="pie"
                height={windowDimensions.height > 768 ? 600 : 450}
              />
            </div>
          </div>
        </Col>
        <Col md={12}>
          <Table
            paging={paging}
            setPaging={setPaging}
            data={{ data: dataChart }}
            pageSize={paging.pageSize}
            style={{ height: "calc(100vh - 350px)" }}
            columns={columns}
          />
        </Col>
      </Row>
    </Card>
  );
};
export default Index;
