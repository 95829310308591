import Table, {
  getSeqByCellData,
  formatValueToMoneyVND,
} from "components/Common/Table";
import { Button } from "reactstrap";
import StatisticService from "services/Statistic";
import { DELAY_FETCH } from "constant";
import { showToast } from "components/Common";
import { useEffect, useRef, useState } from "react";
// import "./../../../assets/scss/custom/components/_fixcolumn.scss";
const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  filter,
  setParaDetail,
  setIsOpenModalDetail,
  setIsOpenDetail,
  setSiteCodeDetail,
  colums,
}) => {
  const [columnTable, setColumnTable] = useState([{}]);
  const timerRef = useRef(null);
  const getColumnTable = () => {
    let dataFullCate = [];
    timerRef.current = setTimeout(async () => {
      dataFullCate =
        await StatisticService.GetLsMasterCategoryCodeResultService({
          search: "",
        });
      if (dataFullCate.error) {
        showToast(
          `Lỗi lấy thông tin column ${dataFullCate.error.title}: ${dataFullCate.error.description}`,
          3
        );
        return [];
      }

      let columHeaders;
      if (filter.masterCategoryCode && filter.masterCategoryCode.length < 1) {
        if (dataFullCate) {
          columHeaders = dataFullCate?.map(item => {
            let column;
            if (item.value == "1NULL") {
              column = {
                Header: () => (
                  <Button
                    color="transparent"
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      setParaDetail(item);
                      setIsOpenModalDetail(true);
                    }}
                  >
                    NULL
                  </Button>
                ),
                accessor: item.value?.trim(),
                width: 150,
                Cell: data => (
                  <Button color="link">
                    <div
                      style={{ textAlign: "center" }}
                      onClick={() => {
                        let param = {
                          masterCategoryCode: null,
                          commonName: data.original.CommonName,
                        };
                        setParaDetail(param);
                        setIsOpenDetail(true);
                      }}
                    >
                      <div>{formatValueToMoneyVND(data.value)}</div>
                    </div>
                  </Button>
                ),
              };
              return column;
            }
            column = {
              Header: () => (
                <Button
                  color="transparent"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setParaDetail(item);
                    setIsOpenModalDetail(true);
                  }}
                >
                  {item.label?.trim()}
                </Button>
              ),
              accessor: item.value?.trim(),
              width: 150,
              Cell: data => (
                <Button
                  color="link"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    let param = {
                      masterCategoryCode: item.value,
                      commonName: data.original.CommonName,
                    };
                    setParaDetail(param);
                    setIsOpenDetail(true);
                  }}
                >
                  <div>{formatValueToMoneyVND(data.value)}</div>
                </Button>
              ),
            };
            return column;
          });
          columHeaders.unshift({
            // fixed: "left",
            Header: "CommonName",
            accessor: "CommonName",
            width: 150,
          });
          columHeaders.unshift({
            // fixed: "left",
            Header: "TestName",
            accessor: "TestName",
            width: 150,
          });
          setColumnTable(columHeaders);
        } else {
          setColumnTable([]);
        }
      } else {
        const arr = dataFullCate.filter(item => {
          return filter.masterCategoryCode?.some(i => i === item.value);
        });
        columHeaders = arr?.map(item => {
          let column;
          if (item.value == "1NULL") {
            column = {
              Header: () => (
                <Button
                  color="transparent"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setParaDetail(item);
                    setIsOpenModalDetail(true);
                  }}
                >
                  NULL
                </Button>
              ),
              accessor: item.value?.trim(),
              width: 150,
              Cell: data => (
                <Button color="link">
                  <div
                    style={{ textAlign: "center" }}
                    onClick={() => {
                      let param = {
                        masterCategoryCode: item.value,
                        commonName: data.original.CommonName,
                      };
                      setParaDetail(param);
                      setIsOpenDetail(true);
                    }}
                  >
                    <div>{formatValueToMoneyVND(data.value)}</div>
                  </div>
                </Button>
              ),
            };
            return column;
          }
          column = {
            Header: () => (
              <Button
                color="transparent"
                style={{ textAlign: "center" }}
                onClick={() => {
                  setParaDetail(item);
                  setIsOpenModalDetail(true);
                }}
              >
                {item.label.trim()}
              </Button>
            ),
            accessor: item.value.trim(),
            width: 150,
            Cell: data => (
              <Button
                color="link"
                style={{ textAlign: "center" }}
                onClick={() => {
                  let param = {
                    masterCategoryCode: item.value,
                    commonName: data.original.CommonName,
                  };
                  setParaDetail(param);
                  setIsOpenDetail(true);
                }}
              >
                <div>{formatValueToMoneyVND(data.value)}</div>
              </Button>
            ),
          };
          return column;
        });
        columHeaders.unshift({
          fixed: "left",
          // className: "sticky",
          headerClassName: "sticky-header",
          Header: () => <div>CommonName</div>,
          accessor: "CommonName",
          width: 150,
        });
        columHeaders.unshift({
          fixed: "left",
          // className: "sticky",
          headerClassName: "sticky-header",
          Header: () => <div>TestName</div>,
          accessor: "TestName",
          width: 150,
        });
      }
      setColumnTable(columHeaders);
    }, DELAY_FETCH);
  };

  useEffect(() => {
    getColumnTable();
  }, [filter]);

  return (
    <Table
      startIndex={2}
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={columnTable}
      disablePagination={false}
    />
  );
};

export default Index;
