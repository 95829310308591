import Table, {
  getSeqByCellData,
  getSTTCellData,
  formatNumber,
  formatValueToMoneyVND,
} from "components/Common/Table";
import { format } from "date-fns";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setIsOpenModalDetail,
  setSiteCodeDetail,
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "siteCode",
          fixed: "left",
          headerClassName: "sticky-header",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSTTCellData(data, paging)}
            </div>
          ),
        },
        {
          Header: "Mã bệnh viện",
          accessor: "siteCode",
          fixed: "left",
          headerClassName: "sticky-header",
          width: 150,
        },
        {
          Header: "Tên bệnh viện",
          accessor: "siteName",
          fixed: "left",
          headerClassName: "sticky-header",
          width: 250,
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          fixed: "left",
          headerClassName: "sticky-header",
          width: 150,
          Cell: ({ value }) => {
            return (
              <div className="d-flex w-100 justify-content-center">
                {format(new Date(value), "yyyy-MM")}
              </div>
            );
          },
        },
        {
          Header: "CountTest",
          accessor: "countTest",
          width: 150,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-end">
              {formatNumber(value)}
            </div>
          ),
        },
        {
          Header: "Gía",
          accessor: "doanhSo",
          width: 150,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-end">
              {formatValueToMoneyVND(value)}
            </div>
          ),
        },
        {
          Header: "MasterTestcode",
          accessor: "masterTestcode",
          width: 150,
        },
        {
          Header: "MasterTestName",
          accessor: "masterTestName",
          width: 150,
        },
        {
          Header: "MasterCategoryCode",
          accessor: "masterCategoryCode",
          width: 150,
        },
        {
          Header: "MasterCategoryName",
          accessor: "masterCategoryName",
          width: 250,
        },
        
        {
          Header: "CommonName",
          accessor: "commonName",
          width: 150,
        },
      ]}
      disablePagination={false}
    />
  );
};

export default Index;
