import { color } from "./constants";
const convertMinutesToHHMM = minutes => {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;

  // Add leading zero if necessary
  var hoursString = hours < 10 ? "0" + hours : hours.toString();
  var minutesString =
    remainingMinutes < 10
      ? "0" + remainingMinutes
      : remainingMinutes.toString();

  return hoursString + ":" + minutesString;
};

function randomNumber(min = 0, max = 30) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const getAverageTimeData = () => {
  const chartData = {
    type: "outlabeledPie",
    labels: ["39 phút (38%)", "36 phút (40%)", "36 phút (40%)"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
        ],
        backgroundColor: [color.GREEN, color.BLUE, color.YELLOW],
        borderWidth: 2,
      },
    ],
  };

  const cardData = [
    {
      id: "preAnalysis",
      title1: "Là thời gian lấy máu đến thời gian nhận mẫu (phút)",
      title2: "Thời gian tiền phân tích",
      value: "00:00",
      background: "linear-gradient(180deg, #00E396, #00bf7f)",
    },
    {
      id: "inAnalysis",
      title1: "Là thời gian hút test đầu tiên đến lúc có kết quả máy (phút)",
      title2: "Thời gian phân tích",
      value: "00:00",
      background: "linear-gradient(180deg, #008FFB, #0279d4)",
    },
    {
      id: "afterAnalysis",
      title1: "Là thời gian có kết quả máy đến thời gian valid (phút)",
      title2: "Thời gian hậu phân tích",
      value: "00:00",
      background: "linear-gradient(180deg, #FEB019, #eb7f05)",
    },
    {
      id: "totalAnalysis",
      title1: "(phút)",
      title2: "TAT total (nhận mẫu ➝ valid)",
      value: "00:00",
      background: "linear-gradient(180deg, #FF4560, #ff1c3d)",
    },
  ];

  return { chartData, cardData };
};

const getFollowingDateData = () => {
  return {
    series: [
      {
        name: "Thời gian tiền phân tích",
        data: [
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
        ],
      },
      {
        name: "Thời gian phân tích",
        data: [
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
        ],
      },
      {
        name: "Thời gian hậu phân tích",
        data: [
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
        ],
      },
    ],
    categories: [
      "01/01/2011",
      "01/02/2011",
      "01/03/2011",
      "01/04/2011",
      "01/05/2011",
      "01/06/2011",
    ],
  };
};

const getFollowingTimeData = () => {
  return {
    series: [
      {
        name: "Thời gian tiền phân tích",
        data: [
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
        ],
      },
      {
        name: "Thời gian phân tích",
        data: [
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
        ],
      },
      {
        name: "Thời gian hậu phân tích",
        data: [
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
          randomNumber(10, 30),
        ],
      },
    ],
    categories: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ],
  };
};

export {
  getAverageTimeData,
  getFollowingDateData,
  getFollowingTimeData,
  convertMinutesToHHMM,
};
