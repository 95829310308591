//set search query
export const SET_SITE_SEARCH_QUERY = "SET_SITE_SEARCH_QUERY";

//reset search query
export const RESET_SITE_SEARCH_QUERY = "RESET_SITE_SEARCH_QUERY";

//get list site
export const GET_SITES = "GET_SITES";
export const GET_SITES_SUCCESS = "GET_SITES_SUCCESS";
export const GET_SITES_FAIL = "GET_SITES_FAIL";

//get detail site
export const GET_SITE_DETAIL = "GET_SITE_DETAIL";
export const GET_SITE_DETAIL_SUCCESS = "GET_SITE_DETAIL_SUCCESS";
export const GET_SITE_DETAIL_FAIL = "GET_SITE_DETAIL_FAIL";

//add site
export const ADD_SITE="ADD_SITE";
export const ADD_SITE_SUCCESS="ADD_SITE_SUCCESS";
export const ADD_SITE_FAIL="ADD_SITE_FAIL";

//update site
export const UPDATE_SITE="UPDATE_SITE";
export const UPDATE_SITE_SUCCESS="UPDATE_SITE_SUCCESS";
export const UPDATE_SITE_FAIL="UPDATE_SITE_FAIL";

//delete site
export const DELETE_SITE="DELETE_SITE";
export const DELETE_SITE_SUCCESS="DELETE_SITE_SUCCESS";
export const DELETE_SITE_FAIL="DELETE_SITE_FAIL";

//SYNC SITE
export const SYNC_SITE="SYNC_SITE";
export const SYNC_SITE_SUCCESS="SYNC_SITE_SUCCESS";
export const SYNC_SITE_FAIL="SYNC_SITE_FAIL";

//EXPORT DATA MAP TEST CODE 
export const EXPORT_MAP_TEST_CODE = "EXPORT_MAP_TEST_CODE";
export const EXPORT_MAP_TEST_CODE_SUCCESS = "EXPORT_MAP_TEST_CODE_SUCCESS";
export const EXPORT_MAP_TEST_CODE_FAIL = "EXPORT_MAP_TEST_CODE_FAIL";

//GET_DUPLICATE_STATMONTHRESULT
export const GET_DUPLICATE_STATMONTHRESULT = "GET_DUPLICATE_STATMONTHRESULT";
export const GET_DUPLICATE_STATMONTHRESULT_SUCCESS = "GET_DUPLICATE_STATMONTHRESULT_SUCCESS";
export const GET_DUPLICATE_STATMONTHRESULT_FAIL = "GET_DUPLICATE_STATMONTHRESULT_FAIL";

//SYNC-DATABASE-MAPTESTCODE-STATMONTH-RESULT
export const SYNC_DATABASE = "SYNC_DATABASE";
export const SYNC_DATABASE_SUCCESS = "SYNC_DATABASE_SUCCESS";
export const SYNC_DATABASE_FAIL = "SYNC_DATABASE_FAIL";

//COMBINE-STATMONTHPATIENT
export const COMBINE_STATMONTHPATIENT = "COMBINE_STATMONTHPATIENT";
export const COMBINE_STATMONTHPATIENT_SUCCESS = "COMBINE_STATMONTHPATIENT_SUCCESS";
export const COMBINE_STATMONTHPATIENT_FAIL = "COMBINE_STATMONTHPATIENT_FAIL";

//COMBINE_STATMONTHRESULT
export const COMBINE_STATMONTHRESULT = "COMBINE_STATMONTHRESULT";
export const COMBINE_STATMONTHRESULT_SUCCESS = "COMBINE_STATMONTHRESULT_SUCCESS";
export const COMBINE_STATMONTHRESULT_FAIL = "COMBINE_STATMONTHRESULT_FAIL";

//COMBINE_STATMONTHLOCATIONTYPE
export const COMBINE_STATMONTHLOCATIONTYPE = "COMBINE_STATMONTHLOCATIONTYPE";
export const COMBINE_STATMONTHLOCATIONTYPE_SUCCESS = "COMBINE_STATMONTHLOCATIONTYPE_SUCCESS";
export const COMBINE_STATMONTHLOCATIONTYPE_FAIL = "COMBINE_STATMONTHLOCATIONTYPE_FAIL";

//COMBINE_STATMONTHRESULTINSTRUMENT
export const COMBINE_STATMONTHRESULTINSTRUMENT = "COMBINE_STATMONTHRESULTINSTRUMENT";
export const COMBINE_STATMONTHRESULTINSTRUMENT_SUCCESS = "COMBINE_STATMONTHRESULTINSTRUMENT_SUCCESS";
export const COMBINE_STATMONTHRESULTINSTRUMENT_FAIL = "COMBINE_STATMONTHRESULTINSTRUMENT_FAIL";

//COMBINE_STATMONTHRESULTFULLDATA
export const COMBINE_STATMONTHRESULTFULLDATA = "COMBINE_STATMONTHRESULTFULLDATA";
export const COMBINE_STATMONTHRESULTFULLDATA_SUCCESS = "COMBINE_STATMONTHRESULTFULLDATA_SUCCESS";
export const COMBINE_STATMONTHRESULTFULLDATA_FAIL = "COMBINE_STATMONTHRESULTFULLDATA_FAIL";

//REPORT_DATA_DATABASE
export const REPORT_DATA_DATABASE = "REPORT_DATA_DATABASE";
export const REPORT_DATA_DATABASE_SUCCESS = "REPORT_DATA_DATABASE_SUCCESS";
export const REPORT_DATA_DATABASE_FAIL = "REPORT_DATA_DATABASE_FAIL";

//REPORT_DATA_INSTRUMENT
export const REPORT_DATA_INSTRUMENT = "REPORT_DATA_INSTRUMENT";
export const REPORT_DATA_INSTRUMENT_SUCCESS = "REPORT_DATA_INSTRUMENT_SUCCESS";
export const REPORT_DATA_INSTRUMENT_FAIL = "REPORT_DATA_INSTRUMENT_FAIL";

//REPORT_DATA_STATMONTH
export const REPORT_DATA_STATMONTH = "REPORT_DATA_STATMONTH";
export const REPORT_DATA_STATMONTH_SUCCESS = "REPORT_DATA_STATMONTH_SUCCESS";
export const REPORT_DATA_STATMONTH_FAIL = "REPORT_DATA_STATMONTH_FAIL";

//SET_CHECKBOX
export const SET_CHECKBOX = "SET_CHECKBOX";