import React from "react";
import { PageContent, CustomBreadcrumb } from "components/Common";
import Target from "./Target";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const RESOURCE = ModuleIds.Target;

const TargetContainer = ({ t }) => {
  const titleTag = t("Quản lý target PAKD");
  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb
        breadcrumbItem={titleTag}
        resource={RESOURCE}
        icon={<i className="bx bx-file" />}
      />
      {/* Page Body */}
      <Target />
    </PageContent>
  );
};

TargetContainer.propTypes = {
  t: PropTypes.any,
};

export default withTranslation(["sitePage"])(TargetContainer);
