import Table, {
    getSeqByCellData,
    getSTTCellData,
    formatNumber,
    formatValueToMoneyVND
  } from "components/Common/Table";
import { format } from "date-fns";
  import { Button } from "reactstrap";
  
  const Index = ({
    paging,
    setPaging,
    data,
    pageSize,
    setIsOpenModalDetail,
    setSiteCodeDetail,
  }) => {
    return (
      <Table
        paging={paging}
        setPaging={setPaging}
        data={data}
        pageSize={pageSize}
        columns={[
          {
            Header: "STT",
            accessor: "siteCode",
            fixed: "left",
            headerClassName: "sticky-header",            
            width: 50,
            Cell: data => (
              <div className="d-flex w-100 justify-content-center">
                {getSTTCellData(data, paging)}
              </div>
            ),
          },
          {
            Header: "Mã bệnh viện",
            accessor: "siteCode",
            fixed: "left",
            headerClassName: "sticky-header",            
            width: 150,
          },
          {
            Header: "Tên bệnh viện",
            accessor: "siteName",
            fixed: "left",
            headerClassName: "sticky-header",            
            width: 250,
          },
          {
            Header: "StatMonth",
            accessor: "statMonth",
            fixed: "left",
            headerClassName: "sticky-header",            
            width: 150,
            Cell: ({ value }) => {            
              return <div className="d-flex w-100 justify-content-center">{format(new Date(value), 'yyyy-MM')}</div>;
            },
          },
          {
            Header: "MasterInstrumentID",
            accessor: "masterInstrumentID",
            width: 150,
          },      
          {
            Header: "MasterInstrumentName",
            accessor: "masterInstrumentName", 
            width: 150,
          },    
          {
            Header: "CountTest",
            accessor: "countTest",
            width: 150,
            Cell: ({ value }) => (
              <div className="d-flex w-100 justify-content-end">{formatNumber(value)}</div>
            ),
          },
          {
            Header: "Gía",
            accessor: "doanhSo",
            width: 150,
            Cell: ({ value }) => (
              <div className="d-flex w-100 justify-content-end">{formatValueToMoneyVND(value)}</div>
            ),
          },
          {
            Header: "MasterTestcode",
            accessor: "masterTestcode",           
            width: 150,
          },    
          {
            Header: "MasterTestName",
            accessor: "masterTestName",           
            width: 150,
          },      
          {
            Header: "MasterCategoryCode",
            accessor: "masterCategoryCode",           
            width: 150,
          }, 
          {
            Header: "MasterCategoryName",
            accessor: "masterCategoryName",           
            width: 250,
          },            
          {
            Header: "Ma_hang",
            accessor: "ma_hang",
            width: 150,
          },
          {
            Header: "ten_nh",
            accessor: "ten_nh",
            width: 150,
          },
          {
            Header: "ma_linhvuc",
            accessor: "ma_linhvuc",
            width: 150,
          },
          {
            Header: "ten_linhvuc",
            accessor: "ten_linhvuc",
            width: 150,
          },
          {
            Header: "nh_vt3",
            accessor: "nh_vt3",
            width: 150,
          },
          {
            Header: "CommonName",
            accessor: "commonName",
            width: 150,
          },
        ]}
        disablePagination={false}
      />
    );
  };
  
  export default Index;
  