import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import getFileSize from "utils/getFileSize";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgb(214 209 209)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "rgb(118 118 118)",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#556ee6",
  color: "#556ee6",
};

const rejectStyle = {
  borderColor: "#ff0048",
  color: "#ff0048",
};

function StyledDropzone({ onChangeFile }) {
  const { t } = useTranslation();
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDrop: files => onChangeFile(files[0]),
    maxFiles: 1,
    // accept:
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept || acceptedFiles.length ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, acceptedFiles]
  );

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {getFileSize(file.size)}
    </li>
  ));

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          {acceptedFiles.length ? (
            acceptedFileItems
          ) : (
            <div className="d-flex flex flex-column align-items-center">
              <p className="mt-0 mb-0">
                {t("Kéo thả tập tin hoặc chọn tập tin ở đây")}.
              </p>
              <p className="mt-0 mb-0">
                <em>({t("Chỉ cho phép chọn 1 file")}).</em>
              </p>
            </div>
          )}
        </p>
      </div>
    </div>
  );
}

StyledDropzone.propTypes = {
  onChangeFile: PropTypes.func,
};

export default StyledDropzone;
