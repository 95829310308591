import { DELAY_FETCH, ModuleIds } from "constant";
import React, { useEffect, useRef, useState, useMemo } from "react";
//i18n
import Table from "components/Common/Table";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Card, Input, Button, Row, Col } from "reactstrap";
import toastr from "toastr";
import Filter from "./Components/Filter";
import ChartEfficiency from "./Components/ChartEfficiency";
import ChartComparison from "./Components/ChartComparison";
import ChartQuarterly from "./Components/ChartQuarterly";
import {
  getCustomers,
  getEmployees,
  getRegions,
  getDataEfficiency,
} from "../../../helpers/app-backend/effectiverealsalebackend_helpers";
import { getAllTargetPAKDs } from "../../../helpers/app-backend/targetpakdbackend_helper";

const StatisticsEffective = ({ t }) => {
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const userName = localStorage.getItem("userName");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [dataCustomers, setDataCustomers] = useState([]);
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [dataRegions, setDataRegions] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataTargetPAKD12, setDataTargetPAKD12] = useState([]);
  const [dataLastYear12, setDataLastYear12] = useState([]);
  const [dataTargetPAKDAll, setDataTargetPAKDAll] = useState({
    nameRow: "TargetPAKD",
    thang1: 0,
    thang2: 0,
    thang3: 0,
    thang4: 0,
    thang5: 0,
    thang6: 0,
    thang7: 0,
    thang8: 0,
    thang9: 0,
    thang10: 0,
    thang11: 0,
    thang12: 0,
    Q1: 0,
    Q2: 0,
    Q3: 0,
    Q4: 0,
    Total: 0,
  });
  const [dataFilter, setDataFilter] = useState({
    employeeSelect: [],
    maKHSelect: [],
    tenKHSelect: [],
    regionSelect: [],
    quaters: [],
  });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const formatCurrencyVND = value => {
    const formattedValue = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    }).format(value);
    return formattedValue.replace(/\./g, ",").replace("₫", "VND");
  };
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onGetData = async () => {
    const listCus = await getCustomers();
    setDataCustomers(listCus.result.data);
    const optionsCus = listCus.result.data.map(item => ({
      value: item.maKH,
      label: item.tenKH != null ? item.tenKH : "Khách hàng không tên",
      key: item.maMien,
    }));
    setCustomerOptions(optionsCus);
    //
    const listEmp = await getEmployees();
    setDataEmployees(listEmp.result.data);
    const optionsEmp = listEmp.result.data.map(item => ({
      value: item.maNV,
      label: item.tenNV,
    }));
    setEmployeesOptions(optionsEmp);
    //
    const listReg = await getRegions();
    setDataRegions(listReg.result.data);
    const optionsReg = listReg.result.data.map(item => ({
      value: item.maMien,
      label: item.tenMien,
    }));
    setRegionsOptions(optionsReg);
  };

  const mapValueToHeader = value => {
    switch (value) {
      case "prevYearValue":
        return t("Doanh số năm ngoái");
      case "TargetPAKD":
        return t("Target PAKD");
      case "value":
        return t("Doanh số 2024");
      case "target":
        return t("Target 2024");
      case "forecast":
        return t("Forecast");
      case "investmentEfficiencyPercentage":
        return t("Hiệu quả đầu tư (%)");
      case "achievementPercentage":
        return t("Achievement (%)");
      default:
        return "";
    }
  };
  const mapValueToTable = (nameRow, value) => {
    switch (nameRow) {
      case "LastYear":
        return formatCurrencyVND(value);
      case "TargetPAKD":
        return formatCurrencyVND(value);
      case "value":
        return formatCurrencyVND(value);
      case "target":
        return formatCurrencyVND(value);
      case "forecast":
        return formatCurrencyVND(value);
      case "investmentEfficiencyPercentage":
        return value + " %";
      case "achievementPercentage":
        return t(value + " %");
      case "prevYearValue":
        return formatCurrencyVND(value);
      default:
        return "";
    }
  };

  const columns = [
    {
      filterable: false,
      resiable: false,
      sortable: false,
      width: 50,
      Header: t("STT"),
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      accessor: "nameRow",
      Header: t(""),
      width: 250,
      Cell: ({ value }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToHeader(value)}
        </div>
      ),
    },
    {
      accessor: "thang1",
      Header: t("Tháng 1"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {mapValueToTable(row?.nameRow, value)}
          </div>
        );
      },
    },
    {
      accessor: "thang2",
      Header: t("Tháng 2"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang3",
      Header: t("Tháng 3"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang4",
      Header: t("Tháng 4"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang5",
      Header: t("Tháng 5"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang6",
      Header: t("Tháng 6"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang7",
      Header: t("Tháng 7"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang8",
      Header: t("Tháng 8"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang9",
      Header: t("Tháng 9"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang10",
      Header: t("Tháng 10"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang11",
      Header: t("Tháng 11"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "thang12",
      Header: t("Tháng 12"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "Q1",
      Header: t("Quý 1"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "Q2",
      Header: t("Quý 2"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "Q3",
      Header: t("Quý 3"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "Q4",
      Header: t("Quý 4"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
    {
      accessor: "Total",
      Header: t("Tổng"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {mapValueToTable(row?.nameRow, value)}
        </div>
      ),
    },
  ];
  const mapData = (key, data) => ({
    nameRow: key,
    thang1: parseFloat(data.find(item => item.name === "Tháng 1")[key]).toFixed(
      2
    ),
    thang2: parseFloat(data.find(item => item.name === "Tháng 2")[key]).toFixed(
      2
    ),
    thang3: parseFloat(data.find(item => item.name === "Tháng 3")[key]).toFixed(
      2
    ),
    thang4: parseFloat(data.find(item => item.name === "Tháng 4")[key]).toFixed(
      2
    ),
    thang5: parseFloat(data.find(item => item.name === "Tháng 5")[key]).toFixed(
      2
    ),
    thang6: parseFloat(data.find(item => item.name === "Tháng 6")[key]).toFixed(
      2
    ),
    thang7: parseFloat(data.find(item => item.name === "Tháng 7")[key]).toFixed(
      2
    ),
    thang8: parseFloat(data.find(item => item.name === "Tháng 8")[key]).toFixed(
      2
    ),
    thang9: parseFloat(data.find(item => item.name === "Tháng 9")[key]).toFixed(
      2
    ),
    thang10: parseFloat(
      data.find(item => item.name === "Tháng 10")[key]
    ).toFixed(2),
    thang11: parseFloat(
      data.find(item => item.name === "Tháng 11")[key]
    ).toFixed(2),
    thang12: parseFloat(
      data.find(item => item.name === "Tháng 12")[key]
    ).toFixed(2),
    Q1: parseFloat(data.find(item => item.name === "Quý 1")[key]).toFixed(2),
    Q2: parseFloat(data.find(item => item.name === "Quý 2")[key]).toFixed(2),
    Q3: parseFloat(data.find(item => item.name === "Quý 3")[key]).toFixed(2),
    Q4: parseFloat(data.find(item => item.name === "Quý 4")[key]).toFixed(2),
    Total: parseFloat(data.find(item => item.name === "Tổng")[key]).toFixed(2),
  });

  useEffect(() => {
    const getDataChart = async () => {
      try {
        const year = new Date().getFullYear();
        const commonParams = {
          year,
          maNVBH: dataFilter.employeeSelect,
          maMien: dataFilter.regionSelect,
          maKH: dataFilter.maKHSelect,
          quaters: dataFilter.quaters,
          username: localStorage ? localStorage.getItem("userName") : "",
        };
        const response = await getDataEfficiency(commonParams);
        if (response.result.isSuccess) {
          setDataChart(response.result.data);

          const formattedData = [
            mapData("value", response.result.data),
            mapData("target", response.result.data),
            mapData("forecast", response.result.data),
            mapData("investmentEfficiencyPercentage", response.result.data),
            mapData("achievementPercentage", response.result.data),
            dataTargetPAKDAll,
            mapData("prevYearValue", response.result.data),
            ,
          ];
          setDataTable(formattedData);
          return;
        }
        toastr.error("Đã có lỗi xảy ra");
        setDataChart([]);
      } catch (err) {
        toastr.error("Đã có lỗi xảy ra");
        setDataChart([]);
      }
    };
    getDataChart();
  }, [dataFilter, dataTargetPAKDAll]);

  useEffect(() => {
    if (dataFilter.customerSelect !== "") {
      const getDataPAKD = async () => {
        try {
          const response = await getAllTargetPAKDs({
            page: paging.currentPage,
            size: paging.pageSize,
            search: dataFilter.customerSelect?.value,
          });
          const rowData = response.data[0];
          const Q1 =
            parseFloat(rowData.thang1) +
            parseFloat(rowData.thang2) +
            parseFloat(rowData.thang3);
          const Q2 =
            parseFloat(rowData.thang4) +
            parseFloat(rowData.thang5) +
            parseFloat(rowData.thang6);
          const Q3 =
            parseFloat(rowData.thang7) +
            parseFloat(rowData.thang8) +
            parseFloat(rowData.thang9);
          const Q4 =
            parseFloat(rowData.thang10) +
            parseFloat(rowData.thang11) +
            parseFloat(rowData.thang12);
          const Total = Q1 + Q2 + Q3 + Q4;
          const parseAndMultiplyByFactor = (value, factor) =>
            parseFloat(value) * factor;

          setDataTargetPAKD12(
            Array.from({ length: 12 }, (_, i) =>
              parseFloat(rowData[`thang${i + 1}`])
            )
          );
          setDataLastYear12(
            Array.from({ length: 12 }, (_, i) =>
              parseAndMultiplyByFactor(rowData[`thang${i + 1}`], 0.9)
            )
          );

          const thangData = Array.from({ length: 12 }, (_, i) => ({
            [`thang${i + 1}`]: parseFloat(rowData[`thang${i + 1}`]),
          }));

          const thangObject = thangData.reduce(
            (acc, obj) => ({ ...acc, ...obj }),
            {}
          );

          setDataTargetPAKDAll({
            nameRow: "TargetPAKD",
            ...thangObject,
            Q1,
            Q2,
            Q3,
            Q4,
            Total,
          });

          const applyFactorToValue = value => parseInt(value) * 0.9;

          return;
        } catch (err) {
          toastr.error("Đã có lỗi xảy ra");
          setDataTargetPAKD12([]);
        }
      };
      getDataPAKD();
    }
  }, [dataFilter.customerSelect]);
  useMemo(() => {
    onGetData();
  }, []);
  return (
    <React.Fragment>
      <Filter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        customerOptions={customerOptions}
        employeesOptions={employeesOptions}
        regionsOptions={regionsOptions}
      />
      <ChartEfficiency
        windowDimensions={windowDimensions}
        dataChart={dataChart}
        formatCurrencyVND={formatCurrencyVND}
        dataTargetPAKD12={dataTargetPAKD12}
      />
      <ChartQuarterly
        windowDimensions={windowDimensions}
        dataChart={dataChart}
      />
      <ChartComparison
        windowDimensions={windowDimensions}
        dataChart={dataChart}
        formatCurrencyVND={formatCurrencyVND}
        dataLastYear12={dataLastYear12}
      />
      <Card>
        <Row>
          <Col
            md={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          ></Col>
          <Col md={12}>
            <Table
              paging={paging}
              setPaging={setPaging}
              data={{ data: dataTable }}
              pageSize={paging.pageSize}
              style={{ height: "calc(100vh - 280px)" }}
              columns={columns}
            />
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(
  withTranslation(["UserPage", "message"])(StatisticsEffective)
);
