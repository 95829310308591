import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="page-title-right">
            <div className="d-flex align-items-center">
              {
                props.icon && <div
                className="px-2 py-1 d-flex justify-content-center align-items-center"
                style={{
                  background: "#FFF",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  borderRadius: "5px",
                  marginRight: '10px',
                }}
              >
                <h2 className="mb-0 mt-1">
                  {props.icon}
                </h2>
              </div>
              }
              <h3 className="mb-0 font-size-30 text-uppercase">{props.breadcrumbItem}</h3>
            </div>
            <ol className="breadcrumb m-0">
              {props.items?.map((_i, idx) => {
                return (
                  <BreadcrumbItem key={idx}>
                    <Link to={_i.link}>{_i.title}</Link>
                  </BreadcrumbItem>
                );
              })}

              {/* Chưa cần hiển thị breadcrum vì chỉ có 1 cấp menu */}
              {/* <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem> */}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
};

export default Breadcrumb;
