import React, { useEffect, useRef, useState, useMemo } from "react";
//i18n
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Input, Button, Row, Col } from "reactstrap";
import CustomCard from "./CustomCard";
import RevenueEmployee from "./ChartComponents/RevenueEmployee";
import RevenueMedical from "./ChartComponents/RevenueMedical";
import Top5Revenue from "./ChartComponents/Top5Revenue";
import RevenueCustomer from "./ChartComponents/RevenueCustomer";
import RevenueGrowth from "./ChartComponents/RevenueGrowth";
import Filter from "./Filter";
import toastr from "toastr";
import {
  getCustomers,
  getEmployees,
  getRegions,
  getDataEfficiency,
  getDashboardRevenueBySector,
  getDashboardRevenueByKAM,
} from "../../helpers/app-backend/effectiverealsalebackend_helpers";
import {
  getDataRevenueByCustomer,
  getDataRevenueByGroupProduct,
} from "../../helpers/app-backend/effectiverealsalebackend_helpers";
const KHCLRevenueReport = ({ t }) => {
  const userName = localStorage.getItem("userName");
  const initialCardData = [
    {
      id: "DSTong",
      title1: "DOANH SỐ TỔNG",
      value: null,
      background: "linear-gradient(180deg, #008FFB, #0279d4)",
      unit: "Tỷ",
    },
    {
      id: "TGTong",
      title1: "TARGET TỔNG",
      value: null,
      background: "linear-gradient(180deg, #008FFB, #0279d4)",
      unit: "Tỷ",
    },
    {
      id: "TG%Tong",
      title1: "%TARGET TỔNG",
      value: null,
      background: "linear-gradient(180deg, #008FFB, #0279d4)",
      unit: "(%)",
    },
    {
      id: "GPADoanhSo",
      title1: "GAP DOANH SỐ",
      value: null,
      background: "#df4343",
      unit: "Tỷ",
    },
    {
      id: "Growth",
      title1: "GROWTH",
      value: null,
      background: "linear-gradient(180deg, #008FFB, #0279d4)",
      unit: "%",
    },
  ];
  const [cardData, setCardData] = useState(initialCardData);
  const [isLoading, setIsLoading] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [dataCustomers, setDataCustomers] = useState([]);
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [dataRegions, setDataRegions] = useState([]);

  const [dataTop5Revenue, setDataTop5Revenue] = useState([]);
  const [dataRevenueMedical, setDataRevenueMedical] = useState([]);
  const [dataRevenueByKAM, setDataRevenueByKAM] = useState([]);
  const [dataRevenueByCustomer, setDataRevenueByCustomer] = useState([]);

  const [loadingDataChart, setLoadingDataChart] = useState(true);
  const [loadingTop5Revenue, setLoadingTop5Revenue] = useState(true);
  const [loadingRevenueMedical, setLoadingRevenueMedical] = useState(true);
  const [loadingRevenueByKAM, setLoadingRevenueByKAM] = useState(true);
  const [loadingRevenueByCustomer, setLoadingRevenueByCustomer] =
    useState(true);

  const [dataFilter, setDataFilter] = useState({
    employeeSelect: [],
    maKHSelect: [],
    tenKHSelect: [],
    regionSelect: [],
    quarters: [],
  });
  const formatCurrency = value => {
    const formattedValue = new Intl.NumberFormat("vi-VN").format(value);
    return formattedValue.replace(/\./g, ",");
  };

  const onGetData = async () => {
    const listCus = await getCustomers();
    setDataCustomers(listCus.result.data);

    const uniqueCustomersMap = new Map();

    listCus.result.data.forEach(item => {
      // Normalize the name by converting to lowercase and trimming whitespace
      const normalizedName = item.tenKH
        ? item.tenKH.trim().toLowerCase()
        : "khách hàng không tên";

      if (!uniqueCustomersMap.has(normalizedName)) {
        uniqueCustomersMap.set(normalizedName, item);
      }
    });

    const optionsCus = Array.from(uniqueCustomersMap.values()).map(item => ({
      value: item.maKH,
      label: item.tenKH != null ? item.tenKH : "Khách hàng không tên",
      key: item.maMien,
    }));

    setCustomerOptions(optionsCus);

    //
    const listEmp = await getEmployees();
    setDataEmployees(listEmp.result.data);
    const optionsEmp = listEmp.result.data.map(item => ({
      value: item.maNV,
      label: item.tenNV,
    }));
    setEmployeesOptions(optionsEmp);
    //
    const listReg = await getRegions();
    setDataRegions(listReg.result.data);
    const optionsReg = listReg.result.data.map(item => ({
      value: item.maMien,
      label: item.tenMien,
    }));
    setRegionsOptions(optionsReg);
  };

  useEffect(() => {
    setLoadingRevenueByCustomer(true);
    setLoadingRevenueByKAM(true);
    setLoadingRevenueMedical(true);
    setLoadingTop5Revenue(true);
    setLoadingDataChart(true);

    const fetchData = async () => {
      try {
        const year = new Date().getFullYear();
        // const commonParams = {
        //   year,
        //   maNVBH: dataFilter.employeeSelect,
        //   maMien: dataFilter.regionSelect,
        //   maKH: dataFilter.maKHSelect,
        //   quarters: dataFilter.quarters,
        // };
        const customerParams = {
          year,
          maNVBH: dataFilter.employeeSelect,
          maMien: dataFilter.regionSelect,
          tenKH: dataFilter.tenKHSelect,
          quarters: dataFilter.quarters,
          username: localStorage ? localStorage.getItem("userName") : "",
        };

        const efficiencyPromise = getDataEfficiency(customerParams);
        const revenuePromise = getDataRevenueByGroupProduct(customerParams);
        const revenueBySectorPromise =
          getDashboardRevenueBySector(customerParams);
        const revenueByKAMPromise = getDashboardRevenueByKAM(customerParams);
        const revenueByCustomerPromise =
          getDataRevenueByCustomer(customerParams);

        const [
          efficiencyResponse,
          revenueResponse,
          sectorResponse,
          kamResponse,
          customerResponse,
        ] = await Promise.all([
          efficiencyPromise,
          revenuePromise,
          revenueBySectorPromise,
          revenueByKAMPromise,
          revenueByCustomerPromise,
        ]);
        // Handle efficiency data
        if (efficiencyResponse.result.isSuccess) {
          const data = efficiencyResponse.result.data;
          const currentMonth = new Date().getMonth().toString().padStart(1);
          const dataCurrentMonth = efficiencyResponse.result.data.slice(
            0,
            currentMonth
          );
          const total = dataCurrentMonth.reduce(
            (acc, item) => {
              acc.value += item.value;
              acc.prevYearValue += item.prevYearValue;
              return acc;
            },
            { value: 0, prevYearValue: 0 }
          );
          const dataTong = data.find(item => item.name === "Tổng");
          const DsTong = dataTong.value;
          const TargetTong = dataTong.target;
          const PTTargetTong = (DsTong * 100) / TargetTong;
          const GPADS = TargetTong - DsTong;
          const Growth = (
            (total.value / total.prevYearValue - 1) *
            100
          ).toFixed(2);
          const updatedCardData = initialCardData.map(item => {
            switch (item.title1) {
              case "DOANH SỐ TỔNG":
                return { ...item, value: formatCurrency(DsTong / 1e9) };
              case "TARGET TỔNG":
                return { ...item, value: formatCurrency(TargetTong / 1e9) };
              case "%TARGET TỔNG":
                return { ...item, value: PTTargetTong.toFixed(2) };
              case "GAP DOANH SỐ":
                return { ...item, value: formatCurrency(GPADS / 1e9) };
              case "GROWTH":
                return { ...item, value: Growth };
              default:
                return item;
            }
          });
          setDataChart(efficiencyResponse.result.data.slice(0, 12));
          setCardData(updatedCardData);
          setLoadingDataChart(false);
        } else {
          toastr.error("Đã có lỗi xảy ra khi tải dữ liệu biểu đồ hiệu quả");
          setDataChart([]);
          setLoadingDataChart(false);
        }

        // Handle revenue data
        if (revenueResponse.result.isSuccess) {
          const dataTop5 = revenueResponse.result.data.slice(0, 5);
          setDataTop5Revenue(dataTop5);
          setLoadingTop5Revenue(false);
        } else {
          toastr.error("Đã có lỗi xảy ra khi tải dữ liệu top 5 doanh thu");
          setDataTop5Revenue([]);
          setLoadingTop5Revenue(false);
        }

        // Handle sector data
        if (sectorResponse.isCompleted) {
          const filteredData = sectorResponse.result.filter(
            item =>
              item.maLinhVuc === "SHMDMT" ||
              item.maLinhVuc === "HHDMTH" ||
              item.maLinhVuc === "SHMDDM"
          );
          const sortedData = filteredData.sort((a, b) => b.doanhSo - a.doanhSo);
          setDataRevenueMedical(sortedData);
          setLoadingRevenueMedical(false);
        } else {
          toastr.error("Đã có lỗi xảy ra khi tải dữ liệu doanh thu theo ngành");
          setDataRevenueMedical([]);
          setLoadingRevenueMedical(false);
        }

        // Handle KAM data
        if (kamResponse.isCompleted) {
          const filteredData = kamResponse.result.filter(
            item => item.maKAM !== "CHIHP" && item.maKAM !== "THUYLN"
          );
          const sortedData = filteredData.sort((a, b) => b.doanhSo - a.doanhSo);
          setDataRevenueByKAM(sortedData);
          setLoadingRevenueByKAM(false);
        } else {
          toastr.error("Đã có lỗi xảy ra khi tải dữ liệu doanh thu theo ngành");
          setDataRevenueByKAM([]);
          setLoadingRevenueByKAM(false);
        }

        // Handle customer data
        if (customerResponse.result.isSuccess) {
          const sortedData = customerResponse.result.data.sort(
            (a, b) => b.doanhSo - a.doanhSo
          );
          setDataRevenueByCustomer(sortedData);
          setLoadingRevenueByCustomer(false);
        } else {
          toastr.error("Đã có lỗi xảy ra khi tải dữ liệu doanh thu theo ngành");
          setDataRevenueByCustomer([]);
          setLoadingRevenueByCustomer(false);
        }
      } catch (error) {
        toastr.error("Đã có lỗi xảy ra khi tải dữ liệu");
        setDataChart([]);
        setLoadingDataChart(false);
        setDataTop5Revenue([]);
        setLoadingTop5Revenue(false);
        setDataRevenueMedical([]);
        setLoadingRevenueMedical(false);
        setDataRevenueByKAM([]);
        setLoadingRevenueByKAM(false);
        setDataRevenueByCustomer([]);
        setLoadingRevenueByCustomer(false);
      }
    };

    fetchData();
  }, [dataFilter]);

  useMemo(() => {
    onGetData();
  }, []);

  return (
    <React.Fragment>
      <Filter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        customerOptions={customerOptions}
        employeesOptions={employeesOptions}
        regionsOptions={regionsOptions}
      />
      {cardData && (
        <CustomCard cardData={cardData} isLoading={loadingDataChart} />
      )}

      <Row className="mt-3">
        <Col lg={4} md={6} sm={6} xs={12}>
          <Card>
            <RevenueEmployee
              dataRevenueByKAM={dataRevenueByKAM}
              formatCurrency={formatCurrency}
              loading={loadingRevenueByKAM}
            />
          </Card>
        </Col>

        <Col lg={4} md={6} sm={6} xs={12}>
          <Card>
            <RevenueMedical
              dataRevenueMedical={dataRevenueMedical}
              formatCurrency={formatCurrency}
              loading={loadingRevenueMedical}
            />
          </Card>
        </Col>
        <Col lg={4} md={6} sm={6} xs={12}>
          <Card>
            {dataTop5Revenue && (
              <Top5Revenue
                dataTop5Revenue={dataTop5Revenue}
                formatCurrency={formatCurrency}
                loading={loadingTop5Revenue}
              />
            )}
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <RevenueGrowth
              dataChart={dataChart}
              formatCurrency={formatCurrency}
              loading={loadingDataChart}
            />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <RevenueCustomer
              dataRevenueByCustomer={dataRevenueByCustomer}
              formatCurrency={formatCurrency}
              loading={loadingRevenueByCustomer}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(
  withTranslation(["UserPage", "message"])(KHCLRevenueReport)
);
