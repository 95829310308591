import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { withTranslation } from "react-i18next";
import { Button } from "antd";
import {
  TEXT_COLOR_FOCUSED,
  BACKGROUND_COLOR_FOCUSED,
  secondsToMinutes,
} from "../../config";
import {
  CustomCard,
  LoadingBackground,
  LoadingText,
} from "./../../Main/custom";
import Loader from "react-loaders";
import { Input } from "reactstrap";
import { fetchTestCodeSamplesAllRoute } from "helpers/app-backend/samplepathbackend_helpers";

const Index = ({
  data = [],
  lsTestCode,
  onGetDetailSampleFlow,
  setCurrentRouteName,
  loading,
  filter,
  setFilter,
  onSearch,
  inputRef,
  setIsLoading,
  totalDataFlow,
  keyState,
  setKeyState,
  paging,
  setPaging,
  keyTopSelectData,
  setDataTop10Calculate,
  setDataTop10Percentage,
  setDataTop5Calculate,
  setDataTop5Percentage,
  setDataNameRoute,
  setDataTestCodeChart,
}) => {
  const defaultExpaned = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  };
  const [expanded, setExpanded] = useState(defaultExpaned);
  const [selectRow, setSelectRow] = useState(null);
  const [preExpandIndex, setPreExpandIndex] = useState(null);
  const [dataTestCodeRoute, setDataTestCodeRoute] = useState([]);

  const subComponent = row => {
    const mapMasterTestcodes = dataTestCodeRoute
      .flat()
      .map(item => item.mapMasterTestcode);
    const uniqueMapMasterTestcodes = [...new Set(mapMasterTestcodes)];
    const result = uniqueMapMasterTestcodes.join(", ");
    return (
      <div className="p-3">Menu test: {dataTestCodeRoute ? result : ""}</div>
    );
  };

  const getTestCodeRoute = async _routeName => {
    let param = {
      dateFrom: filter.fromDate,
      dateTo: filter.toDate,
      listRouteName: _routeName,
      siteCode: filter.site.value,
    };
    const res = await fetchTestCodeSamplesAllRoute(param);

    if (res) {
      setDataTestCodeRoute([res]);
    }
  };
  const columns = [
    {
      Header: () => {
        return (
          <Input
            placeholder="Nhập để tìm route"
            defaultValue={inputRef.current}
            onChange={e => {
              inputRef.current = e.target.value;
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                setKeyState(!keyState);
                setIsLoading(true);
                setFilter(prev => ({
                  ...prev,
                  searchRoute: inputRef.current,
                }));
                setPaging(prev => ({ ...prev, currentPage: 1 }));
                setExpanded(defaultExpaned);
              }
            }}
          />
        );
      },
      accessor: "routeName",
      key: "routeName",
      Cell: data => {
        return (
          <div
            // onClick={() => {
            //   setCurrentRouteName(data.value);
            //   onGetDetailSampleFlow(data.value);
            // }}
            className="w-100 d-flex"
            style={{ fontWeight: "bold" }}
          >
            {data.value}
          </div>
        );
      },
      width: 300,
    },
    {
      Header: "Số lượng check point",
      accessor: "lsPoint.length",
      key: "lsPoint",
      minWidth: 100,
    },
    {
      Header: "Số lượng mẫu",
      accessor: "totalDataCalculate",
      key: "totalDataCalculate",
      minWidth: 130,
    },
    {
      Header: "Số mét",
      accessor: "distance",
      key: "distance",
      minWidth: 80,
    },
    {
      Header: "Min(phút)",
      accessor: "minSecond",
      key: "minSecond",
      Cell: data => {
        return <div>{secondsToMinutes(data.value)}</div>;
      },
      minWidth: 80,
    },
    {
      Header: "Max(phút)",
      accessor: "maxSecond",
      key: "maxSecond",
      Cell: data => {
        return <div>{secondsToMinutes(data.value)}</div>;
      },
      minWidth: 80,
    },
    {
      Header: "Average(phút)",
      accessor: "averageSecond",
      key: "averageSecond",
      Cell: data => {
        return <div>{secondsToMinutes(data.value)}</div>;
      },
      minWidth: 100,
    },
  ];

  const updateProperty = (object, keyToUpdate, value) => {
    for (const key in object) {
      if (key !== keyToUpdate) {
        object[key] = false;
      }
    }
    object[keyToUpdate] = value;
    return object;
  };
  const pageSizeOptions = [20, 40, 50, 80, 100, 200];
  const paginatedData = data.slice(
    (paging.currentPage - 1) * paging.pageSize,
    paging.currentPage * paging.pageSize
  );
  const getPageCount = () => {
    if (totalDataFlow % paging.pageSize === 0) {
      return totalDataFlow / paging.pageSize;
    } else {
      return Math.floor(
        (totalDataFlow + paging.pageSize - 1) / paging.pageSize
      );
    }
  };
  useEffect(() => {
    if (keyTopSelectData === "5") {
      const DataAllCalculate = data.map(item => item.totalDataCalculate);
      const DataTop5Calculate = data
        .slice(0, 5)
        .map(item => item.totalDataCalculate);
      const dataNameRoute = data.slice(0, 5).map(item => item.routeName);

      const sumAllTotalDataCalculate = DataAllCalculate.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const sumTop5TotalDataCalculate = DataTop5Calculate.reduce(
        (acc, curr) => acc + curr,
        0
      );

      const dataLast = sumAllTotalDataCalculate - sumTop5TotalDataCalculate;
      DataTop5Calculate.push(dataLast);
      dataNameRoute.push("Còn lại");

      const DataTop5Percentage = DataTop5Calculate.map(
        value => ((value / sumAllTotalDataCalculate) * 100).toFixed(2) + "%"
      );
      if (filter.site && filter.site.value) {
        async function fetchData() {
          try {
            let param = {
              dateFrom: filter.fromDate,
              dateTo: filter.toDate,
              listRouteName: dataNameRoute,
              siteCode: filter.site.value,
            };
            const res = await fetchTestCodeSamplesAllRoute(param);
            const menuTestMap = res.reduce((acc, item) => {
              const key = item.routeName;
              const value =
                item.mapMasterTestcode + (acc[key] ? `, ${acc[key]}` : "");
              acc[key] = value;
              return acc;
            }, {});
            const formattedData = Object.entries(menuTestMap).map(
              ([routeName, menuTest]) => ({
                routeName,
                menuTest,
              })
            );
            setDataTestCodeChart(formattedData);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
        fetchData();
      }
      setDataNameRoute(dataNameRoute);
      setDataTop5Calculate(DataTop5Calculate);
      setDataTop5Percentage(DataTop5Percentage);
    } else {
      const DataAllCalculate = data.map(item => item.totalDataCalculate);
      const DataTop10Calculate = data
        .slice(0, 9)
        .map(item => item.totalDataCalculate);
      const dataNameRoute = data.slice(0, 9).map(item => item.routeName);

      const sumAllTotalDataCalculate = DataAllCalculate.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const sumTop10TotalDataCalculate = DataTop10Calculate.reduce(
        (acc, curr) => acc + curr,
        0
      );

      const dataLast = sumAllTotalDataCalculate - sumTop10TotalDataCalculate;
      DataTop10Calculate.push(dataLast);
      dataNameRoute.push("Còn lại");

      const DataTop10Percentage = DataTop10Calculate.map(
        value => ((value / sumAllTotalDataCalculate) * 100).toFixed(2) + "%"
      );
      if (filter.site && filter.site.value) {
        async function fetchData() {
          try {
            let param = {
              dateFrom: filter?.fromDate,
              dateTo: filter?.toDate,
              listRouteName: dataNameRoute,
              siteCode: filter?.site.value,
            };
            const res = await fetchTestCodeSamplesAllRoute(param);
            const menuTestMap = res.reduce((acc, item) => {
              const key = item.routeName;
              const value =
                item.mapMasterTestcode + (acc[key] ? `, ${acc[key]}` : "");
              acc[key] = value;
              return acc;
            }, {});
            const formattedData = Object.entries(menuTestMap).map(
              ([routeName, menuTest]) => ({
                routeName,
                menuTest,
              })
            );
            setDataTestCodeChart(formattedData);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
        fetchData();
      }
      setDataNameRoute(dataNameRoute);
      setDataTop10Calculate(DataTop10Calculate);
      setDataTop10Percentage(DataTop10Percentage);
    }
  }, [data, keyTopSelectData]);
  return (
    <Fragment>
      <ReactTable
        SubComponent={subComponent}
        expanded={expanded}
        className="-striped -highlight"
        key={`${keyState}`}
        loading={loading}
        loadingText={<LoadingText />}
        style={{ height: 447 }}
        data={paginatedData}
        columns={columns}
        showPagination={true}
        noDataText={"No data"}
        defaultPageSize={paging.pageSize}
        pages={getPageCount()}
        pageSizeOptions={pageSizeOptions}
        manual
        onPageChange={pageIndex => {
          if (pageIndex >= 0 && pageIndex < getPageCount()) {
            setPaging(prev => ({
              ...prev,
              currentPage: pageIndex + 1,
            }));
            setExpanded(defaultExpaned);
            setPreExpandIndex(null);
          }
        }}
        onPageSizeChange={(newPageSize, pageIndex) => {
          setPaging(prev => ({
            ...prev,
            currentPage: 1,
            pageSize: newPageSize,
          }));
          setExpanded(defaultExpaned);
        }}
        getTrProps={(state, rowInfo, col, instance) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: e => {
                let obj = expanded;
                if (preExpandIndex && rowInfo.viewIndex == preExpandIndex) {
                  updateProperty(obj, rowInfo.viewIndex, false);
                } else {
                  updateProperty(obj, rowInfo.viewIndex, true);
                }
                setExpanded(obj);
                setPreExpandIndex(rowInfo.viewIndex);
                setCurrentRouteName(rowInfo.row.routeName);
                onGetDetailSampleFlow(rowInfo.row.routeName);
                setSelectRow(rowInfo.row);
                getTestCodeRoute([rowInfo.row._original.routeName]);
              },
              style: {
                background:
                  JSON.stringify(rowInfo.row) === JSON.stringify(selectRow)
                    ? BACKGROUND_COLOR_FOCUSED
                    : "",
                color:
                  JSON.stringify(rowInfo.row) === JSON.stringify(selectRow)
                    ? TEXT_COLOR_FOCUSED
                    : "black",
                cursor: "pointer",
              },
            };
          } else {
            return {};
          }
        }}
      />
    </Fragment>
  );
};

export default withTranslation(["configure"])(Index);
