import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"

import { isEmptyValues } from "helpers/utilities"

const PageContent = ({ title, children, t }) => {
  // const { isFetchedPermission, errorPermission } = useSelector(state => ({
  //   isFetchedPermission: state.Authorization.fetched,
  //   errorPermission: state.Authorization.errorPermission,
  // }))

  // if (!isFetchedPermission) return null

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {title}
        </title>
      </MetaTags>
      <Container fluid>
        {/* body && title */}
        {children}
      </Container>
    </div>
  )
}

PageContent.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func,
  children: PropTypes.element
}

export default withTranslation(["common"])(PageContent)
