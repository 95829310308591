import Table, { getSeqByCellData } from "components/Common/Table";
import { Button } from "reactstrap";
import SiteService from "services/Site";
import { DELAY_FETCH } from "constant";
import { showToast } from "components/Common";
import { useEffect, useRef, useState } from "react";
// import "./../../../assets/scss/custom/components/_fixcolumn.scss";
const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  filter,
  setParaDetail,
  setIsOpenModalDetail,
  setIsOpenDetail,
  selectValue,
  setSiteCodeDetail,
  colums,
}) => {
  const [columnTable, setColumnTable] = useState([{}]);
  const timerRef = useRef(null);
  const getColumnTable = () => {
    let columHeaders;
    if (selectValue.length < 1) {
      timerRef.current = setTimeout(async () => {
        const res = await SiteService.getListMasterSite({
          search: "",
        });

        if (res.error) {
          showToast(
            `Lỗi lấy thông tin column ${res.error.title}: ${res.error.description}`,
            3
          );
          return [];
        }
        let result = res.map(
          item =>
            (item = {
              value: item.siteCode,
              label: item.siteName,
            })
        );
        if (result) {
          columHeaders = result?.map(item => {
            let column;
            column = {
              Header: () => (
                <Button
                  color="transparent"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setParaDetail(item);
                    setIsOpenModalDetail(true);
                  }}
                >
                  {item.label.trim()}
                </Button>
              ),
              accessor: item.value.trim(),
              width: 150,
              Cell: data => (
                <Button
                  color="link"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    let param = {
                      siteCode: item.value.trim(),
                      siteName: item.label.trim(),
                      year: data.original.StatYear,
                    };
                    setParaDetail(param);
                    setIsOpenDetail(true);
                  }}
                >
                  <div>
                    {data.value
                      ? parseFloat(data.value).toLocaleString("vi-VN")
                      : ""}
                  </div>
                </Button>
              ),
            };
            return column;
          });
          columHeaders.unshift({
            // fixed: "left",
            Header: () => (
              <div
                color="transparent"
                style={{ textAlign: "center", position: "sticky" }}
              >
                Year
              </div>
            ),
            accessor: "StatYear",
            width: 80,
            Cell: data => (
              <span style={{ textAlign: "right" }}>{data.value}</span>
            ),
          });
          setColumnTable(columHeaders);
        } else {
          setColumnTable([]);
        }
      }, DELAY_FETCH);
    } else {
      columHeaders = selectValue?.map(item => {
        let column;
        column = {
          Header: () => (
            <Button
              color="transparent"
              style={{ textAlign: "center" }}
              onClick={() => {
                setParaDetail(item);
                setIsOpenModalDetail(true);
              }}
            >
              {item.label?.trim()}
            </Button>
          ),
          accessor: item.value?.trim(),
          width: 150,
          Cell: data => (
            <Button
              color="link"
              style={{ textAlign: "center" }}
              onClick={() => {
                let param = {
                  siteCode: item.value.trim(),
                  siteName: item.label.trim(),
                  year: data.original.StatYear,
                };
                setParaDetail(param);
                setIsOpenDetail(true);
              }}
            >
              <div>
                {data.value
                  ? parseFloat(data.value).toLocaleString("vi-VN")
                  : ""}
              </div>
            </Button>
          ),
        };
        return column;
      });
      columHeaders.unshift({
        // fixed: "left",
        Header: () => (
          <div
            color="transparent"
            style={{ textAlign: "center", position: "sticky" }}
          >
            Year
          </div>
        ),
        accessor: "StatYear",
        width: 80,
        Cell: data => <span style={{ textAlign: "right" }}>{data.value}</span>,
      });
    }
    setColumnTable(columHeaders);
  };

  useEffect(() => {
    getColumnTable();
  }, [filter]);

  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={columnTable}
      disablePagination={false}
    />
  );
};

export default Index;
