import React, { useRef, useState, useEffect } from "react";
import MasterInstrumentTypeService from "services/MasterInstrumentType";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalInsertUpdate from "./ModalInsertUpdate";
import { ConfirmModal } from "components/Common";
import { showToast } from "components/Common";
const Index = () => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenModalInsertUpdate, setIsOpenModalInsertUpdate] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [detailData, setDetailData] = useState({});
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        search: filter.search,
        ...params,
      },
      requestBody: {
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await MasterInstrumentTypeService.GetListMasterInstrumentTypesService(
          params
        );
      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setIsDone(false);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };
  const OnDeleteInstrumentType = async () => {
    const res =
      await MasterInstrumentTypeService.DeleteMasterInstrumentTypeService(
        detailData
      );
    if (res.error) {
      showToast(`${res.error.title}`, 3);
      setLoading(false);
      setIsDone(true);
    } else {
      showToast(`Xóa thành công ${detailData?.instrumentTypeCode}`, 1);
      setIsDone(true);    
      setIsModalConfirmDelete(false); 
    }
  };
  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging, isDone]);

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        setIsEdit={setIsEdit}
        isLoading={loading}
        setIsOpenModalInsertUpdate={setIsOpenModalInsertUpdate}
        onSearch={() => {
          setPaging(prev => ({ ...prev, currentPage: 1 }));
          getTableData({ page: 1 });
        }}
      />
      <Table
        setDetailData={setDetailData}
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setIsEdit={setIsEdit}
        setIsOpenModalInsertUpdate={setIsOpenModalInsertUpdate}
        setIsModalConfirmDelete={setIsModalConfirmDelete}
      />
      {isOpenModalInsertUpdate && (
        <ModalInsertUpdate
          data={isEdit ? detailData : {}}
          filter={filter}
          isEdit={isEdit}
          setIsDone={setIsDone}
          isOpen={isOpenModalInsertUpdate}
          onClose={() => setIsOpenModalInsertUpdate(false)}
        />
      )}
      {isModalConfirmDelete && (
        <ConfirmModal
          modal={isModalConfirmDelete}
          title="Xác nhận xóa?"
          message={`Bạn có chắc chắn muốn xóa instrument type ${detailData?.instrumentTypeCode}?`}
          onToggle={() => {
            setIsModalConfirmDelete(false);
          }}
          onDelete={OnDeleteInstrumentType}
        />
      )}
    </>
  );
};

export default Index;
