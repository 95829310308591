import { useEffect, useRef, useState } from "react";
import DataHospitalFileService from "services/DataHospitalFile";
import DataCollectionService from "services/DataCollection";
import ExportExcel from "./ExportExcel";
import Table from "./Table";
import { scrollToTopRow } from "components/Common/Table";
import DownloadFile from "components/Common/DownloadFile";
import { reportType } from "./constants";
import { DELAY_FETCH } from "constant";
import { showToast, ProcessingModal } from "components/Common";
const Index = ({ sites }) => {
  const timeRef = useRef(null);
  const [error, setError] = useState(null);
  const [requestParam, setRequestParam] = useState(null);
  const [isExportFile, setIsExportFile] = useState(false);
  const reportTypesList = reportType.getList();
  const [tableData, setTableData] = useState(null);
  const [isModalConfirmDownload, setIsModalConfirmDownload] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [filter, setFilter] = useState({
    siteCode: "",
    month: undefined,
    year: undefined,
    dateFrom: new Date("0001-01-01"),
  });

  const [exportFilter, setExportFilter] = useState({
    site: null,
    reportType: reportTypesList[0],
  });

  const getRequestParams = (additionalReqBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        // siteCode: exportFilter.site ? exportFilter.site.value : null,
      },
      requestBody: {
        siteCode: exportFilter.site ? exportFilter.site.value : null,
        month: filter.dateFrom?.getMonth() + 1,
        year: filter.dateFrom?.getFullYear() || 0,
        ...additionalReqBody,
      },
    };
    setRequestParam(reqParams);
    return reqParams;
  };

  const getPatientData = async () => {
    const { params, requestBody } = getRequestParams();
    const data =
      await DataHospitalFileService.GetListMasterFileDataHospitalInGooleDriveService(
        params,
        requestBody
      );
    setTableData(data);
    scrollToTopRow();
  };

  const onValidation = () => {
    const err = {};
    if (!exportFilter.site) {
      err.siteName = "Hãy chọn một SiteName";
    }

    if (!filter.dateFrom || !filter.dateTo) {
      err.date = "Không được bỏ trống ngày";
    } else if (filter.dateFrom > filter.dateTo) {
      err.date = "Ngày bắt đầu <= ngày kết thúc";
    }

    if (filter.countFrom === undefined && filter.countTo === undefined) {
      return err;
    }
    if (
      (!filter.countFrom && filter.countFrom !== 0) ||
      (!filter.countTo && filter.countTo !== 0)
    ) {
      err.count = "Không được bỏ trống số lượng";
    } else if (filter.countFrom > filter.countTo) {
      err.count = "Count From <= To";
    }

    return err;
  };

  const getData = () => {
    setTableData(null);
    timeRef.current = setTimeout(() => {
      getPatientData();
    }, DELAY_FETCH);
  };

  const getExportApi = () => {
    return DataHospitalFileService.DownLoadFileFromDriveService;
  };

  const onAutoPullData = async () => {
    setIsProcessing(true);
    let dataBody = {
      siteCode: exportFilter.site ? exportFilter.site.value : null,
      month: filter.dateFrom?.getMonth() + 1,
      year: filter.dateFrom?.getFullYear() || 0,
    };
    const res = await DataHospitalFileService.PullAllDataService(dataBody);
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsProcessing(false);
    } else {
      const messages = res.length > 0 ? res.join(", ") : "";
      showToast(
        `Tự động lấy dữ liệu bệnh viện ${exportFilter.site.label} thành công.${messages} `,
        1,
        5000
      );
      setIsProcessing(false);
    }
    setIsProcessing(false);
  };
  const onUpdateFromGoogleSheetFile = async () => {
    setIsProcessing(true);
    let dataBody = {
      siteCode: exportFilter.site ? exportFilter.site.value : null,
      month: filter.dateFrom?.getMonth() + 1,
      year: filter.dateFrom?.getFullYear() || 0,
    };
    const res = await DataHospitalFileService.PullDataFileGoogleFromService(
      dataBody
    );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsProcessing(false);
    } else {
      showToast(
        `Update dữ liệu file ${exportFilter.site.label} thành công `,
        1
      );
      setIsProcessing(false);
    }
    setIsProcessing(false);
  };
  useEffect(() => {
    getData();
  }, [exportFilter, paging, filter, isProcessing]);

  // Mặc định lấy site đầu tiên trong danh sách sites
  useEffect(() => {
    if (!sites || !sites.length) return;
    setExportFilter(prev => ({ ...prev, site: sites[0] }));
    setError(null);
  }, [sites]);

  useEffect(() => {
    if (!tableData) return;

    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
  }, [tableData]);

  return (
    <div>
      <ExportExcel
        sites={sites}
        error={error}
        setError={setError}
        exportFilter={exportFilter}
        setExportFilter={setExportFilter}
        reportTypesList={reportTypesList}
        isLoading={!tableData}
        setIsExportFile={setIsExportFile}
        setPaging={setPaging}
        filter={filter}
        setFilter={setFilter}
        onAutoPullData={onAutoPullData}
        onUpdateFromGoogleSheetFile={onUpdateFromGoogleSheetFile}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setDetailData={setDetailData}
        setIsExportFile={setIsExportFile}
        tableType={exportFilter.reportType.value}
      />
      {isExportFile && (
        <DownloadFile
          isOpen={isExportFile}
          isUseFilter
          request={{
            fileId: detailData.fileId,
          }}
          serviceApi={getExportApi()}
          onClose={() => setIsExportFile(false)}
          fileName={`${detailData?.fileName.replace(".xlsx", "")}`}
        />
      )}
      {isProcessing && (
        <ProcessingModal
          isOpen={isProcessing}
          title={
            "Quá trình xử lí có thể mất vài phút, vui lòng chờ trong giây lát..."
          }
          size="500px"
        />
      )}
    </div>
  );
};

export default Index;
