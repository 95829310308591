import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import * as moment from "moment"
import { BASE_URL } from "helpers/url_helper"

const prefix = `${BASE_URL}/system-evaluate`


const fetchTATAutoSystemApi = async (data) => { 
    return await post(`${prefix}/tat-auto-system`, data);
};

const fetchTATHourAutoSystemApi = async (data) => {
    return await post(`${prefix}/tat-auto-system-hour`, data);
};

const fetchTATMachineInSystemApi = async (data) => {
    return await post(`${prefix}/tat-machine-system`, data);
};

const fetchTATInSystemApi = async (data) => {
    return await post(`${prefix}/tat-system`, data);
}; 
export {
    fetchTATAutoSystemApi,
    fetchTATHourAutoSystemApi,
    fetchTATMachineInSystemApi,
    fetchTATInSystemApi
}