import { textFieldTypes } from "constant"
// import {
//   getUserById,
// } from "./app-backend"
import {
  isEmptyValues,
  getTypeByDelimiter,
  ifNull,
  replaceId,
} from "./utilities"

// const getUsersName = async id => {
//   if (isEmptyValues(id)) return
//   const res = await getUserById(id)

//   if (res) return "".concat(ifNull(res.familyName), ifNull(" " + res.givenName))
//   return ""
// }

export const getSystemName = async ({ type, id }) => {
  if (id <= 0) return
  let result = ""
  if (type === textFieldTypes.COMPANY) result = await getCompanyName(id)
  else if (getTypeByDelimiter(type) === textFieldTypes.PROFILE) {
    const profileId = id || type.substring(type.indexOf("_") + 1)
    if (profileId > 0) result = await getProfileName(profileId)
  } else if (type === textFieldTypes.DEPARTMENT)
    result = await getDepartmentName(id)
  else if (type === textFieldTypes.USER) result = await getUsersName(id)

  return result
}

export const setSystemName = async ({ type, id, data, propName }) => {
  const result = await getSystemName({ type, id })
  if (result) data[propName] = result
}

export const setSystemNameArray = async ({ type, data, arrayName }) => {
  for (let index = 0; index < arrayName.length; index++) {
    const propName = arrayName[index]
    const id = data[propName]
    setSystemName({ type, id, data, propName: replaceId(propName, true) })
  }
}

export async function fieldsHandler(items, party) {
  if (!isEmptyValues(party))
    return await Promise.all(
      items.map(async _item => {
        const nameField = replaceId(_item.fieldCode, true)

        _item.value = party[_item.fieldCode]
        _item[nameField] = party[nameField]

        // console.log(replaceId(_item.fieldCode, true))

        await setSystemName({
          type: _item.type,
          id: _item.value || _item.initialValue,
          data: _item,
          propName: replaceId(_item.fieldCode, true),
        })

        return _item
      })
    )
}

export async function individualsHandler(items) {
  return await Promise.all(
    items.map(async _item => {
      const id = _item.managementCompanyId

      await setSystemName({
        type: textFieldTypes.COMPANY,
        id,
        data: _item,
        propName: "managementCompanyName",
      })

      return _item
    })
  )
}

export async function physiciansHandler(items) {
  return await Promise.all(
    items.map(async _item => {
      const id = _item.department

      await setSystemName({
        type: textFieldTypes.DEPARTMENT,
        id,
        data: _item,
        propName: "departmentName",
      })

      return _item
    })
  )
}
