import { Card, Button, Row, Col, Label, Input } from "reactstrap";


const Index = ({ filter, setFilter, isLoading, onSearch }) => {
  return (
    <>
      <Card className="p-3 my-2">
        <Row>
          <Col>
            <div className="d-flex align-items-center">
              <Label
                className="mb-0 text-truncate fw-bold" 
                style={{ minWidth: "100px" }}
              >
                Mã/Tên sản phẩm
              </Label>
              <div style={{ marginLeft: "6px", width: "calc(100% - 120px)" }}>
                <Input
                  value={filter.maSPChung}
                  disabled={isLoading}
                  onChange={e => {
                    setFilter(prev => ({
                      ...prev,
                      maSPChung: e.target.value,
                    }));
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      onSearch();
                    }
                  }}
                />
              </div>
            </div>
          </Col>

          <Col>
            <div className="d-flex align-items-center">
              <Label
                className="mb-0 text-truncate fw-bold"
                style={{ minWidth: "100px" }}
              >
                Mã/Tên Hãng
              </Label>
              <div style={{ marginLeft: "6px", width: "calc(100% - 120px)" }}>
                <Input
                  value={filter.maHang}
                  disabled={isLoading}
                  onChange={e => {
                    setFilter(prev => ({
                      ...prev,
                      maHang: e.target.value,
                    }));
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      onSearch();
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex align-items-center">
              <Label
                className="mb-0 text-truncate fw-bold"
                style={{ minWidth: "100px" }}
              >
                Mã/Tên Lĩnh Vực
              </Label>
              <div style={{ marginLeft: "6px", width: "calc(100% - 120px)" }}>
                <Input
                  value={filter.maLinhVuc}
                  disabled={isLoading}
                  onChange={e => {
                    setFilter(prev => ({
                      ...prev,
                      maLinhVuc: e.target.value,
                    }));
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      onSearch();
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={12} lg={12} xl={2}>
            <Button
              color="primary"
              className="btn-hover-shine"
              onClick={onSearch}
              disabled={isLoading}
            >
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-search mr-1"
                  style={{ fontSize: "1rem" }}
                ></i>
                <p className="mx-1 mb-0">
                  {isLoading ? "Đang tìm.." : "Tìm kiếm"}
                </p>
              </div>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Index;
