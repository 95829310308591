import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Tooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const ActionGroupReport = ({
    onClickExportDataDatabase,
    onClickExportDataInstrument
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickExportDataDatabaseHandler = e =>{
    e.preventDefault();
    onClickExportDataDatabase(e);
  }
  const onClickExportDataInstrumentHandler = e=>{
    e.preventDefault();
    onClickExportDataInstrument(e);
  }
  return (
      <Dropdown
        className="me-2"       
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        <DropdownToggle color="transparent" className="btn-primary">
          <div className="d-flex align-items-center">
            <i className='bx bx-file-blank'></i>
            <p className="mb-0 mx-1">Tạo báo cáo</p>
          </div>
        </DropdownToggle>
        <DropdownMenu>
              <DropdownItem>
                <Button color="transparent" onClick={onClickExportDataDatabaseHandler}>
                  Báo cáo tiến độ công việc
                </Button>
              </DropdownItem>
              <DropdownItem>
                <Button color="transparent" onClick={onClickExportDataInstrumentHandler}>
                  Báo cáo Instrument LIS ERP
                </Button>
              </DropdownItem>
            </DropdownMenu>   
      </Dropdown>
  );
};
export default withTranslation(["common"])(ActionGroupReport);
