import Table, {
  formatValueToMoneyVND,
  getSeqByCellData,
} from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setIsEdit,
  setIsOpenModalInsertUpdate,
  setDetailData,
  setIsModalConfirmDelete
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        // {
        //   Header: "ParentTestCode",
        //   accessor: "parentTestCode",
        //   width: 280,
        // },
        {
          Header: "ParentTestName",
          accessor: "parentTestName",
          width: 490,
        },
        // {
        //   Header: "",
        //   width: 70,
        //   fixed: "left",
        //   headerClassName: "sticky-header",
        //   Cell: data => (
        //     <div className="d-flex">
        //       <Button
        //         size="sm"
        //         className="d-flex w-100 justify-content-center me-1"
        //         style={{ backgroundColor: "#c1c4e8", borderWidth: 0 }}
        //         onClick={e => {
        //           setIsEdit(true);
        //           setIsOpenModalInsertUpdate(true);
        //           setDetailData(data.original);
        //         }}
        //       >
        //         <i className="mdi mdi-pencil text-primary"></i>
        //       </Button>
        //       <Button
        //         size="sm"
        //         className="d-flex w-100 justify-content-center"
        //         style={{ backgroundColor: "#f4dcda", borderWidth: 0 }}
        //         onClick={e => {                 
        //           setIsModalConfirmDelete(true);
        //           setDetailData(data.original);
        //         }}
        //       >
        //         <i className="mdi mdi-delete text-danger"></i>
        //       </Button>
        //     </div>
        //   ),
        // },
      ]}
      disablePagination={false}
      style={{ height: "calc(100vh - 360px)" }}
    />
  );
};

export default Index;
