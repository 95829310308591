import {
  ConfirmModal,
  CustomButton,
  WarningModal,
  ProcessingModal,
  CustomModal,
} from "components/Common";
import { DELAY_FETCH, ModuleIds } from "constant";
import { onDelete, onDeleteToggle } from "helpers/utilities";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
//i18n
import Table, {
  getSeqByCellData,
  scrollToTopRow,
} from "components/Common/Table";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Card, Input, Button, Col, Row } from "reactstrap";
import {
  getAllUsers,
  deleteUserById,
} from "helpers/app-backend/userbackend_helpers";
import { fetchAllDataSampleTypeRouteApi } from "../../helpers/app-backend/samplepathbackend_helpers";
import Switch from "react-switch";
import toastr from "toastr";
import Filter from "./Filter";
import SiteService from "services/Site";
import PopConfirm from "../../components/Common/PopConfirm";

const Users = ({ t }) => {
  const timerRef = useRef(null);
  const [listUser, setListUser] = useState([]);
  const [filter, setFilter] = useState({
    site: null,
    fromDate: "2023-11-01",
    toDate: "2023-12-01",
  });
  const [error, setError] = useState(null);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [keyState, setKeyState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDataFlow, setTotalDataFlow] = useState(0);
  const [dataIns, setDataIns] = useState([]);
  const userName = localStorage.getItem("userName");
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await SiteService.getLabelAndValueSite({
        page: 1,
        size: 1000,
        search: "",
      });
      setSites(res.data);
    };
    getData();
  }, []);

  const ValidFilter = () => {
    let err = {};
    if (!filter.site && !filter.site?.value) {
      err.siteName = "Vui lòng chọn bệnh viện";
    }
    return err;
  };
  const getSampleRouteFlow = async () => {
    let err = ValidFilter();
    if (JSON.stringify(err) !== "{}") {
      setError(err);
    } else {
      let data = {
        dateFrom: filter.fromDate,
        dateTo: filter.toDate,
        typeFlow: filter.typeFlow?.value,
        routeNameSearch: filter.searchRoute ? filter.searchRoute : "",
        siteCode: filter.site?.value,
      };
      try {
        const res = await fetchAllDataSampleTypeRouteApi(data);
        if (res.data.length > 0) {
          let analyzerNamesSet = new Set();
          res.data.forEach(item => {
            item.lsPoint.forEach(point => {
              if (
                !point.startsWith("Mod:") &&
                point !== "Delivery" &&
                point.length > 0
              ) {
                analyzerNamesSet.add(point);
              }
            });
          });

          const analyzerNames = Array.from(analyzerNamesSet);
          const analyzerObjects = analyzerNames.map(name => {
            return {
              insName: name,
              page: 1,
              size: res.totalElements,
              totalElements: res.totalElements,
              totalPages: res.totalElements,
            };
          });
          const formattedData = {
            data: analyzerObjects,
            page: res.page,
            size: res.size, 
            totalElements: analyzerObjects.length, 
            totalPages:  Math.ceil(analyzerObjects.length / paging.pageSize), 
          };
          
          setDataIns(formattedData);
          setTotalDataFlow(res.totalElements);
          toastr.success("Tải dữ liệu thành công");
        } else {
          setDataIns([]);
          toastr.error("Lỗi lấy dữ liệu");
        }
      } catch (e) {
        setDataIns([]);
        toastr.error("Lỗi lấy dữ liệu");
      }
    }
    setIsLoading(false);
  };
  console.log("dataIns", dataIns);

  const columns = [
    {
      filterable: false,
      resiable: false,
      sortable: false,
      fixed: "left",
      width: 50,
      Header: t("STT"),
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      accessor: "insName",
      Header: t("Tên máy"),
      Cell: ({ row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {row.insName}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col className="mb-2" md={12}>
          {" "}
          <Filter
            setError={setError}
            setPaging={setPaging}
            error={error}
            sites={sites}
            keyState={keyState}
            setKeyState={setKeyState}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            xs={24}
            filter={filter}
            setFilter={setFilter}
            onSearch={getSampleRouteFlow}
          />
        </Col>
        <Col md={12}>
          {" "}
          <Table
            paging={paging}
            setPaging={setPaging}
            data={dataIns}
            pageSize={paging.pageSize}
            style={{ height: "calc(100vh - 280px)" }}
            columns={columns}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(withTranslation(["UserPage", "message"])(Users));
