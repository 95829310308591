async function convertBlobToString(blob) {
    const reader = new FileReader();
    reader.readAsText(blob);
    const result = await new Promise((resolve) => {
      reader.onload = function () {
        resolve(reader.result);
      };
    });
    return result;
}

export default convertBlobToString;