import {
  DownLoadFileFromDrive,
  GetListMasterFileDataHospitalInGooleDrive,
  PullAllData,
  PullDataFileGoogleFrom
} from "helpers/app-backend/filegooglebackend_helpers";
import HandleReponse from "utils/handleReponse";

const DownLoadFileFromDriveService = async ({ fileId }) => {
  try {
    const res = await DownLoadFileFromDrive(fileId);
    return res;
  } catch (err) {
    return null;
  }
};

const GetListMasterFileDataHospitalInGooleDriveService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListMasterFileDataHospitalInGooleDrive(
      paramters,
      reqBody
    );
    return res;
  } catch (err) {
    return err;
  }
};

const PullAllDataService = async (reqBody) => {
  let result;
  try {
    const res = await PullAllData(reqBody);
    // result = HandleReponse.insertDataSuccess(res);
    result = res;
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const PullDataFileGoogleFromService = async (reqBody) => {
  let result;
  try {
    const res = await PullDataFileGoogleFrom(reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

export default {
  DownLoadFileFromDriveService,
  GetListMasterFileDataHospitalInGooleDriveService,
  PullAllDataService,
  PullDataFileGoogleFromService
};
