import { spreadSearchQuery } from "helpers/utilities"
import { getBlobFile } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/exports`

const ExportSMRIMapInstrument = siteCode => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-smri-mapinstrument?siteCode=${stecodeurl}`;   
    return getBlobFile(url);
}

const ExportMapInstrumentFull = siteCode => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-mapinstrument-full?siteCode=${stecodeurl}`;   
    return getBlobFile(url);
}

const ExportMapInstrumentERPNull = siteCode => { 
    let stecodeurl = siteCode;
    var url = `${BASE_API_URL}/data-mapinstrument-erp-null?siteCode=${stecodeurl}`;   
    return getBlobFile(url);
}

export {
    ExportSMRIMapInstrument,
    ExportMapInstrumentFull,
    ExportMapInstrumentERPNull,
}