import React, { useEffect, useState } from "react";

import { ModalBody, Row, Col, ModalFooter, Label } from "reactstrap";
import * as moment from "moment";
import { CustomModal, CustomButton, CustomAvField } from "components/Common";
import { AvForm } from "availity-reactstrap-validation";
import Switch from "react-switch";
import { withTranslation } from "react-i18next";
import { getInvalidMessageI18n } from "helpers/utilities";
import toastr from "toastr";
import { updateUserById } from "helpers/app-backend/userbackend_helpers";

export const convertDateFormat = (date, format) => {
  if (!date) return date;
  const date1 = moment(new Date(date)).format(format || "YYYY-MM-DD HH:mm");
  return moment(new Date(date1)).isValid() ? date1 : date;
};

const UsersModal = ({
  isAdmin,
  openEdit,
  toggleEdit,
  t,
  onGetListUser,
  setOpenEdit,
  dataEdit,
  setDataEdit,
}) => {
  const title = t("Cập nhật người dùng")

  const handleSwitchChange = (event, name) => {
    setDataEdit(prevDataUser => ({
      ...prevDataUser,
      [name]: event,
    }));
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onUpdateUserHandler = () => {
    // Kiểm tra các trường bắt buộc
    if (!dataEdit.fullName) {
      toastr.error("Vui lòng điền họ và tên");
      return;
    }
    if (!dataEdit.email) {
      toastr.error("Vui lòng điền email");
      return;
    }
    if (!emailRegex.test(dataEdit.email)) {
      // Email không hợp lệ
      toastr.error("Email không hợp lệ");
      return;
    }

    updateUserById(dataEdit)
      .then(() => {
        toastr.success("Cập nhật thành công");
        onGetListUser();
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setOpenEdit(false);
      });
  };

  return (
    <CustomModal modal={openEdit} title={title} onToggle={toggleEdit} size="xl">
      <ModalBody>
        <AvForm
          id="userForm"
          onValidSubmit={(e, values) => {
            onValidSubmit(e, values);
          }}
        >
          <CustomAvField
            name="uuid"
            type="hidden"
            value={dataEdit.userID || ""}
          />
          <Row>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="userName"
                  type="text"
                  value={dataEdit.userName || dataEdit.userName || ""}
                  label={t("Tên đăng nhập")}
                  errorMessage={getInvalidMessageI18n(t, "Tên đăng nhập")}
                  required={true}
                  disabled={true}
                  maxLength={100}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="fullName "
                  type="text"
                  value={dataEdit.fullName || dataEdit.fullName || ""}
                  errorMessage={getInvalidMessageI18n(t, "Họ và tên")}
                  required={true}
                  label={t("Họ và tên")}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataEdit(prevDataUser => ({
                        ...prevDataUser,
                        fullName: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataEdit(prevDataUser => ({
                      ...prevDataUser,
                      fullName: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <CustomAvField
                name="email "
                type="text"
                value={dataEdit.email || dataEdit.email || ""}
                errorMessage={getInvalidMessageI18n(t, "Email")}
                required={true}
                label={t("Email")}
                maxLength={100}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    setDataEdit(prevDataUser => ({
                      ...prevDataUser,
                      email: event.target.value,
                    }));
                  }
                }}
                onBlur={event => {
                  setDataEdit(prevDataUser => ({
                    ...prevDataUser,
                    email: event.target.value,
                  }));
                }}
              />
            </Col>
            <Col xs="3">
              <>
                <div className="label-group-relative position-relative mb-2">
                  <Label check>{t("Hoạt động")}</Label>
                </div>
                <Switch
                  checked={dataEdit.isActive}
                  onChange={event => handleSwitchChange(event, "isActive")}
                />
              </>
            </Col>
            <Col xs="3">
              <>
                <div className="label-group-relative position-relative mb-2">
                  <Label check>{t("Is Admin")}</Label>
                </div>
                <Switch
                  checked={dataEdit.isAdmin}
                  onChange={event => handleSwitchChange(event, "isAdmin")}
                />
              </>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("Lưu")}
          type="submit"
          color="primary"
          onClick={() => {
            onUpdateUserHandler(dataEdit);
          }}
          className="save-user"
        />
        <CustomButton
          text={t("Hủy")}
          type="button"
          onClick={toggleEdit}
          data-dismiss="openEdit"
          color="danger"
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["userPage", "common"])(UsersModal);
