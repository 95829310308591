import {
GetListCombineStatMonthlyResultInstrument
  } from "helpers/app-backend/combineinstrumentbackend_helpers";

  import HandleReponse from 'utils/handleReponse';

  
  const GetListCombineStatMonthlyResultInstrumentService = async (paramters, reqBody) => {
    try {
      const res = await GetListCombineStatMonthlyResultInstrument(paramters, reqBody);
      return HandleReponse.getListSuccess(res);
    } catch (err) {
      return HandleReponse.getListFail();
    }
  };

  export default {
    GetListCombineStatMonthlyResultInstrumentService
  };
  