import Table, { getSeqByCellData } from "components/Common/Table";
import { Button } from "reactstrap";

const Index = ({
  paging,
  setPaging,
  data,
  pageSize,
  setDetailInstrument,
  setIsShowModalUpdate,
}) => {
  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      pageSize={pageSize}
      columns={[
        {
          Header: "STT",
          accessor: "InstrumentIDSurvey",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        {
          Header: "InstrumentIDSurvey",
          accessor: "instrumentIDSurvey",
          width: 150,
        },
        {
          Header: "SiteCodeNew",
          accessor: "siteCodeNew",
          width: 200,
        },
        {
          Header: "InstrumentNameSurvey",
          accessor: "instrumentNameSurvey",
          minWidth: 250,
        },
        {
          Header: "MapMasterInstrumentID",
          accessor: "mapMasterInstrumentID",
          minWidth: 250,
        },
        {
          Header: "Action",
          width: 80,
          Cell: data => (
            <Button
              className="d-flex w-100 justify-content-center"
              color="link"
              onClick={e => {
                setIsShowModalUpdate(true);
                setDetailInstrument(data.original);
              }}
            >
              Edit
            </Button>
          ),
        },
      ]}
      disablePagination={false}
    />
  );
};

export default Index;
