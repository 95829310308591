import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/SystemAccessHistory`


const getAllSystemAccessHistory = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}      
                                             
export {
    getAllSystemAccessHistory,
}