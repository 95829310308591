import React, { useEffect, useState } from "react";

import { ModalBody, Row, Col, ModalFooter, Label } from "reactstrap";
import * as moment from "moment";
import {
  CustomSelect,
  CustomModal,
  CustomButton,
  CustomAvField,
} from "components/Common";
import { AvForm } from "availity-reactstrap-validation";
import Switch from "react-switch";
import { withTranslation } from "react-i18next";
import { getInvalidMessageI18n } from "helpers/utilities";
import toastr from "toastr";
import { createUser } from "helpers/app-backend/userbackend_helpers";

export const convertDateFormat = (date, format) => {
  if (!date) return date;
  const date1 = moment(new Date(date)).format(format || "YYYY-MM-DD HH:mm");
  return moment(new Date(date1)).isValid() ? date1 : date;
};

const UsersModal = ({
  isAdmin,
  modal,
  toggle,
  onValidSubmit,
  data,
  formEl,
  t,
  onGetListUser,
  setModal,
}) => {
  const userName = localStorage.getItem("userName");
  const [dataUser, setDataUser] = useState({
    userID: "",
    userName: "",
    fullName: "",
    email: "",
    userInput: userName,
    userUpdate: userName,
    isActive: false,
    isAdmin: false,
  });
  const title = t("common:Add") + " " + t("User");

  const handleSwitchChange = (event, name) => {
    setDataUser(prevDataUser => ({
      ...prevDataUser,
      [name]: event,
    }));
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const usernameRegex = /^[a-zA-Z0-9._-]+$/;

  const onCreateUserHandler = () => {
    // Kiểm tra các trường bắt buộc
    if (!dataUser.userName) {
      toastr.error("Vui lòng điền tên người dùng");
      return;
    }
    if (!usernameRegex.test(dataUser.userName)) {
      toastr.error("Tên tài khoản không được chứa ký tự đặc biệt");
      return;
    }
    if (!dataUser.fullName) {
      toastr.error("Vui lòng điền họ và tên");
      return;
    }
    if (!dataUser.email) {
      toastr.error("Vui lòng điền email");
      return;
    }
    if (!emailRegex.test(dataUser.email)) {
      // Email không hợp lệ
      toastr.error("Email không hợp lệ");
      return;
    }
    createUser(dataUser)
      .then(() => {
        toastr.success("Create successfully");
        onGetListUser();
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.title
        ) {
          const errorMessage = error.response.data.title;
          toastr.error(errorMessage);
        } else {
          toastr.error("An error occurred.");
        }
      })
      .finally(() => {
        setModal(false);
        setDataUser({
          userID: "",
          userName: "",
          fullName: "",
          email: "",
          userInput: userName,
          userUpdate: userName,
          isActive: false,
          isAdmin: false,
        });
      });
  };

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle} size="xl">
      <ModalBody>
        <AvForm
          ref={formEl}
          id="userForm"
          onValidSubmit={(e, values) => {
            onValidSubmit(e, values);
          }}
          model={data}
        >
          <CustomAvField
            name="uuid"
            type="hidden"
            value={dataUser.userID || ""}
          />
          <Row>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="userName"
                  type="text"
                  value={dataUser.userName || dataUser.userName || ""}
                  label={t("Tên đăng nhập")}
                  errorMessage={getInvalidMessageI18n(t, "Tên đăng nhập")}
                  required={true}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataUser(prevDataUser => ({
                        ...prevDataUser,
                        userName: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataUser(prevDataUser => ({
                      ...prevDataUser,
                      userName: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="fullName "
                  type="text"
                  value={dataUser.fullName || dataUser.fullName || ""}
                  errorMessage={getInvalidMessageI18n(t, "Họ và tên")}
                  required={true}
                  label={t("Họ và tên")}
                  maxLength={100}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      setDataUser(prevDataUser => ({
                        ...prevDataUser,
                        fullName: event.target.value,
                      }));
                    }
                  }}
                  onBlur={event => {
                    setDataUser(prevDataUser => ({
                      ...prevDataUser,
                      fullName: event.target.value,
                    }));
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <CustomAvField
                name="email"
                type="text"
                value={dataUser.email || dataUser.email || ""}
                errorMessage={getInvalidMessageI18n(t, "Email")}
                required={true}
                label={t("Email")}
                maxLength={100}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    setDataUser(prevDataUser => ({
                      ...prevDataUser,
                      email: event.target.value,
                    }));
                  }
                }}
                onBlur={event => {
                  setDataUser(prevDataUser => ({
                    ...prevDataUser,
                    email: event.target.value,
                  }));
                }}
              />
            </Col>
            <Col xs="3">
              <>
                <div className="label-group-relative position-relative mb-2">
                  <Label check>{t("Hoạt động")}</Label>
                </div>
                <Switch
                  checked={dataUser.isActive}
                  onChange={event => handleSwitchChange(event, "isActive")}
                />
              </>
            </Col>
            <Col xs="3">
              <>
                <div className="label-group-relative position-relative mb-2">
                  <Label check>{t("Is Admin")}</Label>
                </div>
                <Switch
                  checked={dataUser.isAdmin}
                  onChange={event => handleSwitchChange(event, "isAdmin")}
                />
              </>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("Lưu")}
          type="submit"
          color="primary"
          onClick={() => {
            onCreateUserHandler(dataUser);
          }}
          className="save-user"
        />
        <CustomButton
          text={t("Hủy")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          color="danger"
        />
      </ModalFooter>
    </CustomModal>
  );
};

export default withTranslation(["userPage", "common"])(UsersModal);
