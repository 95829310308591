import AreaManager from "./AreaManager";
import {
  connectDirectlyPoints,
  connectThroughExactHitBox,
  area,
  MAP_RATIO,
  specialRoute,
  DataPath_Inlet,
  DataPath_No_Inlet,
} from "../constants";
export { default as DrawManager } from "./DrawManager";
export { default as MapBuilder } from "./MapBuilder";
export { default as ConfigManager } from "./ConfigManager";
export { AreaManager };

export const isStopRender = (current, target) => {
  const crr = { id: current.id, x: current.x, y: current.y };
  const tg = { id: target.id, x: target.x, y: target.y };
  return JSON.stringify(crr) === JSON.stringify(tg);
};

// Speed is Pixels
export const getNewPos = (current, target, speed = 1) => {
  const { x: cX, y: cY } = current;
  const { x: tX, y: tY } = target;
  const newPos = { x: 0, y: 0 };

  if (Math.abs(cX - tX) <= speed - 1) {
    newPos.x = tX;
  } else if (cX < tX) {
    newPos.x = cX + speed;
  } else {
    newPos.x = cX - speed;
  }

  if (Math.abs(cY - tY) <= speed - 1) {
    newPos.y = tY;
  } else if (cY < tY) {
    newPos.y = cY + speed;
  } else {
    newPos.y = cY - speed;
  }

  return newPos;
};

export const getCenterPosOfRect = (x, y, w, h) => {
  return {
    x: Math.round((x * 2 + w) / 2),
    y: Math.round((y * 2 + h) / 2),
  };
};

export const getDistancePixel = (x1, y1, x2, y2) => {
  const a = x2 - x1;
  const b = y2 - y1;
  return Math.round(Math.sqrt(a * a + b * b));
};

export const isConnectDirectly = (pointA_ID, pointB_ID) => {
  const matchID1 = `${pointA_ID}-${pointB_ID}`;
  const matchID2 = `${pointB_ID}-${pointA_ID}`;

  return connectDirectlyPoints.includes(matchID1);
  //  ||
  // connectDirectlyPoints.includes(matchID2)
};

export const isConnectThroughHitBox = (pointA_ID, pointB_ID) => {
  const matchID1 = `${pointA_ID}-${pointB_ID}`;
  const matchID2 = `${pointB_ID}-${pointA_ID}`;

  let p;

  connectThroughExactHitBox.forEach((el) => {
    if ([matchID1].includes(el)) p = el;
  });

  return p;
};

export const getSampleInfoByArea = (sample, areaId) => {
  if (!areaId) {
    return {
      ...sample,
      x: 0,
      y: 0,
      area: null,
    };
  }

  const ps = AreaManager.getInstance().getAreaById(areaId);

  return {
    ...sample,
    x: ps.center.x,
    y: ps.center.y,
    area: ps,
  };
};

export const mappingActionLocationToArea = (actionLocation) => {
  if (!actionLocation) return null;

  const objs = area.getMapActionLocationObj();

  const rs = objs[actionLocation.toLowerCase()];
  if (rs) {
    return rs;
  }

  return null;
};

export const mappingAreaToActionLocation = (areaId) => {
  if (!areaId) return null;

  const objs = area.getMapActionLocationObj();
  let rs = {};

  for (const [key, value] of Object.entries(objs)) {
    rs = {...rs, [value]: key};
  }

  if (rs[areaId]) {
    return rs[areaId];
  }

  return null;
};

export const getDistanceByPixels = (distancePixels) =>
  `${((distancePixels * 0.0264583333 * MAP_RATIO) / 100).toFixed(1)} mét`;

export const isSpecialRoute = (routeName) => {
  let route = specialRoute[routeName];
  return route;
};

export const isRouteData = (id, isInlet, isEndInlet) => {
 
  let route;
  if (isInlet && !isEndInlet) {
    route = DataPath_Inlet[id];
  } else {
    route = DataPath_No_Inlet[id];
  }

  return route;
};

// Lấy vị trí của con trỏ chuột khi di chuyển trong canvas
export const getMousePositionOnCanvas = (canvas, mouseEvent) => {
  if (!canvas || !mouseEvent)
    return {
      x: 0,
      y: 0,
    };
  var rect = canvas.getBoundingClientRect();

  const x = mouseEvent.clientX - rect.left;
  const y = mouseEvent.clientY - rect.top;

  return {
    x,
    y,
  };
};

// Kiểm tra con trỏ đang di chuyển trong 1 vùng hình tròn nào đó trong canvas
// Vị trí sectionX, sectionY tính từ tâm vòng tròn (chưa tính tỉ lệ phóng to/thu nhỏ)
// Vị trí sectionX, sectionY truyền vào chính là vị trí tâm vòng tròn trên bản đồ gốc
export const isMouseEnterCircleSection = ({
  mouseX,
  mouseY,
  sectionX,
  sectionY,
  ratio,
  radius,
}) => {
  const relSectionX = sectionX * ratio;
  const relSectionY = sectionY * ratio;

  return (
    mouseY >= relSectionY - radius &&
    mouseY <= relSectionY + radius &&
    mouseX >= relSectionX - radius &&
    mouseX <= relSectionX + radius
  );
};