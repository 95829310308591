import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import CustomTooltip from "./CustomTooltip";
import { renderToStaticMarkup } from "react-dom/server";
import { Spin, Typography } from "antd";
import font from "components/Common/CustomTable/Roboto-Regular-normal";

const ApexChart = ({ dataTop5Revenue, formatCurrency, loading }) => {
  const categories = dataTop5Revenue.map(item => item.maNhomKhachHang);
  const series = [
    {
      name: "Doanh số",
      data: dataTop5Revenue.map(item => item.doanhSo),
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: categories,
      labels: {
        show: true,
        formatter: function (value) {
          const valueInBillions = value / 1e9;
          return formatCurrency(valueInBillions) + " Tỷ";
        },
      },
      title: {
        text: "Doanh thu (Tỷ)",
        style: {
          fontSize: '16px', 
        },
      },
    },
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        const data = w.globals.initialSeries;
        const tooltipItems = data.map((el, idx) => ({
          id: idx,
          name: el.name,
          value: el.data[dataPointIndex],
          color: w.globals.colors[idx],
        }));
        const total = data[0].data.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const result = tooltipItems.map(el => ({
          ...el,
          percent: ((el.value / total) * 100).toFixed(1),
        }));
        return renderToStaticMarkup(
          <CustomTooltip
            title={`${categories[dataPointIndex]}`}
            data={result}
            formatCurrency={formatCurrency}
            unit={"VNĐ"}
          />
        );
      },
    },
    noData: {
      text: "Không có dữ liệu",
    },
  };

  return (
    <div>
      {loading ? (
        <Spin tip="Loading...">
          <div style={{ height: 389 }} />
        </Spin>
      ) : (
        <>
          <Typography.Title
            className="text-center"
            style={{ fontWeight: "bold", marginBottom: 0, fontSize: "20px" }}
          >
            Top 5 doanh thu theo ngành hàng{" "}
          </Typography.Title>
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </div>
        </>
      )}
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
