import { GetListCombineStatMonthlyResult } from "helpers/app-backend/combineresultbackend_helpers";

import HandleReponse from "utils/handleReponse";

const GetListCombineStatMonthlyResultService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListCombineStatMonthlyResult(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

export default {
    GetListCombineStatMonthlyResultService,
};
