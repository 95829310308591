import React, { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { Card } from "reactstrap";
import CustomTooltip from "./CustomTooltip";
import { fetchTATMachineInSystemApi } from "helpers/app-backend/autosystembackend_helpers";
import { customColors } from "./helper";

function MachineEvaluate24h({ filter }) {
  const hoursArr = [...Array(24).keys()];
  const [series, setSeries] = useState(null);

  useEffect(() => {
    setSeries(null);
    const getData = () => {
      let params = {
        fromDate: filter.fromDate,
        toDate: filter.toDate,
        siteCode: filter.site?.value,
        type: "hour",      
      };
      let Difference_In_Time =
        new Date(filter.toDate).getTime() - new Date(filter.fromDate).getTime();

      let Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      setTimeout(async () => {
        try {
          const responseTotal = await fetchTATMachineInSystemApi(params)

          if (responseTotal.data) {
            const data = responseTotal.data || [];
            const nameSet = new Set();

            data.forEach((machine) => {
              nameSet.add(machine.label2);
            });

            const names = Array.from(nameSet);
            const seriesData = Array(24).fill(0);
            const newSeries = names.map((machineName) => ({
              name: machineName,
              data: JSON.parse(JSON.stringify(seriesData)),
            }));
            data.forEach((machine) => {
              const idx = names.findIndex((name) => name === machine.label2);

              const dataIndex = machine.label1
                ? parseInt(machine.label1)
                : null;

              if (idx > -1 && (dataIndex || dataIndex === 0)) {
                newSeries[idx].data[dataIndex] = Math.floor(
                  machine.value / (Difference_In_Days + 1)
                );
              }
            });

            setSeries(newSeries);
          } else {
            setSeries([]);
          }
        } catch (err) {
          setSeries([]);
        }
      }, 1500);
    };

    getData();
  }, [filter]);

  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Đánh giá công suất máy 24h
            </Typography.Title>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center w-100">
          <div style={{ maxWidth: "894px", width: "100%" }}>
            <ReactApexChart
              options={{
                colors: customColors,
                chart: {
                  height: 500,
                  type: "line",
                  dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                noData: {
                  text: series ? "Không có dữ liệu" : "Đang tải...",
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                  width: 2,
                },
                title: {
                  text: "",
                  align: "left",
                },
                markers: {
                  size: 3,
                  strokeWidth: 0,
                  hover: {
                    size: 4,
                    sizeOffset: 6,
                  },
                },
                xaxis: {
                  categories: series && series.length ? hoursArr : [],
                  title: {
                    text: "Giờ",
                  },
                },
                yaxis: {
                  title: {
                    text: "Số lượng test",
                  },
                },
                legend: {
                  position: "bottom",
                  horizontalAlign: "center",
                },
                tooltip: {
                  custom: function ({ dataPointIndex, w }) {
                    const data = w.globals.initialSeries;
                    const tooltipItems = data.map((el, idx) => ({
                      id: idx,
                      name: el.name,
                      value: el.data[dataPointIndex],
                      color: w.globals.colors[idx],
                    }));
                    const total = tooltipItems.reduce(
                      (acc, el) => acc + el.value,
                      0
                    );
                    const result = tooltipItems.map((el) => ({
                      ...el,
                      percent: ((el.value / total) * 100).toFixed(1),
                    }));
                    return renderToStaticMarkup(
                      <CustomTooltip
                        title={`${hoursArr[dataPointIndex]} Giờ`}
                        data={result}
                      />
                    );
                  },
                },
              }}
              series={series || []}
              type="line"
              height={500}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default MachineEvaluate24h;
