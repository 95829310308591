import React from "react";

export const TooltipItem = ({
  title,
  value,
  color,
  size = "small",
  percent,
  unit,
}) => (
  <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
    {value === 0 ? (
      <></>
    ) : (
      <>
        <div
          style={{
            width: size === "small" ? "12px" : "14px",
            height: size === "small" ? "12px" : "14px",
            borderRadius: "50%",
            backgroundColor: color,
            margin: 0,
            marginRight: size === "small" ? "5px" : "8px",
          }}
        />
        <p
          style={{
            fontSize: size === "small" ? ".78rem" : ".82rem",
            margin: 0,
            whiteSpace: "nowrap",
          }}
        >
          {title}
          {value || value === 0 ? (
            <span style={{ fontWeight: "bold", marginLeft: "5px", color }}>
              {value} {unit}
            </span>
          ) : null}
          {percent || percent === 0 ? (
            <span style={{ fontWeight: "bold", marginLeft: "5px", color }}>
              ({percent}%)
            </span>
          ) : null}
        </p>
      </>
    )}
  </div>
);

const Index = ({ data, title, unit }) => (
  <div>
    <div
      style={{
        backgroundColor: "rgb(233, 241, 248)",
        padding: "5px 10px",
      }}
    >
      <p
        style={{
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: ".75rem",
          margin: 0,
          color: "rgba(18, 21, 78, 0.7)",
        }}
      >
        {title}
      </p>
    </div>
    <div style={{ padding: "7px 10px 5px" }}>
      {data.map(({ id, name, value, color, percent }) => (
        <TooltipItem
          key={id}
          title={name}
          value={value}
          color={color}
          percent={percent}
          unit={unit}
        />
      ))}
    </div>
  </div>
);

export default Index;
