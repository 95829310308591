import React, { useMemo } from "react";
import Map from "./Map";
import { MapBuilder } from "../utils";
import SampleNumberEvt from "../Main/SampleNumberEvt";

const Index = ({
  imageSize,
  width,
  target,
  initial,
  history,
  removeTarget,
  config,
  sampleNumberList,
  areaList,
  setMachineSelected,
}) => {
  
  const ratio = useMemo(() => {
    return width / imageSize.width;
  }, [width, imageSize]);
  const nHeight = Math.round(ratio * imageSize.height);
  const instanceBuilder = MapBuilder.getInstance();
  instanceBuilder.setRatio(ratio);

  if (!ratio) return <div>Loading...</div>;

  return (
    <div style={{ width: "100%", height: "100%", display: "flex" }}>
      <Map
        target={target}
        initial={initial}
        history={history}
        width={width}
        height={nHeight}
        ratio={ratio}
        removeTarget={removeTarget}
        config={config}
        sampleNumberList={sampleNumberList}
      />
      <SampleNumberEvt
        areaList={areaList}
        setMachineSelected={setMachineSelected}
        sampleNumberList={sampleNumberList}
        config={config}
        instanceBuilder={instanceBuilder}
        ratio={ratio}
      />
    </div>
  );
};

export default Index;
