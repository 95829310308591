/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import {formatNumber} from 'utils/formatNumber';

const CustomPaginationComponent = ({
  page,
  pages,
  pageSize,
  previousText,
  nextText,
  onPageChange,
  totalRecords,
  onPageSizeChange,
  loading,
  // Thêm các prop khác nếu bạn cần
}) => {
  const { t } = useTranslation();
  const [inputPage, setInputPage] = useState(page);
  useEffect(() => {
    setInputPage(page);
  }, [page]);

  const handleInputChange = e => {
    const val = e.target.value;
    if (val >= 1 && val <= pages) {
      setInputPage(parseInt(val, 10));
    }
  };

  const handleInputBlur = () => {
    onPageChange(inputPage);
  };

  return (
    <div className="w-100 p-1 position-relative">
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255,255,255,.8)",
            color: "transparent",
          }}
        >
          loading
        </div>
      )}

      <div className="d-flex align-items-center">
        <div style={{ flex: 1 }}>
          <Button
            type="text"
            onClick={() => onPageChange(page - 1)}
            disabled={page <= 1}
            className="w-100"
            color="link"
          >
            « {previousText}
          </Button>
        </div>
        <div className="d-flex align-items-center mx-2">
          <Input
            value={pageSize}
            type="select"
            onChange={e => onPageSizeChange(parseInt(e.target.value, 10))}
            style={{ maxWidth: "130px" }}
          >
            <option value={20}>20 rows/page</option>
            <option value={40}>40 rows/page</option>
            <option value={50}>50 rows/page</option>
            <option value={80}>80 rows/page</option>
            <option value={100}>100 rows/page</option>
            <option value={200}>200 rows/page</option>
          </Input>
          <div className="d-flex align-items-center mx-1" style={{ display: "flex", alignItems: "center",  width: "120px" }}>
            <Input
              type="number"
              min={1}
              max={pages}
              value={inputPage}
              onChange={handleInputChange}
              style={{ width: "70px" }}
              onBlur={handleInputBlur}
            />
            &nbsp;/ {formatNumber(pages)}
          </div>
          <span style={{ margin: "0 40px", width: "180px" }}>
            {t("Total Rows")}:{" "}
            <span style={{ fontWeight: "bold", color: "#556ee6" }}>
              {formatNumber(totalRecords)}
            </span>
          </span>
        </div>
        <div style={{ flex: 1 }}>
          <Button
            color="link"
            type="text"
            onClick={() => onPageChange(page + 1)}
            disabled={page >= pages}
            className="w-100"
          >
            {nextText} »
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomPaginationComponent;
