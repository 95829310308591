import React from "react"
import PropTypes from "prop-types"

import { Search } from "react-bootstrap-table2-toolkit"

const CustomSearchBar = ({
  onSearch,
  searchText,
  onClear,
  placeholder,
  onSubmit,
  style,
  buttons,
  ...rest
}) => {
  const { SearchBar } = Search

  return (
    <div className="search-box me-2 mb-0">
      <div className="position-relative">
        <form onSubmit={onSubmit}>
          <label htmlFor="search-bar-testTable"  style={style} className="search-label mx-3 ">
            <span id="search-bar-testTable-label" className="sr-only">
              Search this table
            </span>
            <input
              id="search-bar-testTable"
              type="text"
              aria-labelledby="search-bar-testTable-label"
              className="form-control rounded table-search-input ps-2 pe-5"
              placeholder={placeholder}
              value={searchText}
              onChange={onSearch}
            />
          </label>
         
          {/* <SearchBar
            {...rest}
            className="table-search-input"
            tableId="testTable"
            onSearch={onSearch}
            placeholder={placeholder}
            searchText={searchText}
            deplay={0}
          /> */}
          {/* <i className="mdi mdi-magnify search-icon"></i> */}
          {/* {searchText.length > 0 && (
            <button type="reset" onClick={onClear}>
              &times;
            </button>
          )} */}
          {buttons && buttons()}
          <button type="submit" className="d-none" onClick={onSubmit}>
            submit
          </button>
        </form>
        
      </div>
    </div>
  )
}

CustomSearchBar.propTypes = {
  searchText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default CustomSearchBar
