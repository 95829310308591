import { ModuleIds } from "constant";
import React, { useEffect, useRef, useState } from "react";
//i18n
// import Table from "components/Common/Table";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import SiteService from "services/Site";
import { Table, Button, message } from "antd";
import ExcelJS from "exceljs/dist/exceljs";
import saveAs from "file-saver";
import Filter from "./Filter";
import CustomPagingTag from "./CustomPagingTag";

const TotalStatistic = ({ t }) => {
  const tableRef = useRef(null);
  const [error, setError] = useState(null);
  const [selectValue, setSelectValue] = useState([]);
  const [dataSoucre, setDataSoucre] = useState([]);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(dataSoucre.length);
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [monthOfYear, setMonthOfYear] = useState([]);
  const dataSource = dataSoucre.slice((page - 1) * pageSize, page * pageSize);

  const [filter, setFilter] = useState({
    siteCode: [],
    dateFrom: new Date(),
    dateTo: new Date(),
  });
  const [sites, setSites] = useState([]);
  const [filterColum, setFilterColumn] = useState([]);
  const handlePageChange = newPage => {
    setLoading(true);
    setPage(newPage);
    setLoading(false);
  };

  const handlePageSizeChange = newPageSize => {
    setLoading(true);
    setPageSize(newPageSize);
    setPage(1);
    setLoading(false);
  };
  useEffect(() => {
    setTotalRecords(dataSoucre.length);
  }, [dataSoucre]);

  const getData = async () => {
    const res = await SiteService.getLabelAndValueSite({
      page: 1,
      size: 1000,
      search: "",
    });
    setSites(res.data);
    setFilterColumn(res.data);
  };
  useEffect(() => {
    getData();
  }, []);
  const columns = [
    {
      filterable: false,
      resizable: false,
      sortable: false,
      fixed: "left",
      width: 50,
      title: t("STT"),
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => <div>{index + 1}</div>,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "customerID",
      key: "customerID",
      width: 150,
      fixed: "left",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "customerName",
      key: "customerName",
      width: 250,
      fixed: "left",
      filters: filterColum,
      onFilter: (value, record) => record.customerName.indexOf(value) === 0,
    },
    {
      title: "Loại collect",
      dataIndex: "collectType",
      key: "collectType",
      width: 100,
      fixed: "left",
      onFilter: (value, record) => record.collectType.indexOf(value) === 0,
    },
    {
      title: "StatMonthlyPatient",
      children: years?.map(year => ({
        title: year.toString(),
        children: monthOfYear
          .filter(item => item.year === year)
          .map(({ month }) => ({
            title: month.toString(),
            dataIndex: `countPatient_${year}_${month}`,
            key: `countPatient_${year}_${month}`,
            width: 50,
            render: (value, record) => {
              const item = record.items.find(
                item => item.statMonth === `${year}-${month}-01T00:00:00`
              );
              return item && item.countPatient !== 0 ? "X" : "";
            },
          })),
      })),
    },
    {
      title: "StatMonthlyResult",
      children: years?.map(year => ({
        title: year.toString(),
        children: monthOfYear
          .filter(item => item.year === year)
          .map(({ month }) => ({
            title: month.toString(),
            dataIndex: `countTestResult_${year}_${month}`,
            key: `countTestResult_${year}_${month}`,
            width: 50,
            render: (value, record) => {
              const item = record.items.find(
                item => item.statMonth === `${year}-${month}-01T00:00:00`
              );
              return item && item.countTestResult !== 0 ? "X" : "";
            },
          })),
      })),
    },
    {
      title: "StatMonthlyResultInstrument",
      children: years?.map(year => ({
        title: year.toString(),
        children: monthOfYear
          .filter(item => item.year === year)
          .map(({ month }) => ({
            title: month.toString(),
            dataIndex: `countTestInstrument_${year}_${month}`,
            key: `countTestInstrument_${year}_${month}`,
            width: 50,
            render: (value, record) => {
              const item = record.items.find(
                item => item.statMonth === `${year}-${month}-01T00:00:00`
              );
              return item && item.countTestInstrument !== 0 ? "X" : "";
            },
          })),
      })),
    },
    {
      title: "StatMonthlyResultDepartment",
      children: years?.map(year => ({
        title: year.toString(),
        children: monthOfYear
          .filter(item => item.year === year)
          .map(({ month }) => ({
            title: month.toString(),
            dataIndex: `countTestDepartment_${year}_${month}`,
            key: `countTestDepartment_${year}_${month}`,
            width: 50,
            render: (value, record) => {
              const item = record.items.find(
                item => item.statMonth === `${year}-${month}-01T00:00:00`
              );
              return item && item.countTestDepartment !== 0 ? "X" : "";
            },
          })),
      })),
    },
  ];
  
  const exportExcelFunc = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Total Statistics");

    // Set up headers
    const headerRow = [
      "STT",
      "Mã khách hàng",
      "Tên khách hàng",
      "Loại collect",
    ];

    // Prepare an array to hold the headers in the desired order
    const tempHeaders = [];

    // Add year and month headers for each statistic

    monthOfYear.forEach(({ month, year }) => {
      tempHeaders.push(`${month}-${year} (StatMonthlyPatient)`);
    });

    monthOfYear.forEach(({ month, year }) => {
      tempHeaders.push(`${month}-${year} (StatMonthlyResult)`);
    });

    monthOfYear.forEach(({ month, year }) => {
      tempHeaders.push(`${month}-${year} (StatMonthlyResultInstrument)`);
    });

    monthOfYear.forEach(({ month, year }) => {
      tempHeaders.push(`${month}-${year} (StatMonthlyResultDepartment)`);
    });

    // Combine the headers in the desired order
    headerRow.push(...tempHeaders);
    worksheet.addRow(headerRow);

    // Add data rows
    dataSource.forEach((record, index) => {
      const dataRow = [
        index + 1,
        record.customerID,
        record.customerName,
        record.collectType,
      ];

      // Create a temporary array to hold the data in the desired order
      const tempData = [];

      // years.forEach(year => {
      monthOfYear.forEach(({ month, year }) => {
        const item = record.items.find(
          item => item.statMonth === `${year}-${month}-01T00:00:00`
        );

        // Append data for each statistic type in the desired order
        tempData.push(item ? (item.countPatient !== 0 ? "X" : "") : ""); // Count Patient
      });
      // });

      monthOfYear.forEach(({ month, year }) => {
        const item = record.items.find(
          item => item.statMonth === `${year}-${month}-01T00:00:00`
        );

        tempData.push(item ? (item.countTestResult !== 0 ? "X" : "") : ""); // Count Test Result
      });

      monthOfYear.forEach(({ month, year }) => {
        const item = record.items.find(
          item => item.statMonth === `${year}-${month}-01T00:00:00`
        );

        tempData.push(item ? (item.countTestInstrument !== 0 ? "X" : "") : ""); // Count Test Instrument
      });

      monthOfYear.forEach(({ month, year }) => {
        const item = record.items.find(
          item => item.statMonth === `${year}-${month}-01T00:00:00`
        );

        tempData.push(item ? (item.countTestDepartment !== 0 ? "X" : "") : ""); // Count Test Department
      });

      // Combine the data into the dataRow
      dataRow.push(...tempData);
      worksheet.addRow(dataRow);
    });

    // Set column widths for better readability
    worksheet.columns.forEach(column => {
      column.width = 25; // Adjust as necessary
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "Total_Statistics.xlsx");
  };

  return (
    <React.Fragment>
      <Row>
        <Filter
          filter={filter}
          setFilter={setFilter}
          sites={sites}
          error={error}
          setError={setError}
          isLoading={false}
          setPaging={setPaging}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          setYears={setYears}
          setMonthOfYear={setMonthOfYear}
          setDataSoucre={setDataSoucre}
          setPage={setPage}
          exportExcelFunc={exportExcelFunc}
        />
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        size="middle"
        scroll={{
          x: "calc(700px + 100%)",
          y: 450,
        }}
      />
      <CustomPagingTag
        page={page}
        pages={Math.ceil(totalRecords / pageSize)}
        pageSize={pageSize}
        previousText="Previous"
        nextText="Next"
        onPageChange={handlePageChange}
        totalRecords={totalRecords}
        onPageSizeChange={handlePageSizeChange}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default withRouter(
  withTranslation(["UserPage", "message"])(TotalStatistic)
);
