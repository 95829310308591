import {
  getInstrument,
  addInstrument,
  getInstrumentBySiteCode,
  getMasterInstrument as getMasterInstrumentApi,
  GetListLabelValueMasterInstrumentApi,
  InsertInstrumentManualApi,
  UpdateInstrumentManualApi 
} from "helpers/app-backend/mapinstrument_helpers";
import HandleReponse from 'utils/handleReponse';

const getInstrumentByCode = async code => {
  let result;
  try {
    const res = await getInstrument(code);
    result = HandleReponse.getByIDSuccess(res)
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const addNewInstrument = async requestBody => {
  let result;
  try {
    const res = await addInstrument(requestBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }

  return result;
};

const getListMapInstrument = async (paramters, reqBody) => {
  try {
    const res = await getInstrumentBySiteCode(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const getMasterInstrument = async (paramters, reqBody) => {
  try {
    const res = await getMasterInstrumentApi(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const InsertInstrumentManualService =  async (paramters, reqBody) => {
  let result;
  try {
    const res = await InsertInstrumentManualApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse_v2(err.response);
  }
  return result;
};

const UpdateInstrumentManualService =  async (paramters, reqBody) => {
  let result;
  try {
    const res = await UpdateInstrumentManualApi(paramters, reqBody);
    result = HandleReponse.insertDataSuccess(res);
  } catch (err) {
    result = HandleReponse.catchJSONErrorReponse(err.response);
  }
  return result;
};

const GetListLabelValueMasterInstrumentService = async query => {
  try {
    const res = await GetListLabelValueMasterInstrumentApi(query);
    return res;
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

export default {
  getInstrumentByCode,
  addNewInstrument,
  getListMapInstrument,
  getMasterInstrument,
  InsertInstrumentManualService,
  UpdateInstrumentManualService,
  GetListLabelValueMasterInstrumentService
};
