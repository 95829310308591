import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Card, CardHeader, Collapse, CardBody } from "reactstrap"
import { isEmptyValues } from "helpers/utilities"

export const CollapsibleModuleWrapper = props => {
  const { onToggle, defaultTab, children } = props
  const [isOpened, setIsOpened] = useState({ [defaultTab]: true })

  const toggleAccordion = (tabId, open) => {
    setIsOpened({ [tabId]: open })
    if (onToggle) onToggle(tabId)
  }

  useEffect(() => {
    if (!isEmptyValues(defaultTab)) toggleAccordion(defaultTab, true)
  }, [defaultTab])

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isOpened: !!isOpened?.[child?.props?.tabId],
        onClick: toggleAccordion,
      })
    }
    return child
  })

  return <>{childrenWithProps}</>
}

const CollapsibleModule = ({ onClick, title, isOpened, children, tabId }) => {
  return (
    <Card className="card-default mb-1">
      <CardHeader onClick={() => onClick(tabId, !isOpened)}>
        <h5 className="mb-0">
          <a className="text-inherit">{title || "Title is here"}</a>
        </h5>
      </CardHeader>
      <Collapse isOpen={isOpened}>
        <CardBody className="border-top">{children || "No content"}</CardBody>
      </Collapse>
    </Card>
  )
}

CollapsibleModule.propTypes = {
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.any,
  isOpened: PropTypes.bool,
  defaultTab: PropTypes.string,
  tabId: PropTypes.string.isRequired,
}

CollapsibleModule.defaultProps = {
  onToggle: () => {},
}

export default CollapsibleModule
