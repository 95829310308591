import React, { useEffect, useRef, useState } from "react";
import { Typography, Space, Input, Button, Spin } from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";

import { CustomCard, CustomBox } from "./../../Main/custom";
import TableDetail from "./Table";
const ListDetailSamplePath = ({
  data = [],
  onClickValue,
  loading,
  totalData,
}) => {
  return (
    <CustomCard style={{ padding: "10px 20px" }}>
      <div className="d-flex justify-content-between mb-1 align-items-center">
        <Typography.Title
          level={4}
          className="mb-0 pt-2 px-3"
          style={{ color: "#1677ff", fontWeight: "bold" }}
        >
          Menu test chạy trên luồng
        </Typography.Title>
      </div>

      <div>
        <TableDetail
          data={data}
          onClickValue={onClickValue}
          loading={loading}
          totalData={totalData}
        />
      </div>
    </CustomCard>
  );
};

export default ListDetailSamplePath;
