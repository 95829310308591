import React from "react";
import Table, {getSeqByCellData} from "components/Common/Table";
import {reportType} from './constants';
import moment from "moment";
import {formatNumber} from 'utils/formatNumber';
import {DISPLAY_DATE_TIME_FORMAT, DISPLAY_YEAR_MONTH_FORMAT} from 'constant';

const Index = ({ paging, setPaging, data, tableType, pageSize }) => {
  const getColumns = () => {
    if(tableType === reportType.PATIENT) {
      return [
        {
          Header: "STT",
          accessor: "siteCode",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_YEAR_MONTH_FORMAT)}
            </div>
          ),
        },
        {
          Header: "CountPatient",
          accessor: "countPatient",
          width: 100,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-end">
              {formatNumber(value)}
            </div>
          ),
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: "UserI",
          accessor: "userI",
          width: 100,
        },
        {
          Header: "InTime",
          accessor: "inTime",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
            </div>
          ),
        },
        {
          Header: "UserU",
          accessor: "userU",
          width: 100,
        },
        {
          Header: "UpdateTo",
          accessor: "updateTo",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
            </div>
          ),
        },
      ]
    }

    if(tableType === reportType.RESULT) {
      return [
        {
          Header: "STT",
          accessor: "siteCode",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_YEAR_MONTH_FORMAT)}
            </div>
          ),
        },
        {
          Header: "TestCode",
          accessor: "testCode",
          width: 100,
        },
        {
          Header: "TestName",
          accessor: "testName",
          width: 240,
        },
        {
          Header: "CountTest",
          accessor: "countTest",
          width: 100,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-end">
              {formatNumber(value)}
            </div>
          ),
        },
        {
          Header: "Description",
          accessor: "description",
          width: 200,
        },
        {
          Header: "SampleType",
          accessor: "sampleType",
          width: 120,
        },
        {
          Header: "SampleTypeName",
          accessor: "sampleTypeName",
          width: 160,
        },
        {
          Header: "Category",
          accessor: "category",
          width: 100,
        },
        {
          Header: "CategoryName",
          accessor: "categoryName",
          width: 140,
        },
        {
          Header: "UserI",
          accessor: "userI",
          width: 100,
        },
        {
          Header: "InTime",
          accessor: "inTime",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
            </div>
          ),
        },
        {
          Header: "UserU",
          accessor: "userU",
          width: 100,
        },
        {
          Header: "UpdateTo",
          accessor: "updateTo",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
            </div>
          ),
        },
      ]
    }

    if(tableType === reportType.RESULT_INSTRUMENT) {
      return [
        {
          Header: "STT",
          accessor: "siteCode",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSeqByCellData(data)}
            </div>
          ),
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_YEAR_MONTH_FORMAT)}
            </div>
          ),
        },
        {
          Header: "InstrumentID",
          accessor: "instrumentID",
          width: 100,
        },
        {
          Header: "TestCode",
          accessor: "testCode",
          width: 90,
        },
        {
          Header: "InstrumentName",
          accessor: "instrumentName",
          minWidth: 200,
        },
        {
          Header: "TestName",
          accessor: "testName",
          minWidth: 230,
        },
        {
          Header: "CountTest",
          accessor: "countTest",
          width: 90,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-end">
              {formatNumber(value)}
            </div>
          ),
        },
        {
          Header: "Description",
          accessor: "description",
          minWidth: 200,
        },
        {
          Header: "SampleType",
          accessor: "sampleType",
          width: 120,
        },
        {
          Header: "SampleTypeName",
          accessor: "sampleTypeName",
          width: 160,
        },
        {
          Header: "Category",
          accessor: "category",
          width: 100,
        },
        {
          Header: "CategoryName",
          accessor: "categoryName",
          width: 140,
        },
        {
          Header: "UserI",
          accessor: "userI",
          width: 100,
        },
        {
          Header: "InTime",
          accessor: "inTime",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
            </div>
          ),
        },
        {
          Header: "UserU",
          accessor: "userU",
          width: 100,
        },
        {
          Header: "UpdateTo",
          accessor: "updateTo",
          width: 120,
          Cell: ({ value }) => (
            <div className="d-flex w-100 justify-content-center">
              {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
            </div>
          ),
        },
      ]
    }

    return [
      {
        Header: "STT",
        accessor: "siteCode",
        width: 50,
        Cell: data => (
          <div className="d-flex w-100 justify-content-center">
            {getSeqByCellData(data)}
          </div>
        ),
      },
      {
        Header: "StatMonth",
        accessor: "statMonth",
        width: 120,
        Cell: ({ value }) => (
          <div className="d-flex w-100 justify-content-center">
            {moment(value).format(DISPLAY_YEAR_MONTH_FORMAT)}
          </div>
        ),
      },
      {
        Header: "TestCode",
        accessor: "testCode",
        width: 100,
      },
      {
        Header: "TestName",
        accessor: "testName",
        minWidth: 200
      },
      {
        Header: "LocationId",
        accessor: "locationID",
        width: 100,
      },
      {
        Header: "LocationName",
        accessor: "locationName",
        minWidth: 200
      },
      {
        Header: "CountTest",
        accessor: "countTest",
        width: 90,
        Cell: ({ value }) => (
          <div className="d-flex w-100 justify-content-end">
            {formatNumber(value)}
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        minWidth: 200,
      },
      {
        Header: "SampleType",
        accessor: "sampleType",
        width: 120,
      },
      {
        Header: "SampleTypeName",
        accessor: "sampleTypeName",
        width: 160,
      },
      {
        Header: "Category",
        accessor: "category",
        width: 100,
      },
      {
        Header: "CategoryName",
        accessor: "categoryName",
        width: 140,
      },
      {
        Header: "UserI",
        accessor: "userI",
        width: 100,
      },
      {
        Header: "InTime",
        accessor: "inTime",
        width: 120,
        Cell: ({ value }) => (
          <div className="d-flex w-100 justify-content-center">
            {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
          </div>
        ),
      },
      {
        Header: "UserU",
        accessor: "userU",
        width: 100,
      },
      {
        Header: "UpdateTo",
        accessor: "updateTo",
        width: 120,
        Cell: ({ value }) => (
          <div className="d-flex w-100 justify-content-center">
            {moment(value).format(DISPLAY_DATE_TIME_FORMAT)}
          </div>
        ),
      },
    ]
  }

  return (
    <Table
      paging={paging}
      setPaging={setPaging}
      data={data}
      columns={getColumns()}
      pageSize={pageSize}
    />
  );
};

export default Index;
