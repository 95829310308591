import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "components/Common/InputText";
import { showToast } from "components/Common";
import MapInstrumentService from "services/Instrument";
import Select from "react-select";
import { UpdateMapInstrumentSurveyManual } from "../../helpers/app-backend/instrumentsurveybackend_helpers";

const Schema = Yup.object().shape({
  instrumentIDSurvey: Yup.number().required("Không được bỏ trống"),
});

const Index = ({
  isOpen,
  onClose,
  data,
  getDataTable,
  paging,
  dataFilter,
}) => {
  const [loading, setIsLoading] = useState(false);
  const [selectMasterInstrument, setSelectMasterInstrument] = useState([]);
  const [dataMasterInstrument, setDataMasterInstrument] = useState([]);
  const getdataMasterInstrument = async () => {
    const res =
      await MapInstrumentService.GetListLabelValueMasterInstrumentService({
        search: "",
      });
    res.unshift({ value: null, label: "Giá trị rỗng(NULL)" });
    setDataMasterInstrument(res);
  };

  const formik = useFormik({
    initialValues: {
      instrumentIDSurvey: data?.instrumentIDSurvey || "",
      instrumentNameSurvey: data?.instrumentNameSurvey || "",
      mapMasterInstrumentID: data?.mapMasterInstrumentID || "",
    },
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      updateInstrument(values);
    },
  });

  const getRequestParams = (_params = {}, _requestBody = {}) => {
    const reqParams = {
      params: {
        ..._params,
      },
      requestBody: {
        ..._requestBody,
      },
    };
    return reqParams;
  };

  const updateInstrument = async (_requestBody = {}, _params = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);

    const res = await UpdateMapInstrumentSurveyManual(requestBody);
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
    } else {
      showToast("Cập nhật thành công", 1);
      getDataTable(dataFilter, paging);
      onClose();
    }
  };
  useEffect(() => {
    if (dataMasterInstrument.length > 0) return;
    getdataMasterInstrument();
  }, []);

  useEffect(() => {
    if (!dataMasterInstrument || !dataMasterInstrument.length) return;
    let selectMasterInstrument = dataMasterInstrument.find(
      x => x.value == data?.mapMasterInstrumentID
    );
    if (selectMasterInstrument) {
      setSelectMasterInstrument(selectMasterInstrument);
    } else {
      setSelectMasterInstrument(dataMasterInstrument[0]);
    }
  }, [dataMasterInstrument]);

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "650px", width: "100%" }}>
      <ModalHeader>
        CẬP NHẬT MapMasterInstrumentID
      </ModalHeader>
      <ModalBody>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <InputText
              label="InstrumentIDSurvey"
              name="InstrumentIDSurvey"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentIDSurvey}
              errors={formik.errors}
              disabled={true}
              isRequired
            />
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <InputText
              label="InstrumentNameSurvey"
              name="InstrumentNameSurvey"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentNameSurvey}
              errors={formik.errors}
              disabled={true}
            />
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={6} lg={8}>
            <Label className="mb-0 text-truncate" style={{ minWidth: "98px" }}>
              MapMasterInstrumentID
            </Label>
            <div style={{ display: "flex" }}>
              <Select
                options={dataMasterInstrument || []}
                placeholder=""
                value={selectMasterInstrument}
                onChange={val => {
                  formik.setFieldValue("mapMasterInstrumentID", val.value);
                  setSelectMasterInstrument(val);
                }}
                isDisabled={loading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid  "rgb(206, 212, 218)"`,
                    maxWidth: "400px",
                    width: "400px",
                  }),
                }}
              />
              {selectMasterInstrument && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("mapMasterInstrumentID", null);
                    setSelectMasterInstrument({
                      value: null,
                      label: "Giá trị rỗng(NULL)",
                    });
                  }}
                  className="clear-button"
                  color="link"
                >
                  <i className="mdi mdi-close"></i>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
        {loading && (
          <Row
            style={{ width: "80%", justifyContent: "center", display: "flex" }}
          >
            <Label>
              Quá trình xử lí này có thể mất vài phút, vui lòng chờ đến khi kết
              thúc...
            </Label>
            <div className="mt-1">
              <Progress color="primary" animated value="100" />
            </div>
          </Row>
        )}
        <div>
          <Button
            disabled={loading}
            color="primary"
            onClick={formik.handleSubmit}
          >
            Đồng ý
          </Button>{" "}
          <Button color="danger" onClick={onClose}>
            Đóng
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
