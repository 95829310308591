import {
        getLisErpProducts       
} from "helpers/app-backend/erpproductbackend_helpers";
import HandleReponse from 'utils/handleReponse';

  
  const getLisErpProductsService = async (paramters, reqBody) => {
    try {
      const res = await getLisErpProducts(paramters, reqBody);
      return HandleReponse.getListSuccess(res);
    } catch (err) {
      return HandleReponse.getListFail();
    }
  };

  export default {
    getLisErpProductsService
  };
  