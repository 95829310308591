import React from "react";
import { Spin, Typography } from "antd";
import { Card } from "reactstrap";
import Chart from "react-apexcharts";
import { renderToStaticMarkup } from "react-dom/server";
import CustomTooltip from "./CustomTooltip";
import { color } from "./constants";
import { BoxLoading } from "react-loadingg";
const Index = ({ avgFlTimeData, unit, isLoading }) => {
  const { categories = [], series = [] } = avgFlTimeData || {};

  const options = {
    colors: [
      "#132399",
      "#F66026",
      "#1E8CF9",
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,    
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth:
          20 + 60 / (1 + 30 * Math.exp(-categories.length / 3)) + "%",
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories,
      title: {
        text: "Giờ",
        style: {
          fontFamily: "Roboto",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Số lượng test trung bình",
        style: {
          fontFamily: "Roboto",
          fontSize: "13px",
        },
      },
    },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      custom: function ({ dataPointIndex, w }) {
        const chartData = w.globals.initialSeries;

        const data = [];
        chartData.forEach((item, index) => {
          item.data.forEach((el, idx) => {
            if (idx === dataPointIndex) {
              data.push({
                id: idx,
                name: item.name,
                value: el,
                color: w.globals.colors[index],
              });
            }
          });
        });
        const total = data.reduce((acc, el) => acc + el.value, 0);
        const result = data.map((el) => ({
          ...el,
          percent: ((el.value / total) * 100).toFixed(1),
        })).sort((a,b)=> b.percent - a.percent);
        return renderToStaticMarkup(
          <CustomTooltip
            title={categories[dataPointIndex]}
            data={result}
            unit={unit}
          />
        );
      },
    },
  };

  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Phân bố test trên hệ tự động theo khung giờ
            </Typography.Title>
          </div>
        </div>
        <div className="d-flex justify-content-center w-100">
          {!isLoading ? (
            <div className="w-100" style={{ maxWidth: "894px" }}>
              <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
              />
            </div>
          ) : (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "450px" }}
            >
              <BoxLoading size="large" />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Index;
