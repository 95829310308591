import React from "react"
import PropTypes from "prop-types"

import ButtonDropdownGroup from "components/Common/ButtonDropdownGroup"

const HeaderButtons = ({
  children,
  onEdit,
  onDelete,
  onClone,
  resource,
  external,
}) => {
  return (
    <div className="ms-auto">
      <div className="toolbar button-items text-end">
        {children}

        {!external && (
          <ButtonDropdownGroup
            onEditClick={onEdit}
            onDeleteClick={onDelete}
            onCloneClick={onClone}
            resource={resource}
          />
        )}
      </div>
    </div>
  )
}

HeaderButtons.prototype = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  resource: PropTypes.string,
  external: PropTypes.bool,
}

export default HeaderButtons
