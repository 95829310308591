
import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { BoxLoading } from "react-loadingg";
const PieChart = ({ isLoading, options, chartSeries }) => {
  const [defaultOptions, setDefaultOptions] = useState({
    chart: {
      width: 500,
      type: "pie",
    },
    labels: ["Team A", "Team B", "Team C", "Team D", "Team E", "1"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    fill: {
      type: "gradient",
    }, 
    stroke:{
      width: 0
    }    
  });

  useEffect(() => {  
    if (options) {
      setDefaultOptions(options);
    }
  }, [options]);

  return (
    <div id="chart">
      {isLoading ? (
        <div className="text-center">
          <BoxLoading />
        </div>
      ) : (
        <ReactApexChart
          options={defaultOptions}
          series={chartSeries || []}
          type="pie"
          width={800}
        />
      )}
    </div>
  );
};

export default PieChart;
