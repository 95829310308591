import Table, { getSTTCellData, formatValueToMoneyVND } from "components/Common/Table";
import { format } from 'date-fns';

const Index = ({paging, setPaging, data, pageSize}) => {
  return (
    <Table
    paging={paging}
    setPaging={setPaging}
    data={data}  
    pageSize={pageSize}
    columns={[
        {
          Header: "STT",
          accessor: "",
          width: 50,
          Cell: data => (
            <div className="d-flex w-100 justify-content-center">
              {getSTTCellData(data, paging)}
            </div>
          ),
        },
        {
          Header: "SiteCode",
          accessor: "siteCode",
          width: 250,
        },
        {
            Header: "SiteName",
            accessor: "siteName",
            width: 250,
        },
        {
          Header: "StatMonth",
          accessor: "statMonth",
          width: 150,
          Cell: ({ value }) => (
            <div>
              {format(new Date(value), 'yyyy-MM')}
            </div>
          ),
        },
        {
          Header: "MasterTestCode",
          accessor: "masterTestcode",
          width: 350,
        },       
        {
            Header: "Doanh thu",
            accessor: "doanhSo",
            Cell: data => (
              <div className="d-flex w-100 justify-content-end">{formatValueToMoneyVND(data.value)}</div>
            ),
            width: 250,
        },
        {
            Header: "CommonName",
            accessor: "commonName",
            width: 250,
        },
      
      ]}
      disablePagination = {false}
    />
  );
};

export default Index;
