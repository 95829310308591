import Select from "react-select";
import { Button, Card, Col, Label, Row, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

const Index = ({
  dataFilter,
  setDataFilter,
  showStatistics,
  setShowStatistics,
  employeesOptions,
}) => {
  const options = [
    {
      value: "StatisticsCustomer",
      label: "Thống kê doanh số thực theo khách hàng",
    },
    {
      value: "StatisticsDistribution",
      label: "Thống kê doanh số thực theo mã hàng phân phối",
    },
  ];
  const selectedOption = options.find(
    option => option.value === showStatistics
  );

  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={4} xl={4}>
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">KAM</Label>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 4 }}>
              <Select
                className="custom-select"
                options={employeesOptions}
                placeholder="Chọn KAM"
                value={employeesOptions
                  .filter(option =>
                    dataFilter.employeeSelect.includes(option.value)
                  )
                  .map(option => ({
                    value: option.value,
                    label: option.label,
                  }))}
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value)
                    : [];
                  setDataFilter(prev => ({
                    ...prev,
                    employeeSelect: selectedValues,
                  }));
                }}
                isDisabled={false}
                isClearable
                isMulti
              />
            </div>
          </div>
        </Col>

        <Col sm={12} md={4} xl={4}>
          <div className="d-flex align-items-center">
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 4 }}>
              <Select
                className="custom-select"
                options={options}
                placeholder="Chọn loại thống kê hiển thị"
                value={selectedOption}
                onChange={selectedOption =>
                  setShowStatistics(
                    selectedOption ? selectedOption.value : null
                  )
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
