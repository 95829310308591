import { spreadSearchQuery } from "helpers/utilities";
import { get, put, post, del, delData } from "../api_helper";
import { BASE_URL } from "helpers/url_helper";

const BASE_API_URL = `${BASE_URL}/master-grouptest`;

const GetListMasterGroupTestsApi = payload => {
  let q = spreadSearchQuery(payload);
  return get(`${BASE_API_URL}/ls-master-grouptest?${q}`);
};

const InsertMasterGroupTestApi = reqBody => {
  return post(`${BASE_API_URL}/`, reqBody);
};

const UpdateMasterGroupTestApi = reqBody => {
  return put(`${BASE_API_URL}/`, reqBody);
};

const DeleteMasterGroupTestApi = reqBody => {
  return post(`${BASE_API_URL}/delete`, reqBody);
};

const GetLisLabelValueMasterGroupTestApi = () => {
  return get(`${BASE_API_URL}/ls-label-value`);
};

const InsertTestCodeToMasterGroupTestApi = (parameters, reqBody) => {
  let q = spreadSearchQuery(parameters);
  return post(`${BASE_API_URL}/test-code?${q}`, reqBody);
};

const GetLisLabelValueTestCodeOfGroupTestApi = (parameters) => {
  let q = spreadSearchQuery(parameters);
  return get(`${BASE_API_URL}/test-code?${q}`);
};

export {
  GetListMasterGroupTestsApi,
  InsertMasterGroupTestApi,
  UpdateMasterGroupTestApi,
  DeleteMasterGroupTestApi,
  GetLisLabelValueMasterGroupTestApi,
  InsertTestCodeToMasterGroupTestApi,
  GetLisLabelValueTestCodeOfGroupTestApi
};
