import React, { useEffect, useState } from "react";
import { Col, Row, DatePicker, Button } from "antd";
import { CustomCard, CustomBox } from "./custom";
import dayjs from "dayjs";
import { Label } from "reactstrap";
import Select from "react-select";
const FlowOptions = [
  { value: "0", label: "Tất cả" },
  { value: "1", label: "Hệ" },
  { value: "2", label: "Máy lẻ" },
  { value: "3", label: "Hệ + Máy lẻ" },
  { value: "4", label: "Lỗi" },
];

const Filter = ({
  filter,
  setFilter,
  onSearch,
  isLoading,
  setIsLoading,
  setKeyState,
  keyState,
  sites,
  error,
  setError,
  setPaging,
}) => {
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
  const { RangePicker } = DatePicker;
  const [toDate, setToDate] = useState(dayjs(filter.toDate));
  const [fromDate, setFromDate] = useState(dayjs(filter.fromDate));
  const [selectedDates, setSelectedDates] = useState([]);
  const handleSelectChange = (value, label) => {
    setFilter(prev => ({
      ...prev,
      typeFlow: value,
    }));
  };

  const handleDateChange = dates => {
    setSelectedDates(dates);
    if (dates !== null) {
      setFromDate(dayjs(dates[0]).format("YYYY-MM-DD"));
      setToDate(dayjs(dates[1]).format("YYYY-MM-DD"));
    }
  };

  return (
    <CustomCard style={{ padding: "10px 20px" }}>
      <Row className="d-flex">
        <Col md={24} xl={8} className="mt-4">
          <div className="d-flex align-items-center">
            <span style={{ color: "#1677ff", fontWeight: "bold" }}>
              SiteName
            </span>
            <div
              style={{
                flex: 1,
                marginLeft: "10px",
                marginRight: "10px",
                zIndex: 5,
              }}
            >
              <Select
                options={sites}
                placeholder=""
                value={filter.site}
                // style={{ width: "15rem" }}
                onChange={val => {
                  setFilter(prev => ({ ...prev, site: val }));
                  setPaging(prev => ({ ...prev, currentPage: 1 }));
                  if (error) {
                    setError(null);
                  }
                }}
                isDisabled={isLoading}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${
                      error && error.siteName ? "red" : "rgb(206, 212, 218)"
                    }`,
                  }),
                }}
              />
            </div>
          </div>
          {error && error.siteName ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.siteName}
            </p>
          ) : null}
        </Col>
        <Col md={24} xl={8} className="mt-4">
          <div className="d-flex align-items-center">
            <span style={{ fontWeight: "bold", color: "#1677ff" }}>
              Thời gian
            </span>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 5 }}>
              <RangePicker
                disabled={isLoading}
                // className="ml-2 mr-4"
                style={{ width: "100%", padding: "7px" }}
                defaultValue={[
                  dayjs(fromDate, dateFormatList),
                  dayjs(toDate, dateFormatList),
                ]}
                format={dateFormatList}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </Col>
        <Col md={24} xl={4} className="mt-4">
          <div className="d-flex align-items-center">
            <span
              style={{
                color: "#1677ff",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              Luồng
            </span>
            <div style={{ flex: 1, marginLeft: "6px", zIndex: 5 }}>
              <Select
                value={filter.typeFlow}
                disabled={isLoading}
                dropdownStyle={{ zIndex: 10000 }}
                style={{ width: "90%" }}
                onChange={handleSelectChange}
                options={FlowOptions}
              />
            </div>
          </div>
        </Col>
        <Col md={24} xl={4} className="mt-4">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              disabled={isLoading}
              type="primary"
              style={{ width: "50%" }}
              onClick={() => {
                setKeyState(!keyState);
                setIsLoading(true);
                setFilter(prev => ({
                  ...prev,
                  pageIndex: 1,
                  fromDate: fromDate,
                  toDate: toDate,
                }));
                onSearch();
              }}
            >
              {!isLoading ? "Áp dụng" : "Đang tìm..."}
            </Button>
          </div>
        </Col>
      </Row>
    </CustomCard>
  );
};

export default Filter;
