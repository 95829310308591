import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delData } from "../api_helper"
import { BASE_URL } from "helpers/url_helper"

const BASE_API_URL = `${BASE_URL}/combine-result`

const GetListCombineStatMonthlyResult = (parameters, reqBody) => {
    let q = spreadSearchQuery(parameters)
    return post(`${BASE_API_URL}/ls-result-price?${q}`,reqBody)
}

export {
    GetListCombineStatMonthlyResult
}