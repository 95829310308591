import React, { useRef, useState, useEffect, useMemo } from "react";
import CombineResultService from "services/CombineResult";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import { startOfMonth, format } from "date-fns";
import SubFilter from "./SubFilter";
const Index = ({ sites }) => {
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState([]);
  const [dataSelectSite, setDataSelectSite] = useState([]);
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    siteCode: [],
    dateFrom: new Date("0001-01-01"),
    dateTo: new Date("0001-01-01"),
    isNullPrice: true,    
    masterTestcode: "",
    countTestFrom: undefined,
    countTestTo: undefined,
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        ...params,
      },
      requestBody: {
        siteCode: filter.siteCode,
        dateFrom: format(startOfMonth(filter.dateFrom), "yyyy-MM-dd"),
        dateTo: format(startOfMonth(filter.dateTo), "yyyy-MM-dd"),
        isNullPrice: filter.isNullPrice,       
        countTestFrom: filter.countTestFrom,
        countTestTo: filter.countTestTo,
        masterTestcode: filter.masterTestcode,
        ...requestBody,
      },
    };

    return reqParams;
  };

  const onValidation = () => {
    const err = {};
    if (!filter.dateFrom || !filter.dateTo) {
    } else if (filter.dateFrom.getTime() > filter.dateTo.getTime()) {
      err.date = "Ngày bắt đầu <= ngày kết thúc";
    }
    if (
      (!filter.countTestFrom && filter.countTestFrom !== 0) ||
      (!filter.countTestTo && filter.countTestTo !== 0)
    ) {
    } else if (filter.countTestFrom > filter.countTestTo) {
      err.count = "CountTest From <= To";
    }

    return err;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    if (error) return;
    const err = onValidation();
    if (JSON.stringify(err) !== "{}") {
      setError(err);
      return;
    }

    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res =
        await CombineResultService.GetListCombineStatMonthlyResultService(
          params,
          requestBody
        );

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      setTableData(res);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging]);

  const onChangeData = () => {
     setPaging(prev => ({ ...prev, currentPage: 1 }));
    getTableData({ page: 1 });
  };

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        sites={sites}
        error={error}
        setError={setError}
        isLoading={loading}
        setPaging={setPaging}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
      />
      <SubFilter
        isLoading={loading}
        filter={filter}
        setFilter={setFilter}
        error={error}
        setError={setError}
        onSearch={() => {
          onChangeData();
        }}
      />
      <Table
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setSiteCodeDetail={setSiteCodeDetail}
        setIsOpenModalDetail={setIsOpenModalDetail}
      />
    </>
  );
};

export default Index;
