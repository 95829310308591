class ContextManager {
     static getContext = () => {
        try {
            const canvas = document.getElementById('map');
            if(canvas) {
                return canvas.getContext('2d');
            }
            return null;
        }catch (e) {
            return null;
        }
    }
    
    static getCanvasSize = () => {
        const ctx = this.getContext();
        if(!ctx) {
            return {
                width: 0,
                height: 0
            }
        }
        
        const width = ctx.canvas.clientWidth;
        const height = ctx.canvas.clientHeight;
    
        return {
            width,
            height
        }
    }
}

export default ContextManager;