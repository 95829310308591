import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "components/Common/InputText";
import { showToast } from "components/Common";
import InstrumentService from "services/Instrument";

const Schema = Yup.object().shape({
  instrumentCode: Yup.string().required("Không được bỏ trống"),
});

const Index = ({ isOpen, onClose }) => {
  const [loading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      instrumentCode: "",
      instrumentName: "",
      otherName: "",
    },
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      setIsLoading(true);
      addNewInstrument(values);
    },
  });

  const addNewInstrument = async params => {
    const res = await InstrumentService.addNewInstrument([params]);
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setIsLoading(false);
    } else {
      showToast("Thêm thành công", 1);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "950px", width: "100%" }}>
      <ModalHeader>Thêm mới Master Instrument</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6} lg={4}>
            <InputText
              label="Instrument Code"
              name="instrumentCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentCode}
              errors={formik.errors}
              disabled={loading}
              isRequired
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Instrument Name"
              name="instrumentName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.instrumentName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Other name"
              name="otherName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.otherName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Producer Code"
              name="producerCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.producerCode}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Producer Name"
              name="producerName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.producerName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Field Code"
              name="fieldCode"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.fieldCode}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Field Name"
              name="fieldName"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.fieldName}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="vT3Code"
              name="vT3Code"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.vT3Code}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
          <Col md={6} lg={4}>
            <InputText
              label="Decription"
              name="decription"
              onChange={e => {
                formik.handleChange(e);
                formik.setErrors(null);
              }}
              value={formik.values.decription}
              errors={formik.errors}
              disabled={loading}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          color="primary"
          onClick={formik.handleSubmit}
        >
          Đồng ý
        </Button>{" "}
        <Button color="danger" onClick={onClose}>
          Đóng
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Index;
