import {
  ConfirmModal,
  CustomButton,
  WarningModal,
  showToast,
  ProcessingModal,
  CustomModal,
} from "components/Common";
import { DELAY_FETCH, ModuleIds } from "constant";
import { onDelete, onDeleteToggle } from "helpers/utilities";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useMemo } from "react";
//i18n
import Table, {
  getSeqByCellData,
  scrollToTopRow,
} from "components/Common/Table";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Input, Button } from "reactstrap";
import TargetsModal from "./Modal/TargetModal";
import {
  getAllTargets,
  deleteUserById,
} from "helpers/app-backend/userbackend_helpers";
import Switch from "react-switch";
import toastr from "toastr";
import { getCustomers } from "../../helpers/app-backend/effectiverealsalebackend_helpers";
import PopConfirm from "../../components/Common/PopConfirm";
import {
  getAllTargetPAKDs,
  deleteTargetPAKDById,
} from "helpers/app-backend/targetpakdbackend_helper";
const RESOURCE = ModuleIds.User;

const Targets = ({ t }) => {
  const timerRef = useRef(null);
  const [listTargetPAKD, setListTargetPAKD] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const userName = localStorage.getItem("userName");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [dataCustomers, setDataCustomers] = useState([]);
  const [isAction, setIsAction] = useState(false);
  const [modal, setModal] = useState(false);
  const [dataTarget, setDataTarget] = useState({
    ID:"",
    MaKH: "",
    MaHPP: "",
    MaLV: "",
    thang1: "",
    thang2: "",
    thang3: "",
    thang4: "",
    thang5: "",
    thang6: "",
    thang7: "",
    thang8: "",
    thang9: "",
    thang10: "",
    thang11: "",
    thang12: "",
  });

  const formEl = useRef(null);

  const toggle = () => {
    setModal(prev => !prev);
  };

  const onEditHandler = row => {
    setDataTarget({
      id: row.id,
      MaKH: row.maKH,
      MaHPP: row.maHPP,
      MaLV: row.maLV,
      nam: row.nam,
      thang1: row.thang1,
      thang2: row.thang2,
      thang3: row.thang3,
      thang4: row.thang4,
      thang5: row.thang5,
      thang6: row.thang6,
      thang7: row.thang7,
      thang8: row.thang8,
      thang9: row.thang9,
      thang10: row.thang10,
      thang11: row.thang11,
      thang12: row.thang12,
    });
    setModal(false);
    setIsAction("edit")
    toggle();
  };

  const addUserClicks = () => {
    setIsAction("add")
    toggle();
  };

  const onGetData = async () => {
    const listCus = await getCustomers();
    setDataCustomers(listCus.data);
    const optionsCus = listCus.data?.map(item => ({
      value: item.maKH,
      label: item.tenKH != null ? item.tenKH : "Khách hàng không tên",
      key: item.maMien,
      groupProducts: item.groupProducts,
    }));
    setCustomerOptions(optionsCus);
  };

  const onDeleteUserHandler = idSelect => {
    deleteTargetPAKDById(idSelect)
      .then(() => {
        toastr.success("Xóa thành công");
        onGetListTargetPAKD();
      })
      .catch(error => {
        console.error(error);
      });
  };
  const formatCurrencyVND = value => {
    const formattedValue = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    }).format(value);
    return formattedValue.replace(/\./g, ",").replace("₫", "VND");
  };

  const calculateTotal = (row, monthList) => {
    const total = monthList.reduce((acc, month) => {
      const value = Number(row[month]);
      if (!isNaN(value)) {
        return acc + value;
      } else {
        return acc;
      }
    }, 0);
    return total;
  };

  const columns = [
    {
      filterable: false,
      resiable: false,
      sortable: false,
      width: 50,
      Header: t("STT"),
      Cell: state => (
        <div>{state.page * state.pageSize + state.viewIndex + 1}</div>
      ),
    },
    {
      Header: "Thao tác",
      minWidth: 100,
      maxWidth: 340,
      accessor: "",
      Cell: row => {
        const uniqueId = `Targets-${row.original.id}`;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="sm"
              color="link"
              onClick={() => onEditHandler(row.original)}
              className="me-3 my-0 p-0"
            >
              <i className="mdi mdi-pencil font-size-24 text-primary" />
            </Button>
            <PopConfirm
              id={uniqueId}
              onOK={() => {
                onDeleteUserHandler(row.original.id);
              }}
              placement="bottom"
              type="danger"
              title={t("Cảnh báo")}
              content={t("Bạn có thực sự muốn xóa?")}
            >
              <Button size="sm" color="link" className="me-3 my-0 p-0">
                <i className="mdi mdi-trash-can font-size-24 text-danger" />
              </Button>
            </PopConfirm>
          </div>
        );
      },
    },
    {
      accessor: "nam",
      Header: t("Năm"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "tenKH",
      Header: t("Khách hàng"),
      width: 250,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "maHPP",
      Header: t("Mã hàng phân phối"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "maLV",
      Header: t("Mã lĩnh vực"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      accessor: "thang1",
      Header: t("Tháng 1"),
      width: 150,
      Cell: ({ value, row }) => {
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatCurrencyVND(value)}
          </div>
        );
      },
    },
    {
      accessor: "thang2",
      Header: t("Tháng 2"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang3",
      Header: t("Tháng 3"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang4",
      Header: t("Tháng 4"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang5",
      Header: t("Tháng 5"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang6",
      Header: t("Tháng 6"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang7",
      Header: t("Tháng 7"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang8",
      Header: t("Tháng 8"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang9",
      Header: t("Tháng 9"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang10",
      Header: t("Tháng 10"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang11",
      Header: t("Tháng 11"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "thang12",
      Header: t("Tháng 12"),
      width: 150,
      Cell: ({ value, row }) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrencyVND(value)}
        </div>
      ),
    },
    {
      accessor: "Q1",
      Header: t("Quý 1"),
      width: 150,
      Cell: ({ value, row }) => {
        const monthList = ["thang1", "thang2", "thang3"];
        const total = calculateTotal(row, monthList);

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatCurrencyVND(total)}
          </div>
        );
      },
    },
    {
      accessor: "Q2",
      Header: t("Quý 2"),
      width: 150,
      Cell: ({ value, row }) => {
        const monthList = ["thang4", "thang5", "thang6"];
        const total = calculateTotal(row, monthList);

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatCurrencyVND(total)}
          </div>
        );
      },
    },
    {
      accessor: "Q3",
      Header: t("Quý 3"),
      width: 150,
      Cell: ({ value, row }) => {
        const monthList = ["thang7", "thang8", "thang9"];
        const total = calculateTotal(row, monthList);

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatCurrencyVND(total)}
          </div>
        );
      },
    },
    {
      accessor: "Q4",
      Header: t("Quý 4"),
      width: 150,
      Cell: ({ value, row }) => {
        const monthList = ["thang10", "thang11", "thang12"];
        const total = calculateTotal(row, monthList);

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatCurrencyVND(total)}
          </div>
        );
      },
    },
    {
      accessor: "Total",
      Header: t("Tổng"),
      width: 150,
      Cell: ({ value, row }) => {
        const monthList = [
          "thang1",
          "thang2",
          "thang3",
          "thang4",
          "thang5",
          "thang6",
          "thang7",
          "thang8",
          "thang9",
          "thang10",
          "thang11",
          "thang12",
        ];
        const total = calculateTotal(row, monthList);

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatCurrencyVND(total)}
          </div>
        );
      },
    },
  ];

  const onGetListTargetPAKD = (options = {}) => {
    setListTargetPAKD(null);
    timerRef.current = setTimeout(async () => {
      const res = await getAllTargetPAKDs({
        page: paging.currentPage,
        size: paging.pageSize,
        search: filter.search,
        ...options,
      });

      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setListTargetPAKD([]);
        return;
      }
      setListTargetPAKD(res);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  useEffect(() => {
    onGetListTargetPAKD();
  }, [paging]);

  useMemo(() => {
    onGetData();
  }, []);
  return (
    <React.Fragment>
      <Card className="p-2 mb-2">
        <div className="w-100 d-flex justify-content-end">
          <CustomButton color="success" onClick={addUserClicks}>
            <div className="d-flex align-items-center">
              <i className="bx bx-plus-circle"></i>
              <p className="mb-0 mx-1">{t("Thêm mới")}</p>
            </div>
          </CustomButton>
        </div>
      </Card>
      <Table
        paging={paging}
        setPaging={setPaging}
        data={listTargetPAKD}
        pageSize={paging.pageSize}
        style={{ height: "calc(100vh - 280px)" }}
        columns={columns}
      />
      <TargetsModal
        formEl={formEl}
        modal={modal}
        toggle={toggle}
        setModal={setModal}
        dataTarget={dataTarget}
        setDataTarget={setDataTarget}
        isAction={isAction}
        customerOptions={customerOptions}
        onGetListTargetPAKD={onGetListTargetPAKD}
      />
    </React.Fragment>
  );
};

export default withRouter(withTranslation(["UserPage", "message"])(Targets));
