import React from "react";
import { Card, Label, Row, Col, Button, Input } from "reactstrap";
import DatePicker, {registerLocale} from "react-datepicker";
import { reportType } from "./constants";
import { DISPLAY_DATE_FORMAT } from "constant";
import { InputAutoFormat } from "components/Common";
import vi from "date-fns/locale/vi";
registerLocale("vi", vi);

const Index = ({
  filter,
  setFilter,
  onSearch,
  filterType,
  error,
  setError,
  isLoading,
  exportFilter,
}) => {
  const isUseDynamicInput = [
    reportType.RESULT_DEPARTMENT,
    reportType.RESULT_INSTRUMENT,
  ].includes(filterType);

  const getTitleCount = () => {
    const type = exportFilter.reportType.value;
    if (type === reportType.PATIENT) {
      return "CountPatient"
    } 

    return "CountTest"
  }

  return (
    <Card className="p-3 mb-2">
      <Row>
        <Col sm={12} md={6} lg={5} xl={3} className="my-1">
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">StatMonth</Label>
            <div style={{ width: "95px" }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={filter.dateFrom}
                onChange={date => {
                  
                  setFilter(prev => ({ ...prev, dateFrom: date }));
                  if (error) {
                    setError(null);
                  }
                }}
                dateFormat={DISPLAY_DATE_FORMAT}
                popperPlacement="bottom"
                disabled={isLoading}
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                onKeyDown={e => {
                  if(e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>

            <i className="bx bxs-right-arrow"></i>
            <div style={{ width: "95px" }} className="mx-1">
              <DatePicker
                locale="vi"
                customInput={
                  <Input
                    className="position-relative"
                    style={{
                      borderColor: error && error.date ? "red" : "#ced4da",
                    }}
                  />
                }
                selected={filter.dateTo}
                onChange={date => {
                  setFilter(prev => ({ ...prev, dateTo: date }));
                  if (error) {
                    setError(null);
                  }
                }}
                disabled={isLoading}
                dateFormat={DISPLAY_DATE_FORMAT}
                popperPlacement="bottom"
                popperProps={{ strategy: "fixed" }}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                onKeyDown={e => {
                  if(e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          {error && error.date ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.date}
            </p>
          ) : null}
        </Col>

        <Col sm={12} md={6} lg={5} xl={3} className="my-1">
          <div className="d-flex align-items-center">
            <Label className="mb-0 text-truncate">{getTitleCount()}</Label>
            <div className="mx-1">
              <InputAutoFormat
                defaultValue={filter.countFrom}
                style={{
                  width: "110px",
                  borderColor: error && error.count ? "red" : "#ced4da",
                }}
                disabled={isLoading}
                maxLength={10}
                onValueChange={val => {
                  setFilter(prev => ({
                    ...prev,
                    countFrom: val || val === '0' ? parseInt(val) : undefined,
                  }));
                  if (error) {
                    setError(null);
                  }
                }}
                onKeyDown={e => {
                  if(e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
            <i className="bx bxs-right-arrow"></i>
            <div className="mx-1">
              <InputAutoFormat
                defaultValue={filter.countTo}
                style={{
                  width: "110px",
                  borderColor: error && error.count ? "red" : "#ced4da",
                }}
                disabled={isLoading}
                maxLength={10}
                onValueChange={val => {
                  setFilter(prev => ({
                    ...prev,
                    countTo:  val  ? parseInt(val) : undefined,
                  }));
                  if (error) {
                    setError(null);
                  }
                }}
                onKeyDown={e => {
                  if(e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          {error && error.count ? (
            <p className="mb-0" style={{ color: "red" }}>
              {error.count}
            </p>
          ) : null}
        </Col>

        {filterType !== reportType.PATIENT ? (
          <Col sm={12} md={6} lg={5} xl={3} className="my-1">
            <div className="d-flex align-items-center">
              <Label className="mb-0 text-truncate">TestName</Label>
              <div style={{ flex: 1, marginLeft: "6px" }}>
                <Input
                  value={filter.testName}
                  disabled={isLoading}
                  onChange={e =>
                    setFilter(prev => ({ ...prev, testName: e.target.value }))
                  }
                  onKeyDown={e => {
                    if(e.keyCode === 13) {
                      onSearch();
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        ) : null}

        {isUseDynamicInput ? (
          <Col sm={12} md={5} lg={5} xl={3} className="my-1">
            <div className="d-flex align-items-center">
              <Label className="mb-0 text-truncate">
                {filterType === reportType.RESULT_INSTRUMENT
                  ? "InstrumentName"
                  : "LocationName"}
              </Label>
              <div style={{ flex: 1, marginLeft: "6px" }}>
                <Input
                  value={
                    filterType === reportType.RESULT_INSTRUMENT
                      ? filter.instrumentName
                      : filter.locationName
                  }
                  disabled={isLoading}
                  onChange={e =>
                    setFilter(prev => ({
                      ...prev,
                      [filterType === reportType.RESULT_INSTRUMENT
                        ? "instrumentName"
                        : "locationName"]: e.target.value,
                    }))
                  }
                  onKeyDown={e => {
                    if(e.keyCode === 13) {
                      onSearch();
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        ) : null}

        <Col
          sm={12}
          md={isUseDynamicInput ? 12 : 2}
          className={
            isUseDynamicInput ? "w-100 d-flex justify-content-end mt-2" : "my-1"
          }
        >
          <Button
            color="primary"
            className="btn-hover-shine"
            onClick={onSearch}
            disabled={error || isLoading}
          >
            <div className="d-flex align-items-center">
              <i className="bx bx-search mr-1" style={{fontSize: '1rem'}}></i>
              <p className="mx-1 mb-0">
                {isLoading ? "Đang tìm..." : "Tìm kiếm"}
              </p>
            </div>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
