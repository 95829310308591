import {
  GetListDetailCombineStatMonthlyResult,
  GetListStatisticStatMonthResult,
  GetListDetailCombineStatMonthlyPatient,
  GetListStatisticStatMonthPatient,
  GetListDetailCombineStatMonthlyResultByCate,
  GetListStatisticStatMonthResultByCate,
  GetListDetailStatMonthlyResultLocationType,
  GetListStatMonthlyResultLocationType,
  GetListCombineStatMonthlyResultInstrument,
  GetListMaHangInstrument,
  GetLsCommonName,
  GetLsLabelValueMatHang,
  GetDetailCombineStatMonthlyResultInstrumentByMaHang,
  GetDetailCombineStatMonthlyResultInstrumentByMaHangCommonName,
  GetListResultInstrumentByCate,
  GetDetailCombineStatMonthlyResultInstrumentByMaHangCategoryCode,
  GetLsMasterCategoryCode,
  GetListDetailCombineStatMonthlyPatientByYear,
  GetListStatisticStatMonthPatientByYear,
  GetListDetailCombineStatMonthlyResultByYear,
  GetListStatisticStatMonthResultByYear,
  GetListStatisticStatMonthResultBySale,
  GetLsMasterCategoryCodeResult,
  GetListDetailStatisticStatMonthResultByCateCommon,
  GetListDetailStatisticStatMonthResultSaleByCategory,
  GetListStatisticStatMonthResultSaleByCategory,
  GetListInstrumentSaleByMaHang,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHang,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCategoryCode,
  GetListResultInstrumentSaleByCate,
  GetListDetailStatMonthlyResultLocationTypeSale,
  GetListStatMonthlyResultLocationTypeSale,
  GetListStatisticStatMonthResultSaleByYear,
  GetListDetailCombineStatMonthlyResultSaleByYear,
  GetListDetailCombineStatMonthlyResultSale,
  GetListStatisticStatMonthResultSaleBySite,
  GetListStatisticStatMonthResultByCateType,
  GetListDetailStatisticStatMonthResultSaleByCate,
  GetListDetailStatisticStatMonthResultSaleByCateCommon,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCommonName,
  GetListStatMonthlyResultLocationTypeSaleByLocalLocation,
  GetListDetailStatMonthlyResultLocationTypeSaleByLocalLocation,
  GetListStatisticStatMonthResultByGroupTest,
  GetListDetailCombineStatMonthlyResultByGroupTest,
  GetListStatisticStatMonthResultByParentTest,
  GetListDetailCombineStatMonthlyResultByParentTest,
  GetListStatisticStatMonthResultSaleByGroupTest,
  GetListDetailStatisticStatMonthResultSaleByParentTest,
  GetListStatisticStatMonthResultSaleByParentTest,
  GetListDetailStatisticStatMonthResultSaleByGroupTest,
} from "helpers/app-backend/statisticbackend_helpers";
import HandleReponse from "utils/handleReponse";

const GetListDetailCombineStatMonthlyResultService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListDetailCombineStatMonthlyResult(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListStatisticStatMonthPatientByYearService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListStatisticStatMonthPatientByYear(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListDetailCombineStatMonthlyPatientByYearService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListDetailCombineStatMonthlyPatientByYear(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListStatisticStatMonthResultByYearService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListStatisticStatMonthResultByYear(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListDetailCombineStatMonthlyResultByYearService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListDetailCombineStatMonthlyResultByYear(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListStatisticStatMonthResultService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatisticStatMonthResult(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListDetailCombineStatMonthlyResultByCateService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListDetailCombineStatMonthlyResultByCate(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListStatisticStatMonthResultServiceByCate = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListStatisticStatMonthResultByCate(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListDetailStatMonthlyResultLocationTypeService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListDetailStatMonthlyResultLocationType(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListStatisticStatMonthPatientService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatisticStatMonthPatient(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListDetailCombineStatMonthlyPatientService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListDetailCombineStatMonthlyPatient(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListStatMonthlyResultLocationTypeService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListStatMonthlyResultLocationType(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListMaHangInstrumentService = async () => {
  try {
    const res = await GetListMaHangInstrument();
    return res;
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListCombineStatMonthlyResultInstrumentService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetListCombineStatMonthlyResultInstrument(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetDetailCombineStatMonthlyResultInstrumentByMaHangService = async (
  paramters,
  reqBody
) => {
  try {
    const res = await GetDetailCombineStatMonthlyResultInstrumentByMaHang(
      paramters,
      reqBody
    );
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetDetailCombineStatMonthlyResultInstrumentByMaHangCommonNameService =
  async (paramters, reqBody) => {
    try {
      const res =
        await GetDetailCombineStatMonthlyResultInstrumentByMaHangCommonName(
          paramters,
          reqBody
        );
      return HandleReponse.getListSuccess(res);
    } catch (err) {
      return HandleReponse.getListFail();
    }
  };
const GetDetailCombineStatMonthlyResultInstrumentByMaHangCategoryCodeService =
  async (paramters, reqBody) => {
    try {
      const res =
        await GetDetailCombineStatMonthlyResultInstrumentByMaHangCategoryCode(
          paramters,
          reqBody
        );
      return HandleReponse.getListSuccess(res);
    } catch (err) {
      return HandleReponse.getListFail();
    }
  };

const GetLsCommonNameService = async paramters => {
  try {
    const res = await GetLsCommonName(paramters);
    return res;
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetLsMasterCategoryCodeService = async paramters => {
  try {
    const res = await GetLsMasterCategoryCode(paramters);
    return res;
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetLsMasterCategoryCodeResultService = async paramters => {
  try {
    const res = await GetLsMasterCategoryCodeResult(paramters);
    return res;
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetLsLabelValueMatHangService = async paramters => {
  try {
    const res = await GetLsLabelValueMatHang(paramters);
    return res;
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListResultInstrumentByCateService = async (paramters, reqBody) => {
  try {
    const res = await GetListResultInstrumentByCate(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListStatisticStatMonthResultBySaleService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatisticStatMonthResultBySale(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListDetailStatisticStatMonthResultByCateCommonService = async (paramters, reqBody) => {
  try {
    const res = await GetListDetailStatisticStatMonthResultByCateCommon(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListStatisticStatMonthResultSaleByCategoryService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatisticStatMonthResultSaleByCategory(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListDetailStatisticStatMonthResultSaleByCategoryService = async (paramters, reqBody) => {
  try {
    const res = await GetListDetailStatisticStatMonthResultSaleByCategory(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListInstrumentSaleByMaHangService = async (paramters, reqBody) => {
  try {
    const res = await GetListInstrumentSaleByMaHang(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangService = async (paramters, reqBody) => {
  try {
    const res = await GetDetailCombineStatMonthlyResultInstrumentSaleByMaHang(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCategoryCodeService = async (paramters, reqBody) => {
  try {
    const res = await GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCategoryCode(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListResultInstrumentSaleByCateService = async (paramters, reqBody) => {
  try {
    const res = await GetListResultInstrumentSaleByCate(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListDetailStatMonthlyResultLocationTypeSaleService = async (paramters, reqBody) => {
  try {
    const res = await GetListDetailStatMonthlyResultLocationTypeSale(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListStatMonthlyResultLocationTypeSaleService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatMonthlyResultLocationTypeSale(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListDetailCombineStatMonthlyResultSaleService = async (paramters, reqBody) => {
  try {
    const res = await GetListDetailCombineStatMonthlyResultSale(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListDetailCombineStatMonthlyResultSaleByYearService = async (paramters, reqBody) => {
  try {
    const res = await GetListDetailCombineStatMonthlyResultSaleByYear(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListStatisticStatMonthResultSaleByYearService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatisticStatMonthResultSaleByYear(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};
const GetListStatisticStatMonthResultSaleBySiteService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatisticStatMonthResultSaleBySite(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListStatisticStatMonthResultByCateTypeService = async (paramters, reqBody) => {
  try {
    const res = await GetListStatisticStatMonthResultByCateType(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListDetailStatisticStatMonthResultSaleByCateCommonService = async (paramters, reqBody) => {
  try {
    const res = await GetListDetailStatisticStatMonthResultSaleByCateCommon(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetListDetailStatisticStatMonthResultSaleByCateService = async (paramters, reqBody) => {
  try {
    const res = await GetListDetailStatisticStatMonthResultSaleByCate(paramters, reqBody);
    return HandleReponse.getListSuccess(res);
  } catch (err) {
    return HandleReponse.getListFail();
  }
};

const GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCommonNameService =
  async (paramters, reqBody) => {
    try {
      const res =
        await GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCommonName(
          paramters,
          reqBody
        );
      return HandleReponse.getListSuccess(res);
    } catch (err) {
      return HandleReponse.getListFail();
    }
  };

  const GetListStatMonthlyResultLocationTypeSaleByLocalLocationService = async (paramters, reqBody) => {
    try {
      const res = await GetListStatMonthlyResultLocationTypeSaleByLocalLocation(paramters, reqBody);
      return HandleReponse.getListSuccess(res);
    } catch (err) {
      return HandleReponse.getListFail();
    }
  };
  
  const GetListDetailStatMonthlyResultLocationTypeSaleByLocalLocationService =
    async (paramters, reqBody) => {
      try {
        const res =
          await GetListDetailStatMonthlyResultLocationTypeSaleByLocalLocation(
            paramters,
            reqBody
          );
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListStatisticStatMonthResultByGroupTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListStatisticStatMonthResultByGroupTest(paramters, reqBody);
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListDetailCombineStatMonthlyResultByGroupTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListDetailCombineStatMonthlyResultByGroupTest(
          paramters,
          reqBody
        );
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListStatisticStatMonthResultByParentTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListStatisticStatMonthResultByParentTest(paramters, reqBody);
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListDetailCombineStatMonthlyResultByParentTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListDetailCombineStatMonthlyResultByParentTest(
          paramters,
          reqBody
        );
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListStatisticStatMonthResultSaleByGroupTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListStatisticStatMonthResultSaleByGroupTest(paramters, reqBody);
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListDetailCombineStatMonthlyResultSaleByGroupTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListDetailStatisticStatMonthResultSaleByGroupTest(
          paramters,
          reqBody
        );
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListStatisticStatMonthResultSaleByParentTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListStatisticStatMonthResultSaleByParentTest(paramters, reqBody);
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

    const GetListDetailCombineStatMonthlyResultSaleByParentTestService = async (
      paramters,
      reqBody
    ) => {
      try {
        const res = await GetListDetailStatisticStatMonthResultSaleByParentTest(
          paramters,
          reqBody
        );
        return HandleReponse.getListSuccess(res);
      } catch (err) {
        return HandleReponse.getListFail();
      }
    };

export default {
  GetListDetailCombineStatMonthlyResultService,
  GetListStatisticStatMonthResultService,
  GetListDetailCombineStatMonthlyPatientService,
  GetListStatisticStatMonthPatientService,
  GetListStatisticStatMonthResultServiceByCate,
  GetListDetailCombineStatMonthlyResultByCateService,
  GetListStatMonthlyResultLocationTypeService,
  GetListDetailStatMonthlyResultLocationTypeService,
  GetListMaHangInstrumentService,
  GetListCombineStatMonthlyResultInstrumentService,
  GetDetailCombineStatMonthlyResultInstrumentByMaHangService,
  GetDetailCombineStatMonthlyResultInstrumentByMaHangCommonNameService,
  GetLsCommonNameService,
  GetLsLabelValueMatHangService,
  GetListResultInstrumentByCateService,
  GetDetailCombineStatMonthlyResultInstrumentByMaHangCategoryCodeService,
  GetLsMasterCategoryCodeService,
  GetListDetailCombineStatMonthlyPatientByYearService,
  GetListStatisticStatMonthPatientByYearService,
  GetListStatisticStatMonthResultByYearService,
  GetListDetailCombineStatMonthlyResultByYearService,
  GetLsMasterCategoryCodeResultService,
  GetListStatisticStatMonthResultBySaleService,
  GetListDetailStatisticStatMonthResultByCateCommonService,
  GetListStatisticStatMonthResultSaleByCategoryService,
  GetListDetailStatisticStatMonthResultSaleByCategoryService,
  GetListInstrumentSaleByMaHangService,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangService,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCategoryCodeService,
  GetListResultInstrumentSaleByCateService,
  GetListDetailStatMonthlyResultLocationTypeSaleService,
  GetListStatMonthlyResultLocationTypeSaleService,
  GetListDetailCombineStatMonthlyResultSaleService,
  GetListDetailCombineStatMonthlyResultSaleByYearService,
  GetListStatisticStatMonthResultSaleByYearService,
  GetListStatisticStatMonthResultSaleBySiteService,
  GetListStatisticStatMonthResultByCateTypeService,
  GetListDetailStatisticStatMonthResultSaleByCateCommonService,
  GetListDetailStatisticStatMonthResultSaleByCateService,
  GetDetailCombineStatMonthlyResultInstrumentSaleByMaHangCommonNameService,
  GetListStatMonthlyResultLocationTypeSaleByLocalLocationService,
  GetListDetailStatMonthlyResultLocationTypeSaleByLocalLocationService,
  GetListStatisticStatMonthResultByGroupTestService,
  GetListDetailCombineStatMonthlyResultByGroupTestService,
  GetListStatisticStatMonthResultByParentTestService,
  GetListDetailCombineStatMonthlyResultByParentTestService,
  GetListDetailCombineStatMonthlyResultSaleByParentTestService,
  GetListStatisticStatMonthResultSaleByParentTestService,
  GetListDetailCombineStatMonthlyResultSaleByGroupTestService,
  GetListStatisticStatMonthResultSaleByGroupTestService,
};
