import React, { useRef, useState, useEffect } from "react";
import MasterGroupTestService from "services/MasterGroupTest";
import Table from "./Table";
import Filter from "./Filter";
import { DELAY_FETCH } from "constant";
import { scrollToTopRow } from "components/Common/Table";
import ModalInsertUpdate from "./ModalInsertUpdate";
import { ConfirmModal } from "components/Common";
import { showToast } from "components/Common";
const Index = ({masterCategory, masterTestCode}) => {
  
  const timerRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    groupTestCode: "",
    category:""
  });
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 100,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenModalInsertUpdate, setIsOpenModalInsertUpdate] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isModalConfirmDelete, setIsModalConfirmDelete] = useState(false);
  const [detailData, setDetailData] = useState({});
  const getRequestParams = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: paging.currentPage,
        size: paging.pageSize,
        groupTestCode: filter.groupTestCode,
        category:filter.category,
        ...params,
      },
      requestBody: {
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableData = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParams(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await MasterGroupTestService.GetListMasterGroupTestsService(
        params
      );
      
      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
     let newData = res.data.map(x => {        
        return ({...x, testCode: x.lsMasterTestCode.map((list) => list.value).join(",")})
      })
      res.data = newData;
     
      setTableData(res);
      setIsDone(false);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };
  const getRequestParamsSearch = (params = {}, requestBody = {}) => {
    const reqParams = {
      params: {
        page: 1,
        size: paging.pageSize,
        groupTestCode: filter.groupTestCode,
        category:filter.category,
        ...params,
      },
      requestBody: {
        ...requestBody,
      },
    };
    return reqParams;
  };

  const getTableDataSearch = (_params = {}, _requestBody = {}) => {
    const { params, requestBody } = getRequestParamsSearch(_params, _requestBody);
    setTableData(null);
    setLoading(true);

    timerRef.current = setTimeout(async () => {
      const res = await MasterGroupTestService.GetListMasterGroupTestsService(
        params
      );
      if (res.error) {
        showToast(`${res.error.title}: ${res.error.description}`, 3);
        setTableData([]);
        setLoading(false);
        return;
      }
      let newData = res.data.map(x => {        
        return ({...x, testCode: x.lsMasterTestCode.map((list) => list.value).join(",")})
      })
      res.data = newData;
      setTableData(res);
      setIsDone(false);
      setLoading(false);
      scrollToTopRow();
    }, DELAY_FETCH);
  };

  const OnDeleteGroupTest = async () => {
    const res = await MasterGroupTestService.DeleteMasterGroupTestService(
      detailData
    );
    if (res.error) {
      showToast(`${res.error.title}: ${res.error.description}`, 3);
      setLoading(false);
    } else {
      showToast(`Xóa thành công ${detailData?.groupTestCode}`, 1);
      setIsDone(true);
      setIsModalConfirmDelete(false);
    }
  };

  useEffect(() => {
    if (!tableData) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [tableData]);

  useEffect(() => {
    getTableData();
  }, [paging, isDone]);
  
  useEffect(() => {
    getTableDataSearch();
  }, [filter.category]);
  
  return (
    <>
      <Filter  
        filter={filter}
        setFilter={setFilter}
        setIsEdit={setIsEdit}
        isLoading={loading}
        masterCategory={masterCategory}
        setIsOpenModalInsertUpdate={setIsOpenModalInsertUpdate}
        onSearch={() => {
          // setPaging(prev => ({ ...prev, currentPage: 1 }));
          // getTableData({ page: 1 });
          getTableDataSearch();
        }}
      />
      <Table
        setDetailData={setDetailData}
        paging={paging}
        pageSize={paging.pageSize}
        setPaging={setPaging}
        data={tableData}
        setIsEdit={setIsEdit}
        setIsOpenModalInsertUpdate={setIsOpenModalInsertUpdate}
        setIsModalConfirmDelete={setIsModalConfirmDelete}
      />
      {isOpenModalInsertUpdate && (
        <ModalInsertUpdate
          masterCategory={masterCategory}
          masterTestCode={masterTestCode}
          data={isEdit ? detailData : {}}
          filter={filter}
          isEdit={isEdit}
          setIsDone={setIsDone}
          isOpen={isOpenModalInsertUpdate}
          onClose={() => setIsOpenModalInsertUpdate(false)}
        />
      )}
      {/* {isModalConfirmDelete && (
        <ConfirmModal
          
          modal={isModalConfirmDelete}
          title="Xác nhận xóa?"
          message={`Bạn có chắc chắn muốn xóa grouptest ${detailData?.groupTestCode}?`}
          onToggle={() => {
            setIsModalConfirmDelete(false);
          }}
          onDelete={OnDeleteGroupTest}
        />
      )} */}
    </>
  );
};

export default Index;
