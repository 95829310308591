import React from "react";
import { Spin, Typography, Radio } from "antd";
import { Card } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const Index = ({ windowDimensions, dataChart }) => {
  const limitedDataChart = dataChart.slice(12, 16);
  const series = [
    {
      name: "Hiệu quả % theo quý",
      type: "column",
      data: limitedDataChart.map(item => item.investmentEfficiencyPercentage),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 2) {
          // Chỉ hiển thị label cho đường cong "Hiệu quả đầu tư (%)"
          return value.toFixed(2) + "%";
        }
        return "";
      },
    },
    colors: ["#4472c4", "#ed7d31", "#a5a5a5"],

    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "Hiệu quả %",
      align: "center",
      offsetX: 110,
      style: {
        fontSize: "16px",
        fontFamily: "Times New Roman, serif",
      },
    },
    xaxis: {
      categories: limitedDataChart.map(item => item.name),
    },
    yaxis: [
      {
        min: 0,
        seriesName: "Income",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#74788d",
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          },
          style: {
            colors: "#74788d",
          },
        },
        title: {
          text: "",
          style: {
            color: "#74788d",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
      enabled: true,
      y: {
        formatter: function (value) {
          return value.toFixed(2) + "%";
        },
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  };

  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Thống kê hiệu quả % theo quý
            </Typography.Title>
          </div>
        </div>
        <div className="d-flex justify-content-center w-100">
          <div
            className="w-100"
            style={{
              maxWidth: windowDimensions.height > 768 ? "74%" : "894px",
            }}
          >
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={windowDimensions.height > 768 ? 650 : 500}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Index;
