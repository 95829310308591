import React from "react";
import { Card } from "reactstrap";
import { Typography, Row, Col, Spin, Tooltip } from "antd";
import { Pie } from "react-chartjs-2";
import { color } from "./constants";
import { BoxLoading } from "react-loadingg";

const PieLegendSharp = ({ color = "#FFF", label = "", style = {} }) => (
  <div className="d-flex align-items-center" style={style}>
    <div className="pie-legend-sharp" style={{ borderRightColor: color }} />
    <Typography className="ml-1" style={{ fontSize: ".9rem" }}>
      {label}
    </Typography>
  </div>
);

const CustomCard = ({
  item: {
    title1,
    title2,
    value,
    background = "linear-gradient(180deg, #FEB019, #eb7f05)",
  },
}) => (
  <Tooltip title={title1}>
    <div
      className="w-100"
      style={{
        padding: "5px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "8px",
        background,
      }}
    >
      <Typography
        className="text-center text-truncate"
        style={{ color: "#000" }}
      >
        {title2}
      </Typography>

      {value ? (
        <Typography.Title
          className="mt-1 mb-1 text-center text-truncate"
          level={2}
          style={{ color: "#000" }}
        >
          {value}
        </Typography.Title>
      ) : (
        <div
          className="w-100 d-flex justify-content-center py-3"
          style={{ background: "rgba(255,255,255,.4)", borderRadius: "4px" }}
        >
          <Spin />
        </div>
      )}
    </div>
  </Tooltip>
);

const Index = ({ data, avgTimeData, cardData }) => {
  return (
    <div className="mt-2">
      <Card className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center mb-2">
            <i
              className="pe-7s-graph icon-gradient bg-mean-fruit"
              style={{
                fontSize: "1.4rem",
                padding: "3px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius: "4px",
              }}
            />
            <Typography.Title level={4} className="mb-0 ml-2">
              Thời gian TAT trung bình trong lab
            </Typography.Title>
          </div>
        </div>

        <Row gutter={[16, 16]} className="mt-3">
          {cardData?.map((item) => (
            <Col key={item.id} lg={6} md={12} sm={12} xs={24}>
              <CustomCard item={item} />
            </Col>
          ))}
        </Row>

        {avgTimeData ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <div style={{ maxWidth: "600px", width: "100%" }}>
              <Pie
                data={avgTimeData}
                options={{
                  zoomOutPercentage: 90,
                  title: {
                    display: false,
                    text: "",
                    fontSize: 25,
                  },
                  tooltips: {
                    enabled: false
                  },
                  legend: {
                    display: false,
                  },
                  layout: {
                    padding: 50,
                  },
                  plugins: {
                    datalabels: {
                      display: false,
                    },
                    outlabels: {
                      text: "%l",
                      color: "white",
                      stretch: 40,
                      padding: 6,
                      font: {
                        resizable: true,
                        minSize: 13,
                        maxSize: 17,
                      },
                    },
                  },
                }}
              />             
            </div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center mt-5 mb-5"
            style={{ height: "300px" }}
          >
            <BoxLoading size="large" />
          </div>
        )}
      </Card>
    </div>
  );
};

export default Index;
